export const SLOAN_FACT_SHEET_DETAILS = (customerId: string) => `oneSloan/customer/${customerId}/factSheet`;
export const SLOAN_FACT_SHEET_PDF = (customerId: string) => `oneSloan/customer/${customerId}/factSheet/pdf`;
export const SLOAN_ORDER_TYPES = (customerId: string) => `knbr/customer/${customerId}/orders/orderType`;
export const SLOAN_CREDIT_STATUSES = (customerId: string) => `knbr/customer/${customerId}/orders/creditStatus`;
export const SLOAN_DELIVERY_BLOCK_LIST = (customerId: string) => `knbr/customer/${customerId}/orders/deliveryBlock`;
export const SLOAN_INVOICE_HEADER_PDF = (customerId: string) => `knbr/customer/${customerId}/downloadHeaderPDF`;
export const SLOAN_DOCUMENT_PDF = (customerId: string) => `knbr/customer/${customerId}/getPdf`;
export const SLOAN_ADDRESS_VERIFICATION =
  'https://ws.strikeiron.com/StrikeIron/USAddressVerification5/USAddressVerification/VerifyAddressUSA';
export const SLOAN_HOLIDAY_LIST = `oneSloan/holidayCalendar?fields=FULL`;
export const SLOAN_QUOTE_SINK_HOLE_DRILL_PATTERNS =
  'knbr/customer/${customerId}/quotes/${quoteId}/entries/${entryNumber}/sinkHoleDrillPattern';
export const SLOAN_DRAFT_ORDER_SINK_HOLE_DRILL_PATTERNS =
  'knbr/customer/${customerId}/carts/${cartId}/entries/${entryNumber}/sinkHoleDrillPattern';
export const PAYMETRICS_ACCESS_TOKEN = 'oneSloan/XiIntercept3/getAccessToken';
export const PAYMETRICS_RESPONSE_STATUS = 'knbr/customer/${customerId}/carts/${cartId}/payment/sop/response';
export const SLOAN_DRAFT_ORDER_EDIT = 'knbr/customer/${customerId}/carts/${cartId}/edit';
export const SLOAN_QUOTE_EDIT = 'knbr/customer/${customerId}/quotes/${quoteId}/edit';
export const SLOAN_QUOTE_VALIDATY_DATE = 'oneSloan/quoteValidityDate';
export const SLOAN_DELIVERYMODE='knbr/customer/${customerId}/orders/${code}/deliverymodes';