import { KnBrOrderReturnActions, KnBrOrderReturnActionTypes } from '../actions/kn-br-order-return.action';
import { KnBrOrderReturnState } from '../../../../../app/store/kn-br-order-return/kn-br-order-return.state';

export const initialState: KnBrOrderReturnState = {
  returnRequests: [],
  error: undefined,
  searchRequest: undefined,
  isLoading: false,
  pagination: undefined,
  sorts: undefined,
  isSearchDisabled: false,
  order: undefined,
};

export function knBrOrderReturnReducer(state = initialState, action: KnBrOrderReturnActions): KnBrOrderReturnState {
  switch (action.type) {
    case KnBrOrderReturnActionTypes.KnBrReturnOrdersSearchAction:
      return { ...state, searchRequest: action.payload, isLoading: true, isSearchDisabled: true };
    case KnBrOrderReturnActionTypes.KnBrReturnOrdersSearchSuccessAction:
      return {
        ...state,
        returnRequests: [...state.returnRequests, ...action.payload?.returnRequests],
        pagination: action.payload?.pagination,
        sorts: action.payload?.sorts,
        isLoading: false,
        isSearchDisabled: false,
      };
    case KnBrOrderReturnActionTypes.KnBrReturnOrdersQuickSearchSuccessAction:
      return {
        ...state,
        returnRequests: action.payload?.returnRequests,
        pagination: action.payload?.pagination,
        sorts: action.payload?.sorts,
        isLoading: false,
        isSearchDisabled: false,
      };
    case KnBrOrderReturnActionTypes.KnBrReturnOrdersSearchFailureAction:
      return { ...state, error: action.payload, isLoading: false, isSearchDisabled: false };
    case KnBrOrderReturnActionTypes.KnBrReturnOrdersSearchResetAction:
      return { ...state, returnRequests: [], pagination: null, sorts: null, isLoading: true };
    default:
      return state;
  }
}
