import { Injectable } from '@angular/core';
import { Converter, Occ } from '@spartacus/core';
import { environment } from 'src/environments/environment';
import { KnBrB2BUser } from 'src/feature-libs/kn-br-organization/administration/core/model/kn-br-b2b-user.model';

@Injectable()
export class SloanB2BUserSerializer implements Converter<KnBrB2BUser, Occ.B2BUser> {
  convert(source: KnBrB2BUser, target?: Occ.B2BUser): Occ.B2BUser {
    if (target === undefined) {
      target = { ...(source as any) };
    }
    delete (target as KnBrB2BUser).isAssignedToApprovers;
    if (target.active === false) {
      target.roles = [];
    }
    (target as any).contactNumber = source.knbrMobileNumber;

    // new code for adding basesite pipe
    if (source.email) {
      (target as any).uid = (environment.BASE_SITE[0] + '|').toLowerCase() + source.email;
      (target as any).email = (environment.BASE_SITE[0] + '|').toLowerCase() + source.email;
    }

    return target;
  }
}
