import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { B2BUser, B2BUserRole } from '@spartacus/core';
import { FormService } from '@spartacus/organization/administration/components';
import { CustomFormValidators } from '@spartacus/storefront';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class KnBrUserFormService extends FormService<B2BUser> {
  protected build() {
    const form = new UntypedFormGroup({});
    form.setControl('customerId', new UntypedFormControl(''));
    form.setControl('titleCode', new UntypedFormControl(''));
    form.setControl('firstName', new UntypedFormControl('', Validators.required));
    form.setControl('lastName', new UntypedFormControl('', Validators.required));
    form.setControl('email', new UntypedFormControl('', [Validators.required, CustomFormValidators.emailValidator]));
    form.setControl(
      'orgUnit',
      new UntypedFormGroup({
        uid: new UntypedFormControl(undefined, Validators.required),
      })
    );
    form.setControl('roles', new UntypedFormArray([]));
    form.setControl('isAssignedToApprovers', new UntypedFormControl(false));

    form.get('roles').valueChanges.subscribe((roles: string[]) => {
      if (roles.includes(B2BUserRole.APPROVER)) {
        form.get('isAssignedToApprovers').enable();
      } else {
        form.get('isAssignedToApprovers').disable();
        form.get('isAssignedToApprovers').reset();
      }
    });

    // Custom fields added for KNBR
    form.setControl('jobFunctionKey', new UntypedFormControl(''));
    form.setControl('knbrMobileNumber', new UntypedFormControl(''));

    this.form = form;
  }

  protected patchData(item: B2BUser) {
    super.patchData(item);
    if (item) {
      const roles = this.form.get('roles') as UntypedFormArray;
      const emailFormControl = this.form?.get('email');
      item.roles?.forEach((role) => {
        if (!(roles.value as string[]).includes(role)) {
          roles.push(new UntypedFormControl(role));
        }
      });
      if (item.displayUid && emailFormControl) {
        emailFormControl.setValue(item.displayUid);
      }
    }
  }
}
