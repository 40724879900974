import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { KnBrCheckoutOrderSummaryComponent } from 'src/feature-libs/kn-br-checkout/base/components/kn-br-checkout-order-summary/kn-br-checkout-order-summary.component';

@Component({
  selector: 'cx-checkout-order-summary',
  templateUrl: './sloan-checkout-order-summary.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SloanCheckoutOrderSummaryComponent extends KnBrCheckoutOrderSummaryComponent {
  constructor(protected activeCartService: ActiveCartFacade) {
    super(activeCartService);
  }
}
