import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SloanInvoiceAccountSummaryComponent } from './sloan-invoice-account-summary.component';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { KnBrCustomPipesModule } from 'src/app/shared/pipe/kn-br-custom-pipes/kn-br-custom-pipes.module';

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    KnBrCustomPipesModule,
    ConfigModule.withConfig({
      cmsComponents: {
        KnBrInvoiceHeaderComponent: {
          component: SloanInvoiceAccountSummaryComponent,
        },
      },
    } as CmsConfig),
  ],
  declarations: [SloanInvoiceAccountSummaryComponent],
})
export class SloanInvoiceAccountSummaryModule {}
