import { NgModule } from '@angular/core';
import { KnBrProfileComponentsModule } from './components/kn-br-profile.component.module';
import { UserProfileCoreModule } from '@spartacus/user/profile/core';
import { UserProfileOccModule } from '@spartacus/user/profile/occ';
import { userProfileTranslationChunksConfig, userProfileTranslations } from './assets/translations/translations';
import { provideConfig, I18nConfig } from '@spartacus/core';

@NgModule({
  imports: [KnBrProfileComponentsModule, UserProfileCoreModule, UserProfileOccModule],
  providers: [
    provideConfig(<I18nConfig>{
      i18n: {
        resources: userProfileTranslations,
        chunks: userProfileTranslationChunksConfig,
        fallbackLang: 'en',
      },
    }),
  ]
})
export class KnBrProfileModule { }
