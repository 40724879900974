import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { PageMeta, PageMetaService, ProductSearchPage } from '@spartacus/core';
import { ICON_TYPE, ProductListComponentService, ViewModes } from '@spartacus/storefront';
import { Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { KnBrProductSelectService } from 'src/feature-libs/kn-br-product/core/service/kn-br-product-select.service';
import { ProductViewModeService } from 'src/feature-libs/kn-br-product/core/service/product-view-mode-service';

@Component({
  selector: 'kn-br-product-action-bar',
  templateUrl: './kn-br-product-action-bar.component.html',
  styleUrls: ['./kn-br-product-action-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class KnBrProductActionBarComponent implements OnDestroy {
  title$: Observable<string>;
  iconTypes = ICON_TYPE;
  model$: Observable<ProductSearchPage> = this.productListComponentService.model$;
  viewMode$ = this.viewModeService.getViewMode$();
  ViewModes = ViewModes;
  selectedProductSubscription = new Subscription();
  productCodes: string[];
  selectedProductCodes: string[];
  constructor(
    protected pageMetaService: PageMetaService,
    protected productListComponentService: ProductListComponentService,
    protected viewModeService: ProductViewModeService,
    protected knBrProductService: KnBrProductSelectService
  ) {
    this.selectedProductSubscription = this.knBrProductService
      .loadSelectedProducts$()
      .subscribe((selectedProductCodes) => {
        this.selectedProductCodes = selectedProductCodes;
      });
    this.setProductCountText();
  }
  sortList(sortCode: string): void {
    this.productListComponentService.sort(sortCode);
  }
  setProductCountText(): void {
    this.title$ = this.pageMetaService.getMeta().pipe(
      filter(Boolean),
      map((meta: PageMeta) => meta.heading || meta.title)
    );
  }
  setViewMode(mode: string): void {
    this.knBrProductService.setSelectedProducts([]);
    const newMode = mode === ViewModes.Grid ? ViewModes.List : ViewModes.Grid;
    this.viewModeService.setViewMode(newMode);
  }
  ngOnDestroy(): void {
    this.selectedProductSubscription.unsubscribe();
  }
}
