import { Injectable } from '@angular/core';
import { Converter, Occ } from '@spartacus/core';
import { environment } from 'src/environments/environment';
import { KnBrB2BUser } from 'src/feature-libs/kn-br-organization/administration/core/model/kn-br-b2b-user.model';

@Injectable()
export class SloanB2BUserNormalizer implements Converter<Occ.B2BUser, KnBrB2BUser> {
  convert(source: Occ.B2BUser, target?: KnBrB2BUser): KnBrB2BUser {
    if (target === undefined) {
      target = { ...(source as any) };
    }
    target.email = source?.uid;

    //code for removing the base site id
    if (source?.uid.includes((environment.BASE_SITE[0] + '|').toLowerCase())) {
      let email = source?.uid;
      target.email = email.split((environment.BASE_SITE[0] + '|').toLowerCase())[1];
      target.displayUid = target.email;
      target.uid = target.email;
      // target.displayUid = email.split((environment.BASE_SITE[0] + '|').toLowerCase())[1];
    }

    return target;
  }
}
