import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, queueScheduler } from 'rxjs';
import { observeOn } from 'rxjs/operators';
import { StateWithKnBrInvoice } from '../../../../app/store/kn-br-invoice/kn-br-invoice.state';
import { B2bDocuments, KnBrInvoiceList, KnBrInvoiceSearchForm } from 'src/feature-libs/kn-br-order/root/models/kn-br-invoice.model';
import {
  KnBrInvoiceAccountSummary,
  KnBrInvoiceAccountSummarySuccess,
  KnBrInvoiceSearch,
  KnBrInvoiceSearchReset,
} from '../store/actions/kn-br-invoice.action';
import {
  getInvoice,
  getInvoiceAccountSummaryState,
  getInvoiceLoader,
  getInvoicePaginate,
  getInvoiceSearchCriteria,
  getInvoiceSearchDisabled,
  getInvoiceSorts,
} from '../store/selecters/kn-br-invoice.selector';

@Injectable({
  providedIn: 'root',
})
export class KnBrInvoiceService {
  constructor(private store: Store<StateWithKnBrInvoice>) { }

  list$: Observable<B2bDocuments[]> = this.store.pipe(select(getInvoice), observeOn(queueScheduler));

  loader$: Observable<boolean> = this.store.pipe(select(getInvoiceLoader), observeOn(queueScheduler));

  paginate$: Observable<any> = this.store.pipe(select(getInvoicePaginate), observeOn(queueScheduler));

  sorts$: Observable<any> = this.store.pipe(select(getInvoiceSorts), observeOn(queueScheduler));

  searchCriteria$: Observable<any> = this.store.pipe(select(getInvoiceSearchCriteria), observeOn(queueScheduler));

  accountSummary$: Observable<any> = this.store.pipe(select(getInvoiceAccountSummaryState), observeOn(queueScheduler));

  isSearchDisabled$: Observable<boolean> = this.store.pipe(select(getInvoiceSearchDisabled), observeOn(queueScheduler));

  search(searchForm: KnBrInvoiceSearchForm) {
    if ((searchForm && searchForm.currentPage === 0) || !searchForm) {
      this.reset();
    }
    this.store.dispatch(new KnBrInvoiceSearch(searchForm));
  }

  accountSummary() {
    this.store.dispatch(new KnBrInvoiceAccountSummary(null));
  }

  reset() {
    this.store.dispatch(new KnBrInvoiceSearchReset(null));
  }
}
