import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';
import { SloanCartSharedModule } from '../../sloan-cart/sloan-cart-shared/sloan-cart-shared.module';
import { SloanCheckoutOrderSummaryComponent } from './sloan-checkout-order-summary.component';

@NgModule({
  imports: [
    CommonModule,
    SloanCartSharedModule,
  ],
  declarations: [SloanCheckoutOrderSummaryComponent],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        CheckoutOrderSummary: {
          component: SloanCheckoutOrderSummaryComponent,
        },
      },
    }),
  ],
  exports: [SloanCheckoutOrderSummaryComponent],
})
export class SloanCheckoutOrderSummaryModule { }
