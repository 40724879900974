<ng-container *ngIf="!cartContext || quoteContext">
  <ng-container *ngIf="type === 'PGP' && editable">
    <div
      class="p-0 float-left border-left"
      [class.w-100]="!cartContext && quoteContext"
      [class.w-50]="!cartContext && !quoteContext"
    >
      <button
        (click)="addToQuote()"
        type="button"
        class="btn rounded-0 w-100 btn-primary"
        [disabled]="(isSaveDisabled$ | async) || !isOrderable"
        [ngbTooltip]="addToQuoteButtonTip"
      >
        <i class="fas fa-file-alt color-white font-size-18"></i>
      </button>
    </div>
  </ng-container>
  <ng-container *ngIf="type === 'PDP' && editable">
    <button
      type="button"
      class="btn color-knbr-primary w-100 pl-4 pr-4 pb-1 pt-1 color-white font-lg-size hover-link"
      (click)="addToQuote()"
      [disabled]="!hasStock || (isSaveDisabled$ | async) || !editable || !isOrderable"
    >
      <i class="fas fa-file-alt mr-2"></i>
      {{ 'quoteCommon.label.addToQuote' | cxTranslate }}
    </button>
  </ng-container>
  <ng-container *ngIf="type === 'PLP' && editable && isOrderable">
    <span
      class="m-res-0 res-cart-quote"
      [ngbTooltip]="addToQuoteButtonTip"
      [class.res-w-100]="!cartContext && quoteContext"
      [class.res-w-50]="!cartContext && !quoteContext"
    >
      <i class="fas fa-file-alt font-lg-size" (click)="addToQuote()"></i>
    </span>
  </ng-container>
  <ng-container *ngIf="type === 'ACTION' && editable">
    <div class="float-left mr-2 m-res-0">
      <a
        class="grid-icon p-2 rounded float-left d-flex align-items-center justify-content-center cursor-pointer"
        [ngbTooltip]="addToQuoteButtonTip"
        ><i class="fas fa-file-alt font-lg-size" (click)="addMultipleProducts()"></i>
      </a>
    </div>
  </ng-container>
  <ng-container *ngIf="type === 'QUICK'">
    <button
      type="button"
      (click)="addProductsToQuote()"
      [disabled]="
        (productList && productList.length < 1) || disableAddToQuote || !editable || (quickEntryloader$ | async)
      "
      class="btn btn-primary w-100"
    >
      <i class="fas fa-file-alt mr-2" aria-hidden="true"></i> {{ 'quickOrder.label.addToQuote' | cxTranslate }}
    </button>
  </ng-container>
  <ng-template #addToQuoteButtonTip>{{ 'toolTip.addToQuote' | cxTranslate }}</ng-template>
</ng-container>
