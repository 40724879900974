import { Component, ComponentRef, OnInit, ViewContainerRef, OnDestroy } from '@angular/core';
import { RoutingService } from '@spartacus/core';
import { LaunchDialogService, LAUNCH_CALLER } from '@spartacus/storefront';
import { Observable, Subscription } from 'rxjs';
import { OrderHistoryFacade } from '@spartacus/order/root';
import { OrderDetailsService } from '../kn-br-order-details.service';
import { take, takeLast, takeUntil } from 'rxjs/operators';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';

@Component({
  selector: 'knbr-kn-br-order-details-overview',
  templateUrl: './kn-br-order-details-overview.component.html',
  styleUrls: ['./kn-br-order-details-overview.component.scss'],
})
export class KnBrOrderDetailsOverviewComponent implements OnInit, OnDestroy {
  order$: Observable<any>;
  loader$: Observable<boolean> = this.knBrCommonService.loadSpinner$();
  showSpinner: void | Observable<ComponentRef<any>>;
  subscription = new Subscription();
  constructor(
    protected orderDetailsService: OrderDetailsService,
    protected routingService: RoutingService,
    protected launchDialogService: LaunchDialogService,
    protected vcr: ViewContainerRef,
    protected userOrderService: OrderHistoryFacade,
    protected knBrCommonService: KnBrCommonService
  ) {}

  ngOnInit(): void {
    this.order$ = this.userOrderService.getOrderDetails();
    this.subscription.add(
      this.loader$.subscribe((isLoading) => {
        this.onSpinnerLoading(isLoading);
      })
    );
    this.order$.pipe().subscribe(() => this.knBrCommonService.setSpinner(false));
  }

  onSpinnerLoading(isLoading) {
    if (isLoading) {
      this.showSpinner = this.launchDialogService.launch(LAUNCH_CALLER.PLACE_ORDER_SPINNER, this.vcr);
    } else {
      if (this.showSpinner) {
        this.showSpinner
          .subscribe((component) => {
            this.launchDialogService.clear(LAUNCH_CALLER.PLACE_ORDER_SPINNER);
            component.destroy();
          })
          .unsubscribe();
      }
    }
  }

  goBack() {
    this.routingService.go({ cxRoute: 'orders' });
  }

  ngOnDestroy() {
    this.launchDialogService.clear(LAUNCH_CALLER.PLACE_ORDER_SPINNER);
  }
}
