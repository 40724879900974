import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthGuard, ConfigModule, RoutingConfig } from '@spartacus/core';
import { CmsPageGuard, PageLayoutComponent } from '@spartacus/storefront';

import { KnBrDraftSearchModule } from './kn-br-draft-order-search/kn-br-draft-order-search.module';
import { KnBrDraftSearchResultModule } from './kn-br-draft-search-result/kn-br-draft-search-result.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    KnBrDraftSearchModule,
    KnBrDraftSearchResultModule,
    /*RouterModule.forChild([
      {
        data: { pageLabel: '/carts', cxRoute: 'carts' },
        path: null,
        canActivate: [AuthGuard, CmsPageGuard],
        component: PageLayoutComponent,
      },
    ]),
    ConfigModule.withConfig({
      routing: {
        routes: {
          carts: {
            paths: ['carts'],
          },
        },
      },
    } as RoutingConfig),*/
  ],
})
export class KnBrDraftOrderListModule { }
