import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[noCurlyBracesInput]',
})
export class NoCurlyBracesInputDirective {
  @HostListener('input', ['$event'])
  onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    let value = input.value.replace(/[{}]/g, ''); // Remove curly braces
    input.value = value; // Update input value without curly braces
  }
}
