export const paymentForm = {
  payment: 'Payment',
  choosePaymentMethod: 'Choose a payment method',
  paymentType: 'Payment Type',
  accountHolderName: {
    label: 'Account Holder Name',
    placeholder: 'Account Holder Name',
  },
  cardNumber: 'Card Number',
  expirationDate: 'Expiration Date',
  securityCode: 'Security Code (CVV)',
  securityCodeTitle: 'Card Verification Value',
  saveAsDefault: 'Save as default',
  setAsDefault: 'Set as default',
  billingAddress: 'Billing Address',
  sameAsShippingAddress: 'Same as shipping address',
  selectOne: 'Select One...',
  monthMask: 'MM',
  yearMask: 'YYYY',
  useThisPayment: 'Use This Payment',
  addNewPayment: 'Add New Payment',
  changePayment: 'Change Payment',
};
export const paymentMethods = {
  paymentMethods: 'Payment Methods',
  newPaymentMethodsAreAddedDuringCheckout: 'New payment methods are added during checkout.',
  invalidField: 'InvalidField: {{ field }}',
};
export const paymentCard = {
  deleteConfirmation: 'Are you sure you want to delete this payment method?',
  setAsDefault: 'Set as default',
  expires: 'Expires: {{ month }}/{{ year }}',
  defaultPaymentMethod: '✓ DEFAULT',
  selected: 'Selected',
  cardType: 'Card Type: {{ type }}',
};
export const paymentTypes = {
  title: "Payment Method",
  paymentType_CARD: "Credit Card",
  paymentType_ACCOUNT: "Account",
  noPoNumber: "None",
  poNumber: "Customer P.O.",
  costCenter: "Cost Center",
  placeholder: "Enter P.O.",
  availableLabel: "Shipping addresses available to you depend on the cost center's unit",
  deliveryDetails: "Shipping Details",
  deliveryMode: "Shipping Mode",
  shippingAccountNumber: "Collect Account Number",
  shippingNotes: "Shipping Notes"
};

export const payment = {
  paymentForm,
  paymentMethods,
  paymentCard,
  paymentTypes,
};
