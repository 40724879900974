import { Injectable } from '@angular/core';
import { ActiveCartFacade, Cart, SelectiveCartFacade } from '@spartacus/cart/base/root';
import { PageLayoutHandler } from '@spartacus/storefront';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CartAdapter } from '@spartacus/cart/base/core';
import { UserAccountService } from '@spartacus/user/account/core';
import { KnBrCartContextService } from '../../base/core/facade/kn-br-cart-context.service';
import { UserAccountFacade } from '@spartacus/user/account/root';

@Injectable({
  providedIn: 'root',
})
export class KnBrDraftOrderDetailLayoutHandler implements PageLayoutHandler {
  userId$ = this.currentUserService.get();
  cartId$ = this.knBrCartContextService.get$;
  userId: any;
  cartId: any;

  constructor(
    protected activeCartService: ActiveCartFacade,
    protected selectiveCartService: SelectiveCartFacade,
    protected currentUserService: UserAccountFacade,
    protected knBrCartContextService: KnBrCartContextService,
    protected cartAdapter: CartAdapter
  ) {
    this.userId$.subscribe((res) => {
      this.userId = res?.customerId;
    });
    this.cartId$.subscribe((response) => {
      this.cartId = response;
    });
  }

  handle(slots$: Observable<string[]>, pageTemplate?: string, section?: string) {
    if (pageTemplate === 'KnBrDraftOrderDetailPageTemplate' && !section) {
      return combineLatest([
        slots$,
        this.activeCartService.getActive(),
        !this.selectiveCartService.isStable() ? this.selectiveCartService.getCart() : of({} as Cart),
        this.activeCartService.getLoading(),
      ]).pipe(
        map(([slots, cart, selectiveCart, loadingCart]) => {
          const exclude = (arr, args) => arr.filter((item) => args.every((arg) => arg !== item));
          return Object.keys(cart).length === 0 && loadingCart
            ? exclude(slots, ['KnBrEmptyCartSlot'])
            : cart.totalItems
              ? exclude(slots, ['KnBrEmptyCartSlot'])
              : cart.totalItems
                ? exclude(slots, ['KnBrEmptyCartSlot'])
                : exclude(slots, ['KnBrDraftOrderActionBarSlot', 'KnBrDraftOrderItemsSlot']);
        })
      );
    }
    return slots$;
  }
}
