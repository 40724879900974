import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, queueScheduler } from 'rxjs';
import { observeOn } from 'rxjs/operators';
import { SloanFactSheetDetail } from './sloan-fact-sheet.action';
import { getFacSheetDataLoader, getFactSheetData } from './sloan-fact-sheet.selector';
import { StateWithSloanFactSheet } from './sloan-fact-sheet.state';

@Injectable({
  providedIn: 'root',
})
export class SloanFactSheetService {
  constructor(private store: Store<StateWithSloanFactSheet>) {}
  loading$: Observable<boolean> = this.store.pipe(select(getFacSheetDataLoader), observeOn(queueScheduler));
  factSheetData$: Observable<any> = this.store.pipe(select(getFactSheetData), observeOn(queueScheduler));

  getFactSheetData() {
    this.store.dispatch(new SloanFactSheetDetail());
  }
}
