import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigModule } from '@spartacus/core';
import { sloanUserTableConfig } from './sloan-organization-users.config';
import { UserFormService } from '@spartacus/organization/administration/components';
import {
  AdminGuard,
  B2B_USERS_NORMALIZER,
  B2B_USER_NORMALIZER,
  B2B_USER_SERIALIZER,
} from '@spartacus/organization/administration/core';
import { KnBrAdminGuard } from 'src/app/shared/guards/kn-br-admin.guard';
import { SloanUserFormService } from './sloan-user-form.service';
import { SloanB2BUserListNormalizer } from 'src/app/core/user/connectors/sloan/sloan-b2b-user-list-normalizer';
import { SloanB2BUserNormalizer } from 'src/app/core/user/connectors/sloan/sloan-b2b-user.converter';
import { SloanB2BUserSerializer } from 'src/app/serializer/sloan/sloan-b2b-user.serializer';
import { KnBrUserFormModule } from 'src/feature-libs/kn-br-organization/administration/components/users/kn-br-add-user-form/kn-br-user-form.module';
import { KnBrUserDetailsModule } from 'src/feature-libs/kn-br-organization/administration/components/users/kn-br-user-details/kn-br-user-details.module';
import { SloanOrganizationComponentsModule } from './components/sloan-organization-componets.module';
import { knBrUserCmsConfig, knBrUserTableConfig } from 'src/feature-libs/kn-br-organization/administration/components/users/user.config';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    KnBrUserDetailsModule,
    KnBrUserFormModule,
    SloanOrganizationComponentsModule,
    ConfigModule.withConfig(sloanUserTableConfig),
    ConfigModule.withConfig(knBrUserTableConfig),
    ConfigModule.withConfig(knBrUserCmsConfig),
  ],
  providers: [
    {
      provide: UserFormService,
      useClass: SloanUserFormService,
    },
    {
      provide: AdminGuard,
      useClass: KnBrAdminGuard,
    },
    {
      provide: B2B_USER_NORMALIZER,
      useClass: SloanB2BUserNormalizer,
      multi: true,
    },
    {
      provide: B2B_USER_SERIALIZER,
      useClass: SloanB2BUserSerializer,
      multi: true,
    },
    {
      provide: B2B_USERS_NORMALIZER,
      useClass: SloanB2BUserListNormalizer,
      multi: true,
    },
  ],
})
export class SloanOrganizationUsersModule { }
