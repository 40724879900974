import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CmsConfig, ConfigModule, I18nModule, RoutingModule, UrlModule } from '@spartacus/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormErrorsModule, SpinnerModule } from '@spartacus/storefront';
import { NgSelectModule } from '@ng-select/ng-select';
import { SloanDraftOrderDetailHeaderComponent } from './sloan-draft-order-detail-header.component';
import { KnBrDirectiveModule } from 'src/app/shared/directive/kn-br-directive.module';

@NgModule({
  declarations: [SloanDraftOrderDetailHeaderComponent],
  imports: [
    CommonModule,
    RouterModule,
    I18nModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    RoutingModule,
    UrlModule,
    FormErrorsModule,
    KnBrDirectiveModule,
    ConfigModule.withConfig({
      cmsComponents: {
        KnBrDraftOrderHeaderComponent: {
          component: SloanDraftOrderDetailHeaderComponent,
        },
        KnBrDraftOrderHeaderComponentReadOnly: {
          component: SloanDraftOrderDetailHeaderComponent,
        },
      },
    } as CmsConfig),
    NgSelectModule,
    SpinnerModule,
  ],
  exports: [SloanDraftOrderDetailHeaderComponent],
  providers: [DatePipe],
})
export class SloanDraftOrderDetailHeaderModule {}
