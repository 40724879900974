import { NgModule } from '@angular/core';
import { SloanAsmModule } from 'src/feature-libs/sloan/sloan-asm/sloan-asm.module';
import { SloanDraftOrderModule } from 'src/feature-libs/sloan/sloan-cart/sloan-draft-order/sloan-draft-order.module';
import { SloanOrderToolsModule } from 'src/feature-libs/sloan/sloan-cart/sloan-order-tools/sloan-order-tools.module';
import { SloanQuoteModule } from 'src/feature-libs/sloan/sloan-cart/sloan-quote/sloan-quote.module';
import { SloanCustomer360Module } from 'src/feature-libs/sloan/sloan-order/sloan-customer360/sloan-customer360.module';
import { SloanOrderConfirmationModule } from 'src/feature-libs/sloan/sloan-order/sloan-order-confirmation/sloan-order-confirmation.module';
import { SloanOrganizationUsersModule } from 'src/feature-libs/sloan/sloan-organization/sloan-organization-users.module';
import { SloanProductModule } from 'src/feature-libs/sloan/sloan-product/sloan-product.module';
import { SloanLoginApproverModule } from 'src/feature-libs/sloan/sloan-user/sloan-account/sloan-login-approver/sloan-login-approver.module';
import { SloanLoginModule } from 'src/feature-libs/sloan/sloan-user/sloan-account/sloan-login/sloan-login.module';
import { SloanForgotPasswordModule } from 'src/feature-libs/sloan/sloan-user/sloan-profile/sloan-forgot-password/sloan-forgot-password.module';
import { SloanResetPasswordModule } from 'src/feature-libs/sloan/sloan-user/sloan-profile/sloan-reset-password/sloan-reset-password.module';
import { SloanBannerModule } from '../common/sloan/sloan-banner/sloan-banner.module';
import { SloanFooterModule } from '../common/sloan/sloan-footer/sloan-footer.module';
import { SloanCategoryNavigationModule } from '../sloan-libs/sloan-category-navigation/sloan-category-navigation.module';
import { SloanFactSheetStateModule } from '../store/sloan/sloan-fact-sheet/sloan-fact-sheet.state.module';
import { SloanQuotesStateModule } from '../store/sloan/sloan-quotes/sloan-quotes.state.module';
import { SloanConfigurationModule } from './sloan-configuration.module';
import { TagManagementFeatureModule } from '../spartacus/features/tracking/tag-management-feature.module';
import { SloanCheckoutModule } from 'src/feature-libs/sloan/sloan-checkout/sloan-checkout.module';

@NgModule({
  imports: [
    SloanConfigurationModule,
    SloanLoginModule,
    SloanFooterModule,
    SloanForgotPasswordModule,
    SloanResetPasswordModule,
    SloanCustomer360Module,
    SloanFactSheetStateModule,
    SloanCheckoutModule,
    SloanProductModule,
    SloanDraftOrderModule,
    SloanOrderToolsModule,
    SloanCategoryNavigationModule,
    TagManagementFeatureModule,
    SloanQuoteModule,
    SloanOrderConfirmationModule,
    SloanBannerModule,
    SloanLoginApproverModule,
    SloanQuotesStateModule,
    SloanOrganizationUsersModule,
    SloanAsmModule,
  ],
})
export class SloanModule {}
