import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { OrderConfirmationItemsComponent } from '@spartacus/order/components';
import { OrderFacade } from '@spartacus/order/root';

@Component({
  selector: 'cx-order-confirmation-items',
  templateUrl: './sloan-order-confirmation-items.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SloanOrderConfirmationItemsComponent extends OrderConfirmationItemsComponent implements OnInit, OnDestroy {
  constructor(protected orderFacade: OrderFacade) {
    super(orderFacade);
  }

  ngOnInit() {
    // super.ngOnInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
