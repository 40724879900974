import { NgModule, Type } from '@angular/core';
import { CheckoutB2BRootModule } from '@spartacus/checkout/b2b/root';
import { CheckoutRootModule, CHECKOUT_FEATURE } from '@spartacus/checkout/base/root';
import { provideConfig } from '@spartacus/core';
import { CheckoutScheduledReplenishmentRootModule } from '@spartacus/checkout/scheduled-replenishment/root';
import {
  checkoutScheduledReplenishmentTranslationChunksConfig,
  checkoutScheduledReplenishmentTranslations,
} from '@spartacus/checkout/scheduled-replenishment/assets';
import { checkoutB2BTranslationChunksConfig, checkoutB2BTranslations } from '@spartacus/checkout/b2b/assets';
import { checkoutTranslationChunksConfig, checkoutTranslations } from '@spartacus/checkout/base/assets';
import { knbrCheckoutTranslationChunksConfig, knbrCheckoutTranslations } from 'src/feature-libs/kn-br-checkout/base/assets/translation/translation';

@NgModule({
  imports: [CheckoutRootModule, CheckoutB2BRootModule, CheckoutScheduledReplenishmentRootModule],
  providers: [
    provideConfig({
      featureModules: {
        [CHECKOUT_FEATURE]: {
          module: () => import('./checkout-wrapper.module').then((m) => m.CheckoutWrapperModule),
        },
      },
    }),
    provideConfig({
      i18n: {
        resources: checkoutB2BTranslations,
        chunks: checkoutB2BTranslationChunksConfig,
      },
    }),
    provideConfig({
      i18n: {
        resources: checkoutScheduledReplenishmentTranslations,
        chunks: checkoutScheduledReplenishmentTranslationChunksConfig,
      },
    }),
    provideConfig({
      i18n: {
        resources: checkoutTranslations,
        chunks: checkoutTranslationChunksConfig,
      },
    }),
    provideConfig({
      i18n: {
        resources: knbrCheckoutTranslations,
        chunks: knbrCheckoutTranslationChunksConfig,
      },
    }),
  ],
})
export class CheckoutFeatureModule { }
