import { Injectable } from '@angular/core';
import { AuthConfigService, GlobalMessageService, RoutingService } from '@spartacus/core';
import { ForgotPasswordComponentService } from '@spartacus/user/profile/components';
import { UserPasswordFacade } from '@spartacus/user/profile/root';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SloanForgotPasswordComponentService extends ForgotPasswordComponentService {
  constructor(
    protected userPasswordService: UserPasswordFacade,
    protected routingService: RoutingService,
    protected authConfigService: AuthConfigService,
    protected globalMessage: GlobalMessageService
  ) {
    super(userPasswordService, routingService, authConfigService, globalMessage);
  }

  requestEmail() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this.busy$.next(true);

    this.userPasswordService
      .requestForgotPasswordEmail(environment.BASE_SITE[0] + '|' + this.form.value.userEmail)
      .subscribe({
        next: () => this.onSuccess(),
        error: (error: Error) => this.onError(error),
      });
  }
}
