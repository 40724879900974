/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { FeaturesConfigModule, I18nModule } from '@spartacus/core';
import { CardModule, FormErrorsModule, IconModule, SpinnerModule, NgSelectA11yModule } from '@spartacus/storefront';
import { KnBrCheckoutPaymentFormComponent } from './kn-br-checkout-payment-form.component';

@NgModule({
  /**
   * TODO: (#CXSPA-53) Remove feature config check in 6.0
   */
  imports: [
    NgSelectA11yModule,
    CommonModule,
    ReactiveFormsModule,
    NgSelectModule,
    CardModule,
    I18nModule,
    IconModule,
    SpinnerModule,
    FormErrorsModule,
    FeaturesConfigModule,
  ],
  declarations: [KnBrCheckoutPaymentFormComponent],
  exports: [KnBrCheckoutPaymentFormComponent],
})
export class KnBrCheckoutPaymentFormModule {}
