import { SloanFactSheetData } from 'src/app/models/sloan/sloan-fact-sheet.model';

export const SLOAN_FACT_SHEET_FEATURE = 'sloan-fact-sheet';

export interface SloanFactSheetState {
  factSheetData: SloanFactSheetData;
  error?: Error;
  isLoading?: boolean;
}

export interface StateWithSloanFactSheet {
  [SLOAN_FACT_SHEET_FEATURE]: SloanFactSheetState;
}
