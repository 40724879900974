import { Component, OnInit } from '@angular/core';
import { RoutingService } from '@spartacus/core';
import { OrderReturnRequestService } from '@spartacus/order/core';
import { KnBrReturnOrderDetailsItemsComponent } from 'src/feature-libs/kn-br-order/components/kn-br-customer360/kn-br-return-order-detail/kn-br-return-order-details-items/kn-br-return-order-details-items.component';
@Component({
  selector: 'knbr-sloan-return-order-details-items',
  templateUrl: './sloan-return-order-details-items.component.html',
  styleUrls: ['./sloan-return-order-details-items.component.scss'],
})
export class SloanReturnOrderDetailsItemsComponent extends KnBrReturnOrderDetailsItemsComponent implements OnInit {
  constructor(protected returnRequestService: OrderReturnRequestService, protected routingService: RoutingService) {
    super(returnRequestService, routingService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
}
