import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { PageComponentModule, PageLayoutModule, PageSlotModule } from '@spartacus/storefront';
import { SloanLoginApproverLayoutConfigModule } from './sloan-login-approver.config';
import { SloanLoginApproverComponent } from './sloan-login-approver.component';

@NgModule({
  declarations: [SloanLoginApproverComponent],
  imports: [
    CommonModule,
    I18nModule,
    PageComponentModule,
    PageLayoutModule,
    PageSlotModule,
    SloanLoginApproverLayoutConfigModule,
    ConfigModule.withConfig({
      cmsComponents: {
        KnBrSellerApproverMessageComponent: {
          component: SloanLoginApproverComponent,
        },
      },
    } as CmsConfig),
  ],
})
export class SloanLoginApproverModule {}
