<div class="customersearch-list-table float-left col-lg-12 p-0 mt-3" *ngIf="factSheetData$ | async as factSheetData">
  <div
    class="customersearch-list-titles border-top float-left w-100 p-3 d-none d-sm-none d-lg-block"
    *ngIf="factSheetData?.prices && factSheetData?.prices.length"
  >
    <div class="col-md-6 padding-res-0 float-left font-text-600 pl-0 font-size">
      {{ 'factSheetGroup.header.materialGroup' | cxTranslate }}
    </div>
    <div class="col-md-6 padding-res-0 float-left font-text-600 pl-0 font-size">
      {{ 'factSheetGroup.header.multiplier' | cxTranslate }}
    </div>
  </div>

  <div
    class="
      bg-white
      customersearch-list-titles
      table-content
      border-top
      float-left
      w-100
      pt-2
      pb-2
      pl-3
      pr-3
      font-weight-normal
      d-flex
      align-items-center
    "
    *ngFor="let priceList of factSheetData?.prices"
  >
    <div class="col-md-6 float-left title-wrap pl-0 font-size color-font">
      <span class="res-titles"> {{ 'factSheetGroup.header.materialGroup' | cxTranslate }}</span>
      {{ priceList.matGrpDesc }}
    </div>
    <div class="col-md-6 float-left title-wrap pl-0 font-size color-font">
      <span class="res-titles"> {{ 'factSheetGroup.header.multiplier' | cxTranslate }}</span>
      {{ priceList.rate }}
    </div>
  </div>
</div>
