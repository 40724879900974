import { RouterModule } from '@angular/router';
import { OutletModule, StarRatingModule, MediaModule, CarouselModule } from '@spartacus/storefront';
import { ConfigModule, CmsConfig, I18nModule } from '@spartacus/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SloanProductIntroComponent } from './sloan-product-intro/sloan-product-intro.component';
import { SloanProductSummaryComponent } from './sloan-product-summary/sloan-product-summary.component';
import { SloanProductAttributesComponent } from './sloan-product-attributes/sloan-product-attributes.component';
import { SloanProductCertificationsTabComponent } from './sloan-product-certifications-tab/sloan-product-certifications-tab.component';
import { SloanProductDetailsTabComponent } from './sloan-product-details-tab/sloan-product-details-tab.component';
import { SloanProductFeaturesComponent } from './sloan-product-features/sloan-product-features.component';
import { SloanProductTechnicalDocumentsComponent } from './sloan-product-technical-documents/sloan-product-technical-documents.component';

@NgModule({
  declarations: [
    SloanProductIntroComponent,
    SloanProductSummaryComponent,
    SloanProductAttributesComponent,
    SloanProductDetailsTabComponent,
    SloanProductCertificationsTabComponent,
    SloanProductFeaturesComponent,
    SloanProductTechnicalDocumentsComponent,
  ],
  imports: [
    CommonModule,
    I18nModule,
    StarRatingModule,
    RouterModule,
    MediaModule,
    OutletModule,
    CarouselModule,
    ConfigModule.withConfig({
      cmsComponents: {
        KnBrProductIntroComponent: {
          component: SloanProductIntroComponent,
        },
        KnBrProductSummaryComponent: {
          component: SloanProductSummaryComponent,
        },
        ProductSpecsTabComponent: {
          component: SloanProductAttributesComponent,
        },
        ProductDetailsTabComponent: {
          component: SloanProductDetailsTabComponent,
        },
        ProductCertificationsTabComponent: {
          component: SloanProductCertificationsTabComponent,
        },
        ProductFeaturesComponent: {
          component: SloanProductFeaturesComponent,
        },
        ProductTechnicalDocumentsTabComponent: {
          component: SloanProductTechnicalDocumentsComponent,
        },
      },
    } as CmsConfig),
  ],
})
export class SloanProductDetailModule {}
