import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CmsBreadcrumbsComponent, PageMetaService, TranslationService } from '@spartacus/core';
import { BreadcrumbComponent, CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';

@Component({
  selector: 'kn-br-bread-crumb',
  templateUrl: './kn-br-bread-crumb.component.html',
  styleUrls: ['./kn-br-bread-crumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KnBrBreadCrumbComponent extends BreadcrumbComponent implements OnInit {
  componentData$: Observable<CmsBreadcrumbsComponent> = this.componentData.data$;
  constructor(
    private componentData: CmsComponentData<CmsBreadcrumbsComponent>,
    pageMetaService: PageMetaService,
    translation: TranslationService
  ) {
    super(componentData, pageMetaService, translation);
  }
  ngOnInit(): void {
    super.ngOnInit();
  }
}
