<div [formGroup]="mode">
  <div class="row">
    <div class="col-md-12 col-lg-9">
      <ng-container *ngIf="(supportedDeliveryModes$ | async)?.length; else loading">
        <!-- <div class="form-check" *ngFor="let mode of supportedDeliveryModes$ | async">
          <input
            class="form-check-input"
            role="radio"
            type="radio"
            id="deliveryMode-{{ mode.code }}"
            aria-checked="true"
            (change)="changeMode(mode.code)"
            [value]="mode.code"
            formControlName="deliveryModeId"
          />
          <label class="cx-delivery-label form-check-label form-radio-label" for="deliveryMode-{{ mode.code }}">
            <div class="cx-delivery-mode">{{ mode.name }}</div>
            <div class="cx-delivery-price">
              {{ mode.deliveryCost.formattedValue }}
            </div>
            <div class="cx-delivery-details">{{ mode.description }}</div>
          </label>
        </div> -->

        <div class="col-md-12 p-0">
          <label class="mt-3 mb-3">
            <span class="label-content">
              {{ 'sloanCheckoutMode.deliveryMethod' | cxTranslate }}<span class="text-danger ml-1">*</span>
            </span>
            <ng-select
              [items]="supportedDeliveryModes$ | async"
              formControlName="deliveryModeId"
              bindLabel="name"
              bindValue="code"
              [searchable]="false"
              [clearable]="false"
              (change)="changeMode($event)"
            >
            </ng-select>
          </label>
        </div>

        <div class="col-md-12 p-0" *ngIf="showAccountNumber">
          <label class="mb-3">
            <span class="label-content">
              {{ 'deliveryMode.accountNumberMandatory' | cxTranslate }}<span class="text-danger ml-1">*</span>
            </span>
            <input class="form-control" type="text" formControlName="additionalAccountNumber" />
            <cx-form-errors [control]="mode.get('additionalAccountNumber')"></cx-form-errors>
          </label>
        </div>
        <div class="col-md-12 p-0">
          <label class="mb-3">
            <span class="label-content">{{ 'deliveryMode.deliveryNote' | cxTranslate }}</span>
            <textarea class="form-control" formControlName="shippingNotes" knBrAlphaNumericOnly></textarea>
          </label>
        </div>
      </ng-container>
    </div>
  </div>

  <ng-container *ngIf="!continueButtonPressed; else loading">
    <div class="row cx-checkout-btns">
      <div class="col-md-12 col-lg-6">
        <button class="btn btn-block btn-action" (click)="back()">
          {{ backBtnText | cxTranslate }}
        </button>
      </div>
      <div class="col-md-12 col-lg-6">
        <button class="btn btn-block btn-primary" [disabled]="deliveryModeInvalid" (click)="next()">
          {{ 'common.continue' | cxTranslate }}
        </button>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #loading>
  <div class="cx-spinner">
    <cx-spinner></cx-spinner>
  </div>
</ng-template>
