import { NgModule } from '@angular/core';
import { AsmAuthHttpHeaderService, AsmAuthService, AsmAuthStorageService, AsmLoaderModule } from '@spartacus/asm/root';
import {
  AuthHttpHeaderService,
  AuthService,
  AuthStorageService,
} from '@spartacus/core';

@NgModule({
  imports: [AsmLoaderModule],
  providers: [
    {
      provide: AuthStorageService,
      useExisting: AsmAuthStorageService,
    },
    {
      provide: AuthService,
      useExisting: AsmAuthService,
    },
    {
      provide: AuthHttpHeaderService,
      useExisting: AsmAuthHttpHeaderService,
    },
  ],
})
export class AsmRootModule { }
