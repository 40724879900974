import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RoutingService } from '@spartacus/core';
import { MultiCartFacade } from '@spartacus/cart/base/root';
import * as _ from 'lodash';
import { SloanCalendarHelper } from 'src/app/services/sloan/sloan-calendar-help.service';
import { SloanCommonService } from 'src/app/services/sloan/sloan-common.service';
import { KnBrDateHelper } from 'src/app/shared/kn-br-date.helper';
import { KnBrCustomerContextService } from 'src/app/store/kn-br-customer-context/kn-br-customer-context.service';
import { KnBrSelectCartComponent } from 'src/feature-libs/kn-br-product/components/kn-br-add-to-cart/kn-br-select-cart/kn-br-select-cart.component';
import { KnBrCartContextService } from 'src/feature-libs/kn-br-cart/base/core/facade/kn-br-cart-context.service';
import { KnBrDraftOrdersService } from 'src/feature-libs/kn-br-cart/draft-order/core/facade/kn-br-draft-orders.service';
import { KnBrQuoteReferenceService } from 'src/feature-libs/kn-br-cart/quote/core/facade/kn-br-quote-reference.service';

@Component({
  selector: 'kn-br-select-cart',
  templateUrl: './sloan-select-cart.component.html',
})
export class SloanSelectCartComponent extends KnBrSelectCartComponent implements OnInit, OnDestroy {
  customerNumber;
  today: NgbDate = this.sloanCalendarHelper.today;
  rddMinDate: NgbDate;
  rddMaxDate: NgbDate;
  shipCompelete = false;
  constructor(
    protected modalService: NgbModal,
    protected routingService: RoutingService,
    protected knBrDraftOrderListService: KnBrDraftOrdersService,
    protected multiCartService: MultiCartFacade,
    protected knBrCartContextService: KnBrCartContextService,
    protected knBrCustomerContextService: KnBrCustomerContextService,
    protected knBrQuoteReferenceService: KnBrQuoteReferenceService,
    protected sloanCommonService: SloanCommonService,
    protected sloanCalendarHelper: SloanCalendarHelper,
    protected knBrDateHelper: KnBrDateHelper
  ) {
    super(
      modalService,
      routingService,
      knBrDraftOrderListService,
      multiCartService,
      knBrCartContextService,
      knBrCustomerContextService,
      knBrQuoteReferenceService
    );
    this.sloanCommonService.getSloanHolidayList();
    this.rddMinDate = this.sloanCalendarHelper.getRddMinDate(this.today);
    this.rddMaxDate = this.sloanCalendarHelper.getRddMaxDate(this.today);
  }

  goToCreateNewCart() {
    if (this.knBrQuoteReferenceService.pdpQuoteRef) {
      this.knBrCartContextService.pdpQuoteRef = _.cloneDeep(this.knBrQuoteReferenceService.pdpQuoteRef);
    }
    if (this.productList && this.productList.length > 0) {
      const createCartReq = {
        customerNumber: this.customerNumber,
        requestedDeliveryDate: this.knBrDateHelper.formatDate(this.sloanCalendarHelper.rddDefaultDate(this.today)),
        purchaseOrderNumber: '',
        jobName: '',
        shipComplete: this.shipCompelete,
        headerComments: '',
        referenceCartId: null,
        orderToolsProducts: this.productList,
      };
      this.knBrDraftOrderListService.create(createCartReq);
    } else {
      this.routingService.go({ cxRoute: 'createCart' }, { queryParams: { p: this.productCode, q: this.quantity } });
    }
    this.dismissModal();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.subscription = this.customerContextData$.subscribe((value: any) => {
      if (value.customerNumber) {
        this.customerNumber = value.customerNumber;
      }
      if (value && value.hasOwnProperty('dropShipFlag')) {
        this.shipCompelete = value.dropShipFlag;
      }
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
