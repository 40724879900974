import { Component, OnDestroy, OnInit } from '@angular/core';
import { RoutingService } from '@spartacus/core';
import { KnBrProductSelectService } from 'src/feature-libs/kn-br-product/core/service/kn-br-product-select.service';
import { KnBrProductGridItemComponent } from 'src/feature-libs/kn-br-product/components/kn-br-product-list/kn-br-product-grid-item/kn-br-product-grid-item.component';

@Component({
  selector: 'kn-br-product-grid-item',
  templateUrl: './sloan-product-grid-item.component.html',
  styleUrls: ['./sloan-product-grid-item.component.scss'],
})
export class SloanProductGridItemComponent extends KnBrProductGridItemComponent implements OnDestroy {
  constructor(protected productSelectService: KnBrProductSelectService, protected routingService: RoutingService) {
    super(productSelectService, routingService);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  addProductCode() {
    if (
      this.product.isOrderable &&
      this.product.price &&
      (this.product.price.formattedPrice || this.product.price.formattedValue)
    ) {
      if (this.selectedProductsCodes && this.selectedProductsCodes.length) {
        this.selectedProductsCodes.push(this.product.code);
      } else {
        this.selectedProductsCodes = [this.product.code];
      }
    }
    return this.selectedProductsCodes;
  }
}
