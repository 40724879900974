import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { GlobalMessageService, GlobalMessageType, UserAddressService } from '@spartacus/core';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { KnBrCustomerContextService } from 'src/app/store/kn-br-customer-context/kn-br-customer-context.service';
import {
  KnBrAddressActionTypes,
  KnBrOneTimeAddressFailure,
  KnBrOneTimeAddressSuccess,
  KnBrOrderReturnActions,
} from '../actions/kn-br-address.action';
import { KnBrAddressAdapter } from '../../connectors/kn-br-address.adapter';
import { CheckoutDeliveryAddressFacade } from '@spartacus/checkout/base/root';
import { KnBrCartContextService } from 'src/feature-libs/kn-br-cart/base/core/facade/kn-br-cart-context.service';

@Injectable()
export class KnBrAddressEffects {

  onetimeAddress$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrAddressActionTypes.KnBrOneTimeAddressAction),
    mergeMap((action) =>
      this.knbrAddressAdapter.oneTimeAdress(action.payload).pipe(
        mergeMap((data: any) => {
          return [new KnBrOneTimeAddressSuccess(data)];
        }),
        catchError((error: Error) => {
          this.messageService.add({ key: 'knBrMessages.error' }, GlobalMessageType.MSG_TYPE_ERROR);
          return of(new KnBrOneTimeAddressFailure(error));
        })
      )
    )
  )
  );

  oneTimeAddressSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrAddressActionTypes.KnBrOneTimeAddressSuccessAction),
    map((action) => {
      this.userAddressService.loadAddresses();
      this.messageService.add({ key: 'knbrAddressForm.onetime.successMsg' }, GlobalMessageType.MSG_TYPE_CONFIRMATION);
      this.checkoutDeliveryFacade.setDeliveryAddress(action?.payload);
    })
  ),
    { dispatch: false }
  );

  onetimeAddressFailure$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrAddressActionTypes.KnBrOneTimeAddressFailureAction),
    map((action) => {
      this.messageService.add({ key: 'knBrMessages.error' }, GlobalMessageType.MSG_TYPE_ERROR);
    })
  ),
    { dispatch: false }
  );

  customerNumber;
  cartId;
  constructor(
    private actions$: Actions<KnBrOrderReturnActions>,
    private knbrAddressAdapter: KnBrAddressAdapter,
    private messageService: GlobalMessageService,
    private userAddressService: UserAddressService,
    private checkoutDeliveryFacade: CheckoutDeliveryAddressFacade,
    protected knBrCustomerContextService: KnBrCustomerContextService,
    protected knBrCartContextService: KnBrCartContextService
  ) {
    this.knBrCustomerContextService.getActiveCustomerNumber().subscribe((response) => {
      this.customerNumber = response ? response : null;
    });
    this.knBrCartContextService.get$.subscribe((response) => {
      this.cartId = response ? response : null;
    });
  }
}
