import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CmsOrderDetailOverviewComponent, PaymentDetails, TranslationService } from '@spartacus/core';
import { Card, CmsComponentData } from '@spartacus/storefront';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { RemoveLeadingZerosPipe } from 'src/app/shared/pipe/kn-br-custom-pipes/remove-leading-zeros.pipe';
import { KnBrRemoveBaseSiteIdPipe } from 'src/app/shared/pipe/kn-br-custom-pipes/kn-br-remove-base-site-id.pipe';
import { KnBrOrderOverviewComponent } from 'src/feature-libs/kn-br-order/components/kn-br-order-confirmation/kn-br-order-overview/kn-br-order-overview.component';
import { DeliveryMode } from '@spartacus/cart/base/root';
import { SloanAddress } from 'src/app/models/sloan/sloan-order.model';
import { OrderDetailsService } from '@spartacus/order/components';

@Component({
  selector: 'cx-order-overview',
  templateUrl: './sloan-order-overview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RemoveLeadingZerosPipe, KnBrRemoveBaseSiteIdPipe],
})
export class SloanOrderOverviewComponent extends KnBrOrderOverviewComponent {
  constructor(
    protected translation: TranslationService,
    protected removeLeadingZerosPipe: RemoveLeadingZerosPipe,
    protected removeBaseSiteIdPipe: KnBrRemoveBaseSiteIdPipe,
    protected orderDetailsService: OrderDetailsService,
    protected component: CmsComponentData<CmsOrderDetailOverviewComponent>
  ) {
    super(translation, orderDetailsService, component);
  }

  getOrderCodeCardContent(orderCode: string): Observable<Card> {
    return this.translation.translate('checkoutOrderConfirmation.orderNumber').pipe(
      filter(() => Boolean(orderCode)),
      map((textTitle) => ({
        title: textTitle,
        text: [this.removeLeadingZerosPipe.transform(orderCode)],
      }))
    );
  }

  getOrderTypeCardContent(orderType: string): Observable<Card> {
    return this.translation.translate('checkoutOrderConfirmation.orderType').pipe(
      map((textTitle) => ({
        title: textTitle,
        text: [orderType],
      }))
    );
  }

  getJobNameCardContent(jobName: string): Observable<Card> {
    return this.translation.translate('checkoutOrderConfirmation.jobName').pipe(
      map((textTitle) => ({
        title: textTitle,
        text: [jobName],
      }))
    );
  }

  getOrderPlacedByCardContent(placedBy: string): Observable<Card> {
    return this.translation.translate('checkoutOrderConfirmation.placedBy').pipe(
      map((textTitle) => ({
        title: textTitle,
        text: [this.removeBaseSiteIdPipe.transform(placedBy)],
      }))
    );
  }

  getSoldToAddressCardContent(soldTo: any): Observable<Card> {
    return this.translation.translate('checkoutOrderConfirmation.soldTo').pipe(
      filter(() => Boolean(soldTo)),
      map((textTitle) => ({
        title: textTitle,
        textBold: this.removeLeadingZerosPipe.transform(soldTo.customerNumber) + ', ' + soldTo.customerName,
        text: [
          soldTo.street,
          (soldTo.city ? soldTo.city + ', ' : '') + soldTo.state,
          soldTo.country + ', ' + soldTo.postalCode,
          soldTo.telephone,
        ],
      }))
    );
  }

  getOrderCurrentDateCardContent(isoDate?: string): Observable<Card> {
    return this.translation.translate('checkoutOrderConfirmation.placedOn').pipe(
      map((textTitle) => {
        return {
          title: textTitle,
          text: [isoDate],
        };
      })
    );
  }

  getOrderReqestedDeliveryDateCardContent(isoDate?: string): Observable<Card> {
    return this.translation.translate('checkoutOrderConfirmation.reqDeliveryDate').pipe(
      map((textTitle) => {
        return {
          title: textTitle,
          text: [isoDate],
        };
      })
    );
  }

  getDeliveryModeCardContent(deliveryMode: DeliveryMode): Observable<Card> {
    return this.translation.translate('checkoutOrderConfirmation.shippingMethod').pipe(
      filter(() => Boolean(deliveryMode)),
      map((textTitle) => ({
        title: textTitle,
        textBold: deliveryMode.name,
        // text: [
        //   deliveryMode.description,
        //   deliveryMode.deliveryCost?.formattedValue
        //     ? deliveryMode.deliveryCost?.formattedValue
        //     : '',
        // ],
      }))
    );
  }

  getOrderStatusCardContent(status: string): Observable<Card> {
    return this.translation.translate('checkoutOrderConfirmation.status').pipe(
      map((textTitle) => ({
        title: textTitle,
        text: [status],
      }))
    );
  }

  getPaymentInfoCardContent(payment: PaymentDetails): Observable<Card> {
    return combineLatest([
      this.translation.translate('paymentForm.payment'),
      this.translation.translate('paymentCard.expires', {
        month: Boolean(payment) ? payment.expiryMonth : '',
        year: Boolean(payment) ? payment.expiryYear : '',
      }),
      this.translation.translate('paymentCard.cardType', {
        type: Boolean(payment) ? payment.cardType.name : '',
      }),
    ]).pipe(
      filter(() => Boolean(payment)),
      map(([textTitle, textExpires, textCardType]) => ({
        title: textTitle,
        textBold: payment.accountHolderName,
        text: [textCardType, textExpires],
      }))
    );
  }

  getAddressCardContent(deliveryAddress: SloanAddress): Observable<Card> {
    return this.translation.translate('addressCard.shipTo').pipe(
      filter(() => Boolean(deliveryAddress)),
      map((textTitle) => ({
        title: textTitle,
        textBold: deliveryAddress.firstName + (deliveryAddress.lastName ? ' ' + deliveryAddress.lastName : ''),
        text: [
          (deliveryAddress.line2 ? deliveryAddress.line2 + ', ' : '') + deliveryAddress.line1,
          deliveryAddress.department,
          deliveryAddress.town + ', ' + deliveryAddress.region.name,
          deliveryAddress.country.name + ', ' + deliveryAddress.postalCode,
          deliveryAddress.phone,
        ],
      }))
    );
  }
  getOrderShipComplete(shipComplete?: string): Observable<Card> {
    return this.translation.translate('checkoutOrderConfirmation.shipComplete').pipe(
      map((textTitle) => {
        return {
          title: textTitle,
          text: [shipComplete],
        };
      })
    );
  }
}
