import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthGuard, CmsConfig, provideConfig, provideConfigFactory } from '@spartacus/core';
import { CmsPageGuard, PageLayoutComponent } from '@spartacus/storefront';
import { KnBrCustomerContextGuard } from 'src/app/shared/guards/kn-br-customer-context.guard';
import { QUOTE_CORE_FEATURE, QUOTE_FEATURE } from './feature-name';
import { defaultSavedCartRoutingConfig } from './config/default-saved-cart-routing-config';

export function cartSavedCartComponentsConfig(): CmsConfig {
  const config: CmsConfig = {
    featureModules: {
      [QUOTE_FEATURE]: {
        cmsComponents: [
          'KnBrQuoteSearchComponent',
          'KnBrQuoteSearchResultComponent',
          'KnBrQuoteCreateComponent',
          'KnBrProductQuickEntryComponent',
          'KnBrQuoteAttachmentLinkComponent',
          'KnBrQuoteDeleteItemsComponent',
          'KnBrQuoteDownloadLinkComponent',
          'KnBrQuoteHeaderComponent',
          'KnBrQuoteHeaderComponentReadOnly',
          'KnBrQuoteItemsComponent',
          'KnBrQuoteItemsComponentReadOnly',
          'QuoteSaveButton'
        ]
      }
    }
  };
  return config;
}

@NgModule({
  imports: [
    RouterModule.forChild([
    {
      path: null,
      canActivate: [AuthGuard, CmsPageGuard],
      component: PageLayoutComponent,
      data: { pageLabel: '/create-quote', cxRoute: 'createQuote' },
    },
    {
      path: null,
      canActivate: [AuthGuard, CmsPageGuard, KnBrCustomerContextGuard],
      component: PageLayoutComponent,
      data: { pageLabel: '/quote-detail', cxRoute: 'quoteDetails' },
    },
    {
      path: null,
      canActivate: [AuthGuard, CmsPageGuard],
      component: PageLayoutComponent,
      data: { pageLabel: '/quotes', cxRoute: 'quotes' },
    }
  ])],
  providers: [
    provideConfigFactory(cartSavedCartComponentsConfig),
    provideConfig(defaultSavedCartRoutingConfig),
  ],
})
export class KnBrQuoteRootModule {}
