import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  AuthConfigService,
  GlobalMessageService,
  RoutingService
} from '@spartacus/core';
import { ForgotPasswordComponentService } from '@spartacus/user/profile/components';
import { UserPasswordFacade } from '@spartacus/user/profile/root';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class KnBrForgotPasswordComponentService extends ForgotPasswordComponentService {
  forGotpasswordMsg$: BehaviorSubject<string> = new BehaviorSubject(null);
  constructor(
    protected userPasswordService: UserPasswordFacade,
    protected routingService: RoutingService,
    protected authConfigService: AuthConfigService,
    protected globalMessage: GlobalMessageService
  ) {
    super(userPasswordService, routingService, authConfigService, globalMessage);
  }

  // tslint:disable-next-line:variable-name
  protected onError(_error: any): void {
    this.busy$.next(false);
    this.forGotpasswordMsg$.next(_error.details[0].message);
    // this.globalMessage.add(_error.details[0].message, GlobalMessageType.MSG_TYPE_ERROR);
  }

  setForgotPassswordErrMsg(msg) {
    this.forGotpasswordMsg$.next(msg);
  }

  loadForgotpasswordErrMsg$(): Observable<string> {
    return this.forGotpasswordMsg$.asObservable();
  }

  /**
   * Sends an email to the user to reset the password.
   *
   * When the `ResourceOwnerPasswordFlow` is used, the user is routed
   * to the login page.
   */
  requestEmail() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this.busy$.next(true);

    this.userPasswordService
      .requestForgotPasswordEmail((environment.BASE_SITE[0] + '|').toLowerCase() + this.form.value.userEmail)
      .subscribe({
        next: () => this.onSuccess(),
        error: (error: Error) => this.onError(error),
      });
  }
}
