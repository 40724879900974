import { Component, Input, OnChanges, OnInit, Optional, SimpleChanges } from '@angular/core';
import { CartItemContextSource, CartItemComponent } from '@spartacus/cart/base/components';
import { CartItemContext } from '@spartacus/cart/base/root';

@Component({
  selector: 'cx-cart-item',
  templateUrl: './sloan-cart-item.component.html',
  providers: [CartItemContextSource, { provide: CartItemContext, useExisting: CartItemContextSource }],
})
export class SloanCartItemComponent extends CartItemComponent implements OnInit, OnChanges {
  @Input() item: any;

  constructor(protected cartItemContextSource: CartItemContextSource) {
    super(cartItemContextSource);
  }

  ngOnInit() {
    // super.ngOnInit();
  }

  ngOnChanges(changes?: SimpleChanges) {
    // super.ngOnChanges(changes);
  }
}
