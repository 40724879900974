import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, queueScheduler } from 'rxjs';
import { observeOn } from 'rxjs/operators';
import { KnBrFactSheetDetail } from '../store/actions/kn-br-fact-sheet.action';
import { getFacSheetDataLoader, getFactSheetData } from '../store/selecters/kn-br-fact-sheet.selector';
import { StateWithKnBrFactSheet } from '../../../../app/store/kn-br-fact-sheet/kn-br-fact-sheet.state';

@Injectable({
  providedIn: 'root',
})
export class KnBrFactSheetService {
  constructor(private store: Store<StateWithKnBrFactSheet>) { }
  loading$: Observable<boolean> = this.store.pipe(select(getFacSheetDataLoader), observeOn(queueScheduler));
  factSheetData$: Observable<any> = this.store.pipe(select(getFactSheetData), observeOn(queueScheduler));

  getFactSheetData() {
    this.store.dispatch(new KnBrFactSheetDetail());
  }
}
