/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CmsConfig, I18nModule, provideConfig, provideDefaultConfig } from '@spartacus/core';
import { SpinnerModule } from '@spartacus/storefront';
import { KnBrCheckoutDeliveryModeComponent } from './kn-br-checkout-delivery-mode.component';
import { CheckoutAuthGuard, CartNotEmptyGuard } from '@spartacus/checkout/base/components';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, I18nModule, SpinnerModule],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        CheckoutDeliveryMode: {
          component: KnBrCheckoutDeliveryModeComponent,
          guards: [CheckoutAuthGuard, CartNotEmptyGuard],
        },
      },
    }),
  ],
  declarations: [KnBrCheckoutDeliveryModeComponent],
  exports: [KnBrCheckoutDeliveryModeComponent],
})
export class KnBrCheckoutDeliveryModeModule {}
