import { KnBrOrderReturnActions, KnBrAddressActionTypes } from '../actions/kn-br-address.action';
import { KnBrAddressState } from '../../../../../app/store/kn-br-address/kn-br-address.state';

export const initialState: KnBrAddressState = {
  oneTimeAddress: undefined,
  isloading: false,
  error: undefined,
};

export function knBrAddressReducer(state = initialState, action: KnBrOrderReturnActions): KnBrAddressState {
  switch (action.type) {
    case KnBrAddressActionTypes.KnBrOneTimeAddressAction:
      return { ...state, isloading: true };
    case KnBrAddressActionTypes.KnBrOneTimeAddressSuccessAction:
      return {
        ...state,
        oneTimeAddress: action.payload,
        isloading: false,
      };
    case KnBrAddressActionTypes.KnBrOneTimeAddressFailureAction:
      return { ...state, error: action.payload, isloading: false };
    default:
      return state;
  }
}
