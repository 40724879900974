<div class="rounded w-100 float-left">
  <div class="float-right text-left p-0 justify-content-end d-flex align-items-center">
    <button class="btn back-btn pl-1 pr-1 pb-1 pt-1 font-lg-size" (mousedown)="goBack()">
      <i class="fas fa-level-down-alt fa-rotate-90"></i>
    </button>
  </div>
</div>
<form [formGroup]="draftOrderEditForm" *ngIf="draftOrder">
  <fieldset [disabled]="isLoading$ | async">
    <div class="col-md-12 float-left customersearch-forms mt-3 login-forms p-0">
      <div class="col-lg-12 p-0 float-left">
        <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3">
          <input type="text" disabled [value]="draftOrder?.code" class="form-control w-100"
            [placeholder]="'cartCommon.label.number' | cxTranslate" />
          <span class="highlight"></span>
          <label class="text-muted">{{ 'cartCommon.label.number' | cxTranslate }}</label>
        </div>
        <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3">
          <input (blur)="onSubmit()" type="text" class="form-control w-100"
            [placeholder]="'cartCommon.label.name' | cxTranslate" formControlName="name" />
          <span class="highlight"></span>
          <label class="text-muted">{{ 'cartCommon.label.name' | cxTranslate }}
            <span class="text-danger font-size-18 float-right ml-2">*</span></label>
          <cx-form-errors [control]="draftOrderEditForm.get('name')"></cx-form-errors>
        </div>
        <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3">
          <input type="text" class="form-control w-100" disabled [value]="draftOrder?.cartStatus"
            [placeholder]="'cartCommon.label.status' | cxTranslate" />
          <span class="highlight"></span>
          <label class="text-muted">{{ 'cartCommon.label.status' | cxTranslate }}</label>
        </div>
      </div>
      <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-4 valid-to p-res-0 position-relative">
        <input type="text" class="form-control w-100" [placeholder]="'cartCommon.label.createdBy' | cxTranslate"
          disabled [value]="draftOrder?.createdBy" />
        <span class="highlight"></span>
        <label class="text-muted m-0">{{ 'cartCommon.label.createdBy' | cxTranslate }}</label>
      </div>
      <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-4 valid-to p-res-0 position-relative">
        <input type="text" class="form-control w-100" [placeholder]="'cartCommon.label.createdDate' | cxTranslate"
          disabled [value]="draftOrder?.creationDate" />
        <span class="highlight"></span>
        <label class="text-muted m-0">{{ 'cartCommon.label.createdOn' | cxTranslate }}</label>
      </div>
      <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-4 valid-to p-res-0 position-relative">
        <input type="text" class="form-control w-100" [placeholder]="'cartCommon.label.lastUpdatedDate' | cxTranslate"
          disabled [value]="draftOrder?.lastUpdatedDate" />
        <span class="highlight"></span>
        <label class="text-muted m-0">{{ 'cartCommon.label.lastUpdatedDate' | cxTranslate }}</label>
      </div>
      <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3 valid-to p-res-0 position-relative">
        <label class="text-muted m-0">{{ 'cartCommon.label.requestedDeliveryDate' | cxTranslate }}</label>
        <div class="input-group">
          <input class="form-control w-100 cursor-pointer" [placeholder]="dateFormat$ | async" name="rdd" ngbDatepicker
            #rdd="ngbDatepicker" formControlName="requestedDeliveryDate" (blur)="onSubmit()"
            (dateSelect)="onSubmit()" [minDate]="minDate" />
          <div class="input-group-append">
            <button class="btn btn calendar p-0 h-auto w-auto" container="body" (click)="rdd.toggle()" type="button">
              <i class="far fa-calendar-alt color-font"></i>
            </button>
          </div>
        </div>
        <cx-form-errors [control]="draftOrderEditForm.get('requestedDeliveryDate')"></cx-form-errors>
      </div>

      <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3">
        <input type="text" class="form-control w-100" [placeholder]="'cartCommon.label.customerPO' | cxTranslate"
          formControlName="purchaseOrderNumber" (blur)="onSubmit()" />
        <span class="highlight"></span>
        <label class="text-muted">{{ 'cartCommon.label.customerPO' | cxTranslate }}
          <span class="text-danger font-size-18 float-right ml-2">*</span></label>
        <cx-form-errors [control]="draftOrderEditForm.get('purchaseOrderNumber')"></cx-form-errors>
      </div>

      <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3">
        <input type="text" class="form-control w-100" [placeholder]="'cartCommon.label.jobNameNumber' | cxTranslate"
          formControlName="jobName" (blur)="onSubmit()" />
        <span class="highlight"></span>
        <label class="text-muted">{{ 'cartCommon.label.jobNameNumber' | cxTranslate }}</label>
      </div>
      <div class="col-lg-12 p-0 float-left">
        <div class="form-group head-notes mt-3 col-lg-3 pl-0 float-left mb-3">
          <label for="exampleFormControlTextarea1">{{ 'cartCommon.label.headerNotes' | cxTranslate }}</label>
          <textarea class="form-control w-100" id="exampleFormControlTextarea1" rows="2"
            [placeholder]="'cartCommon.label.headerNotes' | cxTranslate" formControlName="headerComments"
            (blur)="onSubmit()"></textarea>
        </div>
        <div class="remember-me float-left mt-4 d-flex justify-content-start p-2 mr-4">
          <div class="form-check custom-checkbox m-0">
            <input type="checkbox" class="custom-control-input" id="defaultUnchecked" formControlName="shipComplete"
              (change)="onSubmit()" />
            <label class="custom-control-label color-font" for="defaultUnchecked">{{
              'cartCommon.label.shipComplete' | cxTranslate
              }}</label>
          </div>
        </div>
        <div class="col-md-4 float-left text-left p-0 customer-search-btns d-flex align-items-center m-res-bottom">
          <button *ngIf="draftOrder && draftOrder.checkoutReady" type="button" class="btn btn-primary col-md-6 mt-3"
            [disabled]="!draftOrderEditForm.valid" (click)="navigate('/checkout')">
            {{ 'cartCommon.label.checkout' | cxTranslate }}
          </button>
        </div>
        <ng-container *ngIf="statuses$ | async as statuses">
          <div class="form-group head-notes mt-3 col-lg-2 pl-0 float-left mb-3"
            *ngIf="statuses && statuses.length && draftOrder && draftOrder.entries?.length && !draftOrder.checkoutReady">
            <ng-select class="" formControlName="draftOrderStatus" [items]="statuses$ | async"
              [placeholder]="'label.actions' | cxTranslate" [searchable]="false" [clearable]="false" bindLabel="Text"
              bindValue="Key" [readonly]="!draftOrderEditForm.valid">
            </ng-select>
            <span class="highlight"></span>
            <label class="text-muted">{{ 'label.actions' | cxTranslate }}</label>
          </div>
          <div class="form-group head-notes mt-3 col-lg-2 pl-0 float-left mb-3"
            *ngIf="statuses && statuses.length && draftOrder && draftOrder.entries?.length && !draftOrder.checkoutReady">
            <button type="button" class="btn btn-primary" (click)="onSubmit(true)"
              [disabled]="!draftOrderEditForm.valid">
              {{ 'quoteCommon.label.go' | cxTranslate }}
            </button>
          </div>
        </ng-container>
      </div>

      <div class="col-lg-12 p-0 float-left">
        <div class="col-lg-2 float-left pl-1 color-font font-size-17 font-text-600">
          <label class="float-left font-text-600 mr-2">{{ 'cartCommon.label.totalItems' | cxTranslate }} </label>
          {{ draftOrder?.totalItems }}
        </div>
        <div class="col-lg-2 float-left pl-1 color-font font-size-17 font-text-600">
          <label class="float-left font-text-600 mr-2">{{ 'cartCommon.label.totalQuantity' | cxTranslate }} </label>
          {{ draftOrder?.totalUnitCount }}
        </div>
        <div class="col-lg-2 float-left pl-1 color-font font-size-17 font-text-600">
          <label class="float-left font-text-600 mr-2">{{ 'cartCommon.label.totalPrice' | cxTranslate }} </label>
          {{ draftOrder?.totalPrice?.formattedValue }}
        </div>
      </div>
    </div>
  </fieldset>
</form>
