import { Component, OnInit, ViewContainerRef, OnDestroy } from '@angular/core';
import { TranslationService } from '@spartacus/core';
import { LaunchDialogService } from '@spartacus/storefront';
import { SloanCommonService } from 'src/app/services/sloan/sloan-common.service';
import { KnBrInvoiceService } from 'src/feature-libs/kn-br-order/core/facade/kn-br-invoice.service';
import { KnBrInvoiceAccountSummaryComponent } from 'src/feature-libs/kn-br-order/components/kn-br-customer360/kn-br-invoice-list/kn-br-invoice-account-summary/kn-br-invoice-account-summary.component';

@Component({
  selector: 'knbr-sloan-invoice-account-summary',
  templateUrl: './sloan-invoice-account-summary.component.html',
  styleUrls: ['./sloan-invoice-account-summary.component.scss'],
})
export class SloanInvoiceAccountSummaryComponent
  extends KnBrInvoiceAccountSummaryComponent
  implements OnInit, OnDestroy {
  constructor(
    public knBrInvoiceService: KnBrInvoiceService,
    protected translation: TranslationService,
    private commonService: SloanCommonService,
    protected launchDialogService: LaunchDialogService,
    protected vcr: ViewContainerRef
  ) {
    super(knBrInvoiceService, translation, launchDialogService, vcr);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  downloadInvoiceHeaderPDF() {
    this.commonService.downloadInvoiceHeaderPDF();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
