import { TranslationChunksConfig, TranslationResources } from '@spartacus/core';
import { en } from './en/index';

export const userProfileTranslations: TranslationResources = {
  en,
};

export const userProfileTranslationChunksConfig: TranslationChunksConfig = {
  updateprofiledata: ['userProfile',
    'updateProfileForm',
    'updatePasswordForm',
    'updateEmailForm',
    'forgottenPassword'],
};
