export const quoteCommon = {
  label: {
    name: 'Name',
    validFrom: 'Valid From',
    validTo: 'Valid To',
    requestedDeliveryDate: 'Requested Delivery Date',
    requestedShipDate: 'Requested Ship Date',
    estimatedShipDate: "Estimated Ship Date",
    customerPO: 'Customer P.O.',
    jobNameNumber: 'Job Name',
    shipComplete: 'Ship Complete',
    headerNotes: 'Order Notes/Tags',
    referenceQuote: 'Reference Quote',
    quoteOrderNumber: 'Quote Number',
    status: 'Status',
    createdBy: 'Created By',
    creationDate: 'Creation date',
    actions: 'Actions',
    totalQuantity: 'Total Quantity',
    totalItems: 'Total Items',
    totalPrice: 'Total Price',
    go: 'Go',
    quote: 'Quote',
    quoteReference: 'Quote Reference',
    downloadPDF: 'Download PDF',
    addToQuote: 'Add to Quote',
    createdOn: 'Created On',
    cancel: 'Cancel',
    downloadCSV: 'Download Excel',
    downloadExcelTemplate: 'Download Excel Template',
    downloadImages: 'Download Images',
    checkout: 'Accept and Checkout',
    editBtn: 'Edit',
    saveUpdatedQuote: 'Save Updated Quote',
    submitForApproval: 'Submit For Approval',
  },
  createMessage: {
    success: 'Quote has been created successfully.',
    error: 'Error occurred while creating quote.',
  },
  copyMessage: {
    success: 'Quote has been copied successfully.',
    error: 'Error occurred while copying quote.',
  },
  updateMessage: {
    success: 'Quote has been updated successfully.',
    error: 'Error occurred while updating quote.',
  },
  checkoutMessage: {
    success: 'Quote has been accepted and ready for checkout.',
    error: 'Error occurred while accept and checkout the quote.',
  },
  invalidQtyMessage: {
    entryMessage: 'Please enter valid quantity (Multiple of MOQs) for this item',
    globalMessage: 'Please enter valid quantity (Multiple of MOQs) for Line Nos {{ linenos }}',
  },
};
export const quoteSearchForm = {
  label: {
    quoteNo: 'Number',
    accountNumber: 'Account Number',
    accountName: 'Account Name',
    customerPO: 'Customer P.O.',
    jobNameNumber: 'Job Name',
    status: 'Status',
    productNumber: 'Product Number',
  },
  placeholder: {
    suggestedQuote: 'Number',
  },
};
export const quoteResult = {
  header: {
    quoteNumber: 'Number',
    quoteName: 'Quote Name',
    createdBy: 'Created By',
    creationDate: 'Creation date',
    createdOn: 'Created On',
    accountNo: 'Account No.',
    accountName: 'Account Name',
    customerPO: 'Customer P.O.',
    job: 'Job ',
    status: 'Status',
    validFrom: 'Valid From',
    validTo: 'Valid To',
    total: 'Total',
    actions: 'Actions',
    updateBy: 'Update By',
  },
  showMore: 'Show More',
  nodataMsg: 'Sorry! No Quote data found',
};
export const actionBar = {
  label: {
    quickEntry: 'Quick Entry',
    searchProducts: 'Search Products',
  },
};
export const quoteDetailHeader = {
  label: {
    lastUpdatedDate: 'Last Update Date',
    lastModifiedBy: 'Last Update By',
    reqdeliveryDate: ' Requested Delivery Date',
    revision: 'Revision: ',
  },
  title: {
    addUpdate: 'Add/Update Quote',
  },
};
export const quoteItems = {
  header: {
    productDetails: 'Product Details',
    plant: 'Plant',
    reqDeliveryDate: 'Requested Delivery Date',
    reqShipDate: 'Requested Ship Date',
    estimatedShipDate: "Estimated Ship Date",
    listPrice: 'List Price',
    netPrice: 'Net Price',
    availableQty: 'Available QTY',
    quantity: 'Quantity',
    total: 'Total',
    priceAdjustment: 'Price Adjustment',
    lineNo: 'Line No.',
  },
  placeholder: {
    1: '1',
  },
};
export const quotePartialItems = {
  header: {
    orderDetails: 'Order Details',
    order: 'Order',
    item: 'Item',
    product: 'Product',
    description: 'Description',
    quantity: 'Quantity',
    unit: 'Unit',
    orderDate: 'Order Date',
  },
};
export const quoteReference = {
  label: {
    quoteNumber: 'Number',
    totalQty: 'Total QTY',
    availableQty: 'Available QTY',
    price: 'Price',
    jobName: 'Job Name',
    referencedQty: 'Referenced QTY',
    orderQty: 'Ordered QTY',
  },
  referenceNotFound: 'No quote reference found for product #{{ code }}.',
  selectMessage1: 'The Product #{{ code }} is Found in the Following Quote',
  selectMessage2: 'Please select a Quote to Reference in your Cart.',
};
export const addToQuoteMessage = {
  success: 'Product(s) are added to quote successfully.',
  failure: 'Product(s) are not added to quote successfully.',
};
export const button = {
  search: "Search",
  reset: "Reset",
  create: "Create",
  cancel: "Cancel",
}
export const knbrQuote = {
  addToQuoteMessage,
  quoteReference,
  quoteItems,
  quotePartialItems,
  quoteCommon,
  quoteDetailHeader,
  actionBar,
  quoteSearchForm,
  quoteResult,
  button,
};
