import { NgModule } from '@angular/core';
import { CmsConfig, provideConfigFactory } from '@spartacus/core';
import { USER_ACCOUNT_FEATURE, USER_ACCOUNT_CORE_FEATURE } from './feature-name';
import { UserAccountEventModule } from './events/user-account-event.module';
//import { UserAccountEventModule } from '@spartacus/user/account/root';

// TODO: Inline this factory when we start releasing Ivy compiled libraries
export function defaultUserAccountComponentsConfig(): CmsConfig {
  const config: CmsConfig = {
    featureModules: {
      [USER_ACCOUNT_FEATURE]: {
        cmsComponents: ['KnBrLoginFormComponent', 'KnBrSellerApproverMessageComponent'],
      },
      // by default core is bundled together with components
      [USER_ACCOUNT_CORE_FEATURE]: USER_ACCOUNT_FEATURE,
    },
  };
  return config;
}

@NgModule({
  imports: [UserAccountEventModule],
  providers: [provideConfigFactory(defaultUserAccountComponentsConfig)],
})
export class UserAccountRootModule { }
