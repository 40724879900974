import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { CustomerSelectionComponent } from '@spartacus/asm/components';
import { AsmService } from '@spartacus/asm/core';
import { AsmConfig } from '@spartacus/asm/root';
import { DirectionService, LaunchDialogService } from '@spartacus/storefront';

@Component({
  selector: 'cx-customer-selection',
  templateUrl: './kn-br-customer-selection.component.html',
  styleUrls: ['./kn-br-customer-selection.component.scss'],
  encapsulation: ViewEncapsulation.None,
  // tslint:disable-next-line:no-host-metadata-property
  host: {
    '(document:click)': 'onDocumentClick($event)',
  },
})
export class KnBrCustomerSelectionComponent extends CustomerSelectionComponent implements OnInit, OnDestroy {
// TODO:Spartacus - The type of property 'launchDialogService: LaunchDialogService | undefined' changed to: 'launchDialogService: LaunchDialogService' 
  constructor(protected fb: UntypedFormBuilder, protected asmService: AsmService, protected config: AsmConfig, directionService: DirectionService, launchDialogService: LaunchDialogService) {
// TODO:Spartacus - The type of property 'launchDialogService: LaunchDialogService | undefined' changed to: 'launchDialogService: LaunchDialogService' 
    super(    fb, asmService, config, directionService, launchDialogService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  onSubmit(): void {
    if (this.customerSelectionForm.valid && !!this.selectedCustomer) {
      this.submitEvent.emit({ customerId: this.selectedCustomer.uid });
    } else {
      this.customerSelectionForm.markAllAsTouched();
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
