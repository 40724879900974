import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthGuard, CmsConfig, ConfigModule, I18nModule, RoutingConfig, UrlModule } from '@spartacus/core';
import { CmsPageGuard, FormErrorsModule, PageLayoutComponent } from '@spartacus/storefront';

// import { KnBrCustomerContextGuard } from './../../shared/guards/kn-br-customer-context.guard';
import { KnBrQuoteCreateComponent } from './kn-br-quote-create.component';
import { KnBrCustomerContextGuard } from 'src/app/shared/guards/kn-br-customer-context.guard';

@NgModule({
  declarations: [KnBrQuoteCreateComponent],
  imports: [
    CommonModule,
    I18nModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    UrlModule,
    FormErrorsModule,
    ConfigModule.withConfig({
      cmsComponents: {
        KnBrQuoteCreateComponent: {
          component: KnBrQuoteCreateComponent,
          guards: [KnBrCustomerContextGuard],
        },
      },
    } as CmsConfig),
    RouterModule.forChild([
      {
        data: { pageLabel: '/create-quote', cxRoute: 'createQuote' },
        path: null,
        canActivate: [AuthGuard, CmsPageGuard],
        component: PageLayoutComponent,
      },
    ]),
    ConfigModule.withConfig({
      routing: {
        routes: {
          createQuote: {
            paths: ['copy-quote/:code/:name', 'create-quote'],
          },
        },
      },
    } as RoutingConfig),
  ],
  exports: [KnBrQuoteCreateComponent],
})
export class KnBrQuoteCreateModule {}
