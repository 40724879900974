import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CmsOrderDetailOverviewComponent, TranslationService } from '@spartacus/core';
import { OrderDetailsService, OrderOverviewComponent } from '@spartacus/order/components';
import { Card, CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { KnBrAddress } from 'src/feature-libs/kn-br-order/root/models/kn-br-order.model';

@Component({
  selector: 'cx-order-overview',
  templateUrl: './kn-br-order-overview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KnBrOrderOverviewComponent extends OrderOverviewComponent {
  @Input() order: any;
  constructor(protected translation: TranslationService,
    protected orderDetailsService: OrderDetailsService,
    protected component: CmsComponentData<CmsOrderDetailOverviewComponent>
  ) {
    super(translation, orderDetailsService, component);
  }
  date = new Date();

  getAddressCardContent(deliveryAddress: KnBrAddress): Observable<Card> {
    return this.translation.translate('addressCard.shipTo').pipe(
      filter(() => Boolean(deliveryAddress)),
      map((textTitle) => ({
        title: textTitle,
        textBold: deliveryAddress.firstName,
        text: [
          (deliveryAddress.houseNumber ? deliveryAddress.houseNumber + ', ' : '') + deliveryAddress.line1,
          deliveryAddress.line2,
          deliveryAddress.town + ', ' + deliveryAddress.region?.name ?? '',
          deliveryAddress.country.name + ', ' + deliveryAddress.postalCode,
          deliveryAddress.phone,
        ],
      }))
    );
  }

  getOrderStatusCardContent(status: string): Observable<Card> {
    return this.translation.translate('orderDetails.status').pipe(
      map((textTitle) => ({
        title: textTitle,
        text: [status],
      }))
    );
  }

  getTotalItems(totalItems: string): Observable<Card> {
    return this.translation.translate('checkoutOrderConfirmation.totalItems').pipe(
      map((textTitle) => ({
        title: textTitle,
        text: [totalItems],
      }))
    );
  }

  getTotalQuantity(totalUnitCount: string): Observable<Card> {
    return this.translation.translate('checkoutOrderConfirmation.totalQuantity').pipe(
      map((textTitle) => ({
        title: textTitle,
        text: [totalUnitCount],
      }))
    );
  }

  getOrderTotal(totalPrice: string): Observable<Card> {
    return this.translation.translate('checkoutOrderConfirmation.orderTotal').pipe(
      map((textTitle) => ({
        title: textTitle,
        text: [totalPrice],
      }))
    );
  }
}
