import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, I18nModule, provideDefaultConfig } from '@spartacus/core';
import { OutletModule, PageComponentModule } from '@spartacus/storefront';
import { SloanTabParagraphContainerComponent } from './tab-paragraph-container.component';

@NgModule({
  imports: [CommonModule, PageComponentModule, OutletModule, I18nModule],
  providers: [
    provideDefaultConfig(<CmsConfig>{
      cmsComponents: {
        CMSTabParagraphContainer: {
          component: SloanTabParagraphContainerComponent,
        },
      },
    }),
  ],
  declarations: [SloanTabParagraphContainerComponent],
  exports: [SloanTabParagraphContainerComponent],
})
export class SloanTabParagraphContainerModule {}
