export const orderImport = {
  label: {
    pageHeader1: 'Import Saved Cart',
    pageHeader2: 'The imported file will be used to create a saved cart.',
    fileFormatHeader: 'The text file should list the product SKUs and quantities in the following format:',
    format1: 'SKU, Quantity',
    format2: 'Maximum file size: 88.12 KB',
    fileUploadHeader: 'Select a file to upload. The file must be a excel file with extension XLS or XLSX.',
    chooseFile: 'Choose File',
    import: 'Import',
  },
  message: {
    success: 'Order imported successfully.',
    error: 'Error occurred while importing an order.',
  },
};

export const quickEntry = {
  label: {
    pageHeader1: 'Quick Entry',
    cartLimitNote: 'You can add up to 50 valid SKUs below and add to cart',
    resetForm: 'Reset Form',
    addToCart: 'Add To Cart',
    addToQuote: 'Add To Quote',
    reset: 'Reset Form',
    minimumOrderQuantity: 'Minimum Order Quantity',
    codeNumber: 'Enter Code No',
  },
  header: {
    product: 'Product',
    quantity: 'Qty',
    lineNo: 'Line No',
  },
  message: {
    success: 'Add to cart successfully.',
    error: 'Error occurred while adding to cart.',
    minorderQty: 'Minimum Order Quantity ',
    productwarnmsg: 'Product cannot be purchased . Please enter another sku',
    skuplaceholder: 'Enter SKU',
  },
};

export const quickOrder = {
  label: {
    header1: 'You can add up to 50 valid skus',
    availability: 'Availability',
    netPrice: 'Net Price',
    minAdvPrice: 'Min Adv Price',
    plant: 'Plant',
    inventory: 'Inventory',
    estShipDate: 'Estimated Ship Date',
    resetForm: 'Reset Form',
    addToCart: 'Add To Cart',
    addToQuote: 'Add To Quote',
    orderTools: 'Order Tools',
  },
};

export const knbrOrderDetails = {
  label: {
    orderNumber: 'Order Number',
    orderStatus: 'Order Status',
    orderType: 'Order Type',
    poNumber: 'P.O. Number',
    soldTo: 'Sold To',
    shipTo: 'Ship To',
    orderCreationDate: 'Order Creation Date',
    orderPlacedBy: 'Order Placed By',
    jobNumber: 'JOB Number',
    shippingNotes: 'Shipping Notes',
    reqShipDate: 'Requested Ship Date',
    shipComplete: 'Ship Complete',
    shippingMethod: 'Shipping Method',
    totalQty: 'Total Qty',
    orderTotal: 'Order Total',
    downloadDocs: 'Download SAP Documents',
    qty: 'Qty',
    atpDate: 'ATP Date',
    plant: 'Plant',
    lineItemStatus: 'Line Item Status',
    quoteId: 'Quote ID',
    openQuantity: 'Open Quantity',
    unitNetPrice: 'Unit Net Price',
    shipments: 'Shipments',
    shipDate: 'Ship Date',
    status: 'Status',
    deliveryNumber: 'Delivery Number',
    carrier: 'Carrier',
    trackingId: 'Tracking ID',
  },
  actions: {
    reorderAction: 'Reorder',
    returnRMAAction: 'Return Order (RMA)',
    returnZRREAction: 'REP Return Order (ZRRE)',
    cancelAction: 'Cancel Order',
    editAction: 'Edit Order',
    saveEditOrder: 'Save Edited Order',
    cancel: 'Cancel',
    backOrderHistory: 'Back To Order History',
  },
  reOrderMessage: {
    success: 'Order has been converted to draft order successfully.',
    error: 'Error occurred while reordering an order.',
  },
  returnOrder: {
    navigationWarning: 'Order is not returnable at the moment',
  },
  addProducts: {
    product: 'Product',
    itemDetails: 'Item Details',
    listPrice: 'List Price',
    qty: 'Qty',
    total: 'Total',
    minorderQty: 'Minimum Order Quantity ',
    productwarnmsg: 'Product cannot be purchased . Please enter another sku',
    skuplaceholder: 'Enter SKU',
    lineNo: 'Line No',
    moqWarningMsg: 'Minimum Order Quantity doesnot match with the line item quantity, please check and update',
    priceWarnmsg: 'Product can not be purchased. Please enter another SKU',
  },
  editOrder: {
    successMsg: 'Your order edited successfully',
    confirmHeading: 'Your order Number is',
    processMessage: 'Your order being processed for the requested changes',
    errorMsg: 'Error while editing order',
    backButton: 'Back To Order History',
    product: 'Product',
    itemDetails: 'Item Details',
    listPrice: 'List Price',
    qty: 'Qty',
    total: 'Total',
    cancelItem: 'Cancel Line Item',
    qtyMsg: 'You can only decrease the quantity',
    addoreditwarnmsg: ' Please add or edit atleast one product',
    cancelReasonMsg: 'Select cancel reason',
    cancelSelectWarning: 'Please select cancel reason for items which are cancelling',
  },
};

export const orderForms = {
  label: {
    item: 'Item',
    listPrice: 'List Price',
    productDetails: 'Product Details',
    qty: 'Qty',
    minQty: 'Minimum Order Quantity',
    total: 'Total',
    qtySelected: 'Quantity Selected: ',
    subTotal: 'Sub Total:',
    createOrderForm: 'CREATE ORDER FORM',
    keywordSearch: 'Keyword Search',
    onlyProductIds: 'Only ProductIds',
    orderForm: 'Order Form',
    selectProduct: 'Select Product',
    productsFound: 'Products Found',
    availability: 'Availability',
    plant: 'Plant',
    inventory: 'Inventory',
    estShipDate: 'Estimated Ship Date',
  },
  nodataMsg: 'No Products Found!',
};
export const knbrOrderTools = {
  orderImport,
  quickEntry,
  quickOrder,
  knbrOrderDetails,
  orderForms
};
