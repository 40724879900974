import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { KnBrOrderHistoryEffects } from '../../../feature-libs/kn-br-order/core/store/effects/kn-br-order-history.effects';
import { knBrOrderHistoryReducer } from '../../../feature-libs/kn-br-order/core/store/reducers/kn-br-order-history.reducer';
import { KN_BR_ORDER_HISTORY_FEATURE } from './kn-br-order-history.state';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(KN_BR_ORDER_HISTORY_FEATURE, knBrOrderHistoryReducer),
    EffectsModule.forFeature([KnBrOrderHistoryEffects]),
  ],
})
export class KnBrOrderHistoryStateModule { }
