import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  AuthHttpHeaderService,
  ConfigModule,
  HttpErrorHandler,
  I18nConfig,
  provideConfig,
} from '@spartacus/core';
import { PageSlotModule } from '@spartacus/storefront';
import { AppComponent } from './app.component';
import { KnBrLayoutConfigModule } from './common/kn-br-layout-config/kn-br-layout-config.module';
import { SpartacusModule } from './spartacus/spartacus.module';
import { KnBrCheckoutModule } from 'src/feature-libs/kn-br-checkout/kn-br-checkout.module';
import { KnBrAsmModule } from 'src/feature-libs/kn-br-asm/kn-br-asm.module';
import { KnBrProductModule } from '../feature-libs/kn-br-product/kn-br-product.module';
import { KnBrCategoryNavigationModule } from './customer-libs/kn-br-navigation/components/kn-br-category-navigation/kn-br-category-navigation.module';
import { KnBrOrganizationUsersModule } from 'src/feature-libs/kn-br-organization/administration/kn-br-organization.module';
import { SloanModule } from './sloan/sloan.module';
import { KnBrBadRequestHandler } from './core/kn-br-global-message/http-interceptors/handlers/bad-request/kn-br-bad-request.handler';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { MatSidenavModule } from '@angular/material/sidenav';
import { translations, translationChunksConfig } from '@spartacus/assets';
import { KnBrOutletModule } from './common/kn-br-outlet/kn-br-outlet.module';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { DatePipe } from '@angular/common';
import { KnBrAuthHttpHeaderService } from './core/kn-br-auth/kn-br-user-auth/services/kn-br-auth-http-header.service';
import { environment } from 'src/environments/environment';
//import { OrderHistoryAdapter } from 'src/feature-libs/kn-br-order/core/connectors/order-history.adapter';
import { KnBrOccUserOrderAdapter } from 'src/feature-libs/kn-br-order/occ/adapters/kn-br-occ-user-order.adapter';
import { OrderHistoryAdapter } from '@spartacus/order/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { knBrAsmLayoutConfig } from 'src/feature-libs/kn-br-asm/components/kn-br-asm-layout-config';
import { KnBrCommonModule } from './common/kn-br-common.module';
import { KnBrI18nModule } from './common/i18n/i18n.module';
@NgModule({
  providers: [
    provideConfig(<I18nConfig>{
      // we bring in static translations to be up and running soon right away
      i18n: {
        resources: translations,
        chunks: translationChunksConfig,
        fallbackLang: 'en',
      },
    }),
    {
      provide: AuthHttpHeaderService,
      useExisting: KnBrAuthHttpHeaderService,
    },
    {
      provide: HttpErrorHandler,
      useExisting: KnBrBadRequestHandler,
      multi: true,
    },
    {
      provide: OrderHistoryAdapter,
      useClass: KnBrOccUserOrderAdapter,
    },
    DatePipe,
    provideAnimationsAsync(),
  ],
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    ConfigModule.withConfig(knBrAsmLayoutConfig),
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    SpartacusModule,
    KnBrOutletModule,
    PageSlotModule,
    KnBrLayoutConfigModule,
    //KnBrOrganizationUsersModule,
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    MatSidenavModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
