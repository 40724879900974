import { SloanQuoteItemComponent } from './sloan-quote-item.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UrlModule, RoutingModule, I18nModule } from '@spartacus/core';
import { MediaModule, FormErrorsModule } from '@spartacus/storefront';
import { KnBrDirectiveModule } from 'src/app/shared/directive/kn-br-directive.module';
import { KnBrQuotePartialItemModule } from 'src/feature-libs/kn-br-cart/quote/components/kn-br-quote/kn-br-quote-detail/kn-br-quote-items/kn-br-quote-item/kn-br-quote-partial-item/kn-br-quote-partial-item.module';

@NgModule({
  declarations: [SloanQuoteItemComponent],
  imports: [
    CommonModule,
    RouterModule,
    UrlModule,
    RoutingModule,
    I18nModule,
    MediaModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    KnBrDirectiveModule,
    FormErrorsModule,
    KnBrQuotePartialItemModule,
  ],
  exports: [SloanQuoteItemComponent],
})
export class SloanQuoteItemModule { }
