import { KnBrCommonService } from 'src/app/services/kn-br-common.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
// TODO:Spartacus - UserActions - Following actions 'ForgotPasswordEmailRequestAction', 'ResetPasswordAction', 'EmailActions', 'UpdatePasswordAction', 'UserDetailsAction' were removed. Logic was moved to '@spartacus/user'.
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrderActions } from '@spartacus/order/core';

@Injectable()
export class KnBrSpinnerEffects {

  orderDetailNotify$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(OrderActions.LOAD_ORDER_DETAILS),
    map((action: any) => action.payload),
    map((data) => this.knBrCommonServive.setSpinner(true))
  ),
    { dispatch: false }
  );

  orderDetailSucessNotify$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(OrderActions.LOAD_ORDER_DETAILS_SUCCESS),
    map((action: any) => action.payload),
    map((data) => this.knBrCommonServive.setSpinner(false))
  ),
    { dispatch: false }
  );

  orderDetailFailureNotify$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(OrderActions.LOAD_ORDER_DETAILS_FAIL),
    map((action: any) => action.payload?.error),
    map((error: any) => this.knBrCommonServive.setSpinner(false))
  ),
    { dispatch: false }
  );

  returnOrderNotify$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(OrderActions.LOAD_ORDER_RETURN_REQUEST),
    map((action: any) => action.payload),
    map((data) => this.knBrCommonServive.setSpinner(true))
  ),
    { dispatch: false }
  );

  returnOrderSucessNotify$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(OrderActions.LOAD_ORDER_RETURN_REQUEST_SUCCESS),
    map((action: any) => action.payload),
    map((data) => this.knBrCommonServive.setSpinner(false))
  ),
    { dispatch: false }
  );

  returnOrderFailureNotify$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(OrderActions.LOAD_ORDER_RETURN_REQUEST_FAIL),
    map((action: any) => action.payload?.error),
    map((error: any) => this.knBrCommonServive.setSpinner(false))
  ),
    { dispatch: false }
  );
  constructor(private actions$: Actions, private knBrCommonServive: KnBrCommonService) { }
}
