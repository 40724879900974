import { KnBrCommonService } from 'src/app/services/kn-br-common.service';
import { UntypedFormBuilder } from '@angular/forms';
import { Component, OnInit, ChangeDetectorRef, OnDestroy, ViewContainerRef } from '@angular/core';
import { createFrom, EventService } from '@spartacus/core';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { KnBrCustomerContextService } from 'src/app/store/kn-br-customer-context/kn-br-customer-context.service';
import { LaunchDialogService } from '@spartacus/storefront';
import { Actions, ofType } from '@ngrx/effects';
import { KnBrOrderHistoryActionTypes } from 'src/feature-libs/kn-br-order/core/store/actions/kn-br-order-history.action';
import { QuickorderSearchSuccess } from 'src/app/store/custome-spartacus-events';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { KnBrOrderHistoryService } from 'src/feature-libs/kn-br-order/core/facade/kn-br-order-history.service';
import { KnBrAddProductsComponent } from 'src/feature-libs/kn-br-order/components/kn-br-customer360/kn-br-order-detail/kn-br-add-products/kn-br-add-products.component';
import { KnBrCartContextService } from '../../base/core/facade/kn-br-cart-context.service';
import { KnBrQuoteContextService } from '../../quote/core/facade/kn-br-quote-context.service';
import { KnBrDraftOrdersService } from '../../draft-order/core/facade/kn-br-draft-orders.service';
import { KnBrQuoteEntryService } from '../../quote/core/facade/kn-br-quote-entry.service';
import { KnBrQuotesService } from '../../quote/core/facade/kn-br-quotes.service';

@Component({
  selector: 'knbr-kn-br-quick-order',
  templateUrl: './kn-br-quick-order.component.html',
  styleUrls: ['./kn-br-quick-order.component.scss'],
})
export class KnBrQuickOrderComponent extends KnBrAddProductsComponent implements OnInit, OnDestroy {
  cartContext;
  quoteContext;
  selectedProductCodes;
  customerContext: string;

  quoteItems$: Observable<number> = this.knBrQuoteService.get$.pipe(
    startWith({ totalItems: 0 }),
    map((quote) => (quote && quote.totalItems) || 0)
  );
  cartItems$: Observable<number> = this.activeCartService.getActive().pipe(
    startWith({ totalItems: 0 }),
    map((cart) => (cart && cart.totalItems) || 0)
  );
  constructor(
    protected formBuilder: UntypedFormBuilder,
    protected cdr: ChangeDetectorRef,
    protected knBrOrderHistoryService: KnBrOrderHistoryService,
    protected knBrCartContextService: KnBrCartContextService,
    protected knBrQuoteContextService: KnBrQuoteContextService,
    protected knBrDraftOrderService: KnBrDraftOrdersService,
    protected knbrCommonService: KnBrCommonService,
    protected activeCartService: ActiveCartFacade,
    protected knBrQuoteService: KnBrQuotesService,
    protected knBrCustomerContextService: KnBrCustomerContextService,
    protected launchDialogService: LaunchDialogService,
    protected vcr: ViewContainerRef,
    protected knBrQuoteEntryService: KnBrQuoteEntryService,
    private actions$: Actions,
    private eventService: EventService
  ) {
    super(
      formBuilder,
      cdr,
      knBrOrderHistoryService,
      knBrDraftOrderService,
      knbrCommonService,
      launchDialogService,
      vcr,
      knBrQuoteEntryService
    );
    this.subscription.add(
      this.knBrCartContextService.get$.subscribe((data) => {
        this.cartContext = data;
        this.cdr.markForCheck();
      })
    );
    this.subscription.add(
      this.knBrQuoteContextService.get$.subscribe((data) => {
        this.quoteContext = data;
        this.cdr.markForCheck();
      })
    );
    this.subscription.add(
      this.knBrCustomerContextService.getActiveCustomerNumber().subscribe((response) => {
        this.customerContext = response ? response : null;
      })
    );
    this.actions$.pipe(ofType(KnBrOrderHistoryActionTypes.KnBrOrderFormsProductsSearchSuccessAction)).subscribe({
      next: (res: any) => {
        if (!res.payload.products[0].errorMsg) {
          this.eventService.dispatch(
            createFrom(QuickorderSearchSuccess, {
              event: 'ajaxComplete',
              attribute: 'productId',
              ProductId: res.payload.products[0].code,
            })
          );
        }
      },
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  quickOrderChange(e) {
    const ga = (window as any).ga;
    const tracker = ga.getAll()[0];
    tracker.set('page', e.target.value);
    tracker.send('event');
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
