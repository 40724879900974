import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import {
  CustomerSearchActions,
  CustomerSearchFailure,
  CustomerSearchSuccess,
  KnBrCustomerActionTypes,
} from './kn-br-customer.action';
import { KnBrCustomerAdapter } from './kn-br-customer.adapter';
import { CustomerData } from 'src/feature-libs/kn-br-user/kn-br-account/root/model/customer.model';

@Injectable()
export class KnBrCustomerEffects {

  searchCustomer$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrCustomerActionTypes.CustomerSearchAction),
    mergeMap((action) =>
      this.customerAdapter.searchCustomers(action.payload).pipe(
        map((data: CustomerData[]) => {
          return new CustomerSearchSuccess(data);
        }),
        catchError((error: Error) => {
          return of(new CustomerSearchFailure(error));
        })
      )
    )
  )
  );

  constructor(private actions$: Actions<CustomerSearchActions>, private customerAdapter: KnBrCustomerAdapter) { }
}
