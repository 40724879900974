import { NgModule } from '@angular/core';
import { provideConfig } from '@spartacus/core';
import {
  knbrProductTranslations,
  knbrProducTranslationChunksConfig,
} from 'src/feature-libs/kn-br-product/assests/translation/translation';
import { ProductRootModule } from 'src/feature-libs/kn-br-product/root/product-root.module';
import { PRODUCT_FEATURE } from 'src/feature-libs/kn-br-product/root/feature-name';

@NgModule({
  imports: [ProductRootModule],
  providers: [
    provideConfig({
      featureModules: {
        [PRODUCT_FEATURE]: {
          module: () => import('src/feature-libs/kn-br-product/kn-br-product.module').then((m) => m.KnBrProductModule),
        },
      },
    }),
    provideConfig({
      i18n: {
        resources: knbrProductTranslations,
        chunks: knbrProducTranslationChunksConfig,
        fallbackLang: 'en',
      },
    }),
  ],
})
export class ProductFeatureModule { }
