import { SloanQuoteItemsModule } from './sloan-quote-items/sloan-quote-items.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SloanQuoteHeaderModule } from './sloan-quote-header/sloan-quote-header.module';
import { SloanQuoteCommentsPopupModule } from './sloan-quote-comments-popup/sloan-quote-comments-popup.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, SloanQuoteHeaderModule, SloanQuoteItemsModule, SloanQuoteCommentsPopupModule],
})
export class SloanQuoteDetailModule {}
