import { Injectable } from '@angular/core';
// TODO:Spartacus - // TODO:Spartacus - Class UserService has been removed and is no longer part of the public API.
import { CxEvent, WindowRef, EventService } from '@spartacus/core';
import { TmsCollectorConfig, WindowObject } from '@spartacus/tracking/tms/core';
import { GtmCollectorService } from '@spartacus/tracking/tms/gtm';
import { UserAccountService } from '@spartacus/user/account/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { QuickorderSearchSuccess, MarkettingCenterLinkDownlaodEvent } from 'src/app/store/custome-spartacus-events';

@Injectable({ providedIn: 'root' })
export class SloanGtmCollectorService extends GtmCollectorService {
  constructor(protected winRef: WindowRef, private userService: UserAccountFacade, private events: EventService) {
    super(winRef);
  }

  pushEvent<T extends CxEvent>(config: TmsCollectorConfig, windowObject: WindowObject, event: T | any): void {
    const dataLayerProperty = config.dataLayerProperty ?? 'dataLayer';
    const eventName = (event.constructor as typeof CxEvent).type;
    if (eventName === 'LoginEvent' || eventName === 'NavigationEvent') {
      this.userService
        .get()
        .subscribe((user: any) => {
          if (user && user.userPublicKey) {
            windowObject[dataLayerProperty].push({
              visitorRoleType: user.roles && user.roles.length > 0 ? user.roles[0] : 'B2B Customer',
              userPK: user.userPublicKey,
              event: 'visitorLogged',
            });
          }
        })
        .unsubscribe();
    }
    if (eventName === 'QuickorderSearchSuccess') {
      this.events.get(QuickorderSearchSuccess).subscribe((event) => {
        windowObject[dataLayerProperty].push(event);
      });
    }
    if (eventName === 'markettingCenterLinkDownlaodEvent') {
      this.events.get(MarkettingCenterLinkDownlaodEvent).subscribe((event) => {
        windowObject[dataLayerProperty].push(event);
      });
    }
    windowObject[dataLayerProperty].push(event);
  }
}
