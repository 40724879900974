import { filter } from 'rxjs/operators';
import { ChangeDetectorRef, Component, OnInit, OnDestroy, Input } from '@angular/core';
import { GlobalMessageService } from '@spartacus/core';
import { CurrentProductService } from '@spartacus/storefront';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';
import { KnBrProductSelectService } from 'src/feature-libs/kn-br-product/core/service/kn-br-product-select.service';
import { SloanSelectCartComponent } from './sloan-select-cart/sloan-select-cart.component';
import { Observable } from 'rxjs';
import { KnBrAddToCartComponent } from 'src/feature-libs/kn-br-product/components/kn-br-add-to-cart/kn-br-add-to-cart.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActiveCartFacade, MultiCartFacade } from '@spartacus/cart/base/root';
import { KnBrCartContextService } from 'src/feature-libs/kn-br-cart/base/core/facade/kn-br-cart-context.service';
import { KnBrDraftOrdersService } from 'src/feature-libs/kn-br-cart/draft-order/core/facade/kn-br-draft-orders.service';
import { KnBrQuoteContextService } from 'src/feature-libs/kn-br-cart/quote/core/facade/kn-br-quote-context.service';
import { KnBrQuotesService } from 'src/feature-libs/kn-br-cart/quote/core/facade/kn-br-quotes.service';

@Component({
  selector: 'kn-br-add-to-cart',
  templateUrl: './sloan-add-to-cart.component.html',
  styleUrls: ['./sloan-add-to-cart.component.scss'],
})
export class SloanAddToCartComponent extends KnBrAddToCartComponent implements OnInit, OnDestroy {
  @Input() product: any;
  @Input() disableAddToCart = false;
  loader$: Observable<boolean> = this.knBrDraftOrderService.loader$;
  constructor(
    protected modalService: NgbModal,
    protected currentProductService: CurrentProductService,
    protected cd: ChangeDetectorRef,
    protected activeCartService: ActiveCartFacade,
    protected knBrCartContextService: KnBrCartContextService,
    protected knBrProductService: KnBrProductSelectService,
    protected messageService: GlobalMessageService,
    protected knBrQuoteContextService: KnBrQuoteContextService,
    protected commonService: KnBrCommonService,
    protected knBrQuotesService: KnBrQuotesService,
    protected knBrDraftOrderService: KnBrDraftOrdersService,
    protected multiCartFacade: MultiCartFacade
  ) {
    super(
      modalService,
      currentProductService,
      cd,
      activeCartService,
      knBrCartContextService,
      knBrProductService,
      messageService,
      knBrQuoteContextService,
      commonService,
      knBrQuotesService,
      knBrDraftOrderService,
      multiCartFacade
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (this.product) {
      this.isOrderable = this.product.isOrderable && this.product.price && this.product.price?.formattedValue;
      this.cd.markForCheck();
    } else {
      this.subscription.add(
        this.currentProductService
          .getProduct()
          .pipe(filter(Boolean))
          .subscribe((product: any) => {
            this.isOrderable = product && product.isOrderable && product.price && product.price?.formattedValue;
            this.cd.markForCheck();
          })
      );
    }
  }

  openModal() {
    let modalInstance: any;
    this.quantity = this.addToCartForm.get('quantity').value;
    this.modalRef = this.modalService.open(SloanSelectCartComponent, {
      centered: true,
      size: 'lg',
    });
    modalInstance = this.modalRef.componentInstance;
    modalInstance.productCode = this.productCode;
    modalInstance.quantity = this.quantity;
    modalInstance.productList = this.productList;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
