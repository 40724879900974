import { Component, ComponentRef, OnInit, ViewContainerRef } from '@angular/core';
import { LaunchDialogService, LAUNCH_CALLER } from '@spartacus/storefront';
import { Observable, Subscription } from 'rxjs';
import { KnBrFactSheetData } from 'src/feature-libs/kn-br-order/root/models/kn-br-fact-sheet.model';
import { KnBrFactSheetService } from 'src/feature-libs/kn-br-order/core/facade/kn-br-fact-sheet.service';

@Component({
  selector: 'knbr-kn-br-fact-sheet-header',
  templateUrl: './kn-br-fact-sheet-header.component.html',
  styleUrls: ['./kn-br-fact-sheet-header.component.scss'],
})
export class KnBrFactSheetHeaderComponent implements OnInit {
  factSheetData$: Observable<KnBrFactSheetData> = this.knBrFactSheetService.factSheetData$;
  loader$: Observable<boolean> = this.knBrFactSheetService.loading$;
  showSpinner: void | Observable<ComponentRef<any>>;
  subscription = new Subscription();
  constructor(
    protected knBrFactSheetService: KnBrFactSheetService,
    protected launchDialogService: LaunchDialogService,
    protected vcr: ViewContainerRef
  ) { }

  ngOnInit(): void {
    this.knBrFactSheetService.getFactSheetData();
    this.subscription.add(
      this.loader$.subscribe((isloading) => {
        this.onSpinnerLoading(isloading);
      })
    );
  }

  onSpinnerLoading(isLoading) {
    if (isLoading) {
      this.showSpinner = this.launchDialogService.launch(LAUNCH_CALLER.PLACE_ORDER_SPINNER, this.vcr);
    } else {
      if (this.showSpinner) {
        this.showSpinner
          .subscribe((component) => {
            this.launchDialogService.clear(LAUNCH_CALLER.PLACE_ORDER_SPINNER);
            component.destroy();
          })
          .unsubscribe();
      }
    }
  }
}
