/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, I18nModule, provideConfig, provideDefaultConfig, UrlModule } from '@spartacus/core';
import { CardModule, IconModule, OutletModule, PromotionsModule } from '@spartacus/storefront';
import { KnBrCheckoutReviewSubmitComponent } from './kn-br-checkout-review-submit.component';
import { CheckoutAuthGuard, CartNotEmptyGuard } from '@spartacus/checkout/base/components';
import { KnBrCartSharedModule } from 'src/feature-libs/kn-br-cart/base/components/kn-br-cart-shared/kn-br-cart-shared.module';

@NgModule({
  imports: [
    CommonModule,
    CardModule,
    KnBrCartSharedModule,
    I18nModule,
    UrlModule,
    RouterModule,
    PromotionsModule,
    IconModule,
    OutletModule,
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        CheckoutReviewOrder: {
          component: KnBrCheckoutReviewSubmitComponent,
          guards: [CheckoutAuthGuard, CartNotEmptyGuard],
        },
      },
    }),
  ],
  declarations: [KnBrCheckoutReviewSubmitComponent],
  exports: [KnBrCheckoutReviewSubmitComponent],
})
export class KnBrCheckoutReviewSubmitModule {}
