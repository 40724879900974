import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  CheckoutDeliveryAddressAdapter,
  CheckoutDeliveryModesAdapter,
  DELIVERY_MODE_NORMALIZER,
} from '@spartacus/checkout/base/core';
import { CheckoutStepType } from '@spartacus/checkout/base/root';
import { ConfigModule, UserAddressAdapter, provideConfig } from '@spartacus/core';
import { SloanOccCheckoutDeliveryAdapter } from 'src/app/store/sloan/sloan-checkout/sloan-occ-checkout-delivery.adapter';
import { SloanOccUserAddressAdapter } from 'src/app/store/sloan/sloan-checkout/sloan-occ-user-address.adapter';
import { SloanCheckoutProgressModule } from './checkout-progress/checkout-progress.module';
import { SloanCheckoutOrderSummaryModule } from './sloan-checkout-order-summary/sloan-checkout-order-summary.module';
import { SloanCheckoutPaymentTypeModule } from './sloan-checkout-payment-type/sloan-checkout-payment-type.module';
import { SloanCheckoutPlaceOrderModule } from './sloan-checkout-place-order/sloan-checkout-place-order.module';
import { SloanDeliveryModeModule } from './sloan-delivery-mode/sloan-delivery-mode.module';
import { SloanPaymentMethodModule } from './sloan-payment-method/sloan-payment-method.module';
import { SloanReviewSubmitModule } from './sloan-review-submit/sloan-review-submit.module';
import { SloanShippingAddressModule } from './sloan-shipping-address/sloan-shipping-address.module';
import { SloanDeliveryModeNormalizer } from 'src/app/core/user/connectors/sloan/sloan-delivery-mode.converter';
import { knbrCheckoutTranslations, knbrCheckoutTranslationChunksConfig } from 'src/feature-libs/kn-br-checkout/base/assets/translation/translation';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SloanCheckoutProgressModule,
    SloanCheckoutOrderSummaryModule,
    SloanCheckoutPaymentTypeModule,
    SloanShippingAddressModule,
    SloanDeliveryModeModule,
    SloanReviewSubmitModule,
    SloanPaymentMethodModule,
    SloanCheckoutPlaceOrderModule,
    ConfigModule.withConfig({
      checkout: {
        steps: [
          {
            id: 'paymentType',
            name: 'sloanCheckout.methodOfPayment',
            routeName: 'checkoutPaymentType',
            type: [CheckoutStepType.PAYMENT_TYPE],
          },
          {
            id: 'deliveryAddress',
            name: 'sloanCheckout.shippingAddress',
            routeName: 'checkoutDeliveryAddress',
            type: [CheckoutStepType.DELIVERY_ADDRESS],
          },
          {
            id: 'deliveryMode',
            name: 'sloanCheckout.deliveryMode',
            routeName: 'checkoutDeliveryMode',
            type: [CheckoutStepType.DELIVERY_MODE],
          },
          {
            id: 'paymentDetails',
            name: 'sloanCheckout.paymentDetails',
            routeName: 'checkoutPaymentDetails',
            type: [CheckoutStepType.PAYMENT_DETAILS],
          },
          {
            id: 'reviewOrder',
            name: 'sloanCheckout.reviewOrder',
            routeName: 'checkoutReviewOrder',
            type: [CheckoutStepType.REVIEW_ORDER],
          },
        ],
      },
    }),
  ],
  providers: [
    {
      provide: UserAddressAdapter,
      useClass: SloanOccUserAddressAdapter,
    },
    {
      provide: CheckoutDeliveryAddressAdapter,
      useClass: SloanOccCheckoutDeliveryAdapter,
    },
    {
      provide: CheckoutDeliveryModesAdapter,
      useClass: SloanOccCheckoutDeliveryAdapter,
    },
    {
      provide: DELIVERY_MODE_NORMALIZER,
      useClass: SloanDeliveryModeNormalizer,
      multi: true,
    },
    provideConfig({
      i18n: {
        resources: knbrCheckoutTranslations,
        chunks: knbrCheckoutTranslationChunksConfig,
      },
    }),
  ],
})
export class SloanCheckoutModule { }
