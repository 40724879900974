<div class="col-md-12 float-left p-4 border" *ngIf="factSheetData$ | async as factSheetData">
  <div class="col-md-4 p-0 float-left">
    <div class="col-md-12">
      <label class="color-font font-text-600 mb-1">
        {{ 'factSheetHeader.labels.accountNumber' | cxTranslate }}
      </label>
      <p class="text-muted mb-3">{{ factSheetData.soldToNumber }}</p>
    </div>
    <div class="col-md-12">
      <label class="color-font font-text-600 mb-1">
        {{ 'factSheetHeader.labels.name' | cxTranslate }}
      </label>
      <p class="text-muted mb-3">{{ factSheetData.soldName }}</p>
    </div>
    <div class="col-md-12">
      <label class="color-font font-text-600 mb-1">
        {{ 'factSheetHeader.labels.address' | cxTranslate }}
      </label>
      <p class="text-muted mb-0">
        {{ factSheetData.address?.addr1 }}
        {{ factSheetData.address?.addr2 }}
        {{ factSheetData.address?.addr3 }}
        {{ factSheetData.address?.addr4 }}
        {{ factSheetData.address?.addr5 }}
      </p>
    </div>
  </div>
  <div class="col-md-4 p-0 float-left">
    <div class="col-md-12">
      <label class="color-font font-text-600 mb-1">
        {{ 'factSheetHeader.labels.orderConfirmationEmail' | cxTranslate }}
      </label>
      <p class="text-muted mb-3">{{ factSheetData.ordConfEmail != '' ? factSheetData.ordConfEmail : '&nbsp;' }}</p>
    </div>
    <div class="col-md-12">
      <label class="color-font font-text-600 mb-1">
        {{ 'factSheetHeader.labels.advanceShipNoticeEmail' | cxTranslate }}
      </label>
      <p class="text-muted mb-3">{{ factSheetData.asnEmail != '' ? factSheetData.asnEmail : '&nbsp;' }}</p>
    </div>
    <div class="col-md-12">
      <label class="color-font font-text-600 mb-1">
        {{ 'factSheetHeader.labels.invoiceEmail' | cxTranslate }}
      </label>
      <p class="text-muted mb-0">{{ factSheetData.invEmail }}</p>
    </div>
  </div>
  <div class="col-md-4 p-0 float-left">
    <div class="col-md-12" *ngIf="factSheetData.priceList != ''">
      <label class="color-font font-text-600 mb-1"
        >{{ 'factSheetHeader.labels.mrpdProgramMedallionLevel' | cxTranslate }}
      </label>
      <p class="text-muted mb-3">{{ factSheetData.priceList }}</p>
    </div>
    <div class="col-md-12">
      <label class="color-font font-text-600 mb-1">
        {{ 'factSheetHeader.labels.priceGroup' | cxTranslate }}
      </label>
      <p class="text-muted mb-3">{{ factSheetData.priceGroup }}</p>
    </div>
    <div class="col-md-12">
      <label class="color-font font-text-600 mb-1">
        {{ 'factSheetHeader.labels.factSheet' | cxTranslate }}
      </label>
      <p class="text-muted mb-3">
        <a><i class="far fa-file-pdf cursor-pointer" aria-hidden="true" (click)="downloadFactSheetPdf()"></i></a>
      </p>
    </div>
  </div>
</div>
