<div class="col-lg-12 brand-menu d-none d-sm-none d-lg-block" style="display: block" *ngIf="node$ | async as nodes">
  <ng-template ngFor let-node [ngForOf]="(node$ | async)?.children">
    <div class="mr-4 float-left">
      <h5 class="brandmenu-titles color-font font-size text-center m-0">
        <ng-container *ngTemplateOutlet="url; context: { node: node }"> </ng-container>
        <ng-container *ngIf="node.children?.length > 0">
          <ng-container *ngTemplateOutlet="categorymenulist; context: { node: node }"> </ng-container>
        </ng-container>
      </h5>
    </div>
  </ng-template>
</div>

<ng-template #categorymenulist let-node="node">
  <div class="menu-contents col-lg-8 bg-white p-3">
    <div class="col-lg-12 p-0 position-relative float-left">
      <div class="col-lg-6 p-0 float-left position-static">
        <div
          class="
            menu-inner-titles
            border-bottom
            d-flex
            align-items-center
            p-2
            d-flex
            align-items-center
            menu-inner-titles menu-inner-titles
          "
        >
          <span class="col-lg-11 p-2 float-left color-font font-size text-center">
            <cx-generic-link [url]="node.url" [target]="node.target">
              {{ 'navigation.shopAll' | cxTranslate: { navNode: node.title } }}
            </cx-generic-link>
          </span>
        </div>
        <ng-container *ngFor="let child of node.children">
          <div
            class="
              menu-inner-titles
              border-bottom
              d-flex
              align-items-center
              d-flex
              align-items-center
              menu-inner-titles menu-inner-titles
              p-2
            "
          >
            <span class="col-lg-11 p-2 float-left color-font font-size text-center">
              <ng-container *ngTemplateOutlet="url; context: { node: child }"> </ng-container>
            </span>
            <i *ngIf="child.children?.length > 0" class="fas fa-chevron-right float-right" aria-hidden="true"></i>
            <ng-container *ngTemplateOutlet="categorySubMenulist; context: { node: child }"> </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #categorySubMenulist let-node="node">
  <div class="col-lg-6 p-2 menu-contents-inner">
    <ng-container *ngFor="let child of node.children">
      <a class="col-lg-12 p-2 float-left color-font font-size text-center">
        <ng-container *ngTemplateOutlet="url; context: { node: child }"> </ng-container>
      </a>
    </ng-container>
  </div>
</ng-template>

<ng-template #url let-node="node">
  <cx-generic-link [url]="node.url" [target]="node.target">
    {{ node.title }}
  </cx-generic-link>
</ng-template>

<button
  #Brands
  class="btn btn-action btn-block dialog-trigger knbr-filter-btn d-block d-sm-block d-lg-none"
  (click)="open()"
>
  Brands
</button>
