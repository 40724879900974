import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  CartNotEmptyGuard,
  CheckoutAuthGuard,
  CheckoutProgressMobileBottomModule,
  CheckoutProgressMobileTopModule
} from '@spartacus/checkout/base/components';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { CardModule, SpinnerModule } from '@spartacus/storefront';
import { AddressFormModule } from "@spartacus/user/profile/components";
import { KnBrCustomPipesModule } from 'src/app/shared/pipe/kn-br-custom-pipes/kn-br-custom-pipes.module';
import { SloanShippingAddressCheckoutModuleModule } from './sloan-shipping-address-checkout/sloan-shipping-address-checkout.module';
import { SloanShippingAddressComponent } from './sloan-shipping-address.component';

@NgModule({
  declarations: [SloanShippingAddressComponent],
  imports: [
    CommonModule,
    RouterModule,
    AddressFormModule,
    CardModule,
    SpinnerModule,
    I18nModule,
    CheckoutProgressMobileTopModule,
    CheckoutProgressMobileBottomModule,
    KnBrCustomPipesModule,
    SloanShippingAddressCheckoutModuleModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CheckoutDeliveryAddress: {
          component: SloanShippingAddressComponent,
          guards: [CheckoutAuthGuard, CartNotEmptyGuard],
        },
      },
    } as CmsConfig),
    CommonModule,
  ],
  exports: [SloanShippingAddressComponent],
})
export class SloanShippingAddressModule { }
