import { UntypedFormControl } from '@angular/forms';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { KnBrCancelOrReturnRequestEntryInput } from 'src/feature-libs/kn-br-order/root/models/kn-br-order.model';

@Component({
  selector: 'knbr-return-order-item',
  templateUrl: './kn-br-return-order-item.component.html',
  styleUrls: ['./kn-br-return-order-item.component.scss'],
})
export class KnBrReturnOrderItemComponent implements OnInit {
  @Input() item: any;
  quantity = new UntypedFormControl();
  @Output()
  quantityChange = new EventEmitter<KnBrCancelOrReturnRequestEntryInput>();
  constructor() { }

  ngOnInit(): void {
    this.quantity.setValue(0);
  }

  onchageQuantity(event) {
    if (this.quantity.value > this.item.quantity) {
      this.quantity.setValue(this.item.quantity);
    }
    const quantitychange: KnBrCancelOrReturnRequestEntryInput = {
      reason: '',
      notes: '',
      orderEntryNumber: this.item.orderEntry.entryNumber,
      quantity: this.quantity.value,
    };
    this.quantityChange.emit(quantitychange);
  }
}
