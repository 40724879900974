import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { KnBrInvoiceSearchForm } from 'src/feature-libs/kn-br-order/root/models/kn-br-invoice.model';
import { KnBrOrderHistorySearchForm } from 'src/feature-libs/kn-br-order/root/models/kn-br-order.model';

import {
  KNBR_INVOICE_ACCOUNT_SUMMARY,
  KNBR_INVOICE_SEARCH,
  KN_BR_CUSTOMER_CURRENT,
} from '../../../../constants/api.endpoints.constant';
import { KnBrCustomerContextService } from '../../../../app/store/kn-br-customer-context/kn-br-customer-context.service';

@Injectable({
  providedIn: 'root',
})
export class KnBrInvoiceAdapter {
  customerNumber: string;
  constructor(
    private endPointService: OccEndpointsService,
    private httpClient: HttpClient,
    protected knBrCustomerContextService: KnBrCustomerContextService
  ) {
    this.knBrCustomerContextService.getActiveCustomerNumber().subscribe((response) => {
      this.customerNumber = response ? response : null;
    });
  }

  search(invoiceSearch: KnBrInvoiceSearchForm) {
    const CUSTOMER_NUMBER = this.customerNumber ? this.customerNumber : KN_BR_CUSTOMER_CURRENT;

    return this.httpClient.get(
      this.endPointService.buildUrl(KNBR_INVOICE_SEARCH(CUSTOMER_NUMBER), { queryParams: invoiceSearch })
    );
  }

  invoiceAccountSummary() {
    const CUSTOMER_NUMBER = this.customerNumber ? this.customerNumber : KN_BR_CUSTOMER_CURRENT;

    return this.httpClient.get(this.endPointService.buildUrl(KNBR_INVOICE_ACCOUNT_SUMMARY(CUSTOMER_NUMBER)));
  }
}
