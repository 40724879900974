import { NgModule } from '@angular/core';
import { KnBrDraftOrderComponentsModule } from './components/kn-br-draft-order.components.module';
import { KnBrDraftOrderOccModule } from './occ/kn-br-draft-order-occ.module';
import { KnBrDraftOrderRootModule } from './root/kn-br-draft-order-root.module';
import { provideConfig, I18nConfig } from '@spartacus/core';
import { knbrDraftOrderTranslations, knbrDraftOrderTranslationChunksConfig } from './assests/translations/translations';

@NgModule({
  imports: [KnBrDraftOrderComponentsModule, KnBrDraftOrderOccModule, KnBrDraftOrderRootModule],
  providers: [
    provideConfig(<I18nConfig>{
      i18n: {
        resources: knbrDraftOrderTranslations,
        chunks: knbrDraftOrderTranslationChunksConfig,
        fallbackLang: 'en',
      },
    }),
  ],
})
export class KnBrDraftOrderModule {}
