import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { CmsNavigationComponent } from '@spartacus/core';
import { CmsComponentData, NavigationService } from '@spartacus/storefront';
import { KnBrFooterComponent } from 'src/app/common/kn-br-footer/kn-br-footer.component';

@Component({
  selector: 'sloan-footer',
  templateUrl: './sloan-footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SloanFooterComponent extends KnBrFooterComponent implements OnInit {
  constructor(protected componentData: CmsComponentData<CmsNavigationComponent>, protected service: NavigationService) {
    super(componentData, service);
  }
  ngOnInit(): void {
    super.ngOnInit();
  }
}
