<form (ngSubmit)="onSubmit()" [formGroup]="cartCreateForm">
  <div class="bg-white p-0 rounded col-lg-12 float-left">
    <div class="col-md-12 float-left customersearch-forms login-forms p-0 customer-home-forms">
      <div
        class="form-group mt-0 col-lg-12 pl-0 float-left mb-3 p-res-0 font-text-bold color-font"
        *ngIf="copiedDraftOrderData"
      >
        {{ 'cartCommon.label.referenceCart' | cxTranslate }}: &nbsp; {{ copiedDraftOrderData.code }} -
        {{ copiedDraftOrderData.name }}
      </div>
      <div class="form-group mt-3 col-lg-3 pl-0 float-left mb-4 p-res-0">
        <input
          type="text"
          class="form-control w-100"
          [placeholder]="'cartCommon.label.name' | cxTranslate"
          type="text"
          formControlName="name"
        />
        <span class="highlight"></span>
        <label class="text-muted"
          >{{ 'cartCommon.label.name' | cxTranslate }}
          <span class="text-danger font-size-18 float-right ml-2">*</span></label
        >
        <cx-form-errors [control]="cartCreateForm.get('name')"></cx-form-errors>
      </div>

      <div class="form-group mt-3 col-lg-3 pl-0 float-left mb-4 valid-to p-res-0 position-relative">
        <label class="text-muted m-0">{{ 'cartCommon.label.requestedDeliveryDate' | cxTranslate }}</label>
        <div class="col-lg-12 float-left p-0">
          <div class="form-group col-lg-12 pl-0 m-0 float-left p-0 valid-to p-res-0 position-relative">
            <div class="input-group">
              <input
                class="form-control w-100 cursor-pointer"
                [placeholder]="dateFormat$ | async"
                name="dp"
                ngbDatepicker
                #d="ngbDatepicker"
                formControlName="requestedDeliveryDate"
                [minDate]="minDate"
              />
              <div class="input-group-append">
                <button class="btn btn calendar p-0 h-auto w-auto" container="body" (click)="d.toggle()" type="button">
                  <i class="far fa-calendar-alt color-font"></i>
                </button>
              </div>
            </div>
            <cx-form-errors [control]="cartCreateForm.get('requestedDeliveryDate')"></cx-form-errors>
          </div>
        </div>
      </div>

      <div class="form-group mt-3 col-lg-3 pl-0 float-left mb-4 p-res-0">
        <input
          type="text"
          class="form-control w-100"
          [placeholder]="'cartCommon.label.customerPO' | cxTranslate"
          type="text"
          formControlName="purchaseOrderNumber"
        />
        <span class="highlight"></span>
        <label class="text-muted"
          >{{ 'cartCommon.label.customerPO' | cxTranslate }}
          <span class="text-danger font-size-18 float-right ml-2">*</span></label
        >
        <cx-form-errors [control]="cartCreateForm.get('purchaseOrderNumber')"></cx-form-errors>
      </div>

      <div class="form-group mt-3 col-lg-3 pl-0 float-left mb-4 p-res-0">
        <input
          type="text"
          class="form-control w-100"
          [placeholder]="'cartCommon.label.jobNameNumber' | cxTranslate"
          type="text"
          formControlName="jobName"
        />
        <span class="highlight"></span>
        <label class="text-muted">{{ 'cartCommon.label.jobNameNumber' | cxTranslate }}</label>
      </div>
    </div>

    <div class="remember-me col-md-12 float-left mb-3 d-flex justify-content-start pl-4">
      <div class="form-check custom-checkbox m-0 p-0">
        <input type="checkbox" class="custom-control-input" id="defaultUnchecked" formControlName="shipComplete" />
        <label class="custom-control-label color-font" for="defaultUnchecked">{{
          'cartCommon.label.shipComplete' | cxTranslate
        }}</label>
      </div>
    </div>
    <div class="col-md-12 float-left customersearch-forms login-forms p-0">
      <div class="form-group head-notes mt-2 col-md-3 pl-0 float-left mb-3">
        <label for="exampleFormControlTextarea1">{{ 'cartCommon.label.headerNotes' | cxTranslate }}</label>
        <textarea
          class="form-control w-100"
          id="exampleFormControlTextarea1"
          rows="2"
          [placeholder]="'cartCommon.label.headerNotes' | cxTranslate"
          formControlName="headerComments"
        ></textarea>
      </div>
      <div class="col-md-9 float-left p-0 mt-4">
        <button
          type="button"
          class="btn btn-primary col-md-3 mr-3"
          [disabled]="(isCreateDisabled$ | async) || !cartCreateForm.valid"
          (click)="onSubmit()"
        >
          {{ 'button.create' | cxTranslate }}
        </button>
        <button type="button" class="btn btn-outline-primary col-md-3" (click)="goBack()">
          {{ 'button.cancel' | cxTranslate }}
        </button>
      </div>
    </div>
  </div>
</form>
