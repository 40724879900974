import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { KnBrInvoiceEffects } from '../../../feature-libs/kn-br-order/core/store/effects/kn-br-invoice.effects';
import { knBrInvoiceReducer } from '../../../feature-libs/kn-br-order/core/store/reducers/kn-br-invoice.reducer';
import { KN_BR_INVOICE_FEATURE } from './kn-br-invoice.state';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(KN_BR_INVOICE_FEATURE, knBrInvoiceReducer),
    EffectsModule.forFeature([KnBrInvoiceEffects]),
  ],
})
export class KnBrInvoiceStateModule { }
