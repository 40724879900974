<ng-container *ngIf="accountSummaryResult$ | async as accSummaryResult">
  <div class="col-md-12 float-left border p-3 mb-4">
    <div class="col-lg-4 p-0 float-left">
      <label class="color-font font-text-600 mb-1">
        {{ 'knbrAccountSummary.label.accountNumber' | cxTranslate }}
      </label>
      <p class="text-muted mb-2">
        {{ accSummaryResult?.b2bUnitData?.uid | removeLeadingZeros }}
      </p>

      <label class="color-font font-text-600 mb-1">
        {{ 'knbrAccountSummary.label.accountName' | cxTranslate }}
      </label>
      <p class="text-muted mb-2">
        {{ accSummaryResult.b2bUnitData.name }}
      </p>

      <label class="color-font font-text-600 mb-1">
        {{ 'knbrAccountSummary.label.address' | cxTranslate }}
      </label>
      <p class="text-muted mb-0">
        {{ accSummaryResult.billingAddress.line1 }}
      </p>
      <p class="text-muted mb-0">
        {{ accSummaryResult.billingAddress.line2 }}
      </p>
      <p class="text-muted mb-0">
        {{ accSummaryResult.billingAddress.town }}
      </p>
      <p class="text-muted mb-0">
        {{ accSummaryResult.billingAddress.country.isocode + ', ' + accSummaryResult.billingAddress.postalCode }}
      </p>
    </div>

    <div class="col-lg-4 p-0 float-left">
      <label class="color-font font-text-600 mb-1">
        {{ 'knbrAccountSummary.label.accountTodaysBalance' | cxTranslate }}
      </label>
      <p class="text-muted mb-2">
        {{ accSummaryResult.amountBalanceData.openBalance }}
      </p>

      <label class="color-font font-text-600 mb-1">
        {{ 'knbrAccountSummary.label.accountStatement' | cxTranslate }}
      </label>
      <p class="text-muted mb-2">
        <label class="cursor-pointer"
          ><i class="far fa-file-pdf" aria-hidden="true" (click)="downloadInvoiceHeaderPDF()"></i
        ></label>
      </p>
    </div>
    <div class="col-lg-4 p-0 float-left">
      <table class="table" *ngIf="accSummaryResult?.balanceRanges?.entry.length > 0">
        <thead>
          <tr>
            <th></th>
            <th class="text-left pt-0">
              <label class="color-font font-text-600 mb-1">
                {{ 'knbrAccountSummary.label.accountDueItems' | cxTranslate }}
              </label>
            </th>
            <th class="text-left pt-0">
              <label class="color-font font-text-600 mb-1">
                {{ 'knbrAccountSummary.label.accountNotDue' | cxTranslate }}
              </label>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let due of accSummaryResult?.balanceRanges?.entry; let i = index">
            <td>{{ due.key }}</td>
            <td>{{ due.value }}</td>
            <td>{{ accSummaryResult?.noDueRanges?.entry[i]?.value }}</td>
          </tr>
          <tr>
            <td>{{ 'knbrAccountSummary.label.pastDueBalance' | cxTranslate }}</td>
            <td>{{ accSummaryResult.dueTotal }}</td>
            <td>{{ accSummaryResult.noDueTotalBalance }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-container>
