import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { KnBrQuoteCreateModule } from './kn-br-quote-create/kn-br-quote-create.module';
import { KnBrQuoteSearchResultModule } from './kn-br-quote-list/kn-br-quote-search-result/kn-br-quote-search-result.module';
import { KnBrQuoteSearchModule } from './kn-br-quote-list/kn-br-quote-search/kn-br-quote-search.module';
import { KnBrQuoteDetailModule } from './kn-br-quote-detail/kn-br-quote-detail.module';
import { ConfigModule } from '@spartacus/core';
import { knBrOccQuoteConfig } from '../../occ/config/kn-br-occ-quote-config';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    KnBrQuoteSearchResultModule,
    KnBrQuoteSearchModule,
    KnBrQuoteCreateModule,
    KnBrQuoteDetailModule,
    ConfigModule.withConfig(knBrOccQuoteConfig),
  ],
})
export class KnBrQuoteModule {}
