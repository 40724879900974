// import { ModalService } from '@spartacus/storefront';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FileUploadControl } from '@iplab/ngx-file-upload';
import { CmsService, ContentSlotData, GlobalMessageService, GlobalMessageType } from '@spartacus/core';
import { KnBrDocViewerComponent } from 'src/app/common/kn-br-doc-viewer/kn-br-doc-viewer.component';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActiveCartService } from '@spartacus/cart/base/core';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';

@Component({
  selector: 'kn-br-draft-order-attachment-link',
  templateUrl: './kn-br-draft-order-attachment-link.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class KnBrDraftOrderAttachmentLinkComponent implements OnInit {
  draftOrder: any;
  public fileUploadControl = new FileUploadControl().setListVisibility(false).setValidators([Validators.required]);
  components: ContentSlotData;
  modalRef;
  constructor(
    protected activeCartService: ActiveCartFacade,
    protected knBrCommonService: KnBrCommonService,
    protected globalMessageService: GlobalMessageService,
    protected cdr: ChangeDetectorRef,
    protected modalService: NgbModal,
    protected cmsService: CmsService
  ) {}

  ngOnInit(): void {
    this.activeCartService.getActive().subscribe((response) => {
      this.draftOrder = response as any;
      this.cdr.markForCheck();
    });
    this.fileUploadControl.valueChanges.subscribe((value) => {
      if (value && value.length) this.onSubmit();
    });
  }

  onSubmit() {
    if (this.draftOrder && !this.draftOrder?.cartAttachment) {
      const formData: FormData = new FormData();
      formData.append('attachment', this.fileUploadControl.value[0]);
      this.knBrCommonService.uploadDraftOrderAttachment(formData);
    } else {
      this.globalMessageService.add({ key: 'knBrMessages.removeFileFirst' }, GlobalMessageType.MSG_TYPE_WARNING);
    }
    this.fileUploadControl.clear();
  }
  removeFile() {
    this.knBrCommonService.deleteDraftOrderAttachment(this.draftOrder.cartAttachment);
  }

  isDisabled() {
    this.cmsService
      .getContentSlot('KnBrDraftOrderHeaderSlot')
      .subscribe((value) => (this.components = value))
      .unsubscribe();

    if (
      (this.components &&
        this.components.components &&
        this.components.components.length &&
        this.components.components[0].flexType === 'KnBrDraftOrderHeaderComponentReadOnly') ||
      !this.draftOrder.editable
    ) {
      return true;
    }
    return false;
  }

  openModal() {
    let modalInstance: any;
    this.modalRef = this.modalService.open(KnBrDocViewerComponent, {
      centered: true,
      size: 'lg',
    });
    modalInstance = this.modalRef.componentInstance;
    modalInstance.url = this.draftOrder.docURL;
    modalInstance.attachmentName = this.draftOrder.cartAttachment;
  }
}
