import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CurrentProductService, ProductAttributesComponent } from '@spartacus/storefront';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'cx-product-features',
  templateUrl: './sloan-product-features.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SloanProductFeaturesComponent extends ProductAttributesComponent {
  productFeatureList$: Observable<any[]> = this.product$.pipe(map((p: any) => this.extractProductFeatures(p)));

  constructor(protected currentProductService: CurrentProductService) {
    super(currentProductService);
  }

  protected extractProductFeatures(product: any): Observable<any>[] {
    if (!product.classifications || product.classifications.length < 1) {
      return [];
    }

    return _.remove(_.flatMap(product.classifications, 'features'), (obj) => _.includes(obj.code, 'features_l'));
  }

  public isHTML(str) {
    const doc = new DOMParser().parseFromString(str, 'text/html');
    return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
  }
}
