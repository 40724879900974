import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SloanQuoteHeaderComponent } from './sloan-quote-header.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { FormErrorsModule, SpinnerModule } from '@spartacus/storefront';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { KnBrDirectiveModule } from 'src/app/shared/directive/kn-br-directive.module';
import { KnBrQuoteRejectCommentsModule } from 'src/feature-libs/kn-br-cart/quote/components/kn-br-quote/kn-br-quote-detail/kn-br-quote-header/kn-br-quote-reject-comments/kn-br-quote-reject-comments.module';

@NgModule({
  declarations: [SloanQuoteHeaderComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    UrlModule,
    I18nModule,
    FormErrorsModule,
    NgSelectModule,
    NgbModule,
    KnBrQuoteRejectCommentsModule,
    SpinnerModule,
    KnBrDirectiveModule,
    ConfigModule.withConfig({
      cmsComponents: {
        KnBrQuoteHeaderComponent: {
          component: SloanQuoteHeaderComponent,
        },
        KnBrQuoteHeaderComponentReadOnly: {
          component: SloanQuoteHeaderComponent,
        },
      },
    } as CmsConfig),
  ],
})
export class SloanQuoteHeaderModule { }
