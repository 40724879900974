import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[knBrAlphaNumericOnly]',
})
export class KnBrAlphaNumericOnlyDirective {
  readonly regexStrNum = '^[-a-zA-Z0-9!@#$&()?=_%:;`.+,/"\' ]*$';

  constructor(private e1: ElementRef) {}

  // Check for keypress event

  @HostListener('keypress', ['$event'])
  onKeyPress(event) {
    return new RegExp(this.regexStrNum).test(event.key);
  }

  // Check for copy-paste
  @HostListener('paste', ['$event'])
  blockPaste(event: ClipboardEvent) {
    this.validateFields(event);
  }

  validateFields(event: ClipboardEvent) {
    event.preventDefault();
    const pasteData = event.clipboardData.getData('text/plain').replace(/[^-a-zA-Z0-9!@#$&()?=_%:;`.+,/"\' ]/g, '');
    document.execCommand('insertHTML', false, pasteData);
  }
}
