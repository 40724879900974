import { KnBrOrderHistoryService } from 'src/feature-libs/kn-br-order/core/facade/kn-br-order-history.service';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { GlobalMessageService, GlobalMessageType, LoggerService, normalizeHttpError, RoutingService } from '@spartacus/core';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as _ from 'lodash';
import {
  KnBrCreateReturnOrderFailure,
  KnBrCreateReturnOrderSuccess,
  KnBrOrderHistoryActions,
  KnBrOrderHistoryActionTypes,
  KnBrOrderHistorySearchFailure,
  KnBrOrderHistorySearchSuccess,
  KnBrOrderHistoryQuickSearchSuccess,
  KnBrOrderReOrderFailure,
  KnBrOrderReOrderSuccess,
  KnBrOrderCancelFailure,
  KnBrOrderCancelSuccess,
  KnBrOrderFormsProductsSearchSuccess,
  KnBrOrderFormsProductsSearchFailure,
  KnBrEditOrderSuccess,
  KnBrEditOrderFailure,
} from '../actions/kn-br-order-history.action';
import { KnBrOrderHistoryAdapter } from '../../connectors/kn-br-order-history.adapter';
import { KnBrCartContextService } from 'src/feature-libs/kn-br-cart/base/core/facade/kn-br-cart-context.service';

@Injectable()
export class KnBrOrderHistoryEffects {
  protected logger = inject(LoggerService);
  searchOrders$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrOrderHistoryActionTypes.KnBrOrderHistorySearchAction),
    mergeMap((action) =>
      this.orderHistoryAdapter.search(action.payload).pipe(
        map((data: any) => {
          return action.payload?.isWidget
            ? new KnBrOrderHistoryQuickSearchSuccess(data)
            : new KnBrOrderHistorySearchSuccess(data);
        }),
        catchError((error: Error) => {
          this.messageService.add({ key: 'knBrMessages.error' }, GlobalMessageType.MSG_TYPE_ERROR);
          return of(new KnBrOrderHistorySearchFailure(error));
        })
      )
    )
  )
  );

  searchOrdersFailure$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrOrderHistoryActionTypes.KnBrOrderHistorySearchFailureAction),
    map((action) => {
      this.messageService.add({ key: 'knBrMessages.error' }, GlobalMessageType.MSG_TYPE_ERROR);
    })
  ),
    { dispatch: false }
  );

  reorder$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrOrderHistoryActionTypes.KnBrOrderReOrderAction),
    mergeMap((action) =>
      this.orderHistoryAdapter.reorder(action.payload).pipe(
        map((data: any) => new KnBrOrderReOrderSuccess(data)),
        catchError((error: Error) => {
          return of(new KnBrOrderReOrderFailure(error));
        })
      )
    )
  )
  );

  reorderSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrOrderHistoryActionTypes.KnBrOrderReOrderCopySuccessAction),
    map((action) => {
      this.knBrCartContextService.set(action.payload.code);
      this.messageService.add(
        { key: 'knbrOrderDetails.reOrderMessage.success' },
        GlobalMessageType.MSG_TYPE_CONFIRMATION
      );
      this.routeService.go({ cxRoute: 'cartDetails', params: { code: action.payload.code } });
    })
  ),
    { dispatch: false }
  );

  reorderFailure$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrOrderHistoryActionTypes.KnBrOrderReOrderCopyFailureAction),
    map((action) =>
      this.messageService.add({ key: 'knbrOrderDetails.reOrderMessage.error' }, GlobalMessageType.MSG_TYPE_ERROR)
    )
  ),
    { dispatch: false }
  );

  cancelOrder$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrOrderHistoryActionTypes.KnBrOrderCancelAction),
    mergeMap((action) =>
      this.orderHistoryAdapter.cancelOrder(action.payload).pipe(
        map((data: any) => {
          data = {};
          data.orderCode = action.payload.orderCode;
          return new KnBrOrderCancelSuccess(data);
        }),
        catchError((error) => {
          return of(new KnBrOrderCancelFailure(normalizeHttpError(error, this.logger)));
        })
      )
    )
  )
  );

  cancelOrderSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrOrderHistoryActionTypes.KnBrOrderCancelSuccessAction),
    map((action) => {
      this.messageService.add({ key: 'knbrCancelOrder.cancel.success' }, GlobalMessageType.MSG_TYPE_CONFIRMATION);
      this.routeService.go({
        cxRoute: 'orderCancelConfirmation',
        params: { code: action.payload.orderCode, type: 'Cancel' },
      });
    })
  ),
    { dispatch: false }
  );

  cancelOrderFailure$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrOrderHistoryActionTypes.KnBrOrderCancelFailureAction),
    map((action) => {
      if (action.payload.details) {
        this.messageService.add({ raw: action.payload.details[0].message }, GlobalMessageType.MSG_TYPE_ERROR);
      } else {
        map((action) =>
          this.messageService.add({ key: 'knbrCancelOrder.cancel.error' }, GlobalMessageType.MSG_TYPE_ERROR)
        );
      }
    })
  ),
    { dispatch: false }
  );

  createReturnOrder$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrOrderHistoryActionTypes.KnBrCreateReturnOrderAction),
    mergeMap((action) =>
      this.orderHistoryAdapter.createReturnOrder(action.payload).pipe(
        map((data: any) => {
          data.orderCode = action.payload.orderCode;
          return new KnBrCreateReturnOrderSuccess(data);
        }),
        catchError((error) => {
          return of(new KnBrCreateReturnOrderFailure(normalizeHttpError(error, this.logger)));
        })
      )
    )
  )
  );

  createReturnOrderSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrOrderHistoryActionTypes.KnBrCreateReturnOrderSuccessAction),
    map((action) => {
      this.messageService.add({ key: 'knBrOrderReturn.create.success' }, GlobalMessageType.MSG_TYPE_CONFIRMATION);
      this.routeService.go({
        cxRoute: 'orderReturnConfirmation',
        params: { code: action.payload.orderCode, type: 'Return', rma: action.payload.rma },
      });
    })
  ),
    { dispatch: false }
  );

  createReturnOrderFailure$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrOrderHistoryActionTypes.KnBrCreateReturnOrderFailureAction),
    map((action) => {
      if (action.payload.details) {
        this.messageService.add({ raw: action.payload.details[0].message }, GlobalMessageType.MSG_TYPE_ERROR);
      } else {
        this.messageService.add({ key: 'knBrOrderReturn.create.error' }, GlobalMessageType.MSG_TYPE_ERROR);
      }
    })
  ),
    { dispatch: false }
  );

  orderFormsProductSearch$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrOrderHistoryActionTypes.KnBrOrderFormsProductsSearchAction),
    mergeMap((action) =>
      this.orderHistoryAdapter.orderFormsProductSearch(action.payload).pipe(
        map((data: any) => {
          data.rowIndex = action.payload?.rowindex;
          return new KnBrOrderFormsProductsSearchSuccess(data);
        }),
        catchError((error) => {
          return of(new KnBrOrderFormsProductsSearchFailure(normalizeHttpError(error, this.logger)));
        })
      )
    )
  )
  );

  orderFormsProductSearchFailure$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrOrderHistoryActionTypes.KnBrOrderFormsProductsSearchFailureAction),
    map((action) => {
      if (action.payload.details) {
        this.messageService.add({ raw: action.payload.details[0].message }, GlobalMessageType.MSG_TYPE_ERROR);
      } else {
        this.messageService.add({ key: 'knBrOrderReturn.create.error' }, GlobalMessageType.MSG_TYPE_ERROR);
      }
    })
  ),
    { dispatch: false }
  );

  editOrder$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrOrderHistoryActionTypes.KnBrEditOrderAction),
    mergeMap((action) =>
      this.orderHistoryAdapter.editOrder(action.payload).pipe(
        map((data: any) => {
          const result = {
            orderCode: action.payload.orderCode,
            orderNumber: action.payload.orderCode,
          };
          return new KnBrEditOrderSuccess(result);
        }),
        catchError((error) => {
          return of(new KnBrEditOrderFailure(normalizeHttpError(error, this.logger)));
        })
      )
    )
  )
  );

  editOrderSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrOrderHistoryActionTypes.KnBrEditOrderSuccessAction),
    map((action) => {
      this.messageService.add(
        { key: 'knbrOrderDetails.editOrder.successMsg' },
        GlobalMessageType.MSG_TYPE_CONFIRMATION
      );
      this.routeService.go({
        cxRoute: 'orderEditConfirmation',
        params: { code: action.payload.orderCode, type: 'EditOrder', orderNumber: action.payload.orderNumber },
      });
    })
  ),
    { dispatch: false }
  );

  editOrderFailure$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrOrderHistoryActionTypes.KnBrEditOrderFailureAction),
    map((action) => {
      if (action.payload.details) {
        this.messageService.add({ raw: action.payload.details[0].message }, GlobalMessageType.MSG_TYPE_ERROR);
      } else {
        this.messageService.add({ key: 'knbrOrderDetails.editOrder.errorMsg' }, GlobalMessageType.MSG_TYPE_ERROR);
      }
    })
  ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions<KnBrOrderHistoryActions>,
    private orderHistoryAdapter: KnBrOrderHistoryAdapter,
    private messageService: GlobalMessageService,
    private knBrCartContextService: KnBrCartContextService,
    private routeService: RoutingService,
    private knBrOrderHistoryService: KnBrOrderHistoryService
  ) { }
}
