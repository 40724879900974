<div
  class="bg-white customersearch-list-titles table-content border-top float-left w-100 pt-2 pb-2 pl-3 pr-3 font-weight-normal d-flex align-items-center"
  *ngIf="item"
>
  <div class="col-md-1 text-center padding-res-0 float-left">
    <ng-container *ngIf="item.images">
      <cx-media [container]="item.images" format="product"></cx-media>
    </ng-container>
  </div>
  <div
    class="pl-0 float-left title-wrap font-size color-font"
    [ngClass]="availability && availability.length ? 'col-md-2' : 'col-md-3'"
  >
    <span class="res-titles"> {{ 'orderForms.label.item' | cxTranslate }} </span>
    <a
      [routerLink]="{ cxRoute: 'product', params: item } | cxUrl"
      *ngIf="item.name"
      [innerHtml]="item.name"
      class="mb-0"
    ></a>
    <label class="mb-0">{{ item.code }}</label>
  </div>
  <ng-container *ngIf="availability && availability.length">
    <div
      *ngIf="item.availability; else other_content"
      class="col-md-3 pl-0 text-center title-wrap padding-res-0 float-left font-size color-font"
    >
      <span class="res-titles"> {{ 'orderForms.label.availability' | cxTranslate }}</span>
      <div class="col-12 float-left border-bottom pb-1 pl-0 d-flex align-items-end">
        <div class="col-4 pl-0 float-left font-sm-size">{{ 'orderForms.label.plant' | cxTranslate }}</div>
        <div class="col-4 pl-0 float-left font-sm-size">{{ 'orderForms.label.inventory' | cxTranslate }}</div>
        <div class="col-4 pl-0 float-left font-sm-size">{{ 'orderForms.label.estShipDate' | cxTranslate }}</div>
      </div>
      <div *ngFor="let syncItem of item.availability.syncResponse.items" class="col-12 pl-0 float-left pt-1">
        <div class="col-12 pt-1 pb-1 pl-0 pr-0 float-left border-bottom" *ngFor="let innerItem of syncItem?.items">
          <div class="col-4 pl-0 float-left font-sm-size">
            {{ innerItem.plantDescription ? innerItem.plantDescription : innerItem.plant }}
          </div>
          <div class="col-4 pl-0 float-left font-sm-size">
            {{ innerItem.distAvailability }}
          </div>
          <div class="col-4 pl-0 pr-0 float-left font-sm-size">{{ innerItem.shipDate }}</div>
        </div>
      </div>
    </div>
    <ng-template #other_content>
      <div class="col-md-3 text-center title-wrap padding-res-0 float-left font-size color-font"></div>
    </ng-template>
  </ng-container>
  <div class="col-md-2 text-center float-left title-wrap font-size color-font">
    <span class="res-titles"> {{ 'orderForms.label.listPrice' | cxTranslate }}</span>
    {{ item.price?.formattedValue }}
  </div>
  <ng-container *ngIf="isOrderForm">
    <div
      class="float-left text-center title-wrap font-size color-font"
      [ngClass]="availability && availability.length ? 'col-md-1 pl-0 pr-0' : 'col-md-2'"
    >
      <ng-container *ngIf="!item.errorMsg">
        <span class="res-titles">{{ 'orderForms.label.qty' | cxTranslate }} </span>
        <input
          type="text"
          [formControl]="quantity"
          class="form-control w-100 mb-0"
          (blur)="onchageQuantity($event)"
          [disabled]="!item.price || !item.price?.formattedValue"
          knBrNumbersOnly
          [ngClass]="availability && availability.length ? 'pl-1 pr-1' : 'pl-1 pr-1'"
          placeholder="0"
        />
      </ng-container>
      <ng-container *ngIf="item.errorMsg">
        <span class="res-titles"> {{ 'orderForms.label.qty' | cxTranslate }}</span>
        {{ item.errorMsg }}
      </ng-container>
    </div>
    <div class="col-md-2 float-left text-center title-wrap font-size color-font">
      <span class="res-titles"> {{ 'orderForms.label.minQty' | cxTranslate }}</span>
      {{ item.minOrderQuantity }}
    </div>

    <div
      class="float-left text-center title-wrap font-size color-font"
      [ngClass]="availability && availability.length ? 'col-md-1 pl-0 pr-0' : 'col-md-2'"
    >
      <span class="res-titles"> {{ 'orderForms.label.total' | cxTranslate }} </span>
      {{ quantity.value * item.price?.value | number : '1.2-2' }}
    </div>
  </ng-container>
  <ng-container *ngIf="!isOrderForm && !item.errorMsg">
    <div class="col-md-5 float-left text-center title-wrap font-size color-font">
      <span class="res-titles">{{ 'orderForms.label.selectProduct' | cxTranslate }} </span>
      <div class="form-check mb-1">
        <input
          class="form-check-input"
          (change)="onSelectProduct($event)"
          [formControl]="selectProduct"
          type="checkbox"
          [disabled]="!item.price || !item.price?.formattedValue"
          value=""
        />
        <label class="form-check-label">{{ item.code }} </label>
      </div>
    </div>
  </ng-container>
</div>
