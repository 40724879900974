import { Component, OnInit } from '@angular/core';
import { CardComponent } from '@spartacus/storefront';
import { KnBrAddressCardComponent } from 'src/feature-libs/kn-br-order/components/kn-br-customer360/kn-br-address-book/kn-br-address-card/kn-br-address-card.component';

@Component({
  selector: 'sloan-address-card',
  templateUrl: './sloan-address-card.component.html',
  styleUrls: ['./sloan-address-card.component.scss'],
})
export class SloanAddressCardComponent extends KnBrAddressCardComponent implements OnInit {
  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
}
