import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SloanBannerComponent } from './sloan-banner.component';
import { RouterModule } from '@angular/router';
import { ConfigModule, CmsConfig } from '@spartacus/core';
import { GenericLinkModule, MediaModule } from '@spartacus/storefront';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    GenericLinkModule,
    MediaModule,
    ConfigModule.withConfig({
      cmsComponents: {
        SimpleResponsiveBannerComponent: {
          component: SloanBannerComponent,
        },
        BannerComponent: {
          component: SloanBannerComponent,
        },
        SimpleBannerComponent: {
          component: SloanBannerComponent,
        },
      },
    } as CmsConfig),
  ],
  declarations: [SloanBannerComponent],
})
export class SloanBannerModule {}
