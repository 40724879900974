import { KnBrOrderFormsModule } from './kn-br-order-forms/kn-br-order-forms.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KnBrOrderImportModule } from './kn-br-order-import/kn-br-order-import.module';
import { KnBrQuickOrderModule } from './kn-br-quick-order/kn-br-quick-order.module';
import { KnBrQuickEntryModule } from './kn-br-quick-entry/kn-br-quick-entry.module';
import { I18nModule, ConfigModule, CmsConfig, I18nConfig, provideConfig } from '@spartacus/core';
import { KnBrOrderToolsComponent } from './kn-br-order-tools.component';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { knbrOrderToolsTranslationChunksConfig, knbrOrderToolsTranslations } from './assests/translations/translation';
import { KnBrI18nModule } from 'src/app/common/i18n/i18n.module';

@NgModule({
  declarations: [KnBrOrderToolsComponent],
  imports: [
    CommonModule,
    I18nModule,
    RouterModule,
    NgbModule,
    KnBrOrderImportModule,
    KnBrQuickOrderModule,
    KnBrQuickEntryModule,
    KnBrOrderFormsModule,
    ConfigModule.withConfig({
      cmsComponents: {
        KnBrOrderToolsComponent: {
          component: () => import('./kn-br-order-tools.component').then((c) => c.KnBrOrderToolsComponent),
        },
      },
    } as CmsConfig),
  ],
  providers: [
    provideConfig(<I18nConfig>{
      i18n: {
        resources: knbrOrderToolsTranslations,
        chunks: knbrOrderToolsTranslationChunksConfig,
        fallbackLang: 'en',
      },
    }),]
})
export class KnBrOrderToolsModule { }
