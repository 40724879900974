import { SloanDraftOrderItemsModule } from './sloan-draft-order-items/sloan-draft-order-items.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SloanDraftOrderDetailHeaderModule } from './sloan-draft-order-detail-header/sloan-draft-order-detail-header.module';
import { SloanDraftOrderDownloadLinkModule } from './sloan-draft-order-download-link/sloan-draft-order-download-link.module';
import { SloanDraftOrderCommentsPopupModule } from './sloan-draft-order-comments-popup/sloan-draft-order-comments-popup.module';
import { SloanDraftOrderAttachmentLinkModule } from './sloan-draft-order-attachment-link/sloan-draft-order-attachment-link.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SloanDraftOrderDetailHeaderModule,
    SloanDraftOrderDownloadLinkModule,
    SloanDraftOrderItemsModule,
    SloanDraftOrderCommentsPopupModule,
    SloanDraftOrderAttachmentLinkModule,
  ],
})
export class SloanDraftOrderDetailModule {}
