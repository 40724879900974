import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  GlobalMessageService,
  GlobalMessageType,
  RoutingService,
  WindowRef,
  UserAddressService,
} from '@spartacus/core';
import { map, mergeMap } from 'rxjs/operators';
import {
  KnBrCustomerAddUpdateContext,
  KnBrCustomerContextActions,
  KnBrCustomerContextActionTypes,
  KnBrCustomerLoadSuccessContext,
  KnBrCustomerRemoveContext,
  KnBrCustomerResetContext,
} from './kn-br-customer-context.actions';
import { KnBrCustomerContextAdapter } from './kn-br-customer-context.adapter';
import { KnBrCartResetContext } from 'src/feature-libs/kn-br-cart/base/core/store/actions/kn-br-cart-context.actions';
import { KnBrDraftOrdersService } from 'src/feature-libs/kn-br-cart/draft-order/core/facade/kn-br-draft-orders.service';
import { KnBrQuotesService } from 'src/feature-libs/kn-br-cart/quote/core/facade/kn-br-quotes.service';
import { KnBrQuoteRemoveContext } from 'src/feature-libs/kn-br-cart/quote/core/store/actions/kn-br-quote-context.actions';
import { CustomerData } from 'src/feature-libs/kn-br-user/kn-br-account/root/model/customer.model';

@Injectable()
export class KnBrCustomerContextEffects {

  loadCustomerContext$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrCustomerContextActionTypes.KnBrCustomerContextLoadAction),
    map((x) => {
      const context = this.knBrCustomerContextAdapter.get();
      return new KnBrCustomerLoadSuccessContext(context);
    })
  )
  );

  addUpdateCustomerContext$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrCustomerContextActionTypes.KnBrCustomerContextAddUpdateAction),
    map((action: KnBrCustomerAddUpdateContext) => action.payload),
    mergeMap((customer: CustomerData) => {
      const copyObject = JSON.parse(this.winRef.sessionStorage.getItem('copyObject'));
      this.winRef.sessionStorage.removeItem('copyObject');
      if (copyObject) {
        if (copyObject.isQuote) {
          this.knBrQuoteService.copy({ referenceQuoteId: copyObject.code });
        } else {
          this.knBrDraftOrderService.copy({ referenceCartId: copyObject.code, name: copyObject?.name });
        }
        return [];
      }
      const redirectUrl = JSON.parse(this.winRef.sessionStorage.getItem('redirect'));
      this.winRef.sessionStorage.removeItem('redirect');
      if (redirectUrl) {
        this.routingService.go({ cxRoute: redirectUrl.route, params: redirectUrl.params });
      }
      const context = this.getCustomerContext();
      if (!context || context.customerNumber !== customer.customerNumber) {
        this.knBrCustomerContextAdapter.set(customer);
        // this.messageService.add({ key: 'customer.context.set' }, GlobalMessageType.MSG_TYPE_CONFIRMATION);
        this.userAddressService.loadAddresses();
        return [new KnBrQuoteRemoveContext(), new KnBrCartResetContext()];
      }
      return [];
    })
  )
  );

  removeCustomerContext$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrCustomerContextActionTypes.KnBrCustomerContextRemoveAction),
    map((action: KnBrCustomerRemoveContext) => action.payload),
    mergeMap((customer: any) => {
      this.knBrCustomerContextAdapter.remove();
      this.routingService.go({ cxRoute: 'home' });
      this.messageService.add({ key: 'customer.context.remove' }, GlobalMessageType.MSG_TYPE_CONFIRMATION);
      return [new KnBrQuoteRemoveContext(), new KnBrCartResetContext()];
    })
  )
  );

  resetCustomerContext$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrCustomerContextActionTypes.KnBrCustomerContextResetAction),
    map((action: KnBrCustomerResetContext) => action.payload),
    mergeMap((customer: any) => {
      this.knBrCustomerContextAdapter.remove();
      return [new KnBrQuoteRemoveContext(), new KnBrCartResetContext()];
    })
  )
  );

  getCustomerContext() {
    return this.knBrCustomerContextAdapter.get();
  }

  constructor(
    private actions$: Actions<KnBrCustomerContextActions>,
    private messageService: GlobalMessageService,
    private knBrCustomerContextAdapter: KnBrCustomerContextAdapter,
    private routingService: RoutingService,
    private winRef: WindowRef,
    private knBrQuoteService: KnBrQuotesService,
    private knBrDraftOrderService: KnBrDraftOrdersService,
    protected userAddressService: UserAddressService
  ) { }
}
