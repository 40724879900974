import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { IconModule, ListNavigationModule } from '@spartacus/storefront';
import { SloanProductActionBarComponent } from './sloan-product-action-bar.component';
import { SloanAddToCartModule } from '../sloan-add-to-cart/sloan-add-to-cart.module';
import { SloanAddToQuoteModule } from '../sloan-add-to-quote/sloan-add-to-quote.module';

@NgModule({
  declarations: [SloanProductActionBarComponent],
  imports: [
    CommonModule,
    ListNavigationModule,
    UrlModule,
    I18nModule,
    SloanAddToQuoteModule,
    SloanAddToCartModule,
    IconModule,
    NgbTooltipModule,
    ConfigModule.withConfig({
      cmsComponents: {
        KnBrProductActionBarComponent: {
          component: SloanProductActionBarComponent,
        },
      },
    } as CmsConfig),
  ],
  exports: [SloanProductActionBarComponent],
})
export class SloanProductActionBarModule { }
