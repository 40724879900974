import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  AuthConfigService,
  CmsConfig,
  ConfigModule,
  GlobalMessageService,
  I18nModule,
  RoutingService,
  UrlModule,
} from '@spartacus/core';
import {
  FormErrorsModule,
  PageComponentModule,
  PageLayoutModule,
  PageSlotModule,
  SpinnerModule,
} from '@spartacus/storefront';
import { ForgotPasswordComponentService } from '@spartacus/user/profile/components';
import { UserPasswordFacade } from '@spartacus/user/profile/root';

import { SloanForgotPasswordFormComponent } from './sloan-forgot-password.component';
import { SloanForgotPasswordComponentService } from 'src/app/services/sloan/sloan-forgot-password-component.service';

@NgModule({
  declarations: [SloanForgotPasswordFormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    UrlModule,
    I18nModule,
    FormErrorsModule,
    SpinnerModule,
    PageComponentModule,
    PageLayoutModule,
    PageSlotModule,
    NgbModule,
    ConfigModule.withConfig({
      cmsComponents: {
        KnBrForgotPasswordFormComponent: {
          component: SloanForgotPasswordFormComponent,
          providers: [
            {
              provide: ForgotPasswordComponentService,
              useClass: SloanForgotPasswordComponentService,
              deps: [UserPasswordFacade, RoutingService, AuthConfigService, GlobalMessageService],
            },
          ],
        },
      },
    } as CmsConfig),
  ],
  exports: [SloanForgotPasswordFormComponent],
})
export class SloanForgotPasswordModule { }
