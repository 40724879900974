import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { PageMetaService, ProductSearchPage } from '@spartacus/core';
import { ICON_TYPE, ProductListComponentService, ViewModes } from '@spartacus/storefront';
import { Observable, Subscription } from 'rxjs';
import { KnBrProductSelectService } from 'src/feature-libs/kn-br-product/core/service/kn-br-product-select.service';
import { ProductViewModeService } from 'src/feature-libs/kn-br-product/core/service/product-view-mode-service';
import { KnBrProductActionBarComponent } from 'src/feature-libs/kn-br-product/components/kn-br-product-action-bar/kn-br-product-action-bar.component';

@Component({
  selector: 'kn-br-product-action-bar',
  templateUrl: './sloan-product-action-bar.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class SloanProductActionBarComponent extends KnBrProductActionBarComponent implements OnDestroy {
  constructor(
    protected pageMetaService: PageMetaService,
    protected productListComponentService: ProductListComponentService,
    protected viewModeService: ProductViewModeService,
    protected knBrProductService: KnBrProductSelectService
  ) {
    super(pageMetaService, productListComponentService, viewModeService, knBrProductService);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
