import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { shareReplay } from 'rxjs/operators';
import { KnBrCustomerContextService } from 'src/app/store/kn-br-customer-context/kn-br-customer-context.service';
import { KNBR_FACT_SHEET_DETAILS, KN_BR_CUSTOMER_CURRENT } from 'src/constants/api.endpoints.constant';

@Injectable({
  providedIn: 'root',
})
export class KnBrFactSheetAdapter {
  customerNumber: string;
  constructor(
    private endPointService: OccEndpointsService,
    private httpClient: HttpClient,
    protected knBrCustomerContextService: KnBrCustomerContextService
  ) {
    this.knBrCustomerContextService.getActiveCustomerNumber().subscribe((response) => {
      this.customerNumber = response ? response : null;
    });
  }

  getFactSheetDetails() {
    const CUSTOMER_NUMBER = this.customerNumber ? this.customerNumber : KN_BR_CUSTOMER_CURRENT;

    return this.httpClient.get(this.endPointService.buildUrl(KNBR_FACT_SHEET_DETAILS(CUSTOMER_NUMBER)));
  }
}
