import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CmsService, GlobalMessageService } from '@spartacus/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';
import { KnBrDraftOrderAttachmentLinkComponent } from 'src/feature-libs/kn-br-cart/draft-order/components/kn-br-draft-order/kn-br-draft-order-detail/kn-br-draft-order-attachment-link/kn-br-draft-order-attachment-link.component';

@Component({
  selector: 'sloan-draft-order-attachment-link',
  templateUrl: './sloan-draft-order-attachment-link.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class SloanDraftOrderAttachmentLinkComponent extends KnBrDraftOrderAttachmentLinkComponent implements OnInit {
  constructor(
    protected activeCartService: ActiveCartFacade,
    protected knBrCommonService: KnBrCommonService,
    protected globalMessageService: GlobalMessageService,
    protected cdr: ChangeDetectorRef,
    protected modalService: NgbModal,
    protected cmsService: CmsService
  ) {
    super(activeCartService, knBrCommonService, globalMessageService, cdr, modalService, cmsService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
}
