<ng-container *ngIf="componentData$ | async as data">
  <div class="col-lg-12 float-left p-3 border">
    <div class="w-100 customersearch-title mb-3">
      {{ data.title }}
    </div>
    <ul class="mb-0 border-top" *ngIf="(node$ | async)?.children[0] as nodes">
      <li class="p-2 border-bottom quick-link" *ngFor="let menu of nodes.children">
        <ng-container *ngIf="menu.title && menu.url">
          <cx-generic-link class="d-block" [url]="menu.url" [target]="menu.target">
            {{ menu.title }}
          </cx-generic-link>
        </ng-container>
        <ng-container *ngIf="menu.media as media">
          <ng-container *ngIf="isExternalUrl(media.url); else isLocalUrl">
            <a [href]="media.url" download target="_blank" (click)="knbrLinkClickEvent(media.altText, data.title)">{{
              media.altText
            }}</a>
          </ng-container>
          <ng-template #isLocalUrl>
            <a
              [href]="baseURL + media.url"
              download
              target="_blank"
              (click)="knbrLinkClickEvent(media.altText, data.title)"
              >{{ media.altText }}</a
            >
          </ng-template>
        </ng-container>
      </li>
    </ul>
  </div>
</ng-container>
