import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { KnBrOrderFormsSearchRequest } from 'src/feature-libs/kn-br-order/root/models/kn-br-order.model';
import { KnBrOrderHistoryService } from 'src/feature-libs/kn-br-order/core/facade/kn-br-order-history.service';
import { KnBrDraftOrdersService } from 'src/feature-libs/kn-br-cart/draft-order/core/facade/kn-br-draft-orders.service';

@Component({
  selector: 'knbr-kn-br-order-form-search',
  templateUrl: './kn-br-order-form-search.component.html',
  styleUrls: ['./kn-br-order-form-search.component.scss'],
})
export class KnBrOrderFormSearchComponent implements OnInit, OnDestroy {
  orderForm: UntypedFormGroup;
  isSearchDisabled$: Observable<boolean> = this.knBrOrderHistoryService.orderFormsSearchDisabled$;
  currentPage = 0;
  subscription = new Subscription();
  constructor(
    protected fb: UntypedFormBuilder,
    protected knBrOrderHistoryService: KnBrOrderHistoryService,
    protected cdr: ChangeDetectorRef,
    protected knBrDraftOrderService: KnBrDraftOrdersService
  ) {
    this.subscription.add(
      this.knBrOrderHistoryService.getCreateOrderFormData().subscribe((res) => {
        if (res) {
          this.orderForm.patchValue({ keywords: res, onlyProductId: true, orderFormRadio: 'OrderForm' });
          this.submitForm();
        }
      })
    );
    this.subscription.add(
      this.knBrOrderHistoryService.getOrderForm().subscribe((res) => {
        if (res) {
          if (this.orderForm) {
            this.orderForm.patchValue({ orderFormRadio: 'OrderForm' });
          }
        }
        this.cdr.markForCheck();
      })
    );
    this.subscription.add(
      this.knBrDraftOrderService.quickResetForm$.subscribe((res) => {
        if (res) {
          this.orderForm.reset();
          this.knBrOrderHistoryService.resetOrderFormsData();
        }
      })
    );
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.orderForm = this.fb.group({
      keywords: ['', Validators.required],
      onlyProductId: [false],
      orderFormRadio: ['OrderForm'],
    });
  }

  submitForm() {
    if (this.orderForm.valid) {
      const req: KnBrOrderFormsSearchRequest = {
        keywords: this.orderForm.value.keywords,
        onlyProductIds: this.orderForm.value.onlyProductId,
        currentPage: this.currentPage,
      };
      this.knBrOrderHistoryService.getOrderFormProducts(req);
    } else {
      this.orderForm.markAllAsTouched();
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.knBrOrderHistoryService.setOrderForm(true);
    this.knBrOrderHistoryService.createOrderFormData(null);
    this.knBrDraftOrderService.quickFormReset(false);
  }

  onChangeOrderForm() {
    const orderFormValue = this.orderForm.value.orderFormRadio;
    if (orderFormValue === 'OrderForm') {
      this.knBrOrderHistoryService.setOrderForm(true);
    } else {
      this.knBrOrderHistoryService.setOrderForm(false);
    }
  }
}
