import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ProductActions, ProductSearchService } from '@spartacus/core';
import { PageLayoutService, ProductListComponentService, ViewConfig, ViewModes } from '@spartacus/storefront';
import { take } from 'rxjs/operators';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';
import { ProductViewModeService } from 'src/feature-libs/kn-br-product/core/service/product-view-mode-service';
import { KnBrProductListComponent } from 'src/feature-libs/kn-br-product/components/kn-br-product-list/kn-br-product-list.component';

@Component({
  selector: 'sloan-product-list',
  templateUrl: './sloan-product-list.component.html',
  styleUrls: ['./sloan-product-list.component.scss'],
})
export class SloanProductListComponent extends KnBrProductListComponent implements OnInit, OnDestroy {
  constructor(
    protected pageLayoutService: PageLayoutService,
    protected productListComponentService: ProductListComponentService,
    protected knBrCommonService: KnBrCommonService,
    public scrollConfig: ViewConfig,
    protected viewModeService: ProductViewModeService,
    protected productSearchService: ProductSearchService,
    protected store: Store
  ) {
    super(
      pageLayoutService,
      productListComponentService,
      knBrCommonService,
      scrollConfig,
      viewModeService,
      productSearchService
    );
  }

  ngOnInit() {
    // super.ngOnInit();
    this.isInfiniteScroll = this.scrollConfig.view.infiniteScroll.active;
    this.knBrCommonService.setPDPQuantity(1);
    this.subscription.add(
      this.pageLayoutService.templateName$.pipe(take(1)).subscribe((template) => {
        if (template === 'KnBrSearchResultsGridPageTemplate' || template === 'KnBrSearchResultsListPageTemplate') {
          this.store.dispatch(new ProductActions.SearchProducts({ queryText: '' }));
        }
        this.viewModeService.setViewMode(
          template === 'KnBrProductGridPageTemplate' ||
            template === 'KnBrSearchResultsGridPageTemplate' ||
            template === 'KnBrSearchResultsListPageTemplate'
            ? ViewModes.Grid
            : ViewModes.List
        );
      })
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
