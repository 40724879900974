import { SloanAddToQuoteComponent } from './sloan-add-to-quote.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { IconModule, KeyboardFocusModule, SpinnerModule } from '@spartacus/storefront';
import { SloanSelectQuoteComponent } from './sloan-select-quote/sloan-select-quote.component';
import { KnBrCustomPipesModule } from 'src/app/shared/pipe/kn-br-custom-pipes/kn-br-custom-pipes.module';

@NgModule({
  declarations: [SloanAddToQuoteComponent, SloanSelectQuoteComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    SpinnerModule,
    UrlModule,
    IconModule,
    KeyboardFocusModule,
    NgbModule,
    NgSelectModule,
    FormsModule,
    I18nModule,
    KnBrCustomPipesModule,
    ConfigModule.withConfig({
      cmsComponents: {
        KnBrProductAddToQuoteComponent: {
          component: SloanAddToQuoteComponent,
        },
      },
    } as CmsConfig),
  ],
  exports: [SloanAddToQuoteComponent],
})
export class SloanAddToQuoteModule {}
