import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CmsConfig, ConfigModule, FeaturesConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import {
  // CartCouponModule,
  PromotionsModule,
  IconModule,
  MediaModule,
  ItemCounterModule,
  // ModalModule,
  OutletModule,
  CardModule,
  // CartSharedModule,
} from '@spartacus/storefront';
import { CartCouponModule, CartSharedModule } from '@spartacus/cart/base/components';
import { KnBrOrderSummaryComponent } from './kn-br-order-summary/kn-br-order-summary.component';
import { KnBrCartItemListComponent } from './cart-item-list/kn-br-cart-item-list.component';
import { OrderConfirmationGuard } from '@spartacus/order/components';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    CartCouponModule,
    ReactiveFormsModule,
    UrlModule,
    NgbModule,
    PromotionsModule,
    I18nModule,
    IconModule,
    MediaModule,
    ItemCounterModule,
    FeaturesConfigModule,
    // ModalModule,
    OutletModule,
    CardModule,
    CartSharedModule,
  ],
  declarations: [KnBrCartItemListComponent, KnBrOrderSummaryComponent],
  exports: [KnBrCartItemListComponent, KnBrOrderSummaryComponent],
})
export class KnBrCartSharedModule {}
