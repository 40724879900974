import { SloanDraftOrderItemModule } from './sloan-draft-order-item/sloan-draft-order-item.module';
import { SloanDraftOrderItemsComponent } from './sloan-draft-order-items.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { I18nModule, ConfigModule, CmsConfig } from '@spartacus/core';
import { KnBrQuoteReferenceModule } from 'src/feature-libs/kn-br-cart/draft-order/components/kn-br-draft-order/kn-br-draft-order-detail/kn-br-draft-order-items/kn-br-quote-reference/kn-br-quote-reference.module';

@NgModule({
  declarations: [SloanDraftOrderItemsComponent],
  imports: [
    CommonModule,
    SloanDraftOrderItemModule,
    I18nModule,
    KnBrQuoteReferenceModule,
    ConfigModule.withConfig({
      cmsComponents: {
        KnBrDraftOrderItemsComponent: {
          component: SloanDraftOrderItemsComponent,
        },
        KnBrDraftOrderItemsComponentReadOnly: {
          component: SloanDraftOrderItemsComponent,
        },
      },
    } as CmsConfig),
  ],
})
export class SloanDraftOrderItemsModule { }
