import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { UrlModule, I18nModule, ConfigModule, CmsConfig } from '@spartacus/core';
import { PageComponentModule, PageLayoutModule, PageSlotModule } from '@spartacus/storefront';
import { SloanOrderDetailsOverviewComponent } from './sloan-order-details-overview.component';
import { KnBrCustomPipesModule } from 'src/app/shared/pipe/kn-br-custom-pipes/kn-br-custom-pipes.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
@NgModule({
  declarations: [SloanOrderDetailsOverviewComponent],
  imports: [
    CommonModule,
    RouterModule,
    UrlModule,
    I18nModule,
    NgbTooltipModule,
    PageComponentModule,
    PageLayoutModule,
    PageSlotModule,
    KnBrCustomPipesModule,
    ConfigModule.withConfig({
      cmsComponents: {
        AccountOrderDetailsOverviewComponent: {
          component: SloanOrderDetailsOverviewComponent,
        },
      },
    } as CmsConfig),
    NgSelectModule, FormsModule
  ],
})
export class SloanOrderDetailsOverviewModule {}
