import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { RouterState } from '@angular/router';
// TODO:Spartacus - // TODO:Spartacus - Class UserService has been removed and is no longer part of the public API.
import { RoutingService, WindowRef } from '@spartacus/core';
import { UserAccountService } from '@spartacus/user/account/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { KnBrConfirmationDialogService } from 'src/app/common/kn-br-confirm-dialog/kn-br-confirm-dialog.service';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';
import { KnBrCustomerContextService } from 'src/app/store/kn-br-customer-context/kn-br-customer-context.service';
import { KnBrQuoteSearchResultComponent } from 'src/feature-libs/kn-br-cart/quote/components/kn-br-quote/kn-br-quote-list/kn-br-quote-search-result/kn-br-quote-search-result.component';
import { KnBrQuotesService } from 'src/feature-libs/kn-br-cart/quote/core/facade/kn-br-quotes.service';

@Component({
  selector: 'app-sloan-quote-search-result',
  templateUrl: './sloan-quote-search-result.component.html',
  styleUrls: ['./sloan-quote-search-result.component.scss'],
})
export class SloanQuoteSearchResultComponent extends KnBrQuoteSearchResultComponent implements OnInit, OnDestroy {
  constructor(
    protected currentUserService: UserAccountFacade,
    protected knBrQuoteService: KnBrQuotesService,
    protected routeService: RoutingService,
    protected confirmationDialogService: KnBrConfirmationDialogService,
    protected knBrCustomerContextService: KnBrCustomerContextService,
    protected winRef: WindowRef,
    protected knBrCommonService: KnBrCommonService,
    protected cdr: ChangeDetectorRef
  ) {
    super(
      currentUserService,
      knBrQuoteService,
      routeService,
      confirmationDialogService,
      knBrCustomerContextService,
      winRef,
      knBrCommonService,
      cdr
    );
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
