<div class="col-lg-12 p-0 float-right">
  <div class="border upload-files float-right rounded">
    <ng-container *ngIf="draftOrder?.cartAttachment">
      <label class="m-0 float-left">
        <a (click)="openModal()" class="font-color-knbr-primary download-links float-left font-sm-size">
          {{ draftOrder.cartAttachment }}
        </a>
      </label>
      <button
        class="btn pl-3 float-left mr-1 color-upload-bg text-dark pr-3 pb-2 pt-2 font-size-17 color-white h-auto hover-link rounded-0 border-0"
        (click)="removeFile()"
        *ngIf="draftOrder && !isDisabled()"
      >
        <i class="far fa-trash-alt"></i>
      </button>
    </ng-container>
    <ng-container *ngIf="draftOrder && !isDisabled()">
      <file-upload
        simple
        [control]="fileUploadControl"
        class="d-inline-flex border-0"
        *ngIf="!draftOrder?.cartAttachment"
      >
        <ng-template let-file #placeholder> </ng-template>

        <ng-template let-control="control" #button>
          <i
            class="fas fa-paperclip color-upload-bg text-dark font-size-17 color-white h-auto hover-link rounded-0"
            aria-hidden="true"
          ></i>
        </ng-template>
      </file-upload>
      <button
        *ngIf="draftOrder?.cartAttachment"
        class="float-left btn text-dark font-size-17 color-white h-auto hover-link rounded-0 border-0"
        (click)="onSubmit()"
      >
        <i class="fas fa-paperclip"></i>
      </button>
    </ng-container>
  </div>
</div>
