import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { provideConfig } from '@spartacus/core';
import { knBrOccDraftOrderConfig } from './config/kn-br-occ-draft-order-config';

@NgModule({
  imports: [CommonModule],
  providers: [provideConfig(knBrOccDraftOrderConfig)],
})
export class KnBrDraftOrderOccModule { }
