export const cartCommon = {
  label: {
    name: 'Name',
    number: 'Number',
    status: 'Status',
    requestedDeliveryDate: 'Requested Delivery Date',
    customerPO: 'Customer P.O.',
    jobNameNumber: 'Job Name',
    shipComplete: 'Ship Complete',
    headerNotes: 'Order Notes/Tags',
    referenceCart: 'Reference Draft Order',
    createdBy: 'Created By',
    createdDate: 'Created date',
    lastUpdatedDate: 'Last Updated Date',
    lastModifiedBy: 'Last Update By',
    submitForApproval: 'Submit For Approval',
    checkout: 'Checkout',
    editBtn: 'Edit',
    totalItems: 'Total Items:',
    totalQuantity: 'Total Quantity:',
    totalPrice: 'Total Price:',
    itemDetails: 'Item Details',
    productDetails: 'Product Details',
    plant: 'Plant',
    requestedShipDate: 'Requested Ship Date',
    estimatedShipDate: "Estimated Ship Date",
    listPrice: 'List Price',
    netPrice: 'Net Price',
    quantity: 'Quantity',
    total: 'Total',
    priceAdjustment: 'Price Adjustment',
    quoteReference: 'Quote Reference',
    uom: 'UOM',
    moq: 'MOQ',
    actions: 'Actions',
    cart: 'Cart',
    downloadPDF: 'Download PDF',
    createdOn: 'Created On',
    saveUpdatedCart: 'Save Updated Cart',
    lineNo: 'Line No.',
  },
  createMessage: {
    success: 'Draft order has been created successfully.',
    error: 'Error occurred while creating draft order.',
  },
  copyMessage: {
    success: 'Draft order has been copied successfully.',
    error: 'Error occurred while copying draft order.',
  },
  updateMessage: {
    success: 'Draft order has been updated successfully.',
    error: 'Error occurred while updating draft order.',
  },
  deleteMessage: {
    success: 'Draft order has been deleted successfully.',
    error: 'Error occurred while deleting draft order.',
  },
  invalidQtyMessage: {
    entryMessage: 'Please enter valid quantity (Multiple of MOQs) for this item',
    globalMessage: 'Please enter valid quantity (Multiple of MOQs) for Line Nos {{ linenos }}',
  },
  commentMessage: {
    isGenericGlobalMessage:
      'For Product(s): {{ linenos }}, please fill in required field arc worksheet, drawing # or comments',
    isSinkPatternGlobalMessage:
      'For Product(s): {{ linenos }}, you must choose the drill pattern requirement from the drop down table.',
  },
};

export const cartSearchForm = {
  label: {
    draftOrderName: 'Name',
    accountNumber: 'Account Number',
    accountName: 'Account Name',
    customerPO: 'Customer P.O.',
    jobNameNumber: 'Job Name',
    status: 'Status',
    draftOrderNumber: 'Number',
  },
};

export const cartResult = {
  header: {
    draftOrderNumber: 'Number',
    draftOrderName: 'Name',
    createdBy: 'Created By',
    creationDate: 'Creation Date',
    createdOn: 'Created On',
    accountNo: 'Account No.',
    accountName: 'Account Name',
    customerPO: 'Customer P.O.',
    jobNameNumber: 'Job Name',
    status: 'Status',
    total: 'Total',
    actions: 'Actions',
    job: 'Job',
    updateBy: 'Update By',
  },
  nodataMsg: 'Sorry! draft orders data not found',
  showMore: 'Show More',
};

export const addToCartMessage = {
  success: 'Product(s) are added to  draft order successfully.',
  failure: 'Product(s) are not added to draft order successfully.',
};

export const knbrCart = {
  cartCommon,
  cartSearchForm,
  cartResult,
  addToCartMessage,
};
