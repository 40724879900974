import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { RoutingService } from '@spartacus/core';
import { Subscription } from 'rxjs';
import { KnBrProductSelectService } from 'src/feature-libs/kn-br-product/core/service/kn-br-product-select.service';

@Component({
  selector: 'kn-br-product-grid-item',
  templateUrl: './kn-br-product-grid-item.component.html',
  styleUrls: ['./kn-br-product-grid-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class KnBrProductGridItemComponent implements OnDestroy {
  @Input() product: any;
  @Input() editable: boolean;
  selectedProductsCodes: string[];
  subscription: Subscription;
  constructor(protected productSelectService: KnBrProductSelectService, protected routingService: RoutingService) {
    this.subscription = this.productSelectService.loadSelectedProducts$().subscribe((selectedProductsCodes) => {
      this.selectedProductsCodes = selectedProductsCodes;
    });
  }
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  isChecked(code: string): boolean {
    if (this.selectedProductsCodes) {
      return this.selectedProductsCodes.indexOf(code) > -1;
    }
    return false;
  }
  changeCheckBox(event) {
    event.stopPropagation();
    const selectedProductCodes = event.currentTarget.checked ? this.addProductCode() : this.removeProductCode();
    this.productSelectService.setSelectedProducts(selectedProductCodes);
  }
  goToDetail(event) {
    if (event.target && event.target.classList && !event.target.classList.contains('custom-control-input')) {
      event.stopPropagation();
      event.preventDefault();
      this.routingService.go({ cxRoute: 'product', params: this.product });
    }
  }
  addProductCode() {
    if (this.selectedProductsCodes && this.selectedProductsCodes.length) {
      this.selectedProductsCodes.push(this.product.code);
    } else {
      this.selectedProductsCodes = [this.product.code];
    }
    return this.selectedProductsCodes;
  }
  removeProductCode() {
    this.selectedProductsCodes.splice(this.selectedProductsCodes.indexOf(this.product.code), 1);
    return this.selectedProductsCodes;
  }
}
