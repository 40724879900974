import { NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChangeDetectorRef, Component, OnInit, OnDestroy, Input } from '@angular/core';
import { CmsService } from '@spartacus/core';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';
import { KnBrDateHelper } from 'src/app/shared/kn-br-date.helper';
import { SloanQuoteCommentsPopupComponent } from '../../sloan-quote-comments-popup/sloan-quote-comments-popup.component';
import { UserAccountService } from '@spartacus/user/account/core';
import { SloanCalendarHelper } from 'src/app/services/sloan/sloan-calendar-help.service';
import { SloanDataSharedService } from 'src/app/services/sloan/sloan-data-shared.service';
import { KnBrQuoteItemComponent } from 'src/feature-libs/kn-br-cart/quote/components/kn-br-quote/kn-br-quote-detail/kn-br-quote-items/kn-br-quote-item/kn-br-quote-item.component';
import { KnBrQuoteEntryService } from 'src/feature-libs/kn-br-cart/quote/core/facade/kn-br-quote-entry.service';
import { KnBrQuotesService } from 'src/feature-libs/kn-br-cart/quote/core/facade/kn-br-quotes.service';
import { UserAccountFacade } from '@spartacus/user/account/root';

@Component({
  selector: 'kn-br-quote-item',
  templateUrl: './sloan-quote-item.component.html',
  styleUrls: ['./sloan-quote-item.component.scss'],
})
export class SloanQuoteItemComponent extends KnBrQuoteItemComponent implements OnInit, OnDestroy {
  @Input() entry: any;
  @Input() isShipComplete: boolean;
  reqShipMinDate: NgbDate;
  reqShipMaxDate: NgbDate;
  today: NgbDate = this.sloanCalendarHelper.today;
  isReqShipDisabled: (date: NgbDate, current: { year: number; month: number }) => boolean;
  constructor(
    protected currentUserService: UserAccountFacade,
    protected modalService: NgbModal,
    protected knBrQuoteEntryService: KnBrQuoteEntryService,
    protected knBrQuoteService: KnBrQuotesService,
    protected cdr: ChangeDetectorRef,
    protected cmsService: CmsService,
    protected knBrDateHelper: KnBrDateHelper,
    protected knBrCommonService: KnBrCommonService,
    protected sloanCalendarHelper: SloanCalendarHelper,
    protected sloanDataSharedService: SloanDataSharedService
  ) {
    super(
      currentUserService,
      modalService,
      knBrQuoteEntryService,
      knBrQuoteService,
      cdr,
      cmsService,
      knBrDateHelper,
      knBrCommonService
    );
    this.isReqShipDisabled = (date: NgbDate, current: { year: number; month: number }) => {
      return (
        this.sloanCalendarHelper.isHoliday(date) ||
        (this.sloanCalendarHelper.isWeekend(date) && date.month === current.month)
      );
    };
    this.reqShipMinDate = this.sloanCalendarHelper.getRddMinDate(this.today);
    this.reqShipMaxDate = this.sloanCalendarHelper.getRddMaxDate(this.today);
    this.reqShipDate.setValue(this.sloanCalendarHelper.rddDefaultDate(this.today));
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (this.isShipComplete) {
      this.reqShipDate.disable();
    }
  }

  update(event) {
    super.update(event);
    this.sloanDataSharedService.setQuoteCheckoutDisable(false);
    this.quantity.disable();
  }

  onEnterInput() {
    this.sloanDataSharedService.setQuoteCheckoutDisable(true);
  }

  openComment(entry?: any) {
    let modalInstance: any;
    const modalRef = this.modalService.open(SloanQuoteCommentsPopupComponent, {
      centered: true,
      size: entry.product?.isGeneric || entry.product?.isSinkDrillPattern_b ? 'xl' : 'lg',
    });
    modalInstance = modalRef.componentInstance;
    modalInstance.entryNumber = entry.entryNumber;
    modalInstance.isGeneric = entry.product?.isGeneric;
    modalInstance.isSinkDrillPattern = entry.product?.isSinkDrillPattern_b;
    modalInstance.editable = this.isDisabled();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
