import { KnBrQuoteReferenceAction, KnBrQuoteReferenceActionTypes } from '../actions/kn-br-quote-reference.actions';
import { KnBrQuoteReferenceState } from '../../../../../../app/store/kn-br-quote-reference/kn-br-quote-reference.state';

export const initialState: KnBrQuoteReferenceState = {
  reference: undefined,
  error: undefined,
  referenceResponse: undefined,
};

export function knBrQuoteReferenceReducer(
  state = initialState,
  action: KnBrQuoteReferenceAction
): KnBrQuoteReferenceState {
  switch (action.type) {
    case KnBrQuoteReferenceActionTypes.KnBrQuoteAddReferenceAction:
      return { ...state, reference: action.payload };
    case KnBrQuoteReferenceActionTypes.KnBrQuoteAddReferenceSuccessAction:
      return { ...state, reference: action.payload };
    case KnBrQuoteReferenceActionTypes.KnBrQuoteAddReferenceFailureAction:
      return { ...state, error: action.error };
    case KnBrQuoteReferenceActionTypes.KnBrQuoteRemoveReferenceAction:
      return { ...state, reference: action.payload };
    case KnBrQuoteReferenceActionTypes.KnBrQuoteRemoveReferenceSuccessAction:
      return { ...state, reference: action.payload };
    case KnBrQuoteReferenceActionTypes.KnBrQuoteRemoveReferenceFailureAction:
      return { ...state, error: action.error };
    case KnBrQuoteReferenceActionTypes.KnBrQuoteUpdateReferenceAction:
      return { ...state, reference: action.payload };
    case KnBrQuoteReferenceActionTypes.KnBrQuoteUpdateReferenceSuccessAction:
      return { ...state, reference: action.payload };
    case KnBrQuoteReferenceActionTypes.KnBrQuoteUpdateReferenceFailureAction:
      return { ...state, error: action.error };
    case KnBrQuoteReferenceActionTypes.KnBrQuoteLoadReferenceAction:
      return { ...state, reference: action.payload, referenceResponse: undefined };
    case KnBrQuoteReferenceActionTypes.KnBrQuoteLoadReferenceSuccessAction:
      return { ...state, referenceResponse: action.payload };
    case KnBrQuoteReferenceActionTypes.KnBrQuoteLoadReferenceFailureAction:
      return { ...state, error: action.error };
    default:
      return state;
  }
}
