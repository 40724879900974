import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { KnBrCheckoutComponentsModule } from './base/components/kn-br-checkout-components.module';
import { KnBrCheckoutCoreModule } from './base/core/kn-br-checkout-core.module';
import { KnBrCheckoutOccModule } from './base/occ/kn-br-checkout-occ.module';
import { KnBrCheckoutRootModule } from './base/root/kn-br-checkout-root.module';

@NgModule({
  imports: [
    CommonModule,
    KnBrCheckoutCoreModule,
    KnBrCheckoutOccModule,
    KnBrCheckoutRootModule,
    KnBrCheckoutComponentsModule,
  ],
})
export class KnBrCheckoutModule {}
