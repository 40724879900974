import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ResetPasswordComponent, ResetPasswordComponentService } from '@spartacus/user/profile/components';

@Component({
  selector: 'sloan-reset-password',
  templateUrl: './sloan-reset-password.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SloanResetPasswordComponent extends ResetPasswordComponent {
  constructor(protected service: ResetPasswordComponentService) {
    super(service);
  }
}
