import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CmsService } from '@spartacus/core';
import { Subscription } from 'rxjs';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';
import { KnBrQuotesService } from 'src/feature-libs/kn-br-cart/quote/core/facade/kn-br-quotes.service';

@Component({
  selector: 'knbr-kn-br-quote-download-link',
  templateUrl: './kn-br-quote-download-link.component.html',
  styleUrls: ['./kn-br-quote-download-link.component.scss'],
})
export class KnBrQuoteDownloadLinkComponent implements OnInit {
  enableCheckboxFlag = false;
  subscription = new Subscription();
  selectedProductsCodes: string[] = [];
  productCodes: string[] = [];
  templateName: string;
  constructor(
    public knBrQuoteService: KnBrQuotesService,
    private knBrCommonService: KnBrCommonService,
    private cdr: ChangeDetectorRef,
    private cmsService: CmsService
  ) { }

  ngOnInit(): void {
    this.subscription.add(
      this.knBrCommonService.loadDownloadFlag$().subscribe((flag) => {
        this.enableCheckboxFlag = flag;
        this.cdr.markForCheck();
      })
    );
    this.subscription.add(
      this.knBrCommonService.loadQuoteListSelectedProducts$().subscribe((codes) => {
        this.selectedProductsCodes = codes;
        this.cdr.markForCheck();
      })
    );
    this.subscription.add(
      this.knBrQuoteService.list$.subscribe((quotes) => {
        if (quotes) {
          this.productCodes = quotes.map((quote) => quote.code);
        }
        this.cdr.markForCheck();
      })
    );

    this.subscription.add(
      this.cmsService.getCurrentPage().subscribe((page) => {
        if (page) {
          this.templateName = page.template;
        }
      })
    );
  }

  downloadPdf() {
    if (this.selectedProductsCodes) {
      this.knBrCommonService.downloadQuote(
        this.selectedProductsCodes.toString(),
        this.selectedProductsCodes.length > 1
      );
    }
    this.knBrCommonService.setDownloadFlag(false);
  }
  downloadQuoteDetailPdf() {
    this.knBrQuoteService.get$
      .subscribe((response) => {
        if (response) {
          this.knBrCommonService.downloadQuote(response.code);
        }
      })
      .unsubscribe();
  }
  enableCheckbox() {
    this.knBrCommonService.setDownloadFlag(true);
  }
  cancelDownload() {
    this.knBrCommonService.setDownloadFlag(false);
  }
}
