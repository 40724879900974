import { Action } from '@ngrx/store';

export enum KnBrFactSheetActionTypes {
  KnBrFactSheetDetailsAction = '[FactSheet] Details',
  KnBrFactSheetDetailsSuccessAction = '[FactSheet] Details Success',
  KnBrFactSheetDetailsFailureAction = '[FactSheet] Details Failure',
}

/**
 * Action to Search Order History
 */
export class KnBrFactSheetDetail implements Action {
  readonly type = KnBrFactSheetActionTypes.KnBrFactSheetDetailsAction;
  constructor() { }
}

/**
 * Action dispatched on successful Order History Search
 */
export class KnBrFactSheetDetailsSuccess implements Action {
  readonly type = KnBrFactSheetActionTypes.KnBrFactSheetDetailsSuccessAction;
  constructor(public payload: any) { }
}

/**
 * Action dispatched on failure Order History Search
 */
export class KnBrFactSheetDetailsFailure implements Action {
  readonly type = KnBrFactSheetActionTypes.KnBrFactSheetDetailsFailureAction;
  constructor(public payload: Error) { }
}

export type KnBrFactSheetActions = KnBrFactSheetDetail | KnBrFactSheetDetailsSuccess | KnBrFactSheetDetailsFailure;
