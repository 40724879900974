import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, ConfigModule, UrlModule } from '@spartacus/core';
import { MediaModule } from '@spartacus/storefront';
import { KnBrProductCarouselComponent } from './kn-br-product-carousel.component';
import { CarouselModule } from '../sloan/carousel/carousel.module';

@NgModule({
  declarations: [KnBrProductCarouselComponent],
  imports: [
    CommonModule,
    CarouselModule,
    MediaModule,
    RouterModule,
    UrlModule,
    ConfigModule.withConfig({
      cmsComponents: {
        ProductCarouselComponent: {
          component: KnBrProductCarouselComponent,
        },
      },
    } as CmsConfig),
  ],
  providers: [],
})
export class KnBrProductCarouselModule {}
