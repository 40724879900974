import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbCalendar, NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RoutingService } from '@spartacus/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { SloanCalendarHelper } from 'src/app/services/sloan/sloan-calendar-help.service';
import { SloanCommonService } from 'src/app/services/sloan/sloan-common.service';
import { KnBrDateHelper } from 'src/app/shared/kn-br-date.helper';
import { KnBrCustomerContextService } from 'src/app/store/kn-br-customer-context/kn-br-customer-context.service';
import { KnBrQuoteContextService } from 'src/feature-libs/kn-br-cart/quote/core/facade/kn-br-quote-context.service';
import { KnBrQuoteEntryService } from 'src/feature-libs/kn-br-cart/quote/core/facade/kn-br-quote-entry.service';
import { KnBrQuotesService } from 'src/feature-libs/kn-br-cart/quote/core/facade/kn-br-quotes.service';
import { KnBrSelectQuoteComponent } from 'src/feature-libs/kn-br-product/components/kn-br-add-to-quote/kn-br-select-quote/kn-br-select-quote.component';

@Component({
  selector: 'kn-br-select-quote',
  templateUrl: './sloan-select-quote.component.html',
  styleUrls: ['./sloan-select-quote.component.scss'],
})
export class SloanSelectQuoteComponent extends KnBrSelectQuoteComponent implements OnInit, OnDestroy {
  tomorrow: any;
  quoteValidToMax: any;
  reqShipMinDate: NgbDate;
  reqShipMaxDate: NgbDate;
  today: NgbDate = this.sloanCalendarHelper.today;
  shipComplete = false;
  quoteValidityDate$: Observable<any> = this.sloanCommonService.loadQuoteValidityDate$();
  constructor(
    protected modalService: NgbModal,
    protected routingService: RoutingService,
    protected knBrQuotesService: KnBrQuotesService,
    protected knBrQuoteContextService: KnBrQuoteContextService,
    protected knBrQuoteEntryService: KnBrQuoteEntryService,
    protected knBrCustomerContextService: KnBrCustomerContextService,
    protected sloanCalendarHelper: SloanCalendarHelper,
    protected sloanCommonService: SloanCommonService,
    protected knBrDateHelper: KnBrDateHelper,
    protected ngbCalendar: NgbCalendar
  ) {
    super(
      modalService,
      routingService,
      knBrQuotesService,
      knBrQuoteContextService,
      knBrQuoteEntryService,
      knBrCustomerContextService,
      knBrDateHelper,
      ngbCalendar
    );
    this.sloanCommonService.getSloanHolidayList();
    this.reqShipMinDate = this.sloanCalendarHelper.getRddMinDate(this.today);
    this.reqShipMaxDate = this.sloanCalendarHelper.getRddMaxDate(this.today);
  }
  ngOnInit(): void {
    this.quoteList = [];
    this.subscription.add(
      this.quoteList$.subscribe((quoteList) => {
        this.quoteList = _.filter(quoteList, ['status', 'Draft']);
      })
    );
    this.subscription.add(
      this.customerContextData$.subscribe((value: any) => {
        if (value && value.hasOwnProperty('dropShipFlag')) {
          this.shipComplete = value.dropShipFlag;
        }
      })
    );
  }
  goToCreateNewQuote() {
    this.sloanCommonService.getQuoteValidityDate();

    if (this.productList && this.productList.length > 0) {
      const quoteCreateReq = {
        headerNotes: '',
        purchaseOrderNumber: '',
        quoteValidTo: this.knBrDateHelper.formatDate(this.ngbCalendar.getNext(this.ngbCalendar.getToday(), 'd', 60)),
        rdd: this.knBrDateHelper.formatDate(this.sloanCalendarHelper.rddDefaultDate(this.today)),
        shipComplete: this.shipComplete,
        jobName: '',
        referenceQuoteId: null,
        orderToolsProducts: this.productList,
      };
      const updatedQuoteCreateReq = this.updateValidToDate(quoteCreateReq);
      this.knBrQuotesService.create(updatedQuoteCreateReq);
    } else {
      this.routingService.go({ cxRoute: 'createQuote' }, { queryParams: { p: this.productCode, q: this.quantity } });
    }
    this.dismissModal();
  }

  updateValidToDate(quoteCreateReq) {
    // Creating ngbdate with 60 days from today on UI.
    const ngbUIValidToDate = this.ngbCalendar.getNext(this.ngbCalendar.getToday(), 'd', 60);
    let ngbBackendValidToDate: NgbDate;

    // Creating ngbdate using the response from backend.
    this.quoteValidityDate$.subscribe((res) => {
      if (res && res != '') {
        const [year, month, day] = res.split('-');
        ngbBackendValidToDate = new NgbDate(parseInt(year), parseInt(month), parseInt(day));

        // comparing and updating the validto date
        if (ngbUIValidToDate.before(ngbBackendValidToDate)) {
          quoteCreateReq.quoteValidTo = this.knBrDateHelper.formatDate(ngbUIValidToDate);
        } else {
          quoteCreateReq.quoteValidTo = this.knBrDateHelper.formatDate(ngbBackendValidToDate);
        }
      }
    });
    return quoteCreateReq;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
