import { Component, OnInit } from '@angular/core';
import { CmsNavigationComponent } from '@spartacus/core';
import { CmsComponentData, NavigationService } from '@spartacus/storefront';
import { SloanResponsiveCategoryNavigationComponent } from './sloan-responsive-category-navigation/sloan-responsive-category-navigation.component';
import { KnBrCategoryNavigationComponent } from 'src/app/customer-libs/kn-br-navigation/components/kn-br-category-navigation/kn-br-category-navigation/kn-br-category-navigation.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'kn-br-category-navigation',
  templateUrl: './sloan-category-navigation.component.html',
})
export class SloanCategoryNavigationComponent extends KnBrCategoryNavigationComponent implements OnInit {
  constructor(
    protected componentData: CmsComponentData<CmsNavigationComponent>,
    protected service: NavigationService,
    protected modalService: NgbModal
  ) {
    super(componentData, service, modalService);
  }
  ngOnInit(): void {
    super.ngOnInit();
  }

  open() {
    let modalInstance: any;
    const modalRef = this.modalService.open(SloanResponsiveCategoryNavigationComponent, {
      centered: true,
      size: 'lg',
    });
    modalInstance = modalRef.componentInstance;
    modalInstance.node$ = this.node$;
    modalInstance.data$ = this.data$;
  }
}
