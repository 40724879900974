import { KnBrFactSheetActions, KnBrFactSheetActionTypes } from '../actions/kn-br-fact-sheet.action';
import { KnBrFactSheetState } from '../../../../../app/store/kn-br-fact-sheet/kn-br-fact-sheet.state';

export const initialState: KnBrFactSheetState = {
  factSheetData: undefined,
  error: undefined,
  isLoading: false,
};

export function KnBrFactSheetReducer(state = initialState, action: KnBrFactSheetActions): KnBrFactSheetState {
  switch (action.type) {
    case KnBrFactSheetActionTypes.KnBrFactSheetDetailsAction:
      return { ...state, isLoading: true };
    case KnBrFactSheetActionTypes.KnBrFactSheetDetailsSuccessAction:
      return {
        ...state,
        factSheetData: action.payload,
        isLoading: false,
      };
    case KnBrFactSheetActionTypes.KnBrFactSheetDetailsFailureAction:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
}
