import { Injectable } from '@angular/core';
import { WindowRef } from '@spartacus/core';

const KN_BR_CART_CONTEXT_STORAGE_KEY = 'kn-br-cart-context';

@Injectable({
  providedIn: 'root',
})
export class KnBrCartContextAdapter {
  constructor(private winRef: WindowRef) {}

  get() {
    if (this.winRef.sessionStorage) {
      const context = this.winRef.sessionStorage.getItem(KN_BR_CART_CONTEXT_STORAGE_KEY);
      if (context) {
        try {
          return JSON.parse(context);
        } catch (e) {}
      }
    }
    return null;
  }
  set(context: string) {
    if (this.winRef.sessionStorage) {
      this.winRef.sessionStorage.setItem(KN_BR_CART_CONTEXT_STORAGE_KEY, JSON.stringify(context));
    }
  }
  remove() {
    if (this.winRef.sessionStorage) {
      this.winRef.sessionStorage.removeItem(KN_BR_CART_CONTEXT_STORAGE_KEY);
    }
  }
}
