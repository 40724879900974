import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { KnBrHomeModule } from "./kn-br-home/kn-br-home.module";
import { KnBrNacModule } from "./kn-br-nac/kn-br-nac.module";
import { KnBrNavigationModule } from "./kn-br-navigation/kn-br-navigation.module";
import { KnBrQuickLinksModule } from "./kn-br-quick-links/kn-br-quick-links.module";

@NgModule({
  imports: [CommonModule, KnBrQuickLinksModule, KnBrNavigationModule, KnBrNacModule, KnBrHomeModule],
  declarations: [],
})
export class KnBrCustomerLibsModule {}
