import { RoutingConfig } from '@spartacus/core';

export const defaultSavedCartRoutingConfig: RoutingConfig = {
  routing: {
    routes: {
      quotes: {
        paths: ['quotes'],
      },
      createQuote: {
        paths: ['copy-quote/:code/:name', 'create-quote'],
      },
      quoteDetails: {
        paths: ['quote-detail/:code'],
      },
    },
  },
};
