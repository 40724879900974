import { KnBrCustomerContextService } from 'src/app/store/kn-br-customer-context/kn-br-customer-context.service';
import { Observable, Subscription } from 'rxjs';
import { Component, OnInit, ViewContainerRef, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { RoutingService } from '@spartacus/core';
import { KnBrDateHelper } from 'src/app/shared/kn-br-date.helper';
import { LaunchDialogService } from '@spartacus/storefront';
import { SloanCalendarHelper } from 'src/app/services/sloan/sloan-calendar-help.service';
import { SloanCommonService } from 'src/app/services/sloan/sloan-common.service';
import { UserAccountService } from '@spartacus/user/account/core';
import { KnBrQuoteCreateComponent } from 'src/feature-libs/kn-br-cart/quote/components/kn-br-quote/kn-br-quote-create/kn-br-quote-create.component';
import { KnBrQuoteEntryService } from 'src/feature-libs/kn-br-cart/quote/core/facade/kn-br-quote-entry.service';
import { KnBrQuotesService } from 'src/feature-libs/kn-br-cart/quote/core/facade/kn-br-quotes.service';
import { UserAccountFacade } from '@spartacus/user/account/root';

@Component({
  selector: 'knbr-sloan-quote-create',
  templateUrl: './sloan-quote-create.component.html',
  styleUrls: ['./sloan-quote-create.component.scss'],
})
export class SloanQuoteCreateComponent extends KnBrQuoteCreateComponent implements OnInit, OnDestroy {
  tomorrow: any;
  quoteValidToMax: any;
  reqShipMinDate: NgbDate;
  reqShipMaxDate: NgbDate;
  today: NgbDate = this.sloanCalendarHelper.today;
  isReqShipDisabled: (date: NgbDate, current: { year: number; month: number }) => boolean;
  subscription = new Subscription();
  quoteValidityDate$: Observable<any> = this.sloanCommonService.loadQuoteValidityDate$();
  constructor(
    protected currentUserService: UserAccountFacade,
    protected fb: FormBuilder,
    public ngbCalendar: NgbCalendar,
    protected routingService: RoutingService,
    protected quoteService: KnBrQuotesService,
    protected knBrDateHelper: KnBrDateHelper,
    protected sloanCalendarHelper: SloanCalendarHelper,
    protected sloanCommonService: SloanCommonService,
    protected customerService: KnBrCustomerContextService,
    protected launchDialogService: LaunchDialogService,
    protected vcr: ViewContainerRef,
    protected knBrQuoteEntryService: KnBrQuoteEntryService
  ) {
    super(
      currentUserService,
      fb,
      ngbCalendar,
      routingService,
      quoteService,
      knBrDateHelper,
      launchDialogService,
      vcr,
      knBrQuoteEntryService
    );
    this.sloanCommonService.getSloanHolidayList();
    this.isReqShipDisabled = (date: NgbDate, current: { year: number; month: number }) => {
      return (
        this.sloanCalendarHelper.isHoliday(date) ||
        (this.sloanCalendarHelper.isWeekend(date) && date.month === current.month)
      );
    };
    this.reqShipMinDate = this.sloanCalendarHelper.getRddMinDate(this.today);
    this.reqShipMaxDate = this.sloanCalendarHelper.getRddMaxDate(this.today);
  }

  ngOnInit() {
    super.ngOnInit();
    this.sloanCommonService.getQuoteValidityDate();
    this.subscription = this.customerService.get$.subscribe((response: any) => {
      if (response && response.hasOwnProperty('dropShipFlag')) {
        this.quoteForm.patchValue({ shipComplete: response.dropShipFlag });
      }
    });
  }

  initForm() {
    const poNumPattern = '[^*^~|<>]*';
    this.tomorrow = this.ngbCalendar.getNext(this.ngbCalendar.getToday(), 'd', 1);
    this.quoteValidToMax = this.ngbCalendar.getNext(this.ngbCalendar.getToday(), 'd', 60);
    this.quoteForm = this.fb.group({
      headerNotes: [''],
      purchaseOrderNumber: ['', [Validators.pattern(poNumPattern)]],
      quoteValidTo: [{ value: this.ngbCalendar.getNext(this.ngbCalendar.getToday(), 'd', 60), disabled: true }, Validators.required],
      rdd: [this.sloanCalendarHelper.rddDefaultDate(this.today), Validators.required],
      shipComplete: true,
      jobName: ['', Validators.required],
      referenceQuoteId: this.copiedQuoteData ? this.copiedQuoteData.code : null,
    });
    this.updateValidToDate();
  }

  updateValidToDate() {
    // Creating ngbdate with 60 days from today on UI.
    const ngbUIValidToDate = this.ngbCalendar.getNext(this.ngbCalendar.getToday(), 'd', 60);
    let ngbBackendValidToDate: NgbDate;

    // Creating ngbdate using the response from backend.
    this.quoteValidityDate$.subscribe((res) => {
      if (res && res.response != '') {
        const [year, month, day] = res.split('-');
        ngbBackendValidToDate = new NgbDate(parseInt(year), parseInt(month), parseInt(day));
        this.quoteValidToMax = ngbBackendValidToDate;

        // comparing and updating the validto date
        if (ngbUIValidToDate.before(ngbBackendValidToDate)) {
          this.quoteForm.patchValue({ quoteValidTo: ngbUIValidToDate });
          this.quoteForm.controls.quoteValidTo.setValue(ngbUIValidToDate);
        } else {
          this.quoteForm.patchValue({ quoteValidTo: ngbBackendValidToDate });
          this.quoteForm.controls.quoteValidTo.setValue(ngbBackendValidToDate);
        }
        this.quoteForm.controls.quoteValidTo.updateValueAndValidity();
      }
    });
  }

  onKeyPress(event) {
    var key;
    key = event.charCode;
    return !(key == 123 || key == 125 || key == 91 || key == 93);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
