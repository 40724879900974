import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { B2BUserRole, StateWithProcess, UserIdService } from '@spartacus/core';
import { B2BUserService, StateWithOrganization } from '@spartacus/organization/administration/core';

@Injectable({ providedIn: 'root' })
export class KnBrB2BUserService extends B2BUserService {
  constructor(protected store: Store<StateWithOrganization>, protected userIdService: UserIdService) {
    super(store, userIdService);
  }

  /**
   * Get list of all roles for B2BUser sorted by increasing privileges.
   *
   * This list is not driven by the backend (lack of API), but reflects roles
   * from the backend: `b2badmingroup`, `b2bcustomergroup`, `b2bmanagergroup` and `b2bapprovergroup`.
   *
   * If you reconfigure those roles in the backend or extend the list, you should change
   * this implementation accordingly.
   */
  //getAllRoles(): B2BUserRole[] {
  // return [B2BUserRole.CUSTOMER, B2BUserRole.MANAGER];
  //}
}
