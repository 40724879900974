<ng-container>
  <div class="col-8 p-0 float-left">
    <ng-container *ngIf="cartContext$ | async">
      <a [attr.aria-label]="'miniCart.item' | cxTranslate : { count: quantity$ | async }">
        <span class="cursor-pointer" [routerLink]="{ cxRoute: 'cartDetails', params: { code: code$ | async } } | cxUrl">
          <cx-icon [type]="iconTypes.CART"></cx-icon>
          <span class="count font-size">
            ({{ 'miniCart.count' | cxTranslate : { count: quantity$ | async } }} {{ 'label.items' | cxTranslate }})
          </span>
          <span class="total font-size"> {{ 'miniCart.total' | cxTranslate : { total: total$ | async } }} </span>
        </span>
        <span class="cursor-pointer" *ngIf="isNotCartDetailPage$ | async" (click)="removeCartContext()">
          <i class="fas fa-minus-circle ml-2" aria-hidden="true"></i></span>
      </a>
    </ng-container>
    <ng-container *ngIf="quoteContext$ | async">
      <a [attr.aria-label]="'miniCart.item' | cxTranslate : { count: quantity$ | async }">
        <span class="cursor-pointer" (click)="redirectToQuoteDetail()">
          <span class="font-size"> <i class="fas fa-file-alt font-size-17 mr-1"></i></span>
          <span class="count font-size">
            ({{ 'miniCart.count' | cxTranslate : { count: quoteQuantity$ | async } }} {{ 'label.items' | cxTranslate }})
          </span>
          <span class="total font-size"> {{ 'miniCart.total' | cxTranslate : { total: quoteTotal$ | async } }} </span>
        </span>
        <span class="cursor-pointer" (click)="removeQuoteContext()" *ngIf="isNotQuoteDetailPage$ | async">
          <i class="fas fa-minus-circle ml-2" aria-hidden="true"></i></span>
      </a>
    </ng-container>
    <ng-container *ngIf="!(cartContext$ | async) && !(quoteContext$ | async)">
      <a [routerLink]="{ cxRoute: 'carts' } | cxUrl">
        <cx-icon [type]="iconTypes.CART"></cx-icon>
      </a>
    </ng-container>
  </div>
</ng-container>
