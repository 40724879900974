import { Observable, Subscription } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Statuses } from 'src/feature-libs/kn-br-cart/quote/root/models/kn-br-quote.model';
import { KnBrEditOrderRequestEntryInput } from 'src/feature-libs/kn-br-order/root/models/kn-br-order.model';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';

@Component({
  selector: 'knbr-kn-br-edit-order-item',
  templateUrl: './kn-br-edit-order-item.component.html',
  styleUrls: ['./kn-br-edit-order-item.component.scss'],
})
export class KnBrEditOrderItemComponent implements OnInit {
  @Input() item;
  quantity = new UntypedFormControl();
  reason = new UntypedFormControl(null);
  cancelItem = new UntypedFormControl(false);
  @Output()
  inputChange = new EventEmitter<KnBrEditOrderRequestEntryInput>();
  isReasonsLoading = false;
  subscription = new Subscription();
  cancelReasonList$: Observable<Statuses[]> = this.knBrCommonService.loadCancelOrderStatusList$();
  reasonList: Statuses[];
  constructor(private knBrCommonService: KnBrCommonService) { }

  ngOnInit(): void {
    this.quantity.setValue(this.item.quantity);
    this.cancelReasonList$.subscribe((res) => {
      if (res) {
        this.reasonList = res;
      }
    });
  }

  onchangeQuantity(event) {
    if (this.quantity.value > this.item.quantity) {
      this.quantity.setValue(this.item.quantity);
    } else {
      if (this.quantity.value > 0) {
        this.cancelItem.setValue(false);
      }
    }
    this.prepareEditRequestAndEmit();
  }

  prepareEditRequestAndEmit() {
    let reqObj: KnBrEditOrderRequestEntryInput;
    if (this.reason.value) {
      reqObj = {
        cancelReason: this.reason.value,
        orderEntryNumber: this.item.entryNumber,
        quantity: this.quantity.value,
        productCode: this.item.product.code,
      };
    } else {
      reqObj = {
        orderEntryNumber: this.item.entryNumber,
        quantity: this.quantity.value,
        productCode: this.item.product.code,
      };
    }
    this.inputChange.emit(reqObj);
  }

  onChangeCancel() {
    if (this.cancelItem.value) {
      this.quantity.setValue(0);
    } else {
      this.quantity.setValue(this.item.quantity);
      this.reason.setValue(null);
    }
    this.prepareEditRequestAndEmit();
  }

  onChangeReason(event) {
    this.prepareEditRequestAndEmit();
  }
}
