import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Country, OccSiteAdapter, Region } from '@spartacus/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { KnBrCustomerService } from 'src/app/store/kn-br-customer/kn-br-customer.service';

@Component({
  selector: 'knbr-customer-header',
  templateUrl: './kn-br-customer-header.component.html',
  styleUrls: ['./kn-br-customer-header.component.scss'],
  providers: [OccSiteAdapter],
})
export class KnBrCustomerHeaderComponent implements OnInit {
  countries$: Observable<Country[]>;
  isCountryLoading = false;
  regions$: Observable<Region[]>;
  isRegionLoading = false;
  customerSearchForm: UntypedFormGroup;
  isSearchDisabled$: Observable<boolean> = this.customerSearchService.searchDisabled$;
  constructor(
    private occSiteAdapter: OccSiteAdapter,
    private fb: UntypedFormBuilder,
    private customerSearchService: KnBrCustomerService
  ) {}
  ngOnInit(): void {
    this.initForm();
    this.resetForm();
    this.getCountries();
    this.submitForm();
  }
  initForm() {
    this.customerSearchForm = this.fb.group({
      customerName: null,
      customerNumber: null,
      countryCode: null,
      stateCode: null,
      city: null,
      postalCode: null,
    });
  }
  getCountries() {
    this.isCountryLoading = true;
    this.countries$ = this.occSiteAdapter.loadCountries().pipe(tap(() => (this.isCountryLoading = false)));
  }
  countrySelected(country: Country) {
    if (country && country.isocode) {
      this.isRegionLoading = true;
      this.customerSearchForm.patchValue({
        stateCode: null,
      });
      this.regions$ = this.occSiteAdapter.loadRegions(country.isocode).pipe(tap(() => (this.isRegionLoading = false)));
    } else {
      this.regions$ = null;
    }
  }
  submitForm() {
    this.customerSearchService.search(this.customerSearchForm.value);
  }
  resetForm() {
    this.customerSearchForm.reset();
    this.customerSearchService.reset();
    this.submitForm();
  }
}
