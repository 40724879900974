/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { NgModule } from '@angular/core';
import { PageMetaResolver } from '@spartacus/core';
import { knBrFacadeProviders } from './facade/kn-br-facade-providers';
import {
  CheckoutDeliveryAddressConnector,
  CheckoutDeliveryModesConnector,
  CheckoutPaymentConnector,
  CheckoutConnector,
  CheckoutPageMetaResolver,
} from '@spartacus/checkout/base/core';

@NgModule({
  providers: [
    ...knBrFacadeProviders,
    CheckoutDeliveryAddressConnector,
    CheckoutDeliveryModesConnector,
    CheckoutPaymentConnector,
    CheckoutConnector,
    CheckoutPageMetaResolver,
    {
      provide: PageMetaResolver,
      useExisting: CheckoutPageMetaResolver,
      multi: true,
    },
  ],
})
export class KnBrCheckoutCoreModule {}
