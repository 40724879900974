import { address } from './address.i18n';
import { factSheet } from './fact-sheet.i18n';
import { invoice } from './invoice.i18n';
import { myAccount } from './my-account.i18';
import { checkoutOrderConfirmation } from './order-confirmation.i18n';
import { order } from './order.i18n';

export const en = {
  order,
  invoice,
  address,
  factSheet,
  checkoutOrderConfirmation,
  myAccount,
};
