import { TranslationChunksConfig, TranslationResources } from '@spartacus/core';
import { en } from './en';

export const knbrProductTranslations: TranslationResources = { en };

export const knbrProducTranslationChunksConfig: TranslationChunksConfig = {
  knbrProduct: [
    'stockNotification',
    'addToWishList',
    'variant',
    'TabPanelContainer',
    'addToCart',
    'productReview',
    'productSummary',
    'productFacetNavigation',
    'productList',
    'productDetails',
    'KnBrCMSTabParagraphContainer',
  ],
};
