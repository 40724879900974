import { TranslationChunksConfig, TranslationResources } from '@spartacus/core';
import { en } from './en/index';

export const orderTranslations: TranslationResources = {
  en,
};

export const orderTranslationChunksConfig: TranslationChunksConfig = {
  order: [
    'orderHistorySearchForm',
    'orderHistoryResult',
    'knbrOrderDetails',
    'knbrCancelOrder',
    'knBrOrderReturn',
    'orderReturnResult',
    'orderReturnsSearchForm',
    'knbrReturnOrderDetails',
    'freightPopUp',
  ],
  invoice: ['invoiceSearchForm', 'invoiceResult', 'knbrAccountSummary'],
  address: ['addressBook', 'knbrAddressBook', 'addressCard', 'addressForm', 'knbrAddressForm', 'addressMessages'],
  factSheet: ['factSheetHeader', 'factSheetGroup', 'factSheetMaterial'],
  checkoutOrderConfirmation: ['checkoutOrderConfirmation'],
  myAccount: [
    'orderDetails',
    'orderHistory',
    'closeAccount',
    'updateEmailForm',
    'updatePasswordForm',
    'updateProfileForm',
    'consentManagementForm',
    'myCoupons',
    'wishlist',
    'notificationPreference',
    'myInterests',
    'AccountOrderHistoryTabContainer',
    'returnRequestList',
    'returnRequest',
  ],
};
