import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { CsAgentAuthService } from '@spartacus/asm/root';
import { CheckoutPlaceOrderComponent } from '@spartacus/checkout/base/components';
import { RoutingService } from '@spartacus/core';
import { OrderFacade } from '@spartacus/order/root';
import { LaunchDialogService } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { SloanCommonService } from 'src/app/services/sloan/sloan-common.service';

@Component({
  selector: 'cx-place-order',
  templateUrl: './sloan-checkout-place-order.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SloanCheckoutPlaceOrderComponent extends CheckoutPlaceOrderComponent implements OnInit, OnDestroy {
  isASMLoggedIn$: Observable<boolean> = this.csAgentAuthService.isCustomerSupportAgentLoggedIn();

  constructor(
    protected orderFacade: OrderFacade,
    protected routingService: RoutingService,
    protected fb: UntypedFormBuilder,
    protected launchDialogService: LaunchDialogService,
    protected vcr: ViewContainerRef,
    protected commonService: SloanCommonService,
    protected csAgentAuthService: CsAgentAuthService
  ) {
    super(orderFacade, routingService, fb, launchDialogService, vcr);
  }

  ngOnInit() {
    this.checkoutSubmitForm.get('termsAndConditions').patchValue(true);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.commonService.selectedPaymentTypes$.next(null);
  }
}
