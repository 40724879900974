<ng-container *ngIf="quote$ | async as quote">
  <div class="customersearch-list-table mt-3 float-left col-lg-12 p-0">
    <div class="customersearch-list-titles border-top float-left w-100 pl-2 pr-2 pt-3 pb-3 d-none d-sm-none d-lg-block">
      <div
        class="text-center float-left font-text-600 pl-0 font-size"
        [class.col-md-3]="quote.isPartial"
        [class.col-md-4]="!quote.isPartial"
      >
        <div class="text-center padding-res-0 float-left">
          <div class="remember-me col-md-12 float-left d-flex justify-content-start p-0">
            <div class="form-check custom-checkbox" *ngIf="!isDisabled()">
              <input
                type="checkbox"
                class="custom-control-input"
                id="delet"
                [checked]="allChecked"
                (change)="selectAll($event)"
              />
              <label class="custom-control-label color-font" for="delet"></label>
            </div>
          </div>
        </div>
        {{ 'quoteItems.header.productDetails' | cxTranslate }}
      </div>
      <div class="col-md-1 text-center float-left font-text-600 pl-2 pr-2 font-sm-size line-height-16">
        {{ 'quoteItems.header.plant' | cxTranslate }}
      </div>
      <div class="col-md-2 text-center float-left font-text-600 pl-2 pr-2 font-sm-size line-height-16">
        {{ 'quoteItems.header.reqShipDate' | cxTranslate }}
      </div>
      <div class="col-md-1 text-center float-left font-text-600 pl-2 pr-2 font-sm-size line-height-16">
        {{ 'quoteItems.header.listPrice' | cxTranslate }}
      </div>
      <div class="col-md-1 text-center float-left font-text-600 pl-2 pr-2 font-sm-size line-height-16">
        {{ 'quoteItems.header.netPrice' | cxTranslate }}
      </div>
      <div
        *ngIf="quote.isPartial"
        class="col-md-1 text-center float-left font-text-600 pl-2 pr-2 font-sm-size line-height-16"
      >
        {{ 'quoteItems.header.availableQty' | cxTranslate }}
      </div>
      <div class="col-md-1 text-center float-left font-text-600 pl-2 pr-2 font-sm-size line-height-16">
        {{ 'quoteItems.header.quantity' | cxTranslate }}
      </div>
      <div class="col-md-1 text-center float-left font-text-600 pl-1 pr-1 font-sm-size line-height-16">
        {{ 'quoteItems.header.total' | cxTranslate }}
      </div>
    </div>
    <kn-br-quote-item
      [entry]="entry"
      [isPartial]="quote.isPartial"
      *ngFor="let entry of quote.entries"
    ></kn-br-quote-item>
  </div>
</ng-container>
