import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SloanOrderHistorySearchComponent } from './sloan-order-history-search.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { FormErrorsModule } from '@spartacus/storefront';
import { NgSelectModule } from '@ng-select/ng-select';
import { KnBrDirectiveModule } from 'src/app/shared/directive/kn-br-directive.module';

@NgModule({
  declarations: [SloanOrderHistorySearchComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    I18nModule,
    FormErrorsModule,
    KnBrDirectiveModule,
    NgSelectModule,
    ConfigModule.withConfig({
      cmsComponents: {
        KnBrOrderHistorySearchComponent: {
          component: SloanOrderHistorySearchComponent,
        },
      },
    } as CmsConfig),
  ],
})
export class SloanOrderHistorySearchModule {}
