<form (ngSubmit)="onSubmit()" [formGroup]="checkoutLoginForm">
  <div class="form-group">
    <label>
      <span class="label-content">{{
        'checkoutLogin.emailAddress.label' | cxTranslate
      }}</span>
      <input
        required="true"
        type="email"
        name="email"
        class="form-control"
        formControlName="email"
        placeholder="{{
          'checkoutLogin.emailAddress.placeholder' | cxTranslate
        }}"
      />
      <cx-form-errors
        [control]="checkoutLoginForm.get('email')"
      ></cx-form-errors>
    </label>
  </div>

  <div class="form-group">
    <label>
      <span class="label-content">{{
        'checkoutLogin.confirmEmail.label' | cxTranslate
      }}</span>
      <input
        required="true"
        type="email"
        name="emailConfirmation"
        class="form-control"
        formControlName="emailConfirmation"
        placeholder="{{
          'checkoutLogin.confirmEmail.placeholder' | cxTranslate
        }}"
      />
      <cx-form-errors
        [control]="checkoutLoginForm.get('emailConfirmation')"
      ></cx-form-errors>
    </label>
  </div>

  <button type="submit" class="btn btn-block btn-primary">
    {{ 'checkoutLogin.continue' | cxTranslate }}
  </button>
</form>
