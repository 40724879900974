import { Observable } from 'rxjs';
import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, ComponentRef, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { FormBuilder, UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbCalendar, NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CmsService, RoutingService } from '@spartacus/core';
import { LaunchDialogService } from '@spartacus/storefront';
import { KnBrDateHelper } from 'src/app/shared/kn-br-date.helper';
import * as _ from 'lodash';
import { RemoveLeadingZerosPipe } from 'src/app/shared/pipe/kn-br-custom-pipes/remove-leading-zeros.pipe';
import { SloanCalendarHelper } from 'src/app/services/sloan/sloan-calendar-help.service';
import { SloanCommonService } from 'src/app/services/sloan/sloan-common.service';
import { SloanDataSharedService } from 'src/app/services/sloan/sloan-data-shared.service';
import { SloanQuotesService } from 'src/app/store/sloan/sloan-quotes/sloan-quotes.service';
import { UserAccountService } from '@spartacus/user/account/core';
import { distinctUntilChanged } from 'rxjs/operators';
import { KnBrQuote } from 'src/feature-libs/kn-br-cart/quote/root/models/kn-br-quote.model';
import { KnBrQuoteHeaderComponent } from 'src/feature-libs/kn-br-cart/quote/components/kn-br-quote/kn-br-quote-detail/kn-br-quote-header/kn-br-quote-header.component';
import { KnBrQuoteEntryService } from 'src/feature-libs/kn-br-cart/quote/core/facade/kn-br-quote-entry.service';
import { KnBrQuotesService } from 'src/feature-libs/kn-br-cart/quote/core/facade/kn-br-quotes.service';
import { UserAccountFacade } from '@spartacus/user/account/root';

@Component({
  selector: 'sloan-quote-header',
  templateUrl: './sloan-quote-header.component.html',
  styleUrls: ['./sloan-quote-header.component.scss'],
  providers: [RemoveLeadingZerosPipe],
})
export class SloanQuoteHeaderComponent extends KnBrQuoteHeaderComponent implements OnInit, OnDestroy {
  quote: any;
  invalidQuantityLineItems: string;
  tomorrow: any;
  quoteValidToMax = this.ngbCalendar.getNext(this.ngbCalendar.getToday(), 'd', 60);
  reqShipMinDate: NgbDate;
  reqShipMaxDate: NgbDate;
  today: NgbDate = this.sloanCalendarHelper.today;
  closed = false;
  isReqShipDisabled: (date: NgbDate, current: { year: number; month: number }) => boolean;
  isCheckoutDisabled$: Observable<boolean> = this.sloanDataSharedService.isQuoteCheckOutDisabled$();
  isQuickEntryProductsLoading$: Observable<boolean> = this.knBrQuoteEntryService.showLoadingWhileUpdate$;
  spinnerLoading$: Observable<boolean> = this.knBrQuoteService.showSpinner$;
  moqAlertClose = false;
  showSpinner: void | Observable<ComponentRef<any>>;
  statusKeyToShowEditBtn = 'BUYER_OFFER';
  expiredStatusKey = 'EXPIRED';
  isGenericLineItems: string;
  isSinkPatternLineItems: string;
  hasValidToDateSet = false;
  quoteValidityDate$: Observable<any> = this.sloanCommonService.loadQuoteValidityDate$();
  constructor(
    protected modalService: NgbModal,
    protected currentUserService: UserAccountFacade,
    public knBrQuoteService: KnBrQuotesService,
    protected fb: UntypedFormBuilder,
    protected ngbCalendar: NgbCalendar,
    protected cmsService: CmsService,
    protected location: Location,
    protected knBrDateHelper: KnBrDateHelper,
    protected routingService: RoutingService,
    protected knBrQuoteEntryService: KnBrQuoteEntryService,
    protected launchDialogService: LaunchDialogService,
    protected vcr: ViewContainerRef,
    protected removeLeadingZerosPipe: RemoveLeadingZerosPipe,
    protected sloanCommonService: SloanCommonService,
    protected sloanCalendarHelper: SloanCalendarHelper,
    protected sloanDataSharedService: SloanDataSharedService,
    protected sloanQuotesService: SloanQuotesService,
    protected cdr: ChangeDetectorRef
  ) {
    super(
      modalService,
      currentUserService,
      knBrQuoteService,
      fb,
      ngbCalendar,
      cmsService,
      location,
      knBrDateHelper,
      routingService,
      knBrQuoteEntryService,
      launchDialogService,
      vcr,
      removeLeadingZerosPipe
    );
    this.sloanCommonService.getSloanHolidayList();
    this.isReqShipDisabled = (date: NgbDate, current: { year: number; month: number }) => {
      return (
        this.sloanCalendarHelper.isHoliday(date) ||
        (this.sloanCalendarHelper.isWeekend(date) && date.month === current.month)
      );
    };
    this.reqShipMinDate = this.sloanCalendarHelper.getRddMinDate(this.today);
    this.reqShipMaxDate = this.sloanCalendarHelper.getRddMaxDate(this.today);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.sloanCommonService.getQuoteValidityDate();
    this.subscription.add(
      this.knBrQuoteService.get$.subscribe((quote) => {
        this.setQuoteValidToDate(quote);
        this.sloanDataSharedService.setQuoteCheckoutDisable(false);
        this.moqAlertClose = false;
      })
    );
    this.subscription.add(
      this.sloanQuotesService.showSpinner$.subscribe((isLoading) => {
        this.onSpinnerLoading(isLoading);
      })
    );
  }

  onEnterInput() {
    this.sloanDataSharedService.setQuoteCheckoutDisable(true);
  }

  initForm() {
    const poNumPattern = '[^*^~|<>]*';
    this.tomorrow = this.ngbCalendar.getNext(this.ngbCalendar.getToday(), 'd', 1);
    this.quoteValidToMax = this.ngbCalendar.getNext(this.ngbCalendar.getToday(), 'd', 60);
    this.quoteEditForm = this.fb.group({
      createdBy: [{ value: null, disabled: true }],
      creationDate: [{ value: null, disabled: true }],
      lastUpdatedDate: [{ value: null, disabled: true }],
      code: [{ value: null, disabled: true }],
      jobName: [{ value: null, disabled: this.isDisabled() }, Validators.required],
      quoteValidTo: [
        { value: this.ngbCalendar.getNext(this.ngbCalendar.getToday(), 'd', 60), disabled: true },
        Validators.required,
      ],
      rdd: [{ value: null, disabled: this.isDisabled() }, Validators.required],
      shipComplete: [{ value: true, disabled: this.isDisabled() }],
      status: [{ value: null, disabled: true }],
      purchaseOrderNumber: [{ value: null, disabled: this.isDisabled() }, [Validators.pattern(poNumPattern)]],
      headerNotes: [{ value: null, disabled: this.isDisabled() }],
      quoteStatus: null,
      isQuoteStimulated: false,
      editable: false,
    });
    // this.updateValidToDate();
  }

  updateValidToDate(quote?) {
    // Creating ngbdate with 60 days from today on UI.
    const ngbUIValidToDate = this.ngbCalendar.getNext(this.ngbCalendar.getToday(), 'd', 60);
    let ngbBackendValidToDate: NgbDate;

    // Creating ngbdate using the response from backend.
    this.quoteValidityDate$.subscribe((res) => {
      if (res && res != '') {
        const [year, month, day] = res.split('-');
        ngbBackendValidToDate = new NgbDate(parseInt(year), parseInt(month), parseInt(day));
        this.quoteValidToMax = ngbBackendValidToDate;

        if (!quote?.quoteValidTo) {
          // comparing and updating the validto date
          if (ngbUIValidToDate.before(ngbBackendValidToDate)) {
            this.quoteEditForm.patchValue({ quoteValidTo: ngbUIValidToDate });
            this.quoteEditForm.controls.quoteValidTo.setValue(ngbUIValidToDate);
          } else {
            this.quoteEditForm.patchValue({ quoteValidTo: ngbBackendValidToDate });
            this.quoteEditForm.controls.quoteValidTo.setValue(ngbBackendValidToDate);
          }
        }
        this.quoteEditForm.controls.quoteValidTo.updateValueAndValidity();
      }
    });
  }

  setQuoteValidToDate(quote?) {
    const ngbUIValidToDate = this.ngbCalendar.getNext(this.ngbCalendar.getToday(), 'd', 60);
    let ngbBackendValidToDate: NgbDate;
    this.quoteValidityDate$.pipe(distinctUntilChanged()).subscribe((res) => {
      if (res && res.response != '') {
        const [year, month, day] = res.split('-');
        ngbBackendValidToDate = new NgbDate(parseInt(year), parseInt(month), parseInt(day));
        this.quoteValidToMax = ngbBackendValidToDate;

        // comparing and updating the validto date
        if (!quote?.quoteValidTo) {
          console.log('quote?.quoteValidTo outside', quote?.quoteValidTo);
          if (ngbUIValidToDate.before(ngbBackendValidToDate)) {
            this.quoteEditForm.patchValue({ quoteValidTo: ngbUIValidToDate });
            this.quoteEditForm.controls.quoteValidTo.setValue(ngbUIValidToDate);
          } else {
            this.quoteEditForm.patchValue({ quoteValidTo: ngbBackendValidToDate });
            this.quoteEditForm.controls.quoteValidTo.setValue(ngbBackendValidToDate);
          }
        } else {
          this.quoteEditForm.patchValue({ quoteValidTo: this.knBrDateHelper.getDate(quote?.quoteValidTo) });
          // this.quoteEditForm.controls.quoteValidTo.updateValueAndValidity();
        }
      }
      setTimeout(() => {
        this.hasValidToDateSet = true;
      }, 100);
    });
  }

  patchForm(quote: KnBrQuote) {
    let dateFormat = null;
    this.dateFormat$.subscribe((format) => (dateFormat = format)).unsubscribe();
    this.quoteEditForm.patchValue({
      createdBy: quote.createdBy,
      lastModifiedBy:
        quote.lastModifiedBy && !_.includes(quote.lastModifiedBy.toLowerCase(), 'anonymous')
          ? quote.lastModifiedBy
          : '',
      creationDate: quote.creationDate,
      lastUpdatedDate: quote.lastUpdatedDate,
      code: quote.code,
      jobName: quote.jobName,
      quoteValidFrom: quote.quoteValidFrom ? this.knBrDateHelper.getDate(quote.quoteValidFrom) : null,
      quoteValidTo: quote.quoteValidTo ? this.knBrDateHelper.getDate(quote.quoteValidTo) : null,
      rdd: this.knBrDateHelper.getDate(quote.rdd),
      shipComplete: quote.shipComplete,
      quoteStatus: quote.quoteStatus,
      purchaseOrderNumber: quote.purchaseOrderNumber,
      headerNotes: quote.headerNotes,
      status: quote.status,
      editable: quote.editable,
    });
  }

  onSubmit(isRedirect = false) {
    this.sloanDataSharedService.setQuoteCheckoutDisable(false);
    if (this.quoteEditForm.valid && this.quoteEditForm.dirty) {
      const formValues = _.cloneDeep(this.quoteEditForm.getRawValue());
      if (!isRedirect) {
        this.quoteEditForm.get('quoteStatus').patchValue(null);
        formValues.quoteStatus = null;
        this.prepareFormForSubmit(isRedirect, formValues);
        this.setQuoteValidToDate();
      } else {
        const quoteStatus = this.quoteEditForm.get('quoteStatus').value;
        if (quoteStatus === 'SELLER_REJECTED' || quoteStatus === 'BUYER_PENDING_APPROVAL') {
          this.openRejectQuotePopup(isRedirect, formValues);
        } else {
          this.prepareFormForSubmit(isRedirect, formValues);
        }
      }
    } else {
      this.quoteEditForm.markAllAsTouched();
    }
  }

  saveUpdatedQuote(): void {
    this.quoteEditForm.markAsDirty();
    if (this.quoteEditForm.valid && this.quoteEditForm.dirty) {
      this.quoteEditForm.patchValue({ isQuoteStimulated: true });
      this.quoteEditForm.markAsDirty();
      this.hasValidToDateSet = false;
      this.cdr.detectChanges();
      this.onSubmit();
    } else {
      this.quoteEditForm.markAllAsTouched();
    }
    this.quoteEditForm.patchValue({ isQuoteStimulated: false });
  }

  closeGlobalMsg() {
    this.quote.globalMessage = null;
  }

  isSaveUpdateDisabled() {
    if (this.quote && this.quote.entries && this.quote.entries.length > 0) {
      const invalidQuantityEntries = this.quote.entries
        .filter((cart) => cart.isValidQuantity === false)
        .map((cart) => cart.entryNumber);
      if (invalidQuantityEntries && invalidQuantityEntries.length > 0) {
        this.invalidQuantityLineItems = invalidQuantityEntries.join(',');
        this.moqAlertClose = false;
        return true;
      } else {
        this.moqAlertClose = true;
        return false;
      }
    }
    return false;
  }

  editQuote() {
    const payload = {
      quoteStatus: 'BUYER_DRAFT',
    };
    this.sloanQuotesService.edit(payload);
  }

  isCheckoutDisabledUI() {
    if (this.quote && this.quote.entries && this.quote.entries.length > 0) {
      let isGenericEntries = this.quote.entries
        .filter((entry) => entry.product?.isGeneric && entry.commentCount === 0)
        .map((entry) => entry.product.code);
      let isSinkPatternEntries = this.quote.entries
        .filter((entry) => entry.product?.isSinkDrillPattern_b && entry.commentCount === 0)
        .map((entry) => entry.product.code);
      // To remove duplicate product codes.
      isGenericEntries = _.uniq(isGenericEntries);
      isSinkPatternEntries = _.uniq(isSinkPatternEntries);
      if (
        (isGenericEntries && isGenericEntries.length > 0) ||
        (isSinkPatternEntries && isSinkPatternEntries.length > 0)
      ) {
        this.isGenericLineItems = isGenericEntries.join(',');
        this.isSinkPatternLineItems = isSinkPatternEntries.join(',');
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  checkAnonymousKeyword(message: string) {
    return _.includes(message.toLowerCase(), 'anonymous');
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
