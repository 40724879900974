/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CartNotEmptyGuard, CheckoutAuthGuard } from '@spartacus/checkout/base/components';
import { CmsConfig, I18nModule, UrlModule, provideDefaultConfig } from '@spartacus/core';
import { AtMessageModule } from '@spartacus/storefront';
import { SloanCheckoutPlaceOrderComponent } from './sloan-checkout-place-order.component';
import { sloanPlaceOrderSpinnerLayoutConfig } from './sloan-place-order-spinner-layout.config';

@NgModule({
  imports: [AtMessageModule, CommonModule, RouterModule, UrlModule, I18nModule, ReactiveFormsModule],
  providers: [
    provideDefaultConfig(sloanPlaceOrderSpinnerLayoutConfig),
    provideDefaultConfig(<CmsConfig>{
      cmsComponents: {
        CheckoutPlaceOrder: {
          component: SloanCheckoutPlaceOrderComponent,
          guards: [CheckoutAuthGuard, CartNotEmptyGuard],
        },
      },
    }),
  ],
  declarations: [SloanCheckoutPlaceOrderComponent],
  exports: [SloanCheckoutPlaceOrderComponent],
})
export class SloanCheckoutPlaceOrderModule {}
