import { NgModule } from '@angular/core';
import { PRODUCT_NORMALIZER, PRODUCT_SEARCH_PAGE_NORMALIZER, ProductAdapter, ProductReferencesAdapter, ProductReviewsAdapter, ProductSearchAdapter, provideConfig, provideDefaultConfig } from '@spartacus/core';
import { ViewConfig } from '@spartacus/storefront/shared/config';
import { KnBrOccProductSearchAdapter } from '../core/service/kn-br-product-search.service';
import { KnBrOccProductReviewsAdapter } from './adapters/kn-br-occ-product-reviews.adapter';
import { KnBrOccProductAdapter } from './adapters/kn-br-occ-product.adapter';
import { knBrProductEndpointsConfig, knBrProductViewConfig } from './config/kn-br-product.config';
import { ProductPrettyNameNormalizer } from '../root/converters/kn-br-product-name.normalizer';
import { KnProductSearchPageNormalizer } from '../root/converters/kn-br-product-search-page.normalizer';
import { KnBrOccProductReferencesAdapter } from './adapters/kn-br-occ-product-references.adapter';

@NgModule({
  imports: [],
  providers: [
    provideConfig(knBrProductEndpointsConfig),
    provideConfig(<ViewConfig>knBrProductViewConfig),
    {
      provide: ProductAdapter,
      useClass: KnBrOccProductAdapter,
    },
    {
      provide: ProductReferencesAdapter,
      useClass: KnBrOccProductReferencesAdapter,
    },
    {
      provide: ProductReviewsAdapter,
      useClass: KnBrOccProductReviewsAdapter,
    },
    {
      provide: ProductSearchAdapter,
      useClass: KnBrOccProductSearchAdapter,
    },
    {
      provide: PRODUCT_SEARCH_PAGE_NORMALIZER,
      useClass: KnProductSearchPageNormalizer,
      multi: true,
    },
    {
      provide: PRODUCT_NORMALIZER,
      useExisting: ProductPrettyNameNormalizer,
      multi: true,
    },
  ],
})
export class KnBrProductOccModule { }
