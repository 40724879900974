/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
import { NgModule } from '@angular/core';
import { KnBrResetPasswordModule } from './kn-br-reset-password/kn-br-reset-password.module';
import { KnBrUpdateProfileModule } from './kn-br-update-profile/kn-br-update-profile.module';
import { KnBrForgotPasswordModule } from './kn-br-forgot-password/kn-br-forgot-password.module';

@NgModule({
  imports: [KnBrUpdateProfileModule, KnBrForgotPasswordModule, KnBrResetPasswordModule],
})
export class KnBrProfileComponentsModule {}
