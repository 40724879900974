import { KnBrDraftOrdersActionTypes, KnBrDraftOrdersSearchActions } from '../actions/kn-br-draft-orders.action';
import { KnBrDraftOrdersState } from '../../../../../../app/store/kn-br-draft-orders/kn-br-draft-orders.state';

export const initialState: KnBrDraftOrdersState = {
  orders: [],
  error: undefined,
  searchRequest: undefined,
  isLoading: false,
  pagination: undefined,
  order: undefined,
  entry: undefined,
  isSearchDisabled: false,
  isCreateDisabled: false,
  statuses: [],
  quickentry: undefined,
  resetForm: false,
  showSpinner: false,
  checkoutDisabled: false,
  warningMessage: undefined,
};

export function knBrDraftOrderReducer(
  state = initialState,
  action: KnBrDraftOrdersSearchActions
): KnBrDraftOrdersState {
  switch (action.type) {
    case KnBrDraftOrdersActionTypes.KnBrDraftOrderSearchAction:
      return { ...state, searchRequest: action.payload, isLoading: true, isSearchDisabled: true };
    case KnBrDraftOrdersActionTypes.KnBrDraftOrderSearchSuccessAction:
      return {
        ...state,
        orders: [...state.orders, ...action.payload?.carts],
        pagination: action.payload?.pagination,
        isLoading: false,
        isSearchDisabled: false,
      };
    case KnBrDraftOrdersActionTypes.KnBrDraftOrderSearchFailureAction:
      return { ...state, error: action.payload, isLoading: false, isSearchDisabled: false };
    case KnBrDraftOrdersActionTypes.KnBrDraftOrderSearchResetAction:
      return { ...state, orders: [], pagination: null, isLoading: true };
    case KnBrDraftOrdersActionTypes.KnBrDraftOrderCopyAction:
      return { ...state, order: action.payload };
    case KnBrDraftOrdersActionTypes.KnBrDraftOrderCopySuccessAction:
      return { ...state, order: action.payload };
    case KnBrDraftOrdersActionTypes.KnBrDraftOrderCopyFailureAction:
      return { ...state, error: action.payload };
    case KnBrDraftOrdersActionTypes.KnBrDraftOrderUpdateAction:
      return { ...state, order: action.payload, showSpinner: true, warningMessage: action.payload?.warningMessage };
    case KnBrDraftOrdersActionTypes.KnBrDraftOrderUpdateSuccessAction:
      return { ...state, order: action.payload, showSpinner: false, warningMessage: action.payload?.warningMessage };
    case KnBrDraftOrdersActionTypes.KnBrDraftOrderUpdateFailureAction:
      return { ...state, error: action.payload, showSpinner: false };
    case KnBrDraftOrdersActionTypes.KnBrDraftOrderCreateAction:
      return { ...state, order: action.payload, isCreateDisabled: true, isLoading: true };
    case KnBrDraftOrdersActionTypes.KnBrDraftOrderCreateSuccessAction:
      return { ...state, order: action.payload, isCreateDisabled: false, isLoading: false };
    case KnBrDraftOrdersActionTypes.KnBrDraftOrderCreateFailureAction:
      return { ...state, error: action.payload, isCreateDisabled: false, isLoading: false };
    case KnBrDraftOrdersActionTypes.KnBrDraftOrderEntryUpdateAction:
      return { ...state, error: action.payload, isCreateDisabled: true, checkoutDisabled: true };
    case KnBrDraftOrdersActionTypes.KnBrDraftOrderEntryUpdateSuccessAction:
      return { ...state, entry: action.payload, isCreateDisabled: false, checkoutDisabled: false };
    case KnBrDraftOrdersActionTypes.KnBrDraftOrderEntryUpdateFailureAction:
      return { ...state, error: action.payload, isCreateDisabled: false, checkoutDisabled: false };
    case KnBrDraftOrdersActionTypes.KnBrDraftOrderLoadStatusesSuccessAction:
      return { ...state, statuses: action.payload };
    case KnBrDraftOrdersActionTypes.KnBrDraftOrderLoadStatusesFailureAction:
      return { ...state, error: action.payload };
    case KnBrDraftOrdersActionTypes.KnBrDraftOrderQuickEntryAction:
      return { ...state, quickentry: action.payload, isLoading: true, isCreateDisabled: true };
    case KnBrDraftOrdersActionTypes.KnBrDraftOrdeQuickEntrySuccessAction:
      return { ...state, order: action.payload, isLoading: false, isCreateDisabled: false };
    case KnBrDraftOrdersActionTypes.KnBrDraftOrderQuickEntryFailureAction:
      return { ...state, error: action.payload, isLoading: false, isCreateDisabled: false };
    case KnBrDraftOrdersActionTypes.KnBrQuickOrderResetFormAction:
      return { ...state, resetForm: action.payload };
    case KnBrDraftOrdersActionTypes.KnBrDraftOrderWarningMsgAction:
      return { ...state, warningMessage: null };
    case KnBrDraftOrdersActionTypes.KnBrDraftOrderDeleteAction:
      return { ...state, order: action.payload, showSpinner: true };
    case KnBrDraftOrdersActionTypes.KnBrDraftOrderDeleteSuccessAction:
      return { ...state, order: action.payload, showSpinner: false };
    case KnBrDraftOrdersActionTypes.KnBrDraftOrderDeleteFailureAction:
      return { ...state, error: action.payload, showSpinner: false };
    default:
      return state;
  }
}
