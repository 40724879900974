import { Component, OnInit } from '@angular/core';
// import { CheckoutDeliveryFacade } from '@spartacus/checkout/root';
import { GlobalMessageService, TranslationService, UserAddressService } from '@spartacus/core';
import { AddressBookComponent, AddressBookComponentService } from "@spartacus/user/profile/components";
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { KnBrAddress } from 'src/feature-libs/kn-br-order/root/models/kn-br-order.model';

@Component({
  selector: 'knbr-kn-br-address-book',
  templateUrl: './kn-br-address-book.component.html',
  styleUrls: ['./kn-br-address-book.component.scss'],
})
export class KnBrAddressBookComponent extends AddressBookComponent implements OnInit {
  constructor(
    public service: AddressBookComponentService,
    protected translation: TranslationService,
    protected globalMessageService: GlobalMessageService,
    protected userAddressService: UserAddressService // protected checkoutDeliveryService: CheckoutDeliveryFacade
  ) {
    super(service, translation, globalMessageService);
  }
  ngOnInit(): void {
    super.ngOnInit();
  }

  getKnbrCardContent(address: KnBrAddress) {
    return combineLatest([
      this.translation.translate('addressCard.default'),
      this.translation.translate('addressCard.setAsDefault'),
      this.translation.translate('common.delete'),
      this.translation.translate('common.edit'),
      this.translation.translate('addressBook.areYouSureToDeleteAddress'),
    ]).pipe(
      map(([defaultText, setAsDefaultText, textDelete, textEdit, textVerifyDeleteMsg]) => {
        let region = '';

        if (address.region && address.region.isocode) {
          region = address.region.isocode;
        }

        const actions: { name: string; event: string }[] = [];
        if (!address.defaultAddress) {
          actions.push({ name: setAsDefaultText, event: 'default' });
        }
        actions.push({ name: textEdit, event: 'edit' });
        actions.push({ name: textDelete, event: 'delete' });

        return {
          textBold: address.firstName + (address.lastName ? ' ' + address.lastName : ''),
          text: [
            (address.houseNumber ? address.houseNumber + ', ' : '') + address.line1,
            address.line2,
            address.town + ', ' + region,
            address.country.isocode + ', ' + address.postalCode,
            address.phone,
          ],
          actions,
          // header: address.defaultAddress ? `${address.firstName} (${defaultText})` : address.firstName,
          header: address.defaultAddress ? `✓ ${defaultText}` : '',
          deleteMsg: textVerifyDeleteMsg,
        };
      })
    );
  }
}
