import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { KnBrCustomerContextService } from 'src/app/store/kn-br-customer-context/kn-br-customer-context.service';
import { KN_BR_QUOTE_GET_ADD_COMMENTS, KN_BR_QUOTE_UPDATE_DELETE_COMMENTS } from 'src/constants/api.endpoints.constant';
import { KnBrQuoteContextService } from '../facade/kn-br-quote-context.service';

@Injectable({
  providedIn: 'root',
})
export class KnBrQuotesCommentsAdapter {
  private customerNumber: string;
  quoteId: string;
  constructor(
    private endPointService: OccEndpointsService,
    private httpClient: HttpClient,
    protected knBrCustomerContextService: KnBrCustomerContextService,
    protected knBrQuoteContextService: KnBrQuoteContextService
  ) {
    this.knBrCustomerContextService.getActiveCustomerNumber().subscribe((response) => {
      this.customerNumber = response ? response : null;
    });
    this.knBrQuoteContextService.get$.subscribe((response) => {
      this.quoteId = response ? response : null;
    });
  }

  get(entryNumber) {
    return this.httpClient.get(
      this.endPointService.buildUrl(KN_BR_QUOTE_GET_ADD_COMMENTS, {
        urlParams: {
          customerId: this.customerNumber,
          quoteId: this.quoteId,
          entryNumber,
        },
      })
    );
  }

  create(payload) {
    return this.httpClient.post(
      this.endPointService.buildUrl(KN_BR_QUOTE_GET_ADD_COMMENTS, {
        urlParams: {
          customerId: this.customerNumber,
          quoteId: this.quoteId,
          entryNumber: payload.entryNumber,
        },
        queryParams: {
          ...payload,
        },
      }),
      null
    );
  }

  update(payload) {
    return this.httpClient.patch(
      this.endPointService.buildUrl(KN_BR_QUOTE_UPDATE_DELETE_COMMENTS, {
        urlParams: {
          customerId: this.customerNumber,
          quoteId: this.quoteId,
          entryNumber: payload.entryNumber,
          commentId: payload.code,
        },
        queryParams: {
          ...payload,
        },
      }),
      null
    );
  }
  delete(payload) {
    return this.httpClient.delete(
      this.endPointService.buildUrl(KN_BR_QUOTE_UPDATE_DELETE_COMMENTS, {
        urlParams: {
          customerId: this.customerNumber,
          quoteId: this.quoteId,
          entryNumber: payload.entryNumber,
          commentId: payload.code,
        },
      })
    );
  }
}
