import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { KnBrCustomPipesModule } from 'src/app/shared/pipe/kn-br-custom-pipes/kn-br-custom-pipes.module';
import { KnBrParagraphComponent } from './kn-br-paragraph.component';

@NgModule({
    declarations: [KnBrParagraphComponent],
    imports: [
        CommonModule,
        KnBrCustomPipesModule,
        ConfigModule.withConfig({
            cmsComponents: {
                CMSParagraphComponent: {
                    component: KnBrParagraphComponent,
                },
            },
        } as CmsConfig),
    ],
    providers: [],
    exports: [KnBrParagraphComponent]
})
export class KnBrParagraphModule {}
