<ng-container *ngIf="order$ | async as order">
  <div class="col-md-12 float-right d-flex justify-content-end">
    <button class="btn back-btn p-1 font-lg-size" (click)="goBack()">
      <i class="fas fa-level-down-alt fa-rotate-90" aria-hidden="true"></i>
    </button>
  </div>
  <div class="col-md-12 float-left p-0 mt-3 mb-4">
    <div class="col-md-9 float-left p-0">
      <div class="col-md-4 float-left">
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrOrderDetails.label.orderNumber' | cxTranslate }}
        </label>
        <p class="text-muted mb-2">{{ order.code | removeLeadingZeros }}</p>
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrOrderDetails.label.orderStatus' | cxTranslate }}
        </label>
        <p class="text-muted mb-2">{{ order.statusDisplay }}</p>
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrOrderDetails.label.orderType' | cxTranslate }}
        </label>
        <p class="text-muted mb-2">{{ order.orderType }}</p>
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrOrderDetails.label.poNumber' | cxTranslate }}
        </label>
        <p class="text-muted mb-2">{{ order.purchaseOrderNumber }}</p>
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrOrderDetails.label.soldTo' | cxTranslate }}
        </label>
        <p class="float-left text-muted mb-2" *ngIf="order.soldTo">
          <span class="w-100 float-left">{{ order.soldTo?.customerNumber | removeLeadingZeros }}</span>
          <span class="w-100 float-left">{{ order.soldTo?.customerName }}</span>
          <span class="w-100 float-left">{{ order.soldTo?.street }}</span>
          <span class="w-100 float-left">{{ order.soldTo?.city }}</span>
          <span class="w-100 float-left">{{ order.soldTo?.state }}</span>
          <span class="w-100 float-left">{{ order.soldTo?.country }}, {{ order.soldTo?.postalCode }}</span>
          <span class="w-100 float-left">{{ order.soldTo?.telephone }}</span>
        </p>
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrOrderDetails.label.shipTo' | cxTranslate }}
        </label>
        <p class="float-left text-muted mb-2" *ngIf="order.deliveryAddress">
          <span class="w-100 float-left">{{ order.deliveryAddress?.firstName }}</span>
          <span class="w-100 float-left">{{ order.deliveryAddress?.line1 }}</span>
          <span class="w-100 float-left">{{ order.deliveryAddress?.town }}</span>
          <span class="w-100 float-left">{{ order.deliveryAddress?.region?.name }}</span>
          <span class="w-100 float-left"
            >{{ order.deliveryAddress?.country?.name }}, {{ order.deliveryAddress?.postalCode }}</span
          >
          <span class="w-100 float-left">{{ order.deliveryAddress?.cellphone }}</span>
        </p>
      </div>
      <div class="col-md-4 float-left">
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrOrderDetails.label.orderCreationDate' | cxTranslate }}
        </label>
        <p class="text-muted mb-2">{{ order.creationDate }}</p>
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrOrderDetails.label.orderPlacedBy' | cxTranslate }}
        </label>
        <p class="text-muted mb-2">{{ order.placedBy }}</p>
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrOrderDetails.label.jobNumber' | cxTranslate }}
        </label>
        <p class="text-muted mb-2">{{ order.jobName }}</p>
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrOrderDetails.label.shippingNotes' | cxTranslate }}
        </label>
        <p class="text-muted mb-2"></p>
      </div>
      <div class="col-md-4 float-left">
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrOrderDetails.label.reqShipDate' | cxTranslate }}
        </label>
        <p class="text-muted mb-2">{{ order.requestedDeliveryDate }}</p>
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrOrderDetails.label.shippingMethod' | cxTranslate }}
        </label>
        <p class="text-muted mb-2">{{ order.deliveryMode?.name }}</p>
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrOrderDetails.label.totalQty' | cxTranslate }}
        </label>
        <p class="text-muted mb-2">{{ order.totalUnitCount }}</p>
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrOrderDetails.label.orderTotal' | cxTranslate }}
        </label>
        <p class="text-muted mb-2">{{ order.totalPrice?.formattedValue }}</p>
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrOrderDetails.label.downloadDocs' | cxTranslate }}
        </label>
        <p class="float-left mb-2">
          <a
            target="_blank"
            href="{{ doc?.documentMedia?.downloadURL }}"
            class="text-muted cursor-pointer w-100 float-left"
            *ngFor="let doc of order.knBrSAPDocuments"
            >{{ doc?.documentMedia?.realFileName }}</a
          >
        </p>
      </div>
    </div>
    <div class="col-md-3 float-left">
      <cx-page-slot position="ActionSlot"></cx-page-slot>
    </div>
  </div>
</ng-container>
