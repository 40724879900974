import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { CardModule, SpinnerModule } from '@spartacus/storefront';
import { KnBrCustomPipesModule } from 'src/app/shared/pipe/kn-br-custom-pipes/kn-br-custom-pipes.module';
import { SloanPaymentMethodComponent } from './sloan-payment-method.component';
import { CartNotEmptyGuard, CheckoutAuthGuard } from '@spartacus/checkout/base/components';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    CardModule,
    SpinnerModule,
    I18nModule,
    KnBrCustomPipesModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CheckoutPaymentDetails: {
          component: SloanPaymentMethodComponent,
          guards: [CheckoutAuthGuard, CartNotEmptyGuard],
        },
      },
    } as CmsConfig),
  ],
  declarations: [SloanPaymentMethodComponent],
  exports: [SloanPaymentMethodComponent],
})
export class SloanPaymentMethodModule { }
