import { Component, OnInit } from '@angular/core';
import { AsmComponentService } from '@spartacus/asm/components';
import { AsmService } from '@spartacus/asm/core';
import { CsAgentAuthService } from '@spartacus/asm/root';
import { AuthService, GlobalMessageService, RoutingService } from '@spartacus/core';
import { LaunchDialogService } from '@spartacus/storefront';
import { UserAccountService } from '@spartacus/user/account/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { KnBrAsmMainUiComponent } from 'src/feature-libs/kn-br-asm/components/kn-br-asm-main-ui/kn-br-asm-main-ui.component';
import { KnBrAuthRedirectService } from 'src/feature-libs/kn-br-user/kn-br-account/root/facade/kn-br-auth-redirect.service';

@Component({
  selector: 'cx-asm-main-ui',
  templateUrl: './sloan-asm-main-ui.component.html',
  styleUrls: ['./sloan-asm-main-ui.component.scss'],
})
export class SloanAsmMainUiComponent extends KnBrAsmMainUiComponent implements OnInit {
  constructor(
    protected authService: AuthService,
    protected csAgentAuthService: CsAgentAuthService,
    protected userService: UserAccountFacade,
    protected asmComponentService: AsmComponentService,
    protected globalMessageService: GlobalMessageService,
    protected routingService: RoutingService,
    protected asmService: AsmService,
    protected knBrAuthRedirectService: KnBrAuthRedirectService,
    protected launchDialogService: LaunchDialogService
  ) {
    super(
      authService,
      csAgentAuthService,
      userService,
      asmComponentService,
      globalMessageService,
      routingService,
      asmService,
      knBrAuthRedirectService,
      launchDialogService
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
}
