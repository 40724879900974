import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SloanFactSheetModule } from './sloan-fact-sheet/sloan-fact-sheet.module';
import { SloanInvoiceModule } from './sloan-invoice/sloan-invoice.module';
import { ADDRESS_VALIDATION_NORMALIZER, ConfigModule } from '@spartacus/core';
import { SloanAddressBookModule } from './sloan-address-book/sloan-address-book.module';
import { sloanCustomer360EndpointsConfig } from './sloan-customer360-endpoints.config';
import { SloanOrderDetailModule } from './sloan-order-detail/sloan-order-detail.module';
import { SloanOrderHistoryModule } from './sloan-order-history/sloan-order-history.module';
import { SloanCustomer360HomeModule } from './sloan-customer360-home/sloan-customer360-home.module';
import { SloanReturnOrderDetailModule } from './sloan-return-order-detail/sloan-return-order-detail.module';
import { SloanAddressValidationNormalizer } from 'src/app/core/user/connectors/sloan/sloan-address-validation.converter';
import { SloanAcknowledgeModule } from './sloan-acknowledge/sloan-acknowledge.module';

@NgModule({
  imports: [
    CommonModule,
    SloanFactSheetModule,
    SloanInvoiceModule,
    SloanAddressBookModule,
    SloanOrderHistoryModule,
    SloanAcknowledgeModule,
    SloanOrderDetailModule,
    SloanCustomer360HomeModule,
    SloanReturnOrderDetailModule,
    ConfigModule.withConfig(sloanCustomer360EndpointsConfig),
  ],
  providers: [
    {
      provide: ADDRESS_VALIDATION_NORMALIZER,
      useClass: SloanAddressValidationNormalizer,
      multi: true,
    },
  ],
})
export class SloanCustomer360Module { }
