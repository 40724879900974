import { OccConfig } from '@spartacus/core';
import { ViewConfig } from '@spartacus/storefront';

export const knBrProductEndpointsConfig: OccConfig = {
  backend: {
    occ: {
      endpoints: {
        // tslint:disable:max-line-length
        product: {
          default:
            'knbr/customer/${customerId}/products/${productCode}?fields=DEFAULT,averageRating,images(FULL),classifications,manufacturer,numberOfReviews,categories(FULL),baseOptions,baseProduct,variantOptions,variantType',
          list: 'knbr/customer/${customerId}/products/${productCode}?fields=code,name,summary,price(formattedValue),images(DEFAULT,galleryIndex),knbrPrice',
          details:
            'knbr/customer/${customerId}/products/${productCode}?fields=code,name,summary,images(galleryIndex,FULL),stock(DEFAULT),description,url,manufacturer,categories(FULL),priceRange,multidimensional,configuratorType,tags,knbrPrice,minOrderQuantity,uom',
          attributes: 'knbr/customer/${customerId}/products/${productCode}?fields=classifications',
          variants:
            'knbr/customer/${customerId}/products/${productCode}?fields=name,purchasable,baseOptions(DEFAULT),baseProduct,variantOptions(DEFAULT),variantType',
        },
        productReviews: 'knbr/customer/${customerId}/products/${productCode}/reviews',
        productReferences:
          'knbr/customer/${customerId}/products/${productCode}/references?fields=DEFAULT,references(target(images(FULL)))',
        productSearch:
          'knbr/customer/${customerId}/products/search?fields=products(code,name,summary,price(FULL),images(DEFAULT),stock(FULL),averageRating,variantOptions,uom,minOrderQuantity),facets,breadcrumbs,pagination(DEFAULT),sorts(DEFAULT),freeTextSearch,currentQuery',
        productSuggestions: 'knbr/customer/${customerId}/products/suggestions',
      },
    },
  },
};

export const knBrProductViewConfig: ViewConfig = {
  view: {
    infiniteScroll: {
      active: true,
      productLimit: 1,
      showMoreButton: true,
    },
  },
};
