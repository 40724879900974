import { Action } from '@ngrx/store';

import { KnBrQuotesComments } from '../../../root/models/kn-br-quote.model';

export enum KnBrQuoteCommentsActionTypes {
  KnBrQuoteCommentsUpdateAction = '[QuoteComments] Update',
  KnBrQuoteCommentsUpdateSuccessAction = '[QuoteComments] Update Success',
  KnBrQuoteCommentsUpdateFailureAction = '[QuoteComments] Update Failure',
  KnBrQuoteCommentsCreateAction = '[QuoteComments] Create',
  KnBrQuoteCommentsCreateSuccessAction = '[QuoteComments] Create Success',
  KnBrQuoteCommentsCreateFailureAction = '[QuoteComments] Create Failure',
  KnBrQuoteCommentsLoadAction = '[QuoteComments] Load QuoteComments',
  KnBrQuoteCommentsLoadFailureAction = '[QuoteComments] Load QuoteComments Fail',
  KnBrQuoteCommentsLoadSuccessAction = '[QuoteComments] Load QuoteComments Success',
  KnBrQuoteCommentsDeleteAction = '[QuoteComments] Delete',
}

/**
 * Action dispatched on QuoteComments Update
 */
export class KnBrQuoteCommentsUpdate implements Action {
  readonly type = KnBrQuoteCommentsActionTypes.KnBrQuoteCommentsUpdateAction;
  constructor(public payload: any) { }
}

/**
 * Action dispatched on QuoteComments Update Success
 */
export class KnBrQuoteCommentsUpdateSuccess implements Action {
  readonly type = KnBrQuoteCommentsActionTypes.KnBrQuoteCommentsUpdateSuccessAction;
  constructor(public payload: any) { }
}

/**
 * Action dispatched on QuoteComments Update Failure
 */
export class KnBrQuoteCommentsUpdateFailure implements Action {
  readonly type = KnBrQuoteCommentsActionTypes.KnBrQuoteCommentsUpdateFailureAction;
  constructor(public payload: Error) { }
}

/**
 * Action dispatched on QuoteComments Create
 */
export class KnBrQuoteCommentsCreate implements Action {
  readonly type = KnBrQuoteCommentsActionTypes.KnBrQuoteCommentsCreateAction;
  constructor(public payload: any) { }
}

/**
 * Action dispatched on QuoteComments Create Success
 */
export class KnBrQuoteCommentsCreateSuccess implements Action {
  readonly type = KnBrQuoteCommentsActionTypes.KnBrQuoteCommentsCreateSuccessAction;
  constructor(public payload: any) { }
}

/**
 * Action dispatched on QuoteComments Create Failure
 */
export class KnBrQuoteCommentsCreateFailure implements Action {
  readonly type = KnBrQuoteCommentsActionTypes.KnBrQuoteCommentsCreateFailureAction;
  constructor(public payload: Error) { }
}

/**
 * Action dispatched on QuoteComments Load
 */
export class KnBrQuoteCommentsLoad implements Action {
  readonly type = KnBrQuoteCommentsActionTypes.KnBrQuoteCommentsLoadAction;
  constructor(public entryNumber: Number) { }
}

/**
 * Action dispatched on QuoteComments Load Success
 */
export class KnBrQuoteCommentsLoadSuccess implements Action {
  readonly type = KnBrQuoteCommentsActionTypes.KnBrQuoteCommentsLoadSuccessAction;
  constructor(public payload: any) { }
}

/**
 * Action dispatched on QuoteComments Load Failure
 */
export class KnBrQuoteCommentsLoadFailure implements Action {
  readonly type = KnBrQuoteCommentsActionTypes.KnBrQuoteCommentsLoadFailureAction;
  constructor(public payload: Error) { }
}

export class KnBrQuoteCommentsDelete implements Action {
  readonly type = KnBrQuoteCommentsActionTypes.KnBrQuoteCommentsDeleteAction;
  constructor(public payload: KnBrQuotesComments) { }
}
export type KnBrQuoteCommentsActions =
  | KnBrQuoteCommentsUpdate
  | KnBrQuoteCommentsUpdateSuccess
  | KnBrQuoteCommentsUpdateFailure
  | KnBrQuoteCommentsCreate
  | KnBrQuoteCommentsCreateSuccess
  | KnBrQuoteCommentsCreateFailure
  | KnBrQuoteCommentsLoad
  | KnBrQuoteCommentsLoadSuccess
  | KnBrQuoteCommentsLoadFailure
  | KnBrQuoteCommentsDelete;
