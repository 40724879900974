import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SloanOrderDetailsItemsComponent } from './sloan-order-details-items.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { UrlModule, I18nModule, ConfigModule, CmsConfig } from '@spartacus/core';
import { SpinnerModule, MediaModule } from '@spartacus/storefront';
import { SloanAddProductsComponent } from '../sloan-add-products/sloan-add-products.component';
import { KnBrOrderDetailsItemsModule } from 'src/feature-libs/kn-br-order/components/kn-br-customer360/kn-br-order-detail/kn-br-order-details-items/kn-br-order-details-items.module';

@NgModule({
  imports: [
    CommonModule,
    SpinnerModule,
    NgbTooltipModule,
    RouterModule,
    UrlModule,
    MediaModule,
    I18nModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    KnBrOrderDetailsItemsModule,
    ConfigModule.withConfig({
      cmsComponents: {
        AccountOrderDetailsItemsComponent: {
          component: SloanOrderDetailsItemsComponent,
        },
      },
    } as CmsConfig),
  ],
  declarations: [SloanOrderDetailsItemsComponent, SloanAddProductsComponent],
})
export class SloanOrderDetailsItemsModule {}
