import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { KnBrAllowDecimalDirective } from './kn-br-allow-decimal.directive';
import { KnBrAllowNumberDirective } from './kn-br-allow-number.directive';
import { KnBrNumbersOnlyDirective } from './kn-br-numbers-only.directive';
import { KnBrAlphaNumericOnlyDirective } from './kn-br-alpha-only.directive';
import { SloanDebounceClickDirective } from './sloan/sloan-double-click.directive';

@NgModule({
  imports: [FormsModule],
  declarations: [
    KnBrAllowDecimalDirective,
    KnBrAllowNumberDirective,
    KnBrNumbersOnlyDirective,
    KnBrAlphaNumericOnlyDirective,
    SloanDebounceClickDirective,
  ],
  exports: [
    KnBrAllowDecimalDirective,
    KnBrAllowNumberDirective,
    KnBrNumbersOnlyDirective,
    KnBrAlphaNumericOnlyDirective,
    SloanDebounceClickDirective,
  ],
})
export class KnBrDirectiveModule {}
