import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ConverterService, LoggerService, OccEndpointsService, normalizeHttpError } from '@spartacus/core';
import { OccUserProfileAdapter } from '@spartacus/user/profile/occ';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { knBrAuthService } from 'src/app/core/kn-br-auth/kn-br-user-auth/facade/kn-br-auth.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class SloanUserProfileAdapter extends OccUserProfileAdapter {
  protected logger = inject(LoggerService);
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converter: ConverterService,
    protected knBrAuthService: knBrAuthService
  ) {
    super(http, occEndpoints, converter);
  }

  updateEmail(userId: string, currentPassword: string, newUserId: string): Observable<unknown> {
    const url = this.occEndpoints.buildUrl('userUpdateLoginId', {
      urlParams: { userId },
    });
    const httpParams: HttpParams = new HttpParams()
      .set('password', currentPassword)
      .set('newLogin', environment.BASE_SITE[0] + '|' + newUserId);
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    return this.http
      .put(url, httpParams, { headers })
      .pipe(map(() => this.knBrAuthService.logout()))
      .pipe(catchError((error) => throwError(normalizeHttpError(error, this.logger))));
  }
}
