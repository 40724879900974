import { Injectable } from '@angular/core';
import { GlobalMessageService, GlobalMessageType, OccEndpointsService, WindowRef } from '@spartacus/core';
import { KnBrCustomerContextService } from 'src/app/store/kn-br-customer-context/kn-br-customer-context.service';
import { KNBR_ORDER_ACTIONS, KN_BR_CUSTOMER_CURRENT } from 'src/constants/api.endpoints.constant';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { FileSaverService } from 'ngx-filesaver';
import { BehaviorSubject, Observable } from 'rxjs';
import { Statuses } from 'src/feature-libs/kn-br-cart/quote/root/models/kn-br-quote.model';
import { environment } from 'src/environments/environment';
import { PublicHoliday } from 'src/app/models/sloan/sloan-holidays.model';
import {
  SLOAN_FACT_SHEET_PDF,
  SLOAN_INVOICE_HEADER_PDF,
  SLOAN_DOCUMENT_PDF,
  SLOAN_ORDER_TYPES,
  SLOAN_CREDIT_STATUSES,
  SLOAN_DELIVERY_BLOCK_LIST,
  SLOAN_HOLIDAY_LIST,
  SLOAN_QUOTE_SINK_HOLE_DRILL_PATTERNS,
  SLOAN_DRAFT_ORDER_SINK_HOLE_DRILL_PATTERNS,
  PAYMETRICS_ACCESS_TOKEN,
  PAYMETRICS_RESPONSE_STATUS,
  SLOAN_QUOTE_VALIDATY_DATE,
  SLOAN_DELIVERYMODE,
} from 'src/constants/sloan/sloan-api.endpoints.constant';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { KnBrQuoteContextService } from 'src/feature-libs/kn-br-cart/quote/core/facade/kn-br-quote-context.service';

@Injectable({
  providedIn: 'root',
})
export class SloanCommonService {
  customerId: string;
  quoteId: string;
  cartId: string;
  orderTypeList$: BehaviorSubject<Statuses[]> = new BehaviorSubject(null);
  creditStatusList$: BehaviorSubject<Statuses[]> = new BehaviorSubject(null);
  deliveryBlockList$: BehaviorSubject<Statuses[]> = new BehaviorSubject(null);
  holidayList$: BehaviorSubject<PublicHoliday[]> = new BehaviorSubject(null);
  quoteSinkHoleDrillPatterns$: BehaviorSubject<Statuses[]> = new BehaviorSubject(null);
  draftOrderSinkHoleDrillPatterns$: BehaviorSubject<Statuses[]> = new BehaviorSubject(null);
  orderActionsList$: BehaviorSubject<string[]> = new BehaviorSubject(null);
  paymetricsDetail$: BehaviorSubject<any> = new BehaviorSubject(null);
  quoteValidityDate$: BehaviorSubject<any> = new BehaviorSubject(null);
  deliveryModeData$: BehaviorSubject<any> = new BehaviorSubject(null);
  selectedPaymentTypes$: BehaviorSubject<any> = new BehaviorSubject(null);
  editOrderResponse$: BehaviorSubject<any> = new BehaviorSubject({});

  constructor(
    protected knBrCustomerContextService: KnBrCustomerContextService,
    private globalMessageService: GlobalMessageService,
    private httpClient: HttpClient,
    private endPointService: OccEndpointsService,
    private fileSaver: FileSaverService,
    protected knBrQuoteContextService: KnBrQuoteContextService,
    protected activeCartService: ActiveCartFacade,
    protected winRef: WindowRef
  ) {
    this.knBrCustomerContextService.getActiveCustomerNumber().subscribe((response) => {
      this.customerId = response ? response : KN_BR_CUSTOMER_CURRENT;
    });
    this.knBrQuoteContextService.get$.subscribe((response) => {
      this.quoteId = response ? response : null;
    });
    this.activeCartService.getActiveCartId().subscribe((response) => {
      this.cartId = response ? response : null;
    });
  }

  downloadFactSheetPDF(multiple = false) {
    const HTTPOptions = {
      headers: new HttpHeaders({ Accept: 'application/pdf; charset=UTF-8' }),
      observe: 'response' as 'body', // to display the full response & as 'body' for type cast
      responseType: 'blob' as 'json',
    };

    this.globalMessageService.add({ key: 'knBrMessages.downloadInProgress' }, GlobalMessageType.MSG_TYPE_INFO);
    this.httpClient.get(this.endPointService.buildUrl(SLOAN_FACT_SHEET_PDF(this.customerId)), HTTPOptions).subscribe(
      (response: HttpResponse<any>) => {
        let fileName: string;
        if (response.headers) {
          fileName = this.getFileNameFromResponse(response);
        }
        if (!fileName) {
          fileName = 'FactSheet.pdf';
        }
        this.fileSaver.save(response.body as any, fileName);
        this.globalMessageService.add(
          { key: 'knBrMessages.downloadIsCompleted' },
          GlobalMessageType.MSG_TYPE_CONFIRMATION
        );
      },
      (error) => {
        this.globalMessageService.add({ key: 'knBrMessages.downloadIsFailed' }, GlobalMessageType.MSG_TYPE_ERROR);
      }
    );
  }

  getFileNameFromResponse(response: HttpResponse<any>) {
    const contentDisposition = response.headers.get('Content-Disposition');
    if (contentDisposition) {
      const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = fileNameRegex.exec(contentDisposition);
      if (matches != null && matches[1]) {
        return matches[1].replace(/['"]/g, '');
      }
    }
  }

  downloadInvoiceHeaderPDF() {
    const HTTPOptions = {
      headers: new HttpHeaders({ Accept: 'application/json; charset=UTF-8' }),
      observe: 'response' as 'body', // to display the full response & as 'body' for type cast
      responseType: 'blob' as 'json',
    };
    this.globalMessageService.add({ key: 'knBrMessages.downloadInProgress' }, GlobalMessageType.MSG_TYPE_INFO);
    this.httpClient
      .get(this.endPointService.buildUrl(SLOAN_INVOICE_HEADER_PDF(this.customerId)), HTTPOptions)
      .subscribe(
        (response: HttpResponse<any>) => {
          let fileName: string;
          if (response.headers) {
            fileName = this.getFileNameFromResponse(response);
          }
          if (!fileName) {
            fileName = 'Invoice-' + this.customerId + '.pdf';
          }
          this.fileSaver.save(response.body as any, fileName);
          this.globalMessageService.add(
            { key: 'knBrMessages.downloadIsCompleted' },
            GlobalMessageType.MSG_TYPE_CONFIRMATION
          );
        },
        (error) => {
          this.globalMessageService.add({ key: 'knBrMessages.downloadIsFailed' }, GlobalMessageType.MSG_TYPE_ERROR);
        }
      );
  }

  downloadSAPDocumentPDF(documentNumber: string) {
    const reqParams = {
      documentNumber,
      isForOrderOrReturn: true,
    };

    this.globalMessageService.add({ key: 'knBrMessages.downloadInProgress' }, GlobalMessageType.MSG_TYPE_INFO);
    const HTTPOptions = {
      headers: new HttpHeaders({ Accept: 'application/json; charset=UTF-8' }),
      observe: 'response' as 'body', // to display the full response & as 'body' for type cast
      responseType: 'blob' as 'json',
    };
    this.httpClient
      .get(
        this.endPointService.buildUrl(SLOAN_DOCUMENT_PDF(this.customerId), { queryParams: { ...reqParams } }),
        HTTPOptions
      )
      .subscribe(
        (response: HttpResponse<any>) => {
          let fileName: string;
          if (response.headers) {
            fileName = this.getFileNameFromResponse(response);
          }
          if (!fileName) {
            fileName = documentNumber + '.pdf';
          }
          this.fileSaver.save(response.body as any, fileName);
          this.globalMessageService.add(
            { key: 'knBrMessages.downloadIsCompleted' },
            GlobalMessageType.MSG_TYPE_CONFIRMATION
          );
        },
        (error) => {
          this.globalMessageService.add({ key: 'knBrMessages.downloadIsFailed' }, GlobalMessageType.MSG_TYPE_ERROR);
        }
      );
  }

  downloadInvoiceItemPDF(documentNumber: string) {
    const reqParams = {
      documentNumber,
      isForOrderOrReturn: false,
    };

    this.globalMessageService.add({ key: 'knBrMessages.downloadInProgress' }, GlobalMessageType.MSG_TYPE_INFO);
    const HTTPOptions = {
      headers: new HttpHeaders({ Accept: 'application/json; charset=UTF-8' }),
      observe: 'response' as 'body', // to display the full response & as 'body' for type cast
      responseType: 'blob' as 'json',
    };
    this.httpClient
      .get(
        this.endPointService.buildUrl(SLOAN_DOCUMENT_PDF(this.customerId), { queryParams: { ...reqParams } }),
        HTTPOptions
      )
      .subscribe(
        (response: HttpResponse<any>) => {
          let fileName: string;
          if (response.headers) {
            fileName = this.getFileNameFromResponse(response);
          }
          if (!fileName) {
            fileName = documentNumber + '.pdf';
          }
          this.fileSaver.save(response.body as any, fileName);
          this.globalMessageService.add(
            { key: 'knBrMessages.downloadIsCompleted' },
            GlobalMessageType.MSG_TYPE_CONFIRMATION
          );
        },
        (error) => {
          this.globalMessageService.add({ key: 'knBrMessages.downloadIsFailed' }, GlobalMessageType.MSG_TYPE_ERROR);
        }
      );
  }

  loadOrderTypeList$(): Observable<Statuses[]> {
    return this.orderTypeList$.asObservable();
  }

  getOrderTypeList(customerId: string) {
    this.httpClient.get(this.endPointService.buildUrl(SLOAN_ORDER_TYPES(customerId))).subscribe((response: any) => {
      this.orderTypeList$.next(response as Statuses[]);
    });
  }

  loadCreditStatusList$(): Observable<Statuses[]> {
    return this.creditStatusList$.asObservable();
  }

  getCreditStatusList(customerId: string) {
    this.httpClient.get(this.endPointService.buildUrl(SLOAN_CREDIT_STATUSES(customerId))).subscribe((response: any) => {
      this.creditStatusList$.next(response as Statuses[]);
    });
  }

  loadDeliveryBlockList$(): Observable<Statuses[]> {
    return this.deliveryBlockList$.asObservable();
  }

  getDeliveryBlockList(customerId: string) {
    this.httpClient
      .get(this.endPointService.buildUrl(SLOAN_DELIVERY_BLOCK_LIST(customerId)))
      .subscribe((response: any) => {
        this.deliveryBlockList$.next(response as Statuses[]);
      });
  }

  loadSloanHolidayList$(): Observable<PublicHoliday[]> {
    return this.holidayList$.asObservable();
  }

  getSloanHolidayList() {
    this.httpClient.get(this.endPointService.buildUrl(SLOAN_HOLIDAY_LIST)).subscribe((response: any) => {
      this.holidayList$.next(response.publicHoliday as PublicHoliday[]);
    });
  }

  loadQuoteSinkHoleDrillPatterns$(): Observable<Statuses[]> {
    return this.quoteSinkHoleDrillPatterns$.asObservable();
  }

  getQuoteSinkHoleDrillPatterns(entryNumber) {
    this.httpClient
      .get(
        this.endPointService.buildUrl(SLOAN_QUOTE_SINK_HOLE_DRILL_PATTERNS, {
          urlParams: {
            customerId: this.customerId,
            quoteId: this.quoteId,
            entryNumber: entryNumber,
          },
        })
      )
      .subscribe((response: any) => {
        this.quoteSinkHoleDrillPatterns$.next(response as Statuses[]);
      });
  }

  loadDraftOrderSinkHoleDrillPatterns$(): Observable<Statuses[]> {
    return this.draftOrderSinkHoleDrillPatterns$.asObservable();
  }

  getDraftOrderSinkHoleDrillPatterns(entryNumber) {
    this.httpClient
      .get(
        this.endPointService.buildUrl(SLOAN_DRAFT_ORDER_SINK_HOLE_DRILL_PATTERNS, {
          urlParams: {
            customerId: this.customerId,
            cartId: this.cartId,
            entryNumber: entryNumber,
          },
        })
      )
      .subscribe((response: any) => {
        this.draftOrderSinkHoleDrillPatterns$.next(response as Statuses[]);
      });
  }

  getOrderActionsList(orderCode: string, status: string) {
    this.httpClient
      .get(
        this.endPointService.buildUrl(KNBR_ORDER_ACTIONS, {
          urlParams: {
            customerId: this.customerId,
            code: orderCode,
          },
          queryParams: { statusCode: status },
        })
      )
      .subscribe((response: any) => {
        this.orderActionsList$.next(response as string[]);
      });
  }

  loadOrderActionsList$(): Observable<string[]> {
    return this.orderActionsList$.asObservable();
  }

  getPaymetricsIframeAccessTokenUrl() {
    return this.httpClient
      .get(
        this.endPointService.buildUrl(PAYMETRICS_ACCESS_TOKEN, {
          queryParams: {
            store: environment.PAYMETRICS_STORE,
            hostUri: this.winRef.location.origin,
          },
        })
      )
      .subscribe((response: any) => {
        this.paymetricsDetail$.next(response);
      });
  }

  loadPaymetricsDetail$(): Observable<any> {
    return this.paymetricsDetail$.asObservable();
  }

  checkPaymetricResponse(accessToken) {
    const reqParams = {
      accessToken,
    };
    return this.httpClient.post(
      this.endPointService.buildUrl(PAYMETRICS_RESPONSE_STATUS, {
        urlParams: {
          customerId: this.customerId,
          cartId: this.cartId,
        },
        queryParams: { ...reqParams },
      }),
      null
    );
  }
  loadQuoteValidityDate$(): Observable<any> {
    return this.quoteValidityDate$.asObservable();
  }

  getQuoteValidityDate() {
    this.httpClient.get(this.endPointService.buildUrl(SLOAN_QUOTE_VALIDATY_DATE)).subscribe((response: any) => {
      this.quoteValidityDate$.next(response.response as any);
    });
  }

  getDeliveryModeDetails(code?: string): any {
    return this.httpClient
      .get(
        this.endPointService.buildUrl(SLOAN_DELIVERYMODE, {
          urlParams: { customerId: this.customerId, code: code },
        })
      )
      .subscribe((response: any) => {
        this.deliveryModeData$.next(response.deliveryModes as any);
      });
  }

  loadDeliveryModeDetails() {
    return this.deliveryModeData$.asObservable();
  }
}
