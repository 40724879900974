import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SloanQuoteCreateComponent } from './sloan-quote-create.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { I18nModule, UrlModule, ConfigModule, CmsConfig } from '@spartacus/core';
import { FormErrorsModule } from '@spartacus/storefront';
import { KnBrCustomerContextGuard } from 'src/app/shared/guards/kn-br-customer-context.guard';
import { KnBrDirectiveModule } from 'src/app/shared/directive/kn-br-directive.module';

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    UrlModule,
    FormErrorsModule,
    KnBrDirectiveModule,
    ConfigModule.withConfig({
      cmsComponents: {
        KnBrQuoteCreateComponent: {
          component: SloanQuoteCreateComponent,
          guards: [KnBrCustomerContextGuard],
        },
      },
    } as CmsConfig),
  ],
  declarations: [SloanQuoteCreateComponent],
})
export class SloanQuoteCreateModule {}
