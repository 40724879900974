import { OccConfig } from '@spartacus/core';
import { environment } from 'src/environments/environment';
export const defaultKnBrOccAsmConfig: OccConfig = {
  backend: {
    occ: {
      endpoints: {
        asmCustomerSearch: environment.PREFIX + environment.BASE_SITE[0] + '/oneSloan/customers/search',
      },
    },
  },
};
