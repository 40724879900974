import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CmsConfig, ConfigModule, I18nConfig, I18nModule, provideConfig, UrlModule } from '@spartacus/core';
import { FormErrorsModule, IconModule } from '@spartacus/storefront';
import { LoginFormComponentService } from '@spartacus/user/account/components';

import { KnBrLoginFormComponent } from './kn-br-login-form.component';

@NgModule({
    declarations: [KnBrLoginFormComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        UrlModule,
        I18nModule,
        FormErrorsModule,
        ConfigModule.withConfig({
            cmsComponents: {
                KnBrLoginFormComponent: {
                    component: () => import('./kn-br-login-form.component').then((c) => c.KnBrLoginFormComponent),
                },
            },
        } as CmsConfig),
        IconModule
    ],
    exports: [KnBrLoginFormComponent],
    providers: [
        LoginFormComponentService,
    ]
})
export class KnBrLoginFormModule { }
