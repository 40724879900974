<div
  class="
    bg-white
    customersearch-list-titles
    border-top
    float-left
    w-100
    p-3
    font-weight-normal
    d-flex
    align-items-center
    product-list-content
  "
  (click)="goToDetail($event)"
  tabindex="-1"
>
  <div class="col-md-4 float-left d-flex align-items-center p-0 product-list-img">
    <div
      class="remember-me custom-control float-left d-flex justify-content-start cursor-pointer mr-3 pl-0"
      *ngIf="editable"
    >
      <div class="form-check custom-checkbox mb-0">
        <input
          type="checkbox"
          [id]="product.code"
          class="custom-control-input rounded cursor-pointer"
          [checked]="isChecked(product.code)"
          (change)="changeCheckBox($event)"
        />
        <label class="custom-control-label color-font" [for]="product.code" (click)="$event.stopPropagation()"> </label>
      </div>
    </div>
    <cx-media [container]="product.images?.PRIMARY" [format]="'thumbnail'" [alt]="product.summary"></cx-media>
    <div class="col-lg-8 float-left" [innerHtml]="product.nameHtml"></div>
  </div>
  <div class="col-md-1 text-center float-left title-wrap">{{ product.code }}</div>
  <div class="col-md-2 text-center float-left title-wrap">{{ product.price?.formatedNetPrice }}</div>
  <div class="col-md-2 text-center float-left title-wrap">{{ product.price?.formattedValue }}</div>
  <div class="col-md-1 text-center float-left title-wrap">{{ product?.uom }}</div>
  <div class="col-md-1 text-center float-left title-wrap">{{ product?.minOrderQuantity }}</div>
  <div class="col-md-1 text-center float-left p-res-0 p-0">
    <kn-br-add-to-cart
      class="mr-4 cursor-pointer"
      [type]="'PLP'"
      [productCode]="product.code"
      (click)="$event.stopPropagation()"
    ></kn-br-add-to-cart>

    <kn-br-add-to-quote
      class="cursor-pointer"
      [type]="'PLP'"
      [productCode]="product.code"
      (click)="$event.stopPropagation()"
    ></kn-br-add-to-quote>
  </div>
</div>
