// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  BASEURL: 'https://api.sloan.com',
  PREFIX: '/occ/v2/',
  BASE_SITE: ['oneSloan-customer', 'brava-sales', 'powertools', 'powertools-spa'],
  ORIGIN: '*.sloan.com:443',
  STRIKE_IRON_USER_ID: '4ACBEDED6F18BA5F7D08',
  STRIKE_IRON_PASSWORD: '',
  AUTHENTICATION_CLIENT_ID: 'bravaCustomer_client',
  gtmId: 'GTM-PWPR6KZ9',  
  PAYMETRICS_STORE: 'oneSloan',
  recaptchaSiteKey: '6LcKCgspAAAAAC6pu8xR-mQYgwueKUflsok3SIhX',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
