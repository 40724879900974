import { RoutingService, GlobalMessageService, GlobalMessageType } from '@spartacus/core';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { OrderDetailsService } from '@spartacus/order/components';
import { Observable, Subscription } from 'rxjs';
import { KnBrOrder } from 'src/feature-libs/kn-br-order/root/models/kn-br-order.model';
import { KnBrOrderHistoryService } from 'src/feature-libs/kn-br-order/core/facade/kn-br-order-history.service';
import { KnBrCancelDialogComponent } from '../kn-br-cancel-dialog/kn-br-cancel-dialog.component';
// import { ModalService } from '@spartacus/storefront';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'knbr-kn-br-order-details-actions',
  templateUrl: './kn-br-order-details-actions.component.html',
  styleUrls: ['./kn-br-order-details-actions.component.scss'],
})
export class KnBrOrderDetailsActionsComponent implements OnInit, OnDestroy {
  order$: Observable<any>;
  cancelOrderModalRef;
  orderActions$: Observable<string[]> = this.knBrCommonService.loadOrderActionsList$();
  orderInfo: any;
  subscription = new Subscription();
  isEditable = false;
  editDisable$: Observable<boolean> = this.knBrOrderHistoryService.editDisable$;
  constructor(
    protected orderDetailsService: OrderDetailsService,
    protected knBrOrderHistoryService: KnBrOrderHistoryService,
    protected modalService: NgbModal,
    protected knBrCommonService: KnBrCommonService,
    protected routingService: RoutingService,
    protected messageService: GlobalMessageService
  ) { }

  ngOnInit(): void {
    this.order$ = this.orderDetailsService.getOrderDetails();
    this.subscription.add(
      this.order$.subscribe((res) => {
        this.orderInfo = res;
        if (res.code) {
          this.knBrCommonService.getOrderActionsList(res?.code);
        }
      })
    );
    this.subscription.add(
      this.knBrOrderHistoryService.isEditOrderable$().subscribe((res) => {
        this.isEditable = res;
      })
    );
  }

  reOrder(order: KnBrOrder) {
    const payload = {
      orderCode: order?.code,
    };
    this.knBrOrderHistoryService.reorder(payload);
  }

  openCancelOrderModal() {
    this.cancelOrderModalRef = this.modalService.open(KnBrCancelDialogComponent);
    this.cancelOrderModalRef.result.then((result) => {
      if (result) {
        let orderCode = '';
        this.order$.subscribe((res) => {
          orderCode = res?.code;
        });
        const paramsJson = {
          reason: result,
          orderCode,
        };
        this.knBrOrderHistoryService.cancelOrder(paramsJson);
      }
    });
  }

  returnOrder(type) {
    if (this.orderInfo && this.orderInfo.consignments && this.orderInfo.consignments.length > 0) {
      this.routingService.go({
        cxRoute: 'orderReturn',
        params: { code: this.orderInfo.code, returnType: type },
      });
    } else {
      this.messageService.add(
        { key: 'knbrOrderDetails.returnOrder.navigationWarning' },
        GlobalMessageType.MSG_TYPE_WARNING
      );
    }
  }

  editOrder() {
    this.isEditable = true;
    this.knBrOrderHistoryService.setOrderEditable(true);
    this.knBrOrderHistoryService.setSaveEditOrderRequest(false);
  }

  cancelEditOrder() {
    this.isEditable = false;
    this.knBrOrderHistoryService.setOrderEditable(false);
    this.knBrOrderHistoryService.setSaveEditOrderRequest(false);
  }

  saveEditOrder() {
    this.knBrOrderHistoryService.setSaveEditOrderRequest(true);
    this.isEditable = false;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
