import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  NgZone,
  OnDestroy,
  OnInit,
  ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { CheckoutPaymentMethodComponent, CheckoutStepService } from '@spartacus/checkout/base/components';
import { CheckoutDeliveryAddressFacade, CheckoutPaymentFacade } from '@spartacus/checkout/base/root';
import { GlobalMessageService, GlobalMessageType, TranslationService, UserPaymentService } from '@spartacus/core';
import { LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { SloanCommonService } from 'src/app/services/sloan/sloan-common.service';

declare let $XIFrame: any;

@Component({
  selector: 'cx-payment-method',
  templateUrl: './sloan-payment-method.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SloanPaymentMethodComponent extends CheckoutPaymentMethodComponent implements OnInit, OnDestroy {
  paymetricsDetail$: Observable<any> = this.sloanCommonService.loadPaymetricsDetail$();
  showLoading = false;
  showSpinner: void | Observable<ComponentRef<any>>;

  constructor(
    protected userPaymentService: UserPaymentService,
    protected checkoutDeliveryAddressFacade: CheckoutDeliveryAddressFacade,
    protected checkoutPaymentFacade: CheckoutPaymentFacade,
    protected activatedRoute: ActivatedRoute,
    protected translationService: TranslationService,
    protected activeCartFacade: ActiveCartFacade,
    protected checkoutStepService: CheckoutStepService,
    protected globalMessageService: GlobalMessageService,
    protected sloanCommonService: SloanCommonService,
    protected launchDialogService: LaunchDialogService,
    protected zone: NgZone,
    protected vcr: ViewContainerRef,
    private cdr: ChangeDetectorRef,
    private location: Location
  ) {
    super(
      userPaymentService,
      checkoutDeliveryAddressFacade,
      checkoutPaymentFacade,
      activatedRoute,
      translationService,
      activeCartFacade,
      checkoutStepService,
      globalMessageService
    );
  }

  ngOnInit() {
    // super.ngOnInit();
    this.showLoading = true;
    this.sloanCommonService.getPaymetricsIframeAccessTokenUrl();
  }

  iFrame_OnLoad() {
    this.showLoading = false;
    const iframe = document.getElementsByName('dieCommFrame');
    if (iframe) {
      $XIFrame.onload({
        iFrameId: 'dieCommFrame',
        targetUrl: iframe[0].getAttribute('src'),
        autosizewidth: false,
        autosizeheight: false,
        onSuccess(msg: any) {
          console.log(msg);
          console.log(
            'A form for the merchant guid and access token combination is loading in the iFrame successfully.'
          );
        },
        onError(msg: any) {
          console.log(msg);
          console.log('A form for the merchant guid and access token combination has FAILED to load.');
        },
      });
    }
  }

  submitform(accessToken) {
    // this.showLoading = true;
    const self = this;
    const iframe = document.getElementsByName('dieCommFrame');
    if (iframe) {
      $XIFrame.submit({
        iFrameId: 'dieCommFrame',
        targetUrl: iframe[0].getAttribute('src'),
        onSuccess(msg: any) {
          self.onSpinnerLoading(true);
          console.log(msg);
          const message = JSON.parse(msg);
          if (message && message.data.HasPassed) {
            self.sloanCommonService.checkPaymetricResponse(accessToken).subscribe(
              (res) => {
                self.goToNext();
              },
              (error: any) => {
                console.log(error);
                this.showLoading = false;
                const errorResponse = error.error;
                if (errorResponse && errorResponse.errors && errorResponse.errors.length > 0) {
                  self.globalMessageService.add(
                    { raw: errorResponse.errors[0].message },
                    GlobalMessageType.MSG_TYPE_ERROR
                  );
                }
                self.onSpinnerLoading(false);
                self.ngOnInit();
              }
            );
          } else {
            this.showLoading = false;
            self.globalMessageService.add({ raw: message.data.Message }, GlobalMessageType.MSG_TYPE_ERROR);
            console.log(message.data.Message);
            self.onSpinnerLoading(false);
          }
        },
        onError(msg: any) {
          console.log(msg);
          const message = JSON.parse(msg);
          self.globalMessageService.add({ raw: message.data.Message }, GlobalMessageType.MSG_TYPE_ERROR);
          self.onSpinnerLoading(false);
        },
      });
    }
    return false;
  }
  goToNext() {
    this.next();
    this.location.replaceState('/checkout/review-order');
    this.showLoading = false;
    this.cdr.markForCheck();
    // window = window;
    this.onSpinnerLoading(false);
    window.top.location = window.top.location;
    // this.location.forward();
    // this.location.go('/checkout/review-order');
    // window.location.assign('/checkout/review-order');
  }

  onSpinnerLoading(isLoading) {
    if (isLoading) {
      this.showSpinner = this.launchDialogService.launch(LAUNCH_CALLER.PLACE_ORDER_SPINNER, this.vcr);
    } else {
      if (this.showSpinner) {
        this.showSpinner
          .subscribe((component) => {
            this.launchDialogService.clear(LAUNCH_CALLER.PLACE_ORDER_SPINNER);
            component.destroy();
          })
          .unsubscribe();
      }
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.showLoading = false;
    this.launchDialogService.clear(LAUNCH_CALLER.PLACE_ORDER_SPINNER);
  }
}
