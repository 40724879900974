import { Component, OnInit } from '@angular/core';
import { RoutingService } from '@spartacus/core';
import { OrderReturnRequestService } from '@spartacus/order/core';

@Component({
  selector: 'knbr-kn-br-return-order-details-items',
  templateUrl: './kn-br-return-order-details-items.component.html',
  styleUrls: ['./kn-br-return-order-details-items.component.scss'],
})
export class KnBrReturnOrderDetailsItemsComponent implements OnInit {
  orderReturnData$ = this.returnRequestService.getOrderReturnRequest();

  constructor(protected returnRequestService: OrderReturnRequestService, protected routingService: RoutingService) {}

  ngOnInit(): void {}

  goBack() {
    this.routingService.go({ cxRoute: 'returnOrders' });
  }
}
