import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { KnBrFactSheetEffects } from '../../../feature-libs/kn-br-order/core/store/effects/kn-br-fact-sheet.effect';
import { KnBrFactSheetReducer } from '../../../feature-libs/kn-br-order/core/store/reducers/kn-br-fact-sheet.reducer';
import { KNBR_FACT_SHEET_FEATURE } from './kn-br-fact-sheet.state';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(KNBR_FACT_SHEET_FEATURE, KnBrFactSheetReducer),
    EffectsModule.forFeature([KnBrFactSheetEffects]),
  ],
})
export class KnBrFactSheetStateModule { }
