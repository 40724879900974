import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AsmComponentService } from '@spartacus/asm/components';
import { AsmConfig } from '@spartacus/asm/root';
import { RoutingService, UserIdService } from '@spartacus/core';
import { KnBrAsmSessionTimerComponent } from 'src/feature-libs/kn-br-asm/components/kn-br-asm-session-timer/kn-br-asm-session-timer.component';

@Component({
  selector: 'cx-asm-session-timer',
  templateUrl: './sloan-asm-session-timer.component.html',
  styleUrls: ['./sloan-asm-session-timer.component.scss'],
})
export class SloanAsmSessionTimerComponent extends KnBrAsmSessionTimerComponent implements OnInit {
  constructor(
    protected config: AsmConfig,
    protected asmComponentService: AsmComponentService,
    protected routingService: RoutingService,
    protected changeDetectorRef: ChangeDetectorRef,
    protected userIdService: UserIdService
  ) {
    super(config, asmComponentService, routingService, changeDetectorRef, userIdService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
