<div class="customersearch-list-table border-bottom mt-3 float-left col-lg-12 p-0">
  <div class="customersearch-list-titles border-top float-left w-100 p-3 d-none d-sm-none d-lg-block">
    <div class="col-md-3 float-left font-text-600 font-sm-size">
      {{ 'knbrOrderDetails.addProducts.product' | cxTranslate }}
    </div>
    <div class="col-md-2 float-left font-text-600 font-sm-size">
      {{ 'knbrOrderDetails.addProducts.itemDetails' | cxTranslate }}
    </div>
    <div class="col-md-2 float-left font-text-600 font-sm-size">
      {{ 'knbrOrderDetails.addProducts.listPrice' | cxTranslate }}
    </div>
    <div class="col-md-2 float-left font-text-600 font-sm-size">
      {{ 'knbrOrderDetails.addProducts.qty' | cxTranslate }}
    </div>
    <div class="col-md-3 float-left font-text-600 font-sm-size">
      {{ 'knbrOrderDetails.addProducts.total' | cxTranslate }}
    </div>
  </div>
</div>

<form [formGroup]="addProductsForm">
  <ng-container *ngFor="let product of t.controls; let i = index">
    <div
      [formGroup]="product"
      class="row border-bottom mt-3 float-left col-md-12 p-0"
      *ngIf="!product.controls.showForm.value"
    >
      <div class="col-md-11">
        <input
          type="text"
          placeholder="{{ 'knbrOrderDetails.addProducts.skuplaceholder' | cxTranslate }}"
          formControlName="productCode"
          (focus)="onEnterInput($event, i)"
          (blur)="searchForProduct(i)"
          (keyup.enter)="searchForProduct(i)"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && addProductsForm.controls.productCode.errors }"
        />
        <span *ngIf="product.controls.showErrorMsg.value" class="text-danger">{{
          product.controls.errorMsg.value
        }}</span>
      </div>
      <div class="col-md-1 float-left title-wrap font-size color-font">
        <button
          *ngIf="!(i == 0)"
          type="button"
          (click)="removeProduct(i)"
          class="btn reset pl-4 pr-4 mr-3 pb-1 pt-1 font-lg-size mr-left-auto"
        >
          <i class="fas fa-times-circle" aria-hidden="true"></i>
        </button>
      </div>
    </div>
    <div [formGroup]="product" *ngIf="product.controls.showForm.value">
      <div class="row col-md-12 bg-white border-top float-left w-100 p-3 d-flex align-items-center">
        <div class="form-group col-md-3">
          <input
            type="text"
            (blur)="searchForProduct(i)"
            formControlName="productCode"
            class="form-control"
            [ngClass]="{ 'is-invalid': submitted && product.controls.productCode.errors }"
          />
        </div>
        <div class="col-md-2 title-wrap padding-res-0 float-left">
          <span>{{ product.controls.image.value.name }}</span>
          <cx-media [container]="product.controls.image.value.images" format="product"></cx-media>
          <span>{{ product.controls.image.value.code }}</span>
          <!-- <cx-media [container]=" ticket.controls.image.value?.PRIMARY" format="product"></cx-media> -->
        </div>
        <div class="col-md-2 float-left title-wrap font-size color-font">
          <span class="res-titles"> {{ 'knbrOrderDetails.addProducts.qty' | cxTranslate }}</span>
          {{ product.controls.listPrice.value }}
        </div>
        <div class="form-group col-md-2">
          <input
            type="text"
            formControlName="qty"
            class="form-control"
            knBrNumbersOnly
            (change)="onQuantityChange(i)"
            [ngClass]="{ 'is-invalid': submitted && product.controls.qty.errors }"
          />
          <span>
            {{ 'knbrOrderDetails.addProducts.minorderQty' | cxTranslate }}
            {{ product.controls.image.value.minOrderQuantity }}</span
          >
        </div>
        <div class="col-md-2 text-center float-left title-wrap font-size color-font">
          <span class="res-titles"> {{ 'knbrOrderDetails.addProducts.total' | cxTranslate }}</span>
          {{ product.controls.total.value * product.controls.qty.value | number : '0.2-2' }}
        </div>
        <div class="col-md-1 float-left title-wrap font-size color-font">
          <button
            *ngIf="!(i == 0)"
            type="button"
            class="btn reset pl-4 pr-4 mr-3 pb-1 pt-1 font-lg-size mr-left-auto"
            (click)="removeProduct(i)"
          >
            <i class="fas fa-times-circle" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</form>
