<section class="col-md-12 p-0 login-wrapper d-flex justify-content-center align-items-center">
  <div class="container fluid p-0">
    <div class="col-md-12 float-left knbr-login-forms mt-4 d-flex justify-content-center p-0">
      <div class="alert-info p-3 rounded-0 mt-3" *ngIf="successMessage$ | async as successMessage">
        {{ successMessage?.response }}
      </div>

      <div
        class="alert-danger p-3 rounded-0 mt-3 col-lg-5 text-center"
        *ngIf="failureMessage$ | async as failureMessage"
      >
        <i class="fas fa-exclamation-circle" aria-hidden="true"></i>
        {{ 'knBrMessages.approverFailure' | cxTranslate }}
      </div>
    </div>
  </div>
</section>
