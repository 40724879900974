import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { KnBrCSAgentLoginFormComponent } from 'src/feature-libs/kn-br-asm/components/kn-br-csagent-login-form/kn-br-csagent-login-form.component';

@Component({
  selector: 'cx-csagent-login-form',
  templateUrl: './sloan-cs-agent-login-form.component.html',
  styleUrls: ['./sloan-cs-agent-login-form.component.scss'],
})
export class SloanCsAgentLoginFormComponent extends KnBrCSAgentLoginFormComponent implements OnInit {
  constructor(protected fb: FormBuilder) {
    super(fb);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
}
