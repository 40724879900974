import { NgModule } from '@angular/core';
import { CmsConfig, I18nConfig, provideConfig } from '@spartacus/core';
import { OrderModule } from '@spartacus/order';
import {
  orderTranslationChunksConfig,
  orderTranslations,
} from 'src/feature-libs/kn-br-order/assets/translations/translation';
import { ORDER_FEATURE } from 'src/feature-libs/kn-br-order/root/feature-name';
import { OrderRootModule } from 'src/feature-libs/kn-br-order/root/order-root.module';
@NgModule({
  imports: [OrderRootModule, OrderModule],
  providers: [
    provideConfig({
      featureModules: {
        [ORDER_FEATURE]: {
          module: () =>
            import('src/feature-libs/kn-br-order/kn-br-order.module').then((m) => m.KnBrOrderModule),
        },
      },
    }),
    provideConfig(<I18nConfig>{
      i18n: {
        resources: orderTranslations,
        chunks: orderTranslationChunksConfig,
        fallbackLang: 'en',
      },
    }),
  ],
})
export class OrdersFeatureModule { }
