/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { MessageService, BaseItem, ItemService } from '@spartacus/organization/administration/components';
import { ICON_TYPE, ViewComponent } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'cx-org-card',
  templateUrl: './card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'content-wrapper' },
  providers: [MessageService],
})
export class SloanOrganizationCardComponent<T extends BaseItem> {
  @Input() i18nRoot: string;
  @Input() previous: boolean | string = true;
  @Input() subtitle?: string;
  @Input() showHint? = false;
  item: any;
  protected itemKey: string | undefined;

  iconTypes = ICON_TYPE;

  item$: Observable<T | undefined> = this.itemService.current$.pipe(tap((item) => this.refreshMessages(item)));

  @ViewChild(ViewComponent, { read: ViewComponent }) view: ViewComponent;

  constructor(protected itemService: ItemService<T>, protected messageService: MessageService) {
    this.item$.subscribe((res) => {
      this.item = res;
    });
  }

  /**
   * The views are router based, which means if we close a view, the router outlet is
   * cleaned immediately. To prevent this, we're closing the view manually, before
   * navigating back.
   */
  closeView(event: MouseEvent) {
    event.stopPropagation();
    this.view.toggle(true);

    setTimeout(() => {
      (event.target as HTMLElement)?.parentElement?.click();
    }, 500);

    return false;
  }

  get previousLabel(): string {
    return this.previous as string;
  }

  protected refreshMessages(item: T | undefined) {
    if (
      this.itemKey !== undefined &&
      item?.code !== this.itemKey &&
      item?.uid !== this.itemKey &&
      item?.customerId !== this.itemKey
    ) {
      this.messageService.clear();
    }
    this.itemKey = item?.code ?? item?.uid ?? item?.customerId;
  }
}
