import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthGuard, CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { KnBrCustomPipesModule } from 'src/app/shared/pipe/kn-br-custom-pipes/kn-br-custom-pipes.module';
import { SloanOrderAcknowledgeComponent } from './sloan-order-acknowledge.component';

@NgModule({
  declarations: [SloanOrderAcknowledgeComponent],
  imports: [
    CommonModule,
    I18nModule,
    RouterModule,
    UrlModule,
    KnBrCustomPipesModule,
    ConfigModule.withConfig({
      cmsComponents: {
        KnBrOrderAcknowledgeComponent: {
          component: SloanOrderAcknowledgeComponent,
          guards: [AuthGuard],
        },
      },
    } as CmsConfig),
  ],
})
export class SloanOrderAcknowledgeModule {}
