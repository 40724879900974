<h3 class="cx-checkout-title d-none d-lg-block d-xl-block">
  {{ 'paymentForm.payment' | cxTranslate }}
</h3>

<ng-container *ngIf="paymetricsDetail$ | async as paymetricsDetail">
  <div *ngIf="showLoading" class="cx-spinner"><cx-spinner></cx-spinner></div>
  <div *ngIf="paymetricsDetail && paymetricsDetail.paymetricsIframeAccessTokenUrl">
    <div id="iframewrapper">
      <iframe
        id="paymetricIFrame"
        width="100%"
        height="300px"
        [src]="paymetricsDetail.paymetricsIframeAccessTokenUrl | safe: 'resourceUrl'"
        name="dieCommFrame"
        (load)="iFrame_OnLoad()"
      ></iframe>
    </div>
  </div>
  <div id="Status"></div>
  <div id="DynamicIFrame"></div>

  <div class="row cx-checkout-btns" *ngIf="!showLoading">
    <div class="col-md-12 col-lg-6">
      <button class="btn btn-block btn-action" (click)="back()">
        {{ backBtnText | cxTranslate }}
      </button>
    </div>
    <div class="col-md-12 col-lg-6" *ngIf="paymetricsDetail && paymetricsDetail.paymetricsIframeToken">
      <button class="btn btn-block btn-primary" (click)="submitform(paymetricsDetail.paymetricsIframeToken)">
        {{ 'common.continue' | cxTranslate }}
      </button>
    </div>
  </div>
</ng-container>
