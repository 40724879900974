<ng-container *ngIf="isCustomerEmulationSessionInProgress$ | async; else realCustomerSession">
  <input
    formcontrolname="customer"
    type="text"
    disabled="true"
    placeholder="{{ customer?.name }}, {{ customer?.uid.includes('|') ? customer?.uid.split('|')[1] : customer?.uid }}"
  />
  <button (click)="logoutCustomer()">
    {{ 'asm.endSession' | cxTranslate }}
  </button>
</ng-container>

<ng-template #realCustomerSession>
  <div class="asm-alert" role="alert">
    {{ 'asm.standardSessionInProgress' | cxTranslate }}
  </div>
</ng-template>
