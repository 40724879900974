import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  AuthActions,
  GlobalMessageActions,
  GlobalMessageService,
  GlobalMessageType,
} from '@spartacus/core';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { KnBrProductSelectService } from '../../../feature-libs/kn-br-product/core/service/kn-br-product-select.service';
import { KnBrCustomerResetContext } from '../kn-br-customer-context/kn-br-customer-context.actions';
import { KnBrCustomerContextService } from '../kn-br-customer-context/kn-br-customer-context.service';
import { CustomerSearchReset } from '../kn-br-customer/kn-br-customer.action';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { CartActions } from '@spartacus/cart/base/core';
import { KnBrCartRemoveContext, KnBrCartResetContext } from 'src/feature-libs/kn-br-cart/base/core/store/actions/kn-br-cart-context.actions';
import { KnBrDraftOrdersService } from 'src/feature-libs/kn-br-cart/draft-order/core/facade/kn-br-draft-orders.service';
import { KnBrQuoteReferenceService } from 'src/feature-libs/kn-br-cart/quote/core/facade/kn-br-quote-reference.service';
import { KnBrQuoteRemoveContext } from 'src/feature-libs/kn-br-cart/quote/core/store/actions/kn-br-quote-context.actions';

@Injectable()
export class KnBrCartEffects {

  addToCartNotify$: Observable<GlobalMessageActions.AddMessage> =
    createEffect(() => this.actions$.pipe(
      ofType(CartActions.CART_ADD_ENTRY_SUCCESS),
      map((action: CartActions.CartAddEntrySuccess) => action.payload),
      map((product: any) => {
        this.knBrProductSelectService.setSelectedProducts([]);
        const addedEntries = product.knbrCartModificationList;
        if (addedEntries.length > 0) {
          this.knBrQuoteReferenceService.addQuoteFromPDP(product.knbrCartModificationList[0].entryData.entryNumber);
          if (addedEntries && addedEntries[0].statusCode === 'warn') {
            this.messageService.add({ raw: addedEntries[0].statusMessage }, GlobalMessageType.MSG_TYPE_WARNING);
          }
        }
        this.knBrDraftOrdersService.setAddCartEntryLoading(false);
        if (product.errorMessage && product.errorMessage.length > 0) {
          product.errorMessage.forEach((error: any) => {
            if (error.message) {
              this.messageService.add(
                { raw: error.message },
                error.type === 'ERROR' ? GlobalMessageType.MSG_TYPE_ERROR : GlobalMessageType.MSG_TYPE_WARNING
              );
            }
          });
        }

        return new GlobalMessageActions.AddMessage({
          text: { key: addedEntries.length > 0 ? 'addToCartMessage.success' : 'addToCartMessage.failure' },
          type: addedEntries.length > 0 ? GlobalMessageType.MSG_TYPE_CONFIRMATION : GlobalMessageType.MSG_TYPE_ERROR,
        });
      })
    )
    );

  addToCartFailureNotify$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(CartActions.CART_ADD_ENTRY_FAIL), // listen for successful Add To Draft Order
    map((action: CartActions.CartAddEntryFail) => action.payload.error), // get product from action payload
    map((error: any) => {
      this.knBrDraftOrdersService.setAddCartEntryLoading(false);
      const errors = (error?.details || []) as Array<any>;
      if (errors.length > 0) {
        errors.forEach((err) => {
          const type = err.type.toLowerCase();
          if (type.indexOf('warning') !== -1) {
            this.messageService.add({ raw: err.message }, GlobalMessageType.MSG_TYPE_WARNING);
          } else if (type.indexOf('error') !== -1) {
            this.messageService.add({ raw: err.message }, GlobalMessageType.MSG_TYPE_ERROR);
          }
        });
      } else {
        this.messageService.add({ key: 'addToCartMessage.failure' }, GlobalMessageType.MSG_TYPE_ERROR);
      }
    })
  ),
    { dispatch: false }
  );

  removeCartEntryNotify$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(CartActions.CART_REMOVE_ENTRY),
    map((action: any) => action.payload),
    map((data) => this.knBrDraftOrdersService.setAddCartEntryLoading(true))
  ),
    { dispatch: false }
  );

  removeCartEntrySucessNotify$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(CartActions.CART_REMOVE_ENTRY_SUCCESS),
    map((action: any) => action.payload),
    map((data) => this.knBrDraftOrdersService.setAddCartEntryLoading(false))
  ),
    { dispatch: false }
  );

  removeCartEntryFailureNotify$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(CartActions.CART_REMOVE_ENTRY_FAIL),
    map((action: any) => action.payload.error),
    map((error: any) => this.knBrDraftOrdersService.setAddCartEntryLoading(false))
  ),
    { dispatch: false }
  );

  placeOrder$: Observable<any> = createEffect(() => this.actions$.pipe(
    // TODO Implement EventService to listen for the OrderPlacedEvent
    ofType(),
    map((action: any) => action.payload),
    map((orderDetail) => {
      if (orderDetail && orderDetail?.warningMessage) {
        this.messageService.add({ raw: orderDetail?.warningMessage }, GlobalMessageType.MSG_TYPE_WARNING);
      }
      return new KnBrCartRemoveContext();
    })
  )
  );

  placeOrderFail$: Observable<any> = createEffect(() => this.actions$.pipe(
    // TODO Implement EventService to listen for the OrderPlacedEvent
    ofType(),
    map((action: any) => action.payload),
    map((error: any) => {
      const errors = (error.message || error.details || []) as Array<any>;
      if (errors.length > 0) {
        this.messageService.add({ raw: errors[0].message }, GlobalMessageType.MSG_TYPE_ERROR);
      }
    })
  ),
    { dispatch: false }
  );

  login$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.LOGIN),
    map((action: any) => action?.payload),
    mergeMap((token) => {
      this.user$.subscribe((user: any) => {
        if (user && user?.customerId) {
          this.customerContextService.set({
            customerName: user?.customerName,
            customerNumber: user?.customerId,
          });
        }
      });
      return [new KnBrQuoteRemoveContext(), new KnBrCartResetContext(), new CustomerSearchReset(null)];
    })
  )
  );

  logout$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.LOGOUT),
    map((action: any) => action.payload),
    mergeMap((token) => {
      return [
        new KnBrQuoteRemoveContext(),
        new KnBrCartResetContext(),
        new CustomerSearchReset(null),
        new KnBrCustomerResetContext(),
      ];
    })
  )
  );

  user$ = this.userService.get();
  constructor(
    private actions$: Actions,
    private knBrQuoteReferenceService: KnBrQuoteReferenceService,
    private knBrProductSelectService: KnBrProductSelectService,
    private customerContextService: KnBrCustomerContextService,
    private userService: UserAccountFacade,
    private knBrDraftOrdersService: KnBrDraftOrdersService,
    private messageService: GlobalMessageService
  ) { }
}
