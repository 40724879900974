import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SloanCommonService } from 'src/app/services/sloan/sloan-common.service';
import { UserAccountService } from '@spartacus/user/account/core';
import { KnBrDraftOrdersCommentsService } from 'src/feature-libs/kn-br-cart/draft-order/core/facade/kn-br-draft-orders-comments.service';
import { KnBrDraftOrderComments } from 'src/feature-libs/kn-br-cart/draft-order/root/models/kn-br-draft-order.model';
import { UserAccountFacade } from '@spartacus/user/account/root';

@Component({
  selector: 'knbr-kn-br-draft-order-comments-popup',
  templateUrl: './sloan-draft-order-comments-popup.component.html',
  styleUrls: ['./sloan-draft-order-comments-popup.component.scss'],
})
export class SloanDraftOrderCommentsPopupComponent implements OnInit {
  isGeneric: boolean;
  isSinkDrillPattern: boolean;
  messageForm: FormGroup;
  sinkHoleDrillPatternList$ = this.commonService.loadDraftOrderSinkHoleDrillPatterns$();
  comment: any;
  entryNumber: number;
  comments$: Observable<KnBrDraftOrderComments[]> = this.knBrDraftOrdersCommentsService.list$;
  text: string;
  isARCWorkSheet: boolean = false;
  isDrawingNumber: boolean = false;
  isConfigurationID: boolean = false;

  dateFormat$: Observable<any> = this.currentUserService.get().pipe(
    filter((user: any) => user && !!user.dateFormat),
    map((user: any) => user.dateFormat)
  );
  editable: boolean;
  constructor(
    protected currentUserService: UserAccountFacade,
    protected knBrDraftOrdersCommentsService: KnBrDraftOrdersCommentsService,
    protected modalService: NgbModal,
    protected fb: FormBuilder,
    protected commonService: SloanCommonService
  ) { }

  ngOnInit(): void {
    this.knBrDraftOrdersCommentsService.get(this.entryNumber);
    this.commonService.getDraftOrderSinkHoleDrillPatterns(this.entryNumber);
    this.comment = {
      comment: '',
      entryNumber: this.entryNumber,
    };
    this.initForm();
    this.comments$.subscribe((res) => {
      if (res && res.length > 0) {
        res.forEach((element: any) => {
          if (element.archWorkSheet) {
            this.isARCWorkSheet = true;
          }
          if (element.drawingNumber) {
            this.isDrawingNumber = true;
          }
          if (element.configurationId) {
            this.isConfigurationID = true;
          }
        });
      }
      if (res && res.length === 0) {
        this.isARCWorkSheet = false;
        this.isDrawingNumber = false;
        this.isConfigurationID = false;
      }
    });
  }

  initForm() {
    const arcSheetPattern = '^[0-9]{3}[ 0-9A-Za-z#$%=@!{},`~&*()<>?.:;_|^/+\t\r\n"-]{1,}';
    this.messageForm = this.fb.group({
      archWorkSheet: ['', [Validators.pattern(arcSheetPattern)]],
      drawingNumber: ['', [Validators.pattern(arcSheetPattern)]],
      configurationId: [''],
      sinkHoleDrillPattern: ['', this.isSinkDrillPattern ? Validators.required : []],
    });
  }

  isDisabled() {
    let isDisabled = false;
    if (this.isGeneric && this.isSinkDrillPattern) {
      if (
        this.messageForm.controls.archWorkSheet.value === '' &&
        this.messageForm.controls.drawingNumber.value === '' &&
        this.messageForm.controls.configurationId.value === ''
      ) {
        isDisabled = true;
      } else if (this.messageForm.controls.sinkHoleDrillPattern.value === 'CUSTOM' && !this.text?.trim().length) {
        isDisabled = true;
      }
    } else if (this.isGeneric && !this.isSinkDrillPattern) {
      isDisabled =
        this.messageForm.controls.archWorkSheet.value === '' &&
          this.messageForm.controls.drawingNumber.value === '' &&
          this.messageForm.controls.configurationId.value === ''
          ? true
          : false;
    } else if (this.isSinkDrillPattern && !this.isGeneric) {
      isDisabled =
        this.messageForm.controls.sinkHoleDrillPattern.value === 'CUSTOM' && !this.text?.trim().length ? true : false;
    } else if (!this.isSinkDrillPattern && !this.isGeneric) {
      isDisabled = !this.text?.trim().length ? true : false;
    }
    return isDisabled;
  }

  saveComment() {
    const comment = _.cloneDeep(this.comment);
    comment.comment = this.text ? this.text : '';
    comment.entryNumber = this.entryNumber;
    const messageFormvalue = this.messageForm.getRawValue();
    if (this.isGeneric) {
      comment.archWorkSheet = messageFormvalue.archWorkSheet;
      comment.drawingNumber = messageFormvalue.drawingNumber;
      comment.configurationId = messageFormvalue.configurationId;
    }
    if (this.isSinkDrillPattern) {
      comment.sinkHoleDrillPattern = messageFormvalue.sinkHoleDrillPattern;
    }
    if (comment.code) {
      this.knBrDraftOrdersCommentsService.update(comment);
    } else {
      this.knBrDraftOrdersCommentsService.create(comment);
    }
    this.resetComment();
  }
  editComment(comment) {
    this.comment = comment;
    this.text = comment.text;
    if (this.isDrillOrGenericAvaiable()) {
      this.messageForm.patchValue({
        archWorkSheet: comment.archWorkSheet,
        drawingNumber: comment.drawingNumber,
        configurationId: comment.configurationId,
        sinkHoleDrillPattern: comment.sinkHoleDrillPattern,
      });
    }
  }

  resetComment() {
    this.comment = {
      comment: '',
      entryNumber: this.entryNumber,
    };
    this.text = '';
    this.messageForm.reset();
  }

  deleteComment(event, comment) {
    event.stopPropagation();
    if (comment && comment.archWorkSheet) {
      this.isARCWorkSheet = false;
    }
    if (comment && comment.drawingNumber) {
      this.isDrawingNumber = false;
    }
    if (comment && comment.configurationId) {
      this.isConfigurationID = false;
    }
    const commentObj = _.cloneDeep(comment);
    commentObj.entryNumber = this.entryNumber;
    this.knBrDraftOrdersCommentsService.delete(commentObj);
  }

  dismissModal(reason?: any): void {
    this.modalService.dismissAll(reason);
  }

  isDrillOrGenericAvaiable() {
    return this.isGeneric || this.isSinkDrillPattern;
  }
}
