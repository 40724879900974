<ng-container *ngIf="cards$ | async as cards">
  <!-- <h3 class="cx-checkout-title d-none d-lg-block d-xl-block">
    {{ 'checkoutAddress.shippingAddress' | cxTranslate }}
  </h3> -->
  <ng-container *ngIf="!(isUpdating$ | async); else loading">
    <ng-container *ngIf="isAccountPayment || (cards?.length && !addressFormOpened); else newAddressForm">
      <!-- <p class="cx-checkout-text">
        {{ 'checkoutAddress.selectYourShippingAddress' | cxTranslate }}
      </p> -->
      <!-- <div class="cx-checkout-btns row" *ngIf="!isAccountPayment">
        <div class="col-sm-12 col-md-12 col-lg-6">
          <button class="btn btn-block btn-action" (click)="openAddressForm()">
            {{ 'checkoutAddress.addNewAddress' | cxTranslate }}
          </button>
        </div>
      </div> -->

      <div class="cx-checkout-body row pt-3">
        <ng-container *ngIf="deliveryAddress$ | async as deliveryAddress">
          <div class="cx-shipping-address-card col-md-12 col-lg-6" *ngIf="deliveryAddress?.isDisposable">
            <div class="cx-shipping-address-card-inner">
              <cx-address-card
                [border]="true"
                [fitToContainer]="true"
                [content]="getOtshAddress.card"
                (sendCard)="selectAddress(getOtshAddress.address)"
              ></cx-address-card>
            </div>
          </div>
        </ng-container>
        <div
          class="cx-shipping-address-card col-md-12 col-lg-6"
          *ngFor="let card of cards | orderBy : 'card.header' : 'desc'; let i = index"
        >
          <div class="cx-shipping-address-card-inner" (click)="selectAddress(card.address)">
            <cx-address-card
              [border]="true"
              [fitToContainer]="true"
              [content]="card.card"
              (sendCard)="selectAddress(card.address)"
            ></cx-address-card>
          </div>
        </div>
      </div>

      <div class="cx-checkout-btns row">
        <div class="col-md-12 col-lg-4 pl-0">
          <button class="cx-btn btn btn-block btn-action" (click)="back()">
            {{ backBtnText | cxTranslate }}
          </button>
        </div>
        <div class="col-md-12 col-lg-4">
          <button class="btn btn-block btn-action" (click)="openAddressForm()">
            {{ 'checkoutAddress.addNewAddress' | cxTranslate }}
          </button>
        </div>
        <div class="col-md-12 col-lg-4 pr-0" *ngIf="selectedAddress$ | async as selectedAddress">
          <button class="cx-btn btn btn-block btn-primary" [disabled]="!selectedAddress?.id" (click)="next()">
            {{ 'common.continue' | cxTranslate }}
          </button>
        </div>
      </div>
    </ng-container>

    <ng-template #newAddressForm>
      <div class="cx-checkout-btns row" *ngIf="!isAccountPayment">
        <div class="col-sm-12 col-md-12 col-lg-6">
          <button class="btn btn-block btn-action" (click)="openAddressForm()">
            {{ 'checkoutAddress.addNewAddress' | cxTranslate }}
          </button>
        </div>
      </div>
      <!-- <cx-address-form
        *ngIf="cards.length; else initialAddressForm"
        [showTitleCode]="true"
        (backToAddress)="hideNewAddressForm(false)"
        (submitAddress)="addAddress($event)"
      ></cx-address-form>
      <ng-template #initialAddressForm>
        <cx-address-form
          [showTitleCode]="true"
          [setAsDefaultField]="!isGuestCheckout"
          [addressData]="selectedAddress"
          cancelBtnLabel="{{ backBtnText | cxTranslate }}"
          (backToAddress)="hideNewAddressForm(true)"
          (submitAddress)="addAddress($event)"
        ></cx-address-form>
      </ng-template> -->
    </ng-template>
  </ng-container>

  <ng-template #loading>
    <div class="cx-spinner">
      <cx-spinner></cx-spinner>
    </div>
  </ng-template>
</ng-container>
