import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { StateWithProduct, SearchConfig, ProductActions, ProductSearchService } from '@spartacus/core';

@Injectable({
  providedIn: 'root',
})
export class SloanProductSearchService extends ProductSearchService {
  constructor(protected store: Store<StateWithProduct>) {
    super(store);
  }

  search(query: string | undefined, searchConfig?: SearchConfig): void {
    this.store.dispatch(
      new ProductActions.SearchProducts({
        queryText: query,
        searchConfig: searchConfig,
      })
    );
  }
}
