import { TranslationChunksConfig, TranslationResources } from '@spartacus/core';
import { en } from './en/index';

export const knbrCheckoutTranslations: TranslationResources = {
  en,
};

export const knbrCheckoutTranslationChunksConfig: TranslationChunksConfig = {
  checkout: [
    'checkoutAddress',
    'checkoutReview',
    'checkoutShipping',
    'checkoutType',
    'checkoutPO',
    'checkoutProgress',
    'checkoutMode',
    'deliveryMode',
    'orderCost',
  ],
  payment: ['paymentForm', 'paymentMethods', 'paymentCard', 'paymentTypes'],
};
