import { NgModule } from '@angular/core';
import { KnBrQuoteComponentsModule } from './components/kn-br-quote-components.module';
import { KnBrQuoteOccModule } from './occ/kn-br-quote-occ.module';
import { KnBrQuoteRootModule } from './root/kn-br-quote-root.module';
import { provideConfig, I18nConfig } from '@spartacus/core';
import { knbrQuoteTranslations, knbrQuoteTranslationChunksConfig } from './assests/translations/translations';

@NgModule({
  imports: [KnBrQuoteComponentsModule, KnBrQuoteOccModule, KnBrQuoteRootModule],
  providers: [
    provideConfig(<I18nConfig>{
      i18n: {
        resources: knbrQuoteTranslations,
        chunks: knbrQuoteTranslationChunksConfig,
        fallbackLang: 'en',
      },
    }),
  ],
})
export class KnBrQuoteModule { }
