import { TranslationChunksConfig, TranslationResources } from '@spartacus/core';

import { fr } from './fr';
import { en } from './en';

export const KnBrCartBaseTranslations: TranslationResources = {
  en,
  fr,
};

export const KnBrCartBaseTranslationChunksConfig: TranslationChunksConfig = {
  cart: ['cartDetails', 'cartItems', 'orderCost', 'voucher', 'saveForLaterItems', 'clearCart', 'validation'],
};
