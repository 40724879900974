import { FormErrorsModule } from '@spartacus/storefront';
import { NgSelectModule } from '@ng-select/ng-select';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { I18nModule } from '@spartacus/core';

import { SloanDraftOrderCommentsPopupComponent } from './sloan-draft-order-comments-popup.component';
import { KnBrDirectiveModule } from 'src/app/shared/directive/kn-br-directive.module';

@NgModule({
  declarations: [SloanDraftOrderCommentsPopupComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    FormErrorsModule,
    I18nModule,
    KnBrDirectiveModule,
  ],
  exports: [SloanDraftOrderCommentsPopupComponent],
})
export class SloanDraftOrderCommentsPopupModule {}
