/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
import { NgModule } from '@angular/core';
import { KnBrUserRegisterModule } from './kn-br-user-register/kn-br-user-register.module';
import { KnBrLoginApproverModule } from './kn-br-login-approver/kn-br-login-approver.module';
import { KnBrLoginFormModule } from './kn-br-login-form/kn-br-login-form.module';
@NgModule({
  imports: [KnBrUserRegisterModule, KnBrLoginFormModule, KnBrLoginApproverModule],
})
export class KnBrAccountComponentsModule {}
