import { KnBrCommonService } from 'src/app/services/kn-br-common.service';
import { Observable, Subscription } from 'rxjs';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray } from '@angular/forms';
import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  OnDestroy,
  AfterViewInit,
  QueryList,
  ViewChildren,
  ComponentRef,
  ViewContainerRef,
} from '@angular/core';
import { LaunchDialogService, LAUNCH_CALLER } from '@spartacus/storefront';
import { KnBrAddRequestEntry, KnBrOrderFormsSearchRequest } from 'src/feature-libs/kn-br-order/root/models/kn-br-order.model';
import { KnBrOrderHistoryService } from 'src/feature-libs/kn-br-order/core/facade/kn-br-order-history.service';
import { KnBrDraftOrdersService } from 'src/feature-libs/kn-br-cart/draft-order/core/facade/kn-br-draft-orders.service';
import { KnBrQuoteEntryService } from 'src/feature-libs/kn-br-cart/quote/core/facade/kn-br-quote-entry.service';

@Component({
  selector: 'kn-br-add-products',
  templateUrl: './kn-br-add-products.component.html',
  styleUrls: ['./kn-br-add-products.component.scss'],
})
export class KnBrAddProductsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChildren('qty') rows: QueryList<any>;
  previousRowLength = 0;
  addProductsForm: UntypedFormGroup;
  submitted = false;
  addProductsInput;
  currentIndex;
  @Output()
  inputChange = new EventEmitter<KnBrAddRequestEntry[]>();
  allAddedProducts: KnBrAddRequestEntry[] = [];
  subscription = new Subscription();
  cartEntryloader$: Observable<boolean> = this.knBrDraftOrderService.loader$;
  quickEntryloader$: Observable<boolean> = this.knBrQuoteEntryService.quickEntryLoader$;
  showSpinner: void | Observable<ComponentRef<any>>;
  constructor(
    protected formBuilder: UntypedFormBuilder,
    protected cdr: ChangeDetectorRef,
    protected knBrOrderHistoryService: KnBrOrderHistoryService,
    protected knBrDraftOrderService: KnBrDraftOrdersService,
    protected knbrCommonService: KnBrCommonService,
    protected launchDialogService: LaunchDialogService,
    protected vcr: ViewContainerRef,
    protected knBrQuoteEntryService: KnBrQuoteEntryService
  ) { }

  ngOnInit() {
    this.formInit();
    this.subscription.add(
      this.knBrOrderHistoryService.orderFormsProducts$.subscribe((response) => {
        if (response) {
          const products = response.products;
          const rowindex = response.rowindex;
          if (products && products.length > 0) {
            this.createProductForm(rowindex, products[0]);
          } else {
            if (rowindex >= 0) {
              this.t.setControl(
                rowindex,
                this.formBuilder.group({
                  productCode: [this.t.value[rowindex].productCode, Validators.required],
                  showForm: [false],
                  showErrorMsg: [true],
                })
              );
              this.prepareDataAndEmit();
            }
          }
          this.cdr.markForCheck();
        }
      })
    );
    this.subscription.add(
      this.knBrDraftOrderService.quickResetForm$.subscribe((res) => {
        if (res) {
          this.resetForm();
        }
      })
    );
    this.subscription.add(
      this.cartEntryloader$.subscribe((isLoading) => {
        this.onSpinnerLoading(isLoading);
      })
    );
    this.subscription.add(
      this.quickEntryloader$.subscribe((isLoading) => {
        this.onSpinnerLoading(isLoading);
      })
    );
  }

  ngAfterViewInit() {
    this.subscription.add(
      this.rows.changes.subscribe((resp) => {
        this.rows = resp;
        if (this.rows.length > 0 && this.previousRowLength !== this.rows.length) {
          this.rows.last.nativeElement.focus();
          this.rows.last.nativeElement.select();
          this.previousRowLength = this.rows.length;
        }
        this.cdr.markForCheck();
      })
    );
  }

  formInit() {
    this.addProductsForm = this.formBuilder.group({
      sku: [''],
      products: new UntypedFormArray([]),
    });
    this.t.push(
      this.formBuilder.group({
        productCode: [null, Validators.required],
        showForm: [false],
        image: [''],
        listPrice: [''],
        qty: [1, Validators.required],
        total: [''],
        showErrorMsg: [false],
      })
    );
    this.addQuickEntryBufferRow();
    this.addQuickEntryBufferRow();
  }

  get f() {
    return this.addProductsForm.controls;
  }
  get t() {
    return this.f.products as UntypedFormArray;
  }

  createProductForm(index, product: any) {
    this.t.setControl(
      index,
      this.formBuilder.group({
        productCode: [product.code, Validators.required],
        image: [product],
        listPrice: [product.price.formattedValue],
        qty: [product.minOrderQuantity ? product.minOrderQuantity : 1, Validators.required],
        total: [product.price.value],
        showForm: [true],
        showErrorMsg: [false],
      })
    );
    this.prepareDataAndEmit();
    this.cdr.markForCheck();
  }

  prepareDataAndEmit() {
    const addedproducts = this.t.value;
    if (addedproducts && addedproducts.length > 0) {
      this.allAddedProducts = [];
      addedproducts.forEach((addedproduct) => {
        if (addedproduct && !(addedproduct.productCode === '') && addedproduct.qty && addedproduct.showForm) {
          const req: KnBrAddRequestEntry = {
            productCode: addedproduct.productCode,
            quantity: addedproduct.qty,
          };
          this.allAddedProducts.push(req);
        }
      });
      if (this.allAddedProducts && this.allAddedProducts.length > 0) {
        this.inputChange.emit(this.allAddedProducts);
      }
      this.cdr.markForCheck();
    }
  }

  onQuantityChange(index) {
    const qty = this.t.value[index].qty;
    const minqty = this.t.value[index].image.minOrderQuantity ? this.t.value[index].image.minOrderQuantity : 1;
    const qtybasedOnMoq = this.knbrCommonService.getQuantityBasedOnMoq(qty, minqty);
    ((this.addProductsForm.get('products') as UntypedFormArray).at(index) as UntypedFormGroup)
      .get('qty')
      .patchValue(qtybasedOnMoq);
    this.t.value[index].qty = qtybasedOnMoq;
    this.prepareDataAndEmit();
  }

  searchForProduct(index) {
    this.currentIndex = index;
    const productcode = this.t.value[index].productCode;
    if (productcode) {
      const req: KnBrOrderFormsSearchRequest = {
        keywords: productcode,
        onlyProductIds: true,
        rowindex: index,
      };
      this.knBrOrderHistoryService.getOrderFormProducts(req);
    } else {
      this.t.setControl(
        index,
        this.formBuilder.group({
          productCode: [null, Validators.required],
          showForm: [false],
          showErrorMsg: [false],
        })
      );
    }
  }

  onEnterInput(value, inputBoxIndex) {
    if (inputBoxIndex + 1 === this.t.controls.length) {
      this.addQuickEntryBufferRow();
      this.cdr.markForCheck();
    }
  }

  addQuickEntryBufferRow() {
    this.t.push(
      this.formBuilder.group({
        productCode: [null, Validators.required],
        showForm: [false],
        showErrorMsg: [false],
      })
    );
  }

  canRemove(): boolean {
    const inputList = this.t.value.filter((input) => input.showForm === false);
    if (inputList && inputList.length === 1) {
      return true;
    } else {
      return false;
    }
  }

  removeProduct(index) {
    if (this.t.length === 3 || this.canRemove()) {
      this.t.setControl(
        index,
        this.formBuilder.group({
          productCode: [null, Validators.required],
          showForm: [false],
          showErrorMsg: [false],
        })
      );
    } else {
      this.t.removeAt(index);
    }
    this.prepareDataAndEmit();
  }

  resetForm() {
    this.addProductsForm.reset();
    this.t.clear();
    this.formInit();
    this.cdr.markForCheck();
  }

  onSpinnerLoading(isLoading) {
    if (isLoading) {
      this.showSpinner = this.launchDialogService.launch(LAUNCH_CALLER.PLACE_ORDER_SPINNER, this.vcr);
    } else {
      if (this.showSpinner) {
        this.showSpinner
          .subscribe((component) => {
            this.launchDialogService.clear(LAUNCH_CALLER.PLACE_ORDER_SPINNER);
            component.destroy();
          })
          .unsubscribe();
      }
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.knBrOrderHistoryService.resetOrderFormsData();
    this.resetForm();
    this.knBrDraftOrderService.quickFormReset(false);
    this.launchDialogService.clear(LAUNCH_CALLER.PLACE_ORDER_SPINNER);
  }
}
