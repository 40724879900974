import { Injectable } from '@angular/core';
import { AddressValidation, Converter } from '@spartacus/core';
import { SloanAddressValidation } from 'src/app/models/sloan/sloan-address-validation.model';
import * as txml from 'txml';

@Injectable()
export class SloanAddressValidationNormalizer implements Converter<any, AddressValidation> {
  convert(source: any, target: SloanAddressValidation): AddressValidation {
    if (target === undefined) {
      let newDecision = '';
      const validAddressResponse = {
        zipCode: '',
        cityName: '',
        region: {
          isocode: '',
        },
        line1: '',
      };
      const parsedResponse = txml.parse(source, { simplify: true });
      const jsonResponse = JSON.parse(JSON.stringify(parsedResponse));
      const statusReponse =
        jsonResponse.WebServiceResponse.VerifyAddressUSAResponse.VerifyAddressUSAResult.ServiceStatus;
      if (statusReponse.StatusNbr === '200') {
        // Found
        newDecision = 'ACCEPT';
        const serviceResult =
          jsonResponse.WebServiceResponse.VerifyAddressUSAResponse.VerifyAddressUSAResult.ServiceResult;
        validAddressResponse.zipCode =
          jsonResponse.WebServiceResponse.VerifyAddressUSAResponse.VerifyAddressUSAResult.ServiceResult.ZIPPlus4;
        validAddressResponse.cityName =
          jsonResponse.WebServiceResponse.VerifyAddressUSAResponse.VerifyAddressUSAResult.ServiceResult.City;
        validAddressResponse.region.isocode = 'US-' + serviceResult.State;
        validAddressResponse.line1 = ((serviceResult.PreDirection ? serviceResult.PreDirection + ' ' : '') + (serviceResult.StreetName ? serviceResult.StreetName + ' ' : '') + (serviceResult.StreetType ? serviceResult.StreetType + ' ' : '') + (serviceResult.PostDirection ? serviceResult.PostDirection : '')).trim();
      } else if (statusReponse.StatusNbr === '304') {
        // Address Not Found - 1st time REJECT, 2nd time ACCEPT
        newDecision = 'REJECT';
      } else if (statusReponse.StatusNbr === '305') {
        // Address is ambigous
        newDecision = 'REJECT';
      } else if (statusReponse.StatusNbr === '402') {
        // City or ZIP Code is Invalid - 1st time REJECT, 2nd time ACCEPT
        newDecision = 'REJECT';
      } else if (statusReponse.StatusNbr === '500') {
        // Internal Error
        newDecision = 'FAIL';
      } else {
        newDecision = 'FAIL';
      }

      target = {
        decision: newDecision,
        validAddressResponse,
        errors: {
          errors: [],
        },
      };
    }
    return target;
  }
}
