import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { SloanQuotesEffects } from './sloan-quotes.effects';
import { sloanQuoteReducer } from './sloan-quotes.reducer';
import { SLOAN_SEARCH_QUOTES_FEATURE } from './sloan-quotes.state';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(SLOAN_SEARCH_QUOTES_FEATURE, sloanQuoteReducer),
    EffectsModule.forFeature([SloanQuotesEffects]),
  ],
})
export class SloanQuotesStateModule {}
