import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthGuard, ConfigModule, RoutingConfig } from '@spartacus/core';
import { CmsPageGuard, PageLayoutComponent } from '@spartacus/storefront';
import { KnBrCustomerSearchModule } from './components/kn-br-customer-search/kn-br-customer-search.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    KnBrCustomerSearchModule,
    RouterModule.forChild([
      {
        data: { pageLabel: 'bravaCustomerHomepage', cxRoute: 'home' },
        path: null,
        canActivate: [AuthGuard, CmsPageGuard],
        component: PageLayoutComponent,
      },
    ]),
    ConfigModule.withConfig({
      routing: {
        routes: {
          home: {
            paths: [''],
          },
        },
      },
    } as RoutingConfig),
  ],
})
export class KnBrHomeModule {}
