import { KnBrCustomerContextService } from '../../..//store/kn-br-customer-context/kn-br-customer-context.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { SLOAN_QUOTE_EDIT } from 'src/constants/sloan/sloan-api.endpoints.constant';
import { KnBrQuoteContextService } from 'src/feature-libs/kn-br-cart/quote/core/facade/kn-br-quote-context.service';

@Injectable({
  providedIn: 'root',
})
export class SloanQuotesAdapter {
  private customerNumber: string;
  quoteId: string;
  constructor(
    private endPointService: OccEndpointsService,
    private httpClient: HttpClient,
    protected knBrCustomerContextService: KnBrCustomerContextService,
    protected knBrQuoteContextService: KnBrQuoteContextService
  ) {
    this.knBrCustomerContextService.getActiveCustomerNumber().subscribe((response) => {
      this.customerNumber = response ? response : null;
    });
    this.knBrQuoteContextService.get$.subscribe((response) => {
      this.quoteId = response ? response : null;
    });
  }

  edit(payload) {
    return this.httpClient.patch(
      this.endPointService.buildUrl(SLOAN_QUOTE_EDIT, {
        urlParams: { customerId: this.customerNumber, quoteId: payload?.code ? payload.code : this.quoteId },
      }),
      null
    );
  }
}
