<ng-container *ngIf="order$ | async as order">
  <div class="col-md-12 float-right d-flex justify-content-end">
    <button class="btn back-btn p-1 font-lg-size" (click)="goBack()">
      <i class="fas fa-level-down-alt fa-rotate-90" aria-hidden="true"></i>
    </button>
  </div>
  <div class="col-md-12 float-left p-0 mt-3 mb-4">
    <div class="col-md-9 float-left p-0">
      <div class="col-md-4 float-left">
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrOrderDetails.label.orderNumber' | cxTranslate }}
        </label>
        <p class="text-muted mb-2">
          {{ order.sapOrderNumber ? (order.sapOrderNumber | removeLeadingZeros) : (order.code | removeLeadingZeros) }}
        </p>
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrOrderDetails.label.orderStatus' | cxTranslate }}
        </label>
        <p class="text-muted mb-2">{{ order.statusDisplay }}</p>
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrOrderDetails.label.orderType' | cxTranslate }}
        </label>
        <p class="text-muted mb-2">{{ order.orderType }}</p>
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrOrderDetails.label.poNumber' | cxTranslate }}
        </label>
        <p class="text-muted mb-2">{{ order.purchaseOrderNumber }}</p>

        <label class="color-font font-text-600 mb-1 float-left">
          {{ 'knbrOrderDetails.label.soldTo' | cxTranslate }}
        </label>
        <br />
        <p class="text-muted" *ngIf="order.soldTo">
          <span class="w-100 float-left">{{ order.soldTo?.customerNumber }}</span>
          <span class="w-100 float-left">{{ order.soldTo?.customerName }}</span>
          <span class="w-100 float-left">{{ order.soldTo?.houseNumber }}</span>
          <span class="w-100 float-left">{{ order.soldTo?.street }}</span>
          <span class="w-100 float-left">{{ order.soldTo?.city }}</span>
          <span class="w-100 float-left">{{ order.soldTo?.state }}</span>
          <span class="w-100 float-left">{{ order.soldTo?.country }}, {{ order.soldTo?.postalCode }}</span>
          <span class="w-100 float-left mb-2">{{ order.soldTo?.telephone }}</span>
        </p>
        <label class="color-font font-text-600 mb-1 float-left">
          {{ 'knbrOrderDetails.label.shipTo' | cxTranslate }}
        </label>
        <br />
        <p
          class="text-muted mb-2"
          *ngIf="order.soldTo && order.soldTo.shipToPartners && order.soldTo.shipToPartners.length"
        >
          <span class="w-100 float-left">{{ order.soldTo.shipToPartners[0]?.shipToNumber }}</span>
          <span class="w-100 float-left">{{ order.soldTo.shipToPartners[0]?.shipToName }}</span>
          <span
            *ngIf="order.soldTo.shipToPartners[0]?.department && order.soldTo.shipToPartners[0]?.department.length > 0"
            class="w-100 float-left"
            >{{ order.soldTo.shipToPartners[0]?.department }}</span
          >
          <span class="w-100 float-left">{{ order.soldTo.shipToPartners[0]?.houseNumber }}</span>
          <span class="w-100 float-left">{{ order.soldTo.shipToPartners[0]?.street }}</span>
          <span class="w-100 float-left">{{ order.soldTo.shipToPartners[0]?.city }}</span>
          <span class="w-100 float-left">{{ order.soldTo.shipToPartners[0]?.state }}</span>
          <span class="w-100 float-left"
            >{{ order.soldTo.shipToPartners[0]?.country }}, {{ order.soldTo.shipToPartners[0]?.postalCode }}</span
          >
          <span class="w-100 float-left">{{ order.soldTo.shipToPartners[0]?.telephone }}</span>
        </p>
      </div>
      <div class="col-md-4 float-left">
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrOrderDetails.label.orderCreationDate' | cxTranslate }}
        </label>
        <p class="text-muted mb-2">{{ order.creationDate }}</p>
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrOrderDetails.label.orderPlacedBy' | cxTranslate }}
        </label>
        <p class="text-muted mb-2">{{ order.placedBy }}</p>
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrOrderDetails.label.jobNumber' | cxTranslate }}
        </label>
        <p *ngIf="order.jobName" class="text-muted mb-2">{{ order.jobName }}</p>
        <p *ngIf="!order.jobName" class="text-muted mb-2">-</p>
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrOrderDetails.label.shippingNotes' | cxTranslate }}
        </label>
        <p *ngIf="order?.shippingNotes" class="text-muted mb-2">{{ order.shippingNotes }}</p>
        <p *ngIf="!order?.shippingNotes" class="text-muted mb-2">-</p>
        <!--  Ship Complete Block Starts-->
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrOrderDetails.label.shipComplete' | cxTranslate }}
        </label>
        <p *ngIf="order.shipComplete" class="text-muted mb-2">Yes</p>
        <p *ngIf="!order.shipComplete" class="text-muted mb-2">No</p>
        <!--  Ship Complete Block Ends-->
        <!--Order Notes Starts-->
        <label class="color-font font-text-600 mb-1">
          {{ 'cartCommon.label.headerNotes' | cxTranslate }}
        </label>
        <p *ngIf="order.headerComments && order.headerComments.length > 0" class="text-muted mb-2">
          {{ order.headerComments }}
        </p>
        <!--Order Notes Ends-->
      </div>
      <div class="col-md-4 float-left">
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrOrderDetails.label.reqShipDate' | cxTranslate }}
        </label>
        <p class="text-muted mb-2">{{ order.requestedDeliveryDate }}</p>
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrOrderDetails.label.shippingMethod' | cxTranslate }}
        </label>
        <p class="text-muted mb-2">{{ order.shippingMethod }}</p>
        <!-- Account Number Block Starts-->
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrOrderDetails.label.additionalAccountNumber' | cxTranslate }}
        </label>
        <p *ngIf="order.additionalAccountNumber && order.additionalAccountNumber.length > 0" class="text-muted mb-2">
          {{ order.additionalAccountNumber }}
        </p>
        <!-- Account Number Block Ends-->
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrOrderDetails.label.totalQty' | cxTranslate }}
        </label>
        <p class="text-muted mb-2">{{ order.totalUnitCount }}</p>
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrOrderDetails.label.orderTotal' | cxTranslate }}
        </label>
        <p class="text-muted mb-2">{{ order.totalPrice?.formattedValue }}</p>
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrOrderDetails.label.downloadDocs' | cxTranslate }}
        </label>
        <p class="float-left mb-2">
          <a
            class="cx-link cursor-pointer w-100 float-left"
            (click)="downloadSAPDocumentPDF(doc.value)"
            *ngFor="let doc of order.downloadSAPDocuments?.entry"
            >{{ getDocumentKey(doc.key) }}</a
          >
        </p>
      </div>
    </div>
    <div class="col-md-3 float-left">
      <cx-page-slot position="ActionSlot"></cx-page-slot>
    </div>
  </div>
</ng-container>
