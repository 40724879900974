import { OccConfig } from '@spartacus/core';

export const sloanDraftOrderEndpointsConfig: OccConfig = {
  backend: {
    occ: {
      endpoints: {
        cart: 'knbr/customer/${customerId}/carts/${cartId}?fields=DEFAULT,entries(totalPrice(formattedValue),product(images(FULL),stock(FULL)),basePrice(formattedValue,value),updateable),totalPrice(formattedValue),totalItems,totalPriceWithTax(formattedValue),totalDiscounts(formattedValue),subTotal(formattedValue),deliveryItemsQuantity,deliveryCost(formattedValue),totalTax(formattedValue),net,productDiscounts(formattedValue),user,name,description,paymentType,deliveryMode(FULL),shippingNotes,additionalAccountNumber',
      },
    },
  },
};
