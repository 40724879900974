import { Statuses } from '../../../quote/root/models/kn-br-quote.model';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, queueScheduler, BehaviorSubject } from 'rxjs';
import { observeOn } from 'rxjs/operators';
import { DraftOrder } from 'src/feature-libs/kn-br-cart/draft-order/root/models/draft-order.model';

import { DraftSearchForm } from '../../root/models/draft-order.model';
import {
  KnBDraftOrderStatusesLoad,
  KnBrDraftOrderCopy,
  KnBrDraftOrderCreate,
  KnBrDraftOrderEntryUpdate,
  KnBrDraftOrderPatch,
  KnBrDraftOrderSearch,
  KnBrDraftOrderSearchReset,
  KnBrDraftOrderUpdate,
  KnBrDraftOrderQuickEntry,
  KnBrQuickOrderResetForm,
  KnBrDraftOrderWarningMessage,
  KnBrDraftOrderDelete,
} from '../store/actions/kn-br-draft-orders.action';
import {
  getDraftOrders,
  getDraftOrderSearchCriteria,
  getDraftOrdersLoader,
  getDraftOrdersPaginate,
  getDraftSearchDisabled,
  getDraftCreateDisabled,
  getDraftOrderDetailStatuses,
  getResetForm,
  getDraftOrdersSpinner,
  getDraftOrderCheckOutDisable,
  getDraftOrderWarningMessage,
} from '../store/selecters/kn-br-draft-orders.selector';
import { StateWithDraftOrder } from '../../../../../app/store/kn-br-draft-orders/kn-br-draft-orders.state';
import { HttpClient } from '@angular/common/http';
import { OccEndpointsService } from '@spartacus/core';
import { KnBrCustomerContextService } from '../../../../../app/store/kn-br-customer-context/kn-br-customer-context.service';
import { KnBrCartContextService } from 'src/feature-libs/kn-br-cart/base/core/facade/kn-br-cart-context.service';
import { KnBrDraftOrdersAdapter } from '../connectors/kn-br-draft-orders.adapter';

@Injectable({
  providedIn: 'root',
})
export class KnBrDraftOrdersService {
  customerId: string;
  cartId: string;
  frieghtAndShipCompleteDetails: any;

  constructor(
    private endPointService: OccEndpointsService,
    private httpClient: HttpClient,
    protected knBrCustomerContextService: KnBrCustomerContextService,
    protected knBrCartContextService: KnBrCartContextService,
    private store: Store<StateWithDraftOrder>,
    private knBrDraftOrdersAdapter: KnBrDraftOrdersAdapter
  ) {
    this.knBrCustomerContextService.getActiveCustomerNumber().subscribe((response) => {
      this.customerId = response ? response : null;
    });
    this.knBrCartContextService.get$.subscribe((response) => {
      this.cartId = response ? response : null;
    });
  }

  list$: Observable<DraftOrder[]> = this.store.pipe(select(getDraftOrders), observeOn(queueScheduler));

  loader$: Observable<boolean> = this.store.pipe(select(getDraftOrdersLoader), observeOn(queueScheduler));

  paginate$: Observable<any> = this.store.pipe(select(getDraftOrdersPaginate), observeOn(queueScheduler));

  searchCriteria$: Observable<any> = this.store.pipe(select(getDraftOrderSearchCriteria), observeOn(queueScheduler));

  isSearchDisabled$: Observable<boolean> = this.store.pipe(select(getDraftSearchDisabled), observeOn(queueScheduler));

  isCreateDisabled$: Observable<boolean> = this.store.pipe(select(getDraftCreateDisabled), observeOn(queueScheduler));

  spinner$: Observable<boolean> = this.store.pipe(select(getDraftOrdersSpinner), observeOn(queueScheduler));

  warningMessage$: Observable<string> = this.store.pipe(select(getDraftOrderWarningMessage), observeOn(queueScheduler));

  isCheckoutDisabled$: Observable<boolean> = this.store.pipe(
    select(getDraftOrderCheckOutDisable),
    observeOn(queueScheduler)
  );

  showAddCartEntryLoading$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  draftOrderStatuses$: Observable<Statuses[]> = this.store.pipe(
    select(getDraftOrderDetailStatuses),
    observeOn(queueScheduler)
  );

  quickResetForm$: Observable<boolean> = this.store.pipe(select(getResetForm), observeOn(queueScheduler));

  draftHeaderDiscountType$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  search(searchForm: DraftSearchForm) {
    if ((searchForm && searchForm.currentPage === 0) || !searchForm) {
      this.reset();
    }
    this.store.dispatch(new KnBrDraftOrderSearch(searchForm));
  }

  reset() {
    this.store.dispatch(new KnBrDraftOrderSearchReset(null));
  }

  copy(payload) {
    this.store.dispatch(new KnBrDraftOrderCopy(payload));
  }

  create(payload) {
    this.store.dispatch(new KnBrDraftOrderCreate(payload));
  }

  update(payload) {
    this.store.dispatch(new KnBrDraftOrderUpdate(payload));
  }

  delete(payload) {
    this.store.dispatch(new KnBrDraftOrderDelete(payload));
  }

  patch(payload) {
    this.store.dispatch(new KnBrDraftOrderPatch(payload));
  }

  updateEntry(payload) {
    this.store.dispatch(new KnBrDraftOrderEntryUpdate(payload));
  }

  validateOnCheckout() {
    // this.store.dispatch(new KnBrDraftOrderChekoutValidation(null));
    return this.knBrDraftOrdersAdapter.validateOnCheckout();
  }

  getStatuses() {
    this.store.dispatch(new KnBDraftOrderStatusesLoad());
  }

  quickEntry(payload) {
    this.store.dispatch(new KnBrDraftOrderQuickEntry(payload));
  }

  quickFormReset(payload) {
    this.store.dispatch(new KnBrQuickOrderResetForm(payload));
  }

  setAddCartEntryLoading(showLoading: boolean) {
    this.showAddCartEntryLoading$.next(showLoading);
  }

  loadAddCartEntryLoading$(): Observable<boolean> {
    return this.showAddCartEntryLoading$.asObservable();
  }

  resetWarningMsg() {
    this.store.dispatch(new KnBrDraftOrderWarningMessage(null));
  }

  setFrightAndShipComplteDetails(data) {
    this.frieghtAndShipCompleteDetails = data;
  }
  getFrightAndShipComplteDetails() {
    return this.frieghtAndShipCompleteDetails;
  }
}
