import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { RoutingService, TranslationService } from '@spartacus/core';
import { KnBrCustomerContextService } from 'src/app/store/kn-br-customer-context/kn-br-customer-context.service';
import { KnBrOrderHistoryService } from 'src/feature-libs/kn-br-order/core/facade/kn-br-order-history.service';
import * as _ from 'lodash';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { KnBrOrderHistorySearchResultComponent } from 'src/feature-libs/kn-br-order/components/kn-br-customer360/kn-br-order-history/kn-br-order-history-search-result/kn-br-order-history-search-result.component';

@Component({
  selector: 'knbr-kn-br-order-history-search-result',
  templateUrl: './sloan-order-history-search-result.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SloanOrderHistorySearchResultComponent
  extends KnBrOrderHistorySearchResultComponent
  implements OnInit, OnDestroy {
  constructor(
    public knBrOrderHistoryService: KnBrOrderHistoryService,
    protected routeService: RoutingService,
    protected knBrCustomerContextService: KnBrCustomerContextService,
    protected translation: TranslationService
  ) {
    super(knBrOrderHistoryService, routeService, knBrCustomerContextService, translation);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  getSortLabels(): Observable<{
    byDate: string;
    byOrderNumber: string;
    byPONumber: string;
    byOrderType: string;
    byStatus: string;
  }> {
    return combineLatest([
      this.translation.translate('sorting.date'),
      this.translation.translate('sorting.orderNumber'),
      this.translation.translate('sorting.poNumber'),
      this.translation.translate('sorting.orderType'),
      this.translation.translate('sorting.orderStatus'),
    ]).pipe(
      map(([textByDate, textByOrderNumber, textByPoNumber, textByOrderType, textByStatus]) => {
        return {
          byDate: textByDate,
          byOrderNumber: textByOrderNumber,
          byPONumber: textByPoNumber,
          byOrderType: textByOrderType,
          byStatus: textByStatus,
        };
      })
    );
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
