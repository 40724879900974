import { Component, ComponentRef, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { RoutingService } from '@spartacus/core';
import { LaunchDialogService, LAUNCH_CALLER } from '@spartacus/storefront';
import { UserAccountFacade } from '@spartacus/user/account/root';
import * as _ from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { KnBrDateHelper } from 'src/app/shared/kn-br-date.helper';
import { KnBrCustomerContextService } from 'src/app/store/kn-br-customer-context/kn-br-customer-context.service';
import { KnBrDraftOrdersService } from '../../../core/facade/kn-br-draft-orders.service';

@Component({
  selector: 'kn-br-draft-order-create',
  templateUrl: './kn-br-draft-order-create.component.html',
  providers: [KnBrDateHelper],
})
export class KnBrDraftOrderCreateComponent implements OnInit, OnDestroy {
  customerId: string;
  cartCreateForm: UntypedFormGroup;
  copiedDraftOrderData: any;
  subscription = new Subscription();
  dateFormat$: Observable<any> = this.currentUserService.get().pipe(
    filter((user: any) => user && !!user.dateFormat),
    map((user: any) => user.dateFormat)
  );
  isCreateDisabled$: Observable<boolean> = this.service.isCreateDisabled$;
  showSpinner: void | Observable<ComponentRef<any>>;
  loader$: Observable<boolean> = this.service.loader$;
  minDate = undefined;
  constructor(
    protected currentUserService: UserAccountFacade,
    protected fb: UntypedFormBuilder,
    protected service: KnBrDraftOrdersService,
    protected customerService: KnBrCustomerContextService,
    protected routingService: RoutingService,
    protected knBrDateHelper: KnBrDateHelper,
    protected launchDialogService: LaunchDialogService,
    protected vcr: ViewContainerRef
  ) {
    this.routingService
      .getRouterState()
      .subscribe((state) => {
        if (state && state.state && state.state.params && state.state.params.code && state.state.params.name) {
          this.copiedDraftOrderData = { code: state.state.params.code, name: state.state.params.name };
        }
      })
      .unsubscribe();
  }

  ngOnInit(): void {
    this.subscription = this.customerService.get$.subscribe((response) => {
      this.customerId = response && response.customerNumber ? response.customerNumber : null;
    });
    this.subscription.add(
      this.isCreateDisabled$.subscribe((isLoading) => {
        this.onSpinnerLoading(isLoading);
      })
    );
    this.subscription.add(
      this.loader$.subscribe((isLoading) => {
        this.onSpinnerLoading(isLoading);
      })
    );
    this.initForm();
    this.disableBackdate();
  }

  disableBackdate() {
    const current = new Date();
    this.minDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate(),
    };
  }

  onSpinnerLoading(isLoading) {
    if (isLoading) {
      this.showSpinner = this.launchDialogService.launch(LAUNCH_CALLER.PLACE_ORDER_SPINNER, this.vcr);
    } else {
      if (this.showSpinner) {
        this.showSpinner
          .subscribe((component) => {
            this.launchDialogService.clear(LAUNCH_CALLER.PLACE_ORDER_SPINNER);
            component.destroy();
          })
          .unsubscribe();
      }
    }
  }

  initForm() {
    this.cartCreateForm = this.fb.group({
      customerNumber: this.customerId,
      name: ['', Validators.required],
      requestedDeliveryDate: null,
      purchaseOrderNumber: ['', Validators.required],
      jobName: '',
      shipComplete: false,
      headerComments: '',
      referenceCartId: this.copiedDraftOrderData ? this.copiedDraftOrderData.code : null,
    });
  }

  onSubmit() {
    if (this.cartCreateForm.valid) {
      const formValues = _.cloneDeep(this.cartCreateForm.getRawValue());
      formValues.requestedDeliveryDate = '';
      if (this.cartCreateForm.controls.requestedDeliveryDate.value) {
        formValues.requestedDeliveryDate = this.knBrDateHelper.formatDate(
          this.cartCreateForm.controls.requestedDeliveryDate.value
        );
      }
      this.submitOrder(formValues);
    } else {
      this.cartCreateForm.markAllAsTouched();
    }
  }

  submitOrder(formValues) {
    if (formValues.referenceCartId) {
      this.service.copy(formValues);
    } else {
      this.service.create(formValues);
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.launchDialogService.clear(LAUNCH_CALLER.PLACE_ORDER_SPINNER);
  }

  goBack() {
    this.routingService.go({ cxRoute: 'carts' });
  }
}
