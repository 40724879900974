import { Component, ComponentRef, OnInit, ViewContainerRef, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { CxDatePipe, RoutingService, User } from '@spartacus/core';
import { LaunchDialogService, LAUNCH_CALLER } from '@spartacus/storefront';
import { Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { UserAccountService } from '@spartacus/user/account/core';
import { KnBrDateHelper } from 'src/app/shared/kn-br-date.helper';
import { KnBrQuoteEntryService } from '../../../core/facade/kn-br-quote-entry.service';
import { KnBrQuotesService } from '../../../core/facade/kn-br-quotes.service';
import { CustomerData } from 'src/feature-libs/kn-br-user/kn-br-account/root/model/customer.model';
import { UserAccountFacade } from '@spartacus/user/account/root';

@Component({
  selector: 'kn-br-quote-create',
  templateUrl: './kn-br-quote-create.component.html',
  styleUrls: ['./kn-br-quote-create.component.scss'],
  providers: [CxDatePipe, KnBrDateHelper],
})
export class KnBrQuoteCreateComponent implements OnInit, OnDestroy {
  quoteForm: UntypedFormGroup;
  user: User;
  customer: CustomerData;
  copiedQuoteData: any;
  dateFormat$: Observable<any> = this.currentUserService.get().pipe(
    filter((user: any) => user && !!user.dateFormat),
    map((user: any) => user.dateFormat)
  );

  isCreateDisabled$: Observable<boolean> = this.quoteService.isCreateDisabled$;
  showSpinner: void | Observable<ComponentRef<any>>;
  subscription = new Subscription();
  quickEntryloader$: Observable<boolean> = this.knBrQuoteEntryService.quickEntryLoader$;
  constructor(
    protected currentUserService: UserAccountFacade,
    protected fb: UntypedFormBuilder,
    public ngbCalendar: NgbCalendar,
    protected routingService: RoutingService,
    protected quoteService: KnBrQuotesService,
    protected knBrDateHelper: KnBrDateHelper,
    protected launchDialogService: LaunchDialogService,
    protected vcr: ViewContainerRef,
    protected knBrQuoteEntryService: KnBrQuoteEntryService
  ) {
    this.routingService
      .getRouterState()
      .subscribe((state) => {
        if (state && state.state && state.state.params && state.state.params.code && state.state.params.name) {
          this.copiedQuoteData = { code: state.state.params.code, name: state.state.params.name };
        }
      })
      .unsubscribe();
  }

  ngOnInit(): void {
    this.initForm();
    this.subscription.add(
      this.isCreateDisabled$.subscribe((isLoading) => {
        this.onSpinnerLoading(isLoading);
      })
    );
    this.subscription.add(
      this.quickEntryloader$.subscribe((isLoading) => {
        this.onSpinnerLoading(isLoading);
      })
    );
  }

  onSpinnerLoading(isLoading) {
    if (isLoading) {
      this.showSpinner = this.launchDialogService.launch(LAUNCH_CALLER.PLACE_ORDER_SPINNER, this.vcr);
    } else {
      if (this.showSpinner) {
        this.showSpinner
          .subscribe((component) => {
            this.launchDialogService.clear(LAUNCH_CALLER.PLACE_ORDER_SPINNER);
            component.destroy();
          })
          .unsubscribe();
      }
    }
  }

  initForm() {
    this.quoteForm = this.fb.group({
      headerNotes: '',
      purchaseOrderNumber: '',
      quoteValidFrom: [this.ngbCalendar.getToday(), Validators.required],
      quoteValidTo: [this.ngbCalendar.getNext(this.ngbCalendar.getToday(), 'd', 30), Validators.required],
      rdd: null,
      shipComplete: false,
      jobName: ['', Validators.required],
      referenceQuoteId: this.copiedQuoteData ? this.copiedQuoteData.code : null,
    });
  }

  onSubmit() {
    if (this.quoteForm.valid) {
      const formValues = this.quoteForm.value;
      formValues.rdd = '';
      if (this.quoteForm.controls.rdd.value) {
        formValues.rdd = this.knBrDateHelper.formatDate(this.quoteForm.controls.rdd.value);
      }

      if (this.quoteForm.controls.quoteValidFrom && this.quoteForm.controls.quoteValidFrom.value) {
        formValues.quoteValidFrom = this.knBrDateHelper.formatDate(this.quoteForm.controls.quoteValidFrom.value);
      }
      if (this.quoteForm.controls.quoteValidTo && this.quoteForm.controls.quoteValidTo.value) {
        formValues.quoteValidTo = this.knBrDateHelper.formatDate(this.quoteForm.controls.quoteValidTo.value);
      }

      this.submitOrder(formValues);
    } else {
      this.quoteForm.markAllAsTouched();
    }
  }

  goToListPage() {
    this.routingService.go({ cxRoute: 'quotes' });
  }

  submitOrder(formValues) {
    if (formValues.referenceQuoteId) {
      this.quoteService.copy(formValues);
    } else {
      this.quoteService.create(formValues);
    }
  }

  ngOnDestroy() {
    this.launchDialogService.clear(LAUNCH_CALLER.PLACE_ORDER_SPINNER);
  }
}
