import { ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ActiveCartFacade, PaymentType } from '@spartacus/cart/base/root';
import { B2BPaymentTypeEnum, CheckoutPaymentTypeFacade } from '@spartacus/checkout/b2b/root';
import { CheckoutStepService } from '@spartacus/checkout/base/components';
import { CheckoutStepType } from '@spartacus/checkout/base/root';
import { GlobalMessageService, GlobalMessageType, RoutingService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { SloanCommonService } from 'src/app/services/sloan/sloan-common.service';

@Component({
  selector: 'cx-payment-type',
  templateUrl: './sloan-checkout-payment-type.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SloanPaymentTypeComponent implements OnInit {
  @ViewChild('poNumber', { static: false })
  private poNumberInput: ElementRef;
  typeSelected: string;
  cartPoNumber: string;
  paymentTypes$: Observable<PaymentType[]> = this.paymentTypeService.getPaymentTypes();

  typeSelected$: Observable<PaymentType> = this.activeCartService.getActive().pipe(
    map((activeCart) => activeCart.paymentType),
    tap((selected: any) => {
      this.typeSelected = selected?.code;
      this.checkoutStepService.resetSteps();
      this.checkoutStepService.disableEnableStep(
        CheckoutStepType.PAYMENT_DETAILS,
        selected?.code === B2BPaymentTypeEnum.ACCOUNT_PAYMENT
      );
    })
  );

  cartPoNumber$: Observable<string> = this.activeCartService.getActive().pipe(
    filter((po) => po != undefined),
    tap((po: any) => {
      return (this.cartPoNumber = po?.purchaseOrderNumber);
    })
  );

  constructor(
    protected paymentTypeService: CheckoutPaymentTypeFacade,
    protected checkoutStepService: CheckoutStepService,
    protected activatedRoute: ActivatedRoute,
    protected globalMessageService: GlobalMessageService,
    protected activeCartService: ActiveCartFacade,
    protected routingService: RoutingService,
    protected commonService: SloanCommonService
  ) {}

  ngOnInit() {
    this.activeCartService
      .getActive()
      .pipe(take(1))
      .subscribe((res) => {
        if (res.paymentType) {
          this.typeSelected = res.paymentType?.code;
          this.checkoutStepService.resetSteps();
          this.checkoutStepService.disableEnableStep(
            CheckoutStepType.PAYMENT_DETAILS,
            res.paymentType?.code === B2BPaymentTypeEnum.ACCOUNT_PAYMENT
          );
        }
      });
  }

  changeType(code: string): void {
    this.typeSelected = code;
    this.checkoutStepService.disableEnableStep(
      CheckoutStepType.PAYMENT_DETAILS,
      code === B2BPaymentTypeEnum.ACCOUNT_PAYMENT
    );
    this.paymentTypeService.setPaymentType(code);
    this.commonService.selectedPaymentTypes$.next(code);
  }

  next(): void {
    const poNumInput = this.poNumberInput.nativeElement.value;
    if (poNumInput) {
      if (this.typeSelected && poNumInput !== this.cartPoNumber) {
        this.paymentTypeService.setPaymentType(this.typeSelected, poNumInput);
      }
      this.checkoutStepService.next(this.activatedRoute);
    } else {
      this.globalMessageService.add(
        { key: poNumInput ? 'formErrors.poNumberInvalidMessage' : 'formErrors.poNumberRequiredMessage' },
        GlobalMessageType.MSG_TYPE_ERROR
      );
    }
  }

  back(): void {
    this.goToCartDetail();
  }

  goToCartDetail() {
    this.activeCartService
      .getActiveCartId()
      .subscribe((cartId) => {
        this.routingService.go({ cxRoute: 'cartDetails', params: { code: cartId } });
      })
      .unsubscribe();
  }
}
