<div class="col-lg-3 pr-0 float-left mb-3 p-res-0 product-res-grid" (click)="goToDetail($event)">
  <div class="col-lg-12 border-top border-left border-right border-bottom p-2 float-left cursor-pointer">
    <div
      *ngIf="editable"
      class="remember-me custom-control col-md-12 float-left pl-0 pr-0 pb-2 d-flex justify-content-start cursor-pointer"
    >
      <div class="form-check custom-checkbox mb-0">
        <input
          type="checkbox"
          [id]="product.code"
          class="custom-control-input rounded cursor-pointer"
          [disabled]="!product.isOrderable || !product.price || !product.price?.formattedValue"
          [checked]="isChecked(product.code)"
          (change)="changeCheckBox($event)"
        />
        <label class="custom-control-label color-font" [for]="product.code" (click)="$event.stopPropagation()"></label>
      </div>
    </div>

    <div class="col-lg-12 p-0 d-flex product-image justify-content-center mb-3">
      <cx-media [container]="product.images?.PRIMARY" [format]="'thumbnail'" [alt]="product.summary"></cx-media>
    </div>

    <div class="col-lg-12 p-0 d-flex product-name mb-2 justify-content-center">
      <span class="color-font font-size-17 font-text-500 line-height-20">{{ product.code }}</span>
    </div>

    <div class="col-lg-12 p-0 d-flex product-name mb-2 justify-content-center">
      <span class="text-muted" [innerHtml]="product.nameHtml"></span>
    </div>

    <!-- <div class="col-lg-12 p-0 d-flex mb-2 product-name justify-content-center">
      <span class="color-font"
        >{{ 'productList.label.netPrice' | cxTranslate }}: {{ product.price?.formatedNetPrice }}</span
      >
    </div> -->
    <div class="col-lg-12 p-0 d-flex product-name mb-1 justify-content-center">
      <span class="color-font font-text-500 line-height-20" *ngIf="product.price && product.price?.formattedValue">
        {{ product.price?.formattedValue }}</span
      >
      <span class="color-font font-text-500 line-height-20" *ngIf="!product.price || !product.price?.formattedValue"
        >&nbsp;</span
      >
    </div>
  </div>
  <div class="col-lg-12 p-0 add-cart-quote">
    <kn-br-add-to-cart
      [type]="'PGP'"
      [productCode]="product.code"
      [isOrderable]="product.isOrderable && product.price && product.price?.formattedValue"
      class="cursor-pointer"
      (click)="$event.stopPropagation()"
    ></kn-br-add-to-cart>

    <kn-br-add-to-quote
      [type]="'PGP'"
      [productCode]="product.code"
      [isOrderable]="product.isOrderable && product.price && product.price?.formattedValue"
      class="cursor-pointer"
      (click)="$event.stopPropagation()"
    ></kn-br-add-to-quote>
  </div>
</div>
