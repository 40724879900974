<ng-container *ngIf="order$ | async as order">
  <div *ngIf="order.errorMessage.length">
    <div class="close-error" *ngIf="order.errorMessage[0].type === 'ERROR'">
      <ngb-alert type="danger" *ngIf="!closed" (close)="closed = true">
        {{ order.errorMessage[0].message }}
      </ngb-alert>
    </div>
    <div class="close-error" *ngIf="order.errorMessage[0].type === 'WARNING'">
      <ngb-alert type="danger" *ngIf="!closed" (close)="closed = true">
        {{ order.errorMessage[0].message }}
      </ngb-alert>
    </div>
  </div>
  <div class="cx-page-header" *ngIf="!order.errorMessage.length">
    <h1 class="cx-page-title">
      {{ 'checkoutOrderConfirmation.confirmationOfOrder' | cxTranslate }}
      {{ order.sapOrderNumber ? (order.sapOrderNumber | removeLeadingZeros) : (order.code | removeLeadingZeros) }}
    </h1>
  </div>
  <div class="cx-order-confirmation-message">
    <h2>{{ 'checkoutOrderConfirmation.thankYou' | cxTranslate }}</h2>
    <p>{{ 'checkoutOrderConfirmation.copyHasBeenSentToEmail' | cxTranslate }}{{ order.placedBy | removeBaseSiteId }}</p>
    <p>
      {{ 'checkoutOrderConfirmation.termsAndConditionsStart' | cxTranslate }} <a href="https://www.sloan.com/sites/default/files/2023-09/Terms%20of%20Sale%20-%20Sloan.pdf" target="_blank"
        style="color: #86cffc; text-decoration: underline">{{ 'checkoutOrderConfirmation.termsAndConditionsLink' |
        cxTranslate
        }}</a>{{'checkoutOrderConfirmation.termsAndConditionsEnd' | cxTranslate }}
    </p>
  </div>
</ng-container>