<div class="col-12 float-left p-0 pt-2 pb-2">
  <div class="col-1 float-left p-0">
    <label class="color-font font-text-600 mb-1">{{ 'knbrOrderDetails.label.qty' | cxTranslate }}</label>
    <p class="text-muted mb-1">{{ item.quantity }}</p>
  </div>
  <div class="col-2 float-left p-0">
    <label class="color-font font-text-600 mb-1">{{ 'knbrOrderDetails.label.lineItemStatus' | cxTranslate }}</label>
    <p class="text-muted mb-1"></p>
  </div>
  <div class="col-3 float-left p-0">
    <input
      type="number"
      [formControl]="quantity"
      class="form-control w-100"
      (blur)="onchangeQuantity($event)"
      knBrAllowNumber
      [ngClass]="{ 'disabled-input': item.lineItemStatus !== 'Open' }"
    />
    <span class="text-danger" *ngIf="item.lineItemStatus === 'Open'">{{
      'knbrOrderDetails.editOrder.qtyMsg' | cxTranslate
    }}</span>
  </div>
  <div class="col-3 float-left p-0">
    <div class="form-check">
      <div class="row">
        <label class="form-check-label" for="cancelItem">
          {{ 'knbrOrderDetails.editOrder.cancelItem' | cxTranslate }}
        </label>
      </div>
      <div class="row align-items-center">
        <input
          class="form-check-input ml-3 mt-5"
          type="checkbox"
          value=""
          (change)="onChangeCancel()"
          id="cancelItem"
          [formControl]="cancelItem"
          [ngClass]="{ 'disabled-input': item.lineItemStatus !== 'Open' }"
        />
      </div>
    </div>
  </div>
  <div class="col-3 float-left p-0">
    <label class="">{{ 'knBrOrderReturn.reason' | cxTranslate }}</label>
    <ng-select
      [items]="cancelReasonList$ | async"
      [formControl]="reason"
      bindLabel="Text"
      bindValue="Key"
      placeholder="{{ 'quoteCommon.label.status' | cxTranslate }}"
      [searchable]="false"
      [clearable]="false"
      (change)="onChangeReason($event)"
      [loading]="isReasonsLoading"
      bindLabel="Text"
      bindValue="Key"
      [ngClass]="{ 'disabled-input': item.lineItemStatus !== 'Open' }"
    >
    </ng-select>
    <span *ngIf="item.lineItemStatus === 'Open' && cancelItem.value && !reason.value" class="text-danger">
      {{ 'knbrOrderDetails.editOrder.cancelReasonMsg' | cxTranslate }}
    </span>
  </div>
</div>
