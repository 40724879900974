import { NgModule } from '@angular/core';
import { KnBrAccountComponentsModule } from './components/kn-br-account.component.module';
import { UserAccountCoreModule } from '@spartacus/user/account/core';
import { UserAccountOccModule } from '@spartacus/user/account/occ';
import { userAccountTranslationChunksConfig, userAccountTranslations } from './assets/translations/translations';
import { provideConfig, I18nConfig } from '@spartacus/core';
import { UserAccountRootModule } from './root/knbr-account-root.module';

@NgModule({
  imports: [
    KnBrAccountComponentsModule,
    UserAccountCoreModule,
    UserAccountOccModule,
    UserAccountRootModule,
  ],
  providers: [
    provideConfig(<I18nConfig>{
      i18n: {
        resources: userAccountTranslations,
        chunks: userAccountTranslationChunksConfig,
        fallbackLang: 'en',
      },
    }),
  ]
})
export class KnBrAccountModule { }
