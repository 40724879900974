import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { CheckoutConfigService, CheckoutStepService } from '@spartacus/checkout/base/components';
import { CheckoutDeliveryModesFacade } from '@spartacus/checkout/base/root';
import * as _ from 'lodash';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, takeWhile, withLatestFrom } from 'rxjs/operators';
import { SloanDeliveryMode } from 'src/app/models/sloan/sloan-delivery-mode.model';
import { SloanDataSharedService } from 'src/app/services/sloan/sloan-data-shared.service';
import { DATA_SHARED_SERVICE_KEYS } from 'src/constants/sloan/sloan-data.constant';

@Component({
  selector: 'cx-delivery-mode',
  templateUrl: './sloan-delivery-mode.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SloanDeliveryModeComponent implements OnInit, OnDestroy {
  supportedDeliveryModes$: Observable<SloanDeliveryMode[]>;
  selectedDeliveryMode$: Observable<SloanDeliveryMode>;
  continueButtonPressed = false;
  showAccountNumber = false;

  backBtnText = this.checkoutStepService.getBackBntText(this.activatedRoute);

  deliveryModeSub: Subscription;
  activeCartSubscription: Subscription;

  mode: FormGroup = this.fb.group({
    deliveryModeId: ['', Validators.required],
    additionalAccountNumber: [''],
    shippingNotes: [''],
  });

  constructor(
    private fb: FormBuilder,
    private checkoutDeliveryService: CheckoutDeliveryModesFacade,
    private checkoutConfigService: CheckoutConfigService,
    private activatedRoute: ActivatedRoute,
    protected checkoutStepService: CheckoutStepService,
    protected activeCartService: ActiveCartFacade,
    protected sloanDataSharedService: SloanDataSharedService
  ) {}

  ngOnInit() {
    this.supportedDeliveryModes$ = this.checkoutDeliveryService.getSupportedDeliveryModes().pipe(
      filter((deliveryModes: SloanDeliveryMode[]) => !!deliveryModes?.length),
      distinctUntilChanged((current: SloanDeliveryMode[], previous: SloanDeliveryMode[]) => {
        return JSON.stringify(current) === JSON.stringify(previous);
      })
    );

    // Reload delivery modes on error
    this.checkoutDeliveryService
      .getSupportedDeliveryModes()
      .pipe(takeWhile((state: any) => state?.success === false))
      .subscribe((state) => {
        if (state.error && !state.loading) {
          this.checkoutDeliveryService.getSelectedDeliveryModeState();
        }
      });

    this.deliveryModeSub = combineLatest([
      this.supportedDeliveryModes$.pipe(
        withLatestFrom(
          this.checkoutDeliveryService
            .getSelectedDeliveryModeState()
            .pipe(map((deliveryMode: any) => (deliveryMode && deliveryMode.code ? deliveryMode.code : null)))
        )
      ),
      this.activeCartService.getActive(),
      this.activeCartService.isStable(),
    ])
      .pipe(
        map(([[deliveryModes, code], cart, cartLoaded]: [[SloanDeliveryMode[], string], any, boolean]) => {
          if (cartLoaded && cart && cart.code) {
            if (cart.additionalAccountNumber) {
              this.mode.controls.additionalAccountNumber.setValue(cart.additionalAccountNumber);
            }
            if (cart.shippingNotes) {
              this.mode.controls.shippingNotes.setValue(cart.shippingNotes);
            }
            if (cart.deliveryMode && cart.deliveryMode.code) {
              this.showAccountNumber = _.find(deliveryModes, ['code', cart.deliveryMode.code]).showAdditionalField;
              this.setValidationForAccountNumberField();
              this.mode.controls.deliveryModeId.setValue(cart.deliveryMode.code);
              this.sloanDataSharedService.setData(
                DATA_SHARED_SERVICE_KEYS.CHECKOUT_DELIVERY_DETAILS,
                this.mode.getRawValue()
              );
            } else {
              const defaultMode = deliveryModes.find((mode) => mode.isDefault);
              this.showAccountNumber = defaultMode.showAdditionalField;
              this.setValidationForAccountNumberField();
              this.mode.controls.deliveryModeId.setValue(defaultMode.code);
              this.sloanDataSharedService.setData(
                DATA_SHARED_SERVICE_KEYS.CHECKOUT_DELIVERY_DETAILS,
                this.mode.getRawValue()
              );
              this.checkoutDeliveryService.setDeliveryMode(defaultMode.code);
            }
          }
        })
      )
      .subscribe();
  }

  changeMode(deliveryMode: SloanDeliveryMode): void {
    this.showAccountNumber = deliveryMode.showAdditionalField;
    this.setValidationForAccountNumberField();
    this.sloanDataSharedService.setData(DATA_SHARED_SERVICE_KEYS.CHECKOUT_DELIVERY_DETAILS, this.mode.getRawValue());
    this.checkoutDeliveryService.setDeliveryMode(deliveryMode.code);
  }

  setValidationForAccountNumberField() {
    // code for conditional validation on account number field.
    if (this.showAccountNumber) {
      this.mode.controls.additionalAccountNumber.setValidators([Validators.required]);
    } else {
      this.mode.controls.additionalAccountNumber.clearValidators();
      this.mode.controls.additionalAccountNumber.setValue('');
    }
    this.mode.controls.additionalAccountNumber.updateValueAndValidity();
  }

  next(): void {
    this.mode.markAllAsTouched();
    if (this.mode.valid && this.mode.value) {
      console.log(this.mode.value, this.mode.controls.deliveryModeId.value, 'MODE');
      this.sloanDataSharedService.setData(DATA_SHARED_SERVICE_KEYS.CHECKOUT_DELIVERY_DETAILS, this.mode.getRawValue());
      this.checkoutDeliveryService.setDeliveryMode(this.mode.controls.deliveryModeId.value);
      this.deliveryModeSub.add(
        this.checkoutDeliveryService
          .getSupportedDeliveryModesState()
          .pipe(
            takeWhile((state: any) => {
              if (!state.error && !state.loading) {
                this.continueButtonPressed = true;
                this.checkoutStepService.next(this.activatedRoute);
              }
              return state?.success === true || state?.loading === true;
            })
          )
          .subscribe((state) => {})
      );
    }
  }

  back(): void {
    this.checkoutStepService.back(this.activatedRoute);
  }

  get deliveryModeInvalid(): boolean {
    return this.mode.invalid;
  }

  ngOnDestroy(): void {
    if (this.deliveryModeSub) {
      this.deliveryModeSub.unsubscribe();
    }
    if (this.activeCartSubscription) {
      this.activeCartSubscription.unsubscribe();
    }
    this.showAccountNumber = false;
    this.sloanDataSharedService.setData(DATA_SHARED_SERVICE_KEYS.CHECKOUT_DELIVERY_DETAILS, null);
  }
}
