import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';

import { KnBrQuoteDownloadLinkComponent } from './kn-br-quote-download-link.component';

@NgModule({
  declarations: [KnBrQuoteDownloadLinkComponent],
  imports: [
    CommonModule,
    I18nModule,
    ConfigModule.withConfig({
      cmsComponents: {
        KnBrQuoteDownloadLinkComponent: {
          component: KnBrQuoteDownloadLinkComponent,
        },
      },
    } as CmsConfig),
  ],
})
export class KnBrQuoteDownloadLinkModule {}
