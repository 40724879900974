import { TranslationChunksConfig, TranslationResources } from '@spartacus/core';
import { en } from './en';

export const knbrDraftOrderTranslations: TranslationResources = { en };


export const knbrDraftOrderTranslationChunksConfig: TranslationChunksConfig = {
  knbrCart: ['cartCommon', 'cartSearchForm', 'cartResult', 'addToCartMessage', 'button'],
  cart: ['  saveForLaterItems', 'voucher', 'cartItems', 'cartDetails'],
};
