import { Injectable } from '@angular/core';
import { Converter, Occ } from '@spartacus/core';
import { SloanDeliveryMode } from 'src/app/models/sloan/sloan-delivery-mode.model';

@Injectable()
export class SloanDeliveryModeNormalizer implements Converter<Occ.DeliveryMode, SloanDeliveryMode> {
  convert(source: Occ.DeliveryMode, target: SloanDeliveryMode): SloanDeliveryMode {
    if (target === undefined) {
      target = { ...(source as any) };
    }
    return target;
  }
}
