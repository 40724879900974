import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { CmsService } from '@spartacus/core';
import { KnBrCartQuoteSummaryService } from 'src/app/services/kn-br-cart-quote.service';
import { KnBrDraftOrderDownloadLinkComponent } from 'src/feature-libs/kn-br-cart/draft-order/components/kn-br-draft-order/kn-br-draft-order-detail/kn-br-draft-order-download-link/kn-br-draft-order-download-link.component';
import { KnBrDraftOrdersService } from 'src/feature-libs/kn-br-cart/draft-order/core/facade/kn-br-draft-orders.service';

@Component({
  selector: 'knbr-kn-br-draft-order-download-link',
  templateUrl: './sloan-draft-order-download-link.component.html',
})
export class SloanDraftOrderDownloadLinkComponent extends KnBrDraftOrderDownloadLinkComponent implements OnInit {
  constructor(
    protected activeCartService: ActiveCartFacade,
    protected knBrDraftOrderService: KnBrDraftOrdersService,
    protected knBrCartQuoteSummaryService: KnBrCartQuoteSummaryService,
    protected cdr: ChangeDetectorRef,
    protected cmsService: CmsService
  ) {
    super(activeCartService, knBrDraftOrderService, knBrCartQuoteSummaryService, cdr, cmsService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
}
