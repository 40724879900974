import { KnBrCartContextState } from "src/app/store/kn-br-cart-context/kn-br-cart-context.state";
import { KnBrCartContextActions, KnBrCartContextActionTypes } from "../actions/kn-br-cart-context.actions";

export const initialState: KnBrCartContextState = {
  cartContext: undefined,
};

export function knBrCartContextReducer(state = initialState, action: KnBrCartContextActions): KnBrCartContextState {
  switch (action.type) {
    case KnBrCartContextActionTypes.KnBrCartContextLoadSuccessAction:
      return { ...state, cartContext: action.payload };
    case KnBrCartContextActionTypes.KnBrCartContextAddUpdateAction:
      return { ...state, cartContext: action.payload };
    case KnBrCartContextActionTypes.KnBrCartContextRemoveAction:
      return { ...state, cartContext: null };
    case KnBrCartContextActionTypes.KnBrCartContextResetAction:
      return { cartContext: null };
    default:
      return state;
  }
}
