import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AsmService } from '@spartacus/asm/core';
import { AsmConfig } from '@spartacus/asm/root';
import { DirectionService, LaunchDialogService } from '@spartacus/storefront';
import { environment } from 'src/environments/environment';
import { KnBrCustomerSelectionComponent } from 'src/feature-libs/kn-br-asm/components/kn-br-customer-selection/kn-br-customer-selection.component';

@Component({
  selector: 'cx-customer-selection',
  templateUrl: './sloan-customer-selection.component.html',
  styleUrls: ['./sloan-customer-selection.component.scss'],
})
export class SloanCustomerSelectionComponent extends KnBrCustomerSelectionComponent implements OnInit, OnDestroy {
  constructor(protected fb: FormBuilder, protected asmService: AsmService, protected config: AsmConfig, directionService: DirectionService, launchDialogService: LaunchDialogService) {
    super(fb, asmService, config, directionService, launchDialogService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  onSubmit(): void {
    if (this.customerSelectionForm.valid && !!this.selectedCustomer) {
      if (this.selectedCustomer.uid.toLowerCase().includes((environment.BASE_SITE[0] + '|').toLowerCase())) {
        this.submitEvent.emit({ customerId: this.selectedCustomer.uid });
      } else {
        this.submitEvent.emit({ customerId: environment.BASE_SITE[0] + '|' + this.selectedCustomer.uid });
      }
    } else {
      this.customerSelectionForm.markAllAsTouched();
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
