import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SloanReturnOrderDetailsOverviewModule } from './sloan-return-order-details-overview/sloan-return-order-details-overview.module';
import { SloanReturnOrderDetailsItemsModule } from './sloan-return-order-details-items/sloan-return-order-details-items.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, SloanReturnOrderDetailsOverviewModule, SloanReturnOrderDetailsItemsModule],
})
export class SloanReturnOrderDetailModule {}
