import { Location } from '@angular/common';
import { Component, ComponentRef, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbCalendar, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CmsService, ContentSlotData, CxDatePipe, RoutingService } from '@spartacus/core';
import { LaunchDialogService, LAUNCH_CALLER } from '@spartacus/storefront';
import * as _ from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { KnBrQuoteRejectCommentsComponent } from './kn-br-quote-reject-comments/kn-br-quote-reject-comments.component';
import { RemoveLeadingZerosPipe } from 'src/app/shared/pipe/kn-br-custom-pipes/remove-leading-zeros.pipe';
import { UserAccountService } from '@spartacus/user/account/core';
import { KnBrQuote } from 'src/feature-libs/kn-br-cart/quote/root/models/kn-br-quote.model';
import { KnBrDateHelper } from 'src/app/shared/kn-br-date.helper';
import { KnBrQuoteEntryService } from 'src/feature-libs/kn-br-cart/quote/core/facade/kn-br-quote-entry.service';
import { KnBrQuotesService } from 'src/feature-libs/kn-br-cart/quote/core/facade/kn-br-quotes.service';
import { UserAccountFacade } from '@spartacus/user/account/root';

@Component({
  selector: 'kn-br-quote-header',
  templateUrl: './kn-br-quote-header.component.html',
  styleUrls: ['./kn-br-quote-header.component.scss'],
  providers: [CxDatePipe, KnBrDateHelper, RemoveLeadingZerosPipe],
})
export class KnBrQuoteHeaderComponent implements OnInit, OnDestroy {
  quote: KnBrQuote;
  quoteEditForm: UntypedFormGroup;
  subscription = new Subscription();
  statuses$ = this.knBrQuoteService.listStatuses$;
  isLoading$ = this.knBrQuoteService.loading$;
  components: ContentSlotData;
  dateFormat$: Observable<any> = this.currentUserService.get().pipe(
    filter((user: any) => user && !!user.dateFormat),
    map((user: any) => user.dateFormat)
  );
  isQuickEntryProductsLoading$: Observable<boolean> = this.knBrQuoteEntryService.quickEntryLoader$;
  spinnerLoading$: Observable<boolean> = this.knBrQuoteService.showSpinner$;
  showSpinner: void | Observable<ComponentRef<any>>;
  quoteNumberUI: string;
  showDetailSpinner: void | Observable<ComponentRef<any>>;
  spinnerDetailLoading$: Observable<boolean> = this.knBrQuoteService.showDetailSpinner$;
  minDate = undefined;
  constructor(
    protected modalService: NgbModal,
    protected currentUserService: UserAccountFacade,
    public knBrQuoteService: KnBrQuotesService,
    protected fb: UntypedFormBuilder,
    protected ngbCalendar: NgbCalendar,
    protected cmsService: CmsService,
    protected location: Location,
    protected knBrDateHelper: KnBrDateHelper,
    protected routingService: RoutingService,
    protected knBrQuoteEntryService: KnBrQuoteEntryService,
    protected launchDialogService: LaunchDialogService,
    protected vcr: ViewContainerRef,
    protected removeLeadingZerosPipe: RemoveLeadingZerosPipe
  ) {
    this.onSpinnerDetailLoading(true);
  }

  ngOnInit(): void {
    this.subscription.add(
      this.knBrQuoteService.get$.subscribe((quote) => {
        if (quote && Object.keys(quote).length && quote?.code) {
          this.quote = _.cloneDeep(quote);
          this.quoteNumberUI = this.removeLeadingZerosPipe.transform(this.quote.code);
        }
        if (this.quote) {
          this.initForm();
          setTimeout(() => {
            this.patchForm(this.quote);
          }, 100);
        }
      })
    );
    this.subscription.add(
      this.isQuickEntryProductsLoading$.subscribe((isLoading) => {
        this.onSpinnerLoading(isLoading);
      })
    );
    this.subscription.add(
      this.spinnerLoading$.subscribe((isLoading) => {
        this.onSpinnerLoading(isLoading);
      })
    );
    this.subscription.add(
      this.spinnerDetailLoading$.subscribe((isLoading) => {
        if (isLoading === false) {
          this.onSpinnerDetailLoading(false);
        }
      })
    );
    this.disableBackdate();
  }

  disableBackdate() {
    const current = new Date();
    this.minDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate(),
    };
  }

  onSpinnerDetailLoading(isLoading) {
    if (isLoading) {
      this.showDetailSpinner = this.launchDialogService.launch(LAUNCH_CALLER.PLACE_ORDER_SPINNER, this.vcr);
    } else {
      if (this.showDetailSpinner) {
        this.showDetailSpinner
          .subscribe((component) => {
            this.launchDialogService.clear(LAUNCH_CALLER.PLACE_ORDER_SPINNER);
            component.destroy();
          })
          .unsubscribe();
      }
    }
  }
  onSpinnerLoading(isLoading) {
    if (isLoading) {
      this.showSpinner = this.launchDialogService.launch(LAUNCH_CALLER.PLACE_ORDER_SPINNER, this.vcr);
    } else {
      if (this.showSpinner) {
        this.showSpinner
          .subscribe((component) => {
            this.launchDialogService.clear(LAUNCH_CALLER.PLACE_ORDER_SPINNER);
            component.destroy();
          })
          .unsubscribe();
      }
    }
  }

  initForm() {
    this.quoteEditForm = this.fb.group({
      createdBy: [{ value: null, disabled: true }],
      creationDate: [{ value: null, disabled: true }],
      lastUpdatedDate: [{ value: null, disabled: true }],
      code: [{ value: null, disabled: true }],
      jobName: [{ value: null, disabled: this.isDisabled() }, Validators.required],
      quoteValidFrom: [{ value: null, disabled: this.isDisabled() }, Validators.required],
      quoteValidTo: [{ value: null, disabled: this.isDisabled() }, Validators.required],
      rdd: [{ value: null, disabled: this.isDisabled() }],
      shipComplete: [{ value: null, disabled: this.isDisabled() }],
      status: [{ value: null, disabled: true }],
      purchaseOrderNumber: [{ value: null, disabled: this.isDisabled() }],
      headerNotes: [{ value: null, disabled: this.isDisabled() }],
      quoteStatus: null,
      editable: false,
    });
  }

  patchForm(quote: KnBrQuote) {
    let dateFormat = null;
    this.dateFormat$.subscribe((format) => (dateFormat = format)).unsubscribe();
    this.quoteEditForm.patchValue({
      createdBy: quote.createdBy,
      creationDate: quote.creationDate,
      lastUpdatedDate: quote.lastUpdatedDate,
      code: quote.code,
      jobName: quote.jobName,
      quoteValidFrom: quote.quoteValidFrom ? this.knBrDateHelper.getDate(quote.quoteValidFrom) : null,
      quoteValidTo: quote.quoteValidTo ? this.knBrDateHelper.getDate(quote.quoteValidTo) : null,
      rdd: this.knBrDateHelper.getDate(quote.rdd),
      shipComplete: quote.shipComplete,
      quoteStatus: quote.quoteStatus,
      purchaseOrderNumber: quote.purchaseOrderNumber,
      headerNotes: quote.headerNotes,
      status: quote.status,
      editable: quote.editable,
    });
  }

  onSubmit(isRedirect = false) {
    if (this.quoteEditForm.valid && this.quoteEditForm.dirty) {
      const formValues = _.cloneDeep(this.quoteEditForm.getRawValue());
      if (!isRedirect) {
        this.quoteEditForm.get('quoteStatus').patchValue(null);
        formValues.quoteStatus = null;
        this.prepareFormForSubmit(isRedirect, formValues);
      } else {
        const quoteStatus = this.quoteEditForm.get('quoteStatus').value;
        if (quoteStatus === 'SELLER_REJECTED' || quoteStatus === 'BUYER_PENDING_APPROVAL') {
          this.openRejectQuotePopup(isRedirect, formValues);
        } else {
          this.prepareFormForSubmit(isRedirect, formValues);
        }
      }
    } else {
      this.quoteEditForm.markAllAsTouched();
    }
  }

  openRejectQuotePopup(isRedirect, formValues) {
    let modalInstance: any;
    const modalRef: NgbModalRef = this.modalService.open(KnBrQuoteRejectCommentsComponent, {
      centered: true,
      size: 'lg',
    });
    modalInstance = modalRef.componentInstance;
    modalRef.result
      .then((comment) => {
        formValues.headerNotes = comment;
        this.prepareFormForSubmit(isRedirect, formValues);
      })
      .catch((error) => { });
  }

  prepareFormForSubmit(isRedirect, formValues) {
    this.quote.jobName = formValues.jobName;
    this.quote.shipComplete = formValues.shipComplete;
    this.quote.purchaseOrderNumber = formValues.purchaseOrderNumber;
    this.quote.quoteStatus = formValues.quoteStatus;
    this.quote.headerNotes = formValues.headerNotes;

    if (this.quoteEditForm.controls.rdd.value) {
      formValues.rdd = this.knBrDateHelper.formatDate(this.quoteEditForm.controls.rdd.value);
      this.quote.rdd = formValues.rdd;
    }
    if (this.quoteEditForm.controls.quoteValidFrom && this.quoteEditForm.controls.quoteValidFrom.value) {
      formValues.quoteValidFrom = this.knBrDateHelper.formatDate(this.quoteEditForm.controls.quoteValidFrom.value);
    }

    if (this.quoteEditForm.controls.quoteValidTo && this.quoteEditForm.controls.quoteValidTo.value) {
      formValues.quoteValidTo = this.knBrDateHelper.formatDate(this.quoteEditForm.controls.quoteValidTo.value);
    }
    formValues.isRedirect = isRedirect;
    this.quote.quoteValidFrom = formValues.quoteValidFrom;
    this.quote.quoteValidTo = formValues.quoteValidTo;

    this.submitOrder(formValues);
  }

  submitOrder(formValues) {
    this.knBrQuoteService.update(formValues);
  }

  ngOnDestroy(): void {
    this.launchDialogService.clear(LAUNCH_CALLER.PLACE_ORDER_SPINNER);
    this.subscription.unsubscribe();
  }

  goBack() {
    // if (this.quoteEditForm.dirty) {
    //   this.onSubmit(true);
    // } else {
    this.routingService.go({ cxRoute: 'quotes' });
    // }
  }

  isDisabled() {
    this.cmsService
      .getContentSlot('KnBrQuoteHeaderSlot')
      .subscribe((value) => (this.components = value))
      .unsubscribe();

    if (
      (this.components &&
        this.components.components &&
        this.components.components.length &&
        this.components.components[0].flexType === 'KnBrQuoteHeaderComponentReadOnly') ||
      !this.quote.editable
    ) {
      return true;
    }
    return false;
  }

  acceptAndCheckout() {
    const payload = {
      code: this.quote.code,
    };
    this.knBrQuoteService.acceptAndCheckout(payload);
  }
}
