import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FeaturesConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import {
  PromotionsModule,
  IconModule,
  MediaModule,
  ItemCounterModule,
  OutletModule,
  CardModule,
} from '@spartacus/storefront';
import { SloanCartItemListComponent } from './sloan-cart-item-list/sloan-cart-item-list.component';
import { SloanCartItemComponent } from './sloan-cart-item/sloan-cart-item.component';
import { SloanOrderSummaryComponent } from './sloan-order-summary/sloan-order-summary.component';
import { CartCouponModule } from '@spartacus/cart/base/components';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    CartCouponModule,
    ReactiveFormsModule,
    UrlModule,
    NgbModule,
    PromotionsModule,
    I18nModule,
    IconModule,
    MediaModule,
    ItemCounterModule,
    FeaturesConfigModule,
    // ModalModule,
    OutletModule,
    CardModule,
  ],
  declarations: [SloanOrderSummaryComponent, SloanCartItemListComponent, SloanCartItemComponent],
  exports: [SloanOrderSummaryComponent, SloanCartItemListComponent, SloanCartItemComponent],
})
export class SloanCartSharedModule {}
