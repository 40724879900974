import { OccConfig } from '@spartacus/core';

export const sloanProductEndpointsConfig: OccConfig = {
  backend: {
    occ: {
      endpoints: {
        product: {
          details: 'knbr/customer/${customerId}/products/${productCode}?fields=DEFAULT',
        },
        productSearch:
          'knbr/customer/${customerId}/products/search?fields=products(code,name,summary,price(FULL),images(DEFAULT),stock(FULL),averageRating,variantOptions,uom,minOrderQuantity,isOrderable,isShowable),facets,breadcrumbs,pagination(DEFAULT),sorts(DEFAULT),freeTextSearch,currentQuery',
      },
    },
  },
};
