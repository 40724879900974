import { Injectable } from '@angular/core';
import { WindowRef } from '@spartacus/core';
import { CustomerData } from 'src/feature-libs/kn-br-user/kn-br-account/root/model/customer.model';

const KN_BR_CUSTOMER_CONTEXT_STORAGE_KEY = 'kn-br-customer-context';

@Injectable({
  providedIn: 'root',
})
export class KnBrCustomerContextAdapter {
  constructor(private winRef: WindowRef) { }

  get() {
    if (this.winRef.sessionStorage) {
      const context = this.winRef.sessionStorage.getItem(KN_BR_CUSTOMER_CONTEXT_STORAGE_KEY);
      if (context) {
        try {
          return JSON.parse(context);
        } catch (e) { }
      }
    }
    return null;
  }
  set(context: CustomerData) {
    if (this.winRef.sessionStorage) {
      this.winRef.sessionStorage.setItem(KN_BR_CUSTOMER_CONTEXT_STORAGE_KEY, JSON.stringify(context));
    }
  }
  remove() {
    if (this.winRef.sessionStorage) {
      this.winRef.sessionStorage.removeItem(KN_BR_CUSTOMER_CONTEXT_STORAGE_KEY);
    }
  }
}
