import { createFeatureSelector, createSelector } from '@ngrx/store';
import { KN_BR_DRAFT_ORDERS__COMMENT_FEATURE, KnBrDraftOrdersCommentsState } from '../../../../../../app/store/kn-br-draft-order-comments/kn-br-draft-orders-comments.state';

export const getDraftOrderCommentsState = createFeatureSelector<KnBrDraftOrdersCommentsState>(
  KN_BR_DRAFT_ORDERS__COMMENT_FEATURE
);

export const getDraftOrdersComments = createSelector(
  getDraftOrderCommentsState,
  (state: KnBrDraftOrdersCommentsState) => state.DraftOrderComments
);
