import { Location } from '@angular/common';
import { EventService, TranslationService, WindowRef } from '@spartacus/core';
import { CurrentProductService } from '@spartacus/storefront';
import { Component, AfterContentChecked, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { KnBrProductIntroComponent } from 'src/feature-libs/kn-br-product/components/kn-br-product-detail/kn-br-product-intro/kn-br-product-intro.component';

@Component({
  selector: 'kn-br-product-intro',
  templateUrl: './sloan-product-intro.component.html',
  styleUrls: ['./sloan-product-intro.component.scss'],
})
export class SloanProductIntroComponent extends KnBrProductIntroComponent implements OnInit, AfterContentChecked {
  productTitle$: Observable<any[]> = this.product$.pipe(map((p: any) => this.extractProductTitle(p)));
  simpleDescriptionObj: any = {};
  constructor(
    protected currentProductService: CurrentProductService,
    protected knBrTranslationService: TranslationService,
    protected winRef: WindowRef,
    protected location: Location,
    protected eventService: EventService
  ) {
    super(currentProductService, knBrTranslationService, winRef, location, eventService);
  }

  ngOnInit() {
    this.product$.subscribe((res) => {
      if (res && res.classifications && res.classifications.length > 0) {
        res.classifications.forEach((classification) => {
          if (classification.features && classification.features.length > 0) {
            classification.features.forEach((element) => {
              if (element.name === 'Simple Description') {
                this.simpleDescriptionObj['isSimpleDes'] = true;
                this.simpleDescriptionObj['simpleDesValue'] = element.featureValues[0].value;
              }
            });
          }
        });
      }
    });
  }

  ngAfterContentChecked() {
    // super.ngAfterContentChecked();
  }

  protected extractProductTitle(product: any): Observable<any>[] {
    if (!product.classifications || product.classifications.length < 1) {
      return [];
    }

    return _.remove(_.flatMap(product.classifications, 'features'), (obj) => _.includes(obj.code, 'titleseries'));
  }
}
