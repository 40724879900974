// TODO: Add new translations here
export const sloanCheckout = {
  sloanCheckout: {
    label: 'Checkout Progress',
    methodOfPayment: 'Payment Method',
    shippingAddress: 'Shipping Address',
    deliveryMode: 'Shipping Method',
    paymentDetails: 'Payment Details',
    reviewOrder: 'Review Order',
  },
};

export const sloanCheckoutAddress = {
  sloanCheckoutAddress: {
    deliveryAddress: 'Shipping Address',
    selectYourDeliveryAddress: 'Select your Shipping Address',
    defaultDeliveryAddress: 'Default Shipping Address',
    addNewAddress: 'Add New Address',
    shipToThisAddress: 'Ship to this address',
    deliveryAddressSelected: 'Shipping address selected',
  },
};

export const paymentTypes = {
  paymentTypes: {
    title: 'Payment Method',
    paymentType_CARD: 'Credit Card',
    paymentType_ACCOUNT: 'Account',
  },
};

export const sloanAddressBook = {
  sloanAddressBook: {
    addNewDeliveryAddress: 'Add a new Shipping address',
    editDeliveryAddress: 'Edit Shipping address',
    areYouSureToDeleteAddress: 'Are you sure you want to delete this address?',
    addNewAddress: 'Add new address',
    addAddress: 'Add address',
    updateAddress: 'Update address',
    backToAddressList: 'Back to address list',
    defaultDeliveryAddress: 'Default Shipping Address',
    additionalDeliveryAddress: 'Additional Shipping Address {{number}}',
  },
};

export const sloanCheckoutMode = {
  sloanCheckoutMode: {
    deliveryMethod: 'Shipping Method',
    standardDelivery: 'Standard Delivery',
    premiumDelivery: 'Premium Delivery',
  },
};
