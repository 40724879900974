import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';
import { KnBrDateHelper } from 'src/app/shared/kn-br-date.helper';
import { Statuses } from 'src/feature-libs/kn-br-cart/draft-order/root/models/kn-br-draft-order.model';
import { KnBrInvoiceService } from 'src/feature-libs/kn-br-order/core/facade/kn-br-invoice.service';
import { CustomerData } from 'src/feature-libs/kn-br-user/kn-br-account/root/model/customer.model';

@Component({
  selector: 'app-kn-br-invoice-list-search',
  templateUrl: './kn-br-invoice-list-search.component.html',
  styleUrls: ['./kn-br-invoice-list-search.component.scss'],
})
export class KnBrInvoiceListSearchComponent implements OnInit {
  invoiceSearchForm: UntypedFormGroup;
  invoiceStatusList$: Observable<Statuses[]> = this.knBrCommonService.loadInvoiceStatusList$();
  invoiceFilterByList$: Observable<any[]> = this.knBrCommonService.loadInvoiceFilterByList$();
  invoiceDocumentTypeList$: Observable<Statuses[]> = this.knBrCommonService.loadInvoiceDocumentTypeList$();
  documentTypeList: Statuses[] = [];
  filterByList: Statuses[] = [];
  selectedCustomer: CustomerData;
  currentPage = 0;
  selectedStatus = 'open';
  isSearchDisabled$: Observable<boolean> = this.knBrInvoiceService.isSearchDisabled$;
  constructor(
    private fb: UntypedFormBuilder,
    private knBrInvoiceService: KnBrInvoiceService,
    private knBrCommonService: KnBrCommonService,
    public ngbCalendar: NgbCalendar,
    private knBrDateHelper: KnBrDateHelper
  ) { }

  ngOnInit(): void {
    this.knBrCommonService.getInvoiceStatusList();
    this.knBrCommonService.getInvoiceFilterByList();
    this.knBrCommonService.getInvoiceDocumentTypeList();
    this.initForm();
    this.submitForm();
    this.invoiceFilterByList$.subscribe((filterByList) => {
      if (filterByList && filterByList.length > 0) {
        const selectedFilter = filterByList.find((item) => item.Key === 'DOCUMENT_TYPE');
        if (selectedFilter) {
          this.invoiceSearchForm.patchValue({ filterBy: selectedFilter.Key });
        }
      }
    });
  }

  initForm() {
    this.invoiceSearchForm = this.fb.group({
      purchaseOrderNumber: null,
      documentNo: null,
      status: this.selectedStatus,
      filterBy: null,
      documentType: null,
      startDate: null,
      endDate: null,
      fromOpenAmount: null,
      toOpenAmount: null,
      fromOriginalAmount: null,
      toOriginalAmount: null,
      currentPage: this.currentPage,
    });
  }

  submitForm() {
    const formValues = this.invoiceSearchForm.getRawValue();
    if (this.invoiceSearchForm.controls.startDate.value) {
      formValues.startDate = this.knBrDateHelper.formatDate(this.invoiceSearchForm.controls.startDate.value);
    }
    if (this.invoiceSearchForm.controls.endDate.value) {
      formValues.endDate = this.knBrDateHelper.formatDate(this.invoiceSearchForm.controls.endDate.value);
    }
    this.knBrInvoiceService.search(formValues);
  }

  get filterBySelectedValue(): AbstractControl {
    return this.invoiceSearchForm.get('filterBy');
  }

  changeFilterBy() {
    const invoiceFormControls = this.invoiceSearchForm.controls;
    invoiceFormControls.fromOpenAmount.setValue(null);
    invoiceFormControls.toOpenAmount.setValue(null);
    invoiceFormControls.fromOriginalAmount.setValue(null);
    invoiceFormControls.toOriginalAmount.setValue(null);
    invoiceFormControls.startDate.setValue(null);
    invoiceFormControls.endDate.setValue(null);
    invoiceFormControls.documentType.setValue(null);
  }

  resetForm() {
    this.invoiceSearchForm.reset();
    this.initForm();
    this.submitForm();
  }
}
