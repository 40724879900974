import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CartActions, MultiCartState } from '@spartacus/cart/base/core';
import { map } from 'rxjs/operators';
import { KnBrCartContextAdapter } from '../../connectors/kn-br-cart-context.adapter';
import { KnBrCartContextActionTypes, KnBrCartLoadSuccessContext, KnBrCartAddUpdateContext, KnBrCartRemoveContext, KnBrCartResetContext, KnBrCartContextActions } from '../actions/kn-br-cart-context.actions';
import { KnBrQuoteContextService } from 'src/feature-libs/kn-br-cart/quote/core/facade/kn-br-quote-context.service';

@Injectable()
export class KnBrCartContextEffects {

  loadCartContext$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrCartContextActionTypes.KnBrCartContextLoadAction),
    map((x) => {
      const context = this.knBrCartContextAdapter.get();
      return new KnBrCartLoadSuccessContext(context);
    })
  )
  );

  addUpdateCartContext$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrCartContextActionTypes.KnBrCartContextAddUpdateAction),
    map((action: KnBrCartAddUpdateContext) => action.payload),
    map((cartCode: string) => {
      this.knBrQuoteContextService.delete();
      this.store.dispatch(new CartActions.SetActiveCartId(cartCode));
      this.knBrCartContextAdapter.set(cartCode);
    })
  ),
    { dispatch: false }
  );

  removeCartContext$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrCartContextActionTypes.KnBrCartContextRemoveAction),
    map((action: KnBrCartRemoveContext) => action.payload),
    map((cartCode: any) => {
      this.store.dispatch(new CartActions.ClearCartState());
      this.knBrCartContextAdapter.remove();
    })
  ),
    { dispatch: false }
  );

  resetCartContext$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrCartContextActionTypes.KnBrCartContextResetAction),
    map((action: KnBrCartResetContext) => action.payload),
    map((cartCode: any) => {
      this.knBrCartContextAdapter.remove();
    })
  ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions<KnBrCartContextActions>,
    private knBrCartContextAdapter: KnBrCartContextAdapter,
    private store: Store<MultiCartState>,
    private knBrQuoteContextService: KnBrQuoteContextService
  ) { }
}
