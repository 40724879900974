import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CmsService } from '@spartacus/core';
import { Subscription } from 'rxjs';

import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { KnBrCartQuoteSummaryService } from 'src/app/services/kn-br-cart-quote.service';
import { KnBrDraftOrdersService } from 'src/feature-libs/kn-br-cart/draft-order/core/facade/kn-br-draft-orders.service';

@Component({
  selector: 'knbr-kn-br-draft-order-download-link',
  templateUrl: './kn-br-draft-order-download-link.component.html',
  styleUrls: ['./kn-br-draft-order-download-link.component.scss'],
})
export class KnBrDraftOrderDownloadLinkComponent implements OnInit {
  enableCheckboxFlag = false;
  subscription = new Subscription();
  selectedProductsCodes: string[] = [];
  productCodes: string[] = [];
  templateName: string;
  cart$ = this.activeCartService.getActive();
  cartStatus = '';
  constructor(
    protected activeCartService: ActiveCartFacade,
    protected knBrDraftOrderService: KnBrDraftOrdersService,
    protected knBrCartQuoteSummaryService: KnBrCartQuoteSummaryService,
    protected cdr: ChangeDetectorRef,
    protected cmsService: CmsService
  ) { }

  ngOnInit(): void {
    this.subscription.add(
      this.knBrCartQuoteSummaryService.loadDownloadFlag$().subscribe((flag) => {
        this.enableCheckboxFlag = flag;
        this.cdr.markForCheck();
      })
    );
    this.subscription.add(
      this.knBrCartQuoteSummaryService.loadDraftOrderListSelectedProducts$().subscribe((codes) => {
        this.selectedProductsCodes = codes;
        this.cdr.markForCheck();
      })
    );
    this.subscription.add(
      this.knBrDraftOrderService.list$.subscribe((orders) => {
        if (orders) {
          this.productCodes = orders.map((order) => order.code);
        }
        this.cdr.markForCheck();
      })
    );

    this.subscription.add(
      this.cmsService.getCurrentPage().subscribe((page) => {
        if (page) {
          this.templateName = page.template;
        }
      })
    );
    this.cart$.subscribe((res: any) => {
      this.cartStatus = res.cartStatus;
    });
  }

  downloadPdf() {
    if (this.selectedProductsCodes) {
      this.knBrCartQuoteSummaryService.downloadDraftOrder(
        this.selectedProductsCodes.toString(),
        this.selectedProductsCodes.length > 1
      );
    }
    this.knBrCartQuoteSummaryService.setDownloadFlag(false);
  }
  downloadDraftOrderDetailPdf() {
    this.cart$
      .subscribe((response) => {
        if (response) {
          this.knBrCartQuoteSummaryService.downloadDraftOrder(response.code);
        }
      })
      .unsubscribe();
  }
  enableCheckbox() {
    this.knBrCartQuoteSummaryService.setDownloadFlag(true);
  }
  cancelDownload() {
    this.knBrCartQuoteSummaryService.setDownloadFlag(false);
  }

  downloadDraftOrderDetailImages() {
    this.cart$
      .subscribe((response) => {
        if (response) {
          this.knBrCartQuoteSummaryService.downloadDraftOrderImages(response.code);
        }
      })
      .unsubscribe();
  }

  downloadDraftOrderDetailCSV() {
    this.cart$
      .subscribe((response) => {
        if (response) {
          this.knBrCartQuoteSummaryService.downloadDraftOrderCSV(response.code);
        }
      })
      .unsubscribe();
  }

  deleteCart() {
    this.knBrDraftOrderService.delete(null);
  }
}
