import { KnBrEditOrderRequest, KnBrOrderFormsSearchRequest } from '../../root/models/kn-br-order.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { KnBrOrderHistorySearchForm } from 'src/feature-libs/kn-br-order/root/models/kn-br-order.model';

import { KN_BR_CUSTOMER_CURRENT } from '../../../../constants/api.endpoints.constant';
import { KnBrCustomerContextService } from '../../../../app/store/kn-br-customer-context/kn-br-customer-context.service';

@Injectable({
  providedIn: 'root',
})
export class KnBrOrderHistoryAdapter {
  customerNumber: string;
  constructor(
    private endPointService: OccEndpointsService,
    private httpClient: HttpClient,
    protected knBrCustomerContextService: KnBrCustomerContextService
  ) {
    this.knBrCustomerContextService.getActiveCustomerNumber().subscribe((response) => {
      this.customerNumber = response ? response : null;
    });
  }

  search(orderHistorySearch: KnBrOrderHistorySearchForm) {
    const CUSTOMER_NUMBER = this.customerNumber ? this.customerNumber : KN_BR_CUSTOMER_CURRENT;
    return this.httpClient.get(
      this.endPointService.buildUrl('orderList', {
        urlParams: { customerId: CUSTOMER_NUMBER },
        queryParams: orderHistorySearch,
      })
    );
  }

  createReturnOrder(returnItems) {
    const CUSTOMER_NUMBER = this.customerNumber ? this.customerNumber : KN_BR_CUSTOMER_CURRENT;
    // req param for repReturn flag.
    const reqParam = returnItems.repReturn != undefined ? { repReturn: returnItems.repReturn } : null;
    return this.httpClient.post(
      this.endPointService.buildUrl('createReturnOrder', {
        urlParams: { customerId: CUSTOMER_NUMBER },
        queryParams: reqParam,
      }),
      returnItems
    );
  }

  reorder(payload) {
    const order = { orderCode: payload.orderCode };
    return this.httpClient.post(
      this.endPointService.buildUrl('reorder', { urlParams: { customerId: this.customerNumber }, queryParams: order }),
      null
    );
  }

  cancelOrder(params: any) {
    const CUSTOMER_NUMBER = this.customerNumber ? this.customerNumber : KN_BR_CUSTOMER_CURRENT;
    return this.httpClient.post(
      this.endPointService.buildUrl('knbrCancelOrder', {
        urlParams: {
          customerId: CUSTOMER_NUMBER,
          code: params.orderCode,
        },
        queryParams: {
          reason: params.reason,
        },
      }),
      null
    );
  }

  orderFormsProductSearch(payload: KnBrOrderFormsSearchRequest) {
    const CUSTOMER_NUMBER = this.customerNumber ? this.customerNumber : KN_BR_CUSTOMER_CURRENT;
    return this.httpClient.get(
      this.endPointService.buildUrl('orderFormProductSearch', {
        urlParams: { customerId: CUSTOMER_NUMBER },
        queryParams: payload,
      })
    );
  }

  editOrder(params: KnBrEditOrderRequest) {
    const CUSTOMER_NUMBER = this.customerNumber ? this.customerNumber : KN_BR_CUSTOMER_CURRENT;
    return this.httpClient.post(
      this.endPointService.buildUrl('editOrder', {
        urlParams: {
          customerId: CUSTOMER_NUMBER,
          code: params.orderCode,
        },
      }),
      params.editOrderRequest
    );
  }
}
