<div class="cx-order-items" *ngIf="order$ | async as order">
  <h4 class="cx-order-items-header">
    {{ 'checkoutOrderConfirmation.orderItems' | cxTranslate }}
  </h4>

  <ng-container *ngIf="order$ | async as orderPromotions">
    <cx-promotions [promotions]="orderPromotions"></cx-promotions>
  </ng-container>

  <cx-cart-item-list
    [items]="order.orderEntries"
    [readonly]="true"
    [promotionLocation]="promotionLocation"
  ></cx-cart-item-list>
</div>
