/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig, provideDefaultConfig } from '@spartacus/core';
import { OutletModule } from '@spartacus/storefront';
import { KnBrCheckoutOrderSummaryComponent } from './kn-br-checkout-order-summary.component';
import { KnBrCartSharedModule } from 'src/feature-libs/kn-br-cart/base/components/kn-br-cart-shared/kn-br-cart-shared.module';

@NgModule({
  imports: [CommonModule, OutletModule, KnBrCartSharedModule],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        CheckoutOrderSummary: {
          component: KnBrCheckoutOrderSummaryComponent,
        },
      },
    }),
  ],
  declarations: [KnBrCheckoutOrderSummaryComponent],
  exports: [KnBrCheckoutOrderSummaryComponent],
})
export class KnBrCheckoutOrderSummaryModule {}
