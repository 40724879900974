<ng-container *ngIf="order$ | async as order">
  <ng-container *ngIf="!isEditable">
    <ng-container *ngIf="orderActions$ | async as orderActions">
      <ng-container *ngFor="let action of orderActions">
        <ng-container *ngIf="action === 'REORDER'">
          <a class="btn btn-block btn-action" (click)="reOrder(order)">
            {{ 'knbrOrderDetails.actions.reorderAction' | cxTranslate }}
          </a>
        </ng-container>
        <ng-container *ngIf="action === 'CANCEL_ORDER'">
          <a class="btn btn-block btn-action" (click)="openCancelOrderModal()">
            {{ 'knbrOrderDetails.actions.cancelAction' | cxTranslate }}
          </a>
        </ng-container>
        <ng-container *ngIf="action === 'RETURN_ORDER'">
          <a (click)="returnOrder('RMA')" class="btn btn-block btn-action">
            {{ 'knbrOrderDetails.actions.returnRMAAction' | cxTranslate }}
          </a>
        </ng-container>
        <ng-container *ngIf="action === 'REP_RETURN_ORDER'">
          <a (click)="returnOrder('ZRRE')" class="btn btn-block btn-action">
            {{ 'knbrOrderDetails.actions.returnZRREAction' | cxTranslate }}
          </a>
        </ng-container>
        <ng-container *ngIf="action === 'EDIT_ORDER'">
          <a class="btn btn-block btn-primary" (click)="editOrder()">
            {{ 'knbrOrderDetails.actions.editAction' | cxTranslate }}
          </a>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="isEditable">
    <ng-container>
      <a
      class="btn color-knbr-primary btn-block text-white"
      (click)="saveEditOrder()">
        {{ 'knbrOrderDetails.actions.saveEditOrder' | cxTranslate }}
    </a>
    </ng-container>
    <ng-container>
      <button
        [disabled]="editDisable$ | async"
        class="btn btn-block btn-action"
        (click)="cancelEditOrder()"
      >
        {{ 'knbrOrderDetails.actions.cancel' | cxTranslate }}
      </button>
    </ng-container>
  </ng-container>
</ng-container>
