/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { I18nModule, UrlModule } from '@spartacus/core';
import { CarouselComponent } from './carousel.component';
import { IconModule, MediaModule } from '@spartacus/storefront';

@NgModule({
  imports: [CommonModule, RouterModule, IconModule, MediaModule, UrlModule, I18nModule],
  declarations: [CarouselComponent],
  exports: [CarouselComponent],
})
export class CarouselModule {}
