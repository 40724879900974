<div class="modal-header d-flex align-items-center">
  <h4 class="modal-title">{{ 'label.comments' | cxTranslate }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="dismissModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body float-left w-100 p-0">
  <div class="pr-3">
    <div
      class="col-md-12 float-left customersearch-forms login-forms pl-3 modal-comments-list pt-0 pb-0"
      *ngIf="comments$ | async as comments"
    >
      <div class="customersearch-list-table float-left col-lg-12 p-0">
        <div
          class="customersearch-list-titles border-top float-left w-100 pl-2 pr-2 pt-3 pb-3 d-none d-sm-none d-lg-block"
        >
          <div class="col-md-5 padding-res-0 float-left font-text-600 font-sm-size">
            {{ 'label.comments' | cxTranslate }}
          </div>
          <div class="col-md-4 padding-res-0 float-left font-text-600 font-sm-size">
            {{ 'label.date' | cxTranslate }}
          </div>
          <div class="col-md-2 float-left font-text-600 font-sm-size">
            {{ 'label.owner' | cxTranslate }}
          </div>
          <div class="text-center float-left font-text-600 font-sm-size">
            {{ 'label.actions' | cxTranslate }}
          </div>
        </div>
      </div>
      <div class="">
        <div
          class="bg-white customersearch-list-titles table-content float-left font-weight-normal form-group head-notes col-lg-12 pl-0 float-left m-0 p-2 border-bottom d-flex align-items-center"
          *ngFor="let comment of comments"
        >
          <div class="col-md-5 float-left title-wrap font-size color-font">
            <span class="res-titles"> {{ 'label.comments' | cxTranslate }} :</span>
            <span (click)="editComment(comment)" class="cursor-pointer text-break"> {{ comment.text }}</span>
          </div>
          <div class="col-md-4 float-left title-wrap font-size color-font">
            <span class="res-titles"> {{ 'label.date' | cxTranslate }} :</span>
            {{ comment.creationDate | cxDate : 'EEE MMM dd hh:mm:ss zzz yyyy' }}
          </div>

          <div class="col-md-2 float-left title-wrap font-size color-font">
            <span class="res-titles">{{ 'label.owner' | cxTranslate }} :</span>
            {{ comment.author?.name }}
          </div>
          <div class="text-center float-left title-wrap font-size-17 color-font ml-3" *ngIf="!editable">
            <span class="res-titles"> {{ 'label.actions' | cxTranslate }} :</span>
            <span (click)="deleteComment(comment)" class="cursor-pointer"> <i class="far fa-trash-alt"></i> </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <ng-container *ngIf="!editable">
      <div class="col-md-12 float-left customersearch-forms login-forms p-1 m-0">
        <div class="form-group head-notes mt-2 col-lg-12 pl-0 float-left mb-0 p-0">
          <textarea
            class="form-control w-100"
            id="exampleFormControlTextarea1"
            rows="3"
            placeholder="Add Comments"
            [(ngModel)]="text"
          ></textarea>
        </div>
      </div>
      <button type="button" (click)="saveComment()" class="btn btn-primary" [disabled]="!text?.trim().length">
        {{ 'button.save' | cxTranslate }}
      </button>
    </ng-container>

    <button type="button" class="btn btn-outline-primary" (click)="dismissModal()">
      {{ 'button.close' | cxTranslate }}
    </button>
  </div>
</div>
