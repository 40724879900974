export const order = {
  orderHistorySearchForm: {
    label: {
      salesOrderNumber: 'Sales Order Number',
      accountNumber: 'Account Number',
      accountName: 'Account Name',
      purchaseOrderNumber: 'P.O. Number',
      orderType: 'Order Type',
      creditStatus: 'Credit Status',
      deliveryBlock: 'Delivery Block',
      pastDue: 'Past Due',
      productNumber: 'Product Number',
      status: 'Order Status',
      from: 'From',
      to: 'To',
      quickOrderLookup: 'Quick Order Lookup',
    },
  },
  orderHistoryResult: {
    header: {
      orderNumber: 'Order Number',
      purchaseOrderNumber: 'P.O. Number',
      accountNumber: 'Account Number',
      accountName: 'Account Name',
      status: 'Order Status',
      createdDate: 'Order Creation Date',
      orderValue: 'Order Value',
      creditStatus: 'Credit Status',
      deliveryBlock: 'Delivery Block',
      orderType: 'Order Type',
      createdBy: 'Created By',
      pastDue: 'Past Due',
      customer: 'Customer Name',
    },
    widgetHeader: {
      orderNumber: 'Order No.',
      purchaseOrderNumber: 'PO No.',
      createdDate: 'Order Create Date',
    },
    nodataMsg: 'Sorry! Orders data not found',
    showMore: 'Show More',
    detailedSearch: 'Detailed Search',
  },
  knbrOrderDetails: {
    label: {
      orderNumber: 'Order Number',
      orderStatus: 'Order Status',
      orderType: 'Order Type',
      poNumber: 'P.O. Number',
      soldTo: 'Sold To',
      shipTo: 'Ship To',
      orderCreationDate: 'Order Creation Date',
      orderPlacedBy: 'Order Placed By',
      jobNumber: 'JOB Number',
      shippingNotes: 'Shipping Notes',
      reqShipDate: 'Requested Ship Date',
      shippingMethod: 'Shipping Method',
      totalQty: 'Total Qty',
      orderTotal: 'Order Total',
      downloadDocs: 'Download SAP Documents',
      qty: 'Qty',
      atpDate: 'ATP Date',
      plant: 'Plant',
      lineItemStatus: 'Line Item Status',
      quoteId: 'Quote ID',
      openQuantity: 'Open Quantity',
      unitNetPrice: 'Unit Net Price',
      shipments: 'Shipments',
      shipDate: 'Ship Date',
      status: 'Status',
      deliveryNumber: 'Delivery Number',
      carrier: 'Carrier',
      trackingId: 'Tracking ID',
    },
    actions: {
      reorderAction: 'Reorder',
      returnRMAAction: 'Return Order (RMA)',
      returnZRREAction: 'REP Return Order (ZRRE)',
      cancelAction: 'Cancel Order',
      editAction: 'Edit Order',
      saveEditOrder: 'Save Edited Order',
      cancel: 'Cancel',
      backOrderHistory: 'Back To Order History',
    },
    reOrderMessage: {
      success: 'Order has been converted to draft order successfully.',
      error: 'Error occurred while reordering an order.',
    },
    returnOrder: {
      navigationWarning: 'Order is not returnable at the moment',
    },
    addProducts: {
      product: 'Product',
      itemDetails: 'Item Details',
      listPrice: 'List Price',
      qty: 'Qty',
      total: 'Total',
      minorderQty: 'Minimum Order Quantity ',
      productwarnmsg: 'Product cannot be purchased . Please enter another sku',
      skuplaceholder: 'Enter SKU',
      lineNo: 'Line No',
    },
    editOrder: {
      successMsg: 'Your order edited successfully',
      confirmHeading: 'Your order Number is',
      processMessage: 'Your order being processed for the requested changes',
      errorMsg: 'Error while editing order',
      backButton: 'Back To Order History',
      product: 'Product',
      itemDetails: 'Item Details',
      listPrice: 'List Price',
      qty: 'Qty',
      total: 'Total',
      cancelItem: 'Cancel Line Item',
      qtyMsg: 'You can only decrease the quantity',
      addoreditwarnmsg: ' Please add or edit atleast one product',
      cancelReasonMsg: 'Select cancel reason',
      cancelSelectWarning: 'Please select cancel reason for items which are cancelling',
    },
  },
  knbrCancelOrder: {
    cancel: {
      success: 'Your order successfully canceled.',
      error: 'Error occurred while cancelling the order.',
    },
    modalTitle: 'YOU HAVE REQUESTED A CANCELLATION TO THIS ORDER DO YOU WISH TO CONTINUE?',
    modalMsg: 'Please provide your reason for cancellation',
    yesBtn: 'YES',
    noBtn: 'NO',
    confirmation: {
      heading: 'Your Order Successfully Canceled.',
      summary: 'Cancellation Id :  ',
    },
  },
  knBrOrderReturn: {
    create: {
      success: 'Your return created successfully.',
      error: 'Error occurred while creating return order.',
    },
    button: {
      rma: 'Create Return Order',
      zrre: 'Create REP Return Order',
    },
    product: 'Product',
    code: 'Code',
    description: 'Description',
    price: 'Net Price',
    qty: 'Qty',
    returnQty: 'Return Qty',
    returnwarningMsg: 'Restocking fees may apply to the return.',
    reason: 'Reason',
    orderNumber: 'Order Number:',
    notes: 'Reason Notes',
    poNumber: 'P.O. Number:',
    createReturnorder: 'Create Order Return',
    noteserrorMsg: 'Please provide a note stating your reason for the return.',
    confirmation: {
      returnrequest: 'Your Return Request has been Received',
      returnnotes: 'Please log on site after sometime to view this order and its details',
      summary: 'Return Order Summary',
      backbuttonlabel: 'Back to Order Details',
    },
  },
  orderReturnResult: {
    header: {
      rma: 'RMA',
      createdOn: 'Created On',
      purchaseOrderNumber: 'P.O. Number',
      status: 'Status',
      netValue: 'Net Value',
      accountName: 'Account Name',
      accountNumber: 'Account Number',
      customer: 'Customer Name',
      rdd: 'RDD',
    },
    widgetHeader: {
      purchaseOrderNumber: 'PO No.',
      orderValue: 'Order Value',
    },
    nodataMsg: 'Sorry! Orders data not found',
    showMore: 'Show More',
    detailedSearch: 'Detailed Search',
  },
  orderReturnsSearchForm: {
    label: {
      rma: 'RMA',
      poNumber: 'PO Number',
      filterByStatus: 'Filter By Status',
      productNumber: 'Product Number',
      accountNumber: 'Account Number',
      accountName: 'Account Name',
      return: 'Returns',
      from: 'From',
      to: 'To',
    },
    placeholder: {
      rma: 'Enter RMA Number',
      poNumber: 'Enter PO Number',
      status: 'Status',
      productNumber: 'Enter Product Number',
      accountNumber: 'Account Number',
      accountName: 'Account Name',
    },
  },
  knbrReturnOrderDetails: {
    label: {
      returnCreateDate: 'Return Create Date',
      returnNumber: 'Return Number',
      soldTo: 'Sold To',
      PO: 'PO',
      returnReason: 'Return Reason',
      returnNote: 'Return Note',
      totalReturnValue: 'Total Return Value',
      overallStatus: 'Overall Status',
      orderNumber: 'Order Number',
      creditMemoNumber: 'Credit Memo Number',
      returnOrderConfirmation: 'Return Order Confirmation',
      credit: 'Credit',
      customerOriginalPaperwork: 'Customer Original Paperwork',
      product: 'Product',
      code: 'Code',
      description: 'Description',
      qty: 'Qty',
      itemNetPrice: 'Item Net Price',
    },
    heading: {
      followUpDocuments: 'Follow Up Documents',
      attachments: 'Attachments',
    },
    actions: {
      backOrderReturns: 'Back To Returns',
    },
  },
  freightPopUp: {
    heading: 'Freight Terms',
    line1:
      'Your order is close to one of our freight categories, please review your order to determine if additional material needs to be added.',
    line2: '1. Orders valid at $3,500 net or greater ship full freight allowed within the contiguous United States.',
    line3: '2. Any sink will ship full freight allowed at $15,000 net, regardless of order mix.',
    line4:
      '3. Any order containing only vitreous china(fixture or fixture combo) will ship full freight allowed at $10,000 net.',
    line5: 'Click Back to Cart to add to your order or Checkout- to submit your order.',
    backToCartBtn: 'Back to Cart',
    checkOutBtn: 'Checkout',
  },
};
