import { Component, ComponentRef, OnInit, ViewContainerRef, OnDestroy } from '@angular/core';
import { TranslationService } from '@spartacus/core';
import { LaunchDialogService, LAUNCH_CALLER } from '@spartacus/storefront';
import { Observable, Subscription } from 'rxjs';
import { KnBrInvoiceAccountSummaryObject } from 'src/feature-libs/kn-br-order/root/models/kn-br-invoice-account-summary.model';
import { KnBrInvoiceService } from 'src/feature-libs/kn-br-order/core/facade/kn-br-invoice.service';

@Component({
  selector: 'app-kn-br-invoice-account-summary',
  templateUrl: './kn-br-invoice-account-summary.component.html',
  styleUrls: ['./kn-br-invoice-account-summary.component.scss'],
})
export class KnBrInvoiceAccountSummaryComponent implements OnInit, OnDestroy {
  accountSummaryResult$: Observable<KnBrInvoiceAccountSummaryObject> = this.knBrInvoiceService.accountSummary$;
  dueList: Array<any> = [];
  loader$: Observable<boolean> = this.knBrInvoiceService.loader$;
  showSpinner: void | Observable<ComponentRef<any>>;
  subscription = new Subscription();
  constructor(
    public knBrInvoiceService: KnBrInvoiceService,
    protected translation: TranslationService,
    protected launchDialogService: LaunchDialogService,
    protected vcr: ViewContainerRef
  ) { }

  ngOnInit(): void {
    this.getAccountSummary();

    this.dueList.push(
      {
        days: '0-30 Days',
        accountDue: '$0',
        accountNotDue: '$0',
      },
      {
        days: '31-60 Days',
        accountDue: '$0',
        accountNotDue: '$0',
      },
      {
        days: '61-90 Days',
        accountDue: '$0',
        accountNotDue: '$0',
      }
    );
    this.subscription.add(
      this.loader$.subscribe((isLoading) => {
        this.onSpinnerLoading(isLoading);
      })
    );
  }

  onSpinnerLoading(isLoading) {
    if (isLoading) {
      this.showSpinner = this.launchDialogService.launch(LAUNCH_CALLER.PLACE_ORDER_SPINNER, this.vcr);
    } else {
      if (this.showSpinner) {
        this.showSpinner
          .subscribe((component) => {
            this.launchDialogService.clear(LAUNCH_CALLER.PLACE_ORDER_SPINNER);
            component.destroy();
          })
          .unsubscribe();
      }
    }
  }

  getAccountSummary() {
    this.knBrInvoiceService.accountSummary();
  }

  ngOnDestroy() {
    this.launchDialogService.clear(LAUNCH_CALLER.PLACE_ORDER_SPINNER);
  }
}
