export const invoiceSearchForm = {
  header: {
    documents: 'Documents',
    openInvoice: 'Open Invoices',
  },
  label: {
    document: 'Document Number',
    purchaseOrder: 'P.O. Number',
    status: 'Show',
    filterBy: 'Filter By',
    documentType: 'Document Type',
    from: 'From',
    to: 'To',
  },
};
export const invoiceResult = {
  header: {
    document: 'Document Number',
    documentType: 'Document Type',
    purchaseOrder: 'P.O. Number',
    documentDate: 'Document Date',
    status: 'Status',
    orignalAmount: 'Orignal Amount',
    openAmount: 'Open Amount',
    attachment: 'Attachment',
    dueDate: 'Due Date',
    balance: 'Balance',
    docType: 'Doc Type',
    doc: 'Doc #',
  },
  widgetHeader: {
    purchaseOrder: 'PO No.',
  },
  sorting: {
    byDate: 'Document Date',
    byDocType: 'Document Type',
    byDocAmount: 'Original Amount',
    byDocStatus: 'Status',
  },
  nodataMsg: 'Sorry! Invoice data not found',
  showMore: 'Show More',
  sortByMostRecent: 'Date',
  detailedSearch: 'Detailed Search',
};
export const knbrAccountSummary = {
  label: {
    accountNumber: 'Account Number',
    accountName: 'Name',
    address: 'Address',
    accountTodaysBalance: "Today's Balance",
    formattedCreditLine: 'Credit Line',
    accountStatement: 'Account Statement',
    accountDueItems: 'Due Items',
    accountNotDue: 'Not Due',
    pastDueBalance: 'Past Due Balance',
  },
};

export const invoice = {
  invoiceSearchForm,
  invoiceResult,
  knbrAccountSummary,
};
