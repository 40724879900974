import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CmsParagraphComponent } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';

@Component({
  selector: 'kn-br-paragraph',
  templateUrl: './kn-br-paragraph.component.html',
  styleUrls: ['./kn-br-paragraph.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KnBrParagraphComponent implements OnInit {
  componentData$: Observable<CmsParagraphComponent> = this.componentData.data$;
  constructor(private componentData: CmsComponentData<CmsParagraphComponent>) {}
  ngOnInit(): void {}
}
