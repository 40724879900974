import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SloanDataSharedService {
  dataMap = new Map();
  dataMap$: BehaviorSubject<Map<string, any>> = new BehaviorSubject<Map<string, any>>(this.dataMap);
  isDraftOrderCheckOutDisable: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isQuoteCheckOutDisable: BehaviorSubject<boolean> = new BehaviorSubject(false);

  setData(key, value) {
    this.dataMap.set(key, value);
    this.dataMap$.next(this.dataMap);
  }

  setDraftOrderCheckoutDisable(disable: boolean)  {
    this.isDraftOrderCheckOutDisable.next(disable);
  }

  isDraftOrderCheckOutDisabled$(): Observable<boolean> {
    return this.isDraftOrderCheckOutDisable.asObservable();
  }

  setQuoteCheckoutDisable(disable: boolean)  {
    this.isQuoteCheckOutDisable.next(disable);
  }

  isQuoteCheckOutDisabled$(): Observable<boolean> {
    return this.isQuoteCheckOutDisable.asObservable();
  }
}
