export const sloanCustomer360EndpointsConfig = {
  backend: {
    occ: {
      endpoints: {
        orderFormProductSearch:
          'knbr/customer/${customerId}/orderforms?fields=products(code,name,summary,price(FULL),images(DEFAULT),stock(FULL),averageRating,variantOptions,uom,minOrderQuantity,syncResponse,isOrderable,errorMsg,mapPrice),sorts(FULL)',
        orderReturnDetail:
          'knbr/customer/${customerId}/orderReturns/${returnRequestCode}?fields=BASIC,returnEntries(BASIC,refundAmount(formattedValue),orderEntry(basePrice(formattedValue),quantity,product(name,code,baseOptions,images(DEFAULT,galleryIndex)))),deliveryCost(formattedValue),totalPrice(formattedValue),subTotal(formattedValue)',
        orderFormProductAvailability:
          'knbr/customer/${customerId}/orderforms?fields=products(syncResponse),sorts(FULL)',
        orderFormProductSearchKeyword:
          'knbr/customer/${customerId}/orderforms?fields=products(syncResponse),sorts(FULL)&keywords=${productCode}&onlyProductIds=true',
      },
    },
  },
};
