import { SloanAddToCartModule } from './../sloan-add-to-cart/sloan-add-to-cart.module';
import { SloanAddToQuoteModule } from './../sloan-add-to-quote/sloan-add-to-quote.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SloanProductListComponent } from './sloan-product-list.component';
import { RouterModule } from '@angular/router';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { UrlModule, I18nModule, FeaturesConfigModule, ConfigModule, CmsConfig } from '@spartacus/core';
import {
  MediaModule,
  ItemCounterModule,
  ListNavigationModule,
  StarRatingModule,
  IconModule,
  SpinnerModule,
} from '@spartacus/storefront';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SloanProductGridItemComponent } from './sloan-product-grid-item/sloan-product-grid-item.component';
import { KnBrCustomerContextGuard } from 'src/app/shared/guards/kn-br-customer-context.guard';
import { SloanProductListItemComponent } from './sloan-product-list-item/sloan-product-list-item.component';
import { SloanProductScrollComponent } from './sloan-product-scroll/sloan-product-scroll.component';
import { AddToCartModule } from '@spartacus/cart/base/components/add-to-cart';
import { ProductVariantsModule } from '@spartacus/product/variants';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MediaModule,
    AddToCartModule,
    ItemCounterModule,
    ListNavigationModule,
    UrlModule,
    I18nModule,
    StarRatingModule,
    IconModule,
    SpinnerModule,
    InfiniteScrollModule,
    // ViewConfigModule,
    ProductVariantsModule,
    FeaturesConfigModule,
    NgbTooltipModule,
    SloanAddToQuoteModule,
    SloanAddToCartModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CMSProductListComponent: {
          component: SloanProductListComponent,
          guards: [KnBrCustomerContextGuard],
        },
        ProductGridComponent: {
          component: SloanProductListComponent,
          guards: [KnBrCustomerContextGuard],
        },
        SearchResultsListComponent: {
          component: SloanProductListComponent,
          guards: [KnBrCustomerContextGuard],
        },
      },
    } as CmsConfig),
  ],
  declarations: [
    SloanProductListComponent,
    SloanProductGridItemComponent,
    SloanProductListItemComponent,
    SloanProductScrollComponent,
  ],
})
export class SloanProductListModule {}
