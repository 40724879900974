import { SloanFactSheetActions, SloanFactSheetActionTypes } from './sloan-fact-sheet.action';
import { SloanFactSheetState } from './sloan-fact-sheet.state';

export const initialState: SloanFactSheetState = {
  factSheetData: undefined,
  error: undefined,
  isLoading: false,
};

export function SloanFactSheetReducer(state = initialState, action: SloanFactSheetActions): SloanFactSheetState {
  switch (action.type) {
    case SloanFactSheetActionTypes.SloanFactSheetDetailsAction:
      return { ...state, isLoading: true };
    case SloanFactSheetActionTypes.SloanFactSheetDetailsSuccessAction:
      return {
        ...state,
        factSheetData: action.payload,
        isLoading: false,
      };
    case SloanFactSheetActionTypes.SloanFactSheetDetailsFailureAction:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
}
