import { AfterViewInit, Component } from '@angular/core';
import { Event, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { CsAgentAuthService } from "@spartacus/asm/root";
import { CmsService, PageMetaService } from '@spartacus/core';
import { map } from 'rxjs/operators';
import { KnBrSidebarService } from './services/kn-br-sidebar.service';


declare const ga: any;
@Component({
  selector: 'kn-br-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  title = 'brava-customer';
  pageTitle: any;
  Url: any;
  isLoggedInASM = false;
  readonly isNotLoginPage$ = this.cmsService
    .getCurrentPage()
    .pipe(map((page) => page && page.template !== 'KnBrLoginPageTemplate'));
  readonly menuState$ = this.sidebarService.loadMenuState$();
  constructor(
    private cmsService: CmsService,
    private sidebarService: KnBrSidebarService,
    private router: Router,
    protected csAgentAuthService: CsAgentAuthService,
    protected pageMetaService: PageMetaService
  ) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.sidebarService.changeMenuState(false);
      } else if (event instanceof NavigationEnd) {
        let element = document.querySelectorAll('div.ng-sidebar__content')[0];
        setTimeout(() => {
          //element.scrollTop = 0;
          //element.scrollIntoView({ behavior: 'smooth' });
        }, 0);
      }
    });
    this.csAgentAuthService.isCustomerSupportAgentLoggedIn().subscribe((res) => {
      this.isLoggedInASM = res;
    });
  }

  ngAfterViewInit() {
    this.initGoogleAnalyticsPageView();
  }

  private initGoogleAnalyticsPageView() {
    const interval = setInterval(() => {
      if ((window as any).ga && (window as any).ga.getAll) {
        const ga = (window as any).ga;
        const tracker = ga.getAll()[0];
        let count = 0;
        this.router.events.subscribe((event) => {
          if (event instanceof NavigationEnd) {
            count = 0;
            this.Url = event.urlAfterRedirects;
          }
        });
        this.pageMetaService.getMeta().subscribe((meta) => {
          count = count + 1;
          this.pageTitle = meta.title;
          if (this.pageTitle) {
            tracker.set('title', this.pageTitle);
          } else {
            tracker.set('title', document.title);
          }

          if (count === 1) {
            tracker.set('page', this.Url);
            tracker.send('pageview');
          }
        });
        clearInterval(interval);
      }
    }, 50);
  }
  _onBackdropClicked() {
    this.sidebarService.changeMenuState(false);
  }
  _onClosed() {
    this.sidebarService.changeMenuState(false);
  }
  _onOpened() {
    this.sidebarService.changeMenuState(true);
  }
}
