<div class="col-md-12 float-left p-4 border" *ngIf="factSheetData$ | async as factSheetData">
  <div class="col-md-4 p-0 float-left">
    <div class="col-md-12">
      <label class="color-font font-text-600 mb-1">
        {{ 'factSheetHeader.labels.accountNumber' | cxTranslate }}
      </label>
      <p class="text-muted mb-3">{{ factSheetData?.header?.accountNumber }}</p>
    </div>
    <div class="col-md-12">
      <label class="color-font font-text-600 mb-1">
        {{ 'factSheetHeader.labels.name' | cxTranslate }}
      </label>
      <p class="text-muted mb-3">{{ factSheetData?.header?.accountName }}</p>
    </div>
    <div class="col-md-12">
      <label class="color-font font-text-600 mb-1">
        {{ 'factSheetHeader.labels.address' | cxTranslate }}
      </label>
      <p class="text-muted mb-0">
        {{ factSheetData?.header?.address }}
      </p>
    </div>
  </div>
  <div class="col-md-4 p-0 float-left">
    <ng-container *ngIf="factSheetData?.header?.emails?.entry.length"></ng-container>
    <div *ngFor="let entry of factSheetData.header.emails.entry" class="col-md-12">
      <label class="color-font font-text-600 mb-1">
        {{ entry?.key }}
      </label>
      <p class="text-muted mb-3">{{ entry?.value }}</p>
    </div>
  </div>
  <div class="col-md-4 p-0 float-left">
    <div class="col-md-12">
      <label class="color-font font-text-600 mb-1">
        {{ 'factSheetHeader.labels.priceGroup' | cxTranslate }}
      </label>
      <p class="text-muted mb-3">{{ factSheetData?.header?.priceGroup }}</p>
    </div>
    <div class="col-md-12">
      <label class="color-font font-text-600 mb-1">
        {{ 'factSheetHeader.labels.factSheet' | cxTranslate }}
      </label>
      <p class="text-muted mb-3">
        <a><i class="far fa-file-pdf cursor-pointer" aria-hidden="true"></i></a>
      </p>
    </div>
  </div>
</div>
