import { createFeatureSelector, createSelector } from '@ngrx/store';

import { SLOAN_FACT_SHEET_FEATURE, SloanFactSheetState } from './sloan-fact-sheet.state';

export const getFactSheetDataState = createFeatureSelector<SloanFactSheetState>(SLOAN_FACT_SHEET_FEATURE);

export const getFactSheetData = createSelector(
  getFactSheetDataState,
  (state: SloanFactSheetState) => state.factSheetData
);

export const getFacSheetDataLoader = createSelector(
  getFactSheetDataState,
  (state: SloanFactSheetState) => state.isLoading
);
