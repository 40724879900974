import { Injectable } from '@angular/core';
import { Converter, Occ } from '@spartacus/core';
import { KnBrOrderReturnDetails } from 'src/feature-libs/kn-br-order/root/models/kn-br-order.model';

@Injectable()
export class KnBrReturnOrderNormalizer implements Converter<Occ.ReturnRequest, KnBrOrderReturnDetails> {
  convert(source: Occ.ReturnRequest, target: KnBrOrderReturnDetails): KnBrOrderReturnDetails {
    if (target === undefined) {
      target = { ...(source as any) };
    }
    return target;
  }
}
