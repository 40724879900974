import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
// TODO:Spartacus - // TODO:Spartacus - Class UserService has been removed and is no longer part of the public API.
import {
  Country,
  ErrorModel,
  GlobalMessageService,
  GlobalMessageType,
  Region,
  TranslationService,
  UserAddressService,
} from '@spartacus/core';
import { LaunchDialogService, sortTitles } from '@spartacus/storefront';
import { UserProfileFacade } from '@spartacus/user/profile/root';
import * as _ from 'lodash';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { KnBrAddressFormComponent } from 'src/feature-libs/kn-br-order/components/kn-br-customer360/kn-br-address-book/kn-br-address-form/kn-br-address-form.component';

@Component({
  selector: 'sloan-address-form',
  templateUrl: './sloan-address-form.component.html',
  styleUrls: ['./sloan-address-form.component.scss'],
})
export class SloanAddressFormComponent extends KnBrAddressFormComponent implements OnInit, OnDestroy {
  addressForm: FormGroup = this.fb.group({
    country: this.fb.group({
      isocode: [this.defaultCountrySelected, Validators.required],
    }),
    firstName: ['', Validators.required],
    bldg: [''],
    department: [''],
    line1: ['', Validators.required],
    line2: [''],
    town: ['', Validators.required],
    region: this.fb.group({
      isocode: [null, Validators.required],
    }),
    postalCode: ['', Validators.required],
    phone: [''],
    defaultAddress: [false],
    email: [''],
    fax: [''],
    visibleInAddressBook: [true],
  });
  isAddressFormChanged;
  isPreviouslyRejected = false;
  addressCopied: any = null;
  isAddressedChanged = false;
  oneTimeAddress = false;
  selectedAddress: any;
  constructor(
    protected fb: FormBuilder,
    protected userAddressService: UserAddressService,
    protected globalMessageService: GlobalMessageService,
    protected modalService: LaunchDialogService,
    protected translation: TranslationService,
    public activeModal: NgbActiveModal,
    protected userProfileFacade: UserProfileFacade,
  ) {
    super(fb, userAddressService, globalMessageService, translation, modalService, userProfileFacade);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.submitAddressSubscribe = this.submitAddress.asObservable().subscribe((res) => {
      if (this.activeModal) {
        const addrobj = {
          address: this.addressForm.value,
          isOneTime: this.oneTimeAddress,
        };
        this.activeModal.close(addrobj);
      }
    });
    // Fetching countries
    this.countries$ = this.userAddressService.getDeliveryCountries().pipe(
      tap((countries: Country[]) => {
        if (Object.keys(countries).length === 0) {
          this.userAddressService.loadDeliveryCountries();
        }
      })
    );

    // Fetching titles
    this.titles$ = this.userProfileFacade.getTitles().pipe(
      map((titles) => {
        titles.sort(sortTitles);
        const noneTitle = { code: '', name: 'Title' };
        return [noneTitle, ...titles];
      })
    );

    // Fetching regions
    this.regions$ = this.selectedCountry$.pipe(
      switchMap((country) => this.userAddressService.getRegions(country)),
      tap((regions: Region[]) => {
        const regionControl = this.addressForm.get('region.isocode');
        if (regions && regions.length > 0) {
          regionControl.enable();
        } else {
          regionControl.disable();
        }
      })
    );

    if (this.addressData && Object.keys(this.addressData).length !== 0) {
      this.addressForm.patchValue(this.addressData);
      this.countrySelected(this.addressData.country);
      if (this.addressData.region) {
        this.regionSelected(this.addressData.region);
      }
    }
    this.addresses$ = this.userAddressService.getAddresses();

    this.addressForm.valueChanges.subscribe((res) => {
      const newRes = _.cloneDeep(res);
      if (newRes.region && newRes.region.isocodeShort) {
        delete newRes.region.isocodeShort;
      }
      const newValue = JSON.stringify(newRes);
      const oldValue = JSON.stringify(this.addressCopied);
      if (oldValue !== newValue) {
        this.addressCopied = newRes;
        this.isPreviouslyRejected = false;
      }
    });
    // Assigning Values to Edit Address From
    if (this.selectedAddress && this.selectedAddress.editAddress) {
      let isocode = this.selectedAddress.text[2].split(', ')[1];
      this.regions$.subscribe((res) => {
        if (res && res.length > 0) {
          let getIndex = res.findIndex((index) => (index.isocode == isocode));
          this.addressForm.get('region').patchValue(res[getIndex]);
        }
      });
      this.addressForm.patchValue({
        firstName: this.selectedAddress.editAddress ? this.selectedAddress.textBold : '',
        department:
          this.selectedAddress.editAddress && this.selectedAddress.text[1] && this.selectedAddress.text[1].length > 0
            ? this.selectedAddress.text[1]
            : '',
        line2: this.selectedAddress.editAddress ? this.selectedAddress.text[0].split(' ')[0] : '',
        line1: this.selectedAddress.editAddress
          ? this.selectedAddress.text[0].substr(this.selectedAddress.text[0].indexOf(' ') + 1)
          : '',
        town: this.selectedAddress.editAddress ? this.selectedAddress.text[2].split(', ')[0] : '',
        postalCode: this.selectedAddress.editAddress ? this.selectedAddress.text[3].split(', ')[1] : '',
        phone:
          this.selectedAddress.editAddress &&
            this.selectedAddress.text &&
            this.selectedAddress.text[4] &&
            this.selectedAddress.text[4].length > 0
            ? this.selectedAddress.text[4]
            : '',
      });
    }
  }

  handleAddressVerificationResults(results) {
    if (results.decision === 'ACCEPT') {
      this.addressForm.patchValue({
        postalCode: results.validAddressResponse.zipCode,
        town: results.validAddressResponse.cityName,
        region: {
          isocode: results.validAddressResponse.region.isocode,
        },
        line1: results.validAddressResponse.line1,
      });
      if (this.oneTimeAddress) {
        const addrobj = {
          address: this.addressForm.value,
          isOneTime: true,
        };
        this.activeModal.close(addrobj);
      }
      this.submitAddress.emit(this.addressForm.value);
    } else if (results.decision === 'REJECT' && !this.isPreviouslyRejected) {
      this.isPreviouslyRejected = true;
      this.oneTimeAddress = false;
      // TODO: Workaround: allow server for decide is titleCode mandatory (if yes, provide personalized message)
      if (results.errors.errors.some((error: ErrorModel) => error.subject === 'titleCode')) {
        this.globalMessageService.add({ key: 'addressForm.titleRequired' }, GlobalMessageType.MSG_TYPE_ERROR);
      } else {
        this.globalMessageService.add({ key: 'addressForm.invalidAddress' }, GlobalMessageType.MSG_TYPE_WARNING);
      }
    } else if (results.decision === 'REJECT' && this.isPreviouslyRejected) {
      if (this.oneTimeAddress) {
        const addrobj = {
          address: this.addressForm.value,
          isOneTime: true,
        };
        this.activeModal.close(addrobj);
      }
      this.submitAddress.emit(this.addressForm.value);
      this.isPreviouslyRejected = false;
    }
  }

  verifyAddress(): void {
    if (this.addressForm.valid) {
      if (this.addressForm.get('region')?.value.isocode) {
        this.regions$.pipe(take(1)).subscribe((regions) => {
          const obj = regions.find((region) => region.isocode === this.addressForm.controls['region'].value.isocode);
          Object.assign(this.addressForm.value.region, {
            isocodeShort: obj?.isocodeShort,
          });
        });
      }
      if (this.addressForm.dirty) {
        this.subscription.add(
          this.userAddressService.verifyAddress(this.addressForm.value).subscribe((value) => {
            this.handleAddressVerificationResults(value);
          })
        );
      } else {
        this.submitAddress.emit(undefined);
      }
    } else {
      this.addressForm.markAllAsTouched();
    }
  }

  oneTimeUse() {
    this.oneTimeAddress = true;
    if (this.addressForm.valid) {
      this.verifyAddress();
    } else {
      this.addressForm.markAllAsTouched();
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.isPreviouslyRejected = false;
    this.oneTimeAddress = false;
  }
}
