import { SloanOrderFormSearchComponent } from './sloan-order-form-search.component';
import { ConfigModule, CmsConfig, I18nModule } from '@spartacus/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [SloanOrderFormSearchComponent],
  imports: [
    CommonModule,
    I18nModule,
    FormsModule,
    ReactiveFormsModule,
    ConfigModule.withConfig({
      cmsComponents: {
        KnBrSearchProductComponent: {
          component: SloanOrderFormSearchComponent,
        },
      },
    } as CmsConfig),
  ],
})
export class SloanOrderFormSearchModule {}
