import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, queueScheduler } from 'rxjs';
import { observeOn, tap } from 'rxjs/operators';
import { KnBrQuoteReference } from 'src/feature-libs/kn-br-cart/quote/root/models/kn-br-quote.model';
import { StateWithCartContext } from 'src/app/store/kn-br-cart-context/kn-br-cart-context.state';
import { KnBrCartLoadContext, KnBrCartAddUpdateContext, KnBrCartRemoveContext, KnBrCartResetContext } from '../store/actions/kn-br-cart-context.actions';
import { getCartContext } from '../store/selectors/kn-br-cart-context.selector';

@Injectable({
  providedIn: 'root',
})
export class KnBrCartContextService {
  pdpQuoteRef: KnBrQuoteReference;
  constructor(private store: Store<StateWithCartContext>) {
    this.store.dispatch(new KnBrCartLoadContext());
  }

  get$: Observable<string> = this.store.pipe(
    select(getCartContext),
    observeOn(queueScheduler),
    tap((context) => {
      if (!context) {
        this.store.dispatch(new KnBrCartLoadContext());
      }
    })
  );
  set(context: string) {
    this.store.dispatch(new KnBrCartAddUpdateContext(context));
  }

  delete() {
    this.store.dispatch(new KnBrCartRemoveContext());
  }
  reset() {
    this.store.dispatch(new KnBrCartResetContext());
  }
}
