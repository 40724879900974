<div class="col-md-12 float-left customersearch-forms login-forms p-0 customer-home-forms">
  <form [formGroup]="quoteSearchForm" (ngSubmit)="submitForm()">
    <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3 p-res-0">
      <input
        type="text"
        class="form-control w-100"
        placeholder="{{ 'quoteSearchForm.placeholder.suggestedQuote' | cxTranslate }}"
        formControlName="quoteNumber"
      />
      <span class="highlight"></span>
      <label class="text-muted">{{ 'quoteSearchForm.label.quoteNo' | cxTranslate }}</label>
    </div>

    <div class="form-group mt-3 col-lg-3 pl-0 float-left mb-3 p-res-0">
      <input
        type="text"
        class="form-control w-100"
        placeholder="{{ 'quoteSearchForm.label.accountNumber' | cxTranslate }}"
        formControlName="accountNumber"
      />
      <span class="highlight"></span>
      <label class="text-muted">{{ 'quoteSearchForm.label.accountNumber' | cxTranslate }} </label>
    </div>

    <div class="form-group mt-3 col-lg-3 pl-0 float-left mb-3 p-res-0">
      <input
        type="text"
        class="form-control w-100"
        placeholder="{{ 'quoteSearchForm.label.accountName' | cxTranslate }}"
        formControlName="accountName"
      />
      <span class="highlight"></span>
      <label class="text-muted">{{ 'quoteSearchForm.label.accountName' | cxTranslate }}</label>
    </div>

    <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3 p-res-0">
      <input
        type="text"
        class="form-control w-100"
        placeholder="{{ 'quoteSearchForm.label.customerPO' | cxTranslate }}"
        formControlName="purchaseOrderNumber"
        knBrAlphaNumericOnly
        maxlength="32"
      />
      <span class="highlight"></span>
      <label class="text-muted">{{ 'quoteSearchForm.label.customerPO' | cxTranslate }} </label>
    </div>

    <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3 p-res-0">
      <input
        type="text"
        class="form-control w-100"
        placeholder="{{ 'quoteSearchForm.label.jobNameNumber' | cxTranslate }}"
        formControlName="jobName"
        type="text"
      />
      <span class="highlight"></span>
      <label class="text-muted">{{ 'quoteSearchForm.label.jobNameNumber' | cxTranslate }}</label>
    </div>

    <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3 p-res-0">
      <ng-select
        [items]="status$ | async"
        formControlName="status"
        bindLabel="Text"
        bindValue="Key"
        placeholder="{{ 'quoteCommon.label.status' | cxTranslate }}"
        [searchable]="false"
        [clearable]="false"
        bindLabel="Text"
        bindValue="Key"
      >
      </ng-select>
      <span class="highlight"></span>
      <label class="text-muted">{{ 'quoteCommon.label.status' | cxTranslate }}</label>
    </div>

    <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3 p-res-0">
      <input
        type="text"
        class="form-control w-100"
        placeholder="{{ 'quoteSearchForm.label.productNumber' | cxTranslate }}"
        formControlName="productCode"
        type="email"
      />
      <span class="highlight"></span>
      <label class="text-muted">{{ 'quoteSearchForm.label.productNumber' | cxTranslate }}</label>
    </div>

    <div class="col-lg-12 p-0 float-left">
      <div class="col-lg-2 pl-0 p-res-0 float-left">
        <button type="submit" [disabled]="isSearchDisabled$ | async" class="w-100 mt-1 mt-sm-0 btn btn-primary">
          {{ 'button.search' | cxTranslate }}
        </button>
      </div>
      <div class="col-lg-2 pl-0 p-res-0 float-left">
        <button type="button" (click)="resetForm()" class="w-100 mt-1 mt-sm-0 btn btn-outline-primary">
          {{ 'button.reset' | cxTranslate }}
        </button>
      </div>
      <div class="col-lg-2 p-0 p-res-0 float-right mt-1 mt-sm-0">
        <cx-page-slot position="KnBrQuoteListButtonSlot"></cx-page-slot>
      </div>
    </div>
  </form>
</div>
