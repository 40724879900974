<div #dialog>
  <!-- Modal Header -->

  <div class="modal-header d-flex align-items-center">
    <h3 class="modal-title">{{ 'actionBar.label.quickEntry' | cxTranslate }}</h3>
    <button type="button" class="close" aria-label="Close" (click)="dismissModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body float-left w-100 pl-3 pr-3 pt-0 pb-0">
    <div class="col-md-12 float-left customersearch-forms login-forms p-0">
      <div class="form-group head-notes mt-2 col-lg-12 pl-0 float-left mb-0 p-0">
        <textarea
          class="form-control w-100"
          id="exampleFormControlTextarea1"
          (ngModelChange)="onChange($event)"
          [(ngModel)]="productNo"
          rows="8"
          placeholder="Add multiple products/style with comma separator or separated by new line."
        ></textarea>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn color-knbr-primary color-white hover-link"
      (click)="save()"
      [disabled]="isDisabled"
    >
      {{ 'button.save' | cxTranslate }}
    </button>
  </div>
</div>
