import { Component, OnInit } from '@angular/core';
import { RoutingService } from '@spartacus/core';
import { OrderDetailsService } from '@spartacus/order/components';

@Component({
  selector: 'knbr-kn-br-order-acknowledge',
  templateUrl: './kn-br-order-acknowledge.component.html',
  styleUrls: ['./kn-br-order-acknowledge.component.scss'],
})
export class KnBrOrderAcknowledgeComponent implements OnInit {
  orderCode: string;
  type: string;
  rma: string;
  orderNumber: string;
  order$ = this.orderDetailsService.getOrderDetails();

  constructor(protected routingService: RoutingService, protected orderDetailsService: OrderDetailsService) {
    this.routingService
      .getRouterState()
      .subscribe((state) => {
        if (state && state.state && state.state.params && state.state.params.orderCode) {
          this.orderCode = state.state.params.orderCode;
          this.type = state.state.params.type;
          this.rma = state.state.params.rma;
          this.orderNumber = state.state.params.orderNumber;
        }
      })
      .unsubscribe();
  }

  ngOnInit(): void {}

  returnRequestDetails() {
    this.routingService.go({ cxRoute: 'returnRequestDetails', params: { code: this.rma } });
  }

  goToOrderDetails() {
    this.routingService.go({ cxRoute: 'orderDetails', params: { code: this.orderCode } });
  }

  goToOrderList() {
    this.routingService.go({ cxRoute: 'orders' });
  }
}
