import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Address,
  ADDRESS_NORMALIZER,
  ADDRESS_SERIALIZER,
  ADDRESS_VALIDATION_NORMALIZER,
  AddressValidation,
  ConverterService,
  Occ,
  OccEndpointsService,
  UserAddressAdapter,
  HttpParamsURIEncoder,
  UserAddressConnector,
} from '@spartacus/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { KnBrAddress } from 'src/feature-libs/kn-br-order/root/models/kn-br-order.model';
import { KnBrCustomerContextService } from 'src/app/store/kn-br-customer-context/kn-br-customer-context.service';
import { SLOAN_ADDRESS_VERIFICATION } from 'src/constants/sloan/sloan-api.endpoints.constant';
import { environment } from 'src/environments/environment';

@Injectable()
export class SloanOccUserAddressAdapter implements UserAddressAdapter {
  customerId: string;
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converter: ConverterService,
    private customerContextService: KnBrCustomerContextService,
    protected userAddressConnector: UserAddressConnector
  ) {
    this.customerContextService.get$.subscribe((response) => {
      this.customerId = response && response.customerNumber ? response.customerNumber : null;
    });
  }

  loadAll(userId: string): Observable<Address[]> {
    const url = this.occEndpoints.buildUrl('addresses', {
      urlParams: { customerId: this.customerId },
    });
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.get<Occ.AddressList>(url, { headers }).pipe(
      catchError((error: any) => throwError(error)),
      map((addressList) => addressList.addresses),
      this.converter.pipeableMany(ADDRESS_NORMALIZER)
    );
  }

  add(userId: string, address: Address): Observable<{}> {
    const url = this.occEndpoints.buildUrl('addresses', {
      urlParams: { customerId: this.customerId },
    });
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    address = this.converter.convert(address, ADDRESS_SERIALIZER);

    return this.http.post(url, address, { headers }).pipe(catchError((error: any) => throwError(error)));
  }

  update(userId: string, addressId: string, address: Address): Observable<{}> {
    const url = this.occEndpoints.buildUrl('addressDetail', {
      urlParams: { customerId: this.customerId, addressId },
    });
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    address = this.converter.convert(address, ADDRESS_SERIALIZER);

    return this.http.patch(url, address, { headers }).pipe(catchError((error: any) => throwError(error)));
  }

  verify(userId: string, address: Address): Observable<AddressValidation> {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'text/xml',
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      responseType: 'text' as 'json',
    };
    let body = new URLSearchParams();
    const knBrAddress = this.converter.convert(address, ADDRESS_SERIALIZER) as KnBrAddress;
    const params = {
      'LicenseInfo.RegisteredUser.UserID': environment.STRIKE_IRON_USER_ID,
      'LicenseInfo.RegisteredUser.Password': environment.STRIKE_IRON_PASSWORD,
      'LicenseInfo.UnregisteredUser.EmailAddress': address.email,
      'VerifyAddressUSA.AddressLine1': address.line2 + ' ' + address.line1,
      'VerifyAddressUSA.AddressLine2': '',
      'VerifyAddressUSA.CityStateZIPCode': address.town + ' ' + address.region.isocode + ' ' + address.postalCode,
      'VerifyAddressUSA.Firm': '',
      'VerifyAddressUSA.Urbanization': '',
      'VerifyAddressUSA.Casing': '',
    };
    body.set('LicenseInfo.RegisteredUser.UserID', params['LicenseInfo.RegisteredUser.UserID']);
    body.set('VerifyAddressUSA.AddressLine1', params['VerifyAddressUSA.AddressLine1']);
    body.set('VerifyAddressUSA.CityStateZIPCode', params['VerifyAddressUSA.CityStateZIPCode']);
    return this.http.post(this.buildStrikeIronUrl(SLOAN_ADDRESS_VERIFICATION, params), body, httpOptions).pipe(
      catchError((error: any) => throwError(error)),
      this.converter.pipeable(ADDRESS_VALIDATION_NORMALIZER)
    );
  }

  buildStrikeIronUrl(endpoint: string, queryParams?: object) {
    if (queryParams) {
      const httpParamsOptions = { encoder: new HttpParamsURIEncoder() };
      let httpParams = new HttpParams(httpParamsOptions);
      Object.keys(queryParams).forEach((key) => {
        const value = queryParams[key];
        if (value !== undefined) {
          if (value === null) {
            httpParams = httpParams.delete(key);
          } else {
            httpParams = httpParams.set(key, value);
          }
        }
      });
      const params = httpParams.toString();
      if (params.length) {
        endpoint += '?' + params;
      }
    }
    return endpoint;
  }

  delete(userId: string, addressId: string): Observable<{}> {
    const url = this.occEndpoints.buildUrl('addressDetail', {
      urlParams: { customerId: this.customerId, addressId },
    });
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.delete(url, { headers }).pipe(catchError((error: any) => throwError(error)));
  }
}
