import { RoutingConfig } from '@spartacus/core';

export const defaultDraftOrderRoutingConfig: RoutingConfig = {
  routing: {
    routes: {
      carts: {
        paths: ['carts'],
      },
      createCart: {
        paths: ['copy-cart/:code/:name', 'create-cart'],
      },
      cartDetails: {
        paths: ['cart-detail/:orderCode'],
        paramsMapping: { orderCode: 'code' },
      },
    },
  },
};
