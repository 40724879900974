<div class="col-lg-12 p-0" *ngIf="invoiceResult$ | async as invoiceResult">
  <ng-container *ngIf="sorts$ | async as sorts">
    <div class="row d-flex pt-4">
      <div class="col-sm-12 col-md-8 col-lg-10 justify-content-start" *ngIf="paginate$ | async as paginate">
        <kn-br-browsing
          [paginate]="paginate"
          [recordsShown]="invoiceResult.length"
          recordType="{{ 'label.invoices' | cxTranslate }}"
        >
        </kn-br-browsing>
      </div>
      <div class="col-sm-12 col-md-4 col-lg-2 justify-content-end">
        <cx-sorting
          [sortOptions]="sorts"
          [sortLabels]="getSortLabels() | async"
          (sortListEvent)="changeSortCode($event)"
          [selectedOption]="sortType"
          placeholder="{{ 'invoiceResult.sortByMostRecent' | cxTranslate }}"
        ></cx-sorting>
      </div>
    </div>
  </ng-container>
  <div class="row counter-wrapper" *ngIf="invoiceResult && invoiceResult.length && !(isLoader$ | async)">
    <div class="customersearch-list-table float-left col-lg-12 mt-3">
      <div
        class="customersearch-list-titles border-top float-left w-100 pl-2 pr-2 pt-3 pb-3 d-none d-sm-none d-lg-block"
      >
        <div class="col-md-2 padding-res-0 float-left font-text-600 pl-0 font-sm-size">
          {{ 'invoiceResult.header.document' | cxTranslate }}
        </div>
        <div class="col-md-2 padding-res-0 float-left font-text-600 pl-0 font-sm-size">
          {{ 'invoiceResult.header.documentType' | cxTranslate }}
        </div>
        <div class="col-md-2 padding-res-0 float-left font-text-600 pl-0 font-sm-size">
          {{ 'invoiceResult.header.purchaseOrder' | cxTranslate }}
        </div>
        <div class="col-md-2 float-left font-text-600 pl-0 font-sm-size">
          {{ 'invoiceResult.header.documentDate' | cxTranslate }}
        </div>
        <div class="col-md-1 float-left font-text-600 pl-0 font-sm-size">
          {{ 'invoiceResult.header.orignalAmount' | cxTranslate }}
        </div>
        <div class="col-md-1 float-left font-text-600 pl-0 font-sm-size">
          {{ 'invoiceResult.header.openAmount' | cxTranslate }}
        </div>

        <div class="col-md-1 float-left font-text-600 pl-0 font-sm-size">
          {{ 'invoiceResult.header.status' | cxTranslate }}
        </div>
        <div class="col-md-1 text-center float-left font-text-600 pl-0 font-sm-size">
          {{ 'invoiceResult.header.attachment' | cxTranslate }}
        </div>
      </div>

      <div
        class="
          bg-white
          customersearch-list-titles
          table-content
          border-top
          float-left
          w-100
          p-2
          font-weight-normal
          d-flex
          align-items-center
        "
        *ngFor="let invoice of invoiceResult"
      >
        <div class="col-md-2 float-left title-wrap pl-0 font-sm-size color-font">
          <span class="res-titles"> {{ 'invoiceResult.header.document' | cxTranslate }}:</span>
          {{ invoice.documentNumber | removeLeadingZeros }}
        </div>
        <div class="col-md-2 float-left title-wrap pl-0 font-sm-size color-font">
          <span class="res-titles"> {{ 'invoiceResult.header.documentType' | cxTranslate }}:</span>
          {{ invoice.documentType?.name }}
        </div>
        <div class="col-md-2 float-left title-wrap pl-0 font-sm-size color-font">
          <span class="res-titles">{{ 'invoiceResult.header.purchaseOrder' | cxTranslate }}:</span
          >{{ invoice.purchaseOrder }}
        </div>

        <div class="col-md-2 float-left title-wrap pl-0 font-sm-size color-font">
          <span class="res-titles"> {{ 'invoiceResult.header.documentDate' | cxTranslate }}:</span>
          {{ splitDate(invoice.date) | date: 'MM/dd/yyyy' }}
        </div>
        <div class="col-md-1 float-left title-wrap pl-0 font-sm-size color-font">
          <span class="res-titles"> {{ 'invoiceResult.header.orignalAmount' | cxTranslate }}:</span>
          {{ invoice.formattedAmount }}
        </div>
        <div class="col-md-1 float-left title-wrap pl-0 font-sm-size color-font">
          <span class="res-titles"> {{ 'invoiceResult.header.openAmount' | cxTranslate }}:</span>
          {{ invoice.formattedOpenAmount }}
        </div>
        <div class="col-md-1 float-left title-wrap pl-0 font-sm-size color-font">
          <span class="res-titles"> {{ 'invoiceResult.header.status' | cxTranslate }}:</span>
          {{ invoice.status | uppercase }}
        </div>
        <div class="col-md-1 text-center float-left title-wrap pl-0 font-sm-size color-font">
          <span class="res-titles"> {{ 'invoiceResult.header.attachment' | cxTranslate }}:</span>
          <a (click)="downloadInvoiceItemPDF(invoice.documentNumber)"
            ><i class="far fa-file-pdf" aria-hidden="true"></i
          ></a>
        </div>
      </div>
    </div>
    <ng-container *ngIf="paginate$ | async as paginate">
      <div class="d-flex justify-content-center w-100 mt-3">
        <div
          *ngIf="paginate.currentPage < paginate.totalPages - 1 && !(isLoader$ | async)"
          (click)="loadMore(paginate.currentPage)"
          class="
            btn btn-block btn-loadmore
            align-btn
            show-more-product
            font-size
            color-white
            border-0
            float-left
            w-auto
            mt-0
            mr-3
          "
        >
          {{ 'invoiceResult.showMore' | cxTranslate }}
        </div>
      </div>
    </ng-container>
  </div>
  <div class="alert-info p-3 rounded mt-3" *ngIf="invoiceResult && invoiceResult.length < 1 && !(isLoader$ | async)">
    {{ 'invoiceResult.nodataMsg' | cxTranslate }}
  </div>
</div>
