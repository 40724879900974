import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConverterService, OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { SloanDataSharedService } from 'src/app/services/sloan/sloan-data-shared.service';
import { KnBrCustomerContextService } from 'src/app/store/kn-br-customer-context/kn-br-customer-context.service';
import { DATA_SHARED_SERVICE_KEYS } from 'src/constants/sloan/sloan-data.constant';
import { KnBrOccCheckoutDeliveryAdapter } from 'src/feature-libs/kn-br-checkout/base/occ/adapters/kn-br-occ-checkout-delivery.adapter';

@Injectable()
export class SloanOccCheckoutDeliveryAdapter extends KnBrOccCheckoutDeliveryAdapter {
  deliveryDetails: any;

  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converter: ConverterService,
    protected customerContextService: KnBrCustomerContextService,
    protected sloanDataSharedService: SloanDataSharedService
  ) {
    super(http, occEndpoints, converter, customerContextService);
    this.sloanDataSharedService.dataMap$.subscribe((response: Map<string, any>) => {
      this.deliveryDetails = response.get(DATA_SHARED_SERVICE_KEYS.CHECKOUT_DELIVERY_DETAILS);
    });
  }

  public setMode(userId: string, cartId: string, deliveryModeId: string): Observable<any> {
    return this.http.put(
      this.getCartEndpoint(userId) + cartId + '/deliverymode',
      {},
      {
        params: {
          deliveryModeId,
          shippingNotes:
            this.deliveryDetails && this.deliveryDetails.shippingNotes ? this.deliveryDetails.shippingNotes : '',
          additionalAccountNumber:
            this.deliveryDetails && this.deliveryDetails.additionalAccountNumber
              ? this.deliveryDetails.additionalAccountNumber
              : '',
        },
      }
    );
  }
}
