import { inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { GlobalMessageService, GlobalMessageType, LoggerService, normalizeHttpError, RoutingService } from '@spartacus/core';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { KnBrCustomerAddUpdateContext } from '../../../../../../app/store/kn-br-customer-context/kn-br-customer-context.actions';
import { KnBrCustomerContextService } from '../../../../../../app/store/kn-br-customer-context/kn-br-customer-context.service';
import { KnBrQuoteContextService } from '../../facade/kn-br-quote-context.service';
import { KnBrQuoteEntryService } from '../../facade/kn-br-quote-entry.service';
import { KnBrQuoteAddUpdateContext } from '../actions/kn-br-quote-context.actions';
import {
  KnBrQuoteCheckoutFailure,
  KnBrQuoteCheckoutSuccess,
  KnBrQuoteCopyFailure,
  KnBrQuoteCopySuccess,
  KnBrQuoteCreateFailure,
  KnBrQuoteCreateSuccess,
  KnBrQuoteLoadFailure,
  KnBrQuoteLoadStatusesFailure,
  KnBrQuoteLoadStatusesSuccess,
  KnBrQuoteLoadSuccess,
  KnBrQuotesActionTypes,
  KnBrQuoteSearchFailure,
  KnBrQuoteSearchSuccess,
  KnBrQuotesSearchActions,
  KnBrQuoteStatusesLoad,
  KnBrQuoteUpdateFailure,
  KnBrQuoteUpdateSuccess,
} from '../actions/kn-br-quotes.action';
import { KnBrQuotesService } from '../../facade/kn-br-quotes.service';
import { KnBrCartContextService } from 'src/feature-libs/kn-br-cart/base/core/facade/kn-br-cart-context.service';
import { KnBrQuotesAdapter } from '../../connectors/kn-br-quotes.adapter';

@Injectable()
export class KnBrQuotesEffects {
  customerData: any;
  protected logger = inject(LoggerService);
  searchQuotes$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrQuotesActionTypes.KnBrQuoteSearchAction),
    mergeMap((action) =>
      this.quotesAdapter.search(action.payload).pipe(
        map((data: any) => {
          return new KnBrQuoteSearchSuccess(data);
        }),
        catchError((error: Error) => {
          this.messageService.add({ key: 'knBrMessages.error' }, GlobalMessageType.MSG_TYPE_ERROR);
          return of(new KnBrQuoteSearchFailure(error));
        })
      )
    )
  )
  );

  copyQuote$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrQuotesActionTypes.KnBrQuoteCopyAction),
    mergeMap((action) =>
      this.quotesAdapter.copy(action.payload).pipe(
        map((data: any) => new KnBrQuoteCopySuccess(data)),
        catchError((error: Error) => {
          return of(new KnBrQuoteCopyFailure(error));
        })
      )
    )
  )
  );

  copySuccess$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrQuotesActionTypes.KnBrQuoteCopySuccessAction),
    map((action) => {
      this.messageService.add({ key: 'quoteCommon.copyMessage.success' }, GlobalMessageType.MSG_TYPE_CONFIRMATION);
      this.knBrQuoteService.get(action.payload.code);
      this.routeService.go({ cxRoute: 'quoteDetails', params: { code: action.payload.code } });
    })
  ),
    { dispatch: false }
  );

  copyFailure$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrQuotesActionTypes.KnBrQuoteCopyFailureAction),
    map((action) => this.messageService.add({ key: 'quoteCommon.copyMessage.error' }, GlobalMessageType.MSG_TYPE_ERROR))
  ),
    { dispatch: false }
  );

  createQuote$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrQuotesActionTypes.KnBrQuoteCreateAction),
    mergeMap((action) =>
      this.quotesAdapter.create(action.payload).pipe(
        map((data: any) => {
          data.orderToolsProducts = action.payload.orderToolsProducts;
          return new KnBrQuoteCreateSuccess(data);
        }),
        catchError((error: Error) => {
          return of(new KnBrQuoteCreateFailure(error));
        })
      )
    )
  )
  );

  createSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrQuotesActionTypes.KnBrQuoteCreateSuccessAction),
    map((action) => {
      const productList = this.activatedRoute.snapshot.queryParamMap.get('productList');
      const orderToolsProducts = action.payload.orderToolsProducts;
      if (productList || orderToolsProducts) {
        const parsedProducts = JSON.parse(productList);
        if (parsedProducts && parsedProducts.length > 0) {
          const parsedProductsCopy = { request: parsedProducts, quoteId: action.payload.quoteNo };
          this.knBrQuoteEntryService.addEntries(parsedProductsCopy);
        } else if (orderToolsProducts && orderToolsProducts.length > 0) {
          const orderToolsReq = { request: orderToolsProducts, quoteId: action.payload.quoteNo };
          this.knBrQuoteEntryService.addEntries(orderToolsReq);
        }
      } else {
        const productCode = this.activatedRoute.snapshot.queryParamMap.get('p');
        const quantity = this.activatedRoute.snapshot.queryParamMap.get('q');
        if (productCode) {
          const quoteEntry = {
            quoteId: action.payload.quoteNo,
            code: productCode,
            quantity: quantity ? parseInt(quantity, 10) : 1,
          };
          this.knBrQuoteEntryService.add(quoteEntry);
        }
        this.routeService.go({ cxRoute: 'quoteDetails', params: { code: action.payload.quoteNo } });
        this.knBrQuoteService.get(action.payload.quoteNo);
      }
      this.messageService.add({ key: 'quoteCommon.createMessage.success' }, GlobalMessageType.MSG_TYPE_CONFIRMATION);
    })
  ),
    { dispatch: false }
  );

  createFailure$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrQuotesActionTypes.KnBrQuoteCreateFailureAction),
    map((action) =>
      this.messageService.add({ key: 'quoteCommon.createMessage.error' }, GlobalMessageType.MSG_TYPE_ERROR)
    )
  ),
    { dispatch: false }
  );

  updateQuote$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrQuotesActionTypes.KnBrQuoteUpdateAction),
    mergeMap((action) =>
      this.quotesAdapter.update(action.payload).pipe(
        map((data: any) => {
          data.isRedirect = action.payload.isRedirect;
          return new KnBrQuoteUpdateSuccess(data);
        }),
        catchError((error) => {
          this.knBrQuoteService.get(action.payload.code);
          return of(new KnBrQuoteUpdateFailure(normalizeHttpError(error, this.logger)));
        })
      )
    )
  )
  );

  updateSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrQuotesActionTypes.KnBrQuoteUpdateSuccessAction),
    map((action) => {
      if (action.payload.isRedirect) {
        this.knBrQuoteContextService.delete();
        this.routeService.goByUrl('quotes');
      } else {
        this.knBrQuoteService.get(action.payload.quoteNo);
      }
      if (action.payload && action.payload?.warningMessage) {
        this.messageService.add({ raw: action.payload?.warningMessage }, GlobalMessageType.MSG_TYPE_WARNING);
      } else {
        this.messageService.add({ key: 'quoteCommon.updateMessage.success' }, GlobalMessageType.MSG_TYPE_CONFIRMATION);
      }
    })
  ),
    { dispatch: false }
  );

  updateFailure$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrQuotesActionTypes.KnBrQuoteUpdateFailureAction),
    map((action) => {
      const errors = (action.payload?.message || action.payload?.details || []) as Array<any>;
      if (errors.length > 0) {
        errors.forEach((error) => {
          const type = error.type.toLowerCase();
          if (type.indexOf('warning') !== -1) {
            this.messageService.add({ raw: error.message }, GlobalMessageType.MSG_TYPE_WARNING);
          } else if (type.indexOf('error') !== -1) {
            this.messageService.add({ raw: error.message }, GlobalMessageType.MSG_TYPE_ERROR);
          }
        });
      } else {
        this.messageService.add({ key: 'quoteCommon.updateMessage.error' }, GlobalMessageType.MSG_TYPE_ERROR);
      }
    })
  ),
    { dispatch: false }
  );

  get$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrQuotesActionTypes.KnBrQuoteLoadAction),
    mergeMap((action) =>
      this.quotesAdapter.get(action.payload).pipe(
        mergeMap((data: any) => {
          return action.refreshContext
            ? [
              new KnBrCustomerAddUpdateContext(this.customerData),
              new KnBrQuoteLoadSuccess(data),
              new KnBrQuoteStatusesLoad(data.code),
              new KnBrQuoteAddUpdateContext(data.code),
            ]
            : [
              new KnBrQuoteLoadSuccess(data),
              new KnBrQuoteStatusesLoad(data.code),
              new KnBrQuoteAddUpdateContext(data.code),
            ];
        }),
        catchError((error: Error) => {
          return of(new KnBrQuoteLoadFailure(error));
        })
      )
    )
  )
  );

  getStatuses$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrQuotesActionTypes.KnBrQuoteStatusesLoadAction),
    mergeMap((action) =>
      this.quotesAdapter.getStatuses(action.payload).pipe(
        map((data: any) => new KnBrQuoteLoadStatusesSuccess(data)),
        catchError((error: Error) => {
          return of(new KnBrQuoteLoadStatusesFailure(error));
        })
      )
    )
  )
  );

  acceptAndCheckoutQuote$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrQuotesActionTypes.KnBrQuoteCheckoutAction),
    mergeMap((action) =>
      this.quotesAdapter.acceptAndCheckout(action.payload).pipe(
        map((data: any) => new KnBrQuoteCheckoutSuccess(data)),
        catchError((error: Error) => {
          return of(new KnBrQuoteCheckoutFailure(error));
        })
      )
    )
  )
  );

  acceptAndCheckoutSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrQuotesActionTypes.KnBrQuoteCheckoutSuccessAction),
    map((action) => {
      this.knBrCartContextService.set(action.payload.code);
      this.messageService.add({ key: 'quoteCommon.checkoutMessage.success' }, GlobalMessageType.MSG_TYPE_CONFIRMATION);
      this.routeService.go({ cxRoute: 'cartDetails', params: { code: action.payload.code } });
    })
  ),
    { dispatch: false }
  );

  acceptAndCheckoutFailure$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrQuotesActionTypes.KnBrQuoteCheckoutFailureAction),
    map((action) =>
      this.messageService.add({ key: 'quoteCommon.checkoutMessage.error' }, GlobalMessageType.MSG_TYPE_ERROR)
    )
  ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions<KnBrQuotesSearchActions>,
    private quotesAdapter: KnBrQuotesAdapter,
    private messageService: GlobalMessageService,
    private activatedRoute: ActivatedRoute,
    private knBrQuoteEntryService: KnBrQuoteEntryService,
    private knBrQuoteService: KnBrQuotesService,
    private routeService: RoutingService,
    private knBrQuoteContextService: KnBrQuoteContextService,
    private knBrCustomerContext: KnBrCustomerContextService,
    private knBrCartContextService: KnBrCartContextService
  ) {
    this.knBrCustomerContext.get$.subscribe((res) => {
      this.customerData = res;
    });
  }
}
