import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { OrderDetailsService } from '@spartacus/order/components';
import { KnBrCartContextService } from 'src/feature-libs/kn-br-cart/base/core/facade/kn-br-cart-context.service';
import { KnBrOrderConfirmationOverviewComponent } from 'src/feature-libs/kn-br-order/components/kn-br-order-confirmation/kn-br-order-confirmation-overview/kn-br-order-confirmation-overview.component';

@Component({
  selector: 'cx-order-confirmation-overview',
  templateUrl: './sloan-order-confirmation-overview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SloanOrderConfirmationOverviewComponent
  extends KnBrOrderConfirmationOverviewComponent
  implements OnInit, OnDestroy {
  constructor(
    protected orderDetailsService: OrderDetailsService,
    protected knBrCartContextService: KnBrCartContextService
  ) {
    super(orderDetailsService, knBrCartContextService);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnDestroy() {
    // super.ngOnDestroy();
  }
}
