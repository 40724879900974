import { FacetCollapseState, FacetService, ProductFacetService } from '@spartacus/storefront';
import { HttpUrlEncodingCodec } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Facet } from '@spartacus/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

/**
 * Provides access to the facets as well as their UI state. The UI state
 * represents user related changes on the facets, such as expanding or
 * collapsing a facet group or expanding the number of _visible_ facet values.
 */
@Injectable({
  providedIn: 'root',
})
export class SloanFacetService extends FacetService {
  constructor(protected productFacetService: ProductFacetService) {
    super(productFacetService);
  }

  getLinkParams(query: string): { [key: string]: string } {
    return {
      // to avoid encoding issues with facets that have space (' ') in their name,
      // we replace the decoded '+' back to empty space ' '.
      query: new HttpUrlEncodingCodec().decodeValue(query.replace(/\+/g, ' ')),
    };
  }
}
