import { RoutingService, GlobalMessageService, GlobalMessageType } from '@spartacus/core';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { OrderDetailsService } from '@spartacus/order/components';
import { Observable, Subscription } from 'rxjs';
import * as _ from 'lodash';
import {
  KnBrEditOrderRequestEntryInput,
  KnBrAddRequestEntry,
  KnBrEditOrderRequest,
} from 'src/feature-libs/kn-br-order/root/models/kn-br-order.model';
import { KnBrOrderHistoryService } from 'src/feature-libs/kn-br-order/core/facade/kn-br-order-history.service';

@Component({
  selector: 'knbr-kn-br-order-details-items',
  templateUrl: './kn-br-order-details-items.component.html',
  styleUrls: ['./kn-br-order-details-items.component.scss'],
})
export class KnBrOrderDetailsItemsComponent implements OnInit, OnDestroy {
  order$: Observable<any>;
  subscription = new Subscription();
  isOrderEditable$: Observable<boolean> = this.knBrOrderHistoryService.isEditOrderable$();
  editItems: KnBrEditOrderRequestEntryInput[] = [];
  addedProductItems: KnBrAddRequestEntry[] = [];
  editRequest: KnBrEditOrderRequest;
  @Input()
  orderCode: string;
  subscrption = new Subscription();
  constructor(
    protected orderDetailsService: OrderDetailsService,
    protected knBrOrderHistoryService: KnBrOrderHistoryService,
    protected knBrCommonService: KnBrCommonService,
    protected routingService: RoutingService,
    protected messageService: GlobalMessageService
  ) {
    this.routingService
      .getRouterState()
      .subscribe((state) => {
        if (state && state.state && state.state.params && state.state.params.orderCode) {
          this.orderCode = state.state.params.orderCode;
          this.editRequest = {
            orderCode: this.orderCode,
            editOrderRequest: {
              editRequestEntryInputs: this.editItems,
              addRequestEntryInputs: this.addedProductItems,
            },
          };
        }
      })
      .unsubscribe();
    this.subscription.add(
      this.knBrOrderHistoryService.loadSaveEditOrderRequest$().subscribe((saveRequest) => {
        if (saveRequest) {
          if (this.editRequest && this.editRequest.orderCode) {
            const canEditOrder = this.editRequest.editOrderRequest.editRequestEntryInputs
              .filter((editItem) => editItem.quantity === 0 && !editItem.cancelReason)
              .map((item) => item.orderEntryNumber)
              .join(',');
            if (canEditOrder) {
              this.messageService.add(
                { key: 'knbrOrderDetails.editOrder.cancelSelectWarning' },
                GlobalMessageType.MSG_TYPE_WARNING
              );
            } else {
              this.knBrOrderHistoryService.editOrder(this.editRequest);
            }
          }
        }
      })
    );
  }

  ngOnInit(): void {
    this.order$ = this.orderDetailsService.getOrderDetails();
    this.knBrCommonService.getCancelOrderStatusList();
  }

  onQuantityChange(eventData) {
    const index = _.findIndex(this.editItems, { orderEntryNumber: eventData.orderEntryNumber });

    // Replace item at index using native splice
    if (index >= 0) {
      this.editItems[index] = eventData;
    } else {
      this.editItems.push(eventData);
    }
    this.editRequest = {
      orderCode: this.orderCode,
      editOrderRequest: {
        editRequestEntryInputs: this.editItems,
        addRequestEntryInputs: this.addedProductItems,
      },
    };
  }

  onInputChange(eventData) {
    this.addedProductItems = eventData;
    this.editRequest = {
      orderCode: this.orderCode,
      editOrderRequest: {
        editRequestEntryInputs: this.editItems,
        addRequestEntryInputs: this.addedProductItems,
      },
    };
  }

  goBack() {
    this.routingService.go({ cxRoute: 'orders' });
  }

  ngOnDestroy() {
    this.knBrOrderHistoryService.setOrderEditable(false);
    this.knBrOrderHistoryService.setSaveEditOrderRequest(false);
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
