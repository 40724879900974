import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, queueScheduler } from 'rxjs';
import { observeOn } from 'rxjs/operators';

import { SloanQuoteEdit } from './sloan-quotes.action';
import { getQuotesSpinner } from './sloan-quotes.selector';
import { StateWithQuote } from './sloan-quotes.state';

@Injectable({
  providedIn: 'root',
})
export class SloanQuotesService {
  constructor(private store: Store<StateWithQuote>) {}
  showSpinner$: Observable<boolean> = this.store.pipe(select(getQuotesSpinner), observeOn(queueScheduler));

  edit(payload) {
    this.store.dispatch(new SloanQuoteEdit(payload));
  }
}
