<cx-spinner class="overlay" *ngIf="isUpdating$ | async"> </cx-spinner>
<section class="col-md-12 p-0 login-wrapper d-flex justify-content-center align-items-center">
  <div class="container fluid p-0">
    <form *ngIf="token$ | async as token" (ngSubmit)="onSubmit(token)" [formGroup]="form">
      <div class="col-md-12 float-left knbr-login-forms mt-4 d-flex justify-content-center p-0">
        <div class="col-lg-5 col-sm-6 login-forms p-4 login-section">
          <div class="col-md-12 p-0 float-left">
            <cx-page-slot position="SiteLogo"></cx-page-slot>
          </div>
          <div class="form-group col-md-12 p-0 float-left mt-5">
            <i class="fas fa-lock fa-lg pr-1" aria-hidden="true"></i>
            <input
              required
              class="form-control w-100"
              formControlName="password"
              placeholder="{{ 'register.password.placeholder' | cxTranslate }}"
              type="password"
            />
            <span class="highlight"></span>
            <cx-form-errors [control]="form.get('password')"></cx-form-errors>
          </div>

          <div class="form-group col-md-12 p-0 float-left m-0 mb-lg-5 mb-md-3">
            <i class="fas fa-lock fa-lg pr-1" aria-hidden="true"></i>
            <input
              required
              class="form-control w-100"
              placeholder="{{ 'register.confirmPassword.placeholder' | cxTranslate }}"
              formControlName="passwordConfirm"
              type="password"
            />
            <span class="highlight"></span>
            <cx-form-errors [control]="form.get('passwordConfirm')"></cx-form-errors>
          </div>
          <div class="login-btns col-md-12 float-left p-0 p-res-0">
            <button [disabled]="form.disabled" class="btn btn-primary btn-lg col-md-12 m-0">
              {{ 'register.resetPassword' | cxTranslate }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</section>
