import { KnBrOrderReturn, KnBrReturnOrderSearchForm } from '../../../feature-libs/kn-br-order/root/models/kn-br-order.model';

export const KN_BR_ORDER_RETURN_FEATURE = 'kn-br-order-return';

export interface KnBrOrderReturnState {
  returnRequests?: KnBrOrderReturn[];
  pagination?: any;
  sorts?: any;
  error?: Error;
  searchRequest?: KnBrReturnOrderSearchForm;
  isLoading?: boolean;
  isSearchDisabled: boolean;
  order?: any;
}

export interface StateWithKnBrOrderReturn {
  [KN_BR_ORDER_RETURN_FEATURE]: KnBrOrderReturnState;
}
