import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CartActions } from '@spartacus/cart/base/core';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import {
  KnBrDraftOrderCommentsActions,
  KnBrDraftOrderCommentsActionTypes,
  KnBrDraftOrderCommentsCreateFailure,
  KnBrDraftOrderCommentsLoad,
  KnBrDraftOrderCommentsLoadFailure,
  KnBrDraftOrderCommentsLoadSuccess,
  KnBrDraftOrderCommentsUpdateFailure,
} from '../actions/kn-br-draft-orders-comments.action';
import { KnBrDraftOrdersCommentsAdapter } from '../../connectors/kn-br-draft-orders-comments.adapter';

@Injectable()
export class KnBrDraftOrdersCommentsEffects {

  loadDraftOrder$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrDraftOrderCommentsActionTypes.KnBrDraftOrderCommentsLoadAction),
    mergeMap((action) =>
      this.DraftOrdersCommentsAdapter.get(action.entryNumber).pipe(
        map((data: any) => {
          return new KnBrDraftOrderCommentsLoadSuccess(data);
        }),
        catchError((error: Error) => {
          return of(new KnBrDraftOrderCommentsLoadFailure(error));
        })
      )
    )
  )
  );

  createDraftOrderComment$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrDraftOrderCommentsActionTypes.KnBrDraftOrderCommentsCreateAction),
    mergeMap((action) =>
      this.DraftOrdersCommentsAdapter.create(action.payload).pipe(
        mergeMap((data: any) => [
          new KnBrDraftOrderCommentsLoad(action.payload.entryNumber),
          new CartActions.ResetCartDetails(),
        ]),
        catchError((error: Error) => {
          return of(new KnBrDraftOrderCommentsCreateFailure(error));
        })
      )
    )
  )
  );

  createSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrDraftOrderCommentsActionTypes.KnBrDraftOrderCommentsCreateSuccessAction),
    map((action) => new KnBrDraftOrderCommentsLoad(0))
  ),
    { dispatch: false }
  );

  createFailure$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrDraftOrderCommentsActionTypes.KnBrDraftOrderCommentsCreateFailureAction),
    map((action) => {
      // Todo
      return;
    })
  ),
    { dispatch: false }
  );


  updateDraftOrderComment$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrDraftOrderCommentsActionTypes.KnBrDraftOrderCommentsUpdateAction),
    mergeMap((action) =>
      this.DraftOrdersCommentsAdapter.update(action.payload).pipe(
        map((data: any) => new KnBrDraftOrderCommentsLoad(action.payload.entryNumber)),
        catchError((error: Error) => {
          return of(new KnBrDraftOrderCommentsUpdateFailure(error));
        })
      )
    )
  )
  );

  deleteDraftOrderComment$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrDraftOrderCommentsActionTypes.KnBrDraftOrderCommentsDeleteAction),
    mergeMap((action) =>
      this.DraftOrdersCommentsAdapter.delete(action.payload).pipe(
        mergeMap((data: any) => [
          new KnBrDraftOrderCommentsLoad(action.payload.entryNumber),
          new CartActions.ResetCartDetails(),
        ]),
        catchError((error: Error) => {
          return of(new KnBrDraftOrderCommentsUpdateFailure(error));
        })
      )
    )
  )
  );

  constructor(
    private actions$: Actions<KnBrDraftOrderCommentsActions>,
    private DraftOrdersCommentsAdapter: KnBrDraftOrdersCommentsAdapter
  ) { }
}
