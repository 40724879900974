/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { ChangeDetectionStrategy, Component, OnInit, Optional } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { CheckoutDeliveryAddressFacade, CheckoutDeliveryModesFacade } from '@spartacus/checkout/base/root';
import {
  Address,
  FeatureConfigService,
  getLastValueSync,
  GlobalMessageService,
  GlobalMessageType,
  TranslationService,
  UserAddressService,
} from '@spartacus/core';
import { Card, getAddressNumbers } from '@spartacus/storefront';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { KnBrCheckoutStepService } from '../services/kn-br-checkout-step.service';
import { KnBrAddressService } from 'src/feature-libs/kn-br-order/core/facade/kn-br-address.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { KnBrAddressFormComponent } from 'src/feature-libs/kn-br-order/components/kn-br-customer360/kn-br-address-book/kn-br-address-form/kn-br-address-form.component';

export interface CardWithAddress {
  card: Card;
  address: Address;
}

@Component({
  selector: 'cx-delivery-address',
  templateUrl: './kn-br-checkout-delivery-address.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KnBrCheckoutDeliveryAddressComponent implements OnInit {
  protected busy$ = new BehaviorSubject<boolean>(false);

  cards$: Observable<CardWithAddress[]>;
  isUpdating$: Observable<boolean>;
  addressFormOpened = false;
  doneAutoSelect = false;
  OtshAddress: any;
  OtshAddressCard: any;

  get isGuestCheckout(): boolean {
    return !!getLastValueSync(this.activeCartFacade.isGuestCart());
  }

  get backBtnText(): string {
    return this.checkoutStepService.getBackBntText(this.activatedRoute);
  }

  deliveryAddress$: Observable<Address | undefined> = this.checkoutDeliveryAddressFacade.getDeliveryAddressState().pipe(
    filter((state) => !state.loading && !state.error),
    map((state) => state.data)
  );

  get selectedAddress$(): Observable<Address | undefined> {
    return this.checkoutDeliveryAddressFacade.getDeliveryAddressState().pipe(
      filter((state) => !state.loading),
      map((state) => state.data),
      distinctUntilChanged((prev, curr) => prev?.id === curr?.id)
    );
  }

  /**
   * TODO: (#CXSPA-53) Remove featureConfigService from constructor in 6.0
   */
  constructor(
    protected userAddressService: UserAddressService,
    protected checkoutDeliveryAddressFacade: CheckoutDeliveryAddressFacade,
    protected activatedRoute: ActivatedRoute,
    protected modalService: NgbModal,
    protected knBrAddressService: KnBrAddressService,
    protected translationService: TranslationService,
    protected activeCartFacade: ActiveCartFacade,
    protected checkoutStepService: KnBrCheckoutStepService,
    protected checkoutDeliveryModesFacade: CheckoutDeliveryModesFacade,
    protected globalMessageService: GlobalMessageService,
    @Optional() protected featureConfigService?: FeatureConfigService
  ) { }

  ngOnInit(): void {
    this.loadAddresses();
    this.cards$ = this.createCards();
    this.isUpdating$ = this.createIsUpdating();
    this.getOneTimeAddress();
  }

  getOneTimeAddress() {
    this.userAddressService.getAddresses();
    this.deliveryAddress$.subscribe((address) => {
      this.OtshAddress = address;
      this.OtshAddressCard = this.getCardContent(address, { id: this.OtshAddress?.id }, '', '', 'Selected');
    });
  }

  getCardContent(
    address: Address,
    selected: any,
    textDefaultDeliveryAddress: string,
    textShipToThisAddress: string,
    textSelected: string
  ): Card {
    let region = '';
    if (address?.region && address?.region.isocode) {
      region = address?.region.isocode;
    }

    return {
      role: 'region',
      title: address?.defaultAddress ? textDefaultDeliveryAddress : '',
      textBold: address?.firstName + (address?.lastName ? ' ' + address?.lastName : ''),
      text: [
        address?.line1,
        address?.line2,
        address?.town + ', ' + region + address?.country?.isocode,
        address?.postalCode,
        address?.phone,
      ],
      actions: [{ name: textShipToThisAddress, event: 'send' }],
      header: selected && selected.id === address?.id ? textSelected : '',
      label: address?.defaultAddress
        ? 'sloanAddressBook.defaultDeliveryAddress'
        : 'sloanAddressBook.additionalDeliveryAddress',
    };
  }

  selectAddress(address: Address): void {
    if (address?.id === getLastValueSync(this.selectedAddress$)?.id) {
      return;
    }
    this.globalMessageService.add(
      {
        key: 'checkoutAddress.deliveryAddressSelected',
      },
      GlobalMessageType.MSG_TYPE_INFO
    );
    this.setAddress(address);
  }

  addAddress(address: Address | undefined): void {
    if (!address) {
      return;
    }

    this.busy$.next(true);
    this.doneAutoSelect = true;

    this.checkoutDeliveryAddressFacade
      .createAndSetAddress(address)
      .pipe(switchMap(() => this.checkoutDeliveryModesFacade.clearCheckoutDeliveryMode()))
      .subscribe({
        complete: () => {
          // we don't call onSuccess here, because it can cause a spinner flickering
          this.next();
        },
        error: () => {
          this.onError();
          this.doneAutoSelect = false;
        },
      });
  }

  openAddressForm() {
    let modalInstance: any;
    const modalRef = this.modalService.open(KnBrAddressFormComponent, {
      centered: true,
      size: 'lg',
    });
    modalInstance = modalRef.componentInstance;
    modalInstance.isCheckOut = true;
    modalInstance.showCancelBtn = true;
    modalRef.result
      .then((address) => {
        if (address && address.isOneTime) {
          const onetimeaddress = {
            ...address.address,
            isDisposable: true,
          };
          this.knBrAddressService.createOneTimeAddress(onetimeaddress);
        } else {
          this.addAddress(address.address);
        }
      })
      .catch((error) => { });
  }

  hideNewAddressForm(goPrevious: boolean = false): void {
    this.addressFormOpened = false;
    if (goPrevious) {
      this.back();
    }
  }

  next(): void {
    this.checkoutStepService.next(this.activatedRoute);
  }

  back(): void {
    this.checkoutStepService.back(this.activatedRoute);
  }

  protected loadAddresses(): void {
    if (!this.isGuestCheckout) {
      this.userAddressService.loadAddresses();
    }
  }

  protected createCards(): Observable<CardWithAddress[]> {
    const addresses$ = combineLatest([this.getSupportedAddresses(), this.selectedAddress$]);
    const translations$ = combineLatest([
      this.translationService.translate('sloanCheckoutAddress.defaultDeliveryAddress'),
      this.translationService.translate('sloanCheckoutAddress.shipToThisAddress'),
      this.translationService.translate('addressCard.selected'),
      this.translationService.translate('addressCard.phoneNumber'),
      this.translationService.translate('addressCard.mobileNumber'),
    ]);

    return combineLatest([addresses$, translations$]).pipe(
      tap(([[addresses, selected]]) => this.selectDefaultAddress(addresses, selected)),
      map(([[addresses, selected], [textDefault, textShipTo, textSelected]]) =>
        addresses?.map((address) => ({
          address,
          card: this.getCardContent(address, selected, textDefault, textShipTo, textSelected),
        }))
      )
    );
  }

  protected selectDefaultAddress(addresses: Address[], selected: Address | undefined): void {
    if (!this.doneAutoSelect && addresses?.length && (!selected || Object.keys(selected).length === 0)) {
      selected = addresses.find((address) => address.defaultAddress);
      if (selected) {
        this.setAddress(selected);
      }
      this.doneAutoSelect = true;
    }
  }

  protected getSupportedAddresses(): Observable<Address[]> {
    return this.userAddressService.getAddresses();
  }

  protected createIsUpdating(): Observable<boolean> {
    return combineLatest([this.busy$, this.userAddressService.getAddressesLoading(), this.getAddressLoading()]).pipe(
      map(([busy, userAddressLoading, deliveryAddressLoading]) => busy || userAddressLoading || deliveryAddressLoading),
      distinctUntilChanged()
    );
  }

  protected getAddressLoading(): Observable<boolean> {
    return this.checkoutDeliveryAddressFacade.getDeliveryAddressState().pipe(
      map((state) => state.loading),
      distinctUntilChanged()
    );
  }

  protected setAddress(address: Address): void {
    this.busy$.next(true);
    this.checkoutDeliveryAddressFacade
      .setDeliveryAddress(address)
      .pipe(switchMap(() => this.checkoutDeliveryModesFacade.clearCheckoutDeliveryMode()))
      .subscribe({
        complete: () => {
          this.onSuccess();
        },
        error: () => {
          this.onError();
        },
      });
  }

  protected onSuccess(): void {
    this.busy$.next(false);
  }

  protected onError(): void {
    this.busy$.next(false);
  }
}
