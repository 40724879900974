<div class="product-main-grid col-md-12 p-0 d-flex" *ngIf="draftSearchResult$ | async as draftSearchResult">
  <div class="col-lg-12 product-list-view p-0">
    <div class="row d-flex pt-4">
      <div class="col-sm-12 col-md-8 col-lg-10 justify-content-start" *ngIf="paginate$ | async as paginate">
        <kn-br-browsing
          [paginate]="paginate"
          [recordsShown]="draftSearchResult.length"
          recordType="{{ 'label.carts' | cxTranslate }}"
        >
        </kn-br-browsing>
      </div>
    </div>
    <div class="row counter-wrapper" *ngIf="draftSearchResult && draftSearchResult.length">
      <div class="customersearch-list-table float-left col-lg-12 mt-3">
        <div
          class="customersearch-list-titles border-top float-left w-100 pl-2 pr-2 pt-3 pb-3 d-none d-sm-none d-lg-block"
        >
          <div class="col-md-1 padding-res-0 float-left font-text-600 pl-0 font-sm-size">
            {{ 'cartResult.header.draftOrderNumber' | cxTranslate }}
          </div>
          <!-- <div class="col-md-2 padding-res-0 float-left font-text-600 pl-0 font-sm-size">
            {{ 'cartResult.header.draftOrderName' | cxTranslate }}
          </div> -->
          <div class="col-md-1 float-left font-text-600 pl-0 font-sm-size">
            {{ 'cartResult.header.createdBy' | cxTranslate }}
          </div>
          <div class="col-md-1 float-left font-text-600 pl-0 font-sm-size">
            {{ 'cartResult.header.createdOn' | cxTranslate }}
          </div>
          <div class="col-md-1 float-left font-text-600 pl-0 font-sm-size">
            {{ 'cartResult.header.accountNo' | cxTranslate }}
          </div>
          <div class="col-md-2 float-left font-text-600 pl-0 font-sm-size">
            {{ 'cartResult.header.accountName' | cxTranslate }}
          </div>
          <div class="col-md-2 float-left font-text-600 pl-0 font-sm-size">
            {{ 'cartResult.header.customerPO' | cxTranslate }}
          </div>
          <div class="col-md-2 float-left font-text-600 pl-0 font-sm-size">
            {{ 'cartResult.header.job' | cxTranslate }}
          </div>
          <div class="col-md-1 float-left font-text-600 pl-0 font-sm-size">
            {{ 'cartResult.header.status' | cxTranslate }}
          </div>
          <div class="col-md-1 float-left font-text-600 pl-0 font-sm-size">
            {{ 'cartResult.header.total' | cxTranslate }}
          </div>
          <!-- <div
            *ngIf="(copyAction$ | async) && !enableCheckboxFlag"
            class="col-md-1 text-center float-left font-text-600 pl-0 font-sm-size"
          >
            {{ 'cartResult.header.actions' | cxTranslate }}
          </div>
          <div class="col-md-1 text-center float-left font-text-600 pl-0 font-sm-size pr-0" *ngIf="enableCheckboxFlag">
            <div class="text-center padding-res-0 float-left w-100">
              <div class="remember-me col-md-12 float-left mb-3 d-flex justify-content-center p-0">
                <div class="form-check custom-checkbox m-0">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="delet"
                    [checked]="allChecked"
                    (change)="selectAll($event)"
                  />
                  <label class="custom-control-label color-font" for="delet"></label>
                </div>
              </div>
            </div>
          </div> -->
        </div>

        <div
          class="
            bg-white
            customersearch-list-titles
            table-content
            border-top
            float-left
            w-100
            p-2
            font-weight-normal
            d-flex
            align-items-center
          "
          *ngFor="let draft of draftSearchResult"
          (click)="redirectToDetail($event, draft)"
        >
          <div class="col-md-1 float-left title-wrap pl-0 font-sm-size color-font">
            <span class="res-titles"> {{ 'cartResult.header.draftOrderNumber' | cxTranslate }}:</span>
            {{ draft.code | removeLeadingZeros }}
          </div>
          <!-- <div class="col-md-2 float-left title-wrap pl-0 font-sm-size color-font">
            <span class="res-titles">{{ 'cartResult.header.draftOrderName' | cxTranslate }}:</span>{{ draft.name }}
          </div> -->
          <div class="col-md-1 float-left title-wrap pl-0 font-size color-font">
            <span class="res-titles"> {{ 'cartResult.header.createdBy' | cxTranslate }}:</span>
            {{ draft.createdBy }}
          </div>
          <div class="col-md-1 float-left title-wrap pl-0 font-size color-font">
            <span class="res-titles"> {{ 'cartResult.header.createdOn' | cxTranslate }}:</span>
            {{ draft.creationDate }}
          </div>
          <div class="col-md-1 float-left title-wrap pl-0 font-size color-font">
            <span class="res-titles"> {{ 'cartResult.header.accountNo' | cxTranslate }}:</span>
            {{ draft.accountNumber | removeLeadingZeros }}
          </div>
          <div class="col-md-2 float-left title-wrap product-name pl-0 font-size color-font">
            <span class="res-titles"> {{ 'cartResult.header.accountName' | cxTranslate }}:</span>
            {{ draft.accountName }}
          </div>
          <div class="col-md-2 float-left title-wrap pl-0 font-size color-font">
            <span class="res-titles">{{ 'cartResult.header.customerPO' | cxTranslate }}:</span>
            <span class="text-limit">{{ draft.purchaseOrderNumber }}</span>
          </div>
          <div class="col-md-2 float-left title-wrap pl-0 font-size color-font">
            <span class="res-titles"> {{ 'cartResult.header.job' | cxTranslate }}:</span>
            <span class="text-limit">{{ draft.jobName }}</span>
          </div>
          <div class="col-md-1 float-left title-wrap pl-0 font-size color-font">
            <span class="res-titles"> {{ 'cartResult.header.status' | cxTranslate }}:</span>{{ draft.cartStatus }}
          </div>
          <div class="col-md-1 float-left title-wrap pl-0 font-size color-font">
            <span class="res-titles"> {{ 'cartResult.header.total' | cxTranslate }}:</span
            >{{ draft.totalPrice?.formattedValue }}
          </div>
          <!-- <div
            (click)="copyDraftContent(draft)"
            class="col-md-1 text-center float-left title-wrap pl-0 font-size color-font copy-action"
            [ngbTooltip]="copyButtonTip"
            *ngIf="(copyAction$ | async) && !enableCheckboxFlag"
          >
            <span class="res-titles"> {{ 'cartResult.header.actions' | cxTranslate }}:</span>
            <i class="far fa-copy fa-lg"></i>
          </div>
          <div
            class="
              col-md-1
              remember-me
              w-100
              custom-control
              float-left
              d-flex
              justify-content-center
              cursor-pointer
              mr-3
              pl-0
            "
            *ngIf="enableCheckboxFlag"
          >
            <div class="form-check custom-checkbox mb-0">
              <input
                type="checkbox"
                [id]="draft.code"
                class="custom-control-input rounded cursor-pointer"
                [value]="draft.code"
                [checked]="isChecked(draft.code)"
                (change)="changeCheckBox($event)"
              />
              <label class="custom-control-label color-font" [for]="draft.code" (click)="$event.stopPropagation()">
              </label>
            </div>
          </div> -->
        </div>
      </div>
      <ng-container *ngIf="paginate$ | async as paginate">
        <div class="d-flex justify-content-center w-100 mt-3">
          <div
            *ngIf="paginate.currentPage < paginate.totalPages - 1 && !(isLoader$ | async)"
            (click)="loadMore(paginate.currentPage)"
            class="btn btn-loadmore mt-0 mr-3"
          >
            {{ 'cartResult.showMore' | cxTranslate }}
          </div>
        </div>
      </ng-container>
    </div>
    <div
      class="alert-info p-3 rounded mt-3"
      *ngIf="draftSearchResult && draftSearchResult.length < 1 && !(isLoader$ | async)"
    >
      {{ 'cartResult.nodataMsg' | cxTranslate }}
    </div>
  </div>
</div>
<ng-template #copyButtonTip>{{ 'toolTip.copy' | cxTranslate }}</ng-template>
<div *ngIf="(isLoader$ | async) || !(draftSearchResult$ | async)">
  <cx-spinner></cx-spinner>
</div>
