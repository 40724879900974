import { ParamsMapping, RoutingConfig } from '@spartacus/core';

const listPath = `users/:userCode`;
const paramsMapping: ParamsMapping = {
  userCode: 'customerId',
};

export const knBrUserRoutingConfig: RoutingConfig = {
  routing: {
    routes: {
      orgUser: {
        paths: ['users'],
      },
      orgUserCreate: {
        paths: ['users/create'],
      },
      orgUserDetails: {
        paths: [listPath],
        paramsMapping,
      },
      orgUserEdit: {
        paths: [`${listPath}/edit`],
        paramsMapping,
      },
      orgUserChangePassword: {
        paths: [`${listPath}/change-password`],
        paramsMapping,
      },
    },
  },
};
