import { TranslationChunksConfig, TranslationResources } from '@spartacus/core';
import { en } from './en/index';

export const customerTicketingTranslations: TranslationResources = {
  en,
};

export const customerTicketingTranslationChunksConfig: TranslationChunksConfig = {
  customerTicketing: [
    'customerTicketing',
    'customerTicketingList',
    'createCustomerTicket',
    'customerTicketingDetails',
    'serviceTicketsForm',
  ],
};
