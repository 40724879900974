<cx-carousel [items]="items$ | async" [title]="title$ | async" [template]="carouselItem" itemWidth="285px">
</cx-carousel>

<ng-template #carouselItem let-item="item">
  <a tabindex="0" [routerLink]="{ cxRoute: 'product', params: item } | cxUrl" class="kn-br-product-carousel">
    <cx-media [container]="item.images?.PRIMARY"></cx-media>
    <div class="mt-3">
      <p class="mb-1 font-size-17 font-text-500">{{ item.code }}</p>
      <p class="mb-1 text-muted">{{ item.name }}</p>
      <p class="mb-0 font-text-400">{{ item.knbrPrice?.formatedNetPrice }}</p>
    </div>
  </a>
</ng-template>
