<ng-template [ngIf]="popupDetails && popupDetails.freightPopup">
  <div class="modal-header col-md-12 float-left p-3 border-bottom">
    <h4 class="modal-title font-size-18 color-font font-text-bold">{{ 'sloanFreightPopUp.heading' | cxTranslate }}</h4>
    <button type="button" class="close pb-0" aria-label="Close" (click)="dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body col-md-12 float-left p-3 border-bottom">
    <label class="label-content pb-4"> {{ 'sloanFreightPopUp.line1' | cxTranslate }}</label>
    <label class="label-content">{{ 'sloanFreightPopUp.line2' | cxTranslate }} </label>
    <label class="label-content"> {{ 'sloanFreightPopUp.line3' | cxTranslate }}</label>
    <label class="label-content">{{ 'sloanFreightPopUp.line4' | cxTranslate }} </label>
    <label class="label-content pt-4"> {{ 'sloanFreightPopUp.line5' | cxTranslate }}</label>
  </div>
</ng-template>
<ng-template [ngIf]="popupDetails && popupDetails.shipcompletePopup">
  <div class="modal-header col-md-12 float-left p-3 border-bottom">
    <h4 class="modal-title font-size-18 color-font font-text-bold">
      {{ 'sloanFreightPopUp.shipCompleteHeader' | cxTranslate }}
    </h4>
    <button
      *ngIf="popupDetails.shipcompletePopup && !popupDetails.freightPopup"
      type="button"
      class="close pb-0"
      aria-label="Close"
      (click)="dismiss()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body col-md-12 float-left p-3">
    <label class="label-content pt-4">
      {{
        'sloanFreightPopUp.line6'
          | cxTranslate
            : {
                estimateDate: popupDetails.estimateDate
              }
      }}</label
    >
  </div>
</ng-template>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary col-md-4" (click)="dismiss()">
    {{ 'sloanFreightPopUp.backToCartBtn' | cxTranslate }}
  </button>
  <button type="button" class="btn btn-primary col-md-4" (click)="navigate('/checkout')">
    {{ 'sloanFreightPopUp.checkOutBtn' | cxTranslate }}
  </button>
</div>
