import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CmsComponentWithChildren, CmsService } from '@spartacus/core';
import { CmsComponentData, CurrentProductService, ProductDetailsTabComponent } from '@spartacus/storefront';

@Component({
  selector: 'cx-product-details-tab',
  templateUrl: './sloan-product-details-tab.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SloanProductDetailsTabComponent extends ProductDetailsTabComponent implements OnInit {
  constructor(
    protected currentProductService: CurrentProductService,
    protected componentData: CmsComponentData<CmsComponentWithChildren>,
    protected cmsService: CmsService
  ) {
    super(currentProductService, componentData, cmsService);
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
