import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ConverterService, OccEndpointsService } from '@spartacus/core';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { KnBrOrderFormsSearchRequest } from 'src/feature-libs/kn-br-order/root/models/kn-br-order.model';
import { KnBrCustomerContextService } from 'src/app/store/kn-br-customer-context/kn-br-customer-context.service';
import { KnBrOrderHistoryService } from 'src/feature-libs/kn-br-order/core/facade/kn-br-order-history.service';

@Injectable({
  providedIn: 'root',
})
export class SloanOrderHistory {
  customerId: string;
  availability$ = new BehaviorSubject(null);
  loader$ = new BehaviorSubject(false);

  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converter: ConverterService,
    protected customerContextService: KnBrCustomerContextService,
    protected knBrOrderHistory: KnBrOrderHistoryService,
    protected store: Store
  ) {
    this.customerContextService.get$.subscribe((res) => {
      this.customerId = res.customerNumber;
    });
  }

  orderFormProductSearchKeyword(productCode: KnBrOrderFormsSearchRequest) {
    this.loader$.next(true);
    const url = this.occEndpoints.buildUrl('orderFormProductSearchKeyword', {
      urlParams: {
        customerId: this.customerId,
        productCode: productCode.keywords
      },
    });
    return this.http.get(url).pipe(distinctUntilChanged()).subscribe((res: any) => {
      this.availability$.next(res.products);
      this.loader$.next(false);
    });
  }
}
