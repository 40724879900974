import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { provideConfig } from '@spartacus/core';
import { knBrOccQuoteConfig } from './config/kn-br-occ-quote-config';

@NgModule({
  imports: [CommonModule],
  providers: [provideConfig(knBrOccQuoteConfig)],
})
export class KnBrQuoteOccModule {}
