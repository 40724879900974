import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { Product, ProductScope, ProductService, RoutingService } from '@spartacus/core';
import { CurrentProductService, ProductSummaryComponent } from '@spartacus/storefront';
import { Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'kn-br-product-summary',
  templateUrl: './kn-br-product-summary.component.html',
  styleUrls: ['./kn-br-product-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KnBrProductSummaryComponent extends ProductSummaryComponent implements OnDestroy {
  private currentProductCode$: Observable<string> = this.service.getProduct().pipe(
    filter(Boolean),
    map((p: Product) => p.code)
  );
  subscription: Subscription;

  constructor(
    protected service: CurrentProductService,
    protected productService: ProductService,
    protected routingService: RoutingService
  ) {
    super(service);
    this.subscription = this.routingService
      .getRouterState()
      .subscribe
      // (state) => {
      // if (state.state.params['productCode'])
      // TODO:Spartacus - Method 'ProductService.reload' was removed. Please use the reloading triggers configuration instead (see https://sap.github.io/spartacus-docs/loading-scopes/#reloading-triggers for more).
      // this.productService.reload(state.state.params['productCode'], ProductScope.DETAILS);
      // }
      ();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
