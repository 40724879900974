/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { OccConfig } from '@spartacus/core';

export const knBrOccCheckoutConfig: OccConfig = {
  backend: {
    occ: {
      endpoints: {
        setDeliveryAddress: 'knbr/customer/${customerId}/carts/${cartId}/addresses/delivery',
        cardTypes: 'cardtypes',
        createDeliveryAddress: 'knbr/customer/${customerId}/carts/${cartId}/addresses/delivery',
        removeDeliveryAddress: 'knbr/customer/${customerId}/carts/${cartId}/addresses/delivery',
        deliveryMode: 'knbr/customer/${customerId}/carts/${cartId}/deliverymode',
        setDeliveryMode: 'knbr/customer/${customerId}/carts/${cartId}/deliverymode',
        clearDeliveryMode: 'knbr/customer/${customerId}/carts/${cartId}/deliverymode',
        deliveryModes: 'knbr/customer/${customerId}/carts/${cartId}/deliverymodes',
        setCartPaymentDetails: 'knbr/customer/${customerId}/carts/${cartId}/paymentdetails',
        paymentProviderSubInfo: 'knbr/customer/${customerId}/carts/${cartId}/payment/sop/request?responseUrl=sampleUrl',
        createPaymentDetails: 'knbr/customer/${customerId}/carts/${cartId}/payment/sop/response',
        getCheckoutDetails:
          'knbr/customer/${customerId}/carts/${cartId}?fields=deliveryAddress(FULL),deliveryMode(FULL),paymentInfo(FULL)',
        addresses: 'knbr/customer/${customerId}/addresses',
        addressDetail: 'knbr/customer/${customerId}/addresses/${addressId}',
        addressVerification: 'knbr/customer/${customerId}/addresses/verification',
        placeOrder: 'knbr/customer/${customerId}/orders?fields=FULL',
      },
    },
  },
};
