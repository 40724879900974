<ng-container *ngIf="order$ | async as order">
  <ng-container *ngIf="order.orderEntries">
    <div class="col-12 col-sm float-left p-0 mb-3">
      <div
        class="col-12 col-sm float-left p-0 border d-flex align-items-center"
        *ngFor="let item of order.orderEntries; let i = index"
      >
        <div class="col-md-2 col-sm float-left" [ngClass]="{ 'unconsigned-item': !isItemOpen(item) }">
          <a [routerLink]="{ cxRoute: 'product', params: item.product } | cxUrl" cxModal="dismiss" tabindex="-1">
            <cx-media [container]="item.product?.images" format="product"></cx-media>
          </a>
        </div>
        <div class="col-md-10 col-sm float-left">
          <div class="col-md-12 col-sm float-left p-0 pt-2 pb-2 border-bottom">
            <label class="font-text-600 color-font font-size-18">{{ item.product?.code }}</label>
            <div *ngIf="item.product?.name" class="">
              <a
                class="cx-link"
                [routerLink]="{ cxRoute: 'product', params: item.product } | cxUrl"
                cxModal="dismiss"
                >{{ item.product?.name }}</a
              >
            </div>
          </div>
          <ng-container *ngIf="!(isOrderEditable$ | async)">
            <div
              class="col-md-12 col-sm float-left p-0 pt-2 pb-2"
              [ngClass]="{ 'border-bottom': item.consignmentEntry }"
            >
              <div class="col-md-1 col-sm float-left p-0">
                <label class="color-font font-text-600 mb-1">{{ 'knbrOrderDetails.label.qty' | cxTranslate }}</label>
                <p class="text-muted mb-1">{{ item.quantity }}</p>
              </div>
              <div class="col-md-2 col-sm float-left p-0">
                <label class="color-font font-text-600 mb-1">{{
                  'knbrOrderDetails.label.atpDate' | cxTranslate
                }}</label>
                <p class="text-muted mb-1">{{ item?.atpDate }}</p>
              </div>
              <div class="col-md-1 col-sm float-left p-0">
                <label class="color-font font-text-600 mb-1">{{ 'knbrOrderDetails.label.plant' | cxTranslate }}</label>
                <p class="text-muted mb-1">{{ item?.plant }}</p>
              </div>
              <div class="col-md-2 col-sm float-left p-0">
                <label class="color-font font-text-600 mb-1">{{
                  'knbrOrderDetails.label.lineItemStatus' | cxTranslate
                }}</label>
                <p class="text-muted mb-1">{{ item.lineItemStatus }}</p>
              </div>
              <div
                class="col-md-2 col-sm float-left p-0"
                *ngIf="item.knbrQuoteReference && item.knbrQuoteReference?.quoteRefNumber"
              >
                <label class="color-font font-text-600 mb-1">{{
                  'knbrOrderDetails.label.quoteId' | cxTranslate
                }}</label>
                <p class="text-muted mb-1">{{ item.knbrQuoteReference?.quoteRefNumber }}</p>
              </div>
              <div class="col-md-2 col-sm float-left p-0">
                <label class="color-font font-text-600 mb-1">{{
                  'knbrOrderDetails.label.openQuantity' | cxTranslate
                }}</label>
                <p class="text-muted mb-1">{{ item.openQuantity }}</p>
              </div>
              <div class="col-md-2 col-sm float-left p-0">
                <label class="color-font font-text-600 mb-1">{{
                  'knbrOrderDetails.label.unitNetPrice' | cxTranslate
                }}</label>
                <p class="text-muted mb-1">{{ item.basePrice?.formatedNetPrice }}</p>
              </div>
            </div>
          </ng-container>
          <knbr-kn-br-edit-order-item
            *ngIf="isOrderEditable$ | async"
            [item]="item"
            (inputChange)="onQuantityChange($event)"
          >
          </knbr-kn-br-edit-order-item>
          <ng-container *ngIf="!isItemOpen(item)">
            <div class="col-12 float-left p-0 pt-2 pb-2">
              <label class="w-100 float-left mb-2 text-muted font-size-18">{{
                'knbrOrderDetails.label.shipments' | cxTranslate
              }}</label>
              <div class="col-12 float-left p-0" *ngIf="item.quantity">
                <div class="col-2 float-left p-0">
                  <label class="color-font font-text-600 mb-1">{{ 'knbrOrderDetails.label.qty' | cxTranslate }}</label>
                  <p class="text-muted mb-1">{{ item.quantity }}</p>
                </div>
                <div class="col-2 float-left p-0" *ngIf="item.shipDate">
                  <label class="color-font font-text-600 mb-1">{{
                    'knbrOrderDetails.label.shipDate' | cxTranslate
                  }}</label>
                  <p class="text-muted mb-1">{{ item.shipDate }}</p>
                </div>
                <div class="col-2 float-left p-0" *ngIf="item.lineItemStatus">
                  <label class="color-font font-text-600 mb-1">{{
                    'knbrOrderDetails.label.status' | cxTranslate
                  }}</label>
                  <p class="text-muted mb-1">{{ item.lineItemStatus }}</p>
                </div>
                <div class="col-2 float-left p-0" *ngIf="item.deliveryNumberCode">
                  <label class="color-font font-text-600 mb-1">{{
                    'knbrOrderDetails.label.deliveryNumber' | cxTranslate
                  }}</label>
                  <p class="text-muted mb-1">{{ item.deliveryNumberCode }}</p>
                </div>
                <div class="col-2 float-left p-0" *ngIf="item.carrier">
                  <label class="color-font font-text-600 mb-1">{{
                    'knbrOrderDetails.label.carrier' | cxTranslate
                  }}</label>
                  <p class="text-muted mb-1">{{ item.carrier }}</p>
                </div>
                <div class="col-2 float-left p-0" *ngIf="item.trackingId">
                  <label class="color-font font-text-600 mb-1">{{
                    'knbrOrderDetails.label.trackingId' | cxTranslate
                  }}</label>
                  <a [href]="item.url" target="_blank" class="mb-1 cursor-pointer">{{ item.trackingId }}</a>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
  <div *ngIf="!(isOrderEditable$ | async)" class="col-md-12 float-right d-flex justify-content-center mt-2">
    <button type="button" class="btn btn-outline-primary" (click)="goBack()">
      {{ 'knbrOrderDetails.actions.backOrderHistory' | cxTranslate }}
    </button>
  </div>
</ng-container>
<ng-container>
  <sloan-add-products *ngIf="isOrderEditable$ | async" (inputChange)="onInputChange($event)"> </sloan-add-products
></ng-container>
