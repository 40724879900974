import { NgModule } from '@angular/core';
import { CmsConfig, RoutingConfig, provideDefaultConfig, provideDefaultConfigFactory } from '@spartacus/core';
import { PRODUCT_FEATURE } from './feature-name';

// TODO: Inline this factory when we start releasing Ivy compiled libraries
export function knbrProductComponentsConfig(): CmsConfig {
  const config: CmsConfig = {
    featureModules: {
      [PRODUCT_FEATURE]: {
        cmsComponents: [
          'ProductRefinementComponent',
          'KnBrProductActionBarComponent',
          'KnBrProductIntroComponent',
          'KnBrProductImagesComponent',
          'KnBrProductSummaryComponent',
          'SearchBoxComponent',
          'CMSProductListComponent',
          'ProductGridComponent',
          'SearchResultsListComponent',
          'KnBrQuoteReferenceComponent',
        ],
      },
    },
  };

  return config;
}

@NgModule({
  imports: [],
  providers: [
    provideDefaultConfigFactory(knbrProductComponentsConfig),
    provideDefaultConfig(<RoutingConfig>{
      routing: {
        routes: {
          category: {
            paths: ['c/:categoryCode', 'category/:categoryCode'],
            paramsMapping: { categoryCode: 'code' },
          },
          product: {
            paths: ['product/:productCode/:prettyName', 'product/:productCode/:name'],
          },
        },
      },
    }),
  ],
})
export class ProductRootModule { }
