<table role="table">
  <caption class="cx-visually-hidden">
    {{
      'cartItems.caption' | cxTranslate
    }}
  </caption>
  <thead *ngIf="hasHeader">
    <tr role="row" class="cx-item-list-header">
      <th role="columnheader" class="cx-item-list-desc">
        {{ 'cartItems.description' | cxTranslate }}
      </th>
      <th role="columnheader" class="cx-item-list-item-price">
        {{ 'cartItems.itemPrice' | cxTranslate }}
      </th>
      <th role="columnheader" class="cx-item-list-qty">
        {{ 'cartItems.quantity' | cxTranslate }}
      </th>
      <th role="columnheader" class="cx-item-list-total">
        {{ 'cartItems.total' | cxTranslate }}
      </th>
    </tr>
  </thead>
  <tbody class="cx-item-list-items">
    <ng-container *ngFor="let item of items; let i = index">
      <ng-container *ngIf="getControl(item) | async as control" [class.is-changed]="control.get('quantity').disabled">
        <tr class="cx-item">
          <td role="cell">
            <div class="cx-table-item-container">
              <!-- Item Image -->
              <a class="pt-3" [routerLink]="{ cxRoute: 'product', params: item.product } | cxUrl" tabindex="0">
                <cx-media
                  *ngIf="item.product?.images?.PRIMARY"
                  [container]="item.product?.images?.PRIMARY"
                  format="cartIcon"
                ></cx-media>
                <cx-media *ngIf="item.product?.images" [container]="item.product?.images" format="cartIcon"></cx-media>
              </a>
              <div class="cx-info">
                <div *ngIf="item.product?.name" class="cx-name">
                  <a class="cx-link" [routerLink]="{ cxRoute: 'product', params: item.product } | cxUrl">{{
                    item.product?.name
                  }}</a>
                </div>
                <div *ngIf="item.product?.code" class="cx-code">
                  {{ 'cartItems.id' | cxTranslate }} {{ item.product?.code }}
                </div>
                <!-- Item Price -->
                <div *ngIf="item?.knbrTotalPrice?.formatedNetPrice" class="cx-price">
                  <div *ngIf="item?.knbrTotalPrice?.formatedNetPrice" class="cx-value">
                    {{ item?.knbrTotalPrice?.formatedNetPrice }}
                  </div>
                </div>
              </div>
            </div>
          </td>
          <!-- Mobile Item Price -->
          <td role="cell" *ngIf="item?.basePrice || item?.knbrTotalPrice?.formatedNetPrice" class="cx-item-price">
            <div *ngIf="item?.knbrTotalPrice?.formatedNetPrice" class="cx-value">
              {{ item?.knbrTotalPrice?.formatedNetPrice }}
            </div>
            <div *ngIf="item?.basePrice?.formattedValue" class="cx-value">
              {{ item?.basePrice?.formattedValue }}
            </div>
          </td>
          <!-- Item Quantity -->
          <td role="cell" class="cx-quantity">
            <div class="cx-value" [class.readonly-value]="readonly">
              {{ item?.quantity }}
            </div>
          </td>
          <td role="cell" *ngIf="item.totalPrice" class="cx-total">
            <div class="cx-value">
              {{ item.totalPrice.formattedValue }}
            </div>
          </td>
        </tr>
      </ng-container>
    </ng-container>
  </tbody>
</table>
