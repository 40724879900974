import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthGuard, CmsConfig, provideConfig, provideConfigFactory } from '@spartacus/core';
import { CmsPageGuard, PageLayoutComponent } from '@spartacus/storefront';
import { DRAFT_ORDER_CORE_FEATURE, DRAFT_ORDER_FEATURE } from './feature-name';
import { defaultDraftOrderRoutingConfig } from './config/default-draft-order-routing-config';

export function cartSavedCartComponentsConfig(): CmsConfig {
  const config: CmsConfig = {
    featureModules: {
      [DRAFT_ORDER_FEATURE]: {
        cmsComponents: [
          'KnBrDraftOrderCreateComponent',
          'KnBrDraftOrderAttachmentLinkComponent',
          'KnBrDraftOrderDeleteItemsComponent',
          'KnBrDraftOrderHeaderComponent',
          'KnBrDraftOrderHeaderComponentReadOnly',
          'KnBrDraftOrderDownloadLinkComponent',
          'KnBrDraftOrderItemsComponent',
          'KnBrDraftOrderItemsComponentReadOnly',
          'KnBrDraftOrderSearchComponent',
          'KnBrDraftOrderSearchResultComponent'
        ]
      }
    }
  };
  return config;
}

@NgModule({
  imports: [
    RouterModule.forChild([
    {
      data: { pageLabel: '/create-cart', cxRoute: 'createCart' },
      path: null,
      canActivate: [AuthGuard, CmsPageGuard],
      component: PageLayoutComponent,
    },
    {
      path: null,
      canActivate: [AuthGuard, CmsPageGuard],
      component: PageLayoutComponent,
      data: { pageLabel: '/cart-detail', cxRoute: 'cartDetails' },

    },
    {
      path: null,
      canActivate: [AuthGuard, CmsPageGuard],
      component: PageLayoutComponent,
      data: { pageLabel: '/carts', cxRoute: 'carts' },
    },
  ])],
  providers: [
    provideConfigFactory(cartSavedCartComponentsConfig),
    provideConfig(defaultDraftOrderRoutingConfig),
  ],
})
export class KnBrDraftOrderRootModule {}
