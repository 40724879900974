import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { KN_BR_CONTEXT_CART_FEATURE } from './kn-br-cart-context.state';
import { KnBrCartContextEffects } from 'src/feature-libs/kn-br-cart/base/core/store/effects/kn-br-cart-context.effects';
import { knBrCartContextReducer } from 'src/feature-libs/kn-br-cart/base/core/store/reducers/kn-br-cart-context.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(KN_BR_CONTEXT_CART_FEATURE, knBrCartContextReducer),
    EffectsModule.forFeature([KnBrCartContextEffects]),
  ],
})
export class KnBrCartContextStateModule { }
