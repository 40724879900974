import { Action } from '@ngrx/store';

export enum SloanQuotesActionTypes {
  SloanQuoteEditAction = '[SloanQuote] Update',
  SloanQuoteEditSuccessAction = '[SloanQuote] Update Success',
  SloanQuoteEditFailureAction = '[SloanQuote] Update Failure',
}

/**
 * Action dispatched on Quote Edit
 */
export class SloanQuoteEdit implements Action {
  readonly type = SloanQuotesActionTypes.SloanQuoteEditAction;
  constructor(public payload: any) {}
}

/**
 * Action dispatched on Quote Edit Success
 */
export class SloanQuoteEditSuccess implements Action {
  readonly type = SloanQuotesActionTypes.SloanQuoteEditSuccessAction;
  constructor(public payload: any) {}
}

/**
 * Action dispatched on Quote Edit Failure
 */
export class SloanQuoteEditFailure implements Action {
  readonly type = SloanQuotesActionTypes.SloanQuoteEditFailureAction;
  constructor(public payload: any) {}
}

export type KnBrQuotesSearchActions = SloanQuoteEdit | SloanQuoteEditSuccess | SloanQuoteEditFailure;
