import { Component, OnDestroy } from '@angular/core';
import { RoutingService } from '@spartacus/core';
import { KnBrProductSelectService } from 'src/feature-libs/kn-br-product/core/service/kn-br-product-select.service';
import { KnBrProductListItemComponent } from 'src/feature-libs/kn-br-product/components/kn-br-product-list/kn-br-product-list-item/kn-br-product-list-item.component';

@Component({
  selector: 'kn-br-product-list-item',
  templateUrl: './sloan-product-list-item.component.html',
  styleUrls: ['./sloan-product-list-item.component.scss'],
})
export class SloanProductListItemComponent extends KnBrProductListItemComponent implements OnDestroy {
  constructor(protected productSelectService: KnBrProductSelectService, protected routingService: RoutingService) {
    super(productSelectService, routingService);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  isChecked(code: string): boolean {
    if (
      this.selectedProductsCodes &&
      this.product.isOrderable &&
      this.product.price &&
      this.product.price?.formattedValue
    ) {
      return this.selectedProductsCodes.indexOf(code) > -1;
    }
    return false;
  }

  addProductCode() {
    if (this.product.isOrderable && this.product.price && this.product.price?.formattedValue) {
      if (this.selectedProductsCodes && this.selectedProductsCodes.length) {
        this.selectedProductsCodes.push(this.product.code);
      } else {
        this.selectedProductsCodes = [this.product.code];
      }
    }
    return this.selectedProductsCodes;
  }
}
