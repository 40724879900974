import { Injectable } from '@angular/core';
import { RoutingService } from '@spartacus/core';
import { PageLayoutHandler } from '@spartacus/storefront';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { KnBrQuotesService } from '../../../core/facade/kn-br-quotes.service';

@Injectable({
  providedIn: 'root',
})
export class KnBrQuotePageLayoutHandler implements PageLayoutHandler {
  code: string;
  constructor(private routingService: RoutingService, private knBrQuoteService: KnBrQuotesService) { }

  handle(slots$: Observable<string[]>, pageTemplate?: string, section?: string) {
    if (pageTemplate === 'KnBrQuoteDetailPageTemplate' && !section) {
      this.routingService.getRouterState().subscribe((state) => {
        if (state && state.state && state.state.params && state.state.params.code) {
          this.code = state.state.params.code;
          this.knBrQuoteService.get$.subscribe((quote) => {
            if (!quote) {
              this.knBrQuoteService.get(this.code);
            }
          });
        }
      });
      return combineLatest([slots$, this.knBrQuoteService.get$]).pipe(
        map(([slots, quote]) => {
          const exclude = (arr, args) => arr.filter((item) => args.every((arg) => arg !== item));
          if (quote && quote.code && quote.entries) {
            return quote && Object.keys(quote).length === 0
              ? exclude(slots, ['KnBrEmptyCartSlot'])
              : quote?.totalItems
                ? exclude(slots, ['KnBrEmptyCartSlot'])
                : quote?.totalItems
                  ? exclude(slots, ['KnBrEmptyCartSlot'])
                  : exclude(slots, ['KnBrQuoteActionBarSlot', 'KnBrQuoteItemsSlot']);
          } else {
            return exclude(slots, ['KnBrEmptyCartSlot']);
          }
        })
      );
    }
    return slots$;
  }
}
