import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { GlobalMessageService, GlobalMessageType, LoggerService, normalizeHttpError } from '@spartacus/core';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import {
  SloanQuotesActionTypes,
  KnBrQuotesSearchActions,
  SloanQuoteEditFailure,
  SloanQuoteEditSuccess,
} from './sloan-quotes.action';
import { SloanQuotesAdapter } from './sloan-quotes.adapter';
import { KnBrQuotesService } from 'src/feature-libs/kn-br-cart/quote/core/facade/kn-br-quotes.service';

@Injectable()
export class SloanQuotesEffects {
  protected logger = inject(LoggerService);
  editQuote$ = createEffect(() => this.actions$.pipe(
    ofType(SloanQuotesActionTypes.SloanQuoteEditAction),
    mergeMap((action) =>
      this.sloanQuotesAdapter.edit(action.payload).pipe(
        map((data: any) => {
          data.isRedirect = action.payload.isRedirect;
          return new SloanQuoteEditSuccess(data);
        }),
        catchError((error) => {
          this.knBrQuoteService.get(action.payload.code);
          return of(new SloanQuoteEditFailure(normalizeHttpError(error, this.logger)));
        })
      )
    )
  )
  );

  editSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(SloanQuotesActionTypes.SloanQuoteEditSuccessAction),
    map((action) => {
      this.messageService.add({ key: 'quoteCommon.updateMessage.success' }, GlobalMessageType.MSG_TYPE_CONFIRMATION);
      window.location.reload();
    })
  ),
    { dispatch: false }
  );

  editFailure$ = createEffect(() => this.actions$.pipe(
    ofType(SloanQuotesActionTypes.SloanQuoteEditFailureAction),
    map((action) => {
      const errors = (action.payload?.error?.errors || action.payload?.details || []) as Array<any>;
      if (errors.length > 0) {
        errors.forEach((error) => {
          const type = error.type.toLowerCase();
          if (type.indexOf('warning') !== -1) {
            this.messageService.add({ raw: error.message }, GlobalMessageType.MSG_TYPE_WARNING);
          } else if (type.indexOf('error') !== -1) {
            this.messageService.add({ raw: error.message }, GlobalMessageType.MSG_TYPE_ERROR);
          }
        });
      } else {
        this.messageService.add({ key: 'quoteCommon.updateMessage.error' }, GlobalMessageType.MSG_TYPE_ERROR);
      }
    })
  ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions<KnBrQuotesSearchActions>,
    private sloanQuotesAdapter: SloanQuotesAdapter,
    private messageService: GlobalMessageService,
    private knBrQuoteService: KnBrQuotesService
  ) { }
}
