import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { KnBrQuoteReference } from 'src/feature-libs/kn-br-cart/quote/root/models/kn-br-quote.model';
import { KnBrCustomerContextService } from 'src/app/store/kn-br-customer-context/kn-br-customer-context.service';
import { KN_BR_UPDATE_QUOTE_REFERENCE, KN_BR_QUOTE_UPDATE_ENTRY, KN_BR_REMOVE_QUOTE_REFERENCE, KN_BR_GET_QUOTE_REFERENCE, KN_BR_CUSTOMER_CURRENT, KN_BR_GET_QUOTE_REFERENCE_BY_PRODUCT } from 'src/constants/api.endpoints.constant';
import { KnBrCartContextService } from 'src/feature-libs/kn-br-cart/base/core/facade/kn-br-cart-context.service';

@Injectable({
  providedIn: 'root',
})
export class KnBrQuoteReferenceAdapter {
  customerId: string;
  cartId: string;
  constructor(
    protected http: HttpClient,
    protected occEndpointsService: OccEndpointsService,
    protected knBrCustomerContextService: KnBrCustomerContextService,
    protected knBrCartContextService: KnBrCartContextService
  ) {
    this.knBrCustomerContextService.getActiveCustomerNumber().subscribe((response) => {
      this.customerId = response ? response : null;
    });
    this.knBrCartContextService.get$.subscribe((response) => {
      this.cartId = response ? response : null;
    });
  }

  public add(reference: KnBrQuoteReference): Observable<KnBrQuoteReference> {
    const toAdd = JSON.stringify({});

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    const url = this.occEndpointsService.buildUrl(KN_BR_UPDATE_QUOTE_REFERENCE, {
      urlParams: {
        customerId: this.customerId,
        cartId: this.cartId,
        entryNumber: reference.entryNumber,
      },
      queryParams: { quoteEntryPK: reference.knbrQuoteReference.quoteEntryPK },
    });

    return this.http.patch<KnBrQuoteReference>(url, toAdd, { headers });
  }

  public update(entry: KnBrQuoteReference): Observable<KnBrQuoteReference> {
    const params = {
      quantity: entry.quantity,
    };

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    const url = this.occEndpointsService.buildUrl(KN_BR_QUOTE_UPDATE_ENTRY, {
      urlParams: { customerId: this.customerId, cartId: this.cartId, entryNumber: entry.entryNumber },
      queryParams: { ...params },
    });

    return this.http.patch<KnBrQuoteReference>(url, {}, { headers });
  }

  public remove(entryNumber: string): Observable<KnBrQuoteReference> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    const url = this.occEndpointsService.buildUrl(KN_BR_REMOVE_QUOTE_REFERENCE, {
      urlParams: {
        customerId: this.customerId,
        cartId: this.cartId,
        entryNumber,
      },
    });
    return this.http.patch<KnBrQuoteReference>(url, { headers });
  }

  get(entryNumber) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    const url = this.occEndpointsService.buildUrl(KN_BR_GET_QUOTE_REFERENCE, {
      urlParams: { customerId: this.customerId, cartId: this.cartId, entryNumber },
    });
    return this.http.get<KnBrQuoteReference>(url, { headers });
  }

  getByProduct(productId) {
    const CUSTOMER_NUMBER = this.customerId ? this.customerId : KN_BR_CUSTOMER_CURRENT;
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    const url = this.occEndpointsService.buildUrl(KN_BR_GET_QUOTE_REFERENCE_BY_PRODUCT, {
      urlParams: {
        customerId: CUSTOMER_NUMBER,
        productId,
      },
    });
    return this.http.get<KnBrQuoteReference>(url, { headers });
  }
}
