import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, queueScheduler } from 'rxjs';
import { map, observeOn, tap } from 'rxjs/operators';
import {
  KnBrCustomerAddUpdateContext,
  KnBrCustomerLoadContext,
  KnBrCustomerRemoveContext,
  KnBrCustomerResetContext,
} from './kn-br-customer-context.actions';
import { getCustomerContext } from './kn-br-customer-context.selector';
import { StateWithCustomerContext } from './kn-br-customer-context.state';
import { CustomerData } from 'src/feature-libs/kn-br-user/kn-br-account/root/model/customer.model';

@Injectable({
  providedIn: 'root',
})
export class KnBrCustomerContextService {
  constructor(private store: Store<StateWithCustomerContext>) {
    this.store.dispatch(new KnBrCustomerLoadContext());
  }

  get$: Observable<CustomerData> = this.store.pipe(
    select(getCustomerContext),
    observeOn(queueScheduler),
    tap((context) => {
      if (!context) {
        this.store.dispatch(new KnBrCustomerLoadContext());
      }
    })
  );
  set(context: CustomerData) {
    this.store.dispatch(new KnBrCustomerAddUpdateContext(context));
  }

  delete() {
    this.store.dispatch(new KnBrCustomerRemoveContext());
  }
  reset() {
    this.store.dispatch(new KnBrCustomerResetContext());
  }

  getActiveCustomerNumber(): Observable<string> {
    return this.get$.pipe(
      map((response) => {
        if (response && response.customerNumber) {
          return response.customerNumber;
        } else {
          return null;
        }
      })
    );
  }
}
