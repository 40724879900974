import { KnBrCommonService } from 'src/app/services/kn-br-common.service';
import { Component, ComponentRef, OnInit, ViewContainerRef, OnDestroy } from '@angular/core';
import { RoutingService } from '@spartacus/core';
import { LaunchDialogService, LAUNCH_CALLER } from '@spartacus/storefront';
import { OrderReturnRequestService } from '@spartacus/order/core';
import { Observable, Subscription } from 'rxjs';
import { OrderReturnRequestFacade } from 'src/feature-libs/kn-br-order/root/facade/order-return-request.facade';

@Component({
  selector: 'knbr-kn-br-return-order-details-overview',
  templateUrl: './kn-br-return-order-details-overview.component.html',
  styleUrls: ['./kn-br-return-order-details-overview.component.scss'],
})
export class KnBrReturnOrderDetailsOverviewComponent implements OnInit, OnDestroy {
  orderReturnData$ = this.returnRequestService.getOrderReturnRequest();
  returnCode: string;
  showSpinner: void | Observable<ComponentRef<any>>;
  subscription = new Subscription();
  loader$: Observable<boolean> = this.knBrCommonService.loadSpinner$();

  constructor(
    protected returnRequestService: OrderReturnRequestFacade,
    protected routingService: RoutingService,
    protected launchDialogService: LaunchDialogService,
    protected vcr: ViewContainerRef,
    protected knBrCommonService: KnBrCommonService
  ) {
    this.routingService
      .getRouterState()
      .subscribe((state) => {
        if (state && state.state && state.state.params && state.state.params.returnCode) {
          this.returnCode = state.state.params.returnCode;
          this.returnRequestService.loadOrderReturnRequestDetail(this.returnCode);
        }
      })
      .unsubscribe();
  }

  onSpinnerLoading(isLoading) {
    if (isLoading) {
      this.showSpinner = this.launchDialogService.launch(LAUNCH_CALLER.PLACE_ORDER_SPINNER, this.vcr);
    } else {
      if (this.showSpinner) {
        this.showSpinner
          .subscribe((component) => {
            this.launchDialogService.clear(LAUNCH_CALLER.PLACE_ORDER_SPINNER);
            component.destroy();
          })
          .unsubscribe();
      }
    }
  }

  ngOnInit(): void {
    this.subscription.add(
      this.loader$.subscribe((isLoading) => {
        this.onSpinnerLoading(isLoading);
      })
    );
  }

  ngOnDestroy() {
    this.launchDialogService.clear(LAUNCH_CALLER.PLACE_ORDER_SPINNER);
    this.returnRequestService.clearOrderReturnRequestDetail();
    this.subscription.unsubscribe();
  }

  goBack() {
    this.routingService.go({ cxRoute: 'returnOrders' });
  }
}
