import { KnBrOccConfig } from 'src/app/core/occ/kn-br-occ.config';

export const knBrOccQuoteConfig: KnBrOccConfig = {
  backend: {
    occ: {
      endpoints: {
        /* eslint-disable max-len */
        quotes: 'knbr/customer/${customerId}/quotes?fields=BASIC,quotes(totalPrice(formattedValue),totalItems)',
        quote:
          'knbr/customer/${customerId}/quotes/${quoteId}?fields=DEFAULT,entries(totalPrice(formattedValue),product(images(FULL),stock(FULL)),basePrice(formattedValue),updateable),totalPrice(formattedValue),totalItems,totalPriceWithTax(formattedValue),totalDiscounts(formattedValue),subTotal(formattedValue),totalTax(formattedValue),deliveryItemsQuantity,net,productDiscounts(formattedValue),user',
        createQuote: 'knbr/customer/${customerId}/quotes',
        quoteStatuses: 'knbr/customer/${customerId}/quotes/${quoteId}/statuses',
        quoteAcceptCheckout: 'knbr/customer/${customerId}/quotes/${quoteId}/checkout',
        quoteClone: 'knbr/customer/${customerId}/quotes/${quoteId}/clone',
        /* eslint-enable */
      },
    },
  },
};
