import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CurrentProductService, ProductAttributesComponent } from '@spartacus/storefront';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'cx-product-attributes',
  templateUrl: './sloan-product-attributes.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SloanProductAttributesComponent extends ProductAttributesComponent {
  classificationList$: Observable<any[]> = this.product$.pipe(map((p: any) => this.mergeClassification(p)));

  constructor(protected currentProductService: CurrentProductService) {
    super(currentProductService);
  }

  protected mergeClassification(product: any): Observable<any>[] {
    if (!product.classifications || product.classifications.length < 1) {
      return [];
    }

    const mergedAndFilteredList = _.filter(
      _.flatMap(product.classifications, 'features'),
      (obj) =>
        !(
          _.includes(obj.code, 'certifications_l') ||
          _.includes(obj.code, 'features_l') ||
          _.includes(obj.code, 'architecturalworksheet_a') ||
          _.includes(obj.code, 'bimmodel_a') ||
          _.includes(obj.code, 'brochure') ||
          _.includes(obj.code, 'caddrawing_a') ||
          _.includes(obj.code, 'identificationguide') ||
          _.includes(obj.code, 'informationsheet_a') ||
          _.includes(obj.code, 'installationinstructions') ||
          _.includes(obj.code, 'pricingsheet') ||
          _.includes(obj.code, 'repairandmaintenanceguides_a') ||
          _.includes(obj.code, 'sdssafetydatasheet') ||
          _.includes(obj.code, 'sellsheet_a') ||
          _.includes(obj.code, 'troubleshootingguide_a') ||
          _.includes(obj.code, 'productguide_a') ||
          _.includes(obj.code, 'transparencyreport') ||
          _.includes(obj.code, 'maintenanceschedule_a') ||
          _.includes(obj.code, 'roughin_a') ||
          _.includes(obj.code, 'linedrawing') ||
          _.includes(obj.code, 'roughin_a') ||
          _.includes(obj.code, 'videos') ||
          _.includes(obj.code, 'specsheet') ||
          _.includes(obj.code, 'genericspec') ||
          _.includes(obj.code, 'titleseries')
        )
    );
    return _.sortBy(mergedAndFilteredList, ['name']);
  }
}
