import { TranslationService } from '@spartacus/core';
import { map } from 'rxjs/operators';
import { Observable, combineLatest, Subscription } from 'rxjs';
import { Component, OnInit, ChangeDetectorRef, OnDestroy, ViewContainerRef } from '@angular/core';
import { KnBrAddRequestEntry, KnBrOrderFormsSearchRequest } from 'src/feature-libs/kn-br-order/root/models/kn-br-order.model';
import * as _ from 'lodash';
import { ComponentRef } from '@angular/core';
import { LaunchDialogService, LAUNCH_CALLER } from '@spartacus/storefront';
import { KnBrOrderHistoryService } from 'src/feature-libs/kn-br-order/core/facade/kn-br-order-history.service';
import { KnBrCartContextService } from 'src/feature-libs/kn-br-cart/base/core/facade/kn-br-cart-context.service';
import { KnBrQuoteContextService } from 'src/feature-libs/kn-br-cart/quote/core/facade/kn-br-quote-context.service';
import { KnBrDraftOrdersService } from 'src/feature-libs/kn-br-cart/draft-order/core/facade/kn-br-draft-orders.service';

@Component({
  selector: 'knbr-kn-br-order-form-search-result',
  templateUrl: './kn-br-order-form-search-result.component.html',
  styleUrls: ['./kn-br-order-form-search-result.component.scss'],
})
export class KnBrOrderFormSearchResultComponent implements OnInit, OnDestroy {
  products$: Observable<any> = this.knBrOrderHistoryService.orderFormsProducts$;
  isLoader$: Observable<boolean> = this.knBrOrderHistoryService.orderFormsloader$;
  paginate$: Observable<any> = this.knBrOrderHistoryService.orderFormspaginate$;
  sorts$: Observable<any> = this.knBrOrderHistoryService.orderFormssorts$;
  sortType;
  searchCriteria: KnBrOrderFormsSearchRequest;
  subscription = new Subscription();
  cartContext;
  quoteContext;
  selectedProducts: KnBrAddRequestEntry[] = [];
  selectedProductsWithPrice: any[] = [];
  checkedProducts: KnBrAddRequestEntry[] = [];
  isOrderForm = true;
  totalQty = 0;
  subTotal = 0;
  loader$: Observable<boolean> = this.knBrDraftOrderService.loader$;
  showSpinner: void | Observable<ComponentRef<any>>;
  constructor(
    protected knBrOrderHistoryService: KnBrOrderHistoryService,
    protected translation: TranslationService,
    protected knBrCartContextService: KnBrCartContextService,
    protected knBrQuoteContextService: KnBrQuoteContextService,
    protected cdr: ChangeDetectorRef,
    protected knBrDraftOrderService: KnBrDraftOrdersService,
    protected launchDialogService: LaunchDialogService,
    protected vcr: ViewContainerRef
  ) {
    this.subscription.add(
      this.knBrCartContextService.get$.subscribe((data) => {
        this.cartContext = data;
        this.cdr.markForCheck();
      })
    );
    this.subscription.add(
      this.knBrQuoteContextService.get$.subscribe((data) => {
        this.quoteContext = data;
        this.cdr.markForCheck();
      })
    );
    this.subscription.add(
      this.knBrOrderHistoryService.getOrderForm().subscribe((res) => {
        this.isOrderForm = res;
        this.cdr.markForCheck();
      })
    );
    this.subscription = this.knBrOrderHistoryService.orderFormsSearchCriteria$.subscribe((response) => {
      this.searchCriteria = _.cloneDeep(response);
    });
    this.subscription.add(
      this.paginate$.subscribe((paginate: any) => {
        if (paginate) {
          this.sortType = paginate.sort;
        }
      })
    );
    this.subscription.add(
      this.knBrDraftOrderService.quickResetForm$.subscribe((res) => {
        if (res) {
          this.subTotal = 0;
          this.totalQty = 0;
          this.selectedProductsWithPrice = [];
          this.selectedProducts = [];
        }
      })
    );
  }

  ngOnInit(): void {
    this.subscription.add(
      this.loader$.subscribe((isLoading) => {
        this.onSpinnerLoading(isLoading);
      })
    );
  }

  onSpinnerLoading(isLoading) {
    if (isLoading) {
      this.showSpinner = this.launchDialogService.launch(LAUNCH_CALLER.PLACE_ORDER_SPINNER, this.vcr);
    } else {
      if (this.showSpinner) {
        this.showSpinner
          .subscribe((component) => {
            this.launchDialogService.clear(LAUNCH_CALLER.PLACE_ORDER_SPINNER);
            component.destroy();
          })
          .unsubscribe();
      }
    }
  }

  onQuantityChange(eventData) {
    if (eventData.entry.quantity > 0) {
      const index = _.findIndex(this.selectedProducts, { productCode: eventData.entry.productCode });

      // Replace item at index using native splice
      if (index >= 0) {
        this.selectedProducts[index] = eventData.entry;
        this.selectedProductsWithPrice[index] = eventData;
      } else {
        this.selectedProducts.push(eventData.entry);
        this.selectedProductsWithPrice.push(eventData);
      }
    } else {
      _.remove(this.selectedProducts, {
        productCode: eventData.entry.productCode,
      });
      _.remove(this.selectedProductsWithPrice, {
        entry: {
          productCode: eventData.entry.productCode,
        },
      });
    }
    this.totalQty = 0;
    this.subTotal = 0;
    if (this.selectedProducts && this.selectedProducts.length > 0) {
      this.selectedProducts.forEach((product) => (this.totalQty += product.quantity));
    }
    if (this.selectedProductsWithPrice && this.selectedProductsWithPrice.length > 0) {
      this.selectedProductsWithPrice.forEach(
        (productWithPrice) => (this.subTotal += productWithPrice.entry.quantity * productWithPrice.price)
      );
    }
    this.cdr.markForCheck();
  }

  loadMore(currentPage: number) {
    if (this.searchCriteria) {
      this.searchCriteria.currentPage = currentPage + 1;
      this.searchCriteria.sort = this.sortType;
    }
    this.knBrOrderHistoryService.getOrderFormProducts(this.searchCriteria);
  }

  getSortLabels(): Observable<{ byDate: string; byOrderNumber: string; byPONumber: string; byStatus: string }> {
    return combineLatest([
      this.translation.translate('sorting.date'),
      this.translation.translate('sorting.orderNumber'),
      this.translation.translate('sorting.poNumber'),
      this.translation.translate('sorting.status'),
    ]).pipe(
      map(([textByDate, textByOrderNumber, textByPoNumber, textByStatus]) => {
        return {
          byDate: textByDate,
          byOrderNumber: textByOrderNumber,
          byPONumber: textByPoNumber,
          byStatus: textByStatus,
        };
      })
    );
  }

  changeSortCode(sortCode: string): void {
    if (this.searchCriteria) {
      this.searchCriteria.currentPage = 0;
      this.searchCriteria.sort = sortCode;
    }
    this.sortType = sortCode;
    this.knBrOrderHistoryService.getOrderFormProducts(this.searchCriteria);
  }

  onChangeProduct(eventData) {
    if (eventData.checked) {
      const index = _.findIndex(this.checkedProducts, { productCode: eventData.productCode });
      // Replace item at index using native splice
      if (index >= 0) {
        this.checkedProducts[index] = eventData;
      } else {
        this.checkedProducts.push(eventData);
      }
    } else {
      _.remove(this.checkedProducts, {
        productCode: eventData.productCode,
      });
    }
  }

  onpageChange(page: number): void {
    if (this.searchCriteria) {
      this.searchCriteria.currentPage = page;
    }
    this.knBrOrderHistoryService.getOrderFormProducts(this.searchCriteria);
  }

  createOrderForm() {
    const checkedProductList = this.checkedProducts.map((product) => product.productCode).join(',');
    this.knBrOrderHistoryService.createOrderFormData(checkedProductList);
    this.knBrOrderHistoryService.setOrderForm(true);
    this.isOrderForm = true;
    this.checkedProducts = [];
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.knBrOrderHistoryService.resetOrderFormsData();
    this.launchDialogService.clear(LAUNCH_CALLER.PLACE_ORDER_SPINNER);
  }
}
