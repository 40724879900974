import { RoutingConfig } from '@spartacus/core';

export const defaultOrderRoutingConfig: RoutingConfig = {
  routing: {
    routes: {
      customer360: {
        paths: ['customer-360/'],
      },
      AccountAddressBookComponent: {
        paths: ['customer-360/address-book'],
      },
      orders: {
        paths: ['customer-360/orders'],
      },
      orderDetails: {
        paths: ['customer-360/order/:orderCode'],
        paramsMapping: { orderCode: 'code' },
      },
      orderReturn: {
        paths: ['customer-360/order/return/:orderCode/:returnType'],
        paramsMapping: { orderCode: 'code', returnType: 'returnType' },
      },
      orderReturnConfirmation: {
        paths: ['customer-360/order/return/confirmation/:orderCode/:type/:rma'],
        paramsMapping: { orderCode: 'code', type: 'type', rma: 'rma' },
      },
      orderCancelConfirmation: {
        paths: ['customer-360/order/cancel/confirmation/:orderCode/:type'],
        paramsMapping: { orderCode: 'code', type: 'type' },
      },
      orderEditConfirmation: {
        paths: ['customer-360/order/edit/confirmation/:orderCode/:type/:orderNumber'],
        paramsMapping: { orderCode: 'code', type: 'type', orderNumber: 'orderNumber' },
      },
      returnOrders: {
        paths: ['customer-360/returns'],
      },
      returnRequestDetails: {
        paths: ['customer-360/return-request/:returnCode'],
        paramsMapping: { returnCode: 'code' },
      },
      orderConfirmation: {
        paths: ['order-confirmation'],
      },
      factSheet: {
        paths: ['customer-360/fact-sheet'],
      },
      invoiceList: {
        paths: ['customer-360/invoice-list'],
      },
    },
  },
};
