import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SloanFactSheetHeaderComponent } from './sloan-fact-sheet-header.component';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';

@NgModule({
  declarations: [SloanFactSheetHeaderComponent],
  imports: [
    CommonModule,
    I18nModule,
    ConfigModule.withConfig({
      cmsComponents: {
        KnBrFactSheetHeaderComponent: {
          component: () => import('./sloan-fact-sheet-header.component').then((c) => c.SloanFactSheetHeaderComponent),
        },
      },
    } as CmsConfig),
  ],
})
export class SloanFactSheetHeaderModule { }
