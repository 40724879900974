import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KnBrQuickEntryComponent } from './kn-br-quick-entry.component';
import { AuthGuard, CmsConfig, ConfigModule, I18nModule, RoutingConfig } from '@spartacus/core';
import { RouterModule } from '@angular/router';
import { CmsPageGuard, PageLayoutComponent } from '@spartacus/storefront';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { KnBrDirectiveModule } from 'src/app/shared/directive/kn-br-directive.module';
import { KnBrAddToCartModule } from 'src/feature-libs/kn-br-product/components/kn-br-add-to-cart/kn-br-add-to-cart.module';
import { KnBrAddToQuoteModule } from 'src/feature-libs/kn-br-product/components/kn-br-add-to-quote/kn-br-add-to-quote.module';

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    KnBrAddToCartModule,
    KnBrAddToQuoteModule,
    KnBrDirectiveModule,
    ConfigModule.withConfig({
      cmsComponents: {
        KnBrQuickEntryComponent: {
          component: () => import('./kn-br-quick-entry.component').then((c) => c.KnBrQuickEntryComponent),
        },
      },
    } as CmsConfig),
    RouterModule.forChild([
      {
        data: { pageLabel: '/quick-entry', cxRoute: 'quickEntry' },
        path: null,
        canActivate: [AuthGuard, CmsPageGuard],
        component: PageLayoutComponent,
      },
    ]),
    ConfigModule.withConfig({
      routing: {
        routes: {
          quickEntry: {
            paths: ['quick-entry'],
          },
        },
      },
    } as RoutingConfig),
  ],
  declarations: [KnBrQuickEntryComponent],
})
export class KnBrQuickEntryModule { }
