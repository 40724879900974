import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AuthGuard, ConfigModule, I18nModule, RoutingConfig } from '@spartacus/core';
import { CmsPageGuard, FormErrorsModule, PageLayoutComponent } from '@spartacus/storefront';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { KnBrDownloadConfirmationDialogComponent } from './components/kn-br-download-confirmation-dialog/kn-br-download-confirmation-dialog.component';
import { KnBrNacConfigurableModule } from './components/kn-br-nac-configurable/kn-br-nac-configurable.module';
import { KnBrUpdateTemplateDialogComponent } from './components/kn-br-update-template-dialog/kn-br-update-template-dialog.component';
import { KnBrUploadNewTemplateDialogComponent } from './components/kn-br-upload-new-template-dialog/kn-br-upload-new-template-dialog.component';

@NgModule({
  declarations: [
    KnBrUploadNewTemplateDialogComponent,
    KnBrUpdateTemplateDialogComponent,
    KnBrDownloadConfirmationDialogComponent,
  ],
  imports: [
    CommonModule,
    KnBrNacConfigurableModule,
    FileUploadModule,
    ReactiveFormsModule,
    FormsModule,
    FormErrorsModule,
    I18nModule,
    RouterModule.forChild([
      {
        data: { pageLabel: '/nac', cxRoute: 'nac' },
        path: null,
        canActivate: [AuthGuard, CmsPageGuard],
        component: PageLayoutComponent,
      },
    ]),
    ConfigModule.withConfig({
      routing: {
        routes: {
          nac: {
            paths: ['nac'],
          },
        },
      },
    } as RoutingConfig),
  ],
})
export class KnBrNacModule {}
