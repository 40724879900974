/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FeaturesConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { IconModule, SplitViewModule } from '@spartacus/storefront';
import { SloanOrganizationCardComponent } from './card.component';
import { PopoverModule } from '@spartacus/storefront';
import { MessageModule } from '@spartacus/organization/administration/components';
/**
 * Provides a reusable card UI component for the organization split views.
 *
 * The component does not intend to provide a complete set of card features, it's just
 * a reusable component inside the organization UI.
 */
@NgModule({
  imports: [
    CommonModule,
    SplitViewModule,
    RouterModule,
    I18nModule,
    IconModule,
    UrlModule,
    MessageModule,
    PopoverModule,
    // TODO:(CXSPA-1695) #deprecation for next major release remove below feature config
    FeaturesConfigModule,
  ],
  declarations: [SloanOrganizationCardComponent],
  exports: [SloanOrganizationCardComponent],
})
export class SloanOrganizationCardModule {}
