<div *ngIf="hasHeader" class="d-none d-md-block d-lg-block d-xl-block">
  <div class="cx-item-list-header row">
    <div class="cx-item-list-desc col-md-5 col-lg-5 col-xl-6">
      {{ 'cartItems.item' | cxTranslate }}
    </div>
    <div class="cx-item-list-price col-md-3 col-lg-2 col-xl-2">
      {{ 'cartCommon.label.netPrice' | cxTranslate }}
    </div>
    <div class="cx-item-list-qty col-md-2 col-lg-3 col-xl-2">
      {{ 'cartItems.quantity' | cxTranslate }}
    </div>

    <ng-container *ngIf="options.isSaveForLater; else totalHeader">
      <div class="cx-item-list-total col-md-2 col-lg-2 col-xl-2">
        {{ 'saveForLaterItems.stock' | cxTranslate }}
      </div>
    </ng-container>
  </div>
</div>

<div class="cx-item-list-row" *ngFor="let item of items; let i = index">
  <div
    *ngIf="getControl(item) | async as control"
    class="cx-item-list-items"
    [class.is-changed]="
      featureConfigService?.isLevel('3.1') ? control.get('quantity').disabled : control.get('quantity').dirty
    "
  >
    <cx-cart-item
      [item]="item"
      [quantityControl]="control.get('quantity')"
      [readonly]="readonly"
      [promotionLocation]="promotionLocation"
      [options]="options"
    >
    </cx-cart-item>
  </div>
</div>

<ng-template #totalHeader>
  <div class="cx-item-list-total col-md-2 col-lg-2 col-xl-2">
    {{ 'cartItems.total' | cxTranslate }}
  </div>
</ng-template>
