import { Observable } from 'rxjs';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';
import { FormBuilder, Validators, FormArray } from '@angular/forms';
import { Component, OnInit, ChangeDetectorRef, OnDestroy, AfterViewInit, ViewContainerRef } from '@angular/core';
import { KnBrOrderHistoryService } from 'src/feature-libs/kn-br-order/core/facade/kn-br-order-history.service';
import { LaunchDialogService } from '@spartacus/storefront';
import { KnBrAddProductsComponent } from 'src/feature-libs/kn-br-order/components/kn-br-customer360/kn-br-order-detail/kn-br-add-products/kn-br-add-products.component';
import { KnBrDraftOrdersService } from 'src/feature-libs/kn-br-cart/draft-order/core/facade/kn-br-draft-orders.service';
import { KnBrQuoteEntryService } from 'src/feature-libs/kn-br-cart/quote/core/facade/kn-br-quote-entry.service';
import { KnBrQuotesService } from 'src/feature-libs/kn-br-cart/quote/core/facade/kn-br-quotes.service';
@Component({
  selector: 'sloan-add-products',
  templateUrl: './sloan-add-products.component.html',
  styleUrls: ['./sloan-add-products.component.scss'],
})
export class SloanAddProductsComponent extends KnBrAddProductsComponent implements OnInit, AfterViewInit, OnDestroy {
  showMOQWarningMsg = false;
  quickCreateloader$: Observable<boolean> = this.knBrQuotesService.isCreateDisabled$;
  constructor(
    protected formBuilder: FormBuilder,
    protected cdr: ChangeDetectorRef,
    protected knBrOrderHistoryService: KnBrOrderHistoryService,
    protected knBrDraftOrderService: KnBrDraftOrdersService,
    protected knbrCommonService: KnBrCommonService,
    protected launchDialogService: LaunchDialogService,
    protected vcr: ViewContainerRef,
    protected knBrQuoteEntryService: KnBrQuoteEntryService,
    protected knBrQuotesService: KnBrQuotesService
  ) {
    super(
      formBuilder,
      cdr,
      knBrOrderHistoryService,
      knBrDraftOrderService,
      knbrCommonService,
      launchDialogService,
      vcr,
      knBrQuoteEntryService
    );
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.subscription.add(
      this.quickCreateloader$.subscribe((isLoading) => {
        this.onSpinnerLoading(isLoading);
      })
    );
  }

  createProductForm(index, product: any) {
    this.t.setControl(
      index,
      this.formBuilder.group({
        productCode: [product.code, Validators.required],
        image: [product],
        listPrice: [product.price?.formattedValue],
        qty: [product.minOrderQuantity ? product.minOrderQuantity : 1, Validators.required],
        total: [Number(product.syncResponse?.items[0].netPriceValue)],
        showForm: [product.errorMsg || !product.price || !product.price?.formattedValue ? false : true],
        showErrorMsg: [product.errorMsg || !product.price || !product.price?.formattedValue ? true : false],
        errorMsg: [product.errorMsg ? product.errorMsg : null],
        syncResponse: [product.syncResponse],
        netPrice: [product.syncResponse?.items[0].netPrice],
      })
    );
    this.cdr.markForCheck();
    this.prepareDataAndEmit();
  }

  prepareDataAndEmit() {
    super.prepareDataAndEmit();
    this.checkAllQuantiesAreCorrect();
  }

  checkAllQuantiesAreCorrect() {
    const productsForMoqCheck = this.t.value;
    if (productsForMoqCheck && productsForMoqCheck.length > 0) {
      const wrongMoqProduct = productsForMoqCheck.find((product) => product.qty % product.image?.minOrderQuantity > 0);
      if (wrongMoqProduct) {
        this.showMOQWarningMsg = true;
      } else {
        this.showMOQWarningMsg = false;
      }
    }
  }

  formInit() {
    this.addProductsForm = this.formBuilder.group({
      sku: [''],
      products: new FormArray([]),
    });
    this.t.push(
      this.formBuilder.group({
        productCode: [null, Validators.required],
        showForm: [false],
        image: [''],
        listPrice: [''],
        qty: [1, Validators.required],
        total: [''],
        showErrorMsg: [false],
        errorMsg: [''],
      })
    );
    this.addQuickEntryBufferRow();
    this.addQuickEntryBufferRow();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
