import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TranslationService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { KnBrDateHelper } from 'src/app/shared/kn-br-date.helper';
import { KnBrCustomerContextService } from 'src/app/store/kn-br-customer-context/kn-br-customer-context.service';
import { KnBrInvoiceService } from 'src/feature-libs/kn-br-order/core/facade/kn-br-invoice.service';
import { WIDGET_PAGESIZE } from 'src/constants/pagination.constant';
import { KnBrInvoiceListSeachResultComponent } from '../../kn-br-invoice-list/kn-br-invoice-list-seach-result/kn-br-invoice-list-seach-result.component';
import { CustomerData } from 'src/feature-libs/kn-br-user/kn-br-account/root/model/customer.model';

@Component({
  selector: 'knbr-kn-br-quick-invoice',
  templateUrl: './kn-br-quick-invoice.component.html',
  styleUrls: ['./kn-br-quick-invoice.component.scss'],
})
export class KnBrQuickInvoiceComponent extends KnBrInvoiceListSeachResultComponent implements OnInit, OnDestroy {
  invoiceSearchForm: UntypedFormGroup;
  selectedCustomer: CustomerData = null;
  customerContextData$: Observable<CustomerData> = this.knBrCustomerContextService.get$;
  currentPage = 0;
  isSearchDisabled$: Observable<boolean> = this.knBrInvoiceService.isSearchDisabled$;
  constructor(
    public knBrInvoiceService: KnBrInvoiceService,
    protected translation: TranslationService,
    protected knBrDateHelper: KnBrDateHelper,
    protected fb: UntypedFormBuilder,
    protected knBrCustomerContextService: KnBrCustomerContextService
  ) {
    super(knBrInvoiceService, translation);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.customerContextData$.subscribe((value) => {
      if (value) {
        this.selectedCustomer = value;
      }
      this.initForm();
    });
    this.submitForm();
  }

  initForm() {
    this.invoiceSearchForm = this.fb.group({
      startDate: null,
      endDate: null,
      currentPage: this.currentPage,
      pageSize: WIDGET_PAGESIZE,
      isWidget: true,
    });
  }

  submitForm() {
    const formValues = this.invoiceSearchForm.getRawValue();
    if (this.invoiceSearchForm.controls.startDate.value) {
      formValues.startDate = this.knBrDateHelper.formatDate(this.invoiceSearchForm.controls.startDate.value);
    }
    if (this.invoiceSearchForm.controls.endDate.value) {
      formValues.endDate = this.knBrDateHelper.formatDate(this.invoiceSearchForm.controls.endDate.value);
    }
    this.knBrInvoiceService.search(formValues);
  }

  pageChange(page: number): void {
    if (this.searchCriteria) {
      this.searchCriteria.currentPage = page;
    }
    this.knBrInvoiceService.search(this.searchCriteria);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
