import { Component, OnInit } from '@angular/core';
import { CmsNavigationComponent, createFrom, EventService } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { KnBrNavigationService } from '../../services/kn-br-navigation.service';
import { MarkettingCenterLinkDownlaodEvent } from '../../store/custome-spartacus-events';

@Component({
  selector: 'knbr-quick-links',
  templateUrl: './kn-br-quick-links.component.html',
  styleUrls: ['./kn-br-quick-links.component.scss'],
})
export class KnBrQuickLinksComponent implements OnInit {
  componentData$: Observable<CmsNavigationComponent> = this.componentData.data$;
  node$: Observable<any> = this.service.createNavigation(this.componentData.data$);
  baseURL = environment.BASEURL;

  private readonly PROTOCOL_REGEX: RegExp = /^https?:\/\//i;

  constructor(
    protected componentData: CmsComponentData<CmsNavigationComponent>,
    protected service: KnBrNavigationService,
    private events: EventService
  ) {}

  ngOnInit() {}
  knbrLinkClickEvent(data, title): void {
    this.events.dispatch(createFrom(MarkettingCenterLinkDownlaodEvent, { link: data, section: title }));
    const ga = (window as any).ga;
    const tracker = ga?.getAll()[0];
    tracker?.set('page', data);
    tracker?.send('event');
  }
  /**
   * Returns true when the `url` is a string starting with `http://` or `https://`.
   */
  isExternalUrl(mediaUrl: string): boolean {
    return typeof mediaUrl === 'string' && this.PROTOCOL_REGEX.test(mediaUrl);
  }
}
