import { NgSelectModule } from '@ng-select/ng-select';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { I18nModule } from '@spartacus/core';

import { SloanQuoteCommentsPopupComponent } from './sloan-quote-comments-popup.component';
import { KnBrDirectiveModule } from 'src/app/shared/directive/kn-br-directive.module';

@NgModule({
  declarations: [SloanQuoteCommentsPopupComponent],
  imports: [CommonModule, I18nModule, FormsModule, ReactiveFormsModule, NgSelectModule, KnBrDirectiveModule],
})
export class SloanQuoteCommentsPopupModule {}
