import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { NgbActiveModal, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CsAgentAuthService } from '@spartacus/asm/root';
import { AuthGuard, AuthRedirectService, AuthStatePersistenceService, ConfigModule, CxDatePipe } from '@spartacus/core';
import { B2BUserService } from '@spartacus/organization/administration/core';
import { AppRoutingModule } from '@spartacus/storefront';
import { knBrAsmLayoutConfig } from 'src/feature-libs/kn-br-asm/components/kn-br-asm-layout-config';
import { KnBrCsAgentAuthService } from 'src/feature-libs/kn-br-asm/root/service/kn-br-csagent-auth.service';
import { KnBrB2BUserService } from 'src/feature-libs/kn-br-organization/administration/core/service/kn-br-b2b-user.service';
import { KnBrI18nModule } from './i18n/i18n.module';
import { CustomerIdMapHttpInterceptor } from './interceptors/customer-id-map-http-config.interceptor';
import { KnBrBackButtonModule } from './kn-br-back-button/kn-br-back-button.module';
import { KnBrBannerModule } from './kn-br-banner/kn-br-banner.module';
import { KnBrBreadCrumbModule } from './kn-br-bread-crumb/kn-br-bread-crumb.module';
import { KnBrBrowsingModule } from './kn-br-browsing/kn-br-browsing.module';
import { KnBrConfirmDialogModule } from './kn-br-confirm-dialog/kn-br-confirm-dialog.module';
import { KnBrCustomersTitleModule } from './kn-br-customer-title/kn-br-customer-title.module';
import { KnBrFooterModule } from './kn-br-footer/kn-br-footer.module';
import { KnBrMessageDialogModule } from './kn-br-message-dialog/kn-br-message-dialog.module';
import { KnBrMetaResolverModule } from './kn-br-meta-resolvers/kn-br-meta-resolver.module';
import { KnBrOutletModule } from './kn-br-outlet/kn-br-outlet.module';
import { KnBrParagraphModule } from './kn-br-paragraph/kn-br-paragraph.module';
import { KnBrProductCarouselModule } from './kn-br-product-carousel/kn-br-product-carousel.module';
import { KnBrDocViewerModule } from './kn-br-doc-viewer/kn-br-doc-viewer.module';
import { KnBrMiniCartModule } from 'src/feature-libs/kn-br-cart/base/components/kn-br-mini-cart/kn-br-mini-cart.module';
import { KnBrAuthRedirectService } from 'src/feature-libs/kn-br-user/kn-br-account/root/facade/kn-br-auth-redirect.service';
import { KnBrAuthStatePersistenceService } from '../services/kn-br-auth-state-persistence.service';
import { KnBrAuthGuard } from '../shared/guards/kn-br-auth.guard';
import { MomentDateFormatter } from '../shared/moment-date.formatter';

@NgModule({
  imports: [
    CommonModule,
    KnBrI18nModule,
    KnBrBackButtonModule,
    KnBrBannerModule,
    KnBrBreadCrumbModule,
    KnBrBrowsingModule,
    KnBrConfirmDialogModule,
    KnBrDocViewerModule,
    KnBrFooterModule,
    KnBrMetaResolverModule,
    KnBrMiniCartModule,
    KnBrOutletModule,
    KnBrParagraphModule,
    KnBrCustomersTitleModule,
    KnBrMessageDialogModule,
    KnBrProductCarouselModule,
    AppRoutingModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
  ],
  declarations: [],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useExisting: CustomerIdMapHttpInterceptor,
      multi: true,
    },

    {
      provide: AuthRedirectService,
      useClass: KnBrAuthRedirectService,
    },
    {
      provide: CsAgentAuthService,
      useClass: KnBrCsAgentAuthService,
    },
    {
      provide: AuthStatePersistenceService,
      useClass: KnBrAuthStatePersistenceService,
    },
    {
      provide: B2BUserService,
      useClass: KnBrB2BUserService,
    },
    {
      provide: AuthGuard,
      useClass: KnBrAuthGuard,
    },
    { provide: NgbDateParserFormatter, useClass: MomentDateFormatter },
    CxDatePipe,
    NgbActiveModal,
  ],
})
export class KnBrCommonModule { }
