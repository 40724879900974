import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthGuard, CmsConfig, provideConfig, provideConfigFactory } from '@spartacus/core';
import { CmsPageGuard, PageLayoutComponent } from '@spartacus/storefront';
import { defaultOrderRoutingConfig } from './config/default-order-routing-config';
import { ORDER_CORE_FEATURE, ORDER_FEATURE } from './feature-name';
import { KnBrCustomerContextGuard } from 'src/app/shared/guards/kn-br-customer-context.guard';
// TODO: Inline this factory when we start releasing Ivy compiled libraries
export function defaultOrderComponentsConfig(): CmsConfig {
  const config: CmsConfig = {
    featureModules: {
      [ORDER_FEATURE]: {
        cmsComponents: [
          'KnBrOrderAcknowledgeComponent',
          'AccountAddressBookComponent',
          'KnBr360InvoiceHistoryComponent',
          'KnBr360OrderHistoryComponent',
          'KnBr360ReturnHistoryComponent',
          'KnBrFactSheetGroupComponent',
          'KnBrFactSheetHeaderComponent',
          'KnBrFactSheetMaterialComponent',
          'KnBrInvoiceHeaderComponent',
          'KnBrInvoiceSearchResultComponent',
          'KnBrInvoiceSearchComponent',
          'AccountOrderDetailsActionsComponent',
          'AccountOrderDetailsItemsComponent',
          'AccountOrderDetailsOverviewComponent',
          'KnBrOrderHistorySearchComponent',
          'AccountOrderHistoryComponent',
          'ReturnOrderComponent',
          'ReturnRequestItemsComponent',
          'ReturnRequestOverviewComponent',
          'KnBrReturnOrderSearchComponent',
          'OrderReturnRequestListComponent',
          'OrderConfirmationItemsComponent',
          'OrderConfirmationOverviewComponent'
        ],
      },
      // by default core is bundled together with components
      [ORDER_CORE_FEATURE]: ORDER_FEATURE,
    },
  };

  return config;
}

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        data: { pageLabel: '/customer-360/', cxRoute: 'customer360' },
        path: null,
        canActivate: [AuthGuard, CmsPageGuard],
        component: PageLayoutComponent,
      },
      {
        path: 'customer-360/order/:orderCode',
        component: PageLayoutComponent,
        data: { pageLabel: '/customer-360/order' },
        canActivate: [CmsPageGuard],
      },
      {
        data: { pageLabel: '/customer-360/fact-sheet', cxRoute: 'factSheet' },
        path: null,
        canActivate: [AuthGuard, CmsPageGuard, KnBrCustomerContextGuard],
        component: PageLayoutComponent,
      },
      {
        data: { pageLabel: '/customer-360/invoice-list', cxRoute: 'invoiceList' },
        path: null,
        canActivate: [AuthGuard, CmsPageGuard, KnBrCustomerContextGuard],
        component: PageLayoutComponent,
      },
      {
        data: { pageLabel: 'customer-360/returns', cxRoute: 'returnOrders' },
        path: null,
        canActivate: [AuthGuard, CmsPageGuard],
        component: PageLayoutComponent,
      },
      {
        data: { pageLabel: '/customer-360/order/edit/confirmation', cxRoute: 'orderEditConfirmation' },
        path: null,
        canActivate: [AuthGuard, CmsPageGuard],
        component: PageLayoutComponent,
      },
      {
        path: 'customer-360/order/cancel/confirmation/:orderCode/:type',
        component: PageLayoutComponent,
        data: { pageLabel: '/customer-360/order/cancel/confirmation' },
        canActivate: [CmsPageGuard],
      },
      {
        path: 'customer-360/return-request/:returnCode',
        component: PageLayoutComponent,
        data: { pageLabel: '/customer-360/return-request' },
        canActivate: [CmsPageGuard],
      },
      {
        path: 'customer-360/order/return/:orderCode/:returnType',
        component: PageLayoutComponent,
        data: { pageLabel: '/customer-360/order/return' },
        canActivate: [CmsPageGuard],
      },
      {
        path: 'customer-360/order/return/confirmation/:orderCode/:type/:rma',
        component: PageLayoutComponent,
        data: { pageLabel: '/customer-360/order/return/confirmation' },
        canActivate: [CmsPageGuard],
      },
    ]),
  ],
  providers: [
    provideConfigFactory(defaultOrderComponentsConfig),
    provideConfig(defaultOrderRoutingConfig),
  ],
})
export class OrderRootModule { }
