import { SloanQuickEntryComponent } from './sloan-quick-entry.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { KnBrDirectiveModule } from 'src/app/shared/directive/kn-br-directive.module';
import { SloanAddToCartModule } from 'src/feature-libs/sloan/sloan-product/sloan-add-to-cart/sloan-add-to-cart.module';
import { SloanAddToQuoteModule } from 'src/feature-libs/sloan/sloan-product/sloan-add-to-quote/sloan-add-to-quote.module';

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    SloanAddToCartModule,
    SloanAddToQuoteModule,
    KnBrDirectiveModule,
    ConfigModule.withConfig({
      cmsComponents: {
        KnBrQuickEntryComponent: {
          component: SloanQuickEntryComponent,
        },
      },
    } as CmsConfig),
  ],
  declarations: [SloanQuickEntryComponent],
})
export class SloanQuickEntryModule {}
