import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { TranslationService } from '@spartacus/core';
import { LaunchDialogService } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { SloanOrderHistory } from 'src/app/services/sloan/sloan-order-history.service';
import { KnBrCustomerContextService } from 'src/app/store/kn-br-customer-context/kn-br-customer-context.service';
import { KnBrOrderHistoryService } from 'src/feature-libs/kn-br-order/core/facade/kn-br-order-history.service';
import { KnBrOrderFormSearchResultComponent } from 'src/feature-libs/kn-br-cart/kn-br-order-tools/kn-br-order-forms/kn-br-order-form-search-result/kn-br-order-form-search-result.component';
import { KnBrCartContextService } from 'src/feature-libs/kn-br-cart/base/core/facade/kn-br-cart-context.service';
import { KnBrQuoteContextService } from 'src/feature-libs/kn-br-cart/quote/core/facade/kn-br-quote-context.service';
import { KnBrQuoteEntryService } from 'src/feature-libs/kn-br-cart/quote/core/facade/kn-br-quote-entry.service';
import { KnBrDraftOrdersService } from 'src/feature-libs/kn-br-cart/draft-order/core/facade/kn-br-draft-orders.service';

@Component({
  selector: 'knbr-kn-br-order-form-search-result',
  templateUrl: './sloan-order-form-search-result.component.html',
  styleUrls: ['./sloan-order-form-search-result.component.scss'],
})
export class SloanOrderFormSearchResultComponent
  extends KnBrOrderFormSearchResultComponent
  implements OnInit, OnDestroy {
  isCustomerContextAvailable = false;
  showMOQWarningMsg = false;
  quoteEntryloader$: Observable<boolean> = this.knBrQuoteEntryService.quickEntryLoader$;
  availability$: Observable<any> = this.sloanOrderHistory.availability$;
  productList;

  constructor(
    protected knBrOrderHistoryService: KnBrOrderHistoryService,
    protected translation: TranslationService,
    protected knBrCartContextService: KnBrCartContextService,
    protected knBrQuoteEntryService: KnBrQuoteEntryService,
    protected knBrQuoteContextService: KnBrQuoteContextService,
    protected cdr: ChangeDetectorRef,
    protected knBrDraftOrderService: KnBrDraftOrdersService,
    protected knBrCustomerContextService: KnBrCustomerContextService,
    protected launchDialogService: LaunchDialogService,
    protected vcr: ViewContainerRef,
    protected sloanOrderHistory: SloanOrderHistory
  ) {
    super(
      knBrOrderHistoryService,
      translation,
      knBrCartContextService,
      knBrQuoteContextService,
      cdr,
      knBrDraftOrderService,
      launchDialogService,
      vcr
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.subscription.add(
      this.knBrCustomerContextService.get$.subscribe((res) => {
        if (res) {
          this.isCustomerContextAvailable = true;
        } else {
          this.isCustomerContextAvailable = false;
        }
        this.cdr.markForCheck();
      })
    );
    this.subscription.add(
      this.products$.subscribe((res) => {
        this.productList = res?.products;
        this.showMOQWarningMsg = false;
      })
    );
    this.subscription.add(
      this.quoteEntryloader$.subscribe((isloading) => {
        this.onSpinnerLoading(isloading);
      })
    );
    this.subscription.add(this.sloanOrderHistory.loader$.subscribe((isLoading) => this.onSpinnerLoading(isLoading)));
  }

  onQuantityChange(eventData) {
    super.onQuantityChange(eventData);
    const moqInCorrectProducts = this.selectedProducts.filter((selectedProduct) => {
      const productWithMoq = this.productList.find((product) => selectedProduct.productCode === product.code);
      if (productWithMoq && selectedProduct && selectedProduct.quantity % productWithMoq.minOrderQuantity > 0) {
        return true;
      }
      return false;
    });
    if (moqInCorrectProducts && moqInCorrectProducts.length > 0) {
      this.showMOQWarningMsg = true;
    } else {
      this.showMOQWarningMsg = false;
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
