<ng-container *ngIf="componentData$ | async as data">
  <cx-generic-link [url]="data.urlLink" [target]="data.external == 'true' ? '_blank' : null">
    <div *ngIf="data.headline || data.content" class="carousel-caption">
      <p class="main-heading" *ngIf="data.headline" [innerHTML]="data.headline"></p>
      <p class="sub-heading" *ngIf="data.content" [innerHTML]="getContent(data)"></p>
      <button *ngIf="data.content" class="btn btn-success">{{ getButtonText(data) }}</button>
    </div>
    <cx-media [container]="data.media"></cx-media>
    <h3 *ngIf="data?.media?.desktop?.altText" class="text-center category-text">
      {{ data?.media?.desktop?.altText }}
    </h3>
  </cx-generic-link>
</ng-container>
