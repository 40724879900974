import { SloanDraftOrderItemComponent } from './sloan-draft-order-item.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UrlModule, RoutingModule, I18nModule } from '@spartacus/core';
import { MediaModule, FormErrorsModule } from '@spartacus/storefront';
import { KnBrDirectiveModule } from 'src/app/shared/directive/kn-br-directive.module';



@NgModule({
  declarations: [SloanDraftOrderItemComponent],
  imports: [
    CommonModule,
    RouterModule,
    UrlModule,
    RoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MediaModule,
    NgbModule,
    I18nModule,
    KnBrDirectiveModule,
    FormErrorsModule,
  ],
  exports: [SloanDraftOrderItemComponent]
})
export class SloanDraftOrderItemModule { }
