import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { KnBrDocViewerComponent } from './kn-br-doc-viewer.component';

@NgModule({
  imports: [CommonModule, NgxDocViewerModule],
  declarations: [KnBrDocViewerComponent],
  exports: [KnBrDocViewerComponent],
})
export class KnBrDocViewerModule { }
