import { KnBrOrderReturnModule } from './kn-br-order-return/kn-br-order-return.module';
import { ConfigModule, RoutingConfig } from '@spartacus/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    KnBrOrderReturnModule,
  ],
})
export class KnBrReturnOrderModule { }
