import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { ConfigModule, I18nModule } from '@spartacus/core';
import { FormErrorsModule } from '@spartacus/storefront';
import { SloanCustomerSelectionComponent } from './sloan-customer-selection/sloan-customer-selection.component';
import { SloanCustomerEmulationComponent } from './sloan-customer-emulation/sloan-customer-emulation.component';
import { SloanAsmMainUiComponent } from './sloan-asm-main-ui.component';
import { SloanCsAgentLoginFormComponent } from './sloan-cs-agent-login-form/sloan-cs-agent-login-form.component';
import { SloanAsmSessionTimerComponent } from './sloan-asm-session-timer/sloan-asm-session-timer.component';
import { SloanAsmToggleUiComponent } from './sloan-asm-toggle-ui/sloan-asm-toggle-ui.component';
import { SloanFormatTimerPipe } from './sloan-asm-session-timer/sloan-format-timer.pipe';
import { defaultKnBrOccAsmConfig } from './occ/sloan-asm-endpoint.config';

@NgModule({
  declarations: [
    SloanCustomerSelectionComponent,
    SloanCustomerEmulationComponent,
    SloanAsmMainUiComponent,
    SloanCsAgentLoginFormComponent,
    SloanAsmSessionTimerComponent,
    SloanAsmToggleUiComponent,
    SloanFormatTimerPipe,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    I18nModule,
    FormErrorsModule,
    ConfigModule.withConfig(defaultKnBrOccAsmConfig),
  ],
})
export class SloanAsmModule { }
