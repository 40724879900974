import { Injectable } from '@angular/core';
import {
  BreadcrumbMeta,
  CmsService,
  Page,
  PageMetaResolver,
  PageRobotsMeta,
  PageRobotsResolver,
  PageTitleResolver,
  PageType,
  TranslationService,
} from '@spartacus/core';
import { ActiveCartService } from '@spartacus/cart/base/core';
import { Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ActiveCartFacade } from '@spartacus/cart/base/root';

/**
 * Resolves the page data for all Content Pages based on the `PageType.CONTENT_PAGE`
 * and the `MultiStepCheckoutSummaryPageTemplate`. If the checkout page matches this template,
 * the more generic `ContentPageMetaResolver` is overriden by this resolver.
 *
 * The page title and robots are resolved in this implementation only.
 */
@Injectable({
  providedIn: 'root',
})
export class KnBrCheckoutPageMetaResolver extends PageMetaResolver implements PageTitleResolver, PageRobotsResolver {
  protected cart$ = this.activeCartService.getActive();
  protected cms$: Observable<Page> = this.cms.getCurrentPage().pipe(filter((p) => Boolean(p)));
  constructor(
    protected cms: CmsService,
    protected translation: TranslationService,
    protected activeCartService: ActiveCartFacade
  ) {
    super();
    this.pageType = PageType.CONTENT_PAGE;
    this.pageTemplate = 'MultiStepCheckoutSummaryPageTemplate';
  }

  resolveTitle(): Observable<string> {
    return this.cms$.pipe(map((p) => p.title));
  }

  resolveRobots(): Observable<PageRobotsMeta[]> {
    return of([PageRobotsMeta.NOFOLLOW, PageRobotsMeta.NOINDEX]);
  }

  /**
   * Resolves a single breacrumb item to the home page for each `ContentPage`.
   * The home page label is resolved from the translation service.
   */
  resolveBreadcrumbs(): Observable<BreadcrumbMeta[]> {
    return this.resolveTitle().pipe(map((label) => [{ label: label, link: null }] as BreadcrumbMeta[]));
  }
}
