import { Component, OnInit } from '@angular/core';
import { AsmComponentService } from '@spartacus/asm/components';
// TODO:Spartacus - // TODO:Spartacus - Class UserService has been removed and is no longer part of the public API.
import { UserAccountFacade } from '@spartacus/user/account/root';
import { KnBrCustomerEmulationComponent } from 'src/feature-libs/kn-br-asm/components/kn-br-customer-emulation/kn-br-customer-emulation.component';

@Component({
  selector: 'cx-customer-emulation',
  templateUrl: './sloan-customer-emulation.component.html',
  styleUrls: ['./sloan-customer-emulation.component.scss'],
})
export class SloanCustomerEmulationComponent extends KnBrCustomerEmulationComponent implements OnInit {
  constructor(protected asmComponentService: AsmComponentService, protected userService: UserAccountFacade) {
    super(asmComponentService, userService);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
