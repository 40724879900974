import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SloanReturnOrderDetailsOverviewComponent } from './sloan-return-order-details-overview.component';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { KnBrCustomPipesModule } from 'src/app/shared/pipe/kn-br-custom-pipes/kn-br-custom-pipes.module';

@NgModule({
  declarations: [SloanReturnOrderDetailsOverviewComponent],
  imports: [
    CommonModule,
    I18nModule,
    KnBrCustomPipesModule,
    ConfigModule.withConfig({
      cmsComponents: {
        ReturnRequestOverviewComponent: {
          component: SloanReturnOrderDetailsOverviewComponent,
        },
      },
    } as CmsConfig),
  ],
})
export class SloanReturnOrderDetailsOverviewModule {}
