import { createFeatureSelector, createSelector } from '@ngrx/store';
import { KnBrOrderReturnState, KN_BR_ORDER_RETURN_FEATURE } from '../../../../../app/store/kn-br-order-return/kn-br-order-return.state';

export const getOrderReturnState = createFeatureSelector<KnBrOrderReturnState>(KN_BR_ORDER_RETURN_FEATURE);

export const getOrderReturn = createSelector(
  getOrderReturnState,
  (state: KnBrOrderReturnState) => state.returnRequests
);

export const getOrderReturnLoader = createSelector(
  getOrderReturnState,
  (state: KnBrOrderReturnState) => state.isLoading
);

export const getOrderReturnPaginate = createSelector(
  getOrderReturnState,
  (state: KnBrOrderReturnState) => state.pagination
);

export const getOrderRetrunSorts = createSelector(getOrderReturnState, (state: KnBrOrderReturnState) => state.sorts);

export const getOrderReturnSearchCriteria = createSelector(
  getOrderReturnState,
  (state: KnBrOrderReturnState) => state.searchRequest
);

export const getOrderReturnSearchDisabled = createSelector(
  getOrderReturnState,
  (state: KnBrOrderReturnState) => state.isSearchDisabled
);
