import { NgModule } from '@angular/core';
import { ConfigModule, I18nModule } from '@spartacus/core';

@NgModule({
  imports: [
    I18nModule,
    ConfigModule.withConfig({
      i18n: {
        backend: {
          loadPath: 'assets/i18n-assets/{{lng}}/{{ns}}.json',
        },
        chunks: {
          knbrCommons: [
            'button',
            'saved',
            'label',
            'knBrMessages',
            'toolTip',
            'myAccountPriceList',
            'common',
            'pageMetaResolver',
            'spinner',
            'navigation',
            'searchBox',
            'sorting',
            'httpHandlers',
            'miniCart',
            'miniLogin',
            'skipLink',
            'formErrors',
            'errorHandlers',
            'chatMessaging',
          ],
          knbrHome: ['counter', 'customer', 'quicklinks'],
          payment: ['paymentForm', 'paymentMethods', 'paymentCard', 'paymentTypes'],
          checkout: [
            'checkout',
            'checkoutAddress',
            'checkoutReview',
            'checkoutShipping',
            'checkoutProgress',
            'checkoutPO',
            'checkoutMode',
          ],
          freightPopUp: ['sloanFreightPopUp'],
          knbrNac: [
            'nacConfigurbaleForm',
            'downloadConfirmationDialog',
            'updateTemplateDialog',
            'uploadNewTemplateDialog',
          ],
          user: [
            'anonymousConsents',
            'checkoutLogin',
            'authMessages',
            'password',
          ],
          userProfile: ['user-profile'],
          deliveryMode: ['checkoutMode'],
        },
        fallbackLang: 'en',
      },
    }),
  ],
})
export class KnBrI18nModule { }
