<ng-container *ngIf="product$ | async as product">
  <div class="col-12 pl-2">
    <div class="code mb-1 font-text-bold">
      <label class="mb-0">{{ 'productSummary.id' | cxTranslate }}:</label>{{ product?.code }}
    </div>
    <button class="btn reset pl-1 pr-1 pb-1 pt-1 font-lg-size float-right" (click)="back()">
      <i class="fas fa-level-down-alt fa-rotate-90" aria-hidden="true"></i>
    </button>
    <ng-template [cxOutlet]="outlets.SUMMARY" [cxOutletContext]="{ product: product }">
      <p [innerHTML]="product?.name" class="summary w-100 mb-2"></p>
      <p *ngIf="product?.summary" [innerHTML]="product?.summary" class="w-100 font-size-18 text-muted mb-3"></p>
    </ng-template>
  </div>
</ng-container>
