import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SloanOrderHistorySearchModule } from './sloan-order-history-search/sloan-order-history-search.module';
import { SloanOrderHistorySearchResultModule } from './sloan-order-history-search-result/sloan-order-history-search-result.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, SloanOrderHistorySearchModule, SloanOrderHistorySearchResultModule],
})
export class SloanOrderHistoryModule {}
