import { NgModule } from '@angular/core';
import { provideConfig } from '@spartacus/core';
import {
  organizationTranslationChunksConfig,
  organizationTranslations,
} from '@spartacus/organization/administration/assets';
import { AdministrationRootModule } from 'src/feature-libs/kn-br-organization/administration/root/administration-root.module';
//import { AdministrationRootModule } from '@spartacus/organization/administration/root';

@NgModule({
  declarations: [],
  imports: [AdministrationRootModule],
  providers: [
    provideConfig({
      featureModules: {
        organizationAdministration: {
          module: () => import('src/feature-libs/kn-br-organization/administration/kn-br-organization.module').then((m) => m.KnBrOrganizationUsersModule),
        },
      },
    }),
    provideConfig({
      i18n: {
        resources: organizationTranslations,
        chunks: organizationTranslationChunksConfig,
      },
    }),
    provideConfig({
      i18n: {
        resources: {
          en: {
            organization: {
              organization: {
                breadcrumb: 'Users',
              },
              orgUser: {
                mobileNumber: 'Mobile Number',
                orgUnit: 'Sold To',
              },
            },
          },
        },
      },
    }),
  ],
})
export class OrganizationAdministrationFeatureModule { }
