<div class="modal-header d-flex align-items-center">
  <h4 class="modal-title">{{ 'quoteCommon.label.quoteReference' | cxTranslate }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body float-left w-100 pl-3 pr-3 pt-0">
  <ng-container *ngIf="quoteReference$ | async as quoteReference; else showLoader">
    <ng-container *ngIf="quoteReference && quoteReference.length; else noReference">
      <div class="reference-lbl font-size color-font">
        {{ 'quoteReference.selectMessage1' | cxTranslate : { code: cartEntry.product.code } }} <br />
        {{ 'quoteReference.selectMessage2' | cxTranslate }}
      </div>
      <div
        class="product-main-grid col-md-12 p-0 d-flex quote-ref-modal"
        *ngIf="quoteReference$ | async as quoteReference"
      >
        <div class="col-lg-12 product-list-view p-0">
          <div class="row counter-wrapper pr-3">
            <div class="customersearch-list-table float-left col-lg-12 mt-3 knbr-quote-reference pr-0">
              <div class="customersearch-list-titles d-flex align-items-center border-top float-left col-lg-12 p-2">
                <div class="col-md-1 text-center padding-res-0 float-left font-text-600 pl-0 font-sm-size">&nbsp;</div>
                <div class="col-md-2 text-center padding-res-0 float-left font-text-600 pl-0 font-sm-size">
                  {{ 'quoteReference.label.quoteNumber' | cxTranslate }}
                </div>
                <div class="col-md-2 text-center float-left font-text-600 pl-0 font-sm-size">
                  {{ 'quoteCommon.label.jobNameNumber' | cxTranslate }}
                </div>
                <div class="col-md-1 text-center float-left font-text-600 pl-0 font-sm-size">
                  {{ 'quoteReference.label.totalQty' | cxTranslate }}
                </div>
                <!-- <div class="col-md-1 text-center float-left font-text-600 pl-0 font-sm-size">
                  {{ 'quoteReference.label.orderQty' | cxTranslate }}
                </div> -->
                <div class="col-md-2 text-center float-left font-text-600 pl-0 font-sm-size">
                  {{ 'quoteReference.label.referencedQty' | cxTranslate }}
                </div>
                <div class="col-md-2 text-center float-left font-text-600 pl-0 font-sm-size">
                  {{ 'quoteReference.label.availableQty' | cxTranslate }}
                </div>
                <div class="col-md-2 text-center float-left font-text-600 pl-0 font-sm-size">
                  {{ 'quoteReference.label.price' | cxTranslate }}
                </div>
              </div>

              <div
                class="bg-white customersearch-list-titles table-content border-top float-left w-100 p-2 font-weight-normal d-flex align-items-center"
                *ngFor="let reference of quoteReference"
              >
                <div class="col-lg-1 pl-0 reference-checkbox float-left d-flex justify-content-start p-2">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      [(ngModel)]="selectedQuoteReference"
                      [value]="reference"
                      [disabled]="
                        reference?.referencedQty < 0 ||
                        reference.referencedQty === reference.knbrQuoteReference.remainingQuantity
                      "
                      type="radio"
                      name="exampleRadios"
                      id="exampleRadios1"
                      value="option1"
                      (change)="quoteReferenceChange($event)"
                    />
                  </div>
                </div>

                <div class="col-md-2 text-center float-left title-wrap pl-0 font-sm-size color-font">
                  <span class="res-titles"> {{ 'quoteReference.label.quoteNumber' | cxTranslate }}</span>
                  {{ reference?.knbrQuoteReference?.quoteRefNumber }}
                </div>
                <div class="col-md-2 text-center float-left title-wrap product-name pl-0 font-size color-font">
                  <span class="res-titles"> {{ 'quoteCommon.header.jobNameNumber' | cxTranslate }}</span
                  >{{ reference?.knbrQuoteReference?.jobName }}
                </div>
                <div class="col-md-1 text-center float-left title-wrap pl-0 font-size color-font">
                  <span class="res-titles"> {{ 'quoteReference.label.totalQty' | cxTranslate }}</span>
                  {{ reference?.quantity }}
                </div>
                <!-- <div class="col-md-1 text-center float-left title-wrap pl-0 font-size color-font">
                  <span class="res-titles"> {{ 'quoteReference.label.orderQty' | cxTranslate }}</span>
                  {{ reference?.orderedQty }}
                </div> -->
                <div class="col-md-2 text-center float-left title-wrap pl-0 font-size color-font">
                  <span class="res-titles"> {{ 'quoteReference.label.referencedQty' | cxTranslate }}</span>
                  {{ reference?.referencedQty }}
                </div>
                <div class="col-md-2 text-center float-left title-wrap pl-0 font-size color-font">
                  <span class="res-titles">{{ 'quoteReference.label.availableQty' | cxTranslate }}</span>
                  {{ reference?.knbrQuoteReference?.remainingQuantity }}
                </div>
                <div class="col-md-2 text-center float-left title-wrap pl-0 font-size color-font">
                  <span class="res-titles"> {{ 'quoteReference.label.price' | cxTranslate }}</span>
                  {{ reference?.knbrQuoteReference?.refQuotePriceAdjusted }}
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer flex-nowrap pr-0">
            <div
              *ngIf="showWarningMessage"
              class="alert alert-warning rounded h-auto col-lg-9 float-left p-3 font-sm-size m-0"
            >
              <ng-container *ngIf="showWarningMessage && selectedQuoteReference.referencedQty > 0">
                <i class="fas fa-exclamation-triangle mr-2" aria-hidden="true"></i>
                {{
                  'knBrMessages.quoteReferenceWarningMessage'
                    | cxTranslate
                      : {
                          reqQty: cartEntry.quantity,
                          availQty:
                            selectedQuoteReference.knbrQuoteReference.remainingQuantity -
                            selectedQuoteReference.referencedQty
                        }
                }}
              </ng-container>
              <ng-container *ngIf="showWarningMessage && selectedQuoteReference.referencedQty <= 0">
                <i class="fas fa-exclamation-triangle mr-2" aria-hidden="true"></i>
                {{
                  'knBrMessages.quoteReferenceWarningLessThanMessage'
                    | cxTranslate
                      : {
                          reqQty: cartEntry.quantity,
                          availQty: selectedQuoteReference.knbrQuoteReference.remainingQuantity
                        }
                }}
              </ng-container>
            </div>
            <div class="col-lg-3 p-0 d-flex justify-content-end">
              <ng-container *ngIf="quoteReference$ | async as quoteReference">
                <button
                  *ngIf="quoteReference && quoteReference.length"
                  type="button"
                  class="btn color-knbr-primary color-white hover-link mr-2"
                  (click)="add()"
                  [disabled]="!selectedQuoteReference"
                >
                  {{ 'button.ok' | cxTranslate }}
                </button>
              </ng-container>
              <button
                type="button"
                class="btn color-knbr-secondary color-white hover-link"
                (click)="activeModal.close('Close click')"
              >
                {{ 'button.close' | cxTranslate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
<ng-template #noReference>
  <div class="alert-info p-3 rounded w-100">
    {{ 'quoteReference.referenceNotFound' | cxTranslate : { code: cartEntry.product.code } }}
  </div>
</ng-template>
<ng-template #showLoader>
  <cx-spinner></cx-spinner>
</ng-template>
