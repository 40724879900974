import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { I18nModule, ConfigModule, CmsConfig, GlobalMessageService, RoutingService } from '@spartacus/core';
import {
  FormErrorsModule,
  PageComponentModule,
  PageLayoutModule,
  PageSlotModule,
  SpinnerModule,
} from '@spartacus/storefront';
import { ResetPasswordComponentService } from '@spartacus/user/profile/components';
import { UserPasswordFacade } from '@spartacus/user/profile/root';
import { SloanResetPasswordComponent } from './sloan-reset-password.component';

@NgModule({
  declarations: [SloanResetPasswordComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    I18nModule,
    FormErrorsModule,
    SpinnerModule,
    PageComponentModule,
    PageLayoutModule,
    PageSlotModule,
    ConfigModule.withConfig({
      cmsComponents: {
        ResetPasswordComponent: {
          component: SloanResetPasswordComponent,
          providers: [
            {
              provide: ResetPasswordComponentService,
              useClass: ResetPasswordComponentService,
              deps: [UserPasswordFacade, RoutingService, GlobalMessageService],
            },
          ],
        },
      },
    } as CmsConfig),
  ],
  exports: [SloanResetPasswordComponent],
})
export class SloanResetPasswordModule { }
