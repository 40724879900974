<ng-container *ngIf="orderReturnData$ | async as order">
  <ng-container *ngIf="order.returnEntries">
    <div class="customersearch-list-table border-bottom mt-3 float-left col-lg-12 p-0">
      <div
        class="customersearch-list-titles border-top float-left w-100 pl-2 pr-2 pt-3 pb-3 d-none d-sm-none d-lg-block"
      >
        <div class="col-md-1 text-center float-left font-text-600 font-sm-size">
          {{ 'knbrReturnOrderDetails.label.product' | cxTranslate }}
        </div>
        <div class="col-md-3 float-left font-text-600 font-sm-size">
          {{ 'knbrReturnOrderDetails.label.code' | cxTranslate }}
        </div>
        <div class="col-md-3 float-left font-text-600 font-sm-size">
          {{ 'knbrReturnOrderDetails.label.description' | cxTranslate }}
        </div>
        <div class="col-md-2 float-left font-text-600 font-sm-size">
          {{ 'knbrReturnOrderDetails.label.qty' | cxTranslate }}
        </div>
        <div class="col-md-3 float-left font-text-600 font-sm-size">
          {{ 'knbrReturnOrderDetails.label.itemNetPrice' | cxTranslate }}
        </div>
      </div>
    </div>
    <ng-container *ngIf="order.returnEntries">
      <div
        class="bg-white customersearch-list-titles table-content border-top float-left w-100 pl-2 pr-2 pt-3 pb-3 font-weight-normal d-flex align-items-center"
        *ngFor="let item of order.returnEntries"
      >
        <div class="col-md-1 text-center title-wrap padding-res-0 float-left">
          <cx-media [container]="item.orderEntry?.product?.images?.PRIMARY" format="product"></cx-media>
        </div>
        <div class="col-md-3 float-left title-wrap font-size color-font">
          <span class="res-titles"> {{ 'knbrReturnOrderDetails.label.code' | cxTranslate }}</span>
          {{ item.orderEntry?.product?.code }}
        </div>
        <div class="col-md-3 float-left title-wrap font-size color-font">
          <span class="res-titles"> {{ 'knbrReturnOrderDetails.label.description' | cxTranslate }}</span>
          {{ item.orderEntry?.product?.name }}
        </div>
        <div class="col-md-2 float-left title-wrap font-size color-font">
          <span class="res-titles"> {{ 'knbrReturnOrderDetails.label.qty' | cxTranslate }} </span>
          {{ item.expectedQuantity }}
        </div>
        <div class="col-md-3 float-left title-wrap font-size color-font">
          <span class="res-titles">{{ 'knbrReturnOrderDetails.label.itemNetPrice' | cxTranslate }} </span>
          {{ item.orderEntry?.basePrice?.formattedValue }}
        </div>
      </div>
    </ng-container>
  </ng-container>
  <div class="col-md-12 float-right d-flex justify-content-center mt-4">
    <button type="button" class="btn btn-action col-md-3" (click)="goBack()">
      {{ 'knbrReturnOrderDetails.actions.backOrderReturns' | cxTranslate }}
    </button>
  </div>
</ng-container>
