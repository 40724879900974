<ng-container *ngIf="orderReturnData$ | async as order">
  <div class="col-md-12 p-0 float-right d-flex justify-content-end">
    <button class="btn back-btn p-1 font-lg-size" (click)="goBack()">
      <i class="fas fa-level-down-alt fa-rotate-90" aria-hidden="true"></i>
    </button>
  </div>
  <div class="col-md-12 float-left p-0 pb-2 mt-2 mb-4 border">
    <div class="col-md-3 float-left mt-3">
      <label class="color-font font-text-600 mb-1">
        {{ 'knbrReturnOrderDetails.label.returnCreateDate' | cxTranslate }}
      </label>
      <p class="text-muted mb-2">{{ order.creationDate }}</p>
    </div>
    <div class="col-md-3 float-left mt-3">
      <label class="color-font font-text-600 mb-1">
        {{ 'knbrReturnOrderDetails.label.returnNumber' | cxTranslate }}
      </label>
      <p class="text-muted mb-2">{{ order.rma | removeLeadingZeros }}</p>
    </div>
    <div class="col-md-3 float-left mt-3">
      <label class="color-font font-text-600 mb-1">
        {{ 'knbrReturnOrderDetails.label.soldTo' | cxTranslate }}
      </label>
      <p class="text-muted mb-2">{{ order.accountNumber | removeLeadingZeros }}</p>
    </div>
    <div class="col-md-3 float-left mt-3">
      <label class="color-font font-text-600 mb-1">
        {{ 'knbrReturnOrderDetails.label.PO' | cxTranslate }}
      </label>
      <p class="text-muted mb-2">{{ order.purchaseOrderNumber }}</p>
    </div>
    <div class="col-md-3 float-left mt-3">
      <label class="color-font font-text-600 mb-1">
        {{ 'knbrReturnOrderDetails.label.returnReason' | cxTranslate }}
      </label>
      <p class="text-muted mb-2">{{ order.refundReason }}</p>
    </div>
    <div class="col-md-3 float-left mt-3">
      <label class="color-font font-text-600 mb-1">
        {{ 'knbrReturnOrderDetails.label.returnNote' | cxTranslate }}
      </label>
      <p class="text-muted mb-2">{{ order.notes }}</p>
    </div>
    <div class="col-md-3 float-left mt-3">
      <label class="color-font font-text-600 mb-1">
        {{ 'knbrReturnOrderDetails.label.totalReturnValue' | cxTranslate }}
      </label>
      <p class="text-muted mb-2">{{ order.totalPrice.formattedValue }}</p>
    </div>
    <div class="col-md-3 float-left mt-3">
      <label class="color-font font-text-600 mb-1">
        {{ 'knbrReturnOrderDetails.label.overallStatus' | cxTranslate }}
      </label>
      <p class="text-muted mb-2">{{ order.statusDisplay }}</p>
    </div>
  </div>
  <h3 class="color-font font-text-600 ml-3">
    {{ 'knbrReturnOrderDetails.heading.followUpDocuments' | cxTranslate }}
  </h3>
  <div class="col-md-12 float-left p-0 mt-3 mb-4 border">
    <div class="col-md-6 float-left mt-3 mb-2">
      <label class="color-font font-text-600 mb-1">
        {{ 'knbrReturnOrderDetails.label.orderNumber' | cxTranslate }}
      </label>
      <p class="text-muted mb-2">{{ order.code | removeLeadingZeros }}</p>
    </div>
    <div class="col-md-6 float-left mt-3 mb-2">
      <label class="color-font font-text-600 mb-1">
        {{ 'knbrReturnOrderDetails.label.creditMemoNumber' | cxTranslate }}
      </label>
      <p class="text-muted mb-2"></p>
    </div>
  </div>
  <h3 class="color-font font-text-600 ml-3">
    {{ 'knbrReturnOrderDetails.heading.attachments' | cxTranslate }}
  </h3>
  <div class="col-md-12 float-left p-0 mt-3 mb-4 border">
    <div class="col-md-4 float-left mt-3">
      <label class="color-font font-text-600 mb-1">
        {{ 'knbrReturnOrderDetails.label.returnOrderConfirmation' | cxTranslate }}
      </label>
      <p class="text-muted mb-2"></p>
    </div>
    <div class="col-md-4 float-left mt-3">
      <label class="color-font font-text-600 mb-1">
        {{ 'knbrReturnOrderDetails.label.credit' | cxTranslate }}
      </label>
      <p class="text-muted mb-2"></p>
    </div>
    <div class="col-md-4 float-left mt-3">
      <label class="color-font font-text-600 mb-1">
        {{ 'knbrReturnOrderDetails.label.customerOriginalPaperwork' | cxTranslate }}
      </label>
      <p class="text-muted mb-2"></p>
    </div>
  </div>
</ng-container>
