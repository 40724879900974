import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';
import { CardModule } from '@spartacus/storefront';
import { SloanOrderOverviewComponent } from './sloan-order-overview.component';
import { KnBrCustomPipesModule } from 'src/app/shared/pipe/kn-br-custom-pipes/kn-br-custom-pipes.module';

@NgModule({
  imports: [CommonModule, I18nModule, CardModule, KnBrCustomPipesModule],
  declarations: [SloanOrderOverviewComponent],
  exports: [SloanOrderOverviewComponent],
})
export class SloanOrderOverviewModule {}
