import { ChangeDetectorRef, Component, OnInit, OnDestroy } from '@angular/core';
import { CmsService } from '@spartacus/core';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';
import { Subscription } from 'rxjs';
import { SloanCommonService } from 'src/app/services/sloan/sloan-common.service';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { KnBrDraftOrderItemsComponent } from 'src/feature-libs/kn-br-cart/draft-order/components/kn-br-draft-order/kn-br-draft-order-detail/kn-br-draft-order-items/kn-br-draft-order-items.component';
import { KnBrDraftOrdersService } from 'src/feature-libs/kn-br-cart/draft-order/core/facade/kn-br-draft-orders.service';

@Component({
  selector: 'kn-br-draft-order-items',
  templateUrl: './sloan-draft-order-items.component.html',
  styleUrls: ['./sloan-draft-order-items.component.scss'],
})
export class SloanDraftOrderItemsComponent extends KnBrDraftOrderItemsComponent implements OnInit, OnDestroy {
  draftOrder$ = this.activeService.getActive();
  changedValue: string;
  quoteRefErrorMsq: string;
  subscription = new Subscription();
  checkoutValidationResponse$ = this.knBrCommonService.loadCheckoutValidationResponse$();
  constructor(
    protected activeService: ActiveCartFacade,
    protected knBrCommonService: KnBrCommonService,
    protected draftOrdersService: KnBrDraftOrdersService,
    protected ref: ChangeDetectorRef,
    protected cmsService: CmsService,
    protected sloanCommonService: SloanCommonService
  ) {
    super(activeService, knBrCommonService, ref, cmsService);
    this.sloanCommonService.getSloanHolidayList();
    this.subscription.add(
      this.checkoutValidationResponse$.subscribe((res: any) => {
        if (res?.errorMessage && res.errorMessage[0].error === 'QUOTE_REF_VALIDATE') {
          this.getChangedValue('quotereference');
        }
      })
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  getChangedValue(value: string) {
    this.changedValue = value;
  }

  propertyChange(index: number, entry: any): string {
    switch (this.changedValue) {
      case 'price':
        return entry.knbrTotalPrice.priceAdjusted.value;
      case 'quantity':
        return entry.quantity;
      case 'comment':
        return entry.commentCount;
      case 'discount':
        return entry.knbrTotalPrice.knbrAbsoluteDiscount.value;
      case 'date':
        return entry.estimatedShipDate;
      case 'quotereference':
        return entry.knbrQuoteReference.quoteRefNumber;
      default:
        return entry.entryNumber;
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    super.ngOnDestroy();
  }
}
