<div class="modal-header d-flex align-items-center">
  <h4 class="modal-title">{{ 'label.comments' | cxTranslate }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="dismissModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body float-left w-100 p-0">
  <div class="pr-3">
    <div
      class="col-md-12 float-left customersearch-forms login-forms pl-3 modal-comments-list pt-0 pb-0"
      *ngIf="comments$ | async as comments"
    >
      <div class="customersearch-list-table float-left col-lg-12 p-0">
        <div
          class="customersearch-list-titles border-top float-left w-100 pl-2 pr-2 pt-3 pb-3 d-none d-sm-none d-lg-block"
        >
          <div
            [class.col-md-2]="isGeneric && isSinkDrillPattern"
            [class.col-md-2]="(isGeneric && !isSinkDrillPattern) || (!isGeneric && isSinkDrillPattern)"
            [class.col-md-5]="!isGeneric && !isSinkDrillPattern"
            class="padding-res-0 float-left font-text-600 font-sm-size"
          >
            {{ 'label.comments' | cxTranslate }}
          </div>
          <div *ngIf="isGeneric" class="col-md-2 padding-res-0 float-left font-text-600 font-sm-size">
            {{ 'label.acushWorkSheet' | cxTranslate }}
          </div>
          <div *ngIf="isGeneric" class="col-md-2 padding-res-0 float-left font-text-600 font-sm-size">
            {{ 'label.drawingNumber' | cxTranslate }}
          </div>
          <div *ngIf="isGeneric" class="col-md-2 padding-res-0 float-left font-text-600 font-sm-size">
            {{ 'label.configurationId' | cxTranslate }}
          </div>
          <div
            *ngIf="isSinkDrillPattern"
            [class.col-md-2]="isGeneric && isSinkDrillPattern"
            [class.col-md-4]="!isGeneric && isSinkDrillPattern"
            class="col-md-2 padding-res-0 float-left font-text-600 font-sm-size"
          >
            {{ 'label.sinkHoleDrillPattern' | cxTranslate }}
          </div>
          <div
            [ngClass]="isDrillOrGenericAvaiable() ? 'col-md-2' : 'col-md-4'"
            class="padding-res-0 float-left font-text-600 font-sm-size"
          >
            {{ 'label.date' | cxTranslate }}
          </div>
          <div
            [ngClass]="isDrillOrGenericAvaiable() ? 'col-md-1' : 'col-md-2'"
            class="float-left font-text-600 font-sm-size"
          >
            {{ 'label.owner' | cxTranslate }}
          </div>
          <div
            *ngIf="!(isGeneric && isSinkDrillPattern)"
            class="col-md-1 text-center float-left font-text-600 font-sm-size"
          >
            {{ 'label.actions' | cxTranslate }}
          </div>
        </div>
      </div>
      <div class="">
        <div
          class="bg-white customersearch-list-titles table-content float-left font-weight-normal form-group head-notes col-lg-12 pl-0 float-left m-0 p-2 border-bottom d-flex align-items-center"
          *ngFor="let comment of comments"
          (click)="editComment(comment)"
        >
          <div
            [class.col-md-2]="isGeneric && isSinkDrillPattern"
            [class.col-md-2]="(isGeneric && !isSinkDrillPattern) || (!isGeneric && isSinkDrillPattern)"
            [class.col-md-5]="!isGeneric && !isSinkDrillPattern"
            class="float-left title-wrap font-size color-font"
          >
            <span class="res-titles"> {{ 'label.comments' | cxTranslate }} :</span>
            <span class="cursor-pointer text-break"> {{ comment.text }}</span>
          </div>
          <div *ngIf="isGeneric" class="text-break-class col-md-2 float-left title-wrap font-size color-font">
            <span class="res-titles"> {{ 'label.acushWorkSheet' | cxTranslate }} :</span>
            {{ comment.archWorkSheet }}
          </div>
          <div *ngIf="isGeneric" class="text-break-class col-md-2 float-left title-wrap font-size color-font">
            <span class="res-titles"> {{ 'label.drawingNumber' | cxTranslate }} :</span>
            {{ comment.drawingNumber }}
          </div>
          <div *ngIf="isGeneric" class="text-break-class col-md-2 float-left title-wrap font-size color-font">
            <span class="res-titles"> {{ 'label.configurationId' | cxTranslate }} :</span>
            {{ comment.configurationId }}
          </div>
          <div
            *ngIf="isSinkDrillPattern"
            [class.col-md-2]="isGeneric && isSinkDrillPattern"
            [class.col-md-4]="!isGeneric && isSinkDrillPattern"
            class="col-md-2 float-left title-wrap font-size color-font"
          >
            <span class="res-titles"> {{ 'label.sinkHoleDrillPattern' | cxTranslate }}:</span>
            {{ comment.sinkHoleDrillPattern }}
          </div>
          <div
            [ngClass]="isDrillOrGenericAvaiable() ? 'col-md-2' : 'col-md-4'"
            class="float-left title-wrap font-size color-font"
          >
            <span class="res-titles"> {{ 'label.date' | cxTranslate }} :</span>
            {{ comment.creationDate | cxDate : 'EEE MMM dd hh:mm:ss zzz yyyy' }}
          </div>

          <div
            [ngClass]="isDrillOrGenericAvaiable() ? 'col-md-1' : 'col-md-2'"
            class="float-left title-wrap font-size color-font"
          >
            <span class="res-titles"> {{ 'label.owner' | cxTranslate }} :</span>
            {{ comment.author?.name }}
            <ng-container *ngIf="isGeneric && isSinkDrillPattern" class="text-center">
              <span (click)="deleteComment($event, comment)" class="float-right cursor-pointer font-size-17 color-font">
                <i class="far fa-trash-alt"></i>
              </span>
            </ng-container>
          </div>
          <div
            *ngIf="!(isGeneric && isSinkDrillPattern) && !editable"
            class="col-md-1 text-center float-right title-wrap font-size-17 color-font"
          >
            <span class="res-titles"> {{ 'label.actions' | cxTranslate }} :</span>
            <span (click)="deleteComment($event, comment)" class="cursor-pointer">
              <i class="far fa-trash-alt"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <ng-container *ngIf="!editable">
      <div class="pl-3 rounded col-lg-12 float-left" *ngIf="isGeneric || isSinkDrillPattern">
        <form [formGroup]="messageForm">
          <div
            *ngIf="isGeneric && (!isARCWorkSheet || !isDrawingNumber || !isConfigurationID)"
            class="col-md-12 float-left customersearch-forms login-forms p-0 customer-home-forms"
          >
            <p>{{ 'label.mandatoryMsgInCommentPopup' | cxTranslate }}</p>
            <div *ngIf="!isARCWorkSheet" class="form-group mt-3 col-lg-6 pl-0 float-left p-res-0">
              <input
                type="text"
                class="form-control w-100"
                [placeholder]="'label.acushWorkSheet' | cxTranslate"
                formControlName="archWorkSheet"
                maxlength="32"
              />
              <span class="highlight"></span>
              <label class="text-muted"> {{ 'label.acushWorkSheet' | cxTranslate }}</label>

              <span class="kn-br-form-errors" *ngIf="messageForm.get('archWorkSheet')?.errors?.pattern">
                <p>{{ 'knBrMessages.acushOrDrawingMsg' | cxTranslate }}</p>
              </span>
            </div>

            <div
              *ngIf="!isDrawingNumber"
              class="form-group mt-3 col-lg-6 pl-0 float-left valid-to p-res-0 position-relative"
            >
              <label class="text-muted m-0">{{ ' label.drawingNumber' | cxTranslate }}</label>
              <div class="col-lg-12 float-left p-0">
                <div class="form-group col-lg-12 pl-0 m-0 float-left p-0 valid-to p-res-0 position-relative">
                  <div class="input-group">
                    <input
                      class="form-control w-100 cursor-pointer"
                      type="text"
                      [placeholder]="'label.drawingNumber' | cxTranslate"
                      name="drawingNumber"
                      formControlName="drawingNumber"
                    />
                  </div>
                  <span class="highlight"></span>
                  <label class="text-muted"> {{ 'label.drawingNumber' | cxTranslate }}</label>
                  <span class="kn-br-form-errors" *ngIf="messageForm.get('drawingNumber')?.errors?.pattern">
                    <p>{{ 'knBrMessages.acushOrDrawingMsg' | cxTranslate }}</p>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!--Configuration ID Starts-->
          <div *ngIf="isGeneric" class="col-md-12 float-left customersearch-forms login-forms p-0 customer-home-forms">
            <div
              *ngIf="!isConfigurationID"
              class="form-group mt-3 col-lg-6 pl-0 float-left valid-to p-res-0 position-relative"
            >
              <label class="text-muted m-0">{{ ' label.configurationId' | cxTranslate }}</label>
              <div class="col-lg-12 float-left p-0">
                <div class="form-group col-lg-12 pl-0 m-0 float-left p-0 valid-to p-res-0 position-relative">
                  <div class="input-group">
                    <input
                      class="form-control w-100 cursor-pointer"
                      type="text"
                      [placeholder]="'label.configurationId' | cxTranslate"
                      name="configurationId"
                      formControlName="configurationId"
                    />
                  </div>
                  <span class="highlight"></span>
                  <label class="text-muted"> {{ 'label.configurationId' | cxTranslate }}</label>
                </div>
              </div>
            </div>
          </div>
          <!--Configuration ID Ends-->
          <div *ngIf="isSinkDrillPattern" class="col-md-12 float-left d-flex justify-content-start p-0">
            <div class="form-group mt-3 col-lg-12 pl-0 float-left mb-3 p-res-0">
              <label class="text-muted m-0">{{ 'label.sinkHoleDrillPattern' | cxTranslate }}</label>
              <ng-select
                class="mb-1"
                formControlName="sinkHoleDrillPattern"
                bindLabel="Text"
                bindValue="Key"
                [items]="sinkHoleDrillPatternList$ | async"
                [placeholder]="'orderHistorySearchForm.label.orderType' | cxTranslate"
                [searchable]="false"
                [clearable]="false"
              >
              </ng-select>
              <span class="highlight"></span>
            </div>
          </div>
        </form>
      </div>
      <div class="col-md-12 float-left customersearch-forms login-forms p-1 m-0">
        <div class="form-group head-notes mt-2 col-lg-12 pl-0 float-left mb-0 p-0">
          <textarea
            class="form-control w-100"
            id="exampleFormControlTextarea1"
            rows="3"
            [placeholder]="'label.comments' | cxTranslate"
            [(ngModel)]="text"
            knBrAlphaNumericOnly
          ></textarea>
        </div>
      </div>
      <button
        type="button"
        (click)="saveComment()"
        class="btn btn-primary"
        [disabled]="messageForm.invalid || isDisabled()"
      >
        {{ 'button.save' | cxTranslate }}
      </button>
    </ng-container>
    <button type="button" class="btn btn-outline-primary" (click)="dismissModal()">
      {{ 'button.close' | cxTranslate }}
    </button>
  </div>
</div>
