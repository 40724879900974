export const userProfile = {
  updateEmailForm: {
    newEmailAddress: {
      label: 'New email address',
      placeholder: 'Enter email',
    },
    confirmNewEmailAddress: {
      label: 'Confirm new email address',
      placeholder: 'Enter email',
    },
    enterValidEmail: 'Please enter a valid email.',
    bothEmailMustMatch: 'Both emails must match',
    password: {
      label: 'Password',
      placeholder: 'Enter password',
    },
    pleaseInputPassword: 'Please input password',
    emailUpdateSuccess: 'Success. Please sign in with {{ newUid }}',
  },
  register: {
    confirmPassword: {
      action: 'Confirm password',
      label: 'Confirm password',
      placeholder: 'Confirm Password',
    },
    managementInMyAccount: 'Management in My Account.',
    termsAndConditions: 'Terms & Conditions',
    signIn: 'I already have an account. Sign In',
    register: 'Register',
    confirmNewPassword: 'Confirm New Password',
    resetPassword: 'Reset Password',
    createAccount: 'Create an account',
    title: 'Title',
    firstName: {
      label: 'First name',
      placeholder: 'First name',
    },
    lastName: {
      label: 'Last name',
      placeholder: 'Last name',
    },
    emailAddress: {
      label: 'Email address',
      placeholder: 'Email address',
    },
    password: {
      label: 'Password',
      placeholder: 'Password',
    },
    newPassword: 'New Password',
    emailMarketing:
      'Use my personal data to receive e-mail newsletters for marketing campaigns. To change your settings, go to Consent Management in My Account.',
    confirmThatRead: 'I am confirming that I have read and agreed with the',
    selectTitle: 'Select Title',
    passwordMinRequirements:
      'Password must be six characters minimum, with one uppercase letter, one number, one symbol',
    bothPasswordMustMatch: 'Both password must match',
    titleRequired: 'Title is required.',
    postRegisterMessage: 'Please log in with provided credentials.',
  }
};
export const forgottenPassword = {
  resetPassword: 'Reset password',
  enterEmailAddressAssociatedWithYourAccount: 'Enter the email address associated with your account',
  emailAddress: {
    label: 'Email address',
    placeholder: 'Enter email',
  },
  enterValidEmail: 'Please enter a valid email.',
  passwordResetEmailSent: 'An email has been sent to you with information on how to reset your password.',
  passwordResetSuccess: 'Success! You can now login using your new password.',
}
export const updateEmailForm = {
  newEmailAddress: {
    label: 'New Email Address',
    placeholder: 'Enter Email',
  },
  confirmNewEmailAddress: {
    label: 'Confirm New Email Address',
    placeholder: 'Enter Email',
  },
  enterValidEmail: 'Please enter a valid email.',
  bothEmailMustMatch: 'Both emails must match',
  password: {
    label: 'Password',
    placeholder: 'Enter Password',
  },
  pleaseInputPassword: 'Please input password',
  emailUpdateSuccess: 'Success. Please sign in with {{ newUid }}',
};
export const updatePasswordForm = {
  oldPassword: {
    label: 'Old Password',
    placeholder: 'Old Password',
  },
  oldPasswordIsRequired: 'Old password is required.',
  newPassword: {
    label: 'New Password',
    placeholder: 'New Password',
  },
  passwordMinRequirements: 'Password must be six characters minimum, with one uppercase letter, one number, one symbol',
  confirmPassword: {
    label: 'Confirm New Password',
    placeholder: 'Confirm Password',
  },
  bothPasswordMustMatch: 'Both password must match',
  passwordUpdateSuccess: 'Password updated with success',
};
export const updateProfileForm = {
  title: 'Title',
  none: '',
  firstName: {
    label: 'First Name',
    placeholder: 'First Name',
  },
  firstNameIsRequired: 'First name is required.',
  lastName: {
    label: 'Last Name',
    placeholder: 'Last Name',
  },
  lastNameIsRequired: 'Last name is required.',
  profileUpdateSuccess: 'Personal details successfully updated',
  customerId: 'Customer #',
};
export const updateprofiledata = {
  userProfile,
  updateProfileForm,
  updatePasswordForm,
  updateEmailForm,
  forgottenPassword
}
