import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KnBrReturnOrderSearchComponent } from './kn-br-return-order-search.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { FormErrorsModule } from '@spartacus/storefront';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [KnBrReturnOrderSearchComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    UrlModule,
    I18nModule,
    FormErrorsModule,
    NgSelectModule,
    NgbTooltipModule,
    ConfigModule.withConfig({
      cmsComponents: {
        KnBrReturnOrderSearchComponent: {
          component: KnBrReturnOrderSearchComponent,
        },
      },
    } as CmsConfig),
  ],
})
export class KnBrReturnOrderSearchModule {}
