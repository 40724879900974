import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthGuard, ConfigModule, RoutingConfig } from '@spartacus/core';
import { CmsPageGuard, PageLayoutComponent } from '@spartacus/storefront';
import { SloanOrderAcknowledgeModule } from './sloan-order-acknowledge/sloan-order-acknowledge.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SloanOrderAcknowledgeModule,
    RouterModule.forChild([
      {
        data: { pageLabel: '/customer-360/order/edit/confirmation', cxRoute: 'orderEditConfirmation' },
        path: null,
        canActivate: [AuthGuard, CmsPageGuard],
        component: PageLayoutComponent,
      },
    ]),
    ConfigModule.withConfig({
      routing: {
        routes: {
          orderReturnConfirmation: {
            paths: ['customer-360/order/return/confirmation/:orderCode/:type/:rma'],
            paramsMapping: { orderCode: 'code', type: 'type', rma: 'rma' },
          },
          orderCancelConfirmation: {
            paths: ['customer-360/order/cancel/confirmation/:orderCode/:type'],
            paramsMapping: { orderCode: 'code', type: 'type' },
          },
          orderEditConfirmation: {
            paths: ['customer-360/order/edit/confirmation/:orderCode/:type/:orderNumber'],
            paramsMapping: { orderCode: 'code', type: 'type', orderNumber: 'orderNumber' },
          },
        },
      },
    } as RoutingConfig),
  ],
})
export class SloanAcknowledgeModule {}
