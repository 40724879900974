import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthGuard, CmsConfig, ConfigModule, I18nModule, RoutingConfig } from '@spartacus/core';
import { CmsPageGuard, PageLayoutComponent, SpinnerModule } from '@spartacus/storefront';

import { KnBrQuoteSearchResultComponent } from './kn-br-quote-search-result.component';
import { KnBrCustomPipesModule } from 'src/app/shared/pipe/kn-br-custom-pipes/kn-br-custom-pipes.module';

@NgModule({
  declarations: [KnBrQuoteSearchResultComponent],
  imports: [
    CommonModule,
    I18nModule,
    SpinnerModule,
    NgbTooltipModule,
    KnBrCustomPipesModule,
    ConfigModule.withConfig({
      cmsComponents: {
        KnBrQuoteSearchResultComponent: {
          component: KnBrQuoteSearchResultComponent,
        },
      },
    } as CmsConfig),
    RouterModule.forChild([
      {
        data: { pageLabel: '/quotes', cxRoute: 'quotes' },
        path: null,
        canActivate: [AuthGuard, CmsPageGuard],
        component: PageLayoutComponent,
      },
    ]),
    ConfigModule.withConfig({
      routing: {
        routes: {
          quotes: {
            paths: ['quotes'],
          },
        },
      },
    } as RoutingConfig),
  ],
})
export class KnBrQuoteSearchResultModule {}
