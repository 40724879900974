import { Component, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { RoutingService } from '@spartacus/core';
import { LaunchDialogService } from '@spartacus/storefront';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { SloanCalendarHelper } from 'src/app/services/sloan/sloan-calendar-help.service';
import { SloanCommonService } from 'src/app/services/sloan/sloan-common.service';
import { KnBrDateHelper } from 'src/app/shared/kn-br-date.helper';
import { KnBrCustomerContextService } from 'src/app/store/kn-br-customer-context/kn-br-customer-context.service';
import { KnBrDraftOrderCreateComponent } from 'src/feature-libs/kn-br-cart/draft-order/components/kn-br-draft-order/kn-br-draft-order-create/kn-br-draft-order-create.component';
import { KnBrDraftOrdersService } from 'src/feature-libs/kn-br-cart/draft-order/core/facade/kn-br-draft-orders.service';

@Component({
  selector: 'kn-br-draft-order-create',
  templateUrl: './sloan-draft-order-create.component.html',
  providers: [KnBrDateHelper, SloanCalendarHelper],
})
export class SloanDraftOrderCreateComponent extends KnBrDraftOrderCreateComponent implements OnInit, OnDestroy {
  today: NgbDate = this.sloanCalendarHelper.today;
  rddMinDate: NgbDate;
  rddMaxDate: NgbDate;
  constructor(
    protected currentUserService: UserAccountFacade,
    protected fb: UntypedFormBuilder,
    protected service: KnBrDraftOrdersService,
    protected customerService: KnBrCustomerContextService,
    protected routingService: RoutingService,
    protected knBrDateHelper: KnBrDateHelper,
    protected launchDialogService: LaunchDialogService,
    protected vcr: ViewContainerRef,
    protected sloanCalendarHelper: SloanCalendarHelper,
    protected sloanCommonService: SloanCommonService
  ) {
    super(currentUserService, fb, service, customerService, routingService, knBrDateHelper, launchDialogService, vcr);
    this.sloanCommonService.getSloanHolidayList();
    this.rddMinDate = this.sloanCalendarHelper.getRddMinDate(this.today);
    this.rddMaxDate = this.sloanCalendarHelper.getRddMaxDate(this.today);
  }

  isRddDisabled = (date: NgbDate, current: { year: number; month: number }) => {
    return (
      this.sloanCalendarHelper.isHoliday(date) ||
      (this.sloanCalendarHelper.isWeekend(date) && date.month === current.month)
    );
  };
  ngOnInit(): void {
    super.ngOnInit();
    this.subscription = this.customerService.get$.subscribe((response: any) => {
      if (response && response.hasOwnProperty('dropShipFlag')) {
        this.cartCreateForm.patchValue({ shipComplete: response.dropShipFlag });
      }
    });
  }

  initForm() {
    const poNumPattern = '[^*^~|<>]*';
    this.cartCreateForm = this.fb.group({
      customerNumber: this.customerId,
      // name: ['', Validators.required],
      requestedDeliveryDate: [this.sloanCalendarHelper.rddDefaultDate(this.today), Validators.required],
      purchaseOrderNumber: ['', [Validators.required, Validators.pattern(poNumPattern)]],
      jobName: [''],
      shipComplete: true,
      headerComments: [''],
      referenceCartId: this.copiedDraftOrderData ? this.copiedDraftOrderData.code : null,
    });
  }
  onKeyPress(event) {
    var key;
    key = event.charCode;
    //Now allowing to enter { , [ , ] , }
    return !(key == 123 || key == 125 || key == 91 || key == 93);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
