import { Component, Input } from '@angular/core';
import { TranslationService, UserAddressService, UserCostCenterService } from '@spartacus/core';

import { ActiveCartFacade } from '@spartacus/cart/base/root';
import {
  CheckoutPaymentFacade,
  CheckoutDeliveryModesFacade,
  CheckoutDeliveryAddressFacade,
} from '@spartacus/checkout/base/root';
import { CheckoutCostCenterFacade, CheckoutPaymentTypeFacade } from '@spartacus/checkout/b2b/root';
import { KnBrCheckoutStepService } from 'src/feature-libs/kn-br-checkout/base/components/services/kn-br-checkout-step.service';
import { KnBrCheckoutReviewSubmitComponent } from 'src/feature-libs/kn-br-checkout/base/components/kn-br-checkout-review-submit/kn-br-checkout-review-submit.component';
import { KnBrDraftOrder } from 'src/feature-libs/kn-br-cart/draft-order/root/models/kn-br-draft-order.model';

@Component({
  selector: 'cx-order-summary',
  templateUrl: './kn-br-order-summary.component.html',
})
export class KnBrOrderSummaryComponent extends KnBrCheckoutReviewSubmitComponent {
  @Input()
  cart: KnBrDraftOrder;

  constructor(
    protected checkoutDeliveryModesFacade: CheckoutDeliveryModesFacade,
    protected checkoutDeliveryService: CheckoutDeliveryAddressFacade,
    protected checkoutPaymentService: CheckoutPaymentFacade,
    protected userAddressService: UserAddressService,
    protected activeCartService: ActiveCartFacade,
    protected translation: TranslationService,
    protected checkoutStepService: KnBrCheckoutStepService,
    protected checkoutPaymentTypeFacade: CheckoutPaymentTypeFacade,
    protected checkoutCostCenterFacade: CheckoutCostCenterFacade,
    protected userCostCenterService: UserCostCenterService
  ) {
    super(
      checkoutDeliveryService,
      checkoutPaymentService,
      activeCartService,
      translation,
      checkoutStepService,
      checkoutDeliveryModesFacade
    );
  }
}
