<div class="col-md-12 float-left customersearch-forms login-forms p-0 customer-home-forms">
  <!-- <h3 class="color-font font-text-600 ml-3">
    {{ 'invoiceSearchForm.header.documents' | cxTranslate }}
  </h3> -->
  <form [formGroup]="invoiceSearchForm" (ngSubmit)="submitForm()">
    <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3 p-res-0">
      <input
        type="text"
        class="form-control w-100"
        [placeholder]="'invoiceSearchForm.label.document' | cxTranslate"
        formControlName="documentNo"
      />
      <span class="highlight"></span>
      <label class="text-muted">{{ 'invoiceSearchForm.label.document' | cxTranslate }}</label>
    </div>

    <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3 p-res-0">
      <input
        type="text"
        class="form-control w-100"
        [placeholder]="'invoiceSearchForm.label.purchaseOrder' | cxTranslate"
        formControlName="purchaseOrderNumber"
      />
      <span class="highlight"></span>
      <label class="text-muted">{{ 'invoiceSearchForm.label.purchaseOrder' | cxTranslate }}</label>
    </div>

    <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3 p-res-0">
      <ng-select
        class="mb-1"
        formControlName="status"
        bindLabel="Text"
        bindValue="Key"
        [items]="invoiceStatusList$ | async"
        [placeholder]="'invoiceSearchForm.label.status' | cxTranslate"
        [searchable]="false"
        [clearable]="false"
        bindLabel="Text"
        bindValue="Key"
        [(ngModel)]="selectedStatus"
      >
      </ng-select>
      <span class="highlight"></span>
      <label class="text-muted">{{ 'invoiceSearchForm.label.status' | cxTranslate }}</label>
    </div>

    <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3 p-res-0">
      <ng-select
        class="mb-1"
        formControlName="filterBy"
        bindLabel="Text"
        bindValue="Key"
        [items]="invoiceFilterByList$ | async"
        [placeholder]="'invoiceSearchForm.label.filterBy' | cxTranslate"
        [searchable]="false"
        [clearable]="false"
        bindLabel="Text"
        bindValue="Key"
        (change)="changeFilterBy()"
      >
      </ng-select>
      <span class="highlight"></span>
      <label class="text-muted">{{ 'invoiceSearchForm.label.filterBy' | cxTranslate }}</label>
    </div>

    <div
      class="form-group mt-3 col-lg-2 pl-0 float-left mb-3 p-res-0"
      *ngIf="filterBySelectedValue.value == 'DOCUMENT_TYPE'"
    >
      <ng-select
        class="mb-1"
        formControlName="documentType"
        bindLabel="Text"
        bindValue="Key"
        [items]="invoiceDocumentTypeList$ | async"
        [placeholder]="'invoiceSearchForm.label.documentType' | cxTranslate"
        [searchable]="false"
        [clearable]="false"
        bindLabel="Text"
        bindValue="Key"
      >
      </ng-select>
      <span class="highlight"></span>
      <label class="text-muted">{{ 'invoiceSearchForm.label.documentType' | cxTranslate }}</label>
    </div>

    <div class="form-group mt-3 col-lg-4 float-left mb-3 p-res-0" *ngIf="filterBySelectedValue.value == 'OPEN_AMOUNT'">
      <div class="row">
        <div class="col-6 pl-0">
          <input
            type="text"
            class="form-control w-100"
            [placeholder]="'invoiceSearchForm.label.from' | cxTranslate"
            formControlName="fromOpenAmount"
          />
          <span class="highlight"></span>
          <label class="text-muted">{{ 'invoiceSearchForm.label.from' | cxTranslate }}</label>
        </div>

        <div class="col-6 pl-0">
          <input
            type="text"
            class="form-control w-100"
            [placeholder]="'invoiceSearchForm.label.to' | cxTranslate"
            formControlName="toOpenAmount"
          />
          <span class="highlight"></span>
          <label class="text-muted">{{ 'invoiceSearchForm.label.to' | cxTranslate }}</label>
        </div>
      </div>
    </div>

    <div
      class="form-group mt-3 col-lg-4 float-left mb-3 p-res-0 valid-to position-relative"
      *ngIf="filterBySelectedValue.value == 'DOCUMENT_DATE'"
    >
      <div class="row">
        <div class="col-6 pl-0">
          <div class="input-group">
            <input
              class="form-control"
              [placeholder]="'invoiceSearchForm.label.from' | cxTranslate"
              name="validFrom"
              ngbDatepicker
              #validFrom="ngbDatepicker"
              formControlName="startDate"
            />
            <div class="input-group-append">
              <button
                class="btn btn calendar p-0 h-auto w-auto"
                container="body"
                (click)="validFrom.toggle()"
                type="button"
              >
                <i class="far fa-calendar-alt color-font"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="col-6 pl-0">
          <div class="input-group">
            <input
              class="form-control"
              [placeholder]="'invoiceSearchForm.label.to' | cxTranslate"
              name="validTo"
              [minDate]="invoiceSearchForm.get('startDate').value"
              ngbDatepicker
              #validTo="ngbDatepicker"
              formControlName="endDate"
            />
            <div class="input-group-append">
              <button
                class="btn btn calendar p-0 h-auto w-auto"
                container="body"
                (click)="validTo.toggle()"
                type="button"
              >
                <i class="far fa-calendar-alt color-font"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="form-group mt-3 col-lg-4 float-left mb-3 p-res-0"
      *ngIf="filterBySelectedValue.value == 'ORIGINAL_AMOUNT'"
    >
      <div class="row">
        <div class="col-6 pl-0">
          <input
            type="text"
            class="form-control w-100"
            [placeholder]="'invoiceSearchForm.label.from' | cxTranslate"
            formControlName="fromOriginalAmount"
          />
          <span class="highlight"></span>
          <label class="text-muted">{{ 'invoiceSearchForm.label.from' | cxTranslate }}</label>
        </div>

        <div class="col-6 pl-0">
          <input
            type="text"
            class="form-control w-100"
            [placeholder]="'invoiceSearchForm.label.to' | cxTranslate"
            formControlName="toOriginalAmount"
          />
          <span class="highlight"></span>
          <label class="text-muted">{{ 'invoiceSearchForm.label.to' | cxTranslate }}</label>
        </div>
      </div>
    </div>

    <div class="col-lg-12 p-0 float-left">
      <div class="col-lg-2 pl-0 p-res-0 float-left">
        <button type="submit" [disabled]="isSearchDisabled$ | async" class="w-100 btn btn-primary">
          {{ 'button.search' | cxTranslate }}
        </button>
      </div>
      <div class="col-lg-2 pl-0 p-res-0 float-left">
        <button type="button" (click)="resetForm()" class="w-100 btn btn-outline-primary">
          {{ 'button.reset' | cxTranslate }}
        </button>
      </div>
    </div>
  </form>
</div>
