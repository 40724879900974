import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { KnBrOrderToolsModule } from "./kn-br-cart/kn-br-order-tools/kn-br-order-tools.module";
import { KnBrDraftOrderModule } from "./kn-br-cart/draft-order/kn-br-draft-order.module";
import { KnBrQuoteModule } from "./kn-br-cart/quote/kn-br-quote.module";
import { KnBrServiceTicketsModule } from "./kn-br-customer-ticketing/kn-br-service-tickets/kn-br-service-tickets.module";
import { KnBrCheckoutModule } from "./kn-br-checkout/kn-br-checkout.module";

@NgModule({
  imports: [
    KnBrCheckoutModule,
    KnBrOrderToolsModule,
    KnBrDraftOrderModule,
    KnBrQuoteModule,
    KnBrServiceTicketsModule,
  ],
})
export class KnBrFeaturesLibsModule { }
