import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { ProductListComponentService } from '@spartacus/storefront';
import { KnBrProductSelectService } from 'src/feature-libs/kn-br-product/core/service/kn-br-product-select.service';
import { KnBrCartContextService } from 'src/feature-libs/kn-br-cart/base/core/facade/kn-br-cart-context.service';
import { KnBrProductScrollComponent } from 'src/feature-libs/kn-br-product/components/kn-br-product-list/kn-br-product-scroll/kn-br-product-scroll.component';
import { KnBrQuoteContextService } from 'src/feature-libs/kn-br-cart/quote/core/facade/kn-br-quote-context.service';
import { KnBrQuotesService } from 'src/feature-libs/kn-br-cart/quote/core/facade/kn-br-quotes.service';

@Component({
  selector: 'kn-br-product-scroll',
  templateUrl: './sloan-product-scroll.component.html',
  styleUrls: ['./sloan-product-scroll.component.scss'],
})
export class SloanProductScrollComponent extends KnBrProductScrollComponent implements OnInit, OnDestroy {
  constructor(
    protected productListComponentService: ProductListComponentService,
    protected ref: ChangeDetectorRef,
    protected knBrProductService: KnBrProductSelectService,
    protected activeCartService: ActiveCartFacade,
    protected knBrQuoteContextService: KnBrQuoteContextService,
    protected knBrCartContextService: KnBrCartContextService,
    protected knBrQuotesService: KnBrQuotesService
  ) {
    super(
      productListComponentService,
      ref,
      knBrProductService,
      activeCartService,
      knBrQuoteContextService,
      knBrCartContextService,
      knBrQuotesService
    );
  }

  ngOnInit() {
    this.selectedProductCodes = [];
    this.selectedProductSubscription = this.knBrProductService
      .loadSelectedProducts$()
      .subscribe((selectedProductCodes) => {
        this.selectedProductCodes = selectedProductCodes;
        this.productCodes = this.model.products
          .filter((product: any) => product.isOrderable)
          .map((product) => product.code);
        this.allChecked = this.productCodes.length === this.selectedProductCodes.length;
        this.ref.markForCheck();
      });
    this.subscriptions.add(
      this.knBrQuoteContextService.get$.subscribe((value) => {
        this.isQuoteContext = false;
        if (value) {
          this.isQuoteContext = true;
          this.isCartContext = false;
        }
        this.ref.markForCheck();
        this.setEditable();
      })
    );

    this.subscriptions.add(
      this.knBrCartContextService.get$.subscribe((value) => {
        this.isCartContext = false;
        if (value) {
          this.isCartContext = true;
          this.isQuoteContext = false;
        }
        this.ref.markForCheck();

        this.setEditable();
      })
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
