import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { OrderDetailsService } from '@spartacus/order/components';
import { Order } from '@spartacus/order/root';
import { Observable } from 'rxjs';
import { KnBrCartContextService } from 'src/feature-libs/kn-br-cart/base/core/facade/kn-br-cart-context.service';

@Component({
  selector: 'cx-order-confirmation-overview',
  templateUrl: './kn-br-order-confirmation-overview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KnBrOrderConfirmationOverviewComponent implements OnInit {
  order$: Observable<Order | undefined>;
  constructor(
    protected orderDetailsService: OrderDetailsService,
    protected knBrCartContextService: KnBrCartContextService
  ) { }

  ngOnInit() {
    this.order$ = this.orderDetailsService.getOrderDetails();
    this.knBrCartContextService.delete();
  }
}
