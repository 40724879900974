import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigModule, I18nModule, RoutingConfig } from '@spartacus/core';
import { KnBrOrderDetailsActionsModule } from './kn-br-order-details-actions/kn-br-order-details-actions.module';
import { KnBrOrderDetailsItemsModule } from './kn-br-order-details-items/kn-br-order-details-items.module';
import { KnBrOrderDetailsOverviewModule } from './kn-br-order-details-overview/kn-br-order-details-overview.module';
import { KnBrCancelDialogComponent } from './kn-br-cancel-dialog/kn-br-cancel-dialog.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    KnBrOrderDetailsOverviewModule,
    KnBrOrderDetailsActionsModule,
    KnBrOrderDetailsItemsModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    I18nModule,
    RouterModule,
  ],
  declarations: [KnBrCancelDialogComponent],
})
export class KnBrOrderDetailModule { }
