import { NgModule } from '@angular/core';
import { KnBrDraftOrderModule } from './kn-br-draft-order/kn-br-draft-order.module';
import { PAGE_LAYOUT_HANDLER } from '@spartacus/storefront';
import { CommonModule } from '@angular/common';
import { KnBrDraftOrderDetailLayoutHandler } from './kn-br-draft-order-detail-layout.hadler';
@NgModule({
  imports: [CommonModule, KnBrDraftOrderModule],
  providers: [
    {
      provide: PAGE_LAYOUT_HANDLER,
      useExisting: KnBrDraftOrderDetailLayoutHandler,
      multi: true,
    },
  ],
})
export class KnBrDraftOrderComponentsModule { }
