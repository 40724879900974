<div
  class="bg-white customersearch-list-titles table-content border-top float-left w-100 pl-2 pr-2 pt-3 pb-3 d-flex align-items-center"
  *ngIf="entry"
>
  <div class="col-md-3 text-center title-wrap padding-res-0 pl-0 pr-0 float-left d-flex align-items-center">
    <div class="text-center padding-res-0 float-left pr-2">
      <div class="remember-me col-md-12 float-left d-flex justify-content-start p-0">
        <div class="form-check custom-checkbox" *ngIf="!isDisabled()">
          <input
            type="checkbox"
            class="custom-control-input"
            [checked]="isChecked(entry.entryNumber)"
            [id]="entry.entryNumber"
            (change)="changeCheckBox($event)"
          />
          <label class="custom-control-label color-font" [for]="entry.entryNumber"></label>
        </div>
      </div>
    </div>
    <cx-media [container]="entry.product?.images" format="thumbnail"> </cx-media>
    <div
      class="m-res-0 ml-3 text-left float-left product-name"
      [routerLink]="{ cxRoute: 'product', params: entry.product } | cxUrl"
    >
      <span class="color-font font-text-600">{{ entry.product?.code }}</span>
      <span class="color-font font-text-600">{{ entry.product?.name }}</span>
      <span class="font-size color-font pt-1">
        {{ 'cartCommon.label.lineNo' | cxTranslate }}: {{ entry.entryNumber }}
      </span>
    </div>
  </div>

  <div class="col-md-1 text-center title-wrap float-left pl-2 pr-2 color-font font-size">
    <span class="res-titles"> {{ 'cartCommon.label.plant' | cxTranslate }}:</span>
    {{ entry.product?.manufacturer }}
  </div>
  <div class="col-md-2 text-left float-left pl-2 pr-2">
    <span class="res-titles"> {{ 'cartCommon.label.requestedDeliveryDate' | cxTranslate }}:</span>
    <div class="form-group mt-3 col-lg-12 float-left mb-3 valid-to p-res-0 position-relative">
      <div class="input-group">
        <input
          class="form-control w-100 cursor-pointer mb-0"
          [placeholder]="dateFormat$ | async"
          name="validFrom"
          ngbDatepicker
          #validFrom="ngbDatepicker"
          [formControl]="reqShipDate"
          (blur)="update($event)"
          (dateSelect)="update($event)"
          [minDate]="minDate"
        />
        <div class="input-group-append">
          <button
            class="btn btn calendar p-0 h-auto w-auto"
            container="body"
            (click)="validFrom.toggle()"
            type="button"
          >
            <i class="far fa-calendar-alt color-font"></i>
          </button>
        </div>
      </div>
      <cx-form-errors [control]="reqShipDate"></cx-form-errors>
    </div>
  </div>
  <div class="col-md-1 text-center title-wrap float-left pl-2 pr-2 color-font">
    <span class="res-titles"> {{ 'cartCommon.label.listPrice' | cxTranslate }}:</span>
    {{ entry?.knbrTotalPrice?.formatedListPrice }}
  </div>
  <div class="col-md-1 text-center title-wrap float-left pl-2 pr-2 color-font">
    <span class="res-titles"> {{ 'cartCommon.label.netPrice' | cxTranslate }} :</span>
    {{ entry?.knbrTotalPrice?.formatedNetPrice }}
  </div>
  <div class="col-md-1 text-left title-wrap float-left pl-2 pr-2">
    <span class="res-titles"> {{ 'cartCommon.label.quantity' | cxTranslate }}:</span>

    <div class="form-group col-lg-12 pl-0 mb-0 pr-0 float-left valid-to">
      <input
        type="text"
        min="0"
        class="form-control w-100"
        [formControl]="quantity"
        (blur)="update($event)"
        knBrNumbersOnly
      />
      <span class="highlight"></span>
      <span class="col-md-12 float-left p-0 font-sm-size color-font">
        {{ 'cartCommon.label.uom' | cxTranslate }}: {{ entry.uom }}
      </span>
      <span class="col-md-12 float-left p-0 font-sm-size color-font">
        {{ 'cartCommon.label.moq' | cxTranslate }}: {{ entry.minimumOrderQuantity }}
      </span>
    </div>
  </div>

  <div class="col-md-1 text-center title-wrap float-left pl-1 pr-1 color-font font-text-bold">
    <span class="res-titles"> {{ 'cartCommon.label.total' | cxTranslate }}:</span>
    {{ entry.totalPrice?.formattedValue }}
  </div>
  <!-- <div class="col-md-1 text-left title-wrap float-left pl-2 pr-2">
    <span class="res-titles"> {{ 'cartCommon.label.priceAdjustment' | cxTranslate }}:</span>
    <div
      class="form-group col-lg-12 pl-1 pr-1 mb-0 float-left valid-to"
      *ngIf="!entry.knbrQuoteReference || !entry.knbrQuoteReference?.quoteRefNumber"
    >
      <input
        type="text"
        min="0"
        class="form-control w-100"
        [formControl]="priceAdjusted"
        (blur)="update($event)"
        knBrAllowDecimal
      />
      <span class="highlight"></span>
    </div>
  </div> -->

  <div class="col-md-1 text-center title-wrap float-left pl-0 pr-0">
    <span class="res-titles">{{ 'cartCommon.label.quoteReference' | cxTranslate }}:</span>
    <a
      *ngIf="!entry.knbrQuoteReference || (entry.knbrQuoteReference && !entry.knbrQuoteReference?.quoteRefNumber)"
      class="color-font font-lg-size"
      [class.cursor-block]="isDisabled()"
      (click)="openQuoteReference(entry)"
    >
      <i class="fas fa-file-alt"></i
    ></a>
    <ng-container *ngIf="entry.knbrQuoteReference && entry.knbrQuoteReference?.quoteRefNumber">
      <span class="font-size color-font col-lg-12 p-0 d-flex align-items-center justify-content-center float-left">
        {{ entry.knbrQuoteReference?.quoteRefNumber }}
      </span>
      <span class="font-size color-font col-lg-12 p-0 align-items-center justify-content-center float-left">
        {{ entry.knbrQuoteReference?.refQuotePriceAdjusted }} x 1Item
      </span>
      <span class="col-lg-12 align-items-center justify-content-center float-left pl-4 pr-4 pt-2">
        <a class="font-size-17 color-font" [class.cursor-block]="isDisabled()" (click)="openQuoteReference(entry)"
          ><i class="fas fa-file-alt float-left"></i
        ></a>
        <a class="font-size-17 color-font" [class.cursor-block]="isDisabled()" (click)="deleteQuoteReference(entry)"
          ><i class="far fa-trash-alt float-right"></i
        ></a>
      </span>
    </ng-container>
  </div>

  <div
    class="col-md-1 text-center title-wrap float-left pl-0 pr-0 color-font font-size font-lg-size"
    (click)="openComment(entry)"
  >
    <span class="res-titles"> {{ 'cartCommon.label.actions' | cxTranslate }}:</span>
    <i class="fas fa-comments pr-2"></i> {{ entry && entry.commentCount ? entry.commentCount : 0 }}
  </div>
</div>
