import { map, startWith } from 'rxjs/operators';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewContainerRef,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Subscription, Observable } from 'rxjs';
import { KnBrOrderHistoryService } from 'src/feature-libs/kn-br-order/core/facade/kn-br-order-history.service';
import { KnBrCustomerContextService } from 'src/app/store/kn-br-customer-context/kn-br-customer-context.service';
import { LaunchDialogService } from '@spartacus/storefront';
import { SloanAddProductsComponent } from 'src/feature-libs/sloan/sloan-order/sloan-customer360/sloan-order-detail/sloan-add-products/sloan-add-products.component';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { KnBrCartContextService } from 'src/feature-libs/kn-br-cart/base/core/facade/kn-br-cart-context.service';
import { KnBrDraftOrdersService } from 'src/feature-libs/kn-br-cart/draft-order/core/facade/kn-br-draft-orders.service';
import { KnBrQuoteContextService } from 'src/feature-libs/kn-br-cart/quote/core/facade/kn-br-quote-context.service';
import { KnBrQuoteEntryService } from 'src/feature-libs/kn-br-cart/quote/core/facade/kn-br-quote-entry.service';
import { KnBrQuotesService } from 'src/feature-libs/kn-br-cart/quote/core/facade/kn-br-quotes.service';

@Component({
  selector: 'knbr-kn-br-quick-entry',
  templateUrl: './sloan-quick-entry.component.html',
  styleUrls: ['./sloan-quick-entry.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class SloanQuickEntryComponent extends SloanAddProductsComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  cartContext: string;
  quoteContext: string;
  customerContext: string;
  quoteItems$: Observable<number> = this.knBrQuoteService.get$.pipe(
    startWith({ totalItems: 0 }),
    map((quote) => (quote && quote.totalItems) || 0)
  );
  cartItems$: Observable<number> = this.activeCartService.getActive().pipe(
    startWith({ totalItems: 0 }),
    map((cart) => (cart && cart.totalItems) || 0)
  );
  constructor(
    protected formBuilder: FormBuilder,
    protected cdr: ChangeDetectorRef,
    protected knBrOrderHistoryService: KnBrOrderHistoryService,
    private knBrCartContextService: KnBrCartContextService,
    private knBrQuoteContextService: KnBrQuoteContextService,
    protected knBrDraftOrdersService: KnBrDraftOrdersService,
    protected knbrCommonService: KnBrCommonService,
    protected knBrCustomerContextService: KnBrCustomerContextService,
    protected launchDialogService: LaunchDialogService,
    protected vcr: ViewContainerRef,
    protected knBrQuoteEntryService: KnBrQuoteEntryService,
    protected activeCartService: ActiveCartFacade,
    protected knBrQuoteService: KnBrQuotesService
  ) {
    super(
      formBuilder,
      cdr,
      knBrOrderHistoryService,
      knBrDraftOrdersService,
      knbrCommonService,
      launchDialogService,
      vcr,
      knBrQuoteEntryService,
      knBrQuoteService
    );
    this.subscription.add(
      this.knBrCartContextService.get$.subscribe((data) => {
        this.cartContext = data;
        this.cdr.markForCheck();
      })
    );
    this.subscription.add(
      this.knBrQuoteContextService.get$.subscribe((data) => {
        this.quoteContext = data;
        this.cdr.markForCheck();
      })
    );
    this.subscription.add(
      this.knBrCustomerContextService.getActiveCustomerNumber().subscribe((response) => {
        this.customerContext = response ? response : null;
      })
    );
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
