import { Injectable } from '@angular/core';
import { PageMetaResolver, PageType, ProductSearchService, RoutingService, TranslationService } from '@spartacus/core';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

/**
 * Resolves the page data for the Search Result Page based on the
 * `PageType.CATEGORY_PAGE` and the `SearchResultsListPageTemplate` template.
 *
 * Only the page title is resolved in the standard implemenation.
 */
@Injectable({
  providedIn: 'root',
})
export class KnBrSearchPageMetaResolver extends PageMetaResolver implements PageMetaResolver {
  protected total$: Observable<number> = this.productSearchService.getResults().pipe(
    filter((data) => !!data?.pagination),
    map((results) => results.pagination.totalResults)
  );

  protected query$: Observable<string> = this.routingService
    .getRouterState()
    .pipe(map((state) => state.state.params.query));

  constructor(
    protected routingService: RoutingService,
    protected productSearchService: ProductSearchService,
    protected translation: TranslationService
  ) {
    super();
    this.pageType = PageType.CONTENT_PAGE;
    this.pageTemplate = 'KnBrSearchResultsGridPageTemplate';
  }

  resolveTitle(): Observable<string> {
    const sources = [this.total$, this.query$];
    return combineLatest(sources).pipe(
      switchMap(([t, q]: [number, string]) =>
        this.translation.translate('pageMetaResolver.search.title', {
          count: t,
          query: q,
        })
      )
    );
  }
}
