import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import {
  KnBrQuoteAddUpdateContext,
  KnBrQuoteContextActions,
  KnBrQuoteContextActionTypes,
  KnBrQuoteLoadSuccessContext,
  KnBrQuoteRemoveContext,
  KnBrQuoteResetContext,
} from '../actions/kn-br-quote-context.actions';
import { KnBrCartContextService } from 'src/feature-libs/kn-br-cart/base/core/facade/kn-br-cart-context.service';
import { KnBrQuoteContextAdapter } from '../../connectors/kn-br-quote-context.adapter';

@Injectable()
export class KnBrQuoteContextEffects {

  loadQuoteContext$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrQuoteContextActionTypes.KnBrQuoteContextLoadAction),
    map((x) => {
      const context = this.knBrQuoteContextAdapter.get();
      return new KnBrQuoteLoadSuccessContext(context);
    })
  )
  );

  addUpdateQuoteContext$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrQuoteContextActionTypes.KnBrQuoteContextAddUpdateAction),
    map((action: KnBrQuoteAddUpdateContext) => action.payload),
    map((quoteCode: string) => {
      this.knBrCartContextService.delete();
      this.knBrQuoteContextAdapter.set(quoteCode);
    })
  ),
    { dispatch: false }
  );

  removeQuoteContext$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrQuoteContextActionTypes.KnBrQuoteContextRemoveAction),
    map((action: KnBrQuoteRemoveContext) => action.payload),
    map((quoteCode: any) => {
      this.knBrQuoteContextAdapter.remove();
    })
  ),
    { dispatch: false }
  );

  resetQuoteContext$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrQuoteContextActionTypes.KnBrQuoteContextResetAction),
    map((action: KnBrQuoteResetContext) => action.payload),
    map((quoteCode: any) => {
      this.knBrQuoteContextAdapter.remove();
    })
  ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions<KnBrQuoteContextActions>,
    private knBrQuoteContextAdapter: KnBrQuoteContextAdapter,
    private knBrCartContextService: KnBrCartContextService
  ) { }
}
