import { Observable } from 'rxjs/internal/Observable';
import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { ForgotPasswordComponent, ForgotPasswordComponentService } from '@spartacus/user/profile/components';
import { KnBrForgotPasswordComponentService } from 'src/app/services/kn-br-forgot-password.service';

@Component({
  selector: 'sloan-forgot-password',
  templateUrl: './sloan-forgot-password.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SloanForgotPasswordFormComponent extends ForgotPasswordComponent implements OnDestroy {
  errMsg$: Observable<string> = this.service.loadForgotpasswordErrMsg$();
  closed = false;
  constructor(protected service: KnBrForgotPasswordComponentService) {
    super(service);
  }

  onSubmit() {
    this.service.setForgotPassswordErrMsg(null);
    this.closed = false;
    // super.onSubmit();
    this.service.requestEmail();
  }

  ngOnDestroy() {
    this.service.setForgotPassswordErrMsg(null);
  }
}
