<mat-sidenav-container class="example-container">
  <mat-sidenav *ngIf="isNotLoginPage$ | async" [opened]="menuState$ | async" [mode]="'over'" (closed)="_onClosed()"
    (opened)="_onOpened()" class="kn-br-sidebar">
    <cx-page-slot position="TopHeaderSlot"></cx-page-slot>
  </mat-sidenav>
  <mat-sidenav-content>
    <kn-br-outlet></kn-br-outlet>
    <cx-storefront [ngClass]="{ 'asm-storefront': isLoggedInASM }"></cx-storefront>
  </mat-sidenav-content>
</mat-sidenav-container>
