import { NgModule } from '@angular/core';
import { ConfigModule } from '@spartacus/core';
import { LayoutConfig } from '@spartacus/storefront';

@NgModule({
  declarations: [],
  imports: [
    ConfigModule.withConfig({
      layoutSlots: {
        header: null,
        KnBrProductDetailsPageTemplate: null,
      },
    } as LayoutConfig),

    ConfigModule.withConfig({
      layoutSlots: {
        header: {
          lg: {
            slots: ['SiteLogo', 'SiteContext'],
          },
          slots: ['SiteLogo', 'SiteContext'],
        },
        ErrorPageTemplate: {
          slots: ['TopContent', 'MiddleContent', 'BottomContent'],
        },
        KnBrLoginPageTemplate: {
          slots: ['LeftContentSlot', 'SiteTitle'],
        },
        LandingPage2Template: {
          slots: ['Section1', 'Section2'],
        },
        KnBrLandingPage2Template: {
          pageFold: 'Section2B',
          slots: ['Section1', 'Section1B', 'Section2A', 'Section2B', 'Section2C', 'Section3', 'Section4', 'Section5'],
        },
        KnBrProductListPageTemplate: {
          slots: ['BottomHeaderSlot', 'SearchBox', 'Section1', 'ProductLeftRefinements', 'ProductListSlot'],
        },
        KnBrProductGridPageTemplate: {
          slots: ['BottomHeaderSlot', 'SearchBox', 'Section1', 'ProductLeftRefinements', 'ProductGridSlot'],
        },
        KnBrSearchResultsListPageTemplate: {
          slots: ['SearchBox', 'Section1', 'ProductLeftRefinements', 'SearchResultsListSlot'],
        },
        KnBrSearchResultsGridPageTemplate: {
          slots: ['SearchBox', 'Section1', 'ProductLeftRefinements', 'SearchResultsGridSlot'],
        },
        KnBrDraftOrderPageTemplate: {
          slots: ['TopContent', 'BodyContent'],
        },
        KnBrDraftOrderCreatePageTemplate: {
          slots: ['BodyContent'],
        },
        KnBrDraftOrderDetailPageTemplate: {
          slots: [
            'KnBrDraftOrderHeaderSlot',
            'KnBrDraftOrderActionBarSlot',
            'KnBrDraftOrderItemsSlot',
            'KnBrEmptyCartSlot',
          ],
        },
        KnBrQuoteCreatePageTemplate: {
          slots: ['BodyContent'],
        },
        KnBrQuoteDetailPageTemplate: {
          slots: ['KnBrQuoteHeaderSlot', 'KnBrQuoteActionBarSlot', 'KnBrQuoteItemsSlot', 'KnBrEmptyCartSlot'],
        },
        KnBrOrderConfirmationPageTemplate: {
          slots: ['BodyContent', 'SideContent'],
        },
        KnBrQuotePageTemplate: {
          slots: ['TopContent', 'KnBrQuoteListActionBarSlot', 'BodyContent'],
        },
        KnBrProductDetailsPageTemplate: {
          lg: {
            pageFold: 'UpSelling',
          },
          pageFold: 'Summary',
          slots: [
            'BottomHeaderSlot',
            'Summary',
            'UpSelling',
            'CrossSelling',
            'ProductFeatures',
            'Tabs',
            'PlaceholderContentSlot',
          ],
        },
        knBrQuoteSellerApproverTemplate: {
          slots: ['LeftContentSlot', 'SiteTitle'],
        },
        KnBrCustomer360PageTemplate: {
          slots: ['TopContent', 'MiddleContent', 'BottomContent', 'BodyContent'],
        },
        KnBrOrderHistoryPageTemplate: {
          slots: ['TopContent', 'BodyContent'],
        },
        KnBrOrderDetailsPageTemplate: {
          slots: ['TopContent', 'BodyContent'],
        },
        KnBrReturnHistoryPageTemplate: {
          slots: ['TopContent', 'BodyContent'],
        },
        KnBrReturnDetailsPageTemplate: {
          slots: ['BodyContent'],
        },
        KnBrInvoiceListPageTemplate: {
          slots: ['TopContent', 'BodyContent'],
        },
        KnBrAddressBookPageTemplate: {
          slots: ['BodyContent'],
        },
        KnBrFactSheetPageTemplate: {
          slots: ['TopContent', 'MiddleContent', 'BottomContent'],
        },
        KnBrOrderImportPageTemplate: {
          slots: ['BodyContent'],
        },
        KnBrQuickOrderPageTemplate: {
          slots: ['TopContent', 'BodyContent'],
        },
        KnBrOrderFormsPageTemplate: {
          slots: ['TopContent', 'BodyContent'],
        },
        KnBrQuickEntryPageTemplate: {
          slots: ['TopContent', 'BodyContent'],
        },
        KnBrPurchasingAgentPageTemplate: {
          slots: ['Section2'],
        },
        KnBrServiceTicketsPageTemplate: {
          slots: ['Section1'],
        },
        AccountPageTemplate: {
          slots: ['BodyContent'],
        },
        KnBrRegistrationPageTemplate: {
          slots: ['BodyContent'],
        },
        NACPageTemplate: {
          slots: ['TopContent', 'BottomContent'],
        },
        KnBrMarketCentrePageTemplate: {
          slots: ['LeftContentSlot', 'RightContentSlot'],
        },
        ContentPage1Template: {
          slots: ['Section2A'],
        },
        CompanyPageTemplate: {
          slots: ['BodyContent'],
        },
        MultiStepCheckoutSummaryPageTemplate: {
          slots: ['TopContent', 'BodyContent', 'SideContent', 'BottomContent'],
        },
      },
    } as LayoutConfig),
  ],
})
export class KnBrLayoutConfigModule { }
