import { KnBrDirectiveModule } from 'src/app/shared/directive/kn-br-directive.module';
import { ListNavigationModule, MediaModule, SpinnerModule } from '@spartacus/storefront';
import { I18nModule, ConfigModule, CmsConfig, UrlModule } from '@spartacus/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { SloanOrderFormItemComponent } from './sloan-order-form-item/sloan-order-form-item.component';
import { SloanOrderFormSearchResultComponent } from './sloan-order-form-search-result.component';
import { RouterModule } from '@angular/router';
import { SloanAddToCartModule } from 'src/feature-libs/sloan/sloan-product/sloan-add-to-cart/sloan-add-to-cart.module';
import { SloanAddToQuoteModule } from 'src/feature-libs/sloan/sloan-product/sloan-add-to-quote/sloan-add-to-quote.module';

@NgModule({
  declarations: [SloanOrderFormSearchResultComponent, SloanOrderFormItemComponent],
  imports: [
    CommonModule,
    I18nModule,
    MediaModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    ListNavigationModule,
    SloanAddToCartModule,
    SloanAddToQuoteModule,
    SpinnerModule,
    KnBrDirectiveModule,
    RouterModule,
    UrlModule,
    ConfigModule.withConfig({
      cmsComponents: {
        KnBrOrderFormComponent: {
          component: SloanOrderFormSearchResultComponent,
        },
      },
    } as CmsConfig),
  ],
})
export class SloanOrderFormSearchResultModule {}
