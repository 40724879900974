import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SloanFactSheetHeaderModule } from './sloan-fact-sheet-header/sloan-fact-sheet-header.module';
import { SloanFactSheetGroupModule } from './sloan-fact-sheet-group/sloan-fact-sheet-group.module';
import { SloanFactSheetMaterialModule } from './sloan-fact-sheet-material/sloan-fact-sheet-material.module';
import { RouterModule } from '@angular/router';
import { KnBrCustomer360Module } from 'src/feature-libs/kn-br-order/components/kn-br-customer360/kn-br-customer360.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, KnBrCustomer360Module, SloanFactSheetHeaderModule, SloanFactSheetGroupModule, SloanFactSheetMaterialModule, RouterModule],
})
export class SloanFactSheetModule { }
