import { Component, OnDestroy, OnInit } from '@angular/core';
import { RoutingService, TranslationService } from '@spartacus/core';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import { KnBrOrderReturn } from 'src/feature-libs/kn-br-order/root/models/kn-br-order.model';
import { KnBrCustomerContextService } from 'src/app/store/kn-br-customer-context/kn-br-customer-context.service';
import { KnBrOrderReturnService } from 'src/feature-libs/kn-br-order/core/facade/kn-br-order-return.service';
import { CustomerData } from 'src/feature-libs/kn-br-user/kn-br-account/root/model/customer.model';

@Component({
  selector: 'knbr-kn-br-return-order-search-result',
  templateUrl: './kn-br-return-order-search-result.component.html',
  styleUrls: ['./kn-br-return-order-search-result.component.scss'],
})
export class KnBrReturnOrderSearchResultComponent implements OnInit, OnDestroy {
  orderReturnListResult$: Observable<KnBrOrderReturn[]> = this.knBrOrderReturnService.list$;
  isLoader$: Observable<boolean> = this.knBrOrderReturnService.loader$;
  paginate$: Observable<any> = this.knBrOrderReturnService.paginate$;
  sorts$: Observable<any> = this.knBrOrderReturnService.sorts$;
  searchCriteria: any;
  sortType: string;
  subscription: Subscription;

  constructor(
    public knBrOrderReturnService: KnBrOrderReturnService,
    protected routeService: RoutingService,
    protected knBrCustomerContextService: KnBrCustomerContextService,
    protected translation: TranslationService
  ) { }

  ngOnInit(): void {
    this.subscription = this.knBrOrderReturnService.searchCriteria$.subscribe((response) => {
      this.searchCriteria = _.cloneDeep(response);
    });
    this.subscription.add(
      this.paginate$.subscribe((paginate: any) => {
        if (paginate) {
          this.sortType = paginate.sort;
        }
      })
    );
  }

  getSortLabels(): Observable<{ byDate: string; byRMA: string; byPONumber: string; byStatus: string }> {
    return combineLatest([
      this.translation.translate('sorting.date'),
      this.translation.translate('sorting.rma'),
      this.translation.translate('sorting.poNumber'),
      this.translation.translate('sorting.status'),
    ]).pipe(
      map(([textByDate, textByRMA, textByPoNumber, textByStatus]) => {
        return {
          byDate: textByDate,
          byRMA: textByRMA,
          byPONumber: textByPoNumber,
          byStatus: textByStatus,
        };
      })
    );
  }

  changeSortCode(sortCode: string): void {
    if (this.searchCriteria) {
      this.searchCriteria.currentPage = 0;
      this.searchCriteria.sort = sortCode;
    }
    this.sortType = sortCode;
    this.knBrOrderReturnService.returnOrderListSearch(this.searchCriteria);
  }

  loadMore(currentPage: number) {
    if (this.searchCriteria) {
      this.searchCriteria.currentPage = currentPage + 1;
      this.searchCriteria.sort = this.sortType;
    }
    this.knBrOrderReturnService.returnOrderListSearch(this.searchCriteria);
  }

  redirectToDetail(order: KnBrOrderReturn) {
    const customerContext: CustomerData = {
      customerNumber: order.accountNumber,
      customerName: order.accountName,
      telephone: order.accountPhone,
    };
    this.knBrCustomerContextService.set(customerContext);
    this.routeService.go({ cxRoute: 'returnRequestDetails', params: order });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
