import { createFeatureSelector, createSelector } from '@ngrx/store';
import { KnBrOrderHistoryState, KN_BR_ORDER_HISTORY_FEATURE } from '../../../../../app/store/kn-br-order-history/kn-br-order-history.state';

export const getOrderHistoryListState = createFeatureSelector<KnBrOrderHistoryState>(KN_BR_ORDER_HISTORY_FEATURE);

export const getOrderHistory = createSelector(getOrderHistoryListState, (state: KnBrOrderHistoryState) => state.orders);

export const getOrderHistoryLoader = createSelector(
  getOrderHistoryListState,
  (state: KnBrOrderHistoryState) => state.isLoading
);

export const getOrderHistoryPaginate = createSelector(
  getOrderHistoryListState,
  (state: KnBrOrderHistoryState) => state.pagination
);

export const getOrderHistorySorts = createSelector(
  getOrderHistoryListState,
  (state: KnBrOrderHistoryState) => state.sorts
);

export const getOrderHistorySearchCriteria = createSelector(
  getOrderHistoryListState,
  (state: KnBrOrderHistoryState) => state.searchRequest
);

export const getOrderHistorySearchDisabled = createSelector(
  getOrderHistoryListState,
  (state: KnBrOrderHistoryState) => state.isSearchDisabled
);

export const getProductsOrderForms = createSelector(
  getOrderHistoryListState,
  (state: KnBrOrderHistoryState) => state.orderFormResponse
);

export const getEditOrderDisable = createSelector(
  getOrderHistoryListState,
  (state: KnBrOrderHistoryState) => state.editDisable
);

export const getOrdeFormsSearchCriteria = createSelector(
  getOrderHistoryListState,
  (state: KnBrOrderHistoryState) => state.orderFormsSearchRequest
);

export const getOrderFormsLoader = createSelector(
  getOrderHistoryListState,
  (state: KnBrOrderHistoryState) => state.orderFormsLoading
);

export const getOrderFormsPaginate = createSelector(
  getOrderHistoryListState,
  (state: KnBrOrderHistoryState) => state.orderFormspagination
);

export const getOrderFormsSorts = createSelector(
  getOrderHistoryListState,
  (state: KnBrOrderHistoryState) => state.orderFormssorts
);

export const getOrdeFormsSearchDisabled = createSelector(
  getOrderHistoryListState,
  (state: KnBrOrderHistoryState) => state.orderFormsSearchDisabled
);
