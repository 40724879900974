import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { KnBrCartContextService } from 'src/feature-libs/kn-br-cart/base/core/facade/kn-br-cart-context.service';
import { KnBrQuoteContextService } from 'src/feature-libs/kn-br-cart/quote/core/facade/kn-br-quote-context.service';
import { KnBrQuoteEntryService } from 'src/feature-libs/kn-br-cart/quote/core/facade/kn-br-quote-entry.service';
import { KnBrDraftOrdersService } from 'src/feature-libs/kn-br-cart/draft-order/core/facade/kn-br-draft-orders.service';

@Component({
  selector: 'knbr-kn-br-product-quick-entry-popup',
  templateUrl: './kn-br-product-quick-entry-popup.component.html',
  styleUrls: ['./kn-br-product-quick-entry-popup.component.scss'],
})
export class KnBrProductQuickEntryPopupComponent implements OnInit, OnDestroy {
  productNo: string;
  isDisabled = true;
  modalRef: any;
  isCartContext = false;
  isQuoteContext = false;
  subscriptions = new Subscription();
  isSaveDisabled$: Observable<boolean> = this.knBrQuotEntryService.isSaveDisabled$;
  constructor(
    protected modalService: NgbModal,
    private knBrQuotEntryService: KnBrQuoteEntryService,
    private activeCartService: ActiveCartFacade,
    private knBrQuoteContextService: KnBrQuoteContextService,
    private knBrCartContextService: KnBrCartContextService,
    protected knBrDraftOrdersService: KnBrDraftOrdersService
  ) { }

  ngOnInit(): void {
    this.subscriptions.add(
      this.knBrQuoteContextService.get$.subscribe((value) => {
        if (value) {
          this.isQuoteContext = true;
          this.isCartContext = false;
        }
      })
    );

    this.subscriptions.add(
      this.knBrCartContextService.get$.subscribe((value) => {
        if (value) {
          this.isCartContext = true;
          this.isQuoteContext = false;
        }
      })
    );
  }

  save() {
    const productNo = this.prepareMaterialArray(this.productNo);
    const quoteEntry = {
      code: productNo.toString(),
      quantity: 1,
    };
    if (this.isQuoteContext) {
      this.knBrQuotEntryService.add(quoteEntry);
    }
    if (this.isCartContext) {
      this.knBrDraftOrdersService.setAddCartEntryLoading(true);
      this.activeCartService.addEntry(productNo.toString(), 1);
    }
    this.dismissModal();
  }

  prepareMaterialArray(materialNumbers: string) {
    const material = [];
    const splitMaterialNumbers = materialNumbers.split('\n').join(',').split(',');
    const filteredMaterialBumbers = splitMaterialNumbers.filter((el) => {
      return el !== '';
    });
    filteredMaterialBumbers.forEach((element) => {
      material.push(element.trim());
    });
    return material;
  }

  onChange(value) {
    if (value.length < 1) {
      this.isDisabled = true;
    } else {
      this.isDisabled = false;
    }
  }

  dismissModal(reason?: any): void {
    this.modalService.dismissAll(reason);
  }
  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    this.subscriptions.unsubscribe();
  }
}
