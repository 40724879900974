import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { KnBrAddressStateModule } from './kn-br-address/kn-br-address.state.module';
import { KnBrCartContextStateModule } from './kn-br-cart-context/kn-br-cart-context.module';
import { KnBrCartStateModule } from './kn-br-cart/kn-br-cart-state.module';
import { KnBrCustomerContextStateModule } from './kn-br-customer-context/kn-br-customer-context.module';
import { KnBrCustomerStateModule } from './kn-br-customer/kn-br-customer.state.module';
import { KnBrDraftOrdersCommentsStateModule } from './kn-br-draft-order-comments/kn-br-draft-orders-comments.state.module';
import { KnBrDraftOrdersStateModule } from './kn-br-draft-orders/kn-br-draft-orders.state.module';
import { KnBrFactSheetStateModule } from './kn-br-fact-sheet/kn-br-fact-sheet.state.module';
import { KnBrInvoiceStateModule } from './kn-br-invoice/kn-br-invoice.state.module';
import { KnBrOrderHistoryStateModule } from './kn-br-order-history/kn-br-order-history.state.module';
import { KnBrOrderReturnStateModule } from './kn-br-order-return/kn-br-order-return.state.module';
import { KnBrQuoteContextStateModule } from './kn-br-quote-context/kn-br-quote-context.module';
import { KnBrQuoteEntryStateModule } from './kn-br-quote-entry/kn-br-quote-entry.module';
import { KnBrQuoteReferenceStateModule } from './kn-br-quote-reference/kn-br-quote-reference.module';
import { KnBrQuotesCommentsStateModule } from './kn-br-quotes-comments/kn-br-quotes-comments.state.module';
import { KnBrQuotesStateModule } from './kn-br-quotes/kn-br-quotes.state.module';
import { KnBrSpinnerStateModule } from './kn-br-spinner/kn-br-spinner-state.module';
import { KnBrCategoryNavigationModule } from '../customer-libs/kn-br-navigation/components/kn-br-category-navigation/kn-br-category-navigation.module';

@NgModule({
  imports: [
    CommonModule,
    KnBrCartStateModule,
    KnBrCustomerStateModule,
    KnBrCustomerContextStateModule,
    KnBrDraftOrdersStateModule,
    KnBrQuotesStateModule,
    KnBrCartContextStateModule,
    KnBrQuoteContextStateModule,
    KnBrQuoteEntryStateModule,
    KnBrQuotesCommentsStateModule,
    KnBrDraftOrdersCommentsStateModule,
    KnBrQuoteReferenceStateModule,
    KnBrInvoiceStateModule,
    KnBrOrderHistoryStateModule,
    KnBrOrderReturnStateModule,
    KnBrAddressStateModule,
    KnBrFactSheetStateModule,
    KnBrSpinnerStateModule,
    KnBrCategoryNavigationModule
  ],
})
export class KnBrStoreModule { }
