<div class="product-main-grid p-0 d-flex">
  <div class="col-lg-12 product-list-view p-0" *ngIf="model$ | async as model">
    <div class="row counter-wrapper">
      <div class="col-12 col-lg-12 p-res-right-0" *ngIf="viewMode$ | async as viewMode">
        <div class="cx-sorting top">
          <div class="row">
            <div *ngIf="!isInfiniteScroll" class="col-auto">
              <div class="cx-pagination" aria-label="product search pagination">
                <cx-pagination
                  [pagination]="model.pagination"
                  queryParam="currentPage"
                  [defaultPage]="0"
                ></cx-pagination>
              </div>
            </div>
          </div>
        </div>
        <div>
          <!-- Product list when using pagination -->
          <ng-container *ngIf="!isInfiniteScroll; else infiniteScroll">
            <ng-container *ngIf="viewMode === ViewModes.Grid">
              <div class="select-all position-fixed p-3 right-0 color-knbr-primary">
                <div
                  class="
                    remember-me
                    custom-control
                    col-md-12
                    float-left
                    d-flex
                    justify-content-start
                    cursor-pointer
                    p-0
                  "
                >
                  <div class="form-check custom-checkbox mb-0 m-0">
                    <input type="checkbox" class="custom-control-input rounded" id="default" />
                    <label class="custom-control-label text-light font-size" for="default">
                      {{ 'productList.label.selectAll' | cxTranslate }}
                    </label>
                  </div>
                </div>
              </div>
              <kn-br-product-grid-item
                *ngFor="let product of model?.products"
                [product]="product"
                class="col-12 col-sm-6 col-md-4"
              ></kn-br-product-grid-item>
            </ng-container>

            <ng-container *ngIf="viewMode === ViewModes.List">
              <div class="select-all position-fixed p-3 right-0 color-knbr-primary">
                <div
                  class="
                    remember-me
                    custom-control
                    col-md-12
                    float-left
                    d-flex
                    justify-content-start
                    cursor-pointer
                    p-0
                  "
                >
                  <div class="form-check custom-checkbox mb-0 m-0">
                    <input type="checkbox" class="custom-control-input rounded" id="default" />
                    <label class="custom-control-label text-light font-size" for="default">
                      {{ 'productList.label.selectAll' | cxTranslate }}
                    </label>
                  </div>
                </div>
              </div>
              <div class="customersearch-list-table product-res-titles float-left col-lg-12 pr-0">
                <div class="customersearch-list-titles border-top float-left w-100 p-3">
                  <div class="col-md-4 text-center padding-res-0 float-left font-text-600">
                    {{ 'productList.label.name' | cxTranslate }}
                  </div>
                  <div class="col-md-1 text-center float-left font-text-600">
                    {{ 'productList.label.id' | cxTranslate }}
                  </div>
                  <div class="col-md-2 text-center float-left font-text-600">
                    {{ 'productList.label.netPrice' | cxTranslate }}
                  </div>
                  <div class="col-md-2 text-center float-left font-text-600">
                    {{ 'productList.label.listPrice' | cxTranslate }}
                  </div>
                  <div class="col-md-1 text-center float-left font-text-600">
                    {{ 'productList.label.uom' | cxTranslate }}
                  </div>
                  <div class="col-md-1 text-center float-left font-text-600">
                    {{ 'productList.label.moq' | cxTranslate }}
                  </div>
                  <div class="col-md-1 text-center float-left font-text-600"></div>
                </div>
                <kn-br-product-list-item
                  *ngFor="let product of model?.products"
                  [product]="product"
                ></kn-br-product-list-item>
              </div>
            </ng-container>
          </ng-container>

          <!-- Product list when using infinite scroll -->
          <ng-template #infiniteScroll>
            <kn-br-product-scroll
              [scrollConfig]="scrollConfig"
              [model]="model"
              [inputViewMode]="viewMode"
            ></kn-br-product-scroll>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
