import { Action } from '@ngrx/store';

export enum SloanFactSheetActionTypes {
  SloanFactSheetDetailsAction = '[FactSheet] Details',
  SloanFactSheetDetailsSuccessAction = '[FactSheet] Details Success',
  SloanFactSheetDetailsFailureAction = '[FactSheet] Details Failure',
}

/**
 * Action to Search Order History
 */
export class SloanFactSheetDetail implements Action {
  readonly type = SloanFactSheetActionTypes.SloanFactSheetDetailsAction;
  constructor() {}
}

/**
 * Action dispatched on successful Order History Search
 */
export class SloanFactSheetDetailsSuccess implements Action {
  readonly type = SloanFactSheetActionTypes.SloanFactSheetDetailsSuccessAction;
  constructor(public payload: any) {}
}

/**
 * Action dispatched on failure Order History Search
 */
export class SloanFactSheetDetailsFailure implements Action {
  readonly type = SloanFactSheetActionTypes.SloanFactSheetDetailsFailureAction;
  constructor(public payload: Error) {}
}

export type SloanFactSheetActions = SloanFactSheetDetail | SloanFactSheetDetailsSuccess | SloanFactSheetDetailsFailure;
