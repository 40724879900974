/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CartValidationGuard } from '@spartacus/cart/base/core';
import { CartNotEmptyGuard, CheckoutAuthGuard } from '@spartacus/checkout/base/components';
import { CmsConfig, I18nModule, provideConfig } from '@spartacus/core';
import { CardModule, SpinnerModule } from '@spartacus/storefront';
import { AddressFormModule } from "@spartacus/user/profile/components";
import { KnBrCheckoutDeliveryAddressComponent } from './kn-br-checkout-delivery-address.component';

@NgModule({
  imports: [CommonModule, RouterModule, AddressFormModule, CardModule, SpinnerModule, I18nModule],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        CheckoutDeliveryAddress: {
          component: KnBrCheckoutDeliveryAddressComponent,
          guards: [CheckoutAuthGuard, CartNotEmptyGuard, CartValidationGuard],
        },
      },
    }),
  ],
  declarations: [KnBrCheckoutDeliveryAddressComponent],
  exports: [KnBrCheckoutDeliveryAddressComponent],
})
export class KnBrCheckoutDeliveryAddressModule { }
