import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, queueScheduler } from 'rxjs';
import { observeOn } from 'rxjs/operators';
import * as _ from 'lodash';

import { KnBrQuoteReference } from '../../root/models/kn-br-quote.model';
import {
  KnBrQuoteAddReference,
  KnBrQuoteLoadReference,
  KnBrQuoteLoadReferenceByProduct,
  KnBrQuoteRemoveReference,
  KnBrQuoteUpdateReference,
} from '../store/actions/kn-br-quote-reference.actions';
import { StateWithQuoteReference } from '../../../../../app/store/kn-br-quote-reference/kn-br-quote-reference.state';
import { getQuoteReference } from '../store/selecters/kn-br-quote-reference.selector';

@Injectable({
  providedIn: 'root',
})
export class KnBrQuoteReferenceService {
  constructor(private store: Store<StateWithQuoteReference>) { }

  get$: Observable<KnBrQuoteReference[]> = this.store.pipe(select(getQuoteReference), observeOn(queueScheduler));
  pdpQuoteRef: KnBrQuoteReference;

  add(quoteReference: KnBrQuoteReference) {
    this.store.dispatch(new KnBrQuoteAddReference(quoteReference));
  }

  delete(quoteReference: any) {
    this.store.dispatch(new KnBrQuoteRemoveReference(quoteReference));
  }
  update(quoteReference: KnBrQuoteReference) {
    this.store.dispatch(new KnBrQuoteUpdateReference(quoteReference));
  }

  get(entryNumber) {
    this.store.dispatch(new KnBrQuoteLoadReference(entryNumber));
  }
  getQuoteByProduct(productId: string) {
    this.store.dispatch(new KnBrQuoteLoadReferenceByProduct(productId));
  }
  addQuoteFromPDP(entryNumber: number) {
    if (this.pdpQuoteRef) {
      const selectedQuoteReference = _.cloneDeep(this.pdpQuoteRef);
      if (selectedQuoteReference) {
        selectedQuoteReference.entryNumber = entryNumber;
        this.add(selectedQuoteReference);
        // this.pdpQuoteRef = undefined;
      }
    }
  }
}
