import { CommonModule } from '@angular/common';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { FormErrorsModule } from '@spartacus/storefront';
import { KnBrOrderHistorySearchComponent } from './kn-br-order-history-search.component';

@NgModule({
  declarations: [KnBrOrderHistorySearchComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    UrlModule,
    I18nModule,
    FormErrorsModule,
    NgSelectModule,
    NgbTooltipModule,
    ConfigModule.withConfig({
      cmsComponents: {
        KnBrOrderHistorySearchComponent: {
          component: KnBrOrderHistorySearchComponent,
        },
      },
    } as CmsConfig),
  ],
})
export class KnBrOrderHistorySearchModule {}
