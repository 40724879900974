import { Component, OnDestroy, OnInit } from '@angular/core';
import { RoutingService, GlobalMessageService } from '@spartacus/core';
import { OrderDetailsService } from '@spartacus/order/components';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';
import { KnBrOrderHistoryService } from 'src/feature-libs/kn-br-order/core/facade/kn-br-order-history.service';
import { KnBrOrderDetailsItemsComponent } from 'src/feature-libs/kn-br-order/components/kn-br-customer360/kn-br-order-detail/kn-br-order-details-items/kn-br-order-details-items.component';
import { SloanCommonService } from 'src/app/services/sloan/sloan-common.service';

@Component({
  selector: 'knbr-kn-br-order-details-items',
  templateUrl: './sloan-order-details-items.component.html',
  styleUrls: ['./sloan-order-details-items.component.scss'],
})
export class SloanOrderDetailsItemsComponent extends KnBrOrderDetailsItemsComponent implements OnInit, OnDestroy {
  constructor(
    protected orderDetailsService: OrderDetailsService,
    protected knBrOrderHistoryService: KnBrOrderHistoryService,
    protected knBrCommonService: KnBrCommonService,
    protected routingService: RoutingService,
    protected messageService: GlobalMessageService,
    protected sloanCommonService: SloanCommonService
  ) {
    super(orderDetailsService, knBrOrderHistoryService, knBrCommonService, routingService, messageService);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  isItemOpen(item) {
    return item && item.lineItemStatus && item.lineItemStatus === 'Open';
  }

  onQuantityChange(eventData) {
    super.onQuantityChange(eventData);
    this.sloanCommonService.editOrderResponse$.next({
      ...this.sloanCommonService.editOrderResponse$.value,
      addRequestEntryInputs: this.addedProductItems,
      editRequestEntryInputs: this.editItems,
    });
  }

  onInputChange(eventData) {
    super.onInputChange(eventData);
    this.sloanCommonService.editOrderResponse$.next({
      ...this.sloanCommonService.editOrderResponse$.value,
      addRequestEntryInputs: this.addedProductItems,
      editRequestEntryInputs: this.editItems,
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
