import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';
import { RemoveLeadingZerosPipe } from 'src/app/shared/pipe/kn-br-custom-pipes/remove-leading-zeros.pipe';
import { KnBrCustomerContextService } from 'src/app/store/kn-br-customer-context/kn-br-customer-context.service';
import { KN_BR_CUSTOMER_CURRENT } from 'src/constants/api.endpoints.constant';
import { KnBrDraftOrdersService } from 'src/feature-libs/kn-br-cart/draft-order/core/facade/kn-br-draft-orders.service';
import { Statuses } from 'src/feature-libs/kn-br-cart/draft-order/root/models/kn-br-draft-order.model';
import { CustomerData } from 'src/feature-libs/kn-br-user/kn-br-account/root/model/customer.model';

@Component({
  selector: 'kn-br-draft-order-search',
  templateUrl: './kn-br-draft-order-search.component.html',
  styleUrls: ['./kn-br-draft-order-search.component.scss'],
  providers: [RemoveLeadingZerosPipe],
})
export class KnBrDraftOrderSearchComponent implements OnInit, OnDestroy {
  draftSearchForm: UntypedFormGroup;
  status$: Observable<Statuses[]> = this.knBrCommonService.loadOrderStatuses$();
  customerContextData$: Observable<CustomerData> = this.knBrCustomerContextService.get$;
  selectedCustomer: CustomerData;
  currentPage = 0;
  isSearchDisabled$: Observable<boolean> = this.knBrDraftSearchService.isSearchDisabled$;
  subscription: Subscription;

  constructor(
    protected fb: UntypedFormBuilder,
    protected knBrDraftSearchService: KnBrDraftOrdersService,
    protected knBrCustomerContextService: KnBrCustomerContextService,
    protected knBrCommonService: KnBrCommonService,
    protected removeLeadingZerosPipe: RemoveLeadingZerosPipe
  ) { }

  ngOnInit(): void {
    this.subscription = this.customerContextData$.subscribe((value) => {
      if (value) {
        this.selectedCustomer = value;
      }
      const customerNumber = value?.customerNumber ? value?.customerNumber : KN_BR_CUSTOMER_CURRENT;
      this.knBrCommonService.getOrderStatusesList(customerNumber);
      this.initForm();
    });
    this.submitForm();
  }

  initForm() {
    this.draftSearchForm = this.fb.group({
      draftOrderName: null,
      accountNumber: [
        {
          value:
            this.selectedCustomer && this.selectedCustomer.customerNumber
              ? this.removeLeadingZerosPipe.transform(this.selectedCustomer.customerNumber)
              : null,
          disabled: this.selectedCustomer && this.selectedCustomer.customerNumber ? true : false,
        },
      ],
      accountName: [
        {
          value:
            this.selectedCustomer && this.selectedCustomer.customerName ? this.selectedCustomer.customerName : null,
          disabled: this.selectedCustomer && this.selectedCustomer.customerName ? true : false,
        },
      ],
      purchaseOrderNumber: null,
      jobName: null,
      status: null,
      currentPage: this.currentPage,
    });
  }

  submitForm() {
    this.knBrDraftSearchService.search(this.draftSearchForm.getRawValue());
  }

  resetForm() {
    this.draftSearchForm.reset();
    this.initForm();
    this.submitForm();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
