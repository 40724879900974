import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { AuthGuard, CmsConfig, ConfigModule, I18nModule, RoutingConfig } from '@spartacus/core';
import { CardModule, FormErrorsModule, IconModule, SpinnerModule } from '@spartacus/storefront';
import { AddressFormModule } from "@spartacus/user/profile/components";
import { KnBrCustomerContextGuard } from 'src/app/shared/guards/kn-br-customer-context.guard';
import { KnBrAddressBookComponent } from '../kn-br-address-book/kn-br-address-book.component';
import { KnBrAddressCardComponent } from './kn-br-address-card/kn-br-address-card.component';
import { KnBrAddressFormComponent } from './kn-br-address-form/kn-br-address-form.component';

@NgModule({
  declarations: [KnBrAddressBookComponent, KnBrAddressFormComponent, KnBrAddressCardComponent],
  imports: [
    CommonModule,
    I18nModule,
    CardModule,
    AddressFormModule,
    SpinnerModule,
    ReactiveFormsModule,
    FormsModule,
    FormErrorsModule,
    NgSelectModule,
    IconModule,
    ConfigModule.withConfig({
      cmsComponents: {
        AccountAddressBookComponent: {
          component: () => import('./kn-br-address-book.component').then((c) => c.KnBrAddressBookComponent),
          guards: [AuthGuard, KnBrCustomerContextGuard],
        },
      },
    } as CmsConfig),
  ],
  exports: [KnBrAddressBookComponent, KnBrAddressFormComponent, KnBrAddressCardComponent],
})
export class KnBrAddressBookModule { }
