import { NgModule } from '@angular/core';
import { CmsConfig, provideDefaultConfig, provideDefaultConfigFactory, RoutingConfig } from '@spartacus/core';
import { UserFormService } from '@spartacus/organization/administration/components';
import { AdminGuard, B2B_USER_NORMALIZER, B2B_USER_SERIALIZER, B2B_USERS_NORMALIZER } from '@spartacus/organization/administration/core';
import {
  defaultBudgetRoutingConfig,
  defaultCostCenterRoutingConfig,
  defaultOrganizationLayoutConfig,
  defaultPermissionRoutingConfig,
  defaultUnitsRoutingConfig,
  defaultUserGroupRoutingConfig,
  defaultUserRoutingConfig,
} from '@spartacus/organization/administration/root';
import { KnBrUserFormService } from '../components/users/kn-br-add-user-form/kn-br-user-form.service';
import { KnBrB2BUserListNormalizer } from '../core/connectors/b2b-user/kn-br-b2b-user-list-normalizer';
import { KnBrB2BUserNormalizer } from '../core/connectors/b2b-user/kn-br-b2b-user.converter';
import { ORGANIZATION_ADMINISTRATION_FEATURE } from './feature-name';
import { KnBrAdminGuard } from 'src/app/shared/guards/kn-br-admin.guard';
import { KnBrB2BUserSerializer } from 'src/app/serializer/kn-br-b2b-user-serializer';
import { knBrUserRoutingConfig } from '../occ/config/kn-br-organization-users.config';
import { knBrUserCmsConfig, knBrUserTableConfig } from '../components/users/user.config';
// TODO: Inline this factory when we start releasing Ivy compiled libraries
export function defaultOrganizationAdministrationComponentsConfig(): CmsConfig {
  const config: CmsConfig = {
    featureModules: {
      [ORGANIZATION_ADMINISTRATION_FEATURE]: {
        cmsComponents: [
          'ManageBudgetsListComponent',
          'ManageCostCentersListComponent',
          'ManagePermissionsListComponent',
          'ManageUnitsListComponent',
          'ManageUsersListComponent',
          'ManageUserGroupsListComponent',
        ],
      },
    },
  };

  return config;
}

@NgModule({
  providers: [
    provideDefaultConfig(defaultOrganizationLayoutConfig),

    provideDefaultConfig(<RoutingConfig>{
      routing: { routes: { organization: { paths: ['organization'] } } },
    }),
    provideDefaultConfig(defaultBudgetRoutingConfig),
    provideDefaultConfig(defaultCostCenterRoutingConfig),
    provideDefaultConfig(defaultPermissionRoutingConfig),
    provideDefaultConfig(defaultUnitsRoutingConfig),
    provideDefaultConfig(defaultUserRoutingConfig),
    provideDefaultConfig(knBrUserRoutingConfig),
    provideDefaultConfig(knBrUserTableConfig),
    provideDefaultConfig(knBrUserCmsConfig),
    provideDefaultConfig(defaultUserGroupRoutingConfig),

    provideDefaultConfigFactory(defaultOrganizationAdministrationComponentsConfig),
    {
      provide: UserFormService,
      useClass: KnBrUserFormService,
    },
    {
      provide: AdminGuard,
      useClass: KnBrAdminGuard,
    },
    {
      provide: B2B_USER_NORMALIZER,
      useClass: KnBrB2BUserNormalizer,
      multi: true,
    },
    {
      provide: B2B_USER_SERIALIZER,
      useClass: KnBrB2BUserSerializer,
      multi: true,
    },
    {
      provide: B2B_USERS_NORMALIZER,
      useClass: KnBrB2BUserListNormalizer,
      multi: true,
    },
  ],
})
export class AdministrationRootModule { }
