import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { KnBrMessageDialogComponent } from './kn-br-message-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class KnBrMessageDialogService {
  constructor(private modalService: NgbModal) {}

  public open(
    title: string,
    message: string,
    btnOkText: string = 'button.ok',
    dialogSize: 'sm' | 'lg' = 'sm',
    innerHtml?: string
  ): Promise<boolean> {
    const modalRef = this.modalService.open(KnBrMessageDialogComponent, { size: dialogSize, centered: true });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.innerHtml = innerHtml;
    return modalRef.result;
  }
}
