import { Component, OnInit } from '@angular/core';
import { TranslationService } from '@spartacus/core';
import { SloanCommonService } from 'src/app/services/sloan/sloan-common.service';
import { KnBrInvoiceService } from 'src/feature-libs/kn-br-order/core/facade/kn-br-invoice.service';
import { KnBrInvoiceListSeachResultComponent } from 'src/feature-libs/kn-br-order/components/kn-br-customer360/kn-br-invoice-list/kn-br-invoice-list-seach-result/kn-br-invoice-list-seach-result.component';

@Component({
  selector: 'app-sloan-invoice-list-search-result',
  templateUrl: './sloan-invoice-list-search-result.component.html',
  styleUrls: ['./sloan-invoice-list-search-result.component.scss'],
})
export class SloanInvoiceListSearchResultComponent extends KnBrInvoiceListSeachResultComponent implements OnInit {
  constructor(
    public knBrInvoiceService: KnBrInvoiceService,
    protected translation: TranslationService,
    protected commonService: SloanCommonService
  ) {
    super(knBrInvoiceService, translation);
  }

  ngOnInit() {
    super.ngOnInit();
  }
  downloadInvoiceItemPDF(documentNumber: string) {
    this.commonService.downloadInvoiceItemPDF(documentNumber);
  }

  splitDate(date) {
    if (date) {
      return date.split('T')[0];
    }
  }
}
