import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { shareReplay } from 'rxjs/operators';
import { DraftSearchForm } from 'src/feature-libs/kn-br-cart/draft-order/root/models/draft-order.model';
import { KnBrCustomerContextService } from 'src/app/store/kn-br-customer-context/kn-br-customer-context.service';
import { KN_BR_CUSTOMER_CURRENT, KN_BR_DRAFT_SEARCH, KN_BR_DRAFT_ORDER, KN_BR_DRAFT_ORDER_COPY, KN_BR_DRAFT_ORDER_UPDATE, KN_BR_DRAFT_ORDER_DELETE, KN_BR_DRAFT_ORDER_CHECKOUT_VALIDATION, KN_BR_DRAFT_ORDER_STATUSES, KN_BR_DRAFT_ORDER_QUICK_ENTRY } from 'src/constants/api.endpoints.constant';
import { KnBrCartContextService } from 'src/feature-libs/kn-br-cart/base/core/facade/kn-br-cart-context.service';

@Injectable({
  providedIn: 'root',
})
export class KnBrDraftOrdersAdapter {
  customerId: string;
  cartId: string;
  constructor(
    private endPointService: OccEndpointsService,
    private httpClient: HttpClient,
    protected knBrCustomerContextService: KnBrCustomerContextService,
    protected knBrCartContextService: KnBrCartContextService
  ) {
    this.knBrCustomerContextService.getActiveCustomerNumber().subscribe((response) => {
      this.customerId = response ? response : null;
    });
    this.knBrCartContextService.get$.subscribe((response) => {
      this.cartId = response ? response : null;
    });
  }

  search(draftSearch: DraftSearchForm) {
    const CUSTOMER_NUMBER = this.customerId ? this.customerId : KN_BR_CUSTOMER_CURRENT;

    return this.httpClient.get(
      this.endPointService.buildUrl(KN_BR_DRAFT_SEARCH(CUSTOMER_NUMBER), { queryParams: draftSearch })
    );
  }

  create(payload) {
    return this.httpClient.post(
      this.endPointService.buildUrl(KN_BR_DRAFT_ORDER, {
        urlParams: {
          customerId: this.customerId,
        },
      }),
      payload
    );
  }

  copy(payload) {
    const order = { name: payload.name, description: payload.name };
    return this.httpClient.post(
      this.endPointService.buildUrl(KN_BR_DRAFT_ORDER_COPY, {
        urlParams: {
          customerId: this.customerId,
          cartId: payload.referenceCartId,
        },
        queryParams: order,
      }),
      null
    );
  }

  update(payload) {
    return this.httpClient.put(
      this.endPointService.buildUrl(KN_BR_DRAFT_ORDER_UPDATE, {
        urlParams: {
          customerId: this.customerId,
          cartId: this.cartId,
        },
      }),
      payload
    );
  }

  delete(payload) {
    return this.httpClient.delete(
      this.endPointService.buildUrl(KN_BR_DRAFT_ORDER_DELETE, {
        urlParams: {
          customerId: this.customerId,
          cartId: this.cartId,
        },
      })
    );
  }

  patch(payload) {
    return this.httpClient.patch(
      this.endPointService.buildUrl(KN_BR_DRAFT_ORDER_UPDATE, {
        urlParams: {
          customerId: this.customerId,
          cartId: this.cartId,
        },
      }),
      payload
    );
  }

  updateEntry(payload) {
    return this.httpClient.patch(
      this.endPointService.buildUrl('updateEntries', {
        urlParams: {
          customerId: this.customerId,
          cartId: this.cartId,
          entryNumber: payload?.entryNumber,
        },
        queryParams: {
          ...payload,
        },
      }),
      null
    );
  }
  validateOnCheckout() {
    return this.httpClient.get(
      this.endPointService.buildUrl(KN_BR_DRAFT_ORDER_CHECKOUT_VALIDATION, {
        urlParams: {
          customerId: this.customerId,
          cartId: this.cartId,
        },
      })
    );
  }

  getCartStatuses() {
    return this.httpClient
      .get(
        this.endPointService.buildUrl(KN_BR_DRAFT_ORDER_STATUSES, {
          urlParams: {
            customerId: this.customerId,
            cartId: this.cartId,
          },
        })
      )
      .pipe(shareReplay());
  }

  quickEntry(payload) {
    if (payload && payload.cartId) {
      this.cartId = payload.cartId;
      payload = payload.request;
    }
    const reobj = {
      addProductEntryInputs: payload,
    };
    return this.httpClient.post(
      this.endPointService.buildUrl(KN_BR_DRAFT_ORDER_QUICK_ENTRY, {
        urlParams: {
          customerId: this.customerId,
          cartId: this.cartId,
        },
      }),
      reobj
    );
  }
}
