<div class="customersearch-list-table mt-3 float-left col-lg-12 p-0">
  <div class="customersearch-list-titles border-top float-left w-100 pl-2 pr-2 pt-3 pb-3 d-none d-sm-none d-lg-block">
    <div class="col-md-3 text-center float-left font-text-600 pl-0 pr-0 font-sm-size line-height-16">
      <div class="text-center padding-res-0 float-left">
        <div class="remember-me col-md-12 float-left d-flex justify-content-start p-0">
          <div class="form-check custom-checkbox" *ngIf="!isDisabled()">
            <input
              type="checkbox"
              class="custom-control-input"
              id="delet"
              [checked]="allChecked"
              (change)="selectAll($event)"
            />
            <label class="custom-control-label color-font" for="delet"></label>
          </div>
        </div>
      </div>
      {{ 'cartCommon.label.productDetails' | cxTranslate }}
    </div>
    <div class="col-md-1 text-center float-left font-text-600 pl-2 pr-2 font-sm-size line-height-16">
      {{ 'cartCommon.label.plant' | cxTranslate }}
    </div>
    <div class="col-md-2 text-center float-left font-text-600 pl-2 pr-2 font-sm-size line-height-16">
      {{ 'cartCommon.label.requestedShipDate' | cxTranslate }}
    </div>
    <div class="col-md-1 text-center float-left font-text-600 pl-2 pr-2 font-sm-size line-height-16">
      {{ 'cartCommon.label.listPrice' | cxTranslate }}
    </div>
    <div class="col-md-1 text-center float-left font-text-600 pl-2 pr-2 font-sm-size line-height-16">
      {{ 'cartCommon.label.netPrice' | cxTranslate }}
    </div>
    <div class="col-md-1 text-center float-left font-text-600 pl-2 pr-2 font-sm-size line-height-16">
      {{ 'cartCommon.label.quantity' | cxTranslate }}
    </div>
    <div class="col-md-1 text-center float-left font-text-600 pl-1 pr-1 font-sm-size line-height-16">
      {{ 'cartCommon.label.total' | cxTranslate }}
    </div>
    <!-- <div class="col-md-1 text-center float-left font-text-600 pl-2 pr-2 font-sm-size line-height-16">
      {{ 'cartCommon.label.priceAdjustment' | cxTranslate }}
    </div> -->
    <div class="col-md-1 text-center float-left font-text-600 pl-2 pr-2 font-sm-size line-height-16">
      {{ 'cartCommon.label.quoteReference' | cxTranslate }}
    </div>
    <div class="col-md-1 text-center float-left font-text-600 pl-2 pr-2 font-sm-size line-height-16">&nbsp;</div>
  </div>
  <ng-container *ngIf="draftOrder$ | async as draftorder">
    <kn-br-draft-order-item
      [entry]="entry"
      (valueChangeEvent)="getChangedValue($event)"
      [isShipComplete]="draftorder.shipComplete"
      *ngFor="let entry of entries$ | async"
    ></kn-br-draft-order-item>
  </ng-container>
</div>
