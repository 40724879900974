<form [formGroup]="quoteEditForm" (ngSubmit)="onSubmit()" *ngIf="knBrQuoteService.get$ | async">
  <fieldset [disabled]="isLoading$ | async">
    <div class="rounded w-100 float-left">
      <div class="float-right text-left p-0 justify-content-end d-flex align-items-center">
        <button class="btn back-btn pl-1 pr-1 pb-1 pt-1 font-lg-size" (click)="goBack()">
          <i class="fas fa-level-down-alt fa-rotate-90" aria-hidden="true"></i>
        </button>
      </div>
    </div>
    <div class="col-md-12 float-left customersearch-forms mt-3 login-forms p-0">
      <div class="col-lg-12 p-0 float-left">
        <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3">
          <input
            type="text"
            class="form-control w-100"
            placeholder="{{ 'quoteCommon.label.quoteOrderNumber' | cxTranslate }}"
            [value]="quoteNumberUI"
            disabled
          />
          <span class="highlight"></span>
          <label class="text-muted">{{ 'quoteCommon.label.quoteOrderNumber' | cxTranslate }}</label>
        </div>
        <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3">
          <input
            type="text"
            class="form-control w-100"
            placeholder="{{ 'quoteCommon.label.status' | cxTranslate }}"
            formControlName="status"
          />
          <span class="highlight"></span>
          <label class="text-muted">{{ 'quoteCommon.label.status' | cxTranslate }}</label>
        </div>

        <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-4 valid-to p-res-0 position-relative">
          <label class="text-muted m-0"
            >{{ 'quoteCommon.label.validFrom' | cxTranslate }}
            <span class="text-danger font-size-18 float-right ml-2">*</span></label
          >
          <div class="input-group">
            <input
              class="form-control w-100 cursor-pointer"
              [placeholder]="dateFormat$ | async"
              [maxDate]="quoteEditForm.get('quoteValidTo').value"
              name="validFrom"
              ngbDatepicker
              #validFrom="ngbDatepicker"
              formControlName="quoteValidFrom"
              (blur)="onSubmit()"
              (dateSelect)="onSubmit()"
              [minDate]="minDate"
            />
            <div class="input-group-append">
              <button
                class="btn btn calendar p-0 h-auto w-auto"
                container="body"
                (click)="validFrom.toggle()"
                type="button"
                [disabled]="isDisabled()"
              >
                <i class="far fa-calendar-alt color-font"></i>
              </button>
            </div>
          </div>
          <cx-form-errors
            *ngIf="
              quoteEditForm.get('quoteValidFrom')?.errors?.required ||
              quoteEditForm.get('quoteValidFrom')?.errors?.ngbDate?.invalid
            "
            [control]="quoteEditForm.get('quoteValidFrom')"
          ></cx-form-errors>
          <span
            class="kn-br-form-errors"
            *ngIf="
              quoteEditForm.get('quoteValidFrom')?.errors?.ngbDate?.minDate ||
              quoteEditForm.get('quoteValidFrom')?.errors?.ngbDate?.maxDate
            "
          >
            <p>{{ 'formErrors.invalidDateRange' | cxTranslate }}</p>
          </span>
        </div>
        <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-4 valid-to p-res-0 position-relative">
          <label class="text-muted m-0"
            >{{ 'quoteCommon.label.validTo' | cxTranslate }}
            <span class="text-danger font-size-18 float-right ml-2">*</span></label
          >
          <div class="input-group">
            <input
              class="form-control w-100 cursor-pointer"
              [placeholder]="dateFormat$ | async"
              name="quoteValidTo"
              [minDate]="quoteEditForm.get('quoteValidFrom').value"
              ngbDatepicker
              #validTo="ngbDatepicker"
              formControlName="quoteValidTo"
              (blur)="onSubmit()"
              (dateSelect)="onSubmit()"
            />
            <div class="input-group-append">
              <button
                class="btn btn calendar p-0 h-auto w-auto"
                container="body"
                (click)="validTo.toggle()"
                type="button"
                [disabled]="isDisabled()"
              >
                <i class="far fa-calendar-alt color-font"></i>
              </button>
            </div>
          </div>
          <cx-form-errors
            *ngIf="
              quoteEditForm.get('quoteValidTo')?.errors?.required ||
              quoteEditForm.get('quoteValidTo')?.errors?.ngbDate?.invalid
            "
            [control]="quoteEditForm.get('quoteValidTo')"
          ></cx-form-errors>
          <span
            class="kn-br-form-errors"
            *ngIf="
              quoteEditForm.get('quoteValidTo')?.errors?.ngbDate?.minDate ||
              quoteEditForm.get('quoteValidTo')?.errors?.ngbDate?.maxDate
            "
          >
            <p>{{ 'formErrors.invalidDateRange' | cxTranslate }}</p>
          </span>
        </div>
      </div>
      <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-4 valid-to p-res-0 position-relative">
        <label class="text-muted m-0">{{ 'quoteCommon.label.createdBy' | cxTranslate }} </label>
        <input
          type="text"
          class="form-control w-100"
          placeholder="{{ 'quoteCommon.label.createdBy' | cxTranslate }}"
          formControlName="createdBy"
        />
      </div>
      <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-4 valid-to p-res-0 position-relative">
        <label class="text-muted m-0">{{ 'quoteCommon.label.createdOn' | cxTranslate }} </label>
        <input
          type="text"
          class="form-control w-100"
          placeholder="{{ 'quoteCommon.label.creationDate' | cxTranslate }}"
          formControlName="creationDate"
        />
      </div>
      <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-4 valid-to p-res-0 position-relative">
        <label class="text-muted m-0">{{ 'quoteDetailHeader.label.lastUpdatedDate' | cxTranslate }} </label>
        <input
          type="text"
          class="form-control w-100"
          placeholder="{{ 'quoteDetailHeader.label.lastUpdatedDate' | cxTranslate }}"
          formControlName="lastUpdatedDate"
        />
      </div>
      <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3 valid-to p-res-0 position-relative">
        <label class="text-muted m-0">{{ 'quoteDetailHeader.label.reqdeliveryDate' | cxTranslate }}</label>
        <div class="input-group">
          <input
            class="form-control w-100 cursor-pointer"
            [placeholder]="dateFormat$ | async"
            name="rdd"
            ngbDatepicker
            #rdd="ngbDatepicker"
            formControlName="rdd"
            (dateSelect)="onSubmit()"
            (blur)="onSubmit()"
            [minDate]="minDate"
          />
          <div class="input-group-append">
            <button
              class="btn btn calendar p-0 h-auto w-auto"
              [disabled]="isDisabled()"
              container="body"
              (click)="rdd.toggle()"
              type="button"
            >
              <i class="far fa-calendar-alt color-font"></i>
            </button>
          </div>
        </div>

        <cx-form-errors [control]="quoteEditForm.get('rdd')"></cx-form-errors>
      </div>

      <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3">
        <input
          type="text"
          class="form-control w-100"
          placeholder="{{ 'quoteCommon.label.customerPO' | cxTranslate }}"
          formControlName="purchaseOrderNumber"
          (blur)="onSubmit()"
        />
        <span class="highlight"></span>
        <label class="text-muted">{{ 'quoteCommon.label.customerPO' | cxTranslate }} </label>
      </div>

      <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3">
        <input
          type="text"
          class="form-control w-100"
          placeholder="{{ 'quoteCommon.label.jobNameNumber' | cxTranslate }}"
          formControlName="jobName"
          (blur)="onSubmit()"
        />
        <span class="highlight"></span>
        <label class="text-muted"
          >{{ 'quoteCommon.label.jobNameNumber' | cxTranslate }}
          <span class="text-danger font-size-18 float-right ml-2">*</span></label
        >
        <cx-form-errors [control]="quoteEditForm.get('jobName')"></cx-form-errors>
      </div>

      <div class="col-lg-12 p-0 float-left">
        <div class="col-lg-2 float-left pl-1 color-font font-size-17 font-text-600">
          <label class="float-left font-text-600 mr-2"> {{ 'quoteDetailHeader.label.revision' | cxTranslate }} </label>
          {{ quote.version }}
        </div>
        <div class="col-lg-2 float-left pl-1 color-font font-size-17 font-text-600">
          <label class="float-left font-text-600 mr-2"> {{ 'quoteCommon.label.totalItems' | cxTranslate }} : </label>
          {{ quote.totalItems }}
        </div>
        <div class="col-lg-2 float-left pl-1 color-font font-size-17 font-text-600">
          <label class="float-left font-text-600 mr-2"> {{ 'quoteCommon.label.totalQuantity' | cxTranslate }} : </label>
          {{ quote.totalUnitCount }}
        </div>
        <div class="col-lg-2 float-left pl-1 color-font font-size-17 font-text-600">
          <label class="float-left font-text-600 mr-2"> {{ 'quoteCommon.label.totalPrice' | cxTranslate }} : </label>
          {{ quote.totalPrice?.formattedValue }}
        </div>
      </div>
    </div>
    <div class="col-md-12 float-left customersearch-forms mt-3 login-forms p-0">
      <div class="form-group head-notes mt-3 col-lg-3 pl-0 float-left mb-3">
        <label for="exampleFormControlTextarea1">{{ 'quoteCommon.label.headerNotes' | cxTranslate }} </label>
        <textarea
          class="form-control w-100"
          id="exampleFormControlTextarea1"
          rows="2"
          placeholder="{{ 'quoteCommon.label.headerNotes' | cxTranslate }}"
          formControlName="headerNotes"
          (blur)="onSubmit()"
        ></textarea>
      </div>
      <div class="remember-me float-left mt-3 d-flex justify-content-start p-2 mr-4">
        <div class="form-check custom-checkbox m-0">
          <input
            type="checkbox"
            class="custom-control-input"
            id="defaultUnchecked"
            formControlName="shipComplete"
            (change)="onSubmit()"
          />
          <label class="custom-control-label color-font" for="defaultUnchecked"
            >{{ 'quoteCommon.label.shipComplete' | cxTranslate }}
          </label>
        </div>
      </div>

      <div class="col-md-4 float-left text-left p-0 m-res-bottom">
        <button
          *ngIf="quote && quote.checkoutReady"
          type="button"
          class="btn btn-primary col-md-6 mt-3"
          (click)="acceptAndCheckout()"
        >
          {{ 'quoteCommon.label.checkout' | cxTranslate }}
        </button>
      </div>
      <ng-container *ngIf="statuses$ | async as statuses">
        <div
          class="form-group head-notes mt-3 col-lg-3 pl-0 float-left mb-3"
          *ngIf="statuses && statuses.length && quote && quote.entries?.length && !quote.checkoutReady"
        >
          <ng-select
            class=""
            formControlName="quoteStatus"
            [items]="statuses$ | async"
            [placeholder]="'label.actions' | cxTranslate"
            [searchable]="false"
            [clearable]="false"
            bindLabel="Text"
            bindValue="Key"
            [readonly]="!quoteEditForm.valid"
          >
          </ng-select>
          <span class="highlight"></span>
          <label class="text-muted">{{ 'label.actions' | cxTranslate }}</label>
        </div>
        <div
          class="form-group head-notes mt-3 col-lg-3 pl-0 float-left mb-3"
          *ngIf="statuses && statuses.length && quote && quote.entries?.length && !quote.checkoutReady"
        >
          <button type="button" class="btn btn-primary" (click)="onSubmit(true)" [disabled]="!quoteEditForm.valid">
            {{ 'quoteCommon.label.go' | cxTranslate }}
          </button>
        </div>
      </ng-container>
    </div>
  </fieldset>
</form>
