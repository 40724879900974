import { Injectable } from '@angular/core';
import { Converter, Occ } from '@spartacus/core';
import { KnBrB2BUser } from '../../model/kn-br-b2b-user.model';
import { environment } from 'src/environments/environment';

@Injectable()
export class KnBrB2BUserNormalizer implements Converter<Occ.B2BUser, KnBrB2BUser> {
  convert(source: Occ.B2BUser, target?: KnBrB2BUser): KnBrB2BUser {
    if (target === undefined) {
      target = { ...(source as any) };
    }
    target.email = source?.uid;

    return target;
  }
}
