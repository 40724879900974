import { Component, ComponentRef, OnInit, ViewContainerRef, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { LaunchDialogService, LAUNCH_CALLER } from '@spartacus/storefront';
import { SloanFactSheetData } from 'src/app/models/sloan/sloan-fact-sheet.model';
import { SloanCommonService } from 'src/app/services/sloan/sloan-common.service';
import { SloanFactSheetService } from 'src/app/store/sloan/sloan-fact-sheet/sloan-fact-sheet.service';

@Component({
  selector: 'knbr-sloan-fact-sheet-header',
  templateUrl: './sloan-fact-sheet-header.component.html',
  styleUrls: ['./sloan-fact-sheet-header.component.scss'],
})
export class SloanFactSheetHeaderComponent implements OnInit, OnDestroy {
  factSheetData$: Observable<SloanFactSheetData> = this.factSheetService.factSheetData$;
  loader$: Observable<boolean> = this.factSheetService.loading$;
  showSpinner: void | Observable<ComponentRef<any>>;
  subscription = new Subscription();
  constructor(
    protected factSheetService: SloanFactSheetService,
    protected commonService: SloanCommonService,
    protected launchDialogService: LaunchDialogService,
    protected vcr: ViewContainerRef
  ) {}

  ngOnInit(): void {
    this.factSheetService.getFactSheetData();
    this.subscription.add(
      this.loader$.subscribe((isLoading) => {
        this.onSpinnerLoading(isLoading);
      })
    );
  }

  downloadFactSheetPdf() {
    this.commonService.downloadFactSheetPDF();
  }

  onSpinnerLoading(isLoading) {
    if (isLoading) {
      this.showSpinner = this.launchDialogService.launch(LAUNCH_CALLER.PLACE_ORDER_SPINNER, this.vcr);
    } else {
      if (this.showSpinner) {
        this.showSpinner
          .subscribe((component) => {
            this.launchDialogService.clear(LAUNCH_CALLER.PLACE_ORDER_SPINNER);
            component.destroy();
          })
          .unsubscribe();
      }
    }
  }

  ngOnDestroy() {
    this.launchDialogService.clear(LAUNCH_CALLER.PLACE_ORDER_SPINNER);
    this.subscription.unsubscribe();
  }
}
