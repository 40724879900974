import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { KnBrResponsiveCategoryNavigationComponent } from 'src/app/customer-libs/kn-br-navigation/components/kn-br-category-navigation/kn-br-responsive-category-navigation/kn-br-responsive-category-navigation.component';

@Component({
  selector: 'knbr-kn-br-responsive-category-navigation',
  templateUrl: './sloan-responsive-category-navigation.component.html',
})
export class SloanResponsiveCategoryNavigationComponent extends KnBrResponsiveCategoryNavigationComponent {
  constructor(protected modalService: NgbModal) {
    super(modalService);
  }
}
