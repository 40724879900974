<div class="customersearch-list-table float-left col-lg-12 p-0 mt-3" *ngIf="factSheetData$ | async as factSheetData">
  <div
    class="customersearch-list-titles border-top float-left w-100 p-3 d-none d-sm-none d-lg-block"
    *ngIf="factSheetData?.materials && factSheetData?.materials.length"
  >
    <div class="col-md-2 padding-res-0 float-left font-text-600 pl-0 font-size">
      {{ 'factSheetMaterial.header.material' | cxTranslate }}
    </div>
    <div class="col-md-6 padding-res-0 float-left font-text-600 pl-0 font-sm-size">
      {{ 'factSheetMaterial.header.description' | cxTranslate }}
    </div>
    <div class="col-md-2 padding-res-0 float-left font-text-600 pl-0 font-size">
      {{ 'factSheetMaterial.header.netPrice' | cxTranslate }}
    </div>
    <div class="col-md-2 padding-res-0 float-left font-text-600 pl-0 font-size">
      {{ 'factSheetMaterial.header.currency' | cxTranslate }}
    </div>
  </div>

  <div
    class="
      bg-white
      customersearch-list-titles
      table-content
      border-top
      float-left
      w-100
      pt-2
      pb-2
      pl-3
      pr-3
      font-weight-normal
      d-flex
      align-items-center
    "
    *ngFor="let materials of factSheetData?.materials"
  >
    <div class="col-md-2 float-left title-wrap pl-0 font-size color-font">
      <span class="res-titles"> {{ 'factSheetMaterial.header.material' | cxTranslate }}</span>
      {{ materials?.currency }}
    </div>
    <div class="col-md-6 float-left title-wrap pl-0 font-sm-size color-font">
      <span class="res-titles"> {{ 'factSheetMaterial.header.description' | cxTranslate }}</span>
      {{ materials?.description }}
    </div>
    <div class="col-md-2 float-left title-wrap pl-0 font-size color-font">
      <span class="res-titles"> {{ 'factSheetMaterial.header.netPrice' | cxTranslate }}</span>
      {{ materials?.material }}
    </div>
    <div class="col-md-2 float-left title-wrap pl-0 font-size color-font">
      <span class="res-titles"> {{ 'factSheetMaterial.header.currency' | cxTranslate }}</span>
      {{ materials?.netPrice }}
    </div>
  </div>
</div>
