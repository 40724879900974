<ng-container *ngIf="technicalDocumentsList$ | async as technicalDocumentsList">
  <div class="container p-0">
    <h2>{{ 'productDetails.specification' | cxTranslate }}</h2>
    <table>
      <tr class="col-12 d-flex flex-wrap float-left" *ngFor="let feature of technicalDocumentsList">
        <td class="col-12 col-lg-4 col-md-4 col-sm-4 float-left text-capitalize">{{ feature.name }}</td>
        <td class="col-12 col-lg-8 col-md-8 col-sm-8 float-left">
          <ul [ngClass]="feature?.featureValues.length > 1 ? 'show-bulleted-list' : ''">
            <li *ngFor="let featureValue of feature?.featureValues">
              <div [innerHtml]="featureValue?.value"></div>
            </li>
          </ul>
        </td>
      </tr>
    </table>
  </div>
</ng-container>
