import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SloanOrderDetailsOverviewModule } from './sloan-order-details-overview/sloan-order-details-overview.module';
import { SloanOrderDetailsItemsModule } from './sloan-order-details-items/sloan-order-details-items.module';
import { SloanOrderDetailsActionsModule } from './sloan-order-details-actions/sloan-order-details-actions.module';
import { SloanOrderReturnModule } from './sloan-order-return/sloan-order-return.module';

@NgModule({
  imports: [
    CommonModule,
    SloanOrderDetailsOverviewModule,
    SloanOrderDetailsItemsModule,
    SloanOrderDetailsActionsModule,
    SloanOrderReturnModule],
})
export class SloanOrderDetailModule {}
