import { RoutingService, GlobalMessageService } from '@spartacus/core';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { KnBrOrderHistoryService } from 'src/feature-libs/kn-br-order/core/facade/kn-br-order-history.service';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';
import { SloanCommonService } from 'src/app/services/sloan/sloan-common.service';
import { KnBrOrderDetailsActionsComponent } from 'src/feature-libs/kn-br-order/components/kn-br-customer360/kn-br-order-detail/kn-br-order-details-actions/kn-br-order-details-actions.component';
import { OrderDetailsService } from '@spartacus/order/components';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  KnBrEditOrder,
  KnBrOrderHistoryActionTypes,
} from 'src/feature-libs/kn-br-order/core/store/actions/kn-br-order-history.action';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

@Component({
  selector: 'knbr-kn-br-order-details-actions',
  templateUrl: './sloan-order-details-actions.component.html',
  styleUrls: ['./sloan-order-details-actions.component.scss'],
})
export class SloanOrderDetailsActionsComponent extends KnBrOrderDetailsActionsComponent implements OnInit, OnDestroy {
  orderActions$: Observable<string[]> = this.sloanCommonService.loadOrderActionsList$();
  constructor(
    protected orderDetailsService: OrderDetailsService,
    protected knBrOrderHistoryService: KnBrOrderHistoryService,
    protected modalService: NgbModal,
    protected knBrCommonService: KnBrCommonService,
    protected routingService: RoutingService,
    protected messageService: GlobalMessageService,
    protected sloanCommonService: SloanCommonService,
    protected store: Store,
    protected actions: Actions
  ) {
    super(
      orderDetailsService,
      knBrOrderHistoryService,
      modalService,
      knBrCommonService,
      routingService,
      messageService
    );
  }

  ngOnInit(): void {
    this.order$ = this.orderDetailsService.getOrderDetails();
    this.subscription.add(
      this.order$.subscribe((res) => {
        this.orderInfo = res;
        if (res.code) {
          const address = res?.soldTo?.shipToPartners[0];
          const shippingAddress = {
            firstName: address?.shipToName,
            line1: address?.street,
            line2: address?.houseNumber,
            town: address?.city,
            fax: address?.fax || '',
            isDisposable: true,
            region: {
              isocode: address?.region,
              isocodeShort: address?.region || address?.country,
            },
            postalCode: address?.postalCode || '',
            phone: address?.phone || '',
            email: res?.placedBy || '',
            country: {
              isocode: address?.country,
              isocodeShort: address?.country,
            },
            bldg: address?.shipToNumber || '',
            defaultAddress: address?.defaultAddress || true,
            visibleInAddressBook: true,
            department: address?.department,
          };
          this.sloanCommonService.editOrderResponse$.next({
            ...res,
            shippingAddress: shippingAddress,
            deliveryMode: res?.shippingMethodCode || '',
            shipComplete: res?.shipComplete || false,
            additionalAccountNumber: res.additionalAccountNumber || '',
            headerComments: res?.headerComments || '',
          });
          this.sloanCommonService.getOrderActionsList(res?.code, res?.status);
        }
      })
    );
    this.subscription.add(
      this.knBrOrderHistoryService.isEditOrderable$().subscribe((res) => {
        this.isEditable = res;
      })
    );
  }

  saveEditOrder(): void {
    let payload;
    this.sloanCommonService.editOrderResponse$.subscribe((res) => {
      payload = {
        orderCode: res?.code,
        editOrderRequest: {
          poNumber: res?.poNumber || res?.purchaseOrderNumber,
          shipComplete: res?.shipComplete,
          deliveryMode: res?.deliveryMode,
          additionalAccountNumber: res?.additionalAccountNumber,
          shippingAddress: res?.shippingAddress,
          addRequestEntryInputs: res?.addRequestEntryInputs || [],
          editRequestEntryInputs: res?.editRequestEntryInputs || [],
          headerComments: res?.headerComments || '',
        },
      };
    });
    this.store.dispatch(new KnBrEditOrder(payload));
    this.actions.pipe(ofType(KnBrOrderHistoryActionTypes.KnBrEditOrderSuccessAction)).subscribe((res) => {
      if (res) {
        this.sloanCommonService.editOrderResponse$.next(null);
        this.cancelEditOrder();
      }
    });
  }

  returnOrder(type) {
    this.routingService.go({
      cxRoute: 'orderReturn',
      params: { code: this.orderInfo.code, returnType: type },
    });
  }

  cancelEditOrder() {
    super.cancelEditOrder();
    this.sloanCommonService.editOrderResponse$.next(null);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.sloanCommonService.editOrderResponse$.next(null);
  }
}
