import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { FormErrorsModule, SpinnerModule } from '@spartacus/storefront';

import { KnBrQuoteHeaderComponent } from './kn-br-quote-header.component';
import { KnBrQuoteRejectCommentsModule } from './kn-br-quote-reject-comments/kn-br-quote-reject-comments.module';

@NgModule({
  declarations: [KnBrQuoteHeaderComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    UrlModule,
    I18nModule,
    FormErrorsModule,
    NgSelectModule,
    NgbModule,
    KnBrQuoteRejectCommentsModule,
    ConfigModule.withConfig({
      cmsComponents: {
        KnBrQuoteHeaderComponent: {
          component: KnBrQuoteHeaderComponent,
        },
        KnBrQuoteHeaderComponentReadOnly: {
          component: KnBrQuoteHeaderComponent,
        },
      },
    } as CmsConfig),
    SpinnerModule,
  ],
  exports: [KnBrQuoteHeaderComponent],
})
export class KnBrQuoteHeaderModule {}
