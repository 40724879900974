import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { CmsService, ContentSlotData } from '@spartacus/core';
import { Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { KnBrDraftOrderCommentsPopupComponent } from '../../kn-br-draft-order-comments-popup/kn-br-draft-order-comments-popup.component';
import { KnBrQuoteReferenceComponent } from '../kn-br-quote-reference/kn-br-quote-reference.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserAccountService } from '@spartacus/user/account/core';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';
import { KnBrDateHelper } from 'src/app/shared/kn-br-date.helper';
import { KnBrCartContextService } from 'src/feature-libs/kn-br-cart/base/core/facade/kn-br-cart-context.service';
import { KnBrDraftOrdersService } from 'src/feature-libs/kn-br-cart/draft-order/core/facade/kn-br-draft-orders.service';
import { KnBrQuoteReferenceService } from 'src/feature-libs/kn-br-cart/quote/core/facade/kn-br-quote-reference.service';
import { KnBrDraftOrder, KnBrDraftOrderEntry } from 'src/feature-libs/kn-br-cart/draft-order/root/models/kn-br-draft-order.model';
import { UserAccountFacade } from '@spartacus/user/account/root';

@Component({
  selector: 'kn-br-draft-order-item',
  templateUrl: './kn-br-draft-order-item.component.html',
  styleUrls: ['./kn-br-draft-order-item.component.scss'],
  providers: [KnBrDateHelper],
})
export class KnBrDraftOrderItemComponent implements OnInit, OnDestroy {
  @Input() entry: KnBrDraftOrderEntry;
  reqShipDate = new UntypedFormControl('');
  quantity = new UntypedFormControl('');
  priceAdjusted = new UntypedFormControl('');
  selectedProductsCodes: number[];
  subscriptionSelectedProduct: Subscription;
  dateFormat$: Observable<any> = this.currentUserService.get().pipe(
    filter((user: any) => user && !!user.dateFormat),
    map((user: any) => user.dateFormat)
  );
  minDate = undefined;
  components: ContentSlotData;
  constructor(
    protected currentUserService: UserAccountFacade,
    protected knBrCommonService: KnBrCommonService,
    protected draftOrdersService: KnBrDraftOrdersService,
    protected contextService: KnBrCartContextService,
    protected modalService: NgbModal,
    protected knBrQuoteReferenceService: KnBrQuoteReferenceService,
    protected cdr: ChangeDetectorRef,
    protected cmsService: CmsService,
    protected activeCartService: ActiveCartFacade,
    protected knBrDateHelper: KnBrDateHelper
  ) {
    this.subscriptionSelectedProduct = this.knBrCommonService.loadCartEntry().subscribe((selectedProductsCodes) => {
      this.selectedProductsCodes = selectedProductsCodes;
    });
  }

  ngOnInit(): void {
    if (this.entry) {
      this.reqShipDate.setValue(this.knBrDateHelper.getDate(this.entry.requestedShipDate as string));
      this.quantity.setValue(this.entry.quantity);
      this.priceAdjusted.setValue(this.entry.knbrTotalPrice?.priceAdjusted?.value?.toFixed(2));
      this.reqShipDate.enable();
      this.quantity.enable();
      this.priceAdjusted.enable();
    }
    this.disableBackdate();
  }

  disableBackdate() {
    const current = new Date();
    this.minDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate(),
    };
  }

  update(event) {
    if (
      (this.reqShipDate.valid && this.reqShipDate.dirty) ||
      (this.quantity.valid && this.quantity?.dirty) ||
      (this.priceAdjusted.valid && this.priceAdjusted?.dirty)
    ) {
      this.contextService.get$
        .subscribe((value) => {
          const entryObj: any = {
            quantity: this.knBrCommonService.getQuantityBasedOnMoq(
              this.quantity.value,
              this.entry.minimumOrderQuantity
            ),
            priceAdjusted: this.priceAdjusted.value,
            requestedShipDate: '',
            entryNumber: this.entry.entryNumber,
            cartCode: value,
          };
          if (this.reqShipDate.value) {
            entryObj.requestedShipDate = this.knBrDateHelper.formatDate(this.reqShipDate.value);
          }
          this.draftOrdersService.updateEntry(entryObj);
          this.reqShipDate.disable();
          this.quantity.disable();
          this.priceAdjusted.disable();
        })
        .unsubscribe();
    }
  }

  isChecked(code: any): boolean {
    if (this.selectedProductsCodes) {
      return this.selectedProductsCodes.indexOf(code) > -1;
    }
    return false;
  }

  changeCheckBox(event) {
    event.stopPropagation();
    const selectedProductCodes = event.currentTarget.checked ? this.addProductCode() : this.removeProductCode();
    this.knBrCommonService.setCartEntry(selectedProductCodes);
  }

  private addProductCode() {
    if (this.selectedProductsCodes && this.selectedProductsCodes.length) {
      this.selectedProductsCodes.push(this.entry.entryNumber);
    } else {
      this.selectedProductsCodes = [this.entry.entryNumber];
    }
    return this.selectedProductsCodes;
  }
  private removeProductCode() {
    this.selectedProductsCodes.splice(this.selectedProductsCodes.indexOf(this.entry.entryNumber), 1);
    return this.selectedProductsCodes;
  }

  openComment(entry?: KnBrDraftOrderEntry) {
    let modalInstance: any;
    const modalRef = this.modalService.open(KnBrDraftOrderCommentsPopupComponent, {
      centered: true,
      size: 'lg',
    });
    modalInstance = modalRef.componentInstance;
    modalInstance.entryNumber = entry.entryNumber;
    modalInstance.editable = this.isDisabled();
  }

  openQuoteReference(entry?: KnBrDraftOrderEntry) {
    if (this.isEditable()) {
      this.knBrQuoteReferenceService.get(entry.entryNumber);
      let modalInstance: any;
      const modalRef = this.modalService.open(KnBrQuoteReferenceComponent, {
        centered: true,
        size: 'lg',
      });
      modalInstance = modalRef.componentInstance;
      modalInstance.cartEntry = entry;
    }
  }

  deleteQuoteReference(entry?: KnBrDraftOrderEntry) {
    this.knBrQuoteReferenceService.delete(entry.entryNumber);
  }

  isDisabled() {
    this.cdr.markForCheck();
    this.cmsService
      .getContentSlot('KnBrDraftOrderItemsSlot')
      .subscribe((value) => (this.components = value))
      .unsubscribe();

    if (
      (this.components &&
        this.components.components &&
        this.components.components.length &&
        this.components.components[0].flexType === 'KnBrDraftOrderItemsComponentReadOnly') ||
      !this.isEditable()
    ) {
      this.reqShipDate.disable();
      this.quantity.disable();
      this.priceAdjusted.disable();
      return true;
    }
    return false;
  }

  isEditable() {
    let isEditable = true;
    this.activeCartService
      .getActive()
      .subscribe((cart: KnBrDraftOrder) => (isEditable = cart.editable))
      .unsubscribe();
    return isEditable;
  }

  ngOnDestroy(): void {
    if (this.subscriptionSelectedProduct) {
      this.subscriptionSelectedProduct.unsubscribe();
    }
  }
}
