import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { SloanFactSheetData } from 'src/app/models/sloan/sloan-fact-sheet.model';
import { SloanFactSheetService } from 'src/app/store/sloan/sloan-fact-sheet/sloan-fact-sheet.service';

@Component({
  selector: 'knbr-sloan-fact-sheet-material',
  templateUrl: './sloan-fact-sheet-material.component.html',
  styleUrls: ['./sloan-fact-sheet-material.component.scss'],
})
export class SloanFactSheetMaterialComponent implements OnInit {
  factSheetData$: Observable<SloanFactSheetData> = this.factSheetService.factSheetData$;

  constructor(protected factSheetService: SloanFactSheetService) {}

  ngOnInit(): void {}
}
