import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { FormErrorsModule, SpinnerModule } from '@spartacus/storefront';

import { KnBrQuoteItemModule } from './kn-br-quote-item/kn-br-quote-item.module';
import { KnBrQuoteItemsComponent } from './kn-br-quote-items.component';
import { KnBrQuotePartialItemModule } from './kn-br-quote-item/kn-br-quote-partial-item/kn-br-quote-partial-item.module';

@NgModule({
  declarations: [KnBrQuoteItemsComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    UrlModule,
    I18nModule,
    FormErrorsModule,
    NgSelectModule,
    KnBrQuoteItemModule,
    ConfigModule.withConfig({
      cmsComponents: {
        KnBrQuoteItemsComponent: {
          component: KnBrQuoteItemsComponent,
        },
        KnBrQuoteItemsComponentReadOnly: {
          component: KnBrQuoteItemsComponent,
        },
      },
    } as CmsConfig),
    SpinnerModule,
  ],
  exports: [KnBrQuoteItemsComponent],
})
export class KnBrQuoteItemsModule {}
