import { TitleCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Optional } from '@angular/core';
import { ActiveCartFacade, DeliveryMode, PaymentType } from '@spartacus/cart/base/root';
import { CheckoutPaymentTypeFacade } from '@spartacus/checkout/b2b/root';
import {
  CheckoutDeliveryAddressFacade,
  CheckoutDeliveryModesFacade,
  CheckoutPaymentFacade,
  CheckoutQueryFacade,
  CheckoutStep,
  CheckoutStepType
} from '@spartacus/checkout/base/root';
import { FeatureConfigService, PaymentDetails, TranslationService } from '@spartacus/core';
import { Card } from '@spartacus/storefront';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { SloanAddress } from 'src/app/models/sloan/sloan-order.model';
import { KnBrCheckoutReviewSubmitComponent } from 'src/feature-libs/kn-br-checkout/base/components/kn-br-checkout-review-submit/kn-br-checkout-review-submit.component';
import { KnBrCheckoutStepService } from 'src/feature-libs/kn-br-checkout/base/components/services/kn-br-checkout-step.service';


@Component({
  selector: 'cx-review-submit',
  templateUrl: './sloan-review-submit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SloanReviewSubmitComponent extends KnBrCheckoutReviewSubmitComponent {
  checkoutStepTypePaymentType = CheckoutStepType.PAYMENT_TYPE;
  constructor(
    protected checkoutDeliveryAddressFacade: CheckoutDeliveryAddressFacade,
    protected checkoutPaymentFacade: CheckoutPaymentFacade,
    protected checkoutPaymentTypeFacade: CheckoutPaymentTypeFacade,
    protected activeCartFacade: ActiveCartFacade,
    protected translationService: TranslationService,
    protected checkoutStepService: KnBrCheckoutStepService,
    protected checkoutDeliveryModesFacade: CheckoutDeliveryModesFacade,
    protected titlecasePipe?: TitleCasePipe,
    @Optional() protected checkoutQueryFacade?: CheckoutQueryFacade,
    @Optional() protected featureConfigService?: FeatureConfigService
  ) {
    super(
      checkoutDeliveryAddressFacade,
      checkoutPaymentFacade,
      activeCartFacade,
      translationService,
      checkoutStepService,
      checkoutDeliveryModesFacade
    );
  }

  protected getCheckoutDeliverySteps(): Array<CheckoutStepType | string> {
    return [CheckoutStepType.DELIVERY_ADDRESS, CheckoutStepType.DELIVERY_MODE];
  }

  protected getCheckoutPaymentSteps(): Array<CheckoutStepType | string> {
    return [CheckoutStepType.PAYMENT_DETAILS, CheckoutStepType.PAYMENT_TYPE];
  }
  get poNumber$(): Observable<string | undefined> {
    return this.checkoutPaymentTypeFacade.getPurchaseOrderNumberState().pipe(
      filter((state) => !state.loading && !state.error),
      map((state) => state.data)
    );
  }

  getPaymentType(name: string) {
    return `paymentTypes.paymentType_${name}`;
  }

  paymentDetails$: Observable<PaymentDetails | undefined> = this.checkoutPaymentFacade.getPaymentDetailsState().pipe(
    filter((state) => !state.loading && !state.error),
    map((state) => state.data)
  );

  paymentTypes$: Observable<PaymentType[]> = this.checkoutPaymentTypeFacade.getPaymentTypesState().pipe(
    filter((state) => !state.loading && !state.error),
    map((state) => state.data)
  );

  getPaymentTypeCard(paymentType: PaymentType): Observable<Card> {
    return combineLatest([
      this.translationService.translate('checkoutB2B.progress.methodOfPayment'),
      this.translationService.translate('paymentTypes.paymentType'),
    ]).pipe(
      map(([textTitle, paymentType]) => {
        return {
          title: textTitle,
          textBold: paymentType,
        };
      })
    );
  }

  getPoNumberCard(poNumber?: string | null): Observable<Card> {
    return combineLatest([
      this.translationService.translate('paymentTypes.poNumber'),
      this.translationService.translate('checkoutB2B.noPoNumber'),
    ]).pipe(
      map(([textTitle, noneTextTitle]) => {
        return {
          title: textTitle,
          textBold: poNumber ? poNumber : noneTextTitle,
        };
      })
    );
  }

  getPaymentMethodCard(paymentMethod) {
    return combineLatest([
      this.translationService.translate('paymentTypes.title'),
      this.translationService.translate(this.getPaymentType(paymentMethod?.code)),
    ]).pipe(
      map(([textTitle, pm]) => {
        return {
          title: textTitle,
          textBold: pm,
        };
      })
    );
  }

  getDeliveryModeCard(deliveryMode: DeliveryMode, cart?: any): Observable<Card> {
    return combineLatest([
      this.translationService.translate('paymentTypes.deliveryDetails'),
      this.translationService.translate('paymentTypes.deliveryMode'),
      this.translationService.translate('paymentTypes.shippingAccountNumber'),
      this.translationService.translate('paymentTypes.shippingNotes'),
    ]).pipe(
      map(([textTitle, shippingMethod, shippingAccountNumber, shippingNotes]) => {
        const deliveryDetailList = [{ title: shippingMethod, text: [deliveryMode?.name] }];
        if (cart && cart.additionalAccountNumber) {
          deliveryDetailList.push({ title: shippingAccountNumber, text: [cart?.additionalAccountNumber] });
        }
        if (cart && cart.shippingNotes) {
          deliveryDetailList.push({ title: shippingNotes, text: [cart?.shippingNotes] });
        }
        return {
          title: textTitle,
          text: [],
          paragraphs: deliveryDetailList,
        };
      })
    );
  }

  getShippingAddressCard(deliveryAddress: SloanAddress, countryName?: string): Observable<Card> {
    return combineLatest([this.translationService.translate('addressCard.shipTo')]).pipe(
      map(([textTitle]) => {
        if (!countryName) {
          countryName = deliveryAddress?.country?.isocode;
        }

        let region = '';
        if (deliveryAddress && deliveryAddress.region && deliveryAddress.region.isocode) {
          region = deliveryAddress.region.isocode;
        }

        return {
          title: textTitle,
          textBold: deliveryAddress.firstName + (deliveryAddress.lastName ? ' ' + deliveryAddress.lastName : ''),
          text: [
            (deliveryAddress.line2 ? deliveryAddress.line2 + ', ' : '') + deliveryAddress.line1,
            deliveryAddress.department,
            deliveryAddress.town + ', ' + region,
            countryName + ', ' + deliveryAddress.postalCode,
            deliveryAddress.phone,
          ],
        };
      })
    );
  }
  deliverySteps(steps: CheckoutStep[]): CheckoutStep[] {
    return steps.filter((step) => this.getCheckoutDeliverySteps().includes(step.type[0]));
  }

  paymentSteps(steps: CheckoutStep[]): CheckoutStep[] {
    return steps.filter((step) => this.getCheckoutPaymentSteps().includes(step.type[0]));
  }
}
