import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { KnBrCustomerContextService } from 'src/app/store/kn-br-customer-context/kn-br-customer-context.service';
import { KN_BR_CUSTOMER_CURRENT } from '../../../../constants/api.endpoints.constant';
import { SLOAN_FACT_SHEET_DETAILS } from 'src/constants/sloan/sloan-api.endpoints.constant';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SloanFactSheetAdapter {
  customerNumber: string;
  constructor(
    private endPointService: OccEndpointsService,
    private httpClient: HttpClient,
    protected knBrCustomerContextService: KnBrCustomerContextService
  ) {
    this.knBrCustomerContextService.getActiveCustomerNumber().subscribe((response) => {
      this.customerNumber = response ? response : null;
    });
  }

  getFactSheetDetails() {
    const CUSTOMER_NUMBER = this.customerNumber ? this.customerNumber : KN_BR_CUSTOMER_CURRENT;
    return this.httpClient
      .get(this.endPointService.buildUrl(SLOAN_FACT_SHEET_DETAILS(CUSTOMER_NUMBER)))
      .pipe(shareReplay());
  }
}
