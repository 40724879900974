import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DeliveryMode } from '@spartacus/cart/base/root';
import { CheckoutDeliveryAddressAdapter, DELIVERY_MODE_NORMALIZER } from '@spartacus/checkout/base/core';
import {
  Address,
  ADDRESS_NORMALIZER,
  ADDRESS_SERIALIZER,
  ConverterService,
  Occ,
  OccEndpointsService,
} from '@spartacus/core';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { KnBrCustomerContextService } from '../../../../../app/store/kn-br-customer-context/kn-br-customer-context.service';

@Injectable()
export class KnBrOccCheckoutDeliveryAdapter implements CheckoutDeliveryAddressAdapter {
  customerId: string;
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converter: ConverterService,
    protected customerContextService: KnBrCustomerContextService
  ) {
    this.customerContextService.get$.subscribe((response) => {
      this.customerId = response && response.customerNumber ? response.customerNumber : null;
    });
  }

  protected getCartEndpoint(userId: string): string {
    const cartEndpoint = 'knbr/customer/' + this.customerId + '/carts/';

    return this.occEndpoints.buildUrl(cartEndpoint);
  }

  public createAddress(userId: string, cartId: string, address: Address): Observable<Address> {
    address = this.converter.convert(address, ADDRESS_SERIALIZER);

    return this.http
      .post<Occ.Address>(this.getCartEndpoint(userId) + cartId + '/addresses/delivery', address, {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
      })
      .pipe(this.converter.pipeable(ADDRESS_NORMALIZER));
  }

  public setAddress(userId: string, cartId: string, addressId: string): Observable<any> {
    return this.http.put(
      this.getCartEndpoint(userId) + cartId + '/addresses/delivery',
      {},
      {
        params: { addressId },
      }
    );
  }

  public setMode(userId: string, cartId: string, deliveryModeId: string): Observable<any> {
    return this.http.put(
      this.getCartEndpoint(userId) + cartId + '/deliverymode',
      {},
      {
        params: { deliveryModeId },
      }
    );
  }

  public getMode(userId: string, cartId: string): Observable<any> {
    return this.http
      .get(this.getCartEndpoint(userId) + cartId + '/deliverymode')
      .pipe(this.converter.pipeable(DELIVERY_MODE_NORMALIZER));
  }

  public getSupportedModes(userId: string, cartId: string): Observable<DeliveryMode[]> {
    return this.http
      .get<Occ.DeliveryModeList>(this.getCartEndpoint(userId) + cartId + '/deliverymodes')
      .pipe(pluck('deliveryModes'), this.converter.pipeableMany(DELIVERY_MODE_NORMALIZER));
  }
  clearCheckoutDeliveryAddress(userId: string, cartId: string): Observable<unknown> {
    return;
  }
}
