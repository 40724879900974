import { ProductService, RoutingService } from '@spartacus/core';
import { CurrentProductService } from '@spartacus/storefront';
import { Component, OnDestroy } from '@angular/core';
import { KnBrProductSummaryComponent } from 'src/feature-libs/kn-br-product/components/kn-br-product-detail/kn-br-product-summary/kn-br-product-summary.component';

@Component({
  selector: 'kn-br-product-summary',
  templateUrl: './sloan-product-summary.component.html',
  styleUrls: ['./sloan-product-summary.component.scss'],
})
export class SloanProductSummaryComponent extends KnBrProductSummaryComponent implements OnDestroy {
  constructor(
    protected service: CurrentProductService,
    protected productService: ProductService,
    protected routingService: RoutingService
  ) {
    super(service, productService, routingService);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
