import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { KN_BR_QUOTE_REFERENCE_FEATURE } from './kn-br-quote-reference.state';
import { knBrQuoteReferenceReducer } from 'src/feature-libs/kn-br-cart/quote/core/store/reducers/kn-br-quote-reference.reducer';
import { KnBrQuoteReferenceEffects } from 'src/feature-libs/kn-br-cart/quote/core/store/effects/kn-br-quote-reference.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(KN_BR_QUOTE_REFERENCE_FEATURE, knBrQuoteReferenceReducer),
    EffectsModule.forFeature([KnBrQuoteReferenceEffects]),
  ],
})
export class KnBrQuoteReferenceStateModule { }
