<section class="col-md-12 p-0 login-wrapper d-flex justify-content-center align-items-center">
  <div class="container fluid p-0">
    <form (ngSubmit)="checkIsValidUser()" [formGroup]="form">
      <div class="col-md-12 float-left knbr-login-forms mt-4 d-flex justify-content-center p-0">
        <div class="col-lg-5 col-sm-6 login-forms p-4">
          <div class="form-group mt-3">
            <!-- <i class="fas fa-user fa-lg" aria-hidden="true"></i>-->
            <cx-icon [type]="iconTypes.USER"></cx-icon>
            <input type="text" required class="form-control w-100" formControlName="userId"
              placeholder="{{ 'loginForm.emailAddress.placeholder' | cxTranslate }}" type="email" />
            <span class="highlight"></span>
            <!-- <label>{{ 'loginForm.emailAddress.label' | cxTranslate }}</label> -->
            <cx-form-errors [control]="form.get('userId')"></cx-form-errors>
          </div>

          <div class="form-group m-0 mb-4">
            <i _ngcontent-lsq-c299="" aria-hidden="true" class="fas fa-lock pr-1 fa-lg"></i>
            <input type="text" required class="form-control w-100"
              placeholder="{{ 'loginForm.password.placeholder' | cxTranslate }}" formControlName="password"
              type="password" />
            <span class="highlight"></span>
            <!-- <label>{{ 'loginForm.password.label' | cxTranslate }}</label> -->
            <cx-form-errors [control]="form.get('password')"></cx-form-errors>
          </div>

          <div class="login-btns col-lg-6 col-md-12 pl-lg-0 pr-lg-3 p-md-0 float-left">
            <button type="submit" class="btn btn-primary btn-lg col-md-12 m-res-0">
              {{ 'loginForm.signIn' | cxTranslate }}
            </button>
          </div>
          <div class="login-btns col-lg-6 col-md-12 pr-lg-0 pl-lg-3 p-md-0 mt-3 mt-lg-0 float-left">
            <button type="button" class="btn btn-outline-primary btn-lg col-md-12 m-res-0"
              [routerLink]="{ cxRoute: 'register' } | cxUrl">
              {{ 'loginForm.signUp' | cxTranslate }}
            </button>
          </div>

          <div class="remember-me forget-pass col-md-12 float-left mt-3 d-flex justify-content-center p-0">
            <a [routerLink]="{ cxRoute: 'forgotPassword' } | cxUrl" class="font-color-knbr-secondary">
              <i class="fas fa-lock pr-1" aria-hidden="true"></i>{{ 'loginForm.forgotPassword' | cxTranslate }}</a>
          </div>
        </div>
      </div>
    </form>
  </div>
</section>
