import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthGuard, ConfigModule, RoutingConfig } from '@spartacus/core';
import { CmsPageGuard, PAGE_LAYOUT_HANDLER, PageLayoutComponent } from '@spartacus/storefront';

import { KnBrProductQuickEntryModule } from './kn-br-product-quick-entry/kn-br-product-quick-entry.module';
import { KnBrQuoteAttachmentLinkModule } from './kn-br-quote-attachment-link/kn-br-quote-attachment-link.module';
import { KnBrQuoteCommentsPopupModule } from './kn-br-quote-comments-popup/kn-br-quote-comments-popup.module';
import { KnBrQuoteDeleteItemsModule } from './kn-br-quote-delete-items/kn-br-quote-delete-items.module';
import { KnBrQuoteDownloadLinkModule } from './kn-br-quote-download-link/kn-br-quote-download-link.module';
import { KnBrQuoteHeaderModule } from './kn-br-quote-header/kn-br-quote-header.module';
import { KnBrQuoteItemsModule } from './kn-br-quote-items/kn-br-quote-items.module';
import { KnBrQuotePageLayoutHandler } from './kn-br-quote-page-layout.handler';
import { KnBrQuoteSaveModule } from './kn-br-quote-save/kn-br-quote-save.module';
import { KnBrCustomerContextGuard } from 'src/app/shared/guards/kn-br-customer-context.guard';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    KnBrQuoteHeaderModule,
    KnBrQuoteItemsModule,
    KnBrQuoteSaveModule,
    KnBrQuoteDeleteItemsModule,
    KnBrProductQuickEntryModule,
    KnBrQuoteCommentsPopupModule,
    RouterModule.forChild([
      {
        data: { pageLabel: '/quote-detail', cxRoute: 'quoteDetails' },
        path: null,
        canActivate: [AuthGuard, CmsPageGuard, KnBrCustomerContextGuard],
        component: PageLayoutComponent,
      },
    ]),
    ConfigModule.withConfig({
      routing: {
        routes: {
          quoteDetails: {
            paths: ['quote-detail/:code'],
          },
        },
      },
    } as RoutingConfig),
    KnBrQuoteDownloadLinkModule,
    KnBrQuoteAttachmentLinkModule,
  ],
  providers: [
    {
      provide: PAGE_LAYOUT_HANDLER,
      useExisting: KnBrQuotePageLayoutHandler,
      multi: true,
    },
  ],
})
export class KnBrQuoteDetailModule {}
