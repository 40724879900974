import { TranslationChunksConfig, TranslationResources } from '@spartacus/core';
import { en } from './en';

export const knbrQuoteTranslations: TranslationResources = { en };
export const knbrQuoteTranslationChunksConfig: TranslationChunksConfig = {
  knbrQuote: [
    'quoteCommon',
    'quoteSearchForm',
    'quoteResult',
    'actionBar',
    'quoteDetailHeader',
    'quoteItems',
    'quotePartialItems',
    'addToQuoteMessage',
    'quoteReference',
    'button',
  ],
};
