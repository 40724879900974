import { CommonModule, TitleCasePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { CardModule, IconModule, PromotionsModule } from '@spartacus/storefront';
import { SloanCartSharedModule } from '../../sloan-cart/sloan-cart-shared/sloan-cart-shared.module';
import { SloanReviewSubmitComponent } from './sloan-review-submit.component';

@NgModule({
  imports: [
    CommonModule,
    CardModule,
    SloanCartSharedModule,
    I18nModule,
    UrlModule,
    RouterModule,
    PromotionsModule,
    IconModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CheckoutReviewOrder: {
          component: SloanReviewSubmitComponent,
        },
      },
    } as CmsConfig),
  ],
  providers: [TitleCasePipe],
  declarations: [SloanReviewSubmitComponent],
  exports: [SloanReviewSubmitComponent],
})
export class SloanReviewSubmitModule { }
