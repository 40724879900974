export const checkoutOrderConfirmation = {
  checkoutOrderConfirmation: {
    confirmationOfOrder: 'Confirmation of Order:',
    thankYou: 'Thank you for your order!',
    invoiceHasBeenSentByEmail: 'An invoice has been sent by email. You should receive it soon.',
    orderItems: 'Order Items',
    orderPlacedSuccessfully: 'Order placed successfully',
    createAccount: 'Create an account?',
    createAccountForNext: 'Create an account for <{{email}}> for a faster checkout on your next visit.',
    replenishmentNumber: 'Replenishment #',
    placedOn: 'Order Creation Date',
    status: 'Order Status',
    active: 'Active',
    cancelled: 'Cancelled',
    frequency: 'Frequency',
    nextOrderDate: 'Next order date',
    orderNumber: 'Order Number',
    orderTotal: 'Order Total',
    totalQuantity: 'Total Quantity',
    totalItems: 'Total Items',
    shippingMethod: 'Shipping Method',
    orderType: 'Order Type',
    jobName: 'Job Name',
    placedBy: 'Order Placed By',
    soldTo: 'Sold To',
    purchaseOrderNumber: 'Customer P.O.',
    shipComplete: 'Ship Complete',
    reqDeliveryDate: 'Requested Delivery Date',
    copyHasBeenSentToEmail: 'A copy of this page has been emailed to you at ',
    termsAndConditionsStart: 'By purchasing Sloan products you agree to be bound by the',
    termsAndConditionsLink: 'Terms of Sale',
    termsAndConditionsEnd: '. Except as otherwise provided in Section 1 of the Terms of Sale, the Terms of Sale supersede any other terms or agreements concerning the purchase of products under this Order Confirmation, including, without limitation, any purchase orders you send to Sloan.'
  },
};
