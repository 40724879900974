import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ConverterService,
  OccEndpointsService,
  PRODUCT_SEARCH_PAGE_NORMALIZER,
  ProductSearchPage,
  SearchConfig,
} from '@spartacus/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { KnBrOccProductSearchAdapter } from 'src/feature-libs/kn-br-product/core/service/kn-br-product-search.service';
import { KnBrCustomerContextService } from 'src/app/store/kn-br-customer-context/kn-br-customer-context.service';

const DEFAULT_SEARCH_CONFIG: SearchConfig = {
  pageSize: 20,
};

@Injectable()
export class SloanOccProductSearchAdapter extends KnBrOccProductSearchAdapter {
  customerId: string;
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converter: ConverterService,
    protected customerContextService: KnBrCustomerContextService
  ) {
    super(http, occEndpoints, converter, customerContextService);
  }

  search(query: string, searchConfig: SearchConfig = DEFAULT_SEARCH_CONFIG): Observable<ProductSearchPage> {
    const localSearchConfig = _.cloneDeep(searchConfig);
    localSearchConfig.pageSize = DEFAULT_SEARCH_CONFIG.pageSize;
    return this.http
      .get(this.getSearchEndpoint(query, localSearchConfig))
      .pipe(this.converter.pipeable(PRODUCT_SEARCH_PAGE_NORMALIZER));
  }
}
