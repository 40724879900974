import { Component } from '@angular/core';
import { RoutingService } from '@spartacus/core';
import { OrderDetailsService } from '@spartacus/order/components';
import { KnBrOrderAcknowledgeComponent } from 'src/feature-libs/kn-br-order/components/kn-br-customer360/kn-br-acknowledge/kn-br-order-acknowledge/kn-br-order-acknowledge.component';

@Component({
  selector: 'sloan-order-acknowledge',
  templateUrl: './sloan-order-acknowledge.component.html',
})
export class SloanOrderAcknowledgeComponent extends KnBrOrderAcknowledgeComponent {
  cancallationId: string;

  constructor(routingService: RoutingService, protected orderDetailsService: OrderDetailsService) {
    super(routingService, orderDetailsService);
    this.orderDetailsService.getOrderDetails().subscribe((res: any) => {
      this.cancallationId = res?.sapOrderNumber;
    });
  }
}
