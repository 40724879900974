export const cartDetails = {
  id: 'ID',
  proceedToCheckout: 'Proceed to Checkout',
  cartName: 'Cart #{{code}}',
};
export const cartItems = {
  id: 'Id',
  description: 'Item',
  item: 'Item',
  itemPrice: 'Net Price',
  quantity: 'Qty',
  quantityTitle: 'The quantity represents the total number of this item in your cart.',
  total: 'Total',
  cartTotal: 'Cart total ({{count}} item)',
  cartTotal_plural: 'Cart total ({{count}} items)',
  plant: 'Plant',
  requestedShipDate: 'Requested Ship Date',
  estimatedShipDate: 'Estimated Ship Date',
};
export const voucher = {
  coupon: 'Have a coupon?',
  coupon_plural: 'Coupon codes',
  apply: 'Apply',
  placeholder: 'Promo Code',
  applyVoucherSuccess: '{{voucherCode}} has been applied.',
  removeVoucherSuccess: '{{voucherCode}} has been removed.',
  anchorLabel: 'Enter or remove your coupon code',
  vouchersApplied: 'Applied Coupons',
  availableCoupons: 'Available Coupons',
  availableCouponsLabel: 'You can add these coupons to this order.',
};
export const saveForLaterItems = {
  itemTotal: 'Saved for later ({{count}} item)',
  itemTotal_plural: 'Saved for later ({{count}} items)',
  cartTitle: 'Cart',
  saveForLater: 'Save For Later',
  moveToCart: 'Move To Cart',
  stock: 'Stock',
  forceInStock: 'In Stock',
};

export const cart = {
  saveForLaterItems,
  voucher,
  cartItems,
  cartDetails,
};
