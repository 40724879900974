<ng-container *ngIf="componentData$ | async as data">
  <div class="col-md-12 color-knbr-footer p-3 float-left border-top">
    <div class="row">
      <div class="col-md-2 float-left">
        <cx-page-slot position="FooterLogo"></cx-page-slot>
      </div>
      <div class="col-md-6 pl-0 float-left d-flex align-items-center">
        <div class="footer-nav footer-copyright float-left">
          {{ data.notice }}
        </div>
        <ng-container *ngIf="node$ | async as node">
          <ng-container *ngFor="let child of node?.children">
            <ng-container *ngTemplateOutlet="nav; context: { node: child, depth: 0 }"> </ng-container>
          </ng-container>
        </ng-container>
      </div>
      <div class="col-md-4 float-left d-flex align-items-center">
        <cx-page-slot position="FooterSocialMedia"></cx-page-slot>
      </div>
    </div>
  </div>
  <ng-template #nav let-node="node" let-depth="depth">
    <div class="footer-nav float-left p-res-0">
      <ul class="list-unstyled m-0 p-0">
        <cx-generic-link
          *ngIf="node.url && (!node.children || node.children?.length === 0)"
          [url]="node.url"
          [target]="node.target"
          [class]="'w-100 float-left'"
        >
          {{ node.title }}
        </cx-generic-link>
        <ng-container *ngIf="node?.children?.length > 0">
          <li class="w-100" *ngFor="let innerNode of node?.children">
            <cx-generic-link
              *ngIf="innerNode.url"
              [url]="innerNode.url"
              [target]="innerNode.target"
              [class]="'w-100 float-left'"
            >
              {{ innerNode.title }}
            </cx-generic-link>
          </li>
        </ng-container>
      </ul>
    </div>
  </ng-template>
</ng-container>
