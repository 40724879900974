/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  CheckoutAdapter,
  CheckoutDeliveryAddressAdapter,
  CheckoutDeliveryModesAdapter,
} from '@spartacus/checkout/base/core';
import { UserAddressAdapter, provideConfig } from '@spartacus/core';
import { OrderAdapter } from '@spartacus/order/core';
import { knBrOccCheckoutConfig } from './config/kn-br-occ-checkout-config';
import { KnBrOccOrderAdapter } from '../../../kn-br-order/occ/adapters/kn-br-occ-order.adapter';
import { KnBrOccUserAddressAdapter } from './adapters/kn-br-occ-user-address.adapter';
import { KnBrOccCheckoutAdapter } from './adapters/kn-br-occ-checkout.adapter';
import { KnBrOccCheckoutDeliveryAdapter } from './adapters/kn-br-occ-checkout-delivery.adapter';

@NgModule({
  imports: [CommonModule],
  providers: [
    provideConfig(knBrOccCheckoutConfig),
    {
      provide: CheckoutAdapter,
      useClass: KnBrOccCheckoutAdapter,
    },
    {
      provide: CheckoutDeliveryAddressAdapter,
      useClass: KnBrOccCheckoutDeliveryAdapter,
    },
    {
      provide: CheckoutDeliveryModesAdapter,
      useClass: KnBrOccCheckoutDeliveryAdapter,
    },
    {
      provide: OrderAdapter,
      useClass: KnBrOccOrderAdapter,
    },
    {
      provide: UserAddressAdapter,
      useClass: KnBrOccUserAddressAdapter,
    },
  ],
})
export class KnBrCheckoutOccModule { }
