export const cart = {
  cart: {
    cartDetails: {
      id: 'Identifiant',
      proceedToCheckout: 'Passer à la caisse',
      cartName: 'Panier # {{code}}',
    },
    cartItems: {
      id: 'Identifiant',
      description: 'La description',
      item: 'Article',
      itemPrice: "Prix ​​de l'article",
      quantity: 'Qté',
      quantityTitle: 'La quantité représente le nombre total de cet article dans votre panier.',
      total: 'Total',
      cartTotal: 'Total du panier ({{count}} article)',
      cartTotal_plural: 'Total du panier ({{count}} articles)',
    },
    orderCost: {
      orderSummary: 'Récapitulatif de la commande',
      subtotal: 'Sous-total après remises:',
      shipping: 'Livraison:',
      estimatedShipping: 'Frais de livraison estimés:',
      discount: 'Tu as sauvegardé:',
      salesTax: 'Taxe de vente:',
      grossTax: 'The order total does not include tax of',
      total: 'Total:',
    },
    voucher: {
      coupon: 'Avez-vous un coupon?',
      coupon_plural: 'Codes promo',
      apply: 'Appliquer',
      placeholder: 'Code promo',
      applyVoucherSuccess: '{{voucherCode}} has been applied.',
      removeVoucherSuccess: '{{voucherCode}} has been removed.',
      anchorLabel: 'Enter or remove your coupon code',
      vouchersApplied: 'Coupons appliqués',
      availableCoupons: 'Coupons disponibles',
      availableCouponsLabel: 'Le total de la commande ne comprend pas la taxe de',
    },
    saveForLaterItems: {
      itemTotal: '{{voucherCode}} a été appliqué.',
      itemTotal_plural: '{{voucherCode}} a été supprimé.',
      cartTitle: 'Chariot',
      saveForLater: 'Garder pour plus tard',
      moveToCart: 'Passer au panier',
      stock: 'Stock',
      forceInStock: 'En stock',
    },
  },
};
