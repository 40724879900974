/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { Provider } from '@angular/core';
import {
  CheckoutDeliveryAddressFacade,
  CheckoutDeliveryModesFacade,
  CheckoutPaymentFacade,
  CheckoutQueryFacade,
} from '@spartacus/checkout/base/root';
import { KnBrCheckoutDeliveryAddressService } from './kn-br-checkout-delivery-address.service';
import { KnBrCheckoutDeliveryModesService } from './kn-br-checkout-delivery-modes.service';
import { KnBrCheckoutPaymentService } from './kn-br-checkout-payment.service';
import { KnBrCheckoutQueryService } from './kn-br-checkout-query.service';

export const knBrFacadeProviders: Provider[] = [
  KnBrCheckoutDeliveryAddressService,
  {
    provide: CheckoutDeliveryAddressFacade,
    useExisting: KnBrCheckoutDeliveryAddressService,
  },
  KnBrCheckoutDeliveryModesService,
  {
    provide: CheckoutDeliveryModesFacade,
    useExisting: KnBrCheckoutDeliveryModesService,
  },
  KnBrCheckoutPaymentService,
  {
    provide: CheckoutPaymentFacade,
    useExisting: KnBrCheckoutPaymentService,
  },
  KnBrCheckoutQueryService,
  {
    provide: CheckoutQueryFacade,
    useExisting: KnBrCheckoutQueryService,
  },
];
