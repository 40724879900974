import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SloanFactSheetEffects } from './sloan-fact-sheet.effects';
import { SloanFactSheetReducer } from './sloan-fact-sheet.reducer';
import { SLOAN_FACT_SHEET_FEATURE } from './sloan-fact-sheet.state';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(SLOAN_FACT_SHEET_FEATURE, SloanFactSheetReducer),
    EffectsModule.forFeature([SloanFactSheetEffects]),
  ],
})
export class SloanFactSheetStateModule {}
