import { KnBrCustomerService } from './../../store/kn-br-customer/kn-br-customer.service';
import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { AuthService, CmsService } from '@spartacus/core';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';

import { KnBrCustomerContextService } from '../../store/kn-br-customer-context/kn-br-customer-context.service';
import { KnBrSidebarService } from './../../services/kn-br-sidebar.service';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { CustomerData } from 'src/feature-libs/kn-br-user/kn-br-account/root/model/customer.model';

@Component({
  selector: 'kn-br-outlet',
  templateUrl: './kn-br-outlet.component.html',
  styleUrls: ['./kn-br-outlet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class KnBrOutletComponent implements OnInit, OnDestroy {
  isHeaderHidden = false;
  customerContext$: Observable<CustomerData> = this.customerContextService.get$;
  isLoggedIn$: Observable<boolean> = this.authService.isUserLoggedIn();
  redirectionDetail$: Observable<any> = this.knBrCommonService.redirectionDetail$;
  user$ = this.userService.get();
  selectedCustomer;
  subscription = new Subscription();
  readonly isLoginPage$ = this.cmsService
    .getCurrentPage()
    .pipe(
      map(
        (page) =>
          page &&
          (page.template === 'KnBrLoginPageTemplate' ||
            page.template === 'knBrQuoteSellerApproverTemplate' ||
            page.template === 'KnBrRegistrationPageTemplate')
      )
    );
  readonly isUndefinedPage$ = this.cmsService
    .getCurrentPage()
    .pipe(
      map(
        (page) =>
          !page || page.template === 'knBrQuoteSellerApproverTemplate' || page.template === 'ContentPage1Template'
      )
    );
  readonly hideBreadCrumb$ = this.cmsService
    .getCurrentPage()
    .pipe(
      map(
        (page) =>
          page &&
          (page.template === 'KnBrLandingPage2Template' ||
            page.template === 'KnBrSearchResultsListPageTemplate' ||
            page.template === 'KnBrProductListPageTemplate' ||
            page.template === 'KnBrProductGridPageTemplate' ||
            page.template === 'KnBrSearchResultsGridPageTemplate' ||
            page.template === 'KnBrProductDetailsPageTemplate')
      )
    );
  readonly isStaticPage$ = this.cmsService
    .getCurrentPage()
    .pipe(map((page) => page && page.template === 'ContentPage1Template'));
  constructor(
    private cmsService: CmsService,
    private customerContextService: KnBrCustomerContextService,
    private userService: UserAccountFacade,
    private sidebarService: KnBrSidebarService,
    private knBrCommonService: KnBrCommonService,
    private authService: AuthService,
    private customerSearchService: KnBrCustomerService
  ) { }

  removeCustomerContext() {
    this.customerContextService.delete();
  }

  ngOnInit() {
    this.subscription.add(
      this.isLoggedIn$.subscribe(
        (res) => {
          if (res) {
            this.knBrCommonService.getRedirectionDetail().subscribe((redirect) => {
              this.knBrCommonService.redirectionDetail$.next(redirect);
            });
          }
        })
    );
    this.subscription.add(
      this.user$.subscribe((user: any) => {
        if (user && user.customerId) {
          this.customerContextService.set({ customerName: user.customerName, customerNumber: user.customerId });
        }
      })
    );
    this.subscription.add(
      this.customerSearchService.list$.subscribe((customerList) => {
        if (customerList && customerList.length > 0 && this.selectedCustomer) {
          const customertosetcontext = customerList.find(
            (customer) => customer.customerNumber === this.selectedCustomer.customerNumber
          );
          if (customertosetcontext) {
            this.customerContextService.set(customertosetcontext);
          }
        }
      })
    );
    this.subscription.add(
      this.customerContextService.get$.subscribe((customerdata) => {
        this.selectedCustomer = customerdata;
        if (customerdata && !customerdata.hasOwnProperty('telephone')) {
          this.customerSearchService.search(null);
        }
      })
    );
  }

  changeMenuState() {
    this.sidebarService.toggleMenuState();
  }

  ngOnDestroy() {
    this.selectedCustomer = undefined;
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
