import { Component } from '@angular/core';
import { RoutingService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { KnBrCustomerContextService } from 'src/app/store/kn-br-customer-context/kn-br-customer-context.service';
import { KnBrCustomerService } from 'src/app/store/kn-br-customer/kn-br-customer.service';
import { CustomerData } from 'src/feature-libs/kn-br-user/kn-br-account/root/model/customer.model';

@Component({
  selector: 'knbr-customer-search-result',
  templateUrl: './kn-br-customer-search-result.component.html',
  styleUrls: ['./kn-br-customer-search-result.component.scss'],
})
export class KnBrCustomerSearchResultComponent {
  customerSearchSubscription: any;
  customerResult$: Observable<CustomerData[]> = this.customerSearchService.list$;
  isLoading$: Observable<boolean> = this.customerSearchService.loading$;
  routedFrom: string;
  copyObject: any;
  constructor(
    private customerSearchService: KnBrCustomerService,
    private routingService: RoutingService,
    private customerContextService: KnBrCustomerContextService
  ) { }
  storeInContext(customer: CustomerData) {
    this.customerContextService.set(customer);
    this.routingService.go({ cxRoute: 'home' });
  }
}
