export const productDetails = {
  id: 'ID',
  quantity: 'Quantity',
  productDetails: 'Packaging Details',
  specification: 'Product Details',
  reviews: 'Reviews',
  shipping: 'Shipping',
  share: 'Share',
  showReviews: 'Show Reviews',
  noReviews: 'No Reviews Yet',
  quoteReference: {
    quoteContextMsg: 'This product is already available in the following quotes.',
    cartContextMsg: 'This product can be added with any of the following quote references.',
    noContext: 'The selected quote reference will be added only if you are adding this product to a draft order.',
    removeQtoueReference: 'Remove Quote Reference',
    referenceNotFound: 'This product cannot be found in any valid quote.',
  },
  moq: 'Minimum Order Quantity',
  plantName: 'Plant Name',
  availableDate: 'Available Date',
  features: 'Product Features',
};
export const productList = {
  filterBy: {
    label: 'Filter By',
    action: 'Filter By',
  },
  appliedFilter: 'Applied Filter:',
  showLess: 'Show Less...',
  showMore: 'Show More...',
  sortByRelevance: 'Sort by Relevance',
  backToTopBtn: 'Back To Top',
  showMoreBtn: 'Show More',
  label: {
    id: 'ID',
    name: 'Name',
    netPrice: 'Net Price',
    listPrice: 'List Price',
    selectAll: 'Select All',
    uom: 'UOM',
    moq: 'MOQ',
  },
};
export const productFacetNavigation = {
  filterBy: {
    label: 'Filter By',
    action: 'Filter By',
  },
  appliedFilter: 'Applied Filter:',
  showLess: 'Show Less...',
  showMore: 'Show More...',
  sortByRelevance: 'Sort by Relevance',
};
export const productSummary = {
  id: 'Code Number',
  showReviews: 'Show Reviews',
  share: 'Share',
  upc: 'UPC',
};
export const productReview = {
  overallRating: 'Overall Rating',
  reviewTitle: 'Review Title',
  writeYourComments: 'Write your comments',
  rating: 'Rating',
  reviewerName: 'Reviewer Name (Optional)',
  writeReview: 'Write a Review',
  more: 'More',
  less: 'Less',
  thankYouForReview: 'Thank you for the review! Note that reviews may require review before appearing here.',
};
export const addToCart = {
  itemsAddedToYourCart: 'Item(s) added to your cart',
  itemsIncrementedInYourCart: 'This item was already in your cart. The quantity was updated.',
  items: 'Items',
  updatingCart: 'Updating cart...',
  addToCart: 'Add To Draft Order',
  viewCart: 'View Cart',
  proceedToCheckout: 'Proceed To Checkout',
  quantity: 'Qty',
  outOfStock: 'Out Of Stock',
  inStock: 'In Stock',
  selectStyleAndSize: 'Select style and size to check stock',
};
export const TabPanelContainer = {
  tabs: {
    ProductDetailsTabComponent: 'Packaging Details',
    ProductSpecsTabComponent: 'Product Details',
    ProductReviewsTabComponent: 'Reviews',
    deliveryTab: 'Shipping',
    ProductCertificationsTabComponent: 'Product Certifications',
    ProductTechnicalDocumentsTabComponent: 'Technical Documents',
  },
};
export const variant = {
  style: 'Style',
  selectedStyle: 'Selected Style',
  size: 'Size',
  color: 'Color',
  sizeGuideLabel: 'Style Guide',
};
export const addToWishList = {
  add: 'Add to Wish List',
  remove: 'Remove from Wish List',
  anonymous: 'Sign in to add to wish list',
};
export const stockNotification = {
  notifyMe: 'Notify Me',
  stopNotify: 'Stop Notification',
  getNotify: 'Get notified when this product is available.',
  getNotifySuffix: 'to get notified when this product is available.',
  activateChannelsPrefix: 'To be notified you need to activate the ',
  channelsLink: 'Notification Channels',
  activateChannelsSuffix: '.',
  notified: 'You will be notified when this product is back in stock.',
  getNotified: 'Get notified when this product is back in stock.',
  unsubscribeSuccess: 'You will not receive back-in-stock notification for this product.',
  subscriptionDialog: {
    header: 'Out of stock subscription',
    notifiedPrefix: 'You will be notified on:',
    notifiedSuffix: 'as soons as this product is back in stock.',
    manageChannelsPrefix: 'Manage your prefered notification channels at the ',
    manageChannelsLink: 'Notification Preference',
    manageChannelsSuffix: ' page.',
    manageSubscriptionsPrefix: 'You can manage your subscriptions at ',
    manageSubscriptionsLink: 'My Interests',
    manageSubscriptionsSuffix: ' page.',
    okBtn: 'OK',
    subscribing: 'Subscribing you to Out of Stock notifications for this product',
  },
};

export const KnBrCMSTabParagraphContainer = {
  tabs: {
    ProductDetailsTabComponent: 'Product Details',
    ProductSpecsTabComponent: 'Specs',
    ProductReviewsTabComponent: 'Reviews',
    deliveryTab: 'Shipping',
    ProductCertificationsTabComponent: 'Product Certifications',
  },
};

export const knbrProduct = {
  stockNotification,
  addToWishList,
  variant,
  TabPanelContainer,
  addToCart,
  productReview,
  productSummary,
  productFacetNavigation,
  productList,
  productDetails,
  KnBrCMSTabParagraphContainer,
};
