import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { Statuses } from 'src/feature-libs/kn-br-cart/quote/root/models/kn-br-quote.model';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';
import { SloanCommonService } from 'src/app/services/sloan/sloan-common.service';
import { RemoveLeadingZerosPipe } from 'src/app/shared/pipe/kn-br-custom-pipes/remove-leading-zeros.pipe';
import { KnBrCustomerContextService } from 'src/app/store/kn-br-customer-context/kn-br-customer-context.service';
import { KnBrOrderHistoryService } from 'src/feature-libs/kn-br-order/core/facade/kn-br-order-history.service';
import { KN_BR_CUSTOMER_CURRENT } from 'src/constants/api.endpoints.constant';
import { KnBrOrderHistorySearchComponent } from 'src/feature-libs/kn-br-order/components/kn-br-customer360/kn-br-order-history/kn-br-order-history-search/kn-br-order-history-search.component';

@Component({
  selector: 'knbr-sloan-order-history-search',
  templateUrl: './sloan-order-history-search.component.html',
  styleUrls: ['./sloan-order-history-search.component.scss'],
  providers: [RemoveLeadingZerosPipe],
})
export class SloanOrderHistorySearchComponent extends KnBrOrderHistorySearchComponent implements OnInit {
  orderTypeList$: Observable<Statuses[]> = this.sloanCommonService.loadOrderTypeList$();
  creditStatusList$: Observable<Statuses[]> = this.sloanCommonService.loadCreditStatusList$();
  deliveryBlockList$: Observable<Statuses[]> = this.sloanCommonService.loadDeliveryBlockList$();
  pastDueList: any;
  constructor(
    protected fb: FormBuilder,
    protected knBrOrderHistoryService: KnBrOrderHistoryService,
    protected knBrCustomerContextService: KnBrCustomerContextService,
    protected knBrCommonService: KnBrCommonService,
    protected sloanCommonService: SloanCommonService,
    protected removeLeadingZerosPipe: RemoveLeadingZerosPipe
  ) {
    super(fb, knBrOrderHistoryService, knBrCustomerContextService, knBrCommonService, removeLeadingZerosPipe);
  }

  ngOnInit(): void {
    super.ngOnInit();
    // this.pastDueList = [
    //   { Key: true, Text: 'Yes' },
    //   { Key: false, Text: 'No' },
    // ];
    this.customerContextData$.subscribe((value) => {
      const customerNumber = value?.customerNumber ? value?.customerNumber : KN_BR_CUSTOMER_CURRENT;
      this.sloanCommonService.getOrderTypeList(customerNumber);
      this.sloanCommonService.getCreditStatusList(customerNumber);
      this.sloanCommonService.getDeliveryBlockList(customerNumber);
    });
  }

  initForm() {
    const poNumPattern = '[^*^~|<>]*';
    this.orderHistorySearchForm = this.fb.group({
      salesOrderNumber: null,
      accountNumber: [
        {
          value:
            this.selectedCustomer && this.selectedCustomer.customerNumber
              ? this.removeLeadingZerosPipe.transform(this.selectedCustomer.customerNumber)
              : null,
          disabled: this.selectedCustomer && this.selectedCustomer.customerNumber ? true : false,
        },
      ],
      accountName: [
        {
          value:
            this.selectedCustomer && this.selectedCustomer.customerName ? this.selectedCustomer.customerName : null,
          disabled: this.selectedCustomer && this.selectedCustomer.customerName ? true : false,
        },
      ],
      purchaseOrderNumber: [null, Validators.pattern[poNumPattern]],
      orderType: null,
      creditStatus: null,
      deliveryBlock: null,
      status: null,
      pastDue: null,
      productNumber: null,
      currentPage: this.currentPage,
    });
  }
}
