import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { GenericLinkModule, IconModule, NavigationModule } from '@spartacus/storefront';
import { SloanCategoryNavigationComponent } from './sloan-category-navigation.component';
import { SloanResponsiveCategoryNavigationComponent } from './sloan-responsive-category-navigation/sloan-responsive-category-navigation.component';

@NgModule({
  declarations: [SloanCategoryNavigationComponent, SloanResponsiveCategoryNavigationComponent],
  imports: [
    CommonModule,
    RouterModule,
    IconModule,
    GenericLinkModule,
    I18nModule,
    NavigationModule,
    UrlModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CategoryNavigationComponent: {
          component: SloanCategoryNavigationComponent,
        },
      },
    } as CmsConfig),
  ],
  exports: [SloanCategoryNavigationComponent, SloanResponsiveCategoryNavigationComponent],
})
export class SloanCategoryNavigationModule { }
