import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CmsConfig, ConfigModule, GlobalMessageService, I18nModule, UrlModule } from '@spartacus/core';
import { SpinnerModule, FormErrorsModule } from '@spartacus/storefront';
import { UpdateProfileComponentService } from '@spartacus/user/profile/components';
import { UserProfileFacade } from '@spartacus/user/profile/root';
import { KnBrUpdateProfileFormComponent } from './components/kn-br-update-profile-form.component';
import { KnBrUpdateProfileComponent } from './kn-br-update-profile.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SpinnerModule,
    I18nModule,
    FormErrorsModule,
    RouterModule,
    UrlModule,
    ConfigModule.withConfig({
      cmsComponents: {
        UpdateProfileComponent: {
          component: () => import('./kn-br-update-profile.component').then((c) => c.KnBrUpdateProfileComponent),
          providers: [
            {
              provide: UpdateProfileComponentService,
              useClass: UpdateProfileComponentService,
              deps: [UserProfileFacade, GlobalMessageService],
            },
          ],
        },
      },
    } as CmsConfig),
  ],
  declarations: [KnBrUpdateProfileComponent, KnBrUpdateProfileFormComponent],
  exports: [KnBrUpdateProfileComponent, KnBrUpdateProfileFormComponent],
})
export class KnBrUpdateProfileModule { }
