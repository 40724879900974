import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SloanFactSheetGroupComponent } from './sloan-fact-sheet-group.component';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';

@NgModule({
  declarations: [SloanFactSheetGroupComponent],
  imports: [
    CommonModule,
    I18nModule,
    ConfigModule.withConfig({
      cmsComponents: {
        KnBrFactSheetGroupComponent: {
          component: () => import('./sloan-fact-sheet-group.component').then((c) => c.SloanFactSheetGroupComponent),
        },
      },
    } as CmsConfig),
  ],
})
export class SloanFactSheetGroupModule { }
