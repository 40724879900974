import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { FormErrorsModule, IconModule, PageComponentModule, PageLayoutModule, PageSlotModule } from '@spartacus/storefront';
import { SloanLoginComponent } from './sloan-login.component';
import { SloanLoginLayoutConfig } from './sloan-login.config';
import { UserProfileAdapter } from '@spartacus/user/profile/core';
import { SloanUserProfileAdapter } from 'src/app/services/sloan/sloan-user-profile.adapter';

@NgModule({
  declarations: [SloanLoginComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    UrlModule,
    I18nModule,
    FormErrorsModule,
    PageComponentModule,
    PageLayoutModule,
    PageSlotModule,
    ConfigModule.withConfig({
      cmsComponents: {
        KnBrLoginFormComponent: {
          component: SloanLoginComponent,
        },
      },
    } as CmsConfig),
    ConfigModule.withConfig(SloanLoginLayoutConfig),
    IconModule
  ],
  exports: [SloanLoginComponent],
  providers: [
    {
      provide: UserProfileAdapter,
      useClass: SloanUserProfileAdapter,
    },
  ],
})
export class SloanLoginModule { }
