import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { KN_BR_CUSTOMERS } from 'src/constants/api.endpoints.constant';
import { shareReplay } from 'rxjs/operators';
import { CustomerSearchForm } from 'src/feature-libs/kn-br-user/kn-br-account/root/model/customer.model';

@Injectable({
  providedIn: 'root',
})
export class KnBrCustomerAdapter {
  constructor(private endPointService: OccEndpointsService, private httpClient: HttpClient) { }

  searchCustomers(customer: CustomerSearchForm) {
    return this.httpClient
      .get(this.endPointService.buildUrl(KN_BR_CUSTOMERS, { queryParams: customer }))
      .pipe(shareReplay());
  }
}
