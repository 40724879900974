<div class="">
  <ng-container *ngIf="(addresses$ | async).length && !(showAddAddressForm || showEditAddressForm)">
    <div class="col-md-12 p-0 float-left">
      <button class="btn btn-block btn-outline-primary col-md-4 float-right" (click)="addAddressButtonHandle()">
        {{ 'knbrAddressBook.button.addAddress' | cxTranslate }}
      </button>
    </div>

    <div class="col-md-12 p-0 float-left mt-2" *ngIf="!(addressesStateLoading$ | async); else loading">
      <div *ngFor="let address of addresses$ | async" class="col-md-4 p-1 float-left">
        <sloan-address-card
          [content]="getKnbrCardContent(address) | async"
          (editCard)="editAddressButtonHandle(address)"
          (setDefaultCard)="setAddressAsDefault(address)"
          (deleteCard)="setEdit(address.id)"
          [editMode]="address.id === editCard"
          (cancelCard)="cancelCard()"
        ></sloan-address-card>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="(!(addressesStateLoading$ | async) && !(addresses$ | async).length) || showAddAddressForm">
    <h3>{{ 'knbrAddressBook.heading.addAddress' | cxTranslate }}</h3>
    <sloan-address-form
      showTitleCode="true"
      [showCancelBtn]="!((addresses$ | async).length === 0)"
      actionBtnLabel="{{ 'knbrAddressBook.button.save' | cxTranslate }}"
      cancelBtnLabel="{{ 'knbrAddressBook.button.cancel' | cxTranslate }}"
      [setAsDefaultField]="!((addresses$ | async).length === 0)"
      (submitAddress)="addAddressSubmit($event)"
      (backToAddress)="addAddressCancel()"
      (cancelCard)="cancelCard()"
    >
    </sloan-address-form>
  </ng-container>

  <ng-container *ngIf="showEditAddressForm">
    <h3>{{ 'knbrAddressBook.heading.editAddress' | cxTranslate }}</h3>
    <sloan-address-form
      showTitleCode="true"
      actionBtnLabel="{{ 'knbrAddressBook.button.update' | cxTranslate }}"
      cancelBtnLabel="{{ 'knbrAddressBook.button.cancel' | cxTranslate }}"
      [addressData]="currentAddress"
      (submitAddress)="editAddressSubmit($event)"
      (backToAddress)="editAddressCancel()"
    >
    </sloan-address-form>
  </ng-container>
</div>

<ng-template #loading>
  <div class="col-md-12 cx-address-spinner">
    <cx-spinner></cx-spinner>
  </div>
</ng-template>
