import { NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChangeDetectorRef, Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { CmsService } from '@spartacus/core';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';
import { KnBrDateHelper } from 'src/app/shared/kn-br-date.helper';
import { SloanDraftOrderCommentsPopupComponent } from '../../sloan-draft-order-comments-popup/sloan-draft-order-comments-popup.component';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { UserAccountService } from '@spartacus/user/account/core';
import { SloanCalendarHelper } from 'src/app/services/sloan/sloan-calendar-help.service';
import { SloanDataSharedService } from 'src/app/services/sloan/sloan-data-shared.service';
import { KnBrCartContextService } from 'src/feature-libs/kn-br-cart/base/core/facade/kn-br-cart-context.service';
import { KnBrDraftOrderItemComponent } from 'src/feature-libs/kn-br-cart/draft-order/components/kn-br-draft-order/kn-br-draft-order-detail/kn-br-draft-order-items/kn-br-draft-order-item/kn-br-draft-order-item.component';
import { KnBrDraftOrdersService } from 'src/feature-libs/kn-br-cart/draft-order/core/facade/kn-br-draft-orders.service';
import { KnBrQuoteReferenceService } from 'src/feature-libs/kn-br-cart/quote/core/facade/kn-br-quote-reference.service';
import { UserAccountFacade } from '@spartacus/user/account/root';

@Component({
  selector: 'kn-br-draft-order-item',
  templateUrl: './sloan-draft-order-item.component.html',
  styleUrls: ['./sloan-draft-order-item.component.scss'],
})
export class SloanDraftOrderItemComponent extends KnBrDraftOrderItemComponent implements OnInit, OnDestroy {
  @Input() isShipComplete: any;
  @Input() entry: any;
  @Output() valueChangeEvent = new EventEmitter<string>();
  rddMinDate: NgbDate;
  rddMaxDate: NgbDate;
  today: NgbDate = this.sloanCalendarHelper.today;
  isRddDisabled: (date: NgbDate, current: { year: number; month: number }) => boolean;

  constructor(
    protected currentUserService: UserAccountFacade,
    protected knBrCommonService: KnBrCommonService,
    protected draftOrdersService: KnBrDraftOrdersService,
    protected contextService: KnBrCartContextService,
    protected modalService: NgbModal,
    protected knBrQuoteReferenceService: KnBrQuoteReferenceService,
    protected cdr: ChangeDetectorRef,
    protected cmsService: CmsService,
    protected activeCartService: ActiveCartFacade,
    protected knBrDateHelper: KnBrDateHelper,
    protected sloanCalendarHelper: SloanCalendarHelper,
    protected sloanDataSharedService: SloanDataSharedService
  ) {
    super(
      currentUserService,
      knBrCommonService,
      draftOrdersService,
      contextService,
      modalService,
      knBrQuoteReferenceService,
      cdr,
      cmsService,
      activeCartService,
      knBrDateHelper
    );
    this.rddMinDate = this.sloanCalendarHelper.getRddMinDate(this.today);
    this.rddMaxDate = this.sloanCalendarHelper.getRddMaxDate(this.today);
    this.isRddDisabled = (date: NgbDate, current: { year: number; month: number }) => {
      return (
        this.sloanCalendarHelper.isHoliday(date) ||
        (this.sloanCalendarHelper.isWeekend(date) && date.month === current.month)
      );
    };
    this.reqShipDate.setValue(this.sloanCalendarHelper.rddDefaultDate(this.today));
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (this.isShipComplete) {
      this.reqShipDate.disable();
    }
  }

  onEnterInput() {
    this.sloanDataSharedService.setDraftOrderCheckoutDisable(true);
  }

  updateChnage(event, value) {
    this.valueChangeEvent.emit(value);
    super.update(event);
    this.sloanDataSharedService.setDraftOrderCheckoutDisable(false);
  }

  openComment(entry?: any) {
    this.valueChangeEvent.emit('comment');
    let modalInstance: any;
    const modalRef = this.modalService.open(SloanDraftOrderCommentsPopupComponent, {
      centered: true,
      size: entry.product?.isGeneric || entry.product?.isSinkDrillPattern_b ? 'xl' : 'lg',
    });
    modalInstance = modalRef.componentInstance;
    modalInstance.entryNumber = entry.entryNumber;
    modalInstance.isGeneric = entry.product?.isGeneric;
    modalInstance.isSinkDrillPattern = entry.product?.isSinkDrillPattern_b;
    modalInstance.editable = this.isDisabled();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
