<ng-container *ngIf="product$ | async as product">
  <ng-template [cxOutlet]="outlets.PRICE" [cxOutletContext]="{ product: product }">
    <div class="col-12 pl-2 pt-3 pb-3 border-top border-bottom price">
      <div class="col-md-6 col-sm-12 p-0 d-flex align-items-center">
        <span>{{ 'productList.label.listPrice' | cxTranslate }}:</span>
        <span class="pl-4 color-font font-text-600 font-size-24">{{ product?.knbrPrice?.formatedListPrice }}</span>
      </div>
      <div class="col-md-6 col-sm-12 p-0 d-flex align-items-center">
        <span>{{ 'productList.label.netPrice' | cxTranslate }}:</span>
        <span class="pl-4 color-font font-text-600 font-size-24">{{ product?.knbrPrice?.formatedNetPrice }}</span>
      </div>
    </div>
    <div class="col-12 pl-2 pb-3 border-bottom">
      <div class="col-sm-12 pl-0 pt-3 price">
        <span class="col-sm-4 p-0">{{ 'productDetails.plantName' | cxTranslate }}:</span>
        <span class="pl-4">{{ product?.manufacturer }}</span>
      </div>
      <div class="col-sm-12 pl-0 pt-2 price">
        <span class="col-sm-4 p-0">{{ 'cartCommon.label.uom' | cxTranslate }}:</span>
        <span class="pl-4">{{ product?.uom }}</span>
      </div>
      <div class="col-sm-12 pl-0 pt-2 price">
        <span class="col-sm-4 p-0">{{ 'productDetails.moq' | cxTranslate }}:</span>
        <span class="pl-4">{{ product?.minOrderQuantity }}</span>
      </div>
    </div>
  </ng-template>
</ng-container>
