import { NgModule } from '@angular/core';
import { AsmComponentsModule } from '@spartacus/asm/components';
import { AsmOccModule } from '@spartacus/asm/occ';
import { CartPageEventModule } from '@spartacus/cart/base/core';
import { WishListModule } from '@spartacus/cart/wish-list';
import { CheckoutComponentsModule } from '@spartacus/checkout/base/components';
import { CheckoutCoreModule } from '@spartacus/checkout/base/core';
import { CheckoutOccModule } from '@spartacus/checkout/base/occ';
import {
  AuthModule,
  ConfigModule,
  CostCenterOccModule,
  ExternalRoutesModule,
  ProductModule,
  ProductOccModule,
  UserModule,
  UserOccModule
} from '@spartacus/core';
import { OrderModule } from '@spartacus/order';
import { SmartEditModule } from '@spartacus/smartedit';
import {
  BannerCarouselModule,
  BannerModule,
  BreadcrumbModule,
  CategoryNavigationModule,
  CmsParagraphModule,
  FooterNavigationModule,
  HamburgerMenuModule,
  JsonLdBuilderModule,
  LinkModule,
  NavigationEventModule,
  NavigationModule,
  PDFModule,
  ProductDetailsPageModule,
  ProductListingPageModule,
  ProductListModule,
  ProductPageEventModule,
  SiteContextSelectorModule,
  TabParagraphContainerModule,
  UserComponentModule,
  VideoModule
} from '@spartacus/storefront';
import { PersonalizationModule } from '@spartacus/tracking/personalization';
import {
  UpdateEmailModule,
  UpdatePasswordModule
} from '@spartacus/user/profile/components';
import { KnBrOrderModule } from 'src/feature-libs/kn-br-order/kn-br-order.module';
import { KnBrAccountModule } from 'src/feature-libs/kn-br-user/kn-br-account/kn-br-account.module';
import { knBrLogoutModule } from 'src/feature-libs/kn-br-user/kn-br-logout/kn-br-logout.module';
import { KnBrProfileModule } from 'src/feature-libs/kn-br-user/kn-br-profile/kn-br-profile.module';
import { AsmFeatureModule } from './features/asm/asm-feature.module';
import { CartBaseFeatureModule } from './features/cart/cart-base-feature.module';
import { ImportExportFeatureModule } from './features/cart/cart-import-export-feature.module';
import { QuickOrderFeatureModule } from './features/cart/cart-quick-order-feature.module';
import { CheckoutFeatureModule } from './features/checkout/checkout-feature.module';
import { CustomerTicketingFeatureModule } from './features/customer-ticketing/customer-ticketing-feature.module';
import { OrganizationAdministrationFeatureModule } from './features/organization/organization-administration-feature.module';
import { SmartEditFeatureModule } from './features/smartedit/smart-edit-feature.module';
import { KnBrCommonModule } from '../common/kn-br-common.module';
import { KnBrStoreModule } from '../store/kn-br-store.module';
import { KnBrFeaturesLibsModule } from 'src/feature-libs/kn-br-feature-libs.module';
import { KnBrCustomerLibsModule } from '../customer-libs/kn-br-customer-libs.module';
import { KnBrProductOccModule } from 'src/feature-libs/kn-br-product/occ/kn-br-occ-product.module';
import { TagManagementFeatureModule } from './features/tracking/tag-management-feature.module';
import { SloanModule } from '../sloan/sloan.module';
import { KnBrProductModule } from 'src/feature-libs/kn-br-product/kn-br-product.module';
import { ProductFeatureModule } from './features/product/product-feature.module';
import { OrdersFeatureModule } from './features/order/order-feature.module';


@NgModule({
  imports: [
    // Auth Core
    AuthModule.forRoot(),
    knBrLogoutModule,

    // Basic Cms Components
    HamburgerMenuModule,
    SiteContextSelectorModule,
    LinkModule,
    BannerModule,
    CmsParagraphModule,
    TabParagraphContainerModule,
    BannerCarouselModule,
    CategoryNavigationModule,
    NavigationModule,
    FooterNavigationModule,
    BreadcrumbModule,

    // User Core
    //UserFeatureModule,
    KnBrProfileModule,
    KnBrAccountModule,
    UserModule,
    UserOccModule,
    UserComponentModule,
    UpdateEmailModule,
    UpdatePasswordModule,


    // Product Core
    ProductFeatureModule,
    //KnBrProductModule,
    ProductModule.forRoot(),
    ProductOccModule,
    KnBrProductOccModule,
    // Product UI
    ProductDetailsPageModule,
    ProductListingPageModule,
    ProductListModule,
    // Cart
    // CartModule.forRoot(),
    CartBaseFeatureModule,
    QuickOrderFeatureModule,
    ImportExportFeatureModule,
    WishListModule,

    // Checkout Core
    CheckoutCoreModule,
    CheckoutOccModule,
    CostCenterOccModule,
    CheckoutFeatureModule,
    // Checkout UI
    CheckoutComponentsModule,

    // Order
    OrdersFeatureModule,
    //KnBrOrderModule,
    OrderModule,
    VideoModule,
    PDFModule,
    // SmartEdit
    SmartEditModule,
    SmartEditFeatureModule,
    // Personalization
    PersonalizationModule,

    // Asm Core
    AsmOccModule,
    AsmFeatureModule,
    AsmComponentsModule,

    // Page Events
    CartPageEventModule,
    ProductPageEventModule,
    NavigationEventModule,

    // Customer ticket support
    CustomerTicketingFeatureModule,

    KnBrStoreModule,
    KnBrCommonModule,
    KnBrFeaturesLibsModule,
    KnBrCustomerLibsModule,
    SloanModule,
    /************************* Opt-in features *************************/
    ExternalRoutesModule.forRoot(), // to opt-in explicitly, added by default schematics
    JsonLdBuilderModule,
    TagManagementFeatureModule,
    OrganizationAdministrationFeatureModule,

    /************************* External features *************************/

    ConfigModule.withConfig({
      pagination: {
        addPrevious: true,
        addNext: true,
        addDots: true,
      },
    }),
  ],
})
export class SpartacusFeaturesModule { }
