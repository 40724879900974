import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { GlobalMessageService, GlobalMessageType, RoutingService, WindowRef } from '@spartacus/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { KnBrCustomerContextService } from '../../store/kn-br-customer-context/kn-br-customer-context.service';
import { CustomerData } from 'src/feature-libs/kn-br-user/kn-br-account/root/model/customer.model';

@Injectable({
  providedIn: 'root',
})
export class KnBrCustomerContextGuard {
  customerContext: CustomerData;
  constructor(
    private customerSearchService: KnBrCustomerContextService,
    private routingService: RoutingService,
    private globalMessageService: GlobalMessageService,
    private winRef: WindowRef
  ) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.customerSearchService.get$.pipe(
      map((response) => {
        this.customerContext = response;

        if (this.customerContext && this.customerContext.customerNumber) {
          return true;
        } else {
          const redirectUrl = {
            params: next.params?.categoryCode ? { code: next.params?.categoryCode } : next.params,
            route: next.data.cxRoute,
          };
          this.winRef.sessionStorage.setItem('redirect', JSON.stringify(redirectUrl));
          this.routingService.go({ cxRoute: 'switchAccount' });
          this.globalMessageService.add({ key: 'customer.context.required' }, GlobalMessageType.MSG_TYPE_INFO);
          return false;
        }
      })
    );
  }
}
