import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SloanReturnOrderDetailsItemsComponent } from './sloan-return-order-details-items.component';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { MediaModule } from '@spartacus/storefront';

@NgModule({
  declarations: [SloanReturnOrderDetailsItemsComponent],
  imports: [
    CommonModule,
    I18nModule,
    MediaModule,
    ConfigModule.withConfig({
      cmsComponents: {
        ReturnRequestItemsComponent: {
          component: SloanReturnOrderDetailsItemsComponent,
        },
      },
    } as CmsConfig),
  ],
})
export class SloanReturnOrderDetailsItemsModule {}
