<ng-container
  *ngIf="{
    products: (products$ | async)?.products,
    availability: availability$ | async,
    pagination: paginate$ | async
  } as data"
>
  <ng-container *ngIf="data.products && data.products.length > 0 && !(isLoader$ | async)">
    <div class="col-md-12 p-0 float-left mt-3 pb-3 border-bottom d-flex align-items-center">
      <ng-container *ngIf="sorts$ | async as sorts">
        <div class="col-sm-12 col-md-3 col-lg-2 p-0 float-left">
          <cx-sorting
            [sortOptions]="sorts"
            [sortLabels]="getSortLabels() | async"
            (sortListEvent)="changeSortCode($event)"
            [selectedOption]="sortType"
            placeholder="{{ 'orderHistory.sortByMostRecent' | cxTranslate }}"
          ></cx-sorting>
        </div>
      </ng-container>
      <ng-container *ngIf="paginate$ | async as pagination">
        <div class="col-sm-12 col-md-2 p-0 float-left text-center">
          <label>{{ pagination.totalResults }} {{ 'orderForms.label.productsFound' | cxTranslate }}</label>
        </div>
      </ng-container>
    </div>
    <div class="col-md-12 p-0 float-left mt-3 d-flex align-items-center">
      <div class="col-md-3 float-left" *ngIf="isOrderForm">
        {{ 'orderForms.label.qtySelected' | cxTranslate }} {{ totalQty }}
      </div>
      <div class="col-md-2">&nbsp;</div>
      <div class="col-md-3 text-right" *ngIf="isOrderForm">
        {{ 'orderForms.label.subTotal' | cxTranslate }} {{ subTotal | number : '1.2-2' }}
      </div>
      <div class="col-md-10 p-0 float-left" *ngIf="!isOrderForm">
        <button
          (click)="createOrderForm()"
          [disabled]="checkedProducts && checkedProducts.length < 1"
          class="btn btn-primary col-md-3 float-right"
        >
          {{ 'orderForms.label.createOrderForm' | cxTranslate }}
        </button>
      </div>
      <ng-container *ngIf="isOrderForm">
        <kn-br-add-to-cart
          *ngIf="cartContext || !quoteContext"
          class="col-md-2 pr-0"
          [type]="'QUICK'"
          [productList]="selectedProducts"
          [disableAddToCart]="showMOQWarningMsg"
        >
        </kn-br-add-to-cart>
        <kn-br-add-to-quote
          *ngIf="!cartContext || quoteContext"
          class="col-md-2 pr-0"
          [type]="'QUICK'"
          [productList]="selectedProducts"
          [disableAddToQuote]="showMOQWarningMsg"
        >
        </kn-br-add-to-quote>
      </ng-container>
    </div>
    <div *ngIf="showMOQWarningMsg" class="col-md-12 p-0 float-left mt-3 d-md-flex align-items-center">
      <span class="font-size text-danger">
        {{ 'knbrOrderDetails.addProducts.moqWarningMsg' | cxTranslate }}
      </span>
    </div>
    <div class="customersearch-list-table border-bottom mt-3 float-left col-lg-12 p-0 mb-3">
      <div class="customersearch-list-titles border-top float-left w-100 p-3 d-none d-sm-none d-lg-block">
        <div class="col-md-1 float-left font-text-600 font-size">&nbsp;</div>
        <div
          class="pl-0 float-left font-text-600 font-size"
          [ngClass]="data?.availability && data?.availability.length ? 'col-md-2' : 'col-md-3'"
        >
          {{ 'orderForms.label.item' | cxTranslate }}
        </div>
        <div
          *ngIf="data?.availability && data?.availability.length"
          class="col-md-3 pl-0 float-left font-text-600 font-size"
        >
          {{ 'orderForms.label.availability' | cxTranslate }}
        </div>
        <div class="col-md-2 text-center float-left font-text-600 font-size">
          {{ 'orderForms.label.listPrice' | cxTranslate }}
        </div>
        <ng-container *ngIf="isOrderForm">
          <div
            class="text-center float-left font-text-600 font-size"
            [ngClass]="data?.availability && data?.availability.length ? 'col-md-1' : 'col-md-2'"
          >
            {{ 'orderForms.label.qty' | cxTranslate }}
          </div>
          <div class="col-md-2 text-center float-left font-text-600 font-size">
            {{ 'orderForms.label.minQty' | cxTranslate }}
          </div>
          <div
            class="text-center float-left font-text-600 font-size"
            [ngClass]="data?.availability && data?.availability.length ? 'col-md-1' : 'col-md-2'"
          >
            {{ 'orderForms.label.total' | cxTranslate }}
          </div>
        </ng-container>
        <ng-container *ngIf="!isOrderForm">
          <div class="col-md-5 float-left text-center font-text-600 font-size">
            {{ 'orderForms.label.selectProduct' | cxTranslate }}
          </div>
        </ng-container>
      </div>
      <ng-container *ngIf="data.products && data.products.length > 0">
        <kn-br-order-form-item
          *ngFor="let item of data.products"
          [item]="item"
          [availability]="data.availability"
          [isOrderForm]="isOrderForm"
          [isCustomerContextAvailable]="isCustomerContextAvailable"
          (onChangeProduct)="onChangeProduct($event)"
          (quantityChange)="onQuantityChange($event)"
        >
        </kn-br-order-form-item>
      </ng-container>
    </div>

    <div class="col-md-12 p-0 float-left pb-3 border-bottom d-flex align-items-center">
      <div class="col-md-3 float-left" *ngIf="isOrderForm">
        {{ 'orderForms.label.qtySelected' | cxTranslate }} {{ totalQty }}
      </div>
      <div class="col-md-2">&nbsp;</div>
      <div class="col-md-3 text-right" *ngIf="isOrderForm">
        {{ 'orderForms.label.subTotal' | cxTranslate }} {{ subTotal | number : '1.2-2' }}
      </div>
      <div class="col-md-10 p-0 float-left" *ngIf="!isOrderForm">
        <button
          (click)="createOrderForm()"
          [disabled]="checkedProducts && checkedProducts.length < 1"
          class="btn btn-primary col-md-3 float-right"
        >
          {{ 'orderForms.label.createOrderForm' | cxTranslate }}
        </button>
      </div>
      <ng-container *ngIf="isOrderForm">
        <kn-br-add-to-cart
          *ngIf="cartContext || !quoteContext"
          class="col-md-2 pr-0"
          [type]="'QUICK'"
          [productList]="selectedProducts"
          [disableAddToCart]="showMOQWarningMsg"
        >
        </kn-br-add-to-cart>
        <kn-br-add-to-quote
          *ngIf="!cartContext || quoteContext"
          class="col-md-2 pr-0"
          [type]="'QUICK'"
          [productList]="selectedProducts"
          [disableAddToQuote]="showMOQWarningMsg"
        >
        </kn-br-add-to-quote>
      </ng-container>
    </div>

    <div class="col-md-12 p-0 float-left border-bottom d-flex align-items-center">
      <ng-container *ngIf="sorts$ | async as sorts">
        <div class="col-sm-12 col-md-3 col-lg-2 p-0 float-left">
          <cx-sorting
            [sortOptions]="sorts"
            [sortLabels]="getSortLabels() | async"
            (sortListEvent)="changeSortCode($event)"
            [selectedOption]="sortType"
            placeholder="{{ 'orderHistory.sortByMostRecent' | cxTranslate }}"
          ></cx-sorting>
        </div>
      </ng-container>
      <ng-container *ngIf="data.pagination">
        <div class="col-sm-12 col-md-2 col-lg-2 p-0 float-left text-center">
          <label>{{ data.pagination.totalResults }} {{ 'orderForms.label.productsFound' | cxTranslate }}</label>
        </div>
        <div class="col-md-3 float-left">&nbsp;</div>
        <div class="col-md-4 col-sm-12 col-lg-5 p-0 float-left">
          <div
            *ngIf="data.pagination.totalPages > 1"
            class="col-lg-12 p-0 float-left mt-3 mb-3 d-flex justify-content-end"
          >
            <cx-pagination [pagination]="data.pagination" (viewPageEvent)="onpageChange($event)"></cx-pagination>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <div class="alert-info p-3 rounded mt-3" *ngIf="data.products && data.products.length < 1 && !(isLoader$ | async)">
    {{ 'orderForms.nodataMsg' | cxTranslate }}
  </div>
</ng-container>
<div *ngIf="isLoader$ | async">
  <cx-spinner></cx-spinner>
</div>
