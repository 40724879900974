import { KnBrCommonService } from 'src/app/services/kn-br-common.service';
import { Component, OnInit, ViewContainerRef, OnDestroy } from '@angular/core';
import { RoutingService } from '@spartacus/core';
import { LaunchDialogService } from '@spartacus/storefront';
import { SloanCommonService } from 'src/app/services/sloan/sloan-common.service';
import { KnBrOrderDetailsOverviewComponent } from 'src/feature-libs/kn-br-order/components/kn-br-customer360/kn-br-order-detail/kn-br-order-details-overview/kn-br-order-details-overview.component';
import { OrderHistoryFacade } from '@spartacus/order/root';
import { OrderDetailsService } from 'src/feature-libs/kn-br-order/components/kn-br-customer360/kn-br-order-detail/kn-br-order-details.service';
@Component({
  selector: 'sloan-order-details-overview',
  templateUrl: './sloan-order-details-overview.component.html',
})
export class SloanOrderDetailsOverviewComponent extends KnBrOrderDetailsOverviewComponent implements OnInit, OnDestroy {
  constructor(
    protected orderDetailsService: OrderDetailsService,
    protected routingService: RoutingService,
    protected launchDialogService: LaunchDialogService,
    protected vcr: ViewContainerRef,
    protected userOrderService: OrderHistoryFacade,
    protected knBrCommonService: KnBrCommonService,
    private commonService: SloanCommonService
  ) {
    super(orderDetailsService, routingService, launchDialogService, vcr, userOrderService, knBrCommonService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  downloadSAPDocumentPDF(value: any) {
    if (!value) {
      return;
    }

    this.commonService.downloadSAPDocumentPDF(value);
  }

  getDocumentKey(key) {
    const keys = key?.split('_');
    return keys.length > 1 ? keys[1] : '';
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
