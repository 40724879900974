<ng-container *ngIf="product$ | async as product">
  <ng-template [cxOutlet]="outlets.PRICE" [cxOutletContext]="{ product: product }">
    <div
      class="col-lg-12 pl-2 pt-4 pb-4 border-top"
      *ngIf="
        product?.syncResponse && product?.syncResponse?.items[0].items && product?.syncResponse?.items[0].items.length
      "
    >
      <div class="col-md-12 p-0 price">
        <div class="col-md-4 p-0 font-text-500">
          {{ 'productDetails.plantName' | cxTranslate }}
        </div>
        <div class="col-md-4 p-0 font-text-500">
          {{ 'productDetails.quantity' | cxTranslate }}
        </div>
        <div class="col-md-4 p-0 font-text-500">
          {{ 'productDetails.availableDate' | cxTranslate }}
        </div>
      </div>
      <div class="col-md-12 p-0 price pt-3" *ngFor="let availability of product?.syncResponse?.items[0].items">
        <div class="col-md-4 p-0">{{ availability.plantDescription }}</div>
        <div class="col-md-4 p-0">{{ availability.distAvailability }}</div>
        <div class="col-md-4 p-0">{{ availability.shipDate }}</div>
      </div>
    </div>
    <div class="col-lg-12 pl-2 pt-3 pb-3 border-top border-bottom price">
      <div class="col-md-6 col-sm-12 p-0 d-flex align-items-center">
        <span>{{ 'productList.label.listPrice' | cxTranslate }}:</span>
        <span class="pl-4 color-font font-text-500 font-size-24"> {{ product?.syncResponse?.items[0].listPrice }}</span>
      </div>
      <div class="col-md-6 col-sm-12 p-0 d-flex align-items-center">
        <span>{{ 'productList.label.netPrice' | cxTranslate }}:</span>
        <span class="pl-4 color-font font-text-500 font-size-24">{{ product?.syncResponse?.items[0]?.netPrice }}</span>
      </div>
    </div>
    <div class="col-lg-12 pl-2 pb-3 border-bottom">
      <div class="col-sm-12 pl-0 pt-3 price">
        <span class="col-sm-4 p-0">{{ 'cartCommon.label.uom' | cxTranslate }}:</span>
        <span class="pl-4">{{ product?.uom }}</span>
      </div>
      <div class="col-sm-12 pl-0 pt-2 price">
        <span class="col-sm-4 p-0">{{ 'productDetails.moq' | cxTranslate }}:</span>
        <span class="pl-4">{{ product?.minOrderQuantity }}</span>
      </div>
    </div>
  </ng-template>
</ng-container>
