import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConfigModule, ProductSearchAdapter, ProductSearchService } from '@spartacus/core';
import { SloanProductActionBarModule } from './sloan-product-action-bar/sloan-product-action-bar.module';
import { SloanProductDetailModule } from './sloan-product-detail/sloan-product-detail.module';
import { sloanProductEndpointsConfig } from './sloan-product-endpoint.config';
import { SloanProductListModule } from './sloan-product-list/sloan-product-list.module';
import { FacetService } from '@spartacus/storefront';
import { knBrProductViewConfig } from 'src/feature-libs/kn-br-product/occ/config/kn-br-product.config';
import { SloanFacetService } from 'src/app/services/sloan/sloan-facet-service';
import { SloanOccProductSearchAdapter } from 'src/app/services/sloan/sloan-product-search.adapter';
import { SloanProductSearchService } from 'src/app/services/sloan/sloan-product-search.service';
import { SloanTabParagraphContainerModule } from './sloan-product-detail/tab-paragraph-container/tab-paragraph-container.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SloanProductDetailModule,
    SloanProductListModule,
    SloanProductActionBarModule,
    SloanTabParagraphContainerModule,
    ConfigModule.withConfig(sloanProductEndpointsConfig),
    ConfigModule.withConfig(knBrProductViewConfig),
  ],
  providers: [
    {
      provide: ProductSearchAdapter,
      useClass: SloanOccProductSearchAdapter,
    },
    {
      provide: FacetService,
      useClass: SloanFacetService,
    },
    {
      provide: ProductSearchService,
      useClass: SloanProductSearchService,
    },
  ],
})
export class SloanProductModule {}
