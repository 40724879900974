<div
  class="bg-white customersearch-list-titles table-content border-top float-left w-100 pl-2 pr-2 pt-3 pb-3 d-flex flex-column"
  *ngIf="entry"
>
  <div class="d-flex align-items-center">
    <div
      class="text-center title-wrap padding-res-0 pl-0 pr-0 float-left d-flex align-items-center"
      [class.col-md-3]="isPartial"
      [class.col-md-4]="!isPartial"
    >
      <div class="text-center padding-res-0 float-left pr-2">
        <div class="remember-me col-md-12 float-left d-flex justify-content-start p-0">
          <div class="form-check custom-checkbox" *ngIf="!isDisabled()">
            <input
              type="checkbox"
              class="custom-control-input"
              [checked]="isChecked(entry.entryNumber)"
              [id]="entry.entryNumber"
              (change)="changeCheckBox($event)"
            />
            <label class="custom-control-label color-font" [for]="entry.entryNumber"></label>
          </div>
        </div>
      </div>
      <cx-media [container]="entry.product?.images" format="thumbnail"> </cx-media>
      <div
        class="m-res-0 ml-3 text-left float-left product-name"
        [routerLink]="{ cxRoute: 'product', params: entry.product } | cxUrl"
      >
        <span class="color-font font-text-600">{{ entry.product?.code }}</span>
        <span class="color-font font-text-600">{{ entry.product?.name }}</span>
        <span class="font-size color-font pt-1">
          {{ 'quoteItems.header.lineNo' | cxTranslate }}: {{ entry.entryNumber }}
        </span>
      </div>
    </div>

    <div class="col-md-1 text-center title-wrap float-left pl-2 pr-2 color-font font-size">
      <span class="res-titles"> {{ 'quoteItems.header.plant' | cxTranslate }}</span>
      {{ entry.product?.manufacturer }}
    </div>
    <div class="col-md-2 text-left float-left pl-2 pr-2">
      <span class="res-titles"> {{ 'quoteItems.header.reqShipDate' | cxTranslate }}</span>
      <div class="form-group mt-3 col-lg-12 float-left mb-3 valid-to p-res-0 position-relative">
        <div class="input-group">
          <input
            class="form-control w-100 cursor-pointer mb-0"
            [placeholder]="dateFormat$ | async"
            name="validFrom"
            ngbDatepicker
            #validFrom="ngbDatepicker"
            [formControl]="reqShipDate"
            (dateSelect)="update($event)"
            (blur)="update($event)"
            (focus)="onEnterInput()"
            [minDate]="reqShipMinDate"
            [maxDate]="reqShipMaxDate"
            [markDisabled]="isReqShipDisabled"
          />
          <div class="input-group-append">
            <button
              class="btn btn calendar p-0 h-auto w-auto"
              container="body"
              (click)="validFrom.toggle()"
              type="button"
              [disabled]="isDisabled()"
            >
              <i class="far fa-calendar-alt color-font"></i>
            </button>
          </div>
        </div>
        <cx-form-errors [control]="reqShipDate"></cx-form-errors>
        <span class="col-md-12 float-left text-left p-0 font-sm-size color-font" *ngIf="entry.estimatedShipDate">
          {{ 'quoteItems.header.estimatedShipDate' | cxTranslate }}: {{ entry.estimatedShipDate }}
        </span>
      </div>
    </div>
    <div class="col-md-1 text-center title-wrap float-left pl-2 pr-2 color-font">
      <span class="res-titles"> {{ 'quoteItems.header.listPrice' | cxTranslate }}</span>

      {{ entry?.knbrTotalPrice?.formatedListPrice }}
    </div>
    <div class="col-md-1 text-center title-wrap float-left pl-2 pr-2 color-font">
      <span class="res-titles"> {{ 'quoteItems.header.netPrice' | cxTranslate }}</span>
      {{ entry?.knbrTotalPrice?.formatedNetPrice }}
    </div>
    <div *ngIf="isPartial" class="col-md-1 text-center title-wrap float-left pl-2 pr-2 color-font font-size">
      <span class="res-titles"> {{ 'quoteItems.header.availableQty' | cxTranslate }}</span>
      {{ entry?.availableQty }}
    </div>
    <div class="col-md-1 text-left title-wrap float-left pl-2 pr-2">
      <span class="res-titles"> {{ 'quoteItems.header.quantity' | cxTranslate }}</span>

      <div class="form-group col-lg-12 pl-0 mb-0 pr-0 float-left valid-to">
        <input
          type="text"
          class="form-control w-100"
          [formControl]="quantity"
          (blur)="update($event)"
          (keyup.enter)="update($event)"
          (focus)="onEnterInput()"
          knBrNumbersOnly
        />
        <span class="highlight"></span>
        <span class="col-md-12 float-left p-0 font-sm-size color-font">
          {{ 'cartCommon.label.uom' | cxTranslate }}: {{ entry.uom }}
        </span>
        <span class="col-md-12 float-left p-0 font-sm-size color-font">
          {{ 'cartCommon.label.moq' | cxTranslate }}: {{ entry.minimumOrderQuantity }}
        </span>
      </div>
    </div>

    <div class="col-md-1 text-center title-wrap float-left pl-1 pr-1 color-font font-text-bold">
      <span class="res-titles"> {{ 'quoteItems.header.total' | cxTranslate }}</span>
      {{ entry.totalPrice?.formattedValue }}
    </div>
    <div
      class="col-md-1 text-center title-wrap float-left pl-0 pr-0 color-font font-size font-lg-size"
      (click)="openComment(entry)"
    >
      <span class="res-titles"> {{ 'quoteCommon.label.actions' | cxTranslate }} </span>
      <i class="fas fa-comments pr-2" aria-hidden="true"></i>{{ entry && entry.commentCount ? entry.commentCount : 0 }}
    </div>
  </div>

  <div
    class="item w-100 float-left align-items-center pt-3 pl-3 pr-3 pb-0"
    *ngIf="entry.orderRefEntries && entry.orderRefEntries.length"
  >
    <app-kn-br-quote-partial-item
      [togglePartialOrder]="entry.togglePartialOrder"
      [orderRefEntries]="entry.orderRefEntries"
    ></app-kn-br-quote-partial-item>
  </div>
</div>
<div class="pt-2" *ngIf="entry && !entry.isValidQuantity">
  <div class="alert alert-danger" role="alert">
    {{ 'quoteCommon.invalidQtyMessage.entryMessage' | cxTranslate }}
  </div>
</div>
