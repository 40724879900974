import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SloanOrderFormSearchResultModule } from './sloan-order-form-search-result/sloan-order-form-search-result.module';
import { SloanOrderFormSearchModule } from './sloan-order-form-search/sloan-order-form-search.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, SloanOrderFormSearchModule, SloanOrderFormSearchResultModule],
})
export class SloanOrderFormsModule {}
