import { filter } from 'rxjs/operators';
import { ChangeDetectorRef, Component, OnInit, OnDestroy, Input } from '@angular/core';
import { GlobalMessageService, Product } from '@spartacus/core';
import { CurrentProductService } from '@spartacus/storefront';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';
import { KnBrProductSelectService } from 'src/feature-libs/kn-br-product/core/service/kn-br-product-select.service';
import { SloanSelectQuoteComponent } from './sloan-select-quote/sloan-select-quote.component';
import { Observable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { KnBrAddToQuoteComponent } from 'src/feature-libs/kn-br-product/components/kn-br-add-to-quote/kn-br-add-to-quote.component';
import { KnBrCartContextService } from 'src/feature-libs/kn-br-cart/base/core/facade/kn-br-cart-context.service';
import { KnBrQuoteContextService } from 'src/feature-libs/kn-br-cart/quote/core/facade/kn-br-quote-context.service';
import { KnBrQuoteEntryService } from 'src/feature-libs/kn-br-cart/quote/core/facade/kn-br-quote-entry.service';
import { KnBrQuotesService } from 'src/feature-libs/kn-br-cart/quote/core/facade/kn-br-quotes.service';

@Component({
  selector: 'kn-br-add-to-quote',
  templateUrl: './sloan-add-to-quote.component.html',
  styleUrls: ['./sloan-add-to-quote.component.scss'],
})
export class SloanAddToQuoteComponent extends KnBrAddToQuoteComponent implements OnInit, OnDestroy {
  @Input() product: any;
  @Input() disableAddToQuote = false;
  quickEntryloader$: Observable<boolean> = this.knBrQuoteEntryService.quickEntryLoader$;
  constructor(
    protected modalService: NgbModal,
    protected currentProductService: CurrentProductService,
    protected cd: ChangeDetectorRef,
    protected knBrCartContextService: KnBrCartContextService,
    protected knBrProductService: KnBrProductSelectService,
    protected messageService: GlobalMessageService,
    protected knBrQuoteContextService: KnBrQuoteContextService,
    protected knBrQuoteEntryService: KnBrQuoteEntryService,
    protected knBrCommonService: KnBrCommonService,
    protected knBrQuotesService: KnBrQuotesService
  ) {
    super(
      modalService,
      currentProductService,
      cd,
      knBrCartContextService,
      knBrProductService,
      messageService,
      knBrQuoteContextService,
      knBrQuoteEntryService,
      knBrCommonService,
      knBrQuotesService
    );
  }
  openModal() {
    let modalInstance: any;
    this.modalRef = this.modalService.open(SloanSelectQuoteComponent, {
      centered: true,
      size: 'lg',
    });
    modalInstance = this.modalRef.componentInstance;
    modalInstance.productCode = this.productCode;
    modalInstance.quantity = this.quantity;
    modalInstance.productList = this.productList;
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (this.product) {
      this.isOrderable = this.product.isOrderable && this.product.price && this.product.price?.formattedValue;
      this.cd.markForCheck();
    } else {
      this.subscription.add(
        this.currentProductService
          .getProduct()
          .pipe(filter(Boolean))
          .subscribe((product: any) => {
            this.isOrderable = product.isOrderable && product.price && product.price?.formattedValue;
            this.cd.markForCheck();
          })
      );
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
