import { NgModule } from '@angular/core';
import { cartBaseTranslationChunksConfig, cartBaseTranslations } from '@spartacus/cart/base/assets';
import {
  ADD_TO_CART_FEATURE,
  CART_BASE_FEATURE,
  CartBaseRootModule,
  MINI_CART_FEATURE,
} from '@spartacus/cart/base/root';
import { I18nConfig, provideConfig } from '@spartacus/core';
import { KnBrCartBaseTranslations, KnBrCartBaseTranslationChunksConfig } from 'src/feature-libs/kn-br-cart/base/assets/translations/translation';
import { KnBrCartBaseRootModule } from 'src/feature-libs/kn-br-cart/base/root/cart-base-root.module';
import { ADD_TO_QUOTE_FEATURE } from 'src/feature-libs/kn-br-cart/base/root/feature-name';

@NgModule({
  imports: [KnBrCartBaseRootModule],
  providers: [
    provideConfig({
      featureModules: {
        [CART_BASE_FEATURE]: {
          module: () =>
            import('src/feature-libs/kn-br-cart/base/kn-br-cart-base.module').then((m) => m.KnBrCartBaseModule),
        },
      },
    }),
    provideConfig({
      featureModules: {
        [MINI_CART_FEATURE]: {
          module: () => import('src/feature-libs/kn-br-cart/base/components/kn-br-mini-cart/kn-br-mini-cart.module').then((m) => m.KnBrMiniCartModule),
        },
      },
    }),
    provideConfig({
      featureModules: {
        [ADD_TO_CART_FEATURE]: {
          module: () =>
            import('src/feature-libs/kn-br-product/components/kn-br-add-to-cart/kn-br-add-to-cart.module').then(
              (m) => m.KnBrAddToCartModule
            ),
        },
      },
    }),
    provideConfig({
      featureModules: {
        [ADD_TO_QUOTE_FEATURE]: {
          module: () =>
            import('src/feature-libs/kn-br-product/components/kn-br-add-to-quote/kn-br-add-to-quote.module').then(
              (m) => m.KnBrAddToQuoteModule
            ),
        },
      },
    }),
    provideConfig({
      i18n: {
        resources: cartBaseTranslations,
        chunks: cartBaseTranslationChunksConfig,
        fallbackLang: 'en',
      },
    }),
    provideConfig(<I18nConfig>{
      i18n: {
        resources: KnBrCartBaseTranslations,
        chunks: KnBrCartBaseTranslationChunksConfig,
        fallbackLang: 'en',
      },
    }),
  ],
})
export class CartBaseFeatureModule { }
