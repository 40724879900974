import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import {
  SloanFactSheetActions,
  SloanFactSheetActionTypes,
  SloanFactSheetDetailsSuccess,
  SloanFactSheetDetailsFailure,
} from './sloan-fact-sheet.action';
import { SloanFactSheetAdapter } from './sloan-fact-sheet.adapter';

@Injectable()
export class SloanFactSheetEffects {
  constructor(private actions$: Actions<SloanFactSheetActions>, private factSheetAdapter: SloanFactSheetAdapter) { }

  getFactSheetDetails$ = createEffect(() => this.actions$.pipe(
    ofType(SloanFactSheetActionTypes.SloanFactSheetDetailsAction),
    mergeMap((action) =>
      this.factSheetAdapter.getFactSheetDetails().pipe(
        map((data: any) => new SloanFactSheetDetailsSuccess(data)),
        catchError((error: Error) => {
          return of(new SloanFactSheetDetailsFailure(error));
        })
      )
    )
  )
  );
}
