import { KnBrDraftOrderCommentsActions, KnBrDraftOrderCommentsActionTypes } from '../actions/kn-br-draft-orders-comments.action';
import { KnBrDraftOrdersCommentsState } from '../../../../../../app/store/kn-br-draft-order-comments/kn-br-draft-orders-comments.state';

export const initialState: KnBrDraftOrdersCommentsState = {
  DraftOrderComments: [],
  errors: undefined,
  DraftOrderComment: undefined,
};

export function knBrDraftOrdersCommentsReducer(
  state = initialState,
  action: KnBrDraftOrderCommentsActions
): KnBrDraftOrdersCommentsState {
  switch (action.type) {
    case KnBrDraftOrderCommentsActionTypes.KnBrDraftOrderCommentsCreateAction:
      return { ...state, DraftOrderComment: action.payload };
    case KnBrDraftOrderCommentsActionTypes.KnBrDraftOrderCommentsCreateSuccessAction:
      return { ...state, DraftOrderComment: action.payload };
    case KnBrDraftOrderCommentsActionTypes.KnBrDraftOrderCommentsCreateFailureAction:
      return { ...state, errors: action.payload };
    case KnBrDraftOrderCommentsActionTypes.KnBrDraftOrderCommentsLoadAction:
      return { ...state, DraftOrderComments: state.DraftOrderComments };
    case KnBrDraftOrderCommentsActionTypes.KnBrDraftOrderCommentsLoadSuccessAction:
      return { ...state, DraftOrderComments: action.payload };
    case KnBrDraftOrderCommentsActionTypes.KnBrDraftOrderCommentsLoadFailureAction:
      return { ...state, errors: action.payload };
    case KnBrDraftOrderCommentsActionTypes.KnBrDraftOrderCommentsDeleteAction:
      return { ...state, DraftOrderComment: action.payload };
    default:
      return state;
  }
}
