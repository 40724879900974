import { Component, OnInit } from '@angular/core';
import { CmsBannerComponent } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { KnBrBannerComponent } from 'src/app/common/kn-br-banner/kn-br-banner.component';

@Component({
  selector: 'kn-br-banner',
  templateUrl: './sloan-banner.component.html',
  styleUrls: ['./sloan-banner.component.scss'],
})
export class SloanBannerComponent extends KnBrBannerComponent implements OnInit {
  constructor(protected componentData: CmsComponentData<CmsBannerComponent>) {
    super(componentData);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  getContent(data: any): string {
    const contentArray = data && data.content ? data.content?.split('|') : [];
    return contentArray.length ? contentArray[0] : '';
  }

  getButtonText(data: any): string {
    const contentArray = data && data.content ? data.content?.split('|') : [];
    return contentArray.length > 1 ? contentArray[1] : '';
  }
}
