import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { RoutingService, WindowRef } from '@spartacus/core';
import { UserAccountService } from '@spartacus/user/account/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import * as _ from 'lodash';
import { KnBrConfirmationDialogService } from 'src/app/common/kn-br-confirm-dialog/kn-br-confirm-dialog.service';
import { KnBrCartQuoteSummaryService } from 'src/app/services/kn-br-cart-quote.service';
import { KnBrCustomerContextService } from 'src/app/store/kn-br-customer-context/kn-br-customer-context.service';
import { KnBrCartContextService } from 'src/feature-libs/kn-br-cart/base/core/facade/kn-br-cart-context.service';
import { KnBrDraftSearchResultComponent } from 'src/feature-libs/kn-br-cart/draft-order/components/kn-br-draft-order/kn-br-draft-order-list/kn-br-draft-search-result/kn-br-draft-search-result.component';
import { KnBrDraftOrdersService } from 'src/feature-libs/kn-br-cart/draft-order/core/facade/kn-br-draft-orders.service';

@Component({
  selector: 'sloan-draft-search-result',
  templateUrl: './sloan-draft-search-result.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SloanDraftSearchResultComponent extends KnBrDraftSearchResultComponent implements OnInit, OnDestroy {
  constructor(
    protected currentUserService: UserAccountFacade,
    public knBrDraftSearchService: KnBrDraftOrdersService,
    protected routeService: RoutingService,
    protected confirmationDialogService: KnBrConfirmationDialogService,
    protected winRef: WindowRef,
    protected kBrCartContextService: KnBrCartContextService,
    protected knBrCustomerContextService: KnBrCustomerContextService,
    protected knBrCartQuoteSummaryService: KnBrCartQuoteSummaryService,
    protected cdr: ChangeDetectorRef
  ) {
    super(
      currentUserService,
      knBrDraftSearchService,
      routeService,
      confirmationDialogService,
      winRef,
      kBrCartContextService,
      knBrCustomerContextService,
      knBrCartQuoteSummaryService,
      cdr
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
