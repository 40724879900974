import { Component, OnInit } from '@angular/core';
import { AsmService } from '@spartacus/asm/core';
import { KnBrAsmToggleUiComponent } from 'src/feature-libs/kn-br-asm/components/kn-br-asm-toggle-ui/kn-br-asm-toggle-ui.component';

@Component({
  selector: 'cx-asm-toggle-ui',
  templateUrl: './sloan-asm-toggle-ui.component.html',
  styleUrls: ['./sloan-asm-toggle-ui.component.scss'],
})
export class SloanAsmToggleUiComponent extends KnBrAsmToggleUiComponent implements OnInit {
  constructor(protected asmService: AsmService) {
    super(asmService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
}
