<div class="col-md-12 p-0 float-left mt-3 d-md-flex align-items-center">
  <div class="col-md-6 p-0 float-left">{{ 'quickOrder.label.header1' | cxTranslate }}</div>
  <div class="col-md-6 p-0 float-left d-md-flex justify-content-end">
    <div class="col-md-4 mr-3 pl-0 pr-0 pt-1">
      <button type="button" (click)="resetForm()" class="btn btn-outline-primary col-md-12">
        {{ 'quickOrder.label.resetForm' | cxTranslate }}
      </button>
    </div>

    <div class="col-md-4 mr-3 pl-0 pr-0 pt-1" *ngIf="customerContext && (cartContext || !quoteContext)">
      <kn-br-add-to-cart [type]="'QUICK'" [disableAddToCart]="showMOQWarningMsg" [productList]="allAddedProducts">
      </kn-br-add-to-cart>
    </div>
    <div class="col-md-4 pl-0 pr-0 pt-1" *ngIf="customerContext && (!cartContext || quoteContext)">
      <kn-br-add-to-quote [type]="'QUICK'" [disableAddToQuote]="showMOQWarningMsg" [productList]="allAddedProducts">
      </kn-br-add-to-quote>
    </div>
  </div>
</div>
<div *ngIf="showMOQWarningMsg" class="col-md-12 p-0 float-left mt-3 d-md-flex align-items-center">
  <span class="font-size text-danger">
    {{ 'knbrOrderDetails.addProducts.moqWarningMsg' | cxTranslate }}
  </span>
</div>
<div class="customersearch-list-table border-bottom mt-3 float-left col-lg-12 p-0">
  <div class="customersearch-list-titles border-top float-left w-100 pl-2 pr-2 pt-3 pb-3 d-none d-sm-none d-lg-block">
    <div class="col-md-1 pl-0 text-center float-left font-text-600 font-sm-size">
      {{ 'knbrOrderDetails.addProducts.lineNo' | cxTranslate }}
    </div>
    <div class="col-md-1 pl-0 text-center float-left font-text-600 font-sm-size">
      {{ 'knbrOrderDetails.addProducts.product' | cxTranslate }}
    </div>
    <div class="col-md-2 pl-0 text-center float-left font-text-600 font-sm-size">
      {{ 'knbrOrderDetails.addProducts.itemDetails' | cxTranslate }}
    </div>
    <div class="col-md-3 text-center float-left font-text-600 font-sm-size">
      {{ 'quickOrder.label.availability' | cxTranslate }}
    </div>
    <div class="col-md-1 pl-0 text-center float-left font-text-600 font-sm-size">
      {{ 'knbrOrderDetails.addProducts.listPrice' | cxTranslate }}
    </div>
    <div class="col-md-1 pl-0 text-center float-left font-text-600 font-sm-size">
      {{ 'quickOrder.label.netPrice' | cxTranslate }}
    </div>
    <div class="col-md-1 pl-0 text-center float-left font-text-600 font-sm-size">
      {{ 'knbrOrderDetails.addProducts.qty' | cxTranslate }}
    </div>
    <div class="col-md-1 pl-0 text-center float-left font-text-600 font-sm-size">
      {{ 'knbrOrderDetails.addProducts.total' | cxTranslate }}
    </div>
    <div class="col-md-1 pl-0 text-center float-left font-text-600 font-sm-size">
      {{ 'quickOrder.label.minAdvPrice' | cxTranslate }}
    </div>
  </div>
</div>

<form [formGroup]="addProductsForm">
  <ng-container *ngFor="let product of t.controls; let i = index">
    <div
      [formGroup]="product"
      class="bg-white border-bottom float-left w-100 pl-2 pr-2 pt-3 pb-3 font-weight-normal"
      *ngIf="!product.controls.showForm.value"
    >
      <div class="col-md-1 p-0 pt-3 text-center float-left font-size color-font">
        {{
          cartContext
            ? ((cartItems$ | async) + (i + 1)) * 10
            : quoteContext
            ? ((quoteItems$ | async) + (i + 1)) * 10
            : (i + 1) * 10
        }}
      </div>
      <div class="col-md-10 p-0 float-left font-size color-font">
        <input
          type="text"
          placeholder="{{ 'knbrOrderDetails.addProducts.skuplaceholder' | cxTranslate }}"
          formControlName="productCode"
          (focus)="onEnterInput($event, i)"
          (blur)="searchForProduct(i)"
          (keyup.enter)="searchForProduct(i)"
          class="form-control mb-0"
          [ngClass]="{ 'is-invalid': submitted && addProductsForm.controls.productCode.errors }"
        />
        <span *ngIf="product.controls.showErrorMsg.value" class="text-danger">{{
          product.controls.errorMsg.value
            ? product.controls.errorMsg.value
            : ('knbrOrderDetails.addProducts.priceWarnmsg' | cxTranslate)
        }}</span>
      </div>
      <div class="col-md-1 float-left title-wrap font-size color-font">
        <button
          type="button"
          tabindex="-1"
          (click)="removeProduct(i)"
          class="btn reset pl-4 pr-4 mr-3 pb-1 pt-1 font-lg-size mr-left-auto"
        >
          <i class="fas fa-times-circle" aria-hidden="true"></i>
        </button>
      </div>
    </div>
    <div [formGroup]="product" *ngIf="product.controls.showForm.value">
      <div
        class="bg-white border-bottom float-left w-100 pl-2 pr-2 pt-3 pb-3 font-weight-normal d-flex align-items-center"
      >
        <div class="col-md-1 pl-0 pt-3 text-center float-left font-size color-font">
          {{
            cartContext
              ? ((cartItems$ | async) + (i + 1)) * 10
              : quoteContext
              ? ((quoteItems$ | async) + (i + 1)) * 10
              : (i + 1) * 10
          }}
        </div>
        <div class="padding-r-5 col-md-1 pl-0 text-center float-left font-size color-font">
          <input
            type="text"
            (blur)="searchForProduct(i)"
            (focus)="onEnterInput($event, i)"
            (keyup.enter)="searchForProduct(i)"
            formControlName="productCode"
            class="form-control padding-8"
            [ngClass]="{ 'is-invalid': submitted && product.controls.productCode.errors }"
          />
        </div>
        <div class="col-md-2 pl-0 text-center title-wrap padding-res-0 float-left font-size color-font">
          <div class="col-md-4 p-0 float-left">
            <cx-media [container]="product.controls.image.value.images" format="product"></cx-media>
          </div>
          <div class="col-md-8 pl-2 pr-0 float-left">
            <a
              class="w-100 float-left text-left"
              [routerLink]="{ cxRoute: 'product', params: product.controls.image.value } | cxUrl"
              >{{ product.controls.image.value.name }}</a
            >
            <span class="w-100 float-left text-left">
              {{ product.controls.image.value.code }}
            </span>
          </div>
        </div>
        <div class="col-md-3 text-center title-wrap padding-res-0 float-left font-size color-font">
          <span class="res-titles"> {{ 'quickOrder.label.availability' | cxTranslate }}</span>
          <div class="col-12 float-left border-bottom pb-1 d-flex align-items-end">
            <div class="col-4 pl-0 float-left font-sm-size">{{ 'quickOrder.label.plant' | cxTranslate }}</div>
            <div class="col-4 pl-0 float-left font-sm-size">{{ 'quickOrder.label.inventory' | cxTranslate }}</div>
            <div class="col-4 pl-0 float-left font-sm-size">{{ 'quickOrder.label.estShipDate' | cxTranslate }}</div>
          </div>
          <div *ngFor="let syncItem of product.controls?.syncResponse?.value?.items" class="col-12 float-left pt-1">
            <div class="col-12 pt-1 pb-1 pl-0 pr-0 float-left border-bottom" *ngFor="let innerItem of syncItem?.items">
              <div class="col-4 pl-0 float-left font-sm-size">
                {{ innerItem.plantDescription ? innerItem.plantDescription : innerItem.plant }}
              </div>
              <div class="col-4 pl-0 float-left font-sm-size">{{ innerItem.distAvailability }}</div>
              <div class="col-4 pl-0 pr-0 float-left font-sm-size">{{ innerItem.shipDate }}</div>
            </div>
          </div>
        </div>
        <div class="col-md-1 pl-0 text-center float-left title-wrap font-size color-font">
          <span class="res-titles"> {{ 'knbrOrderDetails.addProducts.listPrice' | cxTranslate }}</span>
          {{ product.controls.listPrice.value }}
        </div>
        <div class="col-md-1 pl-0 text-center float-left title-wrap font-size color-font">
          <span class="res-titles"> {{ 'quickOrder.label.netPrice' | cxTranslate }}</span>
          {{ product.controls.netPrice.value }}
        </div>
        <div class="col-md-1 pl-0 text-center float-left font-size color-font">
          <input
            type="text"
            #qty
            formControlName="qty"
            class="form-control mb-0"
            knbrAllowNumber
            (change)="onQuantityChange(i)"
            [ngClass]="{ 'is-invalid': submitted && product.controls.qty.errors }"
          />
          <span class="font-sm-size">
            {{ 'knbrOrderDetails.addProducts.minorderQty' | cxTranslate }}
            {{ product.controls.image.value.minOrderQuantity }}</span
          >
        </div>
        <div class="col-md-1 pl-0 text-center float-left title-wrap font-size color-font">
          <span class="res-titles"> {{ 'knbrOrderDetails.addProducts.total' | cxTranslate }}</span>
          {{ product.controls.total.value * product.controls.qty.value | number: '1.2-2' }}
        </div>
        <div class="row col-md-1 pl-0 text-center float-left title-wrap font-size color-font">
          <div class="col-md-9 p-0 float-left">
            <span class="res-titles"> {{ 'quickOrder.label.minAdvPrice' | cxTranslate }}</span>
            {{ product.controls.image.value?.mapPrice }}
          </div>
          <div class="col-md-3 p-0 float-left">
            <button
              type="button"
              class="btn reset pl-4 pr-4 mr-3 pb-1 pt-1 font-lg-size mr-left-auto"
              tabindex="-1"
              (click)="removeProduct(i)"
            >
              <i class="fas fa-times-circle" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</form>
<div class="col-md-12 p-0 float-left mt-3">
  <div class="col-md-6 p-0 float-right d-md-flex justify-content-end">
    <div class="col-md-4 mr-3 pl-0 pr-0 pt-1">
      <button type="button" (click)="resetForm()" class="btn btn-outline-primary col-md-12">
        {{ 'quickOrder.label.resetForm' | cxTranslate }}
      </button>
    </div>

    <div class="col-md-4 mr-3 pl-0 pr-0 pt-1" *ngIf="customerContext && (cartContext || !quoteContext)">
      <kn-br-add-to-cart [type]="'QUICK'" [productList]="allAddedProducts" [disableAddToCart]="showMOQWarningMsg">
      </kn-br-add-to-cart>
    </div>
    <div class="col-md-4 pl-0 pr-0 pt-1" *ngIf="customerContext && (!cartContext || quoteContext)">
      <kn-br-add-to-quote [type]="'QUICK'" [productList]="allAddedProducts" [disableAddToQuote]="showMOQWarningMsg">
      </kn-br-add-to-quote>
    </div>
  </div>
</div>
