<div class="col-md-12 float-left customersearch-forms login-forms p-0 customer-home-forms">
  <form [formGroup]="orderHistorySearchForm" (ngSubmit)="submitForm()">
    <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3 p-res-0">
      <input
        type="text"
        class="form-control w-100"
        [placeholder]="'orderHistorySearchForm.label.salesOrderNumber' | cxTranslate"
        formControlName="salesOrderNumber"
      />
      <span class="highlight"></span>
      <label class="text-muted">{{ 'orderHistorySearchForm.label.salesOrderNumber' | cxTranslate }}</label>
    </div>

    <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3 p-res-0">
      <input
        type="text"
        class="form-control w-100"
        [placeholder]="'orderHistorySearchForm.label.accountNumber' | cxTranslate"
        formControlName="accountNumber"
      />
      <span class="highlight"></span>
      <label class="text-muted">{{ 'orderHistorySearchForm.label.accountNumber' | cxTranslate }}</label>
    </div>

    <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3 p-res-0">
      <input
        type="text"
        class="form-control w-100"
        [placeholder]="'orderHistorySearchForm.label.accountName' | cxTranslate"
        formControlName="accountName"
      />
      <span class="highlight"></span>
      <label class="text-muted">{{ 'orderHistorySearchForm.label.accountName' | cxTranslate }}</label>
    </div>

    <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3 p-res-0">
      <input
        type="text"
        class="form-control w-100"
        [placeholder]="'orderHistorySearchForm.label.purchaseOrderNumber' | cxTranslate"
        formControlName="purchaseOrderNumber"
        knBrAlphaNumericOnly
        maxlength="32"
      />
      <span class="highlight"></span>
      <label class="text-muted">{{ 'orderHistorySearchForm.label.purchaseOrderNumber' | cxTranslate }}</label>
    </div>

    <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3 p-res-0">
      <ng-select
        class="mb-1"
        formControlName="orderType"
        bindLabel="Text"
        bindValue="Key"
        [items]="orderTypeList$ | async"
        [placeholder]="'orderHistorySearchForm.label.orderType' | cxTranslate"
        [searchable]="false"
        [clearable]="false"
      >
      </ng-select>
      <span class="highlight"></span>
      <label class="text-muted">{{ 'orderHistorySearchForm.label.orderType' | cxTranslate }}</label>
    </div>

    <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3 p-res-0">
      <ng-select
        class="mb-1"
        formControlName="creditStatus"
        bindLabel="Text"
        bindValue="Key"
        [items]="creditStatusList$ | async"
        [placeholder]="'orderHistorySearchForm.label.creditStatus' | cxTranslate"
        [searchable]="false"
        [clearable]="false"
      >
      </ng-select>
      <span class="highlight"></span>
      <label class="text-muted">{{ 'orderHistorySearchForm.label.creditStatus' | cxTranslate }}</label>
    </div>

    <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3 p-res-0">
      <ng-select
        class="mb-1"
        formControlName="deliveryBlock"
        bindLabel="Text"
        bindValue="Key"
        [items]="deliveryBlockList$ | async"
        [placeholder]="'orderHistorySearchForm.label.deliveryBlock' | cxTranslate"
        [searchable]="false"
        [clearable]="false"
      >
      </ng-select>
      <span class="highlight"></span>
      <label class="text-muted">{{ 'orderHistorySearchForm.label.deliveryBlock' | cxTranslate }}</label>
    </div>

    <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3 p-res-0">
      <ng-select
        class="mb-1"
        formControlName="status"
        bindLabel="Text"
        bindValue="Key"
        [items]="orderStatusList$ | async"
        [placeholder]="'orderHistorySearchForm.label.status' | cxTranslate"
        [searchable]="false"
        [clearable]="false"
      >
      </ng-select>
      <span class="highlight"></span>
      <label class="text-muted">{{ 'orderHistorySearchForm.label.status' | cxTranslate }}</label>
    </div>

    <!-- <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3 p-res-0">
      <ng-select
        class="mb-1"
        formControlName="pastDue"
        bindLabel="Text"
        bindValue="Key"
        [items]="pastDueList"
        [placeholder]="'orderHistorySearchForm.label.pastDue' | cxTranslate"
        [searchable]="false"
        [clearable]="false"
      >
      </ng-select>
      <span class="highlight"></span>
      <label class="text-muted">{{ 'orderHistorySearchForm.label.pastDue' | cxTranslate }}</label>
    </div> -->

    <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3 p-res-0">
      <input
        type="text"
        class="form-control w-100"
        [placeholder]="'orderHistorySearchForm.label.productNumber' | cxTranslate"
        formControlName="productNumber"
      />
      <span class="highlight"></span>
      <label class="text-muted">{{ 'orderHistorySearchForm.label.productNumber' | cxTranslate }}</label>
    </div>

    <div class="col-lg-12 p-0 float-left">
      <button type="submit" [disabled]="isSearchDisabled$ | async" class="btn btn-primary text-uppercase col-md-2 mr-3">
        {{ 'button.search' | cxTranslate }}
      </button>
      <button type="button" (click)="resetForm()" class="btn btn-outline-primary text-uppercase col-md-2">
        {{ 'button.reset' | cxTranslate }}
      </button>
      <!-- <div class="col-md-2 p-0 float-right">
        <cx-page-slot position="KnBrCustomerListActionBarSlot"></cx-page-slot>
      </div> -->
    </div>
  </form>
</div>
