/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, I18nModule, provideConfig, provideDefaultConfig } from '@spartacus/core';
import { CardModule, SpinnerModule } from '@spartacus/storefront';
import { KnBrCheckoutPaymentFormModule } from './kn-br-checkout-payment-form/kn-br-checkout-payment-form.module';
import { KnBrCheckoutPaymentMethodComponent } from './kn-br-checkout-payment-method.component';
import { CheckoutAuthGuard, CartNotEmptyGuard } from '@spartacus/checkout/base/components';

@NgModule({
  imports: [CommonModule, RouterModule, KnBrCheckoutPaymentFormModule, CardModule, SpinnerModule, I18nModule],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        CheckoutPaymentDetails: {
          component: KnBrCheckoutPaymentMethodComponent,
          guards: [CheckoutAuthGuard, CartNotEmptyGuard],
        },
      },
    }),
  ],
  declarations: [KnBrCheckoutPaymentMethodComponent],
  exports: [KnBrCheckoutPaymentMethodComponent],
})
export class KnBrCheckoutPaymentMethodModule {}
