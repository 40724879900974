import { Action } from '@ngrx/store';
import { KnBrDraftOrderComments } from '../../../root/models/kn-br-draft-order.model';

export enum KnBrDraftOrderCommentsActionTypes {
  KnBrDraftOrderCommentsUpdateAction = '[DraftOrderComments] Update',
  KnBrDraftOrderCommentsUpdateSuccessAction = '[DraftOrderComments] Update Success',
  KnBrDraftOrderCommentsUpdateFailureAction = '[DraftOrderComments] Update Failure',
  KnBrDraftOrderCommentsCreateAction = '[DraftOrderComments] Create',
  KnBrDraftOrderCommentsCreateSuccessAction = '[DraftOrderComments] Create Success',
  KnBrDraftOrderCommentsCreateFailureAction = '[DraftOrderComments] Create Failure',
  KnBrDraftOrderCommentsLoadAction = '[DraftOrderComments] Load DraftOrderComments',
  KnBrDraftOrderCommentsLoadFailureAction = '[DraftOrderComments] Load DraftOrderComments Fail',
  KnBrDraftOrderCommentsLoadSuccessAction = '[DraftOrderComments] Load DraftOrderComments Success',
  KnBrDraftOrderCommentsDeleteAction = '[DraftOrderComments] Delete',
}

/**
 * Action dispatched on DraftOrderComments Update
 */
export class KnBrDraftOrderCommentsUpdate implements Action {
  readonly type = KnBrDraftOrderCommentsActionTypes.KnBrDraftOrderCommentsUpdateAction;
  constructor(public payload: any) { }
}

/**
 * Action dispatched on DraftOrderComments Update Success
 */
export class KnBrDraftOrderCommentsUpdateSuccess implements Action {
  readonly type = KnBrDraftOrderCommentsActionTypes.KnBrDraftOrderCommentsUpdateSuccessAction;
  constructor(public payload: any) { }
}

/**
 * Action dispatched on DraftOrderComments Update Failure
 */
export class KnBrDraftOrderCommentsUpdateFailure implements Action {
  readonly type = KnBrDraftOrderCommentsActionTypes.KnBrDraftOrderCommentsUpdateFailureAction;
  constructor(public payload: Error) { }
}

/**
 * Action dispatched on DraftOrderComments Create
 */
export class KnBrDraftOrderCommentsCreate implements Action {
  readonly type = KnBrDraftOrderCommentsActionTypes.KnBrDraftOrderCommentsCreateAction;
  constructor(public payload: any) { }
}

/**
 * Action dispatched on DraftOrderComments Create Success
 */
export class KnBrDraftOrderCommentsCreateSuccess implements Action {
  readonly type = KnBrDraftOrderCommentsActionTypes.KnBrDraftOrderCommentsCreateSuccessAction;
  constructor(public payload: any) { }
}

/**
 * Action dispatched on DraftOrderComments Create Failure
 */
export class KnBrDraftOrderCommentsCreateFailure implements Action {
  readonly type = KnBrDraftOrderCommentsActionTypes.KnBrDraftOrderCommentsCreateFailureAction;
  constructor(public payload: Error) { }
}

/**
 * Action dispatched on DraftOrderComments Load
 */
export class KnBrDraftOrderCommentsLoad implements Action {
  readonly type = KnBrDraftOrderCommentsActionTypes.KnBrDraftOrderCommentsLoadAction;
  constructor(public entryNumber: number) { }
}

/**
 * Action dispatched on DraftOrderComments Load Success
 */
export class KnBrDraftOrderCommentsLoadSuccess implements Action {
  readonly type = KnBrDraftOrderCommentsActionTypes.KnBrDraftOrderCommentsLoadSuccessAction;
  constructor(public payload: any) { }
}

/**
 * Action dispatched on DraftOrderComments Load Failure
 */
export class KnBrDraftOrderCommentsLoadFailure implements Action {
  readonly type = KnBrDraftOrderCommentsActionTypes.KnBrDraftOrderCommentsLoadFailureAction;
  constructor(public payload: Error) { }
}

export class KnBrDraftOrderCommentsDelete implements Action {
  readonly type = KnBrDraftOrderCommentsActionTypes.KnBrDraftOrderCommentsDeleteAction;
  constructor(public payload: KnBrDraftOrderComments) { }
}
export type KnBrDraftOrderCommentsActions =
  | KnBrDraftOrderCommentsUpdate
  | KnBrDraftOrderCommentsUpdateSuccess
  | KnBrDraftOrderCommentsUpdateFailure
  | KnBrDraftOrderCommentsCreate
  | KnBrDraftOrderCommentsCreateSuccess
  | KnBrDraftOrderCommentsCreateFailure
  | KnBrDraftOrderCommentsLoad
  | KnBrDraftOrderCommentsLoadSuccess
  | KnBrDraftOrderCommentsLoadFailure
  | KnBrDraftOrderCommentsDelete;
