<ng-container *ngIf="componentData$ | async as data">
  <div class="col-md-12 color-knbr-footer p-3 float-left">
    <div class="container-fluid">
      <div class="knbr-footerTop">
        <div class="row">
          <ng-container *ngIf="node$ | async as node">
            <div class="footer-left flex-wrap col-xs-12 col-sm-12 col-md-9 d-flex p-res-0">
              <ng-container *ngFor="let child of node?.children">
                <ng-container *ngTemplateOutlet="nav; context: { node: child, depth: 0 }"> </ng-container>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <ng-template #nav let-node="node" let-depth="depth">
    <div class="footer-nav col-xs-12 col-sm-2 p-res-0">
      <ul class="list-unstyled m-0 p-0">
        <cx-generic-link
          *ngIf="node.url && (!node.children || node.children?.length === 0)"
          [url]="node.url"
          [target]="node.target"
          [class]="'w-100 p-1 float-left color-white font-text-normal font-size hover-link'"
        >
          {{ node.title }}
        </cx-generic-link>
        <ng-container *ngIf="node?.children?.length > 0">
          <li class="w-100" *ngFor="let innerNode of node?.children">
            <cx-generic-link
              *ngIf="innerNode.url"
              [url]="innerNode.url"
              [target]="innerNode.target"
              [class]="'w-100 p-1 float-left color-white font-text-normal font-size hover-link'"
            >
              {{ innerNode.title }}
            </cx-generic-link>
          </li>
        </ng-container>
      </ul>
    </div>
  </ng-template>
  <div class="knbr-footer-bottom bg-white p-2 d-flex justify-content-center float-left w-100">
    <div class="footer-copyright color-font font-size">
      <div class="container">
        {{ data.notice }}
      </div>
    </div>
  </div>
</ng-container>
