import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';
import { RemoveLeadingZerosPipe } from 'src/app/shared/pipe/kn-br-custom-pipes/remove-leading-zeros.pipe';
import { KnBrCustomerContextService } from 'src/app/store/kn-br-customer-context/kn-br-customer-context.service';
import { KnBrDraftOrderSearchComponent } from 'src/feature-libs/kn-br-cart/draft-order/components/kn-br-draft-order/kn-br-draft-order-list/kn-br-draft-order-search/kn-br-draft-order-search.component';
import { KnBrDraftOrdersService } from 'src/feature-libs/kn-br-cart/draft-order/core/facade/kn-br-draft-orders.service';

@Component({
  selector: 'sloan-draft-order-search',
  templateUrl: './sloan-draft-order-search.component.html',
  styleUrls: ['./sloan-draft-order-search.component.scss'],
  providers: [RemoveLeadingZerosPipe],
})
export class SloanDraftOrderSearchComponent extends KnBrDraftOrderSearchComponent implements OnInit, OnDestroy {
  constructor(
    protected fb: FormBuilder,
    protected knBrDraftSearchService: KnBrDraftOrdersService,
    protected knBrCustomerContextService: KnBrCustomerContextService,
    protected knBrCommonService: KnBrCommonService,
    protected removeLeadingZerosPipe: RemoveLeadingZerosPipe
  ) {
    super(fb, knBrDraftSearchService, knBrCustomerContextService, knBrCommonService, removeLeadingZerosPipe);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  initForm() {
    const poNumPattern = '[^*^~|<>]*';
    this.draftSearchForm = this.fb.group({
      // draftOrderName: null,
      draftOrderNumber: null,
      accountNumber: [
        {
          value:
            this.selectedCustomer && this.selectedCustomer.customerNumber
              ? this.removeLeadingZerosPipe.transform(this.selectedCustomer.customerNumber)
              : null,
          disabled: this.selectedCustomer && this.selectedCustomer.customerNumber ? true : false,
        },
      ],
      accountName: [
        {
          value:
            this.selectedCustomer && this.selectedCustomer.customerName ? this.selectedCustomer.customerName : null,
          disabled: this.selectedCustomer && this.selectedCustomer.customerName ? true : false,
        },
      ],
      purchaseOrderNumber: [null, Validators.pattern[poNumPattern]],
      jobName: null,
      status: null,
      currentPage: this.currentPage,
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
