import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { KnBrQuoteLoad } from '../actions/kn-br-quotes.action';
import {
  KnBrQuoteCommentsActions,
  KnBrQuoteCommentsActionTypes,
  KnBrQuoteCommentsCreateFailure,
  KnBrQuoteCommentsLoad,
  KnBrQuoteCommentsLoadFailure,
  KnBrQuoteCommentsLoadSuccess,
  KnBrQuoteCommentsUpdateFailure,
} from '../actions/kn-br-quotes-comments.action';
import { KnBrQuotesCommentsAdapter } from '../../connectors/kn-br-quotes-comments.adapter';

@Injectable()
export class KnBrQuotesCommentsEffects {

  loadQuote$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrQuoteCommentsActionTypes.KnBrQuoteCommentsLoadAction),
    mergeMap((action) =>
      this.quotesCommentsAdapter.get(action.entryNumber).pipe(
        map((data: any) => {
          return new KnBrQuoteCommentsLoadSuccess(data);
        }),
        catchError((error: Error) => {
          return of(new KnBrQuoteCommentsLoadFailure(error));
        })
      )
    )
  )
  );

  createQuoteComment$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrQuoteCommentsActionTypes.KnBrQuoteCommentsCreateAction),
    mergeMap((action) =>
      this.quotesCommentsAdapter.create(action.payload).pipe(
        mergeMap((data: any) => [
          new KnBrQuoteCommentsLoad(action.payload.entryNumber),
          new KnBrQuoteLoad(null, false),
        ]),
        catchError((error: Error) => {
          return of(new KnBrQuoteCommentsCreateFailure(error));
        })
      )
    )
  )
  );

  createSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrQuoteCommentsActionTypes.KnBrQuoteCommentsCreateSuccessAction),
    map((action) => new KnBrQuoteCommentsLoad(0))
  ),
    { dispatch: false }
  );

  createFailure$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrQuoteCommentsActionTypes.KnBrQuoteCommentsCreateFailureAction),
    map((action) => {
      //Todo
      return;
    })
  ),
    { dispatch: false }
  );

  updateQuoteComment$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrQuoteCommentsActionTypes.KnBrQuoteCommentsUpdateAction),
    mergeMap((action) =>
      this.quotesCommentsAdapter.update(action.payload).pipe(
        map((data: any) => new KnBrQuoteCommentsLoad(action.payload.entryNumber)),
        catchError((error: Error) => {
          return of(new KnBrQuoteCommentsUpdateFailure(error));
        })
      )
    )
  )
  );

  deleteQuoteComment$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrQuoteCommentsActionTypes.KnBrQuoteCommentsDeleteAction),
    mergeMap((action) =>
      this.quotesCommentsAdapter.delete(action.payload).pipe(
        mergeMap((data: any) => [
          new KnBrQuoteCommentsLoad(action.payload.entryNumber),
          new KnBrQuoteLoad(null, false),
        ]),
        catchError((error: Error) => {
          return of(new KnBrQuoteCommentsUpdateFailure(error));
        })
      )
    )
  )
  );

  constructor(
    private actions$: Actions<KnBrQuoteCommentsActions>,
    private quotesCommentsAdapter: KnBrQuotesCommentsAdapter
  ) { }
}
