import { DIALOG_TYPE } from '@spartacus/storefront';
import { SpinnerComponent } from '@spartacus/storefront';
import { LayoutConfig } from '@spartacus/storefront';


export const defaultSpinnerLayoutConfig: LayoutConfig = {
  launch: {
    PLACE_ORDER_SPINNER: {
      inline: true,
      component: SpinnerComponent,
      dialogType: DIALOG_TYPE.POPOVER_CENTER_BACKDROP,
    },
  },
};
