<section class="col-md-12 p-0 login-wrapper d-flex justify-content-center align-items-center">
  <div class="container fluid p-0">
    <div class="col-md-12 float-left knbr-login-forms mt-4 d-flex justify-content-center p-0">
      <div class="col-lg-5 col-sm-6 login-forms p-4 login-section">
        <div class="col-md-12 p-0 float-left">
          <cx-page-slot position="SiteLogo"></cx-page-slot>
        </div>
        <div class="col-md-12 pt-5 pb-5 d-flex justify-content-center">
          <div class="alert-info p-3 rounded-0 mt-3" *ngIf="successMessage$ | async as successMessage">
            {{ successMessage?.response }}
          </div>
          <div class="alert-danger p-3 rounded-0 mt-3" *ngIf="failureMessage$ | async as failureMessage">
            <i class="fas fa-exclamation-circle" aria-hidden="true"></i>
            {{ 'knBrMessages.approverFailure' | cxTranslate }}
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
