import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { I18nModule, FeaturesConfigModule, ConfigModule, CmsConfig } from '@spartacus/core';
import { SloanOrderOverviewModule } from '../sloan-order-overview/sloan-order-overview.module';
import { SloanOrderConfirmationItemsComponent } from './sloan-order-confirmation-items/sloan-order-confirmation-items.component';
import { SloanOrderConfirmationOverviewComponent } from './sloan-order-confirmation-overview/sloan-order-confirmation-overview.component';
import { SloanOrderConfirmationThankYouMessageComponent } from './sloan-order-confirmation-thank-you-message/sloan-order-confirmation-thank-you-message.component';
import { KnBrCustomPipesModule } from 'src/app/shared/pipe/kn-br-custom-pipes/kn-br-custom-pipes.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OrderConfirmationGuard } from '@spartacus/order/components';
import { CardModule, PwaModule, PromotionsModule, FormErrorsModule } from '@spartacus/storefront';
import { SloanCartSharedModule } from '../../sloan-cart/sloan-cart-shared/sloan-cart-shared.module';

@NgModule({
  imports: [
    CommonModule,
    SloanCartSharedModule,
    CardModule,
    PwaModule,
    PromotionsModule,
    I18nModule,
    NgbModule,
    ReactiveFormsModule,
    FeaturesConfigModule,
    FormErrorsModule,
    SloanOrderOverviewModule,
    KnBrCustomPipesModule,
    ConfigModule.withConfig({
      cmsComponents: {
        OrderConfirmationThankMessageComponent: {
          component: SloanOrderConfirmationThankYouMessageComponent,
          guards: [OrderConfirmationGuard],
        },
        OrderConfirmationOverviewComponent: {
          component: SloanOrderConfirmationOverviewComponent,
          guards: [OrderConfirmationGuard],
        },
        OrderConfirmationItemsComponent: {
          component: SloanOrderConfirmationItemsComponent,
          guards: [OrderConfirmationGuard],
        },
      },
    } as CmsConfig),
  ],
  declarations: [
    SloanOrderConfirmationThankYouMessageComponent,
    SloanOrderConfirmationOverviewComponent,
    SloanOrderConfirmationItemsComponent,
  ],
  exports: [
    SloanOrderConfirmationThankYouMessageComponent,
    SloanOrderConfirmationOverviewComponent,
    SloanOrderConfirmationItemsComponent,
  ],
})
export class SloanOrderConfirmationModule {}
