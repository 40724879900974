import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { I18nModule, RoutingModule, UrlModule } from '@spartacus/core';
import { FormErrorsModule, MediaModule } from '@spartacus/storefront';

import { KnBrQuoteItemComponent } from './kn-br-quote-item.component';
import { KnBrQuotePartialItemModule } from './kn-br-quote-partial-item/kn-br-quote-partial-item.module';
import { KnBrDirectiveModule } from 'src/app/shared/directive/kn-br-directive.module';

@NgModule({
  declarations: [KnBrQuoteItemComponent],
  imports: [
    CommonModule,
    RouterModule,
    UrlModule,
    RoutingModule,
    I18nModule,
    MediaModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    KnBrDirectiveModule,
    FormErrorsModule,
    KnBrQuotePartialItemModule,
  ],
  exports: [KnBrQuoteItemComponent],
})
export class KnBrQuoteItemModule {}
