<form *ngIf="form$ | async as form" [formGroup]="form" (submit)="save(form)">
  <cx-org-card [previous]="false" i18nRoot="orgUser.password" [cxFocus]="{ autofocus: 'input', refreshFocus: form }">
    <button actions class="button primary">
      {{ 'organization.save' | cxTranslate }}
    </button>
    <button actions class="link" routerLink="../" type="button">
      {{ 'organization.cancel' | cxTranslate }}
    </button>

    <section main class="details">
      <label class="full-width">
        <span class="label-content">{{ 'orgUser.password.newPassword' | cxTranslate }}</span>
        <input
          required="true"
          class="form-control"
          type="password"
          name="password"
          placeholder="{{ 'orgUser.password.newPassword' | cxTranslate }}"
          formControlName="password"
          [attr.aria-label]="'orgUser.password.newPassword' | cxTranslate"
          cxPasswordVisibilitySwitch
        />
        <cx-form-errors [control]="form.get('password')"></cx-form-errors>
      </label>
      <label>
        <span class="label-content">{{ 'orgUser.password.confirmPassword' | cxTranslate }}</span>
        <input
          required="true"
          class="form-control"
          type="password"
          name="confirmPassword"
          placeholder="{{ 'orgUser.password.confirmPassword' | cxTranslate }}"
          formControlName="confirmPassword"
          [attr.aria-label]="'orgUser.password.confirmPassword' | cxTranslate"
          cxPasswordVisibilitySwitch
        />
        <cx-form-errors [control]="form.get('confirmPassword')"></cx-form-errors>
      </label>
    </section>
  </cx-org-card>
</form>
