import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  GlobalMessageService,
  GlobalMessageType,
  LoggerService,
  normalizeHttpError,
  RoutingService,
} from '@spartacus/core';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { KnBrQuoteEntry } from '../../../root/models/kn-br-quote.model';
import { KnBrQuoteLoad } from '../actions/kn-br-quotes.action';
import { KnBrQuotesService } from '../../facade/kn-br-quotes.service';
import {
  KnBrQuoteAddEntriesFailure,
  KnBrQuoteAddEntriesSuccess,
  KnBrQuoteAddEntryFailure,
  KnBrQuoteAddEntrySuccess,
  KnBrQuoteEntryAction,
  KnBrQuoteEntryActionTypes,
} from '../actions/kn-br-quote-entry.actions';
import { KnBrQuoteEntryAdapter } from '../../connectors/kn-br-quote-entry.adapter';
import { KnBrDraftOrdersService } from 'src/feature-libs/kn-br-cart/draft-order/core/facade/kn-br-draft-orders.service';

@Injectable()
export class KnBrQuoteEntryEffects {
  protected logger = inject(LoggerService);
  addQuoteEntry$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrQuoteEntryActionTypes.KnBrQuoteAddEntryAction),
    mergeMap((action) =>
      this.knBrQuoteEntryAdapter
        .add(action.payload.customerId, action.payload.quoteId, action.payload.code, action.payload.quantity)
        .pipe(
          mergeMap((data: any) => {
            this.messageService.add({ key: 'addToQuoteMessage.success' }, GlobalMessageType.MSG_TYPE_CONFIRMATION);
            const addedEntries = data;
            if (addedEntries.length > 0) {
              if (addedEntries && addedEntries[0].statusCode !== 'success') {
                this.messageService.add({ raw: addedEntries[0]?.statusMessage }, GlobalMessageType.MSG_TYPE_WARNING);
              }
            }
            return [new KnBrQuoteLoad({ code: action.payload.quoteId }), new KnBrQuoteAddEntrySuccess(data)];
          }),
          catchError((error: Error) => {
            const errorRes = normalizeHttpError(error, this.logger);
            const errors = (errorRes?.details || errorRes?.message || []) as Array<any>;
            if (errors.length > 0 && Array.isArray(errors)) {
              errors.forEach((err) => {
                const type = err.type.toLowerCase();
                if (type.indexOf('warning') !== -1) {
                  this.messageService.add({ raw: err.message }, GlobalMessageType.MSG_TYPE_WARNING);
                } else if (type.indexOf('error') !== -1) {
                  this.messageService.add({ raw: err.message }, GlobalMessageType.MSG_TYPE_ERROR);
                }
              });
            } else {
              this.messageService.add({ key: 'addToQuoteMessage.failure' }, GlobalMessageType.MSG_TYPE_ERROR);
            }
            return of(new KnBrQuoteAddEntryFailure(normalizeHttpError(error, this.logger)));
          })
        )
    )
  )
  );

  removeQuoteEntry$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrQuoteEntryActionTypes.KnBrQuoteRemoveEntryAction),
    mergeMap((action) =>
      this.knBrQuoteEntryAdapter
        .remove(action.payload.customerId, action.payload.quoteId, action.payload.entryNumber)
        .pipe(
          mergeMap((data: KnBrQuoteEntry) => {
            this.knBrQuoteService.setSelectedProducts([]);
            return [new KnBrQuoteLoad({ code: action.payload.quoteId }), new KnBrQuoteAddEntrySuccess(data)];
          }),
          catchError((error: Error) => {
            this.messageService.add({ key: 'knBrMessages.error' }, GlobalMessageType.MSG_TYPE_ERROR);
            return of(new KnBrQuoteAddEntryFailure(normalizeHttpError(error, this.logger)));
          })
        )
    )
  )
  );

  updateQuoteEntry$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrQuoteEntryActionTypes.KnBrQuoteUpdateEntryAction),
    mergeMap((action) =>
      this.knBrQuoteEntryAdapter.update(action.payload).pipe(
        map((data: any) => {
          return new KnBrQuoteLoad({}, false);
        }),
        catchError((error: Error) => {
          this.messageService.add({ key: 'knBrMessages.error' }, GlobalMessageType.MSG_TYPE_ERROR);
          return of(new KnBrQuoteLoad({}, false));
        })
      )
    )
  )
  );

  addEntries$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrQuoteEntryActionTypes.KnBrQuoteAddEntriesAction),
    mergeMap((action) =>
      this.knBrQuoteEntryAdapter.addEntries(action.payload).pipe(
        mergeMap((data: any) => {
          this.routeService.go({ cxRoute: 'quoteDetails', params: { code: action.payload.quoteId } });
          return [new KnBrQuoteLoad({ code: action.payload.quoteId }), new KnBrQuoteAddEntriesSuccess(data)];
        }),
        catchError((error: Error) => {
          return of(new KnBrQuoteAddEntriesFailure(normalizeHttpError(error, this.logger)));
        })
      )
    )
  )
  );

  addEntriesSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrQuoteEntryActionTypes.KnBrQuoteAddEntriesSuccessAction),
    map((action) => {
      this.knBrDraftOrderService.quickFormReset(true);
      this.messageService.add({ key: 'addToQuoteMessage.success' }, GlobalMessageType.MSG_TYPE_CONFIRMATION);
      const addedEntries = action.payload;
      if (addedEntries.length > 0) {
        if (addedEntries && addedEntries[0].statusCode === 'success' && addedEntries[0].statusMessage) {
          this.messageService.add({ raw: addedEntries[0].statusMessage }, GlobalMessageType.MSG_TYPE_WARNING);
        }
      }
    })
  ),
    { dispatch: false }
  );

  addEntriesFailure$ = createEffect(() => this.actions$.pipe(
    ofType(KnBrQuoteEntryActionTypes.KnBrQuoteAddEntriesFailureAction),
    map((action) => {
      const errors = (action.payload?.message || action.payload?.details || []) as Array<any>;
      if (errors.length > 0) {
        errors.forEach((error) => {
          const type = error.type.toLowerCase();
          if (type.indexOf('warning') !== -1) {
            this.messageService.add({ raw: error.message }, GlobalMessageType.MSG_TYPE_WARNING);
          } else if (type.indexOf('error') !== -1) {
            this.messageService.add({ raw: error.message }, GlobalMessageType.MSG_TYPE_ERROR);
          }
        });
      } else {
        this.messageService.add({ key: 'addToQuoteMessage.failure' }, GlobalMessageType.MSG_TYPE_ERROR);
      }
    })
  ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions<KnBrQuoteEntryAction>,
    private knBrQuoteEntryAdapter: KnBrQuoteEntryAdapter,
    private messageService: GlobalMessageService,
    private knBrQuoteService: KnBrQuotesService,
    private knBrDraftOrderService: KnBrDraftOrdersService,
    protected routeService: RoutingService
  ) { }
}
