import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { CxDatePipe } from '@spartacus/core';
import { UserAccountService } from '@spartacus/user/account/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MomentDateFormatter extends NgbDateParserFormatter {
  dateFormat$: Observable<any> = this.currentUserService.get().pipe(
    filter((user: any) => user && !!user.dateFormat),
    map((user: any) => user.dateFormat)
  );
  constructor(private currentUserService: UserAccountFacade, private datePipe: CxDatePipe) {
    super();
  }
  parse(value: string): NgbDateStruct {
    let dateFormat = null;
    this.dateFormat$.subscribe((format) => (dateFormat = format)).unsubscribe();
    if (value && value.trim().length == 10) {
      const mdt = moment(value.trim());
      return mdt.isValid() ? { day: mdt.date(), month: mdt.month() + 1, year: mdt.year() } : null;
    }
    return null;
  }
  format(date: NgbDateStruct): string {
    let dateFormat = null;
    this.dateFormat$.subscribe((format) => (dateFormat = format)).unsubscribe();
    if (!date) {
      return '';
    }
    const mdt = moment([date.year, date.month - 1, date.day]);
    return mdt.isValid && dateFormat ? mdt.format(dateFormat.toUpperCase()) : '';
  }
}
