import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { SpinnerModule } from '@spartacus/storefront';
import { SloanDraftSearchResultComponent } from './sloan-draft-search-result.component';
import { KnBrCustomPipesModule } from 'src/app/shared/pipe/kn-br-custom-pipes/kn-br-custom-pipes.module';
import { KnBrBrowsingModule } from 'src/app/common/kn-br-browsing/kn-br-browsing.module';

@NgModule({
  declarations: [SloanDraftSearchResultComponent],
  imports: [
    CommonModule,
    I18nModule,
    SpinnerModule,
    NgbTooltipModule,
    KnBrCustomPipesModule,
    KnBrBrowsingModule,
    ConfigModule.withConfig({
      cmsComponents: {
        KnBrDraftOrderSearchResultComponent: {
          component: SloanDraftSearchResultComponent,
        },
      },
    } as CmsConfig),
  ],
})
export class SloanDraftSearchResultModule {}
