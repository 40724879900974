import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { KnBrFactSheetData } from 'src/feature-libs/kn-br-order/root/models/kn-br-fact-sheet.model';
import { KnBrFactSheetService } from 'src/feature-libs/kn-br-order/core/facade/kn-br-fact-sheet.service';

@Component({
  selector: 'knbr-kn-br-fact-sheet-group',
  templateUrl: './kn-br-fact-sheet-group.component.html',
  styleUrls: ['./kn-br-fact-sheet-group.component.scss'],
})
export class KnBrFactSheetGroupComponent implements OnInit {
  factSheetData$: Observable<KnBrFactSheetData> = this.factSheetService.factSheetData$;

  constructor(protected factSheetService: KnBrFactSheetService) { }

  ngOnInit(): void { }
}
