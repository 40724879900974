import { getDraftOrdersComments } from '../store/selecters/kn-br-draft-orders-comments.selector';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, queueScheduler } from 'rxjs';
import { observeOn } from 'rxjs/operators';
import {
  KnBrDraftOrderCommentsCreate,
  KnBrDraftOrderCommentsDelete,
  KnBrDraftOrderCommentsLoad,
  KnBrDraftOrderCommentsUpdate,
} from '../store/actions/kn-br-draft-orders-comments.action';
import { StateWithDraftOrderComments } from '../../../../../app/store/kn-br-draft-order-comments/kn-br-draft-orders-comments.state';
import { KnBrDraftOrderComments } from '../../root/models/kn-br-draft-order.model';

@Injectable({
  providedIn: 'root',
})
export class KnBrDraftOrdersCommentsService {
  constructor(private store: Store<StateWithDraftOrderComments>) { }

  list$: Observable<KnBrDraftOrderComments[]> = this.store.pipe(
    select(getDraftOrdersComments),
    observeOn(queueScheduler)
  );

  // reset() {
  //   this.store.dispatch(new KnBrDraftOrderSearchReset(null));
  // }
  // copy(payload: KnBrDraftOrder) {
  //   this.store.dispatch(new KnBrDraftOrderCopy(payload));
  // }

  create(payload) {
    this.store.dispatch(new KnBrDraftOrderCommentsCreate(payload));
  }
  update(payload) {
    this.store.dispatch(new KnBrDraftOrderCommentsUpdate(payload));
  }
  get(entryNumber) {
    this.store.dispatch(new KnBrDraftOrderCommentsLoad(entryNumber));
  }

  delete(payload) {
    this.store.dispatch(new KnBrDraftOrderCommentsDelete(payload));
  }
}
