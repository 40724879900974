import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SloanShippingAddressCheckoutComponent } from './sloan-shipping-address-checkout.component';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { KnBrCustomPipesModule } from 'src/app/shared/pipe/kn-br-custom-pipes/kn-br-custom-pipes.module';


@NgModule({
  declarations: [SloanShippingAddressCheckoutComponent],
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CheckoutShippingAddress: {
          component: SloanShippingAddressCheckoutComponent,
        },
      },
    } as CmsConfig),
    KnBrCustomPipesModule
  ],
  exports: [SloanShippingAddressCheckoutComponent],
})
export class SloanShippingAddressCheckoutModuleModule { }
