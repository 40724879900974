import { ChangeDetectorRef, Component, Input, OnInit, Optional } from '@angular/core';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { CheckoutPaymentTypeFacade } from '@spartacus/checkout/b2b/root';
import {
  CheckoutDeliveryAddressFacade,
  CheckoutDeliveryModesFacade,
  CheckoutPaymentFacade,
} from '@spartacus/checkout/base/root';
import { FeatureConfigService, TranslationService } from '@spartacus/core';
import { SloanDraftOrder } from 'src/app/models/sloan/sloan-draft-order.model';
import { SloanCommonService } from 'src/app/services/sloan/sloan-common.service';
import { KnBrCheckoutStepService } from 'src/feature-libs/kn-br-checkout/base/components/services/kn-br-checkout-step.service';
import { SloanReviewSubmitComponent } from 'src/feature-libs/sloan/sloan-checkout/sloan-review-submit/sloan-review-submit.component';

@Component({
  selector: 'cx-order-summary',
  templateUrl: './sloan-order-summary.component.html',
})
export class SloanOrderSummaryComponent extends SloanReviewSubmitComponent implements OnInit {
  @Input()
  cart: SloanDraftOrder;
  paymentType$ = this.commonService.selectedPaymentTypes$;
  allDeliveryMode = [];

  constructor(
    protected checkoutDeliveryAddressFacade: CheckoutDeliveryAddressFacade,
    protected checkoutPaymentFacade: CheckoutPaymentFacade,
    protected checkoutPaymentTypeFacade: CheckoutPaymentTypeFacade,
    protected activeCartFacade: ActiveCartFacade,
    protected translationService: TranslationService,
    protected checkoutStepService: KnBrCheckoutStepService,
    protected checkoutDeliveryModesFacade: CheckoutDeliveryModesFacade,
    protected cdr: ChangeDetectorRef,
    protected commonService: SloanCommonService,
    @Optional() protected featureConfigService?: FeatureConfigService
  ) {
    super(
      checkoutDeliveryAddressFacade,
      checkoutPaymentFacade,
      checkoutPaymentTypeFacade,
      activeCartFacade,
      translationService,
      checkoutStepService,
      checkoutDeliveryModesFacade
    );
    this.deliveryMode$.subscribe((dm) => {
      this.selectedDeleiveryMode = this.allDeliveryMode.find((o) => o.code === dm?.code);
    });
  }
  ngOnInit() {
    this.checkoutDeliveryModesFacade.getSupportedDeliveryModes().subscribe((cdf) => {
      if (cdf.length) {
        this.allDeliveryMode = cdf;
        this.selectedDeleiveryMode = this.allDeliveryMode.find((o) => o.code === this.cart?.deliveryMode?.code);
        this.cdr.detectChanges();
      }
    });
  }
  getPaymentType(name: string) {
    return `paymentTypes.paymentType_${name}`;
  }
}
