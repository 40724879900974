import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import * as _ from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { KnBrOrderFormsSearchRequest } from 'src/feature-libs/kn-br-order/root/models/kn-br-order.model';
import { SloanOrderHistory } from 'src/app/services/sloan/sloan-order-history.service';
import { KnBrOrderHistoryService } from 'src/feature-libs/kn-br-order/core/facade/kn-br-order-history.service';
import { KnBrOrderFormSearchComponent } from 'src/feature-libs/kn-br-cart/kn-br-order-tools/kn-br-order-forms/kn-br-order-form-search/kn-br-order-form-search.component';
import { KnBrDraftOrdersService } from 'src/feature-libs/kn-br-cart/draft-order/core/facade/kn-br-draft-orders.service';

@Component({
  selector: 'knbr-kn-br-order-form-search',
  templateUrl: './sloan-order-form-search.component.html',
  styleUrls: ['./sloan-order-form-search.component.scss'],
})
export class SloanOrderFormSearchComponent extends KnBrOrderFormSearchComponent implements OnInit, OnDestroy {
  products$: Observable<any> = this.knBrOrderHistoryService.orderFormsProducts$;
  isLoader$: Observable<boolean> = this.knBrOrderHistoryService.orderFormsloader$;
  subscription = new Subscription();
  previousResponse = null;

  constructor(
    protected fb: FormBuilder,
    protected knBrOrderHistoryService: KnBrOrderHistoryService,
    protected cdr: ChangeDetectorRef,
    protected knBrDraftOrderService: KnBrDraftOrdersService,
    protected sloanOrderHistory: SloanOrderHistory
  ) {
    super(fb, knBrOrderHistoryService, cdr, knBrDraftOrderService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  submitForm() {
    if (this.orderForm.valid) {
      const req: KnBrOrderFormsSearchRequest = {
        keywords: this.orderForm.value.keywords,
        onlyProductIds: this.orderForm.value.onlyProductId,
        currentPage: this.currentPage,
      };
      this.knBrOrderHistoryService.getOrderFormProducts(req);
      this.previousResponse = null;
      this.sloanOrderHistory.availability$.next(null);
    } else {
      this.orderForm.markAllAsTouched();
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  showAvailability(products) {
    const productCodes = [];
    if (products.length) {
      products.forEach((element) => {
        productCodes.push(element.code);
      });
      const req: KnBrOrderFormsSearchRequest = {
        keywords: productCodes.toString(),
        onlyProductIds: true,
      };
      if (productCodes.length) {
        if (_.isEqual(this.previousResponse, req.keywords)) {
          return;
        }
        this.previousResponse = req.keywords;
        this.sloanOrderHistory.orderFormProductSearchKeyword(req);
      }
    }
  }
}
