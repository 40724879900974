<form [formGroup]="addToCartForm" (submit)="addToCart()">
  <div class="quantity pl-2" *ngIf="type === 'PDP' && editable && quoteEditable">
    <label class="d-flex align-items-center m-0">Quantity: </label>
    <input
      type="text"
      [min]="minQuantity"
      class="form-control color-lighter-bg"
      [formControl]="addToCartForm.get('quantity')"
      (blur)="onQuantityBlur()"
      knBrNumbersOnly
    />
  </div>
  <ng-container *ngIf="cartContext || !quoteContext">
    <ng-container *ngIf="type === 'PGP' && editable">
      <div
        class="p-0 float-left"
        [class.w-100]="cartContext && !quoteContext"
        [class.w-50]="!cartContext && !quoteContext"
      >
        <button
          type="button"
          class="btn rounded-0 w-100 btn-primary"
          [ngbTooltip]="addToCartButtonTip"
          [disabled]="isSaveDisabled$ | async"
          (click)="addToCart()"
        >
          <i class="fas fa-cart-plus color-white font-size-18"></i>
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="type === 'PDP' && editable">
      <button
        type="button"
        (click)="addToCart()"
        [disabled]="!hasStock || (isSaveDisabled$ | async) || !editable"
        class="btn color-knbr-primary color-white pl-4 pr-4 pb-1 pt-1 font-lg-size w-100 hover-link m-res-bottom"
      >
        <i class="fas fa-cart-plus mr-2"></i> {{ 'addToCart.addToCart' | cxTranslate }}
      </button>
    </ng-container>
    <ng-container *ngIf="type === 'PLP' && editable">
      <span
        class="m-res-0 res-cart-quote"
        [class.res-w-100]="cartContext && !quoteContext"
        [class.res-w-50]="!cartContext && !quoteContext"
        [ngbTooltip]="addToCartButtonTip"
      >
        <i class="fas fa-cart-plus font-lg-size" (click)="addToCart()"></i>
      </span>
    </ng-container>
    <ng-container *ngIf="type === 'ACTION' && editable">
      <div class="float-left mr-2">
        <a
          class="grid-icon p-2 rounded float-left d-flex align-items-center justify-content-center cursor-pointer"
          [ngbTooltip]="addToCartButtonTip"
        >
          <i class="fas fa-cart-plus" (click)="addMultipleProducts()"></i>
        </a>
      </div>
    </ng-container>
    <ng-container *ngIf="type === 'QUICK'">
      <button
        type="button"
        (click)="addProductsToCart()"
        [disabled]="(productList && productList.length < 1) || !editable"
        class="btn btn-primary w-100"
      >
        <i class="fas fa-cart-plus mr-2"></i> {{ 'quickOrder.label.addToCart' | cxTranslate }}
      </button>
    </ng-container>
  </ng-container>
  <ng-template #addToCartButtonTip>{{ 'toolTip.addToCart' | cxTranslate }}</ng-template>
</form>
