import {
  ActiveLinkCellComponent,
  CellComponent,
  OrganizationTableType,
  RolesCellComponent,
  StatusCellComponent,
} from '@spartacus/organization/administration/components';
import { TableConfig } from '@spartacus/storefront';

export const sloanUserTableConfig: TableConfig = {
  table: {
    [OrganizationTableType.USER]: {
      cells: ['name', 'displayUid', 'roles', 'active'],
      options: {
        cells: {
          name: {
            dataComponent: ActiveLinkCellComponent,
          },
          uid: {
            dataComponent: CellComponent,
          },
          roles: {
            dataComponent: RolesCellComponent,
          },
          active: {
            dataComponent: StatusCellComponent,
          },
        },
      },
    },
  },
};
