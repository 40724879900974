import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RoutingService } from '@spartacus/core';
import { Subscription } from 'rxjs';
import { KnBrDraftOrdersService } from 'src/feature-libs/kn-br-cart/draft-order/core/facade/kn-br-draft-orders.service';

@Component({
  selector: 'knbr-sloan-freight-pop-up-dialog',
  templateUrl: './sloan-freight-pop-up-dialog.component.html',
  styleUrls: ['./sloan-freight-pop-up-dialog.component.scss'],
})
export class SloanFreightPopUpDialogComponent implements OnInit {
  subscription = new Subscription();
  action = 'back';
  popupDetails: any;
  constructor(
    protected activeModal: NgbActiveModal,
    protected knBrDraftOrderService: KnBrDraftOrdersService,
    protected routingService: RoutingService
  ) { }

  ngOnInit(): void {
    this.popupDetails = this.knBrDraftOrderService.getFrightAndShipComplteDetails();
  }

  dismiss() {
    const formData: any = {};
    formData.action = this.action;
    this.activeModal.close(formData);
  }

  navigate(url: string) {
    this.action = 'ok';
    this.dismiss();
  }
}
