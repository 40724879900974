import { CustomerData } from "src/feature-libs/kn-br-user/kn-br-account/root/model/customer.model";


export const KN_BR_CONTEXT_CUSTOMER_FEATURE = 'kn-br-customer-context';

export interface KnBrCustomerContextState {
  customerContext?: CustomerData;
}

export interface StateWithCustomerContext {
  [KN_BR_CONTEXT_CUSTOMER_FEATURE]: KnBrCustomerContextState;
}
