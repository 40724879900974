/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import {
  backOff,
  ConverterService,
  InterceptorUtil,
  isJaloError,
  normalizeHttpError,
  Occ,
  OccEndpointsService,
  OCC_USER_ID_ANONYMOUS,
  USE_CLIENT_TOKEN,
  LoggerService,
} from '@spartacus/core';
import { OrderAdapter } from '@spartacus/order/core';
import { Order, ORDER_NORMALIZER } from '@spartacus/order/root';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { KnBrCustomerContextService } from 'src/app/store/kn-br-customer-context/kn-br-customer-context.service';
import { KN_BR_CUSTOMER_CURRENT } from 'src/constants/api.endpoints.constant';

@Injectable()
export class KnBrOccOrderAdapter implements OrderAdapter {
  customerId = '';
  protected logger = inject(LoggerService);
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converter: ConverterService,
    private customerContextService: KnBrCustomerContextService
  ) {
    this.customerContextService.get$.subscribe((response) => {
      this.customerId = response && response.customerNumber ? response.customerNumber : '';
    });
  }

  public placeOrder(userId: string, cartId: string, termsChecked: boolean): Observable<Order> {
    const customerId = this.customerId ? this.customerId : KN_BR_CUSTOMER_CURRENT;
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    if (userId === OCC_USER_ID_ANONYMOUS) {
      headers = InterceptorUtil.createHeader(USE_CLIENT_TOKEN, true, headers);
    }

    return this.http
      .post<Occ.Order>(this.getPlaceOrderEndpoint(customerId, cartId, termsChecked.toString()), {}, { headers })
      .pipe(
        catchError((error) => throwError(normalizeHttpError(error, this.logger))),
        backOff({
          shouldRetry: isJaloError,
        }),
        this.converter.pipeable(ORDER_NORMALIZER)
      );
  }

  protected getPlaceOrderEndpoint(customerId: string, cartId: string, termsChecked: string): string {
    const url = this.occEndpoints.buildUrl('placeOrder', {
      urlParams: { customerId },
      queryParams: { cartId, termsChecked },
    });
    return url;
  }
}
