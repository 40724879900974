import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CartAdapter } from '@spartacus/cart/base/core';
import { CART_NORMALIZER, Cart } from '@spartacus/cart/base/root';
import {
  ConverterService,
  InterceptorUtil,
  OCC_CART_ID_CURRENT,
  OCC_USER_ID_ANONYMOUS,
  Occ,
  OccEndpointsService,
  USE_CLIENT_TOKEN,
} from '@spartacus/core';
import { Observable } from 'rxjs';
import { map, pluck } from 'rxjs/operators';

import { KnBrCustomerContextService } from '../../../../../app/store/kn-br-customer-context/kn-br-customer-context.service';
import { KN_BR_CUSTOMER_CURRENT } from '../../../../../constants/api.endpoints.constant';

@Injectable()
export class KnBrOccCartAdapter implements CartAdapter {
  customerId: string;
  constructor(
    protected http: HttpClient,
    protected occEndpointsService: OccEndpointsService,
    protected converterService: ConverterService,
    private customerContextService: KnBrCustomerContextService
  ) {
    this.customerContextService.get$.subscribe((response) => {
      this.customerId = response && response.customerNumber ? response.customerNumber : null;
    });
  }

  public loadAll(userId: string): Observable<Cart[]> {
    const customerId = this.customerId ? this.customerId : KN_BR_CUSTOMER_CURRENT;
    return this.http

      .get<Occ.CartList>(this.occEndpointsService.buildUrl('carts', { urlParams: { customerId } }))
      .pipe(pluck('carts'), this.converterService.pipeableMany(CART_NORMALIZER));
  }

  public load(userId: string, cartId: string): Observable<Cart> {
    if (cartId === OCC_CART_ID_CURRENT) {
      return this.loadAll(userId).pipe(
        map((carts) => {
          if (carts) {
            const activeCart = carts.find((cart) => {
              return cart.saveTime === undefined;
            });
            return activeCart;
          } else {
            return null;
          }
        })
      );
    } else {
      const customerId = this.customerId;
      if (customerId) {
        return this.http
          .get<Occ.Cart>(this.occEndpointsService.buildUrl('cart', { urlParams: { customerId, cartId } }))
          .pipe(this.converterService.pipeable(CART_NORMALIZER));
      }
    }
  }

  create(userId: string, oldCartId?: string, toMergeCartGuid?: string): Observable<Cart> {
    const toAdd = JSON.stringify({});

    let params: { toMergeCartGuid?: string; oldCartId?: string } = {};

    if (oldCartId) {
      params = { oldCartId };
    }
    if (toMergeCartGuid) {
      params.toMergeCartGuid = toMergeCartGuid;
    }

    return this.http

      .post<Occ.Cart>(
        this.occEndpointsService.buildUrl('createCart', {
          urlParams: { customerId: this.customerId },
          queryParams: { params },
        }),
        toAdd
      )
      .pipe(this.converterService.pipeable(CART_NORMALIZER));
  }

  delete(userId: string, cartId: string): Observable<{}> {
    let headers = new HttpHeaders();
    if (userId === OCC_USER_ID_ANONYMOUS) {
      headers = InterceptorUtil.createHeader(USE_CLIENT_TOKEN, true, headers);
    }
    return this.http.delete<{}>(
      this.occEndpointsService.buildUrl('deleteCart', { urlParams: { customerId: this.customerId, cartId } }),
      { headers }
    );
  }

  addEmail(userId: string, cartId: string, email: string): Observable<{}> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    headers = InterceptorUtil.createHeader(USE_CLIENT_TOKEN, true, headers);

    const httpParams: HttpParams = new HttpParams().set('email', email);

    const url = this.occEndpointsService.buildUrl('addEmail', {
      urlParams: {
        customerId: this.customerId,
        cartId,
      },
    });

    return this.http.put(url, httpParams, { headers });
  }
  save(userId: string, cartId: string, saveCartName?: string, saveCartDescription?: string): Observable<Cart> {
    return;
  }
}
