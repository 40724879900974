import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { ConfigModule, CmsConfig, I18nModule, UrlModule } from '@spartacus/core';
import { KnBrOrderReturnComponent } from './kn-br-order-return.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KnBrReturnOrderItemComponent } from './kn-br-return-order-item/kn-br-return-order-item.component';
import { MediaModule } from '@spartacus/storefront';
import { KnBrDirectiveModule } from 'src/app/shared/directive/kn-br-directive.module';

@NgModule({
  declarations: [KnBrOrderReturnComponent, KnBrReturnOrderItemComponent],
  imports: [
    CommonModule,
    NgSelectModule,
    I18nModule,
    ReactiveFormsModule,
    FormsModule,
    KnBrDirectiveModule,
    RouterModule,
    UrlModule,
    MediaModule,
    ConfigModule.withConfig({
      cmsComponents: {
        ReturnOrderComponent: {
          component: KnBrOrderReturnComponent,
        },
      },
    } as CmsConfig),
  ],
})
export class KnBrOrderReturnModule {}
