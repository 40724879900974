import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CART_NORMALIZER } from '@spartacus/cart/base/root';
import { ConfigModule, I18nModule } from '@spartacus/core';

import { KnBrDraftOrderCreateModule } from './kn-br-draft-order-create/kn-br-draft-order-create.module';
import { KnBrDraftOrderDetailModule } from './kn-br-draft-order-detail/kn-br-draft-order-detail.module';
import { KnBrDraftOrderListModule } from './kn-br-draft-order-list/kn-br-draft-order-list.module';
import { KnBrCartNormalizer } from 'src/feature-libs/kn-br-cart/base/occ/adapters/converters/kn-br-occ-cart-normalizer';
import { CartAdapter } from '@spartacus/cart/base/core';
import { KnBrOccCartAdapter } from 'src/feature-libs/kn-br-cart/base/occ/adapters/kn-br-occ-cart.adapter';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    I18nModule,
    KnBrDraftOrderListModule,
    KnBrDraftOrderCreateModule,
    KnBrDraftOrderDetailModule,
    // ConfigModule.withConfig(knBrDraftOrderEndpointsConfig),
  ],
  providers: [
    {
      provide: CART_NORMALIZER,
      useClass: KnBrCartNormalizer,
      multi: true,
    },
    {
      provide: CartAdapter,
     useClass: KnBrOccCartAdapter,
    },
  ],
})
export class KnBrDraftOrderModule {}
