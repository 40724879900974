import { Injectable } from '@angular/core';
import { Converter } from '@spartacus/core';
import { UserSignUp } from '@spartacus/user/profile/root/model';
import { KnBrUserSignUp } from '../models/kn-br-user-signup.model';

@Injectable()
export class KnBrUserSignUpSerializer implements Converter<KnBrUserSignUp, UserSignUp> {
  convert(source: KnBrUserSignUp, target: UserSignUp): UserSignUp {
    if (target === undefined) {
      target = { ...(source as any) };
    }
    return target;
  }
}
