import { Injectable, OnDestroy } from '@angular/core';
import {
  AuthStatePersistenceService,
  StatePersistenceService,
  UserIdService,
  AuthStorageService,
  AuthRedirectStorageService,
  StorageSyncType,
} from '@spartacus/core';

@Injectable({
  providedIn: 'root',
})
export class KnBrAuthStatePersistenceService extends AuthStatePersistenceService implements OnDestroy {
  constructor(
    protected statePersistenceService: StatePersistenceService,
    protected userIdService: UserIdService,
    protected authStorageService: AuthStorageService,
    protected authRedirectStorageService: AuthRedirectStorageService
  ) {
    super(statePersistenceService, userIdService, authStorageService, authRedirectStorageService);
  }

  /**
   * Initializes the synchronization between state and browser storage.
   */
  public initSync() {
    this.subscription.add(
      this.statePersistenceService.syncWithStorage({
        key: this.key,
        state$: this.getAuthState(),
        onRead: (state) => this.onRead(state),
        storageType: StorageSyncType.SESSION_STORAGE,
      })
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
