import { DatePipe, Location } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbCalendar, NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CsAgentAuthService } from '@spartacus/asm/root';
import { CartAdapter } from '@spartacus/cart/base/core';
import { ActiveCartFacade, MultiCartFacade } from '@spartacus/cart/base/root';
import { CheckoutDeliveryAddressFacade } from '@spartacus/checkout/base/root';
import {
  CmsService,
  CurrencyService,
  GlobalMessageService,
  RoutingService,
  User,
  UserAddressService
} from '@spartacus/core';
import { LaunchDialogService } from '@spartacus/storefront';
import { UserAccountFacade } from '@spartacus/user/account/root';
import * as _ from 'lodash';
import { Observable, filter, map, pluck } from 'rxjs';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';
import { SloanCalendarHelper } from 'src/app/services/sloan/sloan-calendar-help.service';
import { SloanDataSharedService } from 'src/app/services/sloan/sloan-data-shared.service';
import { KnBrDateHelper } from 'src/app/shared/kn-br-date.helper';
import { RemoveLeadingZerosPipe } from 'src/app/shared/pipe/kn-br-custom-pipes/remove-leading-zeros.pipe';
import { KnBrCustomerContextService } from 'src/app/store/kn-br-customer-context/kn-br-customer-context.service';
import {
  chinaLimitHigh,
  chinaLimitLow,
  regularLimitHigh,
  regularLimitLow,
  sinkLimitHigh,
  sinkLimitLow,
} from 'src/constants/sloan/freight-limit.constant';
import { KnBrCartContextService } from 'src/feature-libs/kn-br-cart/base/core/facade/kn-br-cart-context.service';
import { KnBrDraftOrderDetailHeaderComponent } from 'src/feature-libs/kn-br-cart/draft-order/components/kn-br-draft-order/kn-br-draft-order-detail/kn-br-draft-order-detail-header/kn-br-draft-order-detail-header.component';
import { KnBrDraftOrdersService } from 'src/feature-libs/kn-br-cart/draft-order/core/facade/kn-br-draft-orders.service';
import { SloanFreightPopUpDialogComponent } from '../../sloan-freight-pop-up-dialog/sloan-freight-pop-up-dialog.component';

@Component({
  selector: 'knbr-sloan-draft-order-detail-header',
  templateUrl: './sloan-draft-order-detail-header.component.html',
  providers: [RemoveLeadingZerosPipe],
})
export class SloanDraftOrderDetailHeaderComponent
  extends KnBrDraftOrderDetailHeaderComponent
  implements OnInit, OnDestroy {
  today: NgbDate = this.sloanCalendarHelper.today;
  rddMinDate: NgbDate;
  rddMaxDate: NgbDate;
  closed = false;
  isCheckoutDisabled$: Observable<boolean> = this.sloanDataSharedService.isDraftOrderCheckOutDisabled$();
  draftOrder: any;
  invalidQuantityLineItems: string;
  moqAlertClose = false;
  statusKeyForJobNameMandatory = 'CART_SELLERAPPROVER_APPROVED';
  isASMLoggedIn$: Observable<boolean> = this.csAgentAuthService.isCustomerSupportAgentLoggedIn();
  isGenericLineItems: string;
  isSinkPatternLineItems: string;
  disableCheckout$ = this.knBrDraftOrderService.isCheckoutDisabled$;
  isStable$ = this.activeCartService.isStable();
  warningMessage$: Observable<string> = this.knBrDraftOrderService.warningMessage$;
  warningClosed = false;
  constructor(
    protected currentUserService: UserAccountFacade,
    protected activeCartService: ActiveCartFacade,
    protected fb: UntypedFormBuilder,
    protected ngbCalendar: NgbCalendar,
    protected cmsService: CmsService,
    protected knBrCommonService: KnBrCommonService,
    protected cdr: ChangeDetectorRef,
    protected knBrDraftOrderService: KnBrDraftOrdersService,
    protected location: Location,
    protected routingService: RoutingService,
    protected knBrDateHelper: KnBrDateHelper,
    protected multiCartService: MultiCartFacade,
    protected knBrCartContextService: KnBrCartContextService,
    protected knBrCustomerContextService: KnBrCustomerContextService,
    protected modalService: NgbModal,
    protected currencyService: CurrencyService,
    protected launchDialogService: LaunchDialogService,
    protected vcr: ViewContainerRef,
    protected removeLeadingZerosPipe: RemoveLeadingZerosPipe,
    protected globalMessageService: GlobalMessageService,
    protected cartAdapter: CartAdapter,
    protected csAgentAuthService: CsAgentAuthService,
    protected sloanCalendarHelper: SloanCalendarHelper,
    protected sloanDataSharedService: SloanDataSharedService,
    protected datePipe: DatePipe
  ) {
    super(
      currentUserService,
      activeCartService,
      fb,
      ngbCalendar,
      cmsService,
      knBrCommonService,
      cdr,
      knBrDraftOrderService,
      location,
      routingService,
      knBrDateHelper,
      multiCartService,
      knBrCartContextService,
      knBrCustomerContextService,
      modalService,
      currencyService,
      launchDialogService,
      vcr,
      removeLeadingZerosPipe,
      globalMessageService,
      cartAdapter
    );
    this.rddMinDate = this.sloanCalendarHelper.getRddMinDate(this.today);
    this.rddMaxDate = this.sloanCalendarHelper.getRddMaxDate(this.today);
  }

  isRddDisabled = (date: NgbDate, current: { year: number; month: number }) => {
    return (
      this.sloanCalendarHelper.isHoliday(date) ||
      (this.sloanCalendarHelper.isWeekend(date) && date.month === current.month)
    );
  };

  ngOnInit(): void {
    super.ngOnInit();
    this.activeCartService.reloadActiveCart();
    this.subscription.add(
      this.activeCartService.getActive().subscribe((draftOrder: any) => {
        this.moqAlertClose = false;
        this.warningClosed = false;
        this.sloanDataSharedService.setDraftOrderCheckoutDisable(false);
      })
    );
  }

  closeWarningMsg() {
    this.warningClosed = true;
    this.knBrDraftOrderService.resetWarningMsg();
  }

  onEnterInput() {
    this.sloanDataSharedService.setDraftOrderCheckoutDisable(true);
  }

  initForm() {
    const poNumPattern = '[^*^~|<>]*';
    this.draftOrderEditForm = this.fb.group({
      headerComments: [{ value: null, disabled: this.isDisabled() }],
      jobName: [{ value: null, disabled: this.isDisabled() }],
      // name: [{ value: null, disabled: this.isDisabled() }, Validators.required],
      purchaseOrderNumber: [
        { value: null, disabled: this.isDisabled() },
        [Validators.required, Validators.pattern(poNumPattern)],
      ],
      requestedDeliveryDate: [{ value: null, disabled: this.isDisabled() }, Validators.required],
      shipComplete: [{ value: true, disabled: this.isDisabled() }],
      draftOrderStatus: null,
      cartStimulated: false,
    });
  }

  getSimulatedCart() {
    this.draftOrderEditForm.markAsDirty();
    if (this.draftOrderEditForm?.valid && this.draftOrderEditForm?.dirty) {
      this.draftOrderEditForm.patchValue({ cartStimulated: true });
      this.onSubmit();
    } else {
      this.draftOrderEditForm.markAllAsTouched();
    }
    this.draftOrderEditForm.patchValue({ cartStimulated: false });
  }

  closeGlobalMsg() {
    this.draftOrder.globalMessage = null;
  }

  onSubmit(isRedirect = false) {
    this.sloanDataSharedService.setDraftOrderCheckoutDisable(false);
    if (this.draftOrderEditForm?.valid && this.draftOrderEditForm?.dirty) {
      const formValues = _.cloneDeep(this.draftOrderEditForm.getRawValue());
      if (this.draftOrderEditForm.controls.requestedDeliveryDate.value) {
        formValues.requestedDeliveryDate = this.knBrDateHelper.formatDate(
          this.draftOrderEditForm.controls.requestedDeliveryDate.value
        );
      }
      if (this.isHeaderDiscountUpdate) {
        formValues.knbrHeaderDiscount = this.knBrHeaderDiscount ? this.knBrHeaderDiscount : '0';
      }
      formValues.isRedirect = isRedirect;
      if (!isRedirect) {
        this.submitOrder(formValues);
      } else {
        const draftOrderStatus = formValues.draftOrderStatus;
        if (draftOrderStatus === 'CART_SELLERAPPROVER_REJECTED' || draftOrderStatus === 'CART_BUYER_PENDING_APPROVAL') {
          this.openRejectQuotePopup(isRedirect, formValues);
        } else {
          this.updateCartStatus(formValues);
        }
      }
    } else {
      this.draftOrderEditForm.markAllAsTouched();
    }
  }

  isSaveUpdateDisabled() {
    if (this.draftOrder && this.draftOrder.entries && this.draftOrder.entries.length > 0) {
      const invalidQuantityEntries = this.draftOrder.entries
        .filter((cart) => cart.isValidQuantity === false)
        .map((cart) => cart.entryNumber);
      if (invalidQuantityEntries && invalidQuantityEntries.length > 0) {
        this.invalidQuantityLineItems = invalidQuantityEntries.join(',');
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  isCheckoutDisabledUI() {
    if (this.draftOrder && this.draftOrder.entries && this.draftOrder.entries.length > 0) {
      let isGenericEntries = this.draftOrder.entries
        .filter((entry) => entry.product?.isGeneric && entry.commentCount === 0)
        .map((entry) => entry.product.code);
      let isSinkPatternEntries = this.draftOrder.entries
        .filter((entry) => entry.product?.isSinkDrillPattern_b && entry.commentCount === 0)
        .map((entry) => entry.product.code);
      // To remove duplicate product codes.
      isGenericEntries = _.uniq(isGenericEntries);
      isSinkPatternEntries = _.uniq(isSinkPatternEntries);
      if (
        (isGenericEntries && isGenericEntries.length > 0) ||
        (isSinkPatternEntries && isSinkPatternEntries.length > 0)
      ) {
        this.isGenericLineItems = isGenericEntries.join(',');
        this.isSinkPatternLineItems = isSinkPatternEntries.join(',');
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  patchForm(draftOrder: any) {
    this.draftOrderEditForm.patchValue({
      headerComments: draftOrder.headerComments,
      jobName: draftOrder.jobName,
      name: draftOrder.name,
      purchaseOrderNumber: draftOrder.purchaseOrderNumber,
      requestedDeliveryDate: this.knBrDateHelper.getDate(draftOrder.requestedDeliveryDate),
      shipComplete: draftOrder.shipComplete,
      draftOrderStatus: draftOrder.cartStatus,
    });

    if (draftOrder.statsuKey === this.statusKeyForJobNameMandatory) {
      this.draftOrderEditForm.controls.jobName.setValidators([Validators.required]);
      this.draftOrderEditForm.controls.jobName.updateValueAndValidity();
    }
  }

  checkAnonymousKeyword(message: string) {
    return _.includes(message.toLowerCase(), 'anonymous');
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  navigate(url) {
    let date: Date = new Date();
    let currentFormate = this.datePipe.transform(date, 'MM/dd/yyyy');
    let toDay = new Date(currentFormate);
    const maxDate = new Date(
      Math.max(
        ...this.draftOrder.entries.map((element) => {
          return new Date(element.estimatedShipDate);
        })
      )
    );
    let requestedShipDate = this.datePipe.transform(maxDate, 'MM/dd/yyyy');
    let deleveryDay = new Date(requestedShipDate);
    let Time = deleveryDay.getTime() - toDay.getTime();
    let Days = Time / (1000 * 3600 * 24);
    if (this.draftOrderEditForm?.valid) {
      // conditions to show freight terms poup.
      if (
        (this.draftOrder.totalPrice.value >= regularLimitLow && this.draftOrder.totalPrice.value <= regularLimitHigh) ||
        (this.draftOrder.totalPrice.value >= sinkLimitLow &&
          this.draftOrder.totalPrice.value <= sinkLimitHigh &&
          this.draftOrder.entries.some((entry) => entry.product.isSink)) ||
        (this.draftOrder.totalPrice.value >= chinaLimitLow && this.draftOrder.totalPrice.value <= chinaLimitHigh) ||
        (this.draftOrder.shipComplete && this.draftOrder.entries && this.draftOrder.entries.length > 1 && Days > 20)
      ) {
        let popupDetails = {};
        popupDetails['shipcompletePopup'] =
          this.draftOrder.shipComplete && Days > 20 && this.draftOrder.entries && this.draftOrder.entries.length > 1
            ? true
            : false;
        popupDetails['freightPopup'] =
          (this.draftOrder.totalPrice.value >= regularLimitLow &&
            this.draftOrder.totalPrice.value <= regularLimitHigh) ||
            (this.draftOrder.totalPrice.value >= sinkLimitLow &&
              this.draftOrder.totalPrice.value <= sinkLimitHigh &&
              this.draftOrder.entries.some((entry) => entry.product.isSink)) ||
            (this.draftOrder.totalPrice.value >= chinaLimitLow && this.draftOrder.totalPrice.value <= chinaLimitHigh)
            ? true
            : false;
        popupDetails['estimateDate'] = requestedShipDate;
        this.knBrDraftOrderService.setFrightAndShipComplteDetails(popupDetails);
        const freightPopUpModalref = this.modalService.open(SloanFreightPopUpDialogComponent);
        freightPopUpModalref.result.then((res) => {
          if (res.action === 'ok') {
            super.navigate(url);
          }
        });
      } else {
        super.navigate(url);
      }
    } else {
      this.draftOrderEditForm.markAllAsTouched();
    }
  }
}
