import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FileUploadControl, FileUploadValidators } from '@iplab/ngx-file-upload';
import { GlobalMessageService, GlobalMessageType } from '@spartacus/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Statuses } from 'src/feature-libs/kn-br-cart/quote/root/models/kn-br-quote.model';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';
// import { Statuses } from '../models/kn-br-quote.model';
// import { KnBrCommonService } from '../services/kn-br-common.service';

@Component({
  selector: 'knbr-kn-br-service-tickets',
  templateUrl: './kn-br-service-tickets.component.html',
  styleUrls: ['./kn-br-service-tickets.component.scss'],
})
export class KnBrServiceTicketsComponent implements OnInit {
  serviceTicketCategoryList$: Observable<Statuses[]> = this.knBrCommonService.loadServiceTicketCategoryList$();
  public fileUploadControl = new FileUploadControl()
    .setListVisibility(false)
    .setValidators([FileUploadValidators.filesLimit(1)]);
  documentTypes = '.pdf,.txt,.png,.jpg,.jpeg,.gif,.doc,.docx,.html,.htm,.zip';
  serviceTicketForm: UntypedFormGroup;
  responseMessage$ = new BehaviorSubject<string>('');
  isLoading$ = new BehaviorSubject<Boolean>(false);

  constructor(
    protected fb: UntypedFormBuilder,
    private knBrCommonService: KnBrCommonService,
    protected globalMessageService: GlobalMessageService
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.knBrCommonService.getServiceTicketCategoryList();
  }

  initForm() {
    this.serviceTicketForm = this.fb.group({
      subject: ['', Validators.required],
      message: ['', Validators.required],
      ticketCategory: ['', Validators.required],
    });

    this.serviceTicketCategoryList$.subscribe((category) => {
      if (category) {
        this.serviceTicketForm.patchValue({ ticketCategory: category[0].Key });
      }
    });
  }

  submitTicket() {
    if (this.serviceTicketForm.valid) {
      this.isLoading$.next(true);

      const fileData: FormData = new FormData();
      if (this.fileUploadControl.value.length > 0) {
        fileData.append('attachment', this.fileUploadControl.value[0]);
      }

      this.knBrCommonService.createServiceTicket(this.serviceTicketForm.value, fileData).subscribe(
        (response: any) => {
          this.isLoading$.next(false);
          this.responseMessage$.next(response.response);
        },
        (error) => {
          this.isLoading$.next(false);
          this.globalMessageService.add({ key: 'knBrMessages.error' }, GlobalMessageType.MSG_TYPE_ERROR);
        }
      );

      this.fileUploadControl.clear();
      this.initForm();
    } else {
      this.serviceTicketForm.markAllAsTouched();
    }
  }

  hideMsg() {
    this.responseMessage$.next('');
  }
}
