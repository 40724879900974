<!-- Item Start Outlet -->
<ng-template [cxOutlet]="CartOutlets.ITEM">
  <div [ngClass]="compact ? 'cx-compact row' : 'row'">
    <!-- Item Image -->
    <div class="col-2 cx-image-container">
      <a [routerLink]="{ cxRoute: 'product', params: item.product } | cxUrl" cxModal="dismiss" tabindex="-1">
        <ng-container *ngIf="item.product.images?.PRIMARY; else noPrimaryCorrection">
          <cx-media [container]="item.product.images?.PRIMARY" format="cartIcon"></cx-media>
        </ng-container>
      </a>
    </div>
    <ng-template #noPrimaryCorrection>
      <cx-media [container]="item.product.images" format="cartIcon"></cx-media>
    </ng-template>
    <!-- Item Information -->
    <div class="cx-info col-10" [ngClass]="compact ? '' : 'pl-0'">
      <div class="cx-info-container row">
        <!-- Item Description -->
        <div [ngClass]="compact ? '' : ' col-md-3 col-lg-3 col-xl-5'">
          <div *ngIf="item.product.name" class="cx-name">
            <a class="cx-link" [routerLink]="{ cxRoute: 'product', params: item.product } | cxUrl" cxModal="dismiss">{{
              item.product.name
            }}</a>
          </div>
          <div *ngIf="item.product.code" class="cx-code">
            {{ 'cartItems.id' | cxTranslate }}: {{ item.product.code }}
          </div>

          <div class="cx-code">{{ 'cartCommon.label.plant' | cxTranslate }}: {{ item.product?.manufacturer }}</div>

          <div *ngIf="item.requestedShipDate" class="cx-code">
            {{ 'cartCommon.label.requestedShipDate' | cxTranslate }}: {{ item.requestedShipDate }}
          </div>

          <div class="cx-code">
            {{ 'cartCommon.label.estimatedShipDate' | cxTranslate }}: {{ item.estimatedShipDate }}
          </div>

          <!-- Item Details Outlet -->
          <ng-template [cxOutlet]="CartOutlets.ITEM_DETAILS"> </ng-template>

          <!-- Variants -->
          <ng-container *ngIf="item.product.baseOptions?.length">
            <div
              *ngFor="let variant of item.product.baseOptions[0]?.selected?.variantOptionQualifiers"
              class="cx-property"
            >
              <div class="cx-label" *ngIf="variant.name && variant.value">{{ variant.name }}: {{ variant.value }}</div>
            </div>
          </ng-container>
        </div>
        <!-- Item Price -->
        <div *ngIf="item.knbrTotalPrice" class="cx-price" [ngClass]="compact ? '' : ' col-md-3 col-lg-3 col-xl-2'">
          <div class="cx-label" [ngClass]="compact ? '' : ' d-block d-md-none d-lg-none d-xl-none'">
            {{ 'cartItems.itemPrice' | cxTranslate }}
          </div>
          <div *ngIf="item.knbrTotalPrice" class="cx-value">
            {{ item.knbrTotalPrice?.formatedNetPrice }}
          </div>
        </div>
        <!-- Item Quantity -->
        <div class="cx-quantity" [ngClass]="compact ? '' : ' col-3 pl-0'">
          <div
            class="cx-label"
            [ngClass]="compact ? '' : ' d-block d-md-none d-lg-none d-xl-none'"
            placement="left"
            title="{{ 'cartItems.quantityTitle' | cxTranslate }}"
          >
            {{ 'cartItems.quantity' | cxTranslate }}
          </div>
          <div class="cx-value">
            {{ item.quantity }}
          </div>
        </div>
        <!-- Total -->
        <div *ngIf="item.totalPrice" class="cx-total" [ngClass]="compact ? '' : ' col-md-3 col-xl-2'">
          <div class="cx-label" [ngClass]="compact ? '' : ' d-block d-md-none d-lg-none d-xl-none'">
            {{ 'cartItems.total' | cxTranslate }}
          </div>
          <div class="cx-value">{{ item.totalPrice.formattedValue }}</div>
        </div>
      </div>
      <!-- Availability -->
      <div *ngIf="isProductOutOfStock(item.product)" class="cx-availability col-12">
        {{ 'addToCart.outOfStock' | cxTranslate }}
      </div>
      <!-- Promotion -->
      <!-- <ng-container *ngIf="appliedProductPromotions$ | async as appliedProductPromotions">
        <cx-promotions [promotions]="appliedProductPromotions"></cx-promotions>
      </ng-container> -->
    </div>
  </div>
</ng-template>
