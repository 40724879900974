import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslationService } from '@spartacus/core';
import { SloanCommonService } from 'src/app/services/sloan/sloan-common.service';
import { KnBrDateHelper } from 'src/app/shared/kn-br-date.helper';
import { KnBrCustomerContextService } from 'src/app/store/kn-br-customer-context/kn-br-customer-context.service';
import { KnBrInvoiceService } from 'src/feature-libs/kn-br-order/core/facade/kn-br-invoice.service';
import { KnBrQuickInvoiceComponent } from 'src/feature-libs/kn-br-order/components/kn-br-customer360/kn-br-customer360-home/kn-br-quick-invoice/kn-br-quick-invoice.component';

@Component({
  selector: 'knbr-sloan-quick-invoice',
  templateUrl: './sloan-quick-invoice.component.html',
  styleUrls: ['./sloan-quick-invoice.component.scss'],
})
export class SloanQuickInvoiceComponent extends KnBrQuickInvoiceComponent implements OnInit, OnDestroy {
  constructor(
    public knBrInvoiceService: KnBrInvoiceService,
    protected translation: TranslationService,
    protected knBrDateHelper: KnBrDateHelper,
    protected fb: FormBuilder,
    protected knBrCustomerContextService: KnBrCustomerContextService,
    protected commonService: SloanCommonService
  ) {
    super(knBrInvoiceService, translation, knBrDateHelper, fb, knBrCustomerContextService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  downloadInvoiceItemPDF(documentNumber: string) {
    this.commonService.downloadInvoiceItemPDF(documentNumber);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
