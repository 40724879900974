import { Component, OnDestroy, OnInit } from '@angular/core';
import { OCC_USER_ID_CURRENT, RoutingService } from '@spartacus/core';
import { ICON_TYPE } from '@spartacus/storefront';
import { Observable, Subscription } from 'rxjs';
import * as _ from 'lodash';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MultiCartFacade } from '@spartacus/cart/base/root';
import { DraftSearchForm } from 'src/feature-libs/kn-br-cart/draft-order/root/models/draft-order.model';
import { KnBrAddRequestEntry } from 'src/feature-libs/kn-br-order/root/models/kn-br-order.model';
import { KnBrCustomerContextService } from 'src/app/store/kn-br-customer-context/kn-br-customer-context.service';
import { KnBrCartContextService } from 'src/feature-libs/kn-br-cart/base/core/facade/kn-br-cart-context.service';
import { KnBrDraftOrdersService } from 'src/feature-libs/kn-br-cart/draft-order/core/facade/kn-br-draft-orders.service';
import { KnBrQuoteReferenceService } from 'src/feature-libs/kn-br-cart/quote/core/facade/kn-br-quote-reference.service';
import { CustomerData } from 'src/feature-libs/kn-br-user/kn-br-account/root/model/customer.model';

@Component({
  selector: 'kn-br-select-cart',
  templateUrl: './kn-br-select-cart.component.html',
  styleUrls: ['./kn-br-select-cart.component.scss'],
})
export class KnBrSelectCartComponent implements OnInit, OnDestroy {
  productCode: string;
  quantity: number;
  iconTypes = ICON_TYPE;
  cartCode: string;
  cartList$: Observable<any> = this.knBrDraftOrderListService.list$;
  customerContextData$: Observable<CustomerData> = this.knBrCustomerContextService.get$;
  subscription: Subscription;
  productList: KnBrAddRequestEntry[];
  customerNumber;
  constructor(
    protected modalService: NgbModal,
    protected routingService: RoutingService,
    protected knBrDraftOrderListService: KnBrDraftOrdersService,
    protected multiCartService: MultiCartFacade,
    protected knBrCartContextService: KnBrCartContextService,
    protected knBrCustomerContextService: KnBrCustomerContextService,
    protected knBrQuoteReferenceService: KnBrQuoteReferenceService
  ) {
    this.subscription = this.customerContextData$.subscribe((value) => {
      this.knBrDraftOrderListService.reset();
      if (value.customerNumber) {
        this.customerNumber = value.customerNumber;
        this.knBrDraftOrderListService.search({ accountNumber: value.customerNumber } as DraftSearchForm);
      }
    });
  }
  ngOnInit(): void { }
  goToCreateNewCart() {
    if (this.knBrQuoteReferenceService.pdpQuoteRef) {
      this.knBrCartContextService.pdpQuoteRef = _.cloneDeep(this.knBrQuoteReferenceService.pdpQuoteRef);
    }
    if (this.productList && this.productList.length > 0) {
      const createCartReq = {
        customerNumber: this.customerNumber,
        requestedDeliveryDate: null,
        purchaseOrderNumber: '',
        jobName: '',
        shipComplete: false,
        headerComments: '',
        referenceCartId: null,
        orderToolsProducts: this.productList,
      };
      this.knBrDraftOrderListService.create(createCartReq);
    } else {
      this.routingService.go({ cxRoute: 'createCart' }, { queryParams: { p: this.productCode, q: this.quantity } });
    }
    this.dismissModal();
  }
  saveCart() {
    this.knBrCartContextService.set(this.cartCode);
    if (this.productList && this.productList.length > 0) {
      const req = {
        cartId: this.cartCode,
        request: this.productList,
      };
      this.knBrDraftOrderListService.quickEntry(req);
      this.dismissModal();
    } else {
      this.multiCartService.addEntry(OCC_USER_ID_CURRENT, this.cartCode, this.productCode, this.quantity);
      this.dismissModal();
    }
  }
  dismissModal(reason?: any): void {
    this.modalService.dismissAll(reason);
  }
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
