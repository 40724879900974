<h2 class="cx-checkout-title d-none d-lg-block d-xl-block">
  {{ 'checkoutProgress.deliveryMode' | cxTranslate }}
</h2>

<ng-container *ngIf="(supportedDeliveryModes$ | async)?.length && !(isUpdating$ | async); else loading">
  <div role="status" [attr.aria-label]="'common.loaded' | cxTranslate"></div>
  <div [formGroup]="mode">
    <div class="row">
      <div class="col-md-12 col-lg-9" role="radiogroup">
        <div class="form-check" *ngFor="let mode of supportedDeliveryModes$ | async">
          <input
            [attr.aria-checked]="getAriaChecked(mode.code)"
            class="form-check-input"
            role="radio"
            type="radio"
            id="deliveryMode-{{ mode.code }}"
            (change)="changeMode(mode.code)"
            [value]="mode.code"
            formControlName="deliveryModeId"
          />
          <label class="cx-delivery-label form-check-label form-radio-label" for="deliveryMode-{{ mode.code }}">
            <div class="cx-delivery-mode">{{ mode.name }}</div>
            <div class="cx-delivery-price">
              {{ mode.deliveryCost.formattedValue }}
            </div>
            <div class="cx-delivery-details">{{ mode.description }}</div>
          </label>
        </div>
      </div>
    </div>

    <div class="row cx-checkout-btns">
      <div class="col-md-12 col-lg-6">
        <button class="btn btn-block btn-action" (click)="back()">
          {{ backBtnText | cxTranslate }}
        </button>
      </div>
      <div class="col-md-12 col-lg-6">
        <button class="btn btn-block btn-primary" [disabled]="deliveryModeInvalid" (click)="next()">
          {{ 'common.continue' | cxTranslate }}
        </button>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #loading>
  <div class="cx-spinner">
    <cx-spinner></cx-spinner>
  </div>
</ng-template>
