import { AsmComponentService, CustomerEmulationComponent } from '@spartacus/asm/components';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UserAccountFacade } from '@spartacus/user/account/root';

@Component({
  selector: 'cx-customer-emulation',
  templateUrl: './kn-br-customer-emulation.component.html',
  styleUrls: ['./kn-br-customer-emulation.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class KnBrCustomerEmulationComponent extends CustomerEmulationComponent implements OnInit, OnDestroy {
  constructor(protected asmComponentService: AsmComponentService, protected userService: UserAccountFacade) {
    super(asmComponentService, userService);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
