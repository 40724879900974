import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserAccountService } from '@spartacus/user/account/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { KnBrQuotesComments } from 'src/feature-libs/kn-br-cart/quote/root/models/kn-br-quote.model';
import { KnBrQuotesCommentsService } from 'src/feature-libs/kn-br-cart/quote/core/facade/kn-br-quotes-comments.service';
import { UserAccountFacade } from '@spartacus/user/account/root';

@Component({
  selector: 'knbr-kn-br-quote-comments-popup',
  templateUrl: './kn-br-quote-comments-popup.component.html',
  styleUrls: ['./kn-br-quote-comments-popup.component.scss'],
})
export class KnBrQuoteCommentsPopupComponent implements OnInit {
  comment: KnBrQuotesComments;
  entryNumber: number;
  comments$: Observable<KnBrQuotesComments[]> = this.knBrQuoteCommentService.list$;
  text: string;
  dateFormat$: Observable<any> = this.currentUserService.get().pipe(
    filter((user: any) => user && !!user.dateFormat),
    map((user: any) => user.dateFormat)
  );
  editable: boolean;
  constructor(
    protected currentUserService: UserAccountFacade,
    protected knBrQuoteCommentService: KnBrQuotesCommentsService,
    protected modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.knBrQuoteCommentService.get(this.entryNumber);
    this.comment = {
      comment: '',
      entryNumber: this.entryNumber,
    };
  }

  saveComment() {
    const comment = _.cloneDeep(this.comment);
    comment.comment = this.text;
    comment.entryNumber = this.entryNumber;
    if (comment.code) {
      this.knBrQuoteCommentService.update(comment);
    } else {
      this.knBrQuoteCommentService.create(comment);
    }
    this.resetComment();
  }
  editComment(comment) {
    if (this.editable) {
      this.comment = comment;
      this.text = comment.text;
    }
  }

  resetComment() {
    this.comment = {
      text: '',
      entryNumber: this.entryNumber,
    };
    this.text = '';
  }

  deleteComment(comment) {
    const commentObj = _.cloneDeep(comment);
    commentObj.entryNumber = this.entryNumber;
    this.knBrQuoteCommentService.delete(commentObj);
  }

  dismissModal(reason?: any): void {
    this.modalService.dismissAll(reason);
  }
}
