<ng-container *ngIf="classificationList$ | async as classificationList">
  <div class="container p-0">
    <h2>{{ 'productDetails.specification' | cxTranslate }}</h2>
    <table class="d-flex flex-wrap">
      <tr class="col-12 col-lg-6 d-flex float-left" *ngFor="let feature of classificationList">
        <td class="text-capitalize">{{ feature.name }}</td>
        <td class="text-capitalize">
          <ul [ngClass]="feature?.featureValues.length > 1 ? 'show-bulleted-list' : ''">
            <li *ngFor="let featureValue of feature?.featureValues">
              <div
                [innerHtml]="
                  featureValue?.value === 'true' || featureValue?.value === 'false'
                    ? featureValue?.value === 'true'
                      ? 'Yes'
                      : 'No'
                    : featureValue?.value
                "
              ></div>
              <span *ngIf="feature.featureUnit?.symbol?.length > 0 && feature.featureUnit.unitType != '300'">
                {{ feature.featureUnit.symbol }}
              </span>
            </li>
          </ul>
        </td>
      </tr>
    </table>
  </div>
</ng-container>
