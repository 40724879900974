<ng-container *ngIf="certificationList$ | async as certificationList">
  <div class="container p-0">
    <h2>{{ 'productDetails.specification' | cxTranslate }}</h2>
    <table class="d-flex flex-wrap">
      <ng-container *ngFor="let feature of certificationList">
        <tr class="col-12 col-lg-6 col-md-6 d-flex float-left" *ngFor="let featureValue of feature?.featureValues">
          <td class="col-12 float-left">
            <div
              class="certification-name w-100 d-flex align-items-center text-capitalize"
              [innerHtml]="featureValue?.value"
            ></div>
          </td>
        </tr>
      </ng-container>
    </table>
  </div>
</ng-container>
