import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CurrentProductService, ProductAttributesComponent } from '@spartacus/storefront';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'cx-product-certifications-tab',
  templateUrl: './sloan-product-certifications-tab.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SloanProductCertificationsTabComponent extends ProductAttributesComponent {
  certificationList$: Observable<any[]> = this.product$.pipe(map((p: any) => this.extractCertifications(p)));

  constructor(protected currentProductService: CurrentProductService) {
    super(currentProductService);
  }

  protected extractCertifications(product: any): Observable<any>[] {
    if (!product.classifications || product.classifications.length < 1) {
      return [];
    }

    return _.remove(_.flatMap(product.classifications, 'features'), (obj) => _.includes(obj.code, 'certifications_l'));
  }
}
