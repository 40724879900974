<form class="cx-place-order-form form-check" [formGroup]="checkoutSubmitForm">
  <div class="form-group">
    <label>
      <input formControlName="termsAndConditions" class="scaled-input form-check-input" type="checkbox" />
      <span class="form-check-label">
        {{ 'label.sloanConfirmThatRead' | cxTranslate }}
        <a [routerLink]="{ cxRoute: 'termsOfUse' } | cxUrl" class="cx-tc-link" rel="noopener noreferrer">
          {{ 'checkoutReview.termsAndConditions' | cxTranslate }}
        </a>
      </span>
    </label>
  </div>

  <button
    (click)="submitForm()"
    class="btn btn-primary btn-block"
    [disabled]="termsAndConditionInvalid || (isASMLoggedIn$ | async)"
    [cxAtMessage]="'checkoutReview.orderInProcess' | cxTranslate"
  >
    {{ 'checkoutReview.placeOrder' | cxTranslate }}
  </button>
</form>
