import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Card, CardComponent } from '@spartacus/storefront';
import { KnBrAddressService } from 'src/feature-libs/kn-br-order/core/facade/kn-br-address.service';
import { SloanAddressFormComponent } from 'src/feature-libs/sloan/sloan-order/sloan-customer360/sloan-address-book/sloan-address-form/sloan-address-form.component';

@Component({
  selector: 'cx-address-card',
  templateUrl: './sloan-shipping-address-checkout.component.html',
  styleUrls: ['./sloan-shipping-address-checkout.component.scss'],
})
export class SloanShippingAddressCheckoutComponent extends CardComponent implements OnInit {
  @Output()
  deleteCard: EventEmitter<number> = new EventEmitter();
  @Output()
  setDefaultCard: EventEmitter<number> = new EventEmitter();
  @Output()
  sendCard: EventEmitter<number> = new EventEmitter();
  @Output()
  editCard: EventEmitter<number> = new EventEmitter();
  @Output()
  cancelCard: EventEmitter<number> = new EventEmitter();

  @Input()
  border = false;

  @Input()
  editMode = false;

  @Input()
  isDefault = false;

  @Input()
  content: Card;

  @Input()
  fitToContainer = false;

  @Input()
  truncateText = false;

  @Input()
  charactersLimit = 100;

  constructor(protected modalService: NgbModal, protected knBrAddressService: KnBrAddressService) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
  openEditAddress(content?: any) {
    let selectedAddress = content;
    selectedAddress['editAddress'] = true;
    // this.knBrAddressService.setSelectedAddress(selectedAddress);
    let modalInstance: any;
    const modalRef = this.modalService.open(SloanAddressFormComponent, {
      centered: true,
      size: 'lg',
    });
    modalInstance = modalRef.componentInstance;
    modalInstance.isCheckOut = true;
    modalInstance.showCancelBtn = true;
    modalInstance.selectedAddress = selectedAddress;
    modalRef.result
      .then((address) => {
        if (address && address.isOneTime) {
          const onetimeaddress = {
            ...address.address,
            isDisposable: true,
          };
          this.knBrAddressService.createOneTimeAddress(onetimeaddress);
        } else {
        }
      })
      .catch((error) => { });
  }
}
