import { NgModule } from '@angular/core';
import { provideConfig } from '@spartacus/core';
import { environment } from 'src/environments/environment';

@NgModule({
  providers: [
    provideConfig({
      authentication: {
        client_id: environment.AUTHENTICATION_CLIENT_ID,
      },
    }),
  ],
})
export class SloanConfigurationModule {}
