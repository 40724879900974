import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { GlobalMessageService, TranslationService } from '@spartacus/core';
import { OrderConfirmationThankYouMessageComponent } from '@spartacus/order/components';
import { OrderFacade } from '@spartacus/order/root';

@Component({
  selector: 'cx-order-confirmation-thank-you-message',
  templateUrl: './sloan-order-confirmation-thank-you-message.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SloanOrderConfirmationThankYouMessageComponent
  extends OrderConfirmationThankYouMessageComponent
  implements OnInit, OnDestroy
{
  closed = false;
  constructor(
    protected orderFacade: OrderFacade,
    protected globalMessageService: GlobalMessageService,
    protected translationService: TranslationService
  ) {
    super(orderFacade, globalMessageService, translationService);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
