import { ChangeDetectorRef, Component, OnInit, OnDestroy } from '@angular/core';
import { CmsService } from '@spartacus/core';
import { SloanCommonService } from 'src/app/services/sloan/sloan-common.service';
import { KnBrQuoteItemsComponent } from 'src/feature-libs/kn-br-cart/quote/components/kn-br-quote/kn-br-quote-detail/kn-br-quote-items/kn-br-quote-items.component';
import { KnBrQuoteEntryService } from 'src/feature-libs/kn-br-cart/quote/core/facade/kn-br-quote-entry.service';
import { KnBrQuotesService } from 'src/feature-libs/kn-br-cart/quote/core/facade/kn-br-quotes.service';

@Component({
  selector: 'kn-br-quote-items',
  templateUrl: './sloan-quote-items.component.html',
  styleUrls: ['./sloan-quote-items.component.scss'],
})
export class SloanQuoteItemsComponent extends KnBrQuoteItemsComponent implements OnInit, OnDestroy {
  constructor(
    protected knBrQuoteService: KnBrQuotesService,
    protected ref: ChangeDetectorRef,
    protected cmsService: CmsService,
    protected sloanCommonService: SloanCommonService,
    protected knBrQuoteEntryService: KnBrQuoteEntryService
  ) {
    super(knBrQuoteService, ref, cmsService);
    this.sloanCommonService.getSloanHolidayList();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
