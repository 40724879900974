import { Injectable } from '@angular/core';
import { Converter, ConverterService, EntitiesModel, Occ } from '@spartacus/core';
import { B2B_USER_NORMALIZER } from '@spartacus/organization/administration/core';
import { KnBrB2BUser } from '../../model/kn-br-b2b-user.model';

@Injectable()
export class KnBrB2BUserListNormalizer implements Converter<Occ.OrgUnitUserList, EntitiesModel<KnBrB2BUser>> {
  constructor(private converter: ConverterService) { }

  convert(source: Occ.OrgUnitUserList, target?: EntitiesModel<KnBrB2BUser>): EntitiesModel<KnBrB2BUser> {
    if (target === undefined) {
      target = { ...(source as any) };
    }
    target.values = source.users.map((b2bUser) => ({
      ...this.converter.convert(b2bUser, B2B_USER_NORMALIZER),
    }));
    return target;
  }
}
