import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { shareReplay } from 'rxjs/operators';
import { KnBrQuote } from 'src/feature-libs/kn-br-cart/quote/root/models/kn-br-quote.model';
import { KnBrCustomerContextService } from 'src/app/store/kn-br-customer-context/kn-br-customer-context.service';
import { KN_BR_CUSTOMER_CURRENT } from 'src/constants/api.endpoints.constant';
import { KnBrQuoteContextService } from '../facade/kn-br-quote-context.service';

@Injectable({
  providedIn: 'root',
})
export class KnBrQuotesAdapter {
  private customerNumber: string;
  quoteId: string;
  constructor(
    private endPointService: OccEndpointsService,
    private httpClient: HttpClient,
    protected knBrCustomerContextService: KnBrCustomerContextService,
    protected knBrQuoteContextService: KnBrQuoteContextService
  ) {
    this.knBrCustomerContextService.getActiveCustomerNumber().subscribe((response) => {
      this.customerNumber = response ? response : null;
    });
    this.knBrQuoteContextService.get$.subscribe((response) => {
      this.quoteId = response ? response : null;
    });
  }

  search(quoteSearch: KnBrQuote) {
    const CUSTOMER_NUMBER = this.customerNumber ? this.customerNumber : KN_BR_CUSTOMER_CURRENT;
    return this.httpClient.get(
      this.endPointService.buildUrl('quotes', {
        urlParams: {
          customerId: CUSTOMER_NUMBER,
        },
        queryParams: quoteSearch,
      })
    );
  }
  create(payload: KnBrQuote) {
    return this.httpClient.post(
      this.endPointService.buildUrl('createQuote', {
        urlParams: {
          customerId: this.customerNumber,
        },
      }),
      payload
    );
  }

  copy(payload: KnBrQuote) {
    return this.httpClient.post(
      this.endPointService.buildUrl('quoteClone', {
        urlParams: {
          customerId: this.customerNumber,
          quoteId: payload.referenceQuoteId,
        },
      }),
      null
    );
  }

  update(payload: KnBrQuote) {
    return this.httpClient
      .put(
        this.endPointService.buildUrl('quote', {
          urlParams: {
            customerId: this.customerNumber,
            quoteId: payload?.code ? payload.code : this.quoteId,
          },
        }),
        payload
      )
      .pipe(shareReplay());
  }

  get(payload: KnBrQuote) {
    const payloadObj = payload ? payload : [];
    return this.httpClient
      .get(
        this.endPointService.buildUrl('quote', {
          urlParams: {
            customerId: this.customerNumber,
            quoteId: payload?.code ? payload.code : this.quoteId,
          },
        }),
        payloadObj as any
      )
      .pipe(shareReplay());
  }

  getStatuses(quoteCode?: string) {
    return this.httpClient
      .get(
        this.endPointService.buildUrl('quoteStatuses', {
          urlParams: {
            customerId: this.customerNumber,
            quoteId: this.quoteId ? this.quoteId : quoteCode,
          },
        })
      )
      .pipe(shareReplay());
  }

  acceptAndCheckout(payload: KnBrQuote) {
    return this.httpClient.post(
      this.endPointService.buildUrl('quoteAcceptCheckout', {
        urlParams: {
          customerId: this.customerNumber,
          quoteId: payload?.code ? payload.code : this.quoteId,
        },
      }),
      null
    );
  }
}
