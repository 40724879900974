import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SloanDraftOrderDetailModule } from './sloan-draft-order-detail/sloan-draft-order-detail.module';
import { ConfigModule } from '@spartacus/core';
import { sloanDraftOrderEndpointsConfig } from './sloan-draft-order-endpoints.config';
import { SloanDraftOrderCreateModule } from './sloan-draft-order-create/sloan-draft-order-create.module';
import { SloanDraftOrderListModule } from './sloan-draft-order-list/sloan-draft-order-list.module';
import { SloanDraftOrderLayoutConfigModule } from './sloan-draft-order-layout.config';
import { defaultSpinnerLayoutConfig } from './default-spinner-layout.config';
import { SloanFreightPopUpDialogModule } from './sloan-freight-pop-up-dialog/sloan-freight-pop-up-dialog.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SloanDraftOrderListModule,
    SloanDraftOrderCreateModule,
    SloanDraftOrderDetailModule,
    ConfigModule.withConfig(sloanDraftOrderEndpointsConfig),
    ConfigModule.withConfig(defaultSpinnerLayoutConfig),
    SloanDraftOrderLayoutConfigModule,
    SloanFreightPopUpDialogModule,
  ],
})
export class SloanDraftOrderModule {}
