import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { AuthGuard, CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { CardModule, FormErrorsModule, IconModule, SpinnerModule } from '@spartacus/storefront';
import { AddressFormModule } from "@spartacus/user/profile/components";
import { NoCurlyBracesInputDirective } from 'src/app/shared/directive/sloan/sloan-no-curly-braces.directive';
import { KnBrCustomerContextGuard } from 'src/app/shared/guards/kn-br-customer-context.guard';
import { KnBrAddressBookModule } from 'src/feature-libs/kn-br-order/components/kn-br-customer360/kn-br-address-book/kn-br-address-book.module';
import { KnBrCustomer360Module } from 'src/feature-libs/kn-br-order/components/kn-br-customer360/kn-br-customer360.module';
import { SloanAddressBookComponent } from './sloan-address-book.component';
import { SloanAddressCardComponent } from './sloan-address-card/sloan-address-card.component';
import { SloanAddressFormComponent } from './sloan-address-form/sloan-address-form.component';

@NgModule({
  declarations: [
    SloanAddressBookComponent,
    SloanAddressCardComponent,
    SloanAddressFormComponent,
    NoCurlyBracesInputDirective,
  ],
  imports: [
    CommonModule,
    I18nModule,
    CardModule,
    AddressFormModule,
    SpinnerModule,
    ReactiveFormsModule,
    FormsModule,
    FormErrorsModule,
    NgSelectModule,
    IconModule,
    KnBrAddressBookModule,
    KnBrCustomer360Module,
    ConfigModule.withConfig({
      cmsComponents: {
        AccountAddressBookComponent: {
          component: SloanAddressBookComponent,
          guards: [AuthGuard, KnBrCustomerContextGuard],
        },
      },
    } as CmsConfig),
  ],
})
export class SloanAddressBookModule { }
