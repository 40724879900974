export const addressBook = {
  addNewShippingAddress: 'Add a new shipping address',
  editShippingAddress: 'Edit shipping address',
  areYouSureToDeleteAddress: 'Are you sure you want to delete this address?',
  addNewAddress: 'Add new address',
  addAddress: 'Add address',
  updateAddress: 'Update address',
  backToAddressList: 'Back to address list',
};
export const knbrAddressBook = {
  button: {
    addAddress: 'Add Address',
    save: 'Save',
    cancel: 'Cancel',
    update: 'Update',
  },
  heading: {
    addAddress: 'Add Address',
    editAddress: 'Edit Address',
  },
}
export const addressCard = {
  default: 'Default',
  selected: 'Selected',
  setAsDefault: 'Set as default',
  shipTo: 'Ship To',
  billTo: 'Bill To',
  phoneNumber: 'Phone number',
  mobileNumber: 'mobile number',
};

export const addressForm = {
  title: 'Title',
  firstName: {
    label: 'First Name',
    placeholder: 'First Name',
  },
  lastName: {
    label: 'Last Name',
    placeholder: 'Last Name',
  },
  address1: 'Address 1',
  address2: 'Address 2 (Optional)',
  country: 'Country',
  city: {
    label: 'City',
    placeholder: 'City',
  },
  state: 'State',
  zipCode: {
    label: 'Zip Code',
    placeholder: 'Postal Code/Zip',
  },
  phoneNumber: {
    label: 'Phone Number (Optional)',
    placeholder: '(555) 555 - 0123',
  },
  saveAsDefault: 'Save as default',
  chooseAddress: 'Choose address',
  streetAddress: 'Street Address',
  aptSuite: 'Apt, Suite',
  selectOne: 'Select One...',
  setAsDefault: 'Set as default',
  titleRequired: 'Title is required.',
  userAddressAddSuccess: 'New address was added successfully!',
  userAddressUpdateSuccess: 'Address updated successfully!',
  userAddressDeleteSuccess: 'Address deleted successfully!',
  invalidAddress: 'Address is not valid. Please verify your input and try again or proceed with this address.',
};
export const knbrAddressForm = {
  label: {
    name: 'Name',
    bldg: 'ATTN/BLDG/STE',
    houseNumberAndStreetName: 'House Number/ Street Name',
    cityAndPostalCode: 'City / Postal Code',
    email: 'Email',
    telephone: 'Telephone',
    fax: 'Fax',
    mandatoryFields: ' You Must Fill Out These Fields',
  },
  placeholder: {
    name: 'Name',
    bldg: 'ATTN/BLDG/STE',
    houseNumber: 'House Number',
    streetName: 'Street Name',
    select: 'Select',
    postalCode: 'Postal Code',
    email: 'Email',
    telephone: 'Telephone',
    fax: 'Fax',
  },
  button: {
    onetime: 'One Time Use',
    cancel: 'Cancel',
  },
  onetime: {
    successMsg: 'OneTime Address created successfully',
  },
}
export const addressMessages = {
  "setAsDefaultSuccessfully": "Address {{ streetAddress }} was successfully set as default"
};
export const address = {
  addressBook,
  knbrAddressBook,
  addressForm,
  knbrAddressForm,
  addressCard,
  addressMessages
};
