import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';
import { SloanCommonService } from './sloan-common.service';
import { PublicHoliday } from 'src/app/models/sloan/sloan-holidays.model';

@Injectable({
  providedIn: 'root',
})
export class SloanCalendarHelper {
  holidays: PublicHoliday[] = [];
  constructor(protected ngbCalendar: NgbCalendar, protected sloanCommonService: SloanCommonService) {
    this.sloanCommonService.loadSloanHolidayList$().subscribe((res) => {
      this.holidays = res ? res : [];
    });
  }

  getFirstAvailableDate(date: NgbDate): NgbDate {
    while (this.isWeekend(date) || this.isHoliday(date)) {
      date = this.ngbCalendar.getNext(date, 'd', 1);
    }
    return date;
  }

  isHoliday(date: NgbDate): boolean {
    const holiday = this.holidays.find(
      (h) => h.day === date.day && h.month === date.month && date.year >= h.yearFrom && date.year <= h.yearTo
    );
    return holiday && holiday.code ? true : false;
  }

  isWeekend(date: NgbDate) {
    return this.ngbCalendar.getWeekday(date) >= 6;
  }

  get today(): NgbDate {
    return this.ngbCalendar.getToday();
  }

  getRddMinDate(date): NgbDate {
    return this.ngbCalendar.getNext(date, 'd', 1);
  }

  getRddMaxDate(date): NgbDate {
    return this.ngbCalendar.getNext(date, 'm', 10);
  }

  rddDefaultDate(date, weekDaysToAdd?): NgbDate {
    weekDaysToAdd = weekDaysToAdd ? weekDaysToAdd : 3;
    while (weekDaysToAdd > 0) {
      date = this.ngbCalendar.getNext(date, 'd', 1);
      if (!this.isWeekend(date) && !this.isHoliday(date)) {
        weekDaysToAdd--;
      }
    }
    return date;
  }
}
