<ng-container>
  <div class="product-main-grid col-md-12 p-0 d-flex" *ngIf="quoteSearchResult$ | async as quoteSearchResult">
    <div class="col-lg-12 product-list-view p-0">
      <div class="row d-flex pt-4">
        <div class="col-sm-12 col-md-8 col-lg-10 justify-content-start" *ngIf="paginate$ | async as paginate">
          <kn-br-browsing
            [paginate]="paginate"
            [recordsShown]="quoteSearchResult.length"
            recordType="{{ 'label.quotes' | cxTranslate }}"
          >
          </kn-br-browsing>
        </div>
      </div>
      <div class="row counter-wrapper" *ngIf="quoteSearchResult && quoteSearchResult.length && !(isLoading$ | async)">
        <div class="customersearch-list-table float-left col-lg-12 mt-3">
          <div
            class="customersearch-list-titles border-top float-left w-100 pl-2 pr-2 pt-3 pb-3 d-none d-sm-none d-lg-block"
          >
            <div class="col-md-1 padding-res-0 float-left font-text-600 pl-0 font-sm-size">
              {{ 'quoteResult.header.quoteNumber' | cxTranslate }}
            </div>
            <div
              class="col-md-1 float-left font-text-600 pl-0 font-sm-size"
              [class.col-md-1]="copyAction || enableCheckboxFlag"
              [class.col-md-2]="!copyAction && !enableCheckboxFlag"
            >
              {{ 'quoteResult.header.createdBy' | cxTranslate }}
            </div>
            <div class="col-md-1 float-left font-text-600 pl-0 font-sm-size">
              {{ 'quoteResult.header.createdOn' | cxTranslate }}
            </div>
            <div class="col-md-1 float-left font-text-600 pl-0 font-sm-size">
              {{ 'quoteResult.header.accountNo' | cxTranslate }}
            </div>
            <div class="col-md-2 float-left font-text-600 pl-0 font-sm-size">
              {{ 'quoteResult.header.accountName' | cxTranslate }}
            </div>
            <div class="col-md-1 float-left font-text-600 pl-0 font-sm-size">
              {{ 'quoteResult.header.customerPO' | cxTranslate }}
            </div>
            <div class="col-md-1 float-left font-text-600 pl-0 font-sm-size">
              {{ 'quoteResult.header.job' | cxTranslate }}
            </div>
            <div class="col-md-1 float-left font-text-600 pl-0 font-sm-size">
              {{ 'quoteResult.header.status' | cxTranslate }}
            </div>
            <!-- <div class="col-md-1 float-left font-text-600 pl-0 font-sm-size">
              {{ 'quoteResult.header.validFrom' | cxTranslate }}
            </div> -->
            <div class="col-md-1 float-left font-text-600 pl-0 font-sm-size">
              {{ 'quoteResult.header.validTo' | cxTranslate }}
            </div>
            <div class="col-md-1 float-left font-text-600 pl-0 font-sm-size">
              {{ 'quoteResult.header.total' | cxTranslate }}
            </div>
            <div
              *ngIf="copyAction && !enableCheckboxFlag"
              class="col-md-1 text-center float-left font-text-600 pl-0 font-sm-size"
            >
              {{ 'quoteResult.header.actions' | cxTranslate }}
            </div>
            <div
              class="col-md-1 text-center float-left font-text-600 pl-0 font-sm-size pr-0"
              *ngIf="enableCheckboxFlag"
            >
              <div class="text-center padding-res-0 float-left w-100">
                <div class="remember-me col-md-12 float-left mb-3 d-flex justify-content-center p-0">
                  <div class="form-check custom-checkbox m-0">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="delet"
                      [checked]="allChecked"
                      (change)="selectAll($event)"
                    />
                    <label class="custom-control-label color-font" for="delet"></label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="bg-white customersearch-list-titles table-content border-top float-left w-100 p-2 font-weight-normal d-flex align-items-center"
            *ngFor="let quote of quoteSearchResult"
            (click)="redirectToDetail($event, quote)"
          >
            <div class="col-md-1 float-left title-wrap pl-0 font-sm-size color-font">
              <span class="res-titles"> {{ 'quoteResult.header.quoteNumber' | cxTranslate }}</span>
              {{ quote.code | removeLeadingZeros }}
            </div>

            <div
              class="col-md-1 float-left title-wrap pl-0 font-size color-font"
              [class.col-md-1]="copyAction || enableCheckboxFlag"
              [class.col-md-2]="!copyAction && !enableCheckboxFlag"
            >
              <span class="res-titles"> {{ 'quoteResult.header.createdBy' | cxTranslate }}</span>
              {{ quote.createdBy }}
            </div>
            <div class="col-md-1 float-left title-wrap pl-0 font-size color-font">
              <span class="res-titles"> {{ 'quoteResult.header.createdOn' | cxTranslate }}</span>
              {{ quote.creationDate }}
            </div>
            <div class="col-md-1 float-left title-wrap pl-0 font-size color-font">
              <span class="res-titles"> {{ 'quoteResult.header.accountNo' | cxTranslate }} </span>
              {{ quote.accountNumber | removeLeadingZeros }}
            </div>
            <div class="col-md-2 float-left title-wrap product-name pl-0 font-size color-font">
              <span class="res-titles"> {{ 'quoteResult.header.accountName' | cxTranslate }}</span>
              {{ quote.accountName }}
            </div>
            <div class="col-md-1 float-left title-wrap pl-0 font-size color-font">
              <span class="res-titles"> {{ 'quoteResult.header.customerPO' | cxTranslate }}</span>
              {{ quote.purchaseOrderNumber }}
            </div>
            <div class="col-md-1 float-left title-wrap pl-0 font-size color-font">
              <span class="res-titles"> {{ 'quoteResult.header.job' | cxTranslate }}</span>
              {{ quote.jobName }}
            </div>
            <div class="col-md-1 float-left title-wrap pl-0 font-size color-font">
              <span class="res-titles"> {{ 'quoteResult.header.status' | cxTranslate }}</span> {{ quote.status }}
            </div>
            <!-- <div class="col-md-1 float-left title-wrap pl-0 font-size color-font">
              <span class="res-titles"> {{ 'quoteResult.header.validFrom' | cxTranslate }}</span>
              {{ quote.quoteValidFrom }}
            </div> -->
            <div class="col-md-1 float-left title-wrap pl-0 font-size color-font">
              <span class="res-titles"> {{ 'quoteResult.header.validTo' | cxTranslate }}</span>
              {{ quote.quoteValidTo }}
            </div>
            <div class="col-md-1 float-left title-wrap pl-0 font-size color-font">
              <span class="res-titles"> {{ 'quoteResult.header.total' | cxTranslate }}</span>
              {{ quote.totalPrice?.formattedValue }}
            </div>
            <div
              (debounceClick)="copyQuoteConent(quote)"
              [debounceTime]="700"
              class="col-md-1 text-center float-left title-wrap pl-0 font-size color-font copy-action"
              [ngbTooltip]="copyButtonTip"
              *ngIf="copyAction && !enableCheckboxFlag"
              appDebounceClick
            >
              <span class="res-titles"> {{ 'quoteResult.header.actions' | cxTranslate }}</span>
              <i class="far fa-copy fa-lg"></i>
            </div>
            <div
              class="col-md-1 remember-me w-100 custom-control float-left d-flex justify-content-center cursor-pointer mr-3 pl-0 pr-0"
              *ngIf="enableCheckboxFlag"
            >
              <div class="form-check custom-checkbox mb-0">
                <input
                  type="checkbox"
                  [id]="quote.code"
                  class="custom-control-input rounded cursor-pointer"
                  [value]="quote.code"
                  [checked]="isChecked(quote.code)"
                  (change)="changeCheckBox($event)"
                />
                <label class="custom-control-label color-font" [for]="quote.code" (click)="$event.stopPropagation()">
                </label>
              </div>
            </div>
          </div>
        </div>
        <ng-container *ngIf="paginate$ | async as paginate">
          <div class="d-flex justify-content-center w-100 mt-3">
            <div
              *ngIf="paginate.currentPage < paginate.totalPages - 1 && !(isLoading$ | async)"
              (click)="loadMore(paginate.currentPage)"
              class="btn btn-loadmore mt-0 mr-3"
            >
              {{ 'cartResult.showMore' | cxTranslate }}
            </div>
          </div>
        </ng-container>
      </div>

      <div
        class="alert-info p-3 rounded mt-3"
        *ngIf="quoteSearchResult && quoteSearchResult.length < 1 && !(isLoading$ | async)"
      >
        {{ 'quoteResult.nodataMsg' | cxTranslate }}
      </div>
    </div>
  </div>
</ng-container>
<ng-template #copyButtonTip>{{ 'toolTip.copy' | cxTranslate }}</ng-template>
<ng-container *ngIf="!(quoteSearchResult$ | async) || (isLoading$ | async)">
  <cx-spinner></cx-spinner>
</ng-container>
