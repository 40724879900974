import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SloanQuoteSearchResultComponent } from './sloan-quote-search-result.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { I18nModule, ConfigModule, CmsConfig } from '@spartacus/core';
import { SpinnerModule } from '@spartacus/storefront';
import { KnBrCustomPipesModule } from 'src/app/shared/pipe/kn-br-custom-pipes/kn-br-custom-pipes.module';
import { KnBrBrowsingModule } from 'src/app/common/kn-br-browsing/kn-br-browsing.module';
import { KnBrDirectiveModule } from 'src/app/shared/directive/kn-br-directive.module';

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    SpinnerModule,
    NgbTooltipModule,
    KnBrCustomPipesModule,
    KnBrDirectiveModule,
    KnBrBrowsingModule,
    ConfigModule.withConfig({
      cmsComponents: {
        KnBrQuoteSearchResultComponent: {
          component: SloanQuoteSearchResultComponent,
        },
      },
    } as CmsConfig),
  ],
  declarations: [SloanQuoteSearchResultComponent],
})
export class SloanQuoteSearchResultModule {}
