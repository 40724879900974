export const checkoutAddress = {
  shippingAddress: 'Shipping Address',
  selectYourDeliveryAddress: 'Select your Shipping Address',
  defaultDeliveryAddress: 'Default Shipping Address',
  verifyYourAddress: 'Verify your address',
  ensureAccuracySuggestChange: 'To ensure delivery accuracy, we suggest the change selected below.',
  chooseAddressToUse: 'Please choose which address you would like to use:',
  suggestedAddress: 'Suggested Address',
  enteredAddress: 'Entered Address',
  addNewAddress: 'Add New Address',
  shipToThisAddress: 'Ship to this address',
  editAddress: 'Edit Address',
  saveAddress: 'Save Address',
  backToCart: 'Back To Cart',
  deliveryAddressSelected: 'Delivery address selected',
};
export const checkoutReview = {
  review: "Review",
  orderItems: "Order Items",
  autoReplenishOrder: "Auto Replenish Order",
  confirmThatRead: "I am confirming that I have read and agreed with the",
  sloanConfirmThatRead: "By placing the order, I am confirming that I have read and agree with the",
  placeOrder: "Place Order",
  scheduleReplenishmentOrder: "Schedule Replenishment Order",
  orderType_PLACE_ORDER: "None (one time order only)",
  orderType_SCHEDULE_REPLENISHMENT_ORDER: "Replenish Order",
  termsAndConditions: "Terms & Conditions",
  editShippingAddress: "Edit shipping address",
  poNumber: "Customer P.O.",
  every: "Every",
  startOn: "Start On",
  dayOfMonth: "On Day",
  repeatOnDays: "Repeat on the following days",
  recurrencePeriodType_DAILY: "Day(s)",
  recurrencePeriodType_WEEKLY: "Week(s)",
  recurrencePeriodType_MONTHLY: "Month"
};

export const checkoutShipping = {
  shippingMethod: 'Shipping Method',
  standardDelivery: 'Standard Delivery',
  premiumDelivery: 'Premium Delivery',
  accountNumberMandatory: 'Account Number must be provided for the selected Shipping Method.',
  shippingNotes: 'Shipping Notes',
  shippingDetail: 'Shipping Detail',
  shippingAccountNumber: 'Collect Account Number',
};
export const checkoutType = {
  backToCart: 'Back to cart',
  invalid: {
    accountType: 'Your account does not allow you to checkout here.',
  },
};
export const checkoutPO = {
  noPoNumber: 'None',
  poNumber: 'Purchase order number (optional)',
  costCenter: 'Cost Center',
  placeholder: 'Enter P.O',
  availableLabel: "Shipping addresses available to you depend on the cost center's unit",
};
export const checkoutProgress = {
  methodOfPayment: 'Payment Method',
  deliveryMode: 'Delivery Mode',
  paymentDetails: 'Payment Details',
  reviewOrder: 'Review Order',
  deliveryAddress: 'Shipping Address',
};
export const checkoutMode = {
  deliveryMethod: 'Delivery Mode',
  standardDelivery: 'Standard Delivery',
  premiumDelivery: 'Premium Delivery',
  accountNumberMandatory123: "Account Number must be provided for the selected Delivery Mode.",
  deliveryNote: "Delivery Note",
};
export const orderCost = {
  orderSummary: 'Order Summary',
  subtotal: 'Subtotal after discounts:',
  shipping: 'Shipping:',
  estimatedShipping: 'Estimated shipping:',
  discount: 'You saved:',
  salesTax: 'Sales Tax:',
  grossTax: 'The order total does not include tax of',
  grossIncludeTax: 'The order total includes tax of',
  total: 'Total:',
  toBeDetermined: 'TBD',
};
export const deliveryMode = {
  accountNumberMandatory: "Account Number must be provided for the selected Shipping Mode.",
  deliveryNote: "Shipping Notes",
};
export const checkout = {
  checkoutAddress,
  checkoutReview,
  checkoutShipping,
  checkoutType,
  checkoutPO,
  checkoutProgress,
  checkoutMode,
  deliveryMode,
  orderCost,
};
