<div
  class="
    bg-white
    customersearch-list-titles
    table-content
    border-top
    float-left
    w-100
    p-3
    font-weight-normal
    d-flex
    align-items-center
  "
  *ngIf="item"
>
  <div class="col-md-1 text-center title-wrap padding-res-0 float-left">
    <cx-media [container]="item.product?.images" format="product"></cx-media>
  </div>
  <div class="col-md-2 float-left title-wrap font-size color-font">
    <span class="res-titles"> {{ 'knBrOrderReturn.code' | cxTranslate }}</span>
    {{ item.product?.code }}
  </div>
  <div class="col-md-3 float-left title-wrap font-size color-font">
    <span class="res-titles"> {{ 'knBrOrderReturn.description' | cxTranslate }}</span>
    {{ item.product?.name }}
  </div>
  <div class="col-md-2 float-left title-wrap font-size color-font">
    <span class="res-titles">{{ 'knBrOrderReturn.price' | cxTranslate }} </span>
    {{ item.basePrice?.formatedNetPrice }}
  </div>
  <div class="col-md-2 float-left title-wrap font-size color-font">
    <span class="res-titles">{{ 'knBrOrderReturn.qty' | cxTranslate }} </span>
    {{ item.quantity }}
  </div>
  <div class="col-md-2 float-left title-wrapfont-size color-font">
    <span class="res-titles"> {{ 'knBrOrderReturn.returnQty' | cxTranslate }} </span>
    <input
      type="text"
      [formControl]="quantity"
      class="form-control w-100"
      (blur)="onchageQuantity($event)"
      knBrAllowNumber
    />
  </div>
</div>
