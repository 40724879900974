import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslationService } from '@spartacus/core';
import * as _ from 'lodash';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { B2bDocuments } from 'src/feature-libs/kn-br-order/root/models/kn-br-invoice.model';
import { KnBrInvoiceService } from 'src/feature-libs/kn-br-order/core/facade/kn-br-invoice.service';

@Component({
  selector: 'app-kn-br-invoice-list-seach-result',
  templateUrl: './kn-br-invoice-list-seach-result.component.html',
  styleUrls: ['./kn-br-invoice-list-seach-result.component.scss'],
})
export class KnBrInvoiceListSeachResultComponent implements OnInit, OnDestroy {
  invoiceResult$: Observable<B2bDocuments[]> = this.knBrInvoiceService.list$;
  isLoader$: Observable<boolean> = this.knBrInvoiceService.loader$;
  paginate$: Observable<any> = this.knBrInvoiceService.paginate$;
  sorts$: Observable<any> = this.knBrInvoiceService.sorts$;
  searchCriteria: any;
  sortType: string;
  subscription: Subscription;
  constructor(public knBrInvoiceService: KnBrInvoiceService, protected translation: TranslationService) { }

  ngOnInit(): void {
    this.subscription = this.knBrInvoiceService.searchCriteria$.subscribe((response) => {
      this.searchCriteria = _.cloneDeep(response);
    });
    this.subscription.add(
      this.paginate$.subscribe((paginate: any) => {
        if (paginate) {
          this.sortType = paginate.sort;
        }
      })
    );
  }

  getSortLabels(): Observable<{ byDate: string; byDocType: string; byDocAmount: string; byDocStatus: string }> {
    return combineLatest([
      this.translation.translate('invoiceResult.sorting.byDate'),
      this.translation.translate('invoiceResult.sorting.byDocType'),
      this.translation.translate('invoiceResult.sorting.byDocAmount'),
      this.translation.translate('invoiceResult.sorting.byDocStatus'),
    ]).pipe(
      map(([textByDate, textByDocType, textByDocAmount, textByDocStatus]) => {
        return {
          byDate: textByDate,
          byDocType: textByDocType,
          byDocAmount: textByDocAmount,
          byDocStatus: textByDocStatus,
        };
      })
    );
  }

  changeSortCode(sortCode: string): void {
    if (this.searchCriteria) {
      this.searchCriteria.currentPage = 0;
      this.searchCriteria.sort = sortCode;
    }
    this.sortType = sortCode;
    this.knBrInvoiceService.search(this.searchCriteria);
  }

  loadMore(currentPage: number) {
    if (this.searchCriteria) {
      this.searchCriteria.currentPage = currentPage + 1;
      this.searchCriteria.sort = this.sortType;
    }
    this.knBrInvoiceService.search(this.searchCriteria);
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
