import { Action } from '@ngrx/store';

export enum KnBrInvoiceActionTypes {
  KnBrInvoiceSearchAction = '[InvoiceList] Search',
  KnBrInvoiceSearchSuccessAction = '[InvoiceList] Search Success',
  KnBrInvoiceQuickSearchSuccessAction = '[InvoiceList] Quick Search Success',
  KnBrInvoiceSearchFailureAction = '[InvoiceList] Search Failure',
  KnBrInvoiceSearchResetAction = '[InvoiceList] Search Reset',
  KnBrInvoiceAccountSummaryAction = '[InvoiceList] Account Summary',
  KnBrInvoiceAccountSummarySuccessAction = '[InvoiceList] Account Summary Success',
  KnBrInvoiceAccountSummaryFailureAction = '[InvoiceList] Account Summary Failure',
}

/**
 * Action to Search invoice
 */
export class KnBrInvoiceSearch implements Action {
  readonly type = KnBrInvoiceActionTypes.KnBrInvoiceSearchAction;
  constructor(public payload: any) {}
}

/**
 * Action dispatched on successful invoice  Search
 */
export class KnBrInvoiceSearchSuccess implements Action {
  readonly type = KnBrInvoiceActionTypes.KnBrInvoiceSearchSuccessAction;
  constructor(public payload: any) {}
}

/**
 * Action dispatched on successful invoice  Search
 */
export class KnBrInvoiceQuickSearchSuccess implements Action {
  readonly type = KnBrInvoiceActionTypes.KnBrInvoiceQuickSearchSuccessAction;
  constructor(public payload: any) {}
}

/**
 * Action dispatched on failure invoice  Search
 */
export class KnBrInvoiceSearchFailure implements Action {
  readonly type = KnBrInvoiceActionTypes.KnBrInvoiceSearchFailureAction;
  constructor(public payload: Error) {}
}

/**
 * Action dispatched on successful invoice  Search
 */
export class KnBrInvoiceSearchReset implements Action {
  readonly type = KnBrInvoiceActionTypes.KnBrInvoiceSearchResetAction;
  constructor(public payload: any) {}
}

/**
 * Action dispatched on Account Summary
 */
export class KnBrInvoiceAccountSummary implements Action {
  readonly type = KnBrInvoiceActionTypes.KnBrInvoiceAccountSummaryAction;
  constructor(public payload: any) {}
  // constructor() { }
}

/**
 * Action dispatched on Account Summary Success
 */
export class KnBrInvoiceAccountSummarySuccess implements Action {
  readonly type = KnBrInvoiceActionTypes.KnBrInvoiceAccountSummarySuccessAction;
  constructor(public payload: any) {}
  // constructor() { }
}

/**
 * Action dispatched on failure invoice  Search
 */
export class KnBrInvoiceAccountSummaryFailure implements Action {
  readonly type = KnBrInvoiceActionTypes.KnBrInvoiceAccountSummaryFailureAction;
  constructor(public payload: Error) {}
}

export type KnBrInvoiceActions =
  | KnBrInvoiceSearch
  | KnBrInvoiceSearchSuccess
  | KnBrInvoiceQuickSearchSuccess
  | KnBrInvoiceSearchFailure
  | KnBrInvoiceSearchReset
  | KnBrInvoiceAccountSummary
  | KnBrInvoiceAccountSummarySuccess
  | KnBrInvoiceAccountSummaryFailure;
