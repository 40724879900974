/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { NgModule } from '@angular/core';
import { KnBrCheckoutDeliveryAddressModule } from './kn-br-checkout-delivery-address/kn-br-checkout-delivery-address.module';
import { KnBrCheckoutDeliveryModeModule } from './kn-br-checkout-delivery-mode/kn-br-checkout-delivery-mode.module';
import { KnBrCheckoutLoginModule } from './kn-br-checkout-login/kn-br-checkout-login.module';
import { KnBrCheckoutOrderSummaryModule } from './kn-br-checkout-order-summary/kn-br-checkout-order-summary.module';
import { KnBrCheckoutPaymentMethodModule } from './kn-br-checkout-payment-method/kn-br-checkout-payment-method.module';
import { KnBrCheckoutPlaceOrderModule } from './kn-br-checkout-place-order/kn-br-checkout-place-order.module';
import { KnBrCheckoutReviewSubmitModule } from './kn-br-checkout-review-submit/kn-br-checkout-review-submit.module';

@NgModule({
  imports: [
    KnBrCheckoutOrderSummaryModule,
    KnBrCheckoutDeliveryModeModule,
    KnBrCheckoutPaymentMethodModule,
    // KnBrCheckoutPlaceOrderModule,
    KnBrCheckoutReviewSubmitModule,
    KnBrCheckoutDeliveryAddressModule,
    KnBrCheckoutLoginModule,
  ],
})
export class KnBrCheckoutComponentsModule {}
