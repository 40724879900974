export const SLOAN_SEARCH_QUOTES_FEATURE = 'sloan-quotes';

export interface SloanQuotesState {
  quote?: any;
  error?: Error;
  showSpinner?: boolean;
}

export interface StateWithQuote {
  [SLOAN_SEARCH_QUOTES_FEATURE]: SloanQuotesState;
}
