import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SloanDraftSearchModule } from './sloan-draft-order-search/sloan-draft-order-search.module';
import { SloanDraftSearchResultModule } from './sloan-draft-search-result/sloan-draft-search-result.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, SloanDraftSearchModule, SloanDraftSearchResultModule],
})
export class SloanDraftOrderListModule {}
