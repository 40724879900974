import { Location } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { RoutingService, GlobalMessageService, GlobalMessageType } from '@spartacus/core';
import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import {
  KnBrCancelOrReturnRequestEntryInput,
  KnBrCancellationRequestEntryInputList,
} from 'src/feature-libs/kn-br-order/root/models/kn-br-order.model';
import { Statuses } from 'src/feature-libs/kn-br-cart/quote/root/models/kn-br-quote.model';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';
import { KnBrOrderHistoryService } from 'src/feature-libs/kn-br-order/core/facade/kn-br-order-history.service';
import { KnBrOrderReturnComponent } from 'src/feature-libs/kn-br-order/components/kn-br-customer360/kn-br-return-order/kn-br-order-return/kn-br-order-return.component';
import { OrderDetailsService } from '@spartacus/order/components';
import { OrderHistoryFacade } from '@spartacus/order/root';
@Component({
  selector: 'knbr-order-return',
  templateUrl: './sloan-order-return.component.html',
  styleUrls: ['./sloan-order-return.component.scss'],
})
export class SloanOrderReturnComponent extends KnBrOrderReturnComponent implements OnInit {
  constructor(
    protected knBrCommonService: KnBrCommonService,
    protected fb: FormBuilder,
    protected knBrOrderHistoryService: KnBrOrderHistoryService,
    protected routingService: RoutingService,
    protected orderDetailsService: OrderDetailsService,
    protected messageService: GlobalMessageService,
    protected location: Location,
    protected userOrderService: OrderHistoryFacade
  ) {
    super(
      knBrCommonService,
      fb,
      knBrOrderHistoryService,
      routingService,
      orderDetailsService,
      messageService,
      location,
      userOrderService
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
}
