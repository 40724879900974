import { SloanQuickOrderModule } from './sloan-quick-order/sloan-quick-order.module';
import { SloanQuickEntryModule } from './sloan-quick-entry/sloan-quick-entry.module';
import { SloanOrderFormsModule } from './sloan-order-forms/sloan-order-forms.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule, SloanOrderFormsModule, SloanQuickEntryModule, SloanQuickOrderModule],
})
export class SloanOrderToolsModule {}
