import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { GlobalMessageService, GlobalMessageType, Product } from '@spartacus/core';
import { CurrentProductService } from '@spartacus/storefront';
import { ActiveCartFacade, MultiCartFacade, OrderEntry } from '@spartacus/cart/base/root';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { KnBrSelectCartComponent } from './kn-br-select-cart/kn-br-select-cart.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';
import { KnBrProductSelectService } from 'src/feature-libs/kn-br-product/core/service/kn-br-product-select.service';
import { KnBrAddRequestEntry } from 'src/feature-libs/kn-br-order/root/models/kn-br-order.model';
import { KnBrCartContextService } from 'src/feature-libs/kn-br-cart/base/core/facade/kn-br-cart-context.service';
import { KnBrQuoteContextService } from 'src/feature-libs/kn-br-cart/quote/core/facade/kn-br-quote-context.service';
import { KnBrDraftOrdersService } from 'src/feature-libs/kn-br-cart/draft-order/core/facade/kn-br-draft-orders.service';
import { KnBrQuotesService } from 'src/feature-libs/kn-br-cart/quote/core/facade/kn-br-quotes.service';
import { KnBrDraftOrder } from 'src/feature-libs/kn-br-cart/draft-order/root/models/kn-br-draft-order.model';

@Component({
  selector: 'kn-br-add-to-cart',
  templateUrl: './kn-br-add-to-cart.component.html',
  styleUrls: ['./kn-br-add-to-cart.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class KnBrAddToCartComponent implements OnInit, OnDestroy {
  minQuantity: any;
  @Input() type = 'PDP';
  @Input() isOrderable = true;
  @Input() productCode: string;
  /**
   * As long as we do not support #5026, we require product input, as we need
   *  a reference to the product model to fetch the stock data.
   */
  @Input() product: Product;
  maxQuantity: number;
  modalRef: NgbModalRef;
  hasStock = false;
  quantity = 1;
  increment = false;
  cartEntry$: Observable<OrderEntry>;
  cartEntries$: Observable<OrderEntry[]>;
  subscription = new Subscription();
  addToCartForm = new UntypedFormGroup({
    quantity: new UntypedFormControl(1),
  });
  cartContext: string;
  quoteContext: string;
  selectedProductCodes: string[];
  isSaveDisabled$: Observable<boolean> = this.activeCartService.getLoading();
  editable = false;
  quoteEditable = false;
  @Input() productList: KnBrAddRequestEntry[];
  constructor(
    protected modalService: NgbModal,
    protected currentProductService: CurrentProductService,
    protected cd: ChangeDetectorRef,
    protected activeCartService: ActiveCartFacade,
    protected knBrCartContextService: KnBrCartContextService,
    protected knBrProductService: KnBrProductSelectService,
    protected messageService: GlobalMessageService,
    protected knBrQuoteContextService: KnBrQuoteContextService,
    protected commonService: KnBrCommonService,
    protected knBrQuotesService: KnBrQuotesService,
    protected knBrDraftOrderService: KnBrDraftOrdersService,
    protected multiCartFacade: MultiCartFacade
  ) { }
  ngOnInit() {
    this.subscription.add(
      this.knBrCartContextService.get$.subscribe((data) => {
        this.cartContext = data;
        this.editable = false;
        this.quoteEditable = false;
        if (data) {
          this.isEditable();
        } else {
          this.editable = true;
          this.quoteEditable = true;
        }
        if (this.cartContext) {
          if (this.product) {
            this.productCode = this.product.code;
            this.cartEntry$ = this.multiCartFacade.getLastEntry(this.cartContext, this.productCode);
            this.cartEntries$ = this.multiCartFacade.getEntries(this.cartContext);
            this.setStockInfo(this.product);
            this.cd.markForCheck();
          } else if (this.productCode) {
            this.cartEntry$ = this.multiCartFacade.getLastEntry(this.cartContext, this.productCode);
            this.cartEntries$ = this.multiCartFacade.getEntries(this.cartContext);
            // force hasStock and quantity for the time being, as we do not have more info:
            this.quantity = 1;
            this.hasStock = true;
            this.cd.markForCheck();
          } else {
            this.subscription.add(
              this.currentProductService
                .getProduct()
                .pipe(filter(Boolean))
                .subscribe((product: Product) => {
                  this.productCode = product.code;
                  this.setStockInfo(product);
                  this.cartEntry$ = this.multiCartFacade.getLastEntry(this.cartContext, this.productCode);
                  this.cartEntries$ = this.multiCartFacade.getEntries(this.cartContext);
                  this.cd.markForCheck();
                })
            );
            this.subscription.add(
              this.addToCartForm.get('quantity').valueChanges.subscribe((quantity) => {
                this.commonService.setPDPQuantity(quantity);
              })
            );
          }
        } else {
          this.subscription.add(
            this.addToCartForm.get('quantity').valueChanges.subscribe((quantity) => {
              this.commonService.setPDPQuantity(quantity);
            })
          );
        }
        this.cd.markForCheck();
      })
    );
    if (this.product) {
      this.productCode = this.product.code;
      this.cartEntry$ = this.multiCartFacade.getLastEntry(this.cartContext, this.productCode);
      this.cartEntries$ = this.multiCartFacade.getEntries(this.cartContext);
      this.setStockInfo(this.product);
      this.cd.markForCheck();
    } else if (this.productCode) {
      this.cartEntry$ = this.multiCartFacade.getLastEntry(this.cartContext, this.productCode);
      this.cartEntries$ = this.multiCartFacade.getEntries(this.cartContext);
      // force hasStock and quantity for the time being, as we do not have more info:
      this.quantity = 1;
      this.hasStock = true;
      this.cd.markForCheck();
    } else {
      this.subscription.add(
        this.currentProductService
          .getProduct()
          .pipe(filter(Boolean))
          .subscribe((product: Product) => {
            this.productCode = product.code;
            this.setStockInfo(product);
            this.cartEntry$ = this.multiCartFacade.getLastEntry(this.cartContext, this.productCode);
            this.cartEntries$ = this.multiCartFacade.getEntries(this.cartContext);
            this.cd.markForCheck();
          })
      );
      this.subscription.add(
        this.addToCartForm.get('quantity').valueChanges.subscribe((quantity) => {
          this.commonService.setPDPQuantity(quantity);
        })
      );
    }
    this.subscription.add(
      this.knBrQuoteContextService.get$.subscribe((data) => {
        this.quoteContext = data;
        this.quoteEditable = false;
        if (data) {
          this.isEditable();
        } else {
          this.quoteEditable = true;
        }
        this.cd.markForCheck();
      })
    );
    this.subscription.add(
      this.knBrProductService.loadSelectedProducts$().subscribe((selectedProductCodes) => {
        this.selectedProductCodes = selectedProductCodes;
        this.cd.markForCheck();
      })
    );
  }

  setStockInfo(product: any): void {
    this.quantity = product.minOrderQuantity ? product.minOrderQuantity : 1;
    this.minQuantity = this.quantity;
    this.addToCartForm.controls.quantity.setValidators([Validators.min(this.minQuantity)]);
    this.hasStock = product.stock && product.stock.stockLevelStatus !== 'outOfStock';
    if (this.hasStock && product.stock.stockLevel) {
      this.maxQuantity = product.stock.stockLevel;
    }
    this.addToCartForm.controls.quantity.updateValueAndValidity();
    this.addToCartForm.get('quantity').patchValue(this.quantity);
  }

  updateCount(value: number): void {
    this.quantity = value;
  }

  onQuantityBlur() {
    const qty = this.addToCartForm.get('quantity').value;
    const qtyBasedOnMoq = this.commonService.getQuantityBasedOnMoq(qty, this.minQuantity);
    this.addToCartForm.get('quantity').patchValue(qtyBasedOnMoq);
  }

  addToCart() {
    this.knBrProductService.setSelectedProducts([]);
    const quantity = this.addToCartForm.get('quantity').value;
    if (!this.productCode || quantity <= 0) {
      return;
    }
    if (!this.cartContext) {
      this.openModal();
    } else {
      this.multiCartFacade.addEntry(null, this.cartContext, this.productCode, quantity);
    }
  }
  addMultipleProducts() {
    if (this.selectedProductCodes && this.selectedProductCodes.length) {
      this.productCode = this.selectedProductCodes.toString();
      this.addToCart();
    } else {
      this.messageService.add({ raw: 'Please select product(s).' }, GlobalMessageType.MSG_TYPE_WARNING);
    }
  }
  openModal() {
    let modalInstance: any;
    this.quantity = this.addToCartForm.get('quantity').value;
    this.modalRef = this.modalService.open(KnBrSelectCartComponent, {
      centered: true,
      size: 'lg',
    });
    modalInstance = this.modalRef.componentInstance;
    modalInstance.productCode = this.productCode;
    modalInstance.quantity = this.quantity;
    modalInstance.productList = this.productList;
  }

  isEditable() {
    this.editable = true;
    this.subscription.add(
      this.multiCartFacade.getCart(this.cartContext).subscribe((draftOrder: KnBrDraftOrder) => {
        this.editable = true;
        if (draftOrder && this.cartContext) {
          this.editable = draftOrder.editable;
        }
        this.cd.markForCheck();
      })
    );
    this.isQuoteEditable();
  }

  isQuoteEditable() {
    this.quoteEditable = true;
    this.subscription.add(
      this.knBrQuotesService.get$.subscribe((quote) => {
        if (quote && Object.keys(quote).length && quote?.code && this.quoteContext) {
          this.quoteEditable = quote.editable;
        }
        this.cd.markForCheck();
      })
    );
  }

  addProductsToCart() {
    if (!this.cartContext) {
      this.openModal();
    } else if (this.productList && this.productList.length > 0) {
      const req = {
        cartId: this.cartContext,
        request: this.productList,
      };
      this.knBrDraftOrderService.quickEntry(req);
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
