import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { KN_BR_SEARCH_QUOTES_FEATURE } from './kn-br-quotes.state';
import { KnBrQuotesEffects } from 'src/feature-libs/kn-br-cart/quote/core/store/effects/kn-br-quotes.effects';
import { knBrQuoteReducer } from 'src/feature-libs/kn-br-cart/quote/core/store/reducers/kn-br-quotes.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(KN_BR_SEARCH_QUOTES_FEATURE, knBrQuoteReducer),
    EffectsModule.forFeature([KnBrQuotesEffects]),
  ],
})
export class KnBrQuotesStateModule { }
