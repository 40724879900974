import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SloanQuickOrderComponent } from './sloan-quick-order.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { I18nModule, ConfigModule, CmsConfig, UrlModule } from '@spartacus/core';
import { MediaModule } from '@spartacus/storefront';
import { RouterModule } from '@angular/router';
import { SloanAddToCartModule } from 'src/feature-libs/sloan/sloan-product/sloan-add-to-cart/sloan-add-to-cart.module';
import { SloanAddToQuoteModule } from 'src/feature-libs/sloan/sloan-product/sloan-add-to-quote/sloan-add-to-quote.module';

@NgModule({
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    I18nModule,
    MediaModule,
    SloanAddToCartModule,
    SloanAddToQuoteModule,
    RouterModule,
    UrlModule,
    ConfigModule.withConfig({
      cmsComponents: {
        KnBrQuickOrderComponent: {
          component: SloanQuickOrderComponent,
        },
      },
    } as CmsConfig),
  ],
  declarations: [SloanQuickOrderComponent],
})
export class SloanQuickOrderModule {}
