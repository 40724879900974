import { KnBrDraftOrderComments } from "src/feature-libs/kn-br-cart/draft-order/root/models/kn-br-draft-order.model";


export const KN_BR_DRAFT_ORDERS__COMMENT_FEATURE = 'kn-br-draft-order-comments';

export interface KnBrDraftOrdersCommentsState {
  DraftOrderComments?: KnBrDraftOrderComments[];
  errors?: Error;
  DraftOrderComment?: KnBrDraftOrderComments;
  entryNumber?: number;
}

export interface StateWithDraftOrderComments {
  [KN_BR_DRAFT_ORDERS__COMMENT_FEATURE]: KnBrDraftOrdersCommentsState;
}
