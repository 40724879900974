import { KnBrFactSheetData } from 'src/feature-libs/kn-br-order/root/models/kn-br-fact-sheet.model';

export const KNBR_FACT_SHEET_FEATURE = 'knbr-fact-sheet';

export interface KnBrFactSheetState {
  factSheetData: KnBrFactSheetData;
  error?: Error;
  isLoading?: boolean;
}

export interface StateWithKnBrFactSheet {
  [KNBR_FACT_SHEET_FEATURE]: KnBrFactSheetState;
}
