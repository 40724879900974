import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';
import { RemoveLeadingZerosPipe } from 'src/app/shared/pipe/kn-br-custom-pipes/remove-leading-zeros.pipe';
import { KnBrCustomerContextService } from 'src/app/store/kn-br-customer-context/kn-br-customer-context.service';
import { KnBrOrderReturnService } from 'src/feature-libs/kn-br-order/core/facade/kn-br-order-return.service';
import { KN_BR_CUSTOMER_CURRENT } from 'src/constants/api.endpoints.constant';
import { CustomerData } from 'src/feature-libs/kn-br-user/kn-br-account/root/model/customer.model';
import { Statuses } from 'src/feature-libs/kn-br-cart/draft-order/root/models/kn-br-draft-order.model';

@Component({
  selector: 'knbr-kn-br-return-order-search',
  templateUrl: './kn-br-return-order-search.component.html',
  styleUrls: ['./kn-br-return-order-search.component.scss'],
  providers: [RemoveLeadingZerosPipe],
})
export class KnBrReturnOrderSearchComponent implements OnInit {
  returnOrdersSearchForm: UntypedFormGroup;
  customerContextData$: Observable<CustomerData> = this.knBrCustomerContextService.get$;
  selectedCustomer: CustomerData;
  orderStatusList$: Observable<Statuses[]> = this.knBrCommonService.loadReturnOrderStatusList$();
  isSearchDisabled$: Observable<boolean> = this.knBrOrderReturnService.isSearchDisabled$;
  currentPage = 0;

  constructor(
    private fb: UntypedFormBuilder,
    private knBrCustomerContextService: KnBrCustomerContextService,
    private knBrCommonService: KnBrCommonService,
    private knBrOrderReturnService: KnBrOrderReturnService,
    protected removeLeadingZerosPipe: RemoveLeadingZerosPipe
  ) { }

  ngOnInit(): void {
    this.customerContextData$.subscribe((value) => {
      if (value) {
        this.selectedCustomer = value;
      }
      const customerNumber = value?.customerNumber ? value?.customerNumber : KN_BR_CUSTOMER_CURRENT;
      this.knBrCommonService.getReturnOrderStatusList(customerNumber);
      this.initForm();
    });
    this.submitForm();
  }

  submitForm() {
    this.knBrOrderReturnService.returnOrderListSearch(this.returnOrdersSearchForm.getRawValue());
  }

  initForm() {
    this.returnOrdersSearchForm = this.fb.group({
      rma: null,
      accountNumber: [
        {
          value: this.removeLeadingZerosPipe.transform(
            this.selectedCustomer && this.selectedCustomer.customerNumber ? this.selectedCustomer.customerNumber : null
          ),
          disabled: this.selectedCustomer && this.selectedCustomer.customerNumber ? true : false,
        },
      ],
      accountName: [
        {
          value:
            this.selectedCustomer && this.selectedCustomer.customerName ? this.selectedCustomer.customerName : null,
          disabled: this.selectedCustomer && this.selectedCustomer.customerName ? true : false,
        },
      ],
      purchaseOrderNumber: null,
      status: null,
      productNumber: null,
      currentPage: this.currentPage,
    });
  }

  resetForm() {
    this.returnOrdersSearchForm.reset();
    this.initForm();
    this.submitForm();
  }
}
