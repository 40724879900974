import { Component, OnInit } from '@angular/core';
import { RoutingService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';
import { KnBrLoginApproverComponent } from 'src/feature-libs/kn-br-user/kn-br-account/components/kn-br-login-approver/kn-br-login-approver.component';

@Component({
  selector: 'kn-br-login-approver',
  templateUrl: './sloan-login-approver.component.html',
})
export class SloanLoginApproverComponent extends KnBrLoginApproverComponent implements OnInit {
  successMessage$: Observable<string> = this.commonService.approverStatusSuccess$;
  failureMessage$: Observable<string> = this.commonService.approverStatusFailure$;

  constructor(protected routingService: RoutingService, protected commonService: KnBrCommonService) {
    super(routingService, commonService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
}
