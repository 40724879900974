import { FormControl } from '@angular/forms';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { KnBrCancelOrReturnRequestEntryInput } from 'src/feature-libs/kn-br-order/root/models/kn-br-order.model';
import { KnBrReturnOrderItemComponent } from 'src/feature-libs/kn-br-order/components/kn-br-customer360/kn-br-return-order/kn-br-order-return/kn-br-return-order-item/kn-br-return-order-item.component';

@Component({
  selector: 'knbr-return-order-item',
  templateUrl: './sloan-return-order-item.component.html',
  styleUrls: ['./sloan-return-order-item.component.scss'],
})
export class SloanReturnOrderItemComponent extends KnBrReturnOrderItemComponent implements OnInit {
  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  onchageQuantity(event) {
    if (this.quantity.value > this.item.quantity) {
      this.quantity.setValue(this.item.quantity);
    }
    const quantitychange: KnBrCancelOrReturnRequestEntryInput = {
      reason: '',
      notes: '',
      orderEntryNumber: this.item.entryNumber,
      quantity: this.quantity.value,
    };
    this.quantityChange.emit(quantitychange);
  }
}
