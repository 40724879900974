import { SloanQuoteItemModule } from './sloan-quote-item/sloan-quote-item.module';
import { SloanQuoteItemsComponent } from './sloan-quote-items.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { UrlModule, I18nModule, ConfigModule, CmsConfig } from '@spartacus/core';
import { FormErrorsModule, SpinnerModule } from '@spartacus/storefront';

@NgModule({
  declarations: [SloanQuoteItemsComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    UrlModule,
    I18nModule,
    FormErrorsModule,
    NgSelectModule,
    SloanQuoteItemModule,
    ConfigModule.withConfig({
      cmsComponents: {
        KnBrQuoteItemsComponent: {
          component: SloanQuoteItemsComponent,
        },
        KnBrQuoteItemsComponentReadOnly: {
          component: SloanQuoteItemsComponent,
        },
      },
    } as CmsConfig),
    SpinnerModule,
  ],
})
export class SloanQuoteItemsModule {}
