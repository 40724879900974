<ng-container *ngIf="productFeatureList$ | async as productFeatureList">
  <div class="container p-0 my-4" *ngIf="productFeatureList.length > 0">
    <h3 class="mb-3">{{ 'productDetails.features' | cxTranslate }}</h3>
    <table>
      <tr class="col-12 d-flex flex-wrap float-left p-0" *ngFor="let feature of productFeatureList">
        <ng-container *ngFor="let featureValue of feature?.featureValues">
          <td
            class="col-12 float-left pl-0"
            [ngClass]="isHTML(featureValue?.value) ? 'col-lg-12 html-tag' : 'col-lg-6'"
          >
            <ul class="d-flex flex-wrap mb-2 pl-4">
              <li>
                <div class="mr-3 mb-3 mb-lg-0 mb-md-0" [innerHtml]="featureValue?.value"></div>
              </li>
            </ul>
          </td>
        </ng-container>
      </tr>
    </table>
  </div>
</ng-container>
