<div class="col-lg-12 p-0" *ngIf="orderHistoryResult$ | async as orderHistoryResult">
  <ng-container *ngIf="sorts$ | async as sorts">
    <div class="row d-flex justify-content-end">
      <div class="col-sm-12 col-md-4 col-lg-2">
        <cx-sorting
          [sortOptions]="sorts"
          [sortLabels]="getSortLabels() | async"
          (sortListEvent)="changeSortCode($event)"
          [selectedOption]="sortType"
          placeholder="{{ 'orderHistory.sortByMostRecent' | cxTranslate }}"
        ></cx-sorting>
      </div>
    </div>
  </ng-container>
  <div class="row counter-wrapper" *ngIf="orderHistoryResult && orderHistoryResult.length && !(isLoader$ | async)">
    <div class="customersearch-list-table float-left col-lg-12 mt-3">
      <div
        class="customersearch-list-titles border-top float-left w-100 pl-2 pr-2 pt-3 pb-3 d-none d-sm-none d-lg-block"
      >
        <div class="col-md-1 padding-res-0 float-left font-text-600 pl-0 font-sm-size">
          {{ 'orderHistoryResult.header.orderNumber' | cxTranslate }}
        </div>
        <div class="col-md-1 padding-res-0 float-left font-text-600 pl-0 font-sm-size">
          {{ 'orderHistoryResult.header.purchaseOrderNumber' | cxTranslate }}
        </div>
        <div class="col-md-1 float-left font-text-600 pl-0 font-sm-size">
          {{ 'orderHistoryResult.header.accountNumber' | cxTranslate }}
        </div>
        <div class="col-md-1 float-left font-text-600 pl-0 font-sm-size">
          {{ 'orderHistoryResult.header.accountName' | cxTranslate }}
        </div>
        <div class="col-md-1 float-left font-text-600 pl-0 font-sm-size">
          {{ 'orderHistoryResult.header.status' | cxTranslate }}
        </div>
        <div class="col-md-1 float-left font-text-600 pl-0 font-sm-size">
          {{ 'orderHistoryResult.header.createdDate' | cxTranslate }}
        </div>
        <div class="col-md-1 float-left font-text-600 pl-0 font-sm-size">
          {{ 'orderHistoryResult.header.orderValue' | cxTranslate }}
        </div>
        <div class="col-md-1 float-left font-text-600 pl-0 font-sm-size">
          {{ 'orderHistoryResult.header.creditStatus' | cxTranslate }}
        </div>
        <div class="col-md-1 float-left font-text-600 pl-0 font-sm-size">
          {{ 'orderHistoryResult.header.deliveryBlock' | cxTranslate }}
        </div>
        <div class="col-md-1 float-left font-text-600 pl-0 font-sm-size">
          {{ 'orderHistoryResult.header.orderType' | cxTranslate }}
        </div>
        <div class="col-md-1 float-left font-text-600 pl-0 font-sm-size">
          {{ 'orderHistoryResult.header.createdBy' | cxTranslate }}
        </div>
        <div class="col-md-1 float-left font-text-600 pl-0 font-sm-size">
          {{ 'orderHistoryResult.header.pastDue' | cxTranslate }}
        </div>
      </div>

      <div
        class="
          bg-white
          customersearch-list-titles
          table-content
          border-top
          float-left
          w-100
          p-2
          font-weight-normal
          d-flex
          align-items-center
        "
        *ngFor="let order of orderHistoryResult"
        (click)="redirectToDetail(order)"
      >
        <div class="col-md-1 float-left title-wrap pl-0 font-sm-size color-font">
          <span class="res-titles"> {{ 'orderHistoryResult.header.orderNumber' | cxTranslate }}:</span>
          {{ order.code | removeLeadingZeros }}
        </div>
        <div class="col-md-1 float-left title-wrap pl-0 font-sm-size color-font">
          <span class="res-titles">{{ 'orderHistoryResult.header.purchaseOrderNumber' | cxTranslate }}:</span
          >{{ order.purchaseOrderNumber }}
        </div>

        <div class="col-md-1 float-left title-wrap pl-0 font-sm-size color-font">
          <span class="res-titles"> {{ 'orderHistoryResult.header.accountNumber' | cxTranslate }}:</span>
          {{ order.accountNumber | removeLeadingZeros }}
        </div>
        <div class="col-md-1 float-left title-wrap pl-0 font-sm-size color-font">
          <span class="res-titles"> {{ 'orderHistoryResult.header.accountName' | cxTranslate }}:</span>
          {{ order.accountName }}
        </div>
        <div class="col-md-1 float-left title-wrap pl-0 font-sm-size color-font">
          <span class="res-titles"> {{ 'orderHistoryResult.header.status' | cxTranslate }}:</span>
          {{ order.statusDisplay }}
        </div>
        <div class="col-md-1 float-left title-wrap pl-0 font-sm-size color-font">
          <span class="res-titles"> {{ 'orderHistoryResult.header.createdDate' | cxTranslate }}:</span>
          {{ order.creationDate }}
        </div>
        <div class="col-md-1 float-left title-wrap pl-0 font-sm-size color-font">
          <span class="res-titles">{{ 'orderHistoryResult.header.orderValue' | cxTranslate }}:</span>
          {{ order.total?.formattedValue }}
        </div>
        <div class="col-md-1 float-left title-wrap pl-0 font-sm-size color-font">
          <span class="res-titles"> {{ 'orderHistoryResult.header.creditStatus' | cxTranslate }}:</span
          >{{ order.creditStatus }}
        </div>
        <div class="col-md-1 float-left title-wrap pl-0 font-sm-size color-font">
          <span class="res-titles"> {{ 'orderHistoryResult.header.deliveryBlock' | cxTranslate }}:</span
          >{{ order.deliveryBlock }}
        </div>
        <div class="col-md-1 float-left title-wrap pl-0 font-sm-size color-font">
          <span class="res-titles"> {{ 'orderHistoryResult.header.orderType' | cxTranslate }}:</span
          >{{ order.orderType }}
        </div>
        <div class="col-md-1 float-left title-wrap pl-0 font-sm-size color-font">
          <span class="res-titles"> {{ 'orderHistoryResult.header.createdBy' | cxTranslate }}:</span
          >{{ order.createdBy }}
        </div>
        <div class="col-md-1 float-left title-wrap pl-0 font-sm-size color-font">
          <span class="res-titles"> {{ 'orderHistoryResult.header.pastDue' | cxTranslate }}:</span>{{ order.pastDue }}
        </div>
      </div>
    </div>
    <ng-container *ngIf="paginate$ | async as paginate">
      <div class="d-flex justify-content-center w-100 mt-3">
        <div
          *ngIf="paginate.currentPage < paginate.totalPages - 1 && !(isLoader$ | async)"
          (click)="loadMore(paginate.currentPage)"
          class="btn btn-loadmore mt-0 mr-3"
        >
          {{ 'orderHistoryResult.showMore' | cxTranslate }}
        </div>
      </div>
    </ng-container>
  </div>
  <div
    class="alert-info p-3 rounded mt-3"
    *ngIf="orderHistoryResult && orderHistoryResult.length < 1 && !(isLoader$ | async)"
  >
    {{ 'orderHistoryResult.nodataMsg' | cxTranslate }}
  </div>
</div>
<div *ngIf="(isLoader$ | async) || !(orderHistoryResult$ | async)">
  <cx-spinner></cx-spinner>
</div>
