<ng-container *ngIf="product$ | async as product">
  <div class="container p-0">
    <table class="d-flex flex-wrap">
      <tr class="col-12 col-lg-6 d-flex float-left" *ngIf="product?.accountassignmentgroup">
        <td class="text-capitalize">Account Assignment Group</td>
        <td class="font-text-400 text-capitalize">{{ product?.accountassignmentgroup }}</td>
      </tr>
      <tr class="col-12 col-lg-6 d-flex float-left" *ngIf="product?.baseUOM">
        <td class="text-capitalize">Base UOM</td>
        <td class="font-text-400 text-capitalize">{{ product?.baseUOM }}</td>
      </tr>
      <tr class="col-12 col-lg-6 d-flex float-left" *ngIf="product?.chainmaterialstatus">
        <td class="text-capitalize">Chain Material Status</td>
        <td class="font-text-400 text-capitalize">{{ product?.chainmaterialstatus }}</td>
      </tr>
      <tr class="col-12 col-lg-6 d-flex float-left" *ngIf="product?.commissionGroup">
        <td class="text-capitalize">Commission Group</td>
        <td class="font-text-400 text-capitalize">{{ product?.commissionGroup }}</td>
      </tr>
      <tr class="col-12 col-lg-6 d-flex float-left" *ngIf="product?.countryOfOrigin">
        <td class="text-capitalize">Country Of Origin</td>
        <td class="font-text-400 text-capitalize">{{ product?.countryOfOrigin }}</td>
      </tr>
      <tr class="col-12 col-lg-6 d-flex float-left" *ngIf="product?.createdOn">
        <td class="text-capitalize">Created On</td>
        <td class="font-text-400 text-capitalize">{{ product?.createdOn }}</td>
      </tr>
      <tr class="col-12 col-lg-6 d-flex float-left" *ngIf="product?.departureCountry">
        <td class="text-capitalize">Departure Country</td>
        <td class="font-text-400 text-capitalize">{{ product?.departureCountry }}</td>
      </tr>
      <tr class="col-12 col-lg-6 d-flex float-left" *ngIf="product?.DimensionForLWH">
        <td class="text-capitalize">Dimension For LWH</td>
        <td class="font-text-400 text-capitalize">{{ product?.DimensionForLWH }}</td>
      </tr>
      <tr class="col-12 col-lg-6 d-flex float-left" *ngIf="product?.group1">
        <td class="text-capitalize">Group 1</td>
        <td class="font-text-400 text-capitalize">{{ product?.group1 }}</td>
      </tr>
      <tr class="col-12 col-lg-6 d-flex float-left" *ngIf="product?.group2">
        <td class="text-capitalize">Group 2</td>
        <td class="font-text-400 text-capitalize">{{ product?.group2 }}</td>
      </tr>
      <tr class="col-12 col-lg-6 d-flex float-left" *ngIf="product?.group5">
        <td class="text-capitalize">group 5</td>
        <td class="font-text-400 text-capitalize">{{ product?.group5 }}</td>
      </tr>
      <tr class="col-12 col-lg-6 d-flex float-left" *ngIf="product?.hazardousMaterialNo">
        <td class="text-capitalize">Hazardous Material No</td>
        <td class="font-text-400 text-capitalize">{{ product?.hazardousMaterialNo }}</td>
      </tr>
      <tr class="col-12 col-lg-6 d-flex float-left" *ngIf="product?.height">
        <td class="text-capitalize">Height</td>
        <td class="font-text-400 text-capitalize">{{ product?.height }}</td>
      </tr>
      <tr class="col-12 col-lg-6 d-flex float-left" *ngIf="product?.internationalArticleNumber">
        <td class="text-capitalize">International Article Number</td>
        <td class="font-text-400 text-capitalize">{{ product?.internationalArticleNumber }}</td>
      </tr>
      <tr class="col-12 col-lg-6 d-flex float-left" *ngIf="product?.makeToOrderQuantity">
        <td class="text-capitalize">Make To Order Quantity</td>
        <td class="font-text-400 text-capitalize">{{ product?.makeToOrderQuantity }}</td>
      </tr>
      <tr class="col-12 col-lg-6 d-flex float-left" *ngIf="product?.masterEAN">
        <td class="text-capitalize">Master EAN</td>
        <td class="font-text-400 text-capitalize">{{ product?.masterEAN }}</td>
      </tr>
      <tr class="col-12 col-lg-6 d-flex float-left" *ngIf="product?.masterGrossWeight">
        <td class="text-capitalize">Master Gross Weight</td>
        <td class="font-text-400 text-capitalize">{{ product?.masterGrossWeight }}</td>
      </tr>
      <tr class="col-12 col-lg-6 d-flex float-left" *ngIf="product?.masterHeight">
        <td class="text-capitalize">Master Height</td>
        <td class="font-text-400 text-capitalize">{{ product?.masterHeight }}</td>
      </tr>
      <tr class="col-12 col-lg-6 d-flex float-left" *ngIf="product?.masterLength">
        <td class="text-capitalize">Master Length</td>
        <td class="font-text-400 text-capitalize">{{ product?.masterLength }}</td>
      </tr>
      <tr class="col-12 col-lg-6 d-flex float-left" *ngIf="product?.masterQuantity">
        <td class="text-capitalize">Master Quantity</td>
        <td class="font-text-400 text-capitalize">{{ product?.masterQuantity }}</td>
      </tr>
      <tr class="col-12 col-lg-6 d-flex float-left" *ngIf="product?.masterWidth">
        <td class="text-capitalize">Master Width</td>
        <td class="font-text-400 text-capitalize">{{ product?.masterWidth }}</td>
      </tr>
      <tr class="col-12 col-lg-6 d-flex float-left" *ngIf="product?.matchcode">
        <td class="text-capitalize">Match Code</td>
        <td class="font-text-400 text-capitalize">{{ product?.matchcode }}</td>
      </tr>
      <tr class="col-12 col-lg-6 d-flex float-left" *ngIf="product?.materialpricinggroup">
        <td class="text-capitalize">Material Pricing Group</td>
        <td class="font-text-400 text-capitalize">{{ product?.materialpricinggroup }}</td>
      </tr>
      <tr class="col-12 col-lg-6 d-flex float-left" *ngIf="product?.materialType">
        <td class="text-capitalize">Material Type</td>
        <td class="font-text-400 text-capitalize">{{ product?.materialType }}</td>
      </tr>
      <tr class="col-12 col-lg-6 d-flex float-left" *ngIf="product?.mindeliveryquantity">
        <td class="text-capitalize">Min Delivery Quantity</td>
        <td class="font-text-400 text-capitalize">{{ product?.mindeliveryquantity }}</td>
      </tr>
      <tr class="col-12 col-lg-6 d-flex float-left" *ngIf="product?.NPDIStatus">
        <td class="text-capitalize">NPDI Status</td>
        <td class="font-text-400 text-capitalize">{{ product?.NPDIStatus }}</td>
      </tr>
      <tr class="col-12 col-lg-6 d-flex float-left" *ngIf="product?.PCS_PER_PACKAGE">
        <td class="text-capitalize">Pcs Per Package</td>
        <td class="font-text-400 text-capitalize">{{ product?.PCS_PER_PACKAGE }}</td>
      </tr>
      <tr class="col-12 col-lg-6 d-flex float-left" *ngIf="product?.purchasingValueKey">
        <td class="text-capitalize">Purchasing Value Key</td>
        <td class="font-text-400 text-capitalize">{{ product?.purchasingValueKey }}</td>
      </tr>
      <tr class="col-12 col-lg-6 d-flex float-left" *ngIf="product?.QTY_PER_MASTER">
        <td class="text-capitalize">Qty Per Master</td>
        <td class="font-text-400 text-capitalize">{{ product?.QTY_PER_MASTER }}</td>
      </tr>
      <tr class="col-12 col-lg-6 d-flex float-left" *ngIf="product?.singleGrossWeight">
        <td class="text-capitalize">Single Gross Weight</td>
        <td class="font-text-400 text-capitalize">{{ product?.singleGrossWeight }}</td>
      </tr>
      <tr class="col-12 col-lg-6 d-flex float-left" *ngIf="product?.singleHeight">
        <td class="text-capitalize">Single Height</td>
        <td class="font-text-400 text-capitalize">{{ product?.singleHeight }}</td>
      </tr>
      <tr class="col-12 col-lg-6 d-flex float-left" *ngIf="product?.singleLength">
        <td class="text-capitalize">Single Length</td>
        <td class="font-text-400 text-capitalize">{{ product?.singleLength }}</td>
      </tr>
      <tr class="col-12 col-lg-6 d-flex float-left" *ngIf="product?.singleNetWeight">
        <td class="text-capitalize">single Net Weight</td>
        <td class="font-text-400 text-capitalize">{{ product?.singleNetWeight }}</td>
      </tr>
      <tr class="col-12 col-lg-6 d-flex float-left" *ngIf="product?.singleQuantity">
        <td class="text-capitalize">single Quantity</td>
        <td class="font-text-400 text-capitalize">{{ product?.singleQuantity }}</td>
      </tr>
      <tr class="col-12 col-lg-6 d-flex float-left" *ngIf="product?.singleWidth">
        <td class="text-capitalize">single Width</td>
        <td class="font-text-400 text-capitalize">{{ product?.singleWidth }}</td>
      </tr>
      <tr class="col-12 col-lg-6 d-flex float-left" *ngIf="product?.sizeCode">
        <td class="text-capitalize">size Code</td>
        <td class="font-text-400 text-capitalize">{{ product?.sizeCode }}</td>
      </tr>
      <tr class="col-12 col-lg-6 d-flex float-left" *ngIf="product?.sloanLevel1Hier">
        <td class="text-capitalize">sloan Level1 Hier</td>
        <td class="font-text-400 text-capitalize">{{ product?.sloanLevel1Hier }}</td>
      </tr>
      <tr class="col-12 col-lg-6 d-flex float-left" *ngIf="product?.storageconditions">
        <td class="text-capitalize">storage conditions</td>
        <td class="font-text-400 text-capitalize">{{ product?.storageconditions }}</td>
      </tr>
      <tr class="col-12 col-lg-6 d-flex float-left" *ngIf="product?.storageLocation">
        <td class="text-capitalize">storage Location</td>
        <td class="font-text-400 text-capitalize">{{ product?.storageLocation }}</td>
      </tr>
      <tr class="col-12 col-lg-6 d-flex float-left" *ngIf="product?.volume">
        <td class="text-capitalize">volume</td>
        <td class="font-text-400 text-capitalize">{{ product?.volume }}</td>
      </tr>
      <tr class="col-12 col-lg-6 d-flex float-left" *ngIf="product?.volumeUnit">
        <td class="text-capitalize">volume Unit</td>
        <td class="font-text-400 text-capitalize">{{ product?.volumeUnit }}</td>
      </tr>
      <tr class="col-12 col-lg-6 d-flex float-left" *ngIf="product?.weightUnit">
        <td class="text-capitalize">weight Unit</td>
        <td class="font-text-400 text-capitalize">{{ product?.weightUnit }}</td>
      </tr>
    </table>
  </div>
</ng-container>
