import { Component, OnInit } from '@angular/core';
import { CmsService, ContentSlotData } from '@spartacus/core';
import { ActiveCartService } from '@spartacus/cart/base/core';
import { Observable } from 'rxjs';

import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';
import { KnBrDraftOrder } from 'src/feature-libs/kn-br-cart/draft-order/root/models/kn-br-draft-order.model';

@Component({
  selector: 'knbr-kn-br-draft-order-delete-items',
  templateUrl: './kn-br-draft-order-delete-items.component.html',
  styleUrls: ['./kn-br-draft-order-delete-items.component.scss'],
})
export class KnBrDraftOrderDeleteItemsComponent implements OnInit {
  editable = true;
  components: ContentSlotData;
  isProductSelected$: Observable<number[]> = this.knBrCommonService.loadCartEntry();

  constructor(
    private activeCartService: ActiveCartFacade,
    private knBrCommonService: KnBrCommonService,
    private cmsService: CmsService
  ) { }

  ngOnInit(): void { }

  delete() {
    this.knBrCommonService
      .loadCartEntry()
      .subscribe((entries) => {
        this.activeCartService.removeEntry({ entryNumber: entries.toString() as any });
      })
      .unsubscribe();
  }

  isDisabled() {
    let editable = true;
    this.cmsService
      .getContentSlot('KnBrDraftOrderHeaderSlot')
      .subscribe((value) => (this.components = value))
      .unsubscribe();
    this.activeCartService
      .getActive()
      .subscribe((value: KnBrDraftOrder) => (editable = value.editable))
      .unsubscribe();
    if (
      (this.components &&
        this.components.components &&
        this.components.components.length &&
        this.components.components[0].flexType === 'KnBrDraftOrderHeaderComponentReadOnly') ||
      !editable
    ) {
      return true;
    }
    return false;
  }
}
