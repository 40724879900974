import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';
import { SloanOrderHistory } from 'src/app/services/sloan/sloan-order-history.service';
import { KnBrOrderHistoryService } from 'src/feature-libs/kn-br-order/core/facade/kn-br-order-history.service';
import { KnBrOrderFormItemComponent } from 'src/feature-libs/kn-br-cart/kn-br-order-tools/kn-br-order-forms/kn-br-order-form-search-result/kn-br-order-form-item/kn-br-order-form-item.component';

@Component({
  selector: 'kn-br-order-form-item',
  templateUrl: './sloan-order-form-item.component.html',
  styleUrls: ['./sloan-order-form-item.component.scss'],
})
export class SloanOrderFormItemComponent extends KnBrOrderFormItemComponent implements OnInit, OnChanges {
  @Input() isCustomerContextAvailable = false;
  @Input() availability: any;

  constructor(
    public knbrCommonService: KnBrCommonService,
    protected knBrOrderHistoryService: KnBrOrderHistoryService,
    protected sloanOrderHistory: SloanOrderHistory
  ) {
    super(knbrCommonService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (this.item && this.item.errorMsg) {
      this.quantity.disable();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['availability']?.currentValue) {
      this.availability = changes['availability'].currentValue;
      this.manageAvailability();
    }
  }

  manageAvailability() {
    if (this.availability) {
      this.availability.forEach((x) => {
        if (x.syncResponse.items[0].productId === this.item.code) {
          this.item['availability'] = x;
        }
      });
    }
  }
}
