import { Component } from '@angular/core';
import { CmsService, ContentSlotData } from '@spartacus/core';
import { Observable } from 'rxjs';
import { KnBrQuoteEntryService } from 'src/feature-libs/kn-br-cart/quote/core/facade/kn-br-quote-entry.service';
import { KnBrQuotesService } from 'src/feature-libs/kn-br-cart/quote/core/facade/kn-br-quotes.service';

@Component({
  selector: 'kn-br-quote-delete-items',
  templateUrl: './kn-br-quote-delete-items.component.html',
  styleUrls: ['./kn-br-quote-delete-items.component.scss'],
})
export class KnBrQuoteDeleteItemsComponent {
  components: ContentSlotData;
  isProductSelected$: Observable<string[]> = this.knBrQuotesService.loadSelectedProducts$();

  constructor(
    private knBrQuotesService: KnBrQuotesService,
    private knBrQuoteEntryService: KnBrQuoteEntryService,
    private cmsService: CmsService
  ) { }

  delete() {
    this.knBrQuotesService
      .loadSelectedProducts$()
      .subscribe((entries) => {
        this.knBrQuoteEntryService.delete({ entryNumber: entries });
      })
      .unsubscribe();
  }

  isDisabled() {
    let editable = true;
    this.cmsService
      .getContentSlot('KnBrQuoteHeaderSlot')
      .subscribe((value) => (this.components = value))
      .unsubscribe();
    this.knBrQuotesService.get$.subscribe((value) => (editable = value.editable)).unsubscribe();
    if (
      (this.components &&
        this.components.components &&
        this.components.components.length &&
        this.components.components[0].flexType === 'KnBrQuoteHeaderComponentReadOnly') ||
      !editable
    ) {
      return true;
    }
    return false;
  }
}
