import { Injectable } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { B2BUser } from '@spartacus/core';
import { environment } from 'src/environments/environment';
import { KnBrUserFormService } from 'src/feature-libs/kn-br-organization/administration/components/users/kn-br-add-user-form/kn-br-user-form.service';

@Injectable({
  providedIn: 'root',
})
export class SloanUserFormService extends KnBrUserFormService {
  protected patchData(item: B2BUser) {
    super.patchData(item);
    if (item) {
      const roles = this.form.get('roles') as FormArray;
      const emailFormControl = this.form.get('email');
      item.roles?.forEach((role) => {
        if (!(roles.value as string[]).includes(role)) {
          roles.push(new FormControl(role));
        }
      });
      if (item.displayUid && emailFormControl) {
        emailFormControl.setValue(item.displayUid);
      }
      // removing basesite id from email.
      //if (item.email?.includes((environment.BASE_SITE[0] + '|').toLowerCase())) {
      //  item.email = item.email.split((environment.BASE_SITE[0] + '|').toLowerCase())[1];
      // }
    }
  }
}
