import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { KN_BR_COMMENT_QUOTES_FEATURE } from './kn-br-quotes-comments.state';
import { knBrQuotesCommentsReducer } from 'src/feature-libs/kn-br-cart/quote/core/store/reducers/kn-br-quotes-comments.reducer';
import { KnBrQuotesCommentsEffects } from 'src/feature-libs/kn-br-cart/quote/core/store/effects/kn-br-quotes-comments.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(KN_BR_COMMENT_QUOTES_FEATURE, knBrQuotesCommentsReducer),
    EffectsModule.forFeature([KnBrQuotesCommentsEffects]),
  ],
})
export class KnBrQuotesCommentsStateModule { }
