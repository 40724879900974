import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProductSearchPage, ProductSearchService } from '@spartacus/core';
import { PageLayoutService, ProductListComponentService, ViewConfig, ViewModes } from '@spartacus/storefront';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';
import { ProductViewModeService } from 'src/feature-libs/kn-br-product/core/service/product-view-mode-service';

@Component({
  selector: 'kn-br-product-list',
  templateUrl: './kn-br-product-list.component.html',
  styleUrls: ['./kn-br-product-list.component.scss'],
})
export class KnBrProductListComponent implements OnInit, OnDestroy {
  protected subscription = new Subscription();
  isInfiniteScroll: boolean;
  model$: Observable<ProductSearchPage> = this.productListComponentService.model$;
  viewMode$ = this.viewModeService.getViewMode$();
  ViewModes = ViewModes;
  constructor(
    protected pageLayoutService: PageLayoutService,
    protected productListComponentService: ProductListComponentService,
    protected knBrCommonService: KnBrCommonService,
    public scrollConfig: ViewConfig,
    protected viewModeService: ProductViewModeService,
    protected productSearchService: ProductSearchService
  ) { }
  ngOnInit(): void {
    this.isInfiniteScroll = this.scrollConfig.view.infiniteScroll.active;
    this.knBrCommonService.setPDPQuantity(1);
    // this.isInfiniteScroll = true;
    this.subscription.add(
      this.pageLayoutService.templateName$.pipe(take(1)).subscribe((template) => {
        this.viewModeService.setViewMode(
          template === 'KnBrProductGridPageTemplate' || template === 'KnBrSearchResultsGridPageTemplate'
            ? ViewModes.Grid
            : ViewModes.List
        );
      })
    );
  }
  ngOnDestroy(): void {
    this.productSearchService.getResults();
    this.productSearchService.clearResults();
    this.subscription.unsubscribe();
  }
}
