import { CustomerData, CustomerSearchForm } from "src/feature-libs/kn-br-user/kn-br-account/root/model/customer.model";


export const KN_BR_SEARCH_CUSTOMER_FEATURE = 'kn-br-customer-search';

export interface KnBrCustomerState {
  customers?: CustomerData[];
  error?: Error;
  searchRequest?: CustomerSearchForm;
  isLoading?: boolean;
  searchDisabled?: boolean;
}

export interface StateWithCustomer {
  [KN_BR_SEARCH_CUSTOMER_FEATURE]: KnBrCustomerState;
}
