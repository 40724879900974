import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { KN_BR_QUOTE_ENTRY_FEATURE } from './kn-br-quote-entry.state';
import { KnBrQuoteEntryEffects } from 'src/feature-libs/kn-br-cart/quote/core/store/effects/kn-br-quote-entry.effects';
import { knBrQuoteEntryReducer } from 'src/feature-libs/kn-br-cart/quote/core/store/reducers/kn-br-quote-entry.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(KN_BR_QUOTE_ENTRY_FEATURE, knBrQuoteEntryReducer),
    EffectsModule.forFeature([KnBrQuoteEntryEffects]),
  ],
})
export class KnBrQuoteEntryStateModule { }
