import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SloanQuoteDetailModule } from './sloan-quote-detail/sloan-quote-detail.module';
import { SloanQuoteCreateModule } from './sloan-quote-create/sloan-quote-create.module';
import { SloanQuoteSearchResultModule } from './sloan-quote-list/sloan-quote-search-result/sloan-quote-search-result.module';
import { ConfigModule } from '@spartacus/core';
import { defaultSpinnerLayoutConfig } from '../sloan-draft-order/default-spinner-layout.config';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SloanQuoteDetailModule,
    SloanQuoteCreateModule,
    SloanQuoteSearchResultModule,
    ConfigModule.withConfig(defaultSpinnerLayoutConfig),
  ],
})
export class SloanQuoteModule {}
