import { createFeatureSelector, createSelector } from '@ngrx/store';
import { KnBrInvoiceState, KN_BR_INVOICE_FEATURE } from '../../../../../app/store/kn-br-invoice/kn-br-invoice.state';

export const getInvoiceListState = createFeatureSelector<KnBrInvoiceState>(KN_BR_INVOICE_FEATURE);

export const getInvoice = createSelector(getInvoiceListState, (state: KnBrInvoiceState) => state.b2bDocuments);

export const getInvoiceLoader = createSelector(getInvoiceListState, (state: KnBrInvoiceState) => state.isLoading);

export const getInvoicePaginate = createSelector(getInvoiceListState, (state: KnBrInvoiceState) => state.pagination);

export const getInvoiceSorts = createSelector(getInvoiceListState, (state: KnBrInvoiceState) => state.sorts);

export const getInvoiceSearchCriteria = createSelector(
  getInvoiceListState,
  (state: KnBrInvoiceState) => state.searchRequest
);

export const getInvoiceSearchDisabled = createSelector(
  getInvoiceListState,
  (state: KnBrInvoiceState) => state.isSearchDisabled
);

export const getInvoiceAccountSummaryState = createSelector(
  getInvoiceListState,
  (state: KnBrInvoiceState) => state.accountSummary
);
