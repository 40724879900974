import { CmsConfig, AuthGuard } from "@spartacus/core";
import { OrganizationTableType, ActiveLinkCellComponent, CellComponent, RolesCellComponent, StatusCellComponent, UserItemService, ItemService } from "@spartacus/organization/administration/components";
import { AdminGuard } from "@spartacus/organization/administration/core";
import { TableConfig } from "@spartacus/storefront";
import { SloanUserChangePasswordFormComponent } from "src/feature-libs/sloan/sloan-organization/components/change-password-form/user-change-password-form.component";
import { KnBrListComponent } from "../shared/kn-br-list/kn-br-list.component";
import { KnBrListService } from "../shared/kn-br-list/kn-br-list.service";
import { KnBrUserFormComponent } from "./kn-br-add-user-form/kn-br-user-form.component";
import { KnBrUserDetailsComponent } from "./kn-br-user-details/kn-br-user-details.component";
import { KnBrUserListService } from "./services/kn-br-user-list.service";
//import { KnBrUserListService } from "./services";

export const knBrUserTableConfig: TableConfig = {
  table: {
    [OrganizationTableType.USER]: {
      cells: ['name', 'uid', 'roles', 'active'],
      options: {
        cells: {
          name: {
            dataComponent: ActiveLinkCellComponent,
          },
          uid: {
            dataComponent: CellComponent,
          },
          roles: {
            dataComponent: RolesCellComponent,
          },
          active: {
            dataComponent: StatusCellComponent,
          },
        },
      },
    },
  },
};

export const knBrUserCmsConfig: CmsConfig = {
  cmsComponents: {
    ManageUsersListComponent: {
      component: KnBrListComponent,
      providers: [
        {
          provide: KnBrListService,
          useExisting: KnBrUserListService,
        },
        {
          provide: ItemService,
          useExisting: UserItemService,
        },
      ],
      childRoutes: {
        children: [
          {
            path: 'create',
            component: KnBrUserFormComponent,
          },
          {
            path: `:userCode`,
            component: KnBrUserDetailsComponent,
            data: {
              cxPageMeta: { breadcrumb: 'orgUser.breadcrumbs.details' },
            },
            children: [
              {
                path: `edit`,
                component: KnBrUserFormComponent,
              },
              {
                path: `change-password`,
                component: SloanUserChangePasswordFormComponent,
              },
            ],
          },
        ],
      },
      guards: [AuthGuard, AdminGuard],
    },
  },
};
