import { KnBrCommonService } from 'src/app/services/kn-br-common.service';
import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { RoutingService } from '@spartacus/core';
import { LaunchDialogService } from '@spartacus/storefront';
import { Subscription } from 'rxjs';
import { KnBrOrderReturnDetails } from 'src/feature-libs/kn-br-order/root/models/kn-br-order.model';
import { OrderReturnRequestService } from '@spartacus/order/core';
import { SloanCommonService } from 'src/app/services/sloan/sloan-common.service';
import { KnBrReturnOrderDetailsOverviewComponent } from 'src/feature-libs/kn-br-order/components/kn-br-customer360/kn-br-return-order-detail/kn-br-return-order-details-overview/kn-br-return-order-details-overview.component';

@Component({
  selector: 'knbr-sloan-return-order-details-overview',
  templateUrl: './sloan-return-order-details-overview.component.html',
  styleUrls: ['./sloan-return-order-details-overview.component.scss'],
})
export class SloanReturnOrderDetailsOverviewComponent
  extends KnBrReturnOrderDetailsOverviewComponent
  implements OnInit {
  subscription = new Subscription();
  documentsData = new Array();
  returnOrderConfermationsKey = 'returnOrderConfermations';
  creditMemosKey = 'creditMemos';
  customerPaperWorksKey = 'customerPaperWorks';
  constructor(
    protected returnRequestService: OrderReturnRequestService,
    protected routingService: RoutingService,
    protected commonService: SloanCommonService,
    protected launchDialogService: LaunchDialogService,
    protected vcr: ViewContainerRef,
    protected knBrCommonService: KnBrCommonService
  ) {
    super(returnRequestService, routingService, launchDialogService, vcr, knBrCommonService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.subscription.add(
      this.orderReturnData$.subscribe((res: KnBrOrderReturnDetails) => {
        if (res && res.attachments) {
          this.documentsData = res.attachments.map((val: any) => {
            let attachmentsString = val.value;
            attachmentsString = attachmentsString.replace(/[{}]/g, '');
            const attachmentsArray = attachmentsString.split(', ');
            const pdfArray = attachmentsArray.map((val2: any) => {
              if (val2) {
                let attachmentsData = val2.split('_')[1];
                attachmentsData = attachmentsData ? attachmentsData.split('=') : [];
                return {
                  pdfLabelKey: attachmentsData[0] ? attachmentsData[0] : '',
                  pdfValueKey: attachmentsData[1] ? attachmentsData[1] : '',
                };
              } else {
                return [];
              }
            });
            return { key: val.key, pdfData: pdfArray ? pdfArray : [] };
          });
        }
      })
    );
  }

  downloadSAPDocumentPDF(value: any) {
    if (!value) {
      return;
    }

    this.commonService.downloadSAPDocumentPDF(value);
  }
}
