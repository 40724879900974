<ng-container *ngIf="order$ | async as order">
  <ng-container *ngIf="order.orderEntries">
    <div class="col-12 col-sm float-left p-0 mb-3">
      <div
        class="col-12 col-sm float-left p-0 border d-flex align-items-center"
        *ngFor="let item of order.orderEntries; let i = index"
      >
        <div class="col-md-2 col-sm float-left" [ngClass]="{ 'unconsigned-item': !item.consignmentEntry }">
          <a [routerLink]="{ cxRoute: 'product', params: item.product } | cxUrl" cxModal="dismiss" tabindex="-1">
            <cx-media [container]="item.product?.images" format="product"></cx-media>
          </a>
        </div>
        <div class="col-md-10 col-sm float-left">
          <div class="col-md-12 col-sm float-left p-0 pt-2 pb-2 border-bottom">
            <label class="font-text-600 color-font font-size-18">{{ item.product?.code }}</label>
            <div *ngIf="item.product?.name" class="">
              <a
                class="cx-link"
                [routerLink]="{ cxRoute: 'product', params: item.product } | cxUrl"
                cxModal="dismiss"
                >{{ item.product?.name }}</a
              >
            </div>
          </div>
          <ng-container *ngIf="!(isOrderEditable$ | async)">
            <div
              class="col-md-12 col-sm float-left p-0 pt-2 pb-2"
              [ngClass]="{ 'border-bottom': item.consignmentEntry }"
            >
              <div class="col-md-1 col-sm float-left p-0">
                <label class="color-font font-text-600 mb-1">{{ 'knbrOrderDetails.label.qty' | cxTranslate }}</label>
                <p class="text-muted mb-1">{{ item.quantity }}</p>
              </div>
              <div class="col-md-2 col-sm float-left p-0">
                <label class="color-font font-text-600 mb-1">{{
                  'knbrOrderDetails.label.atpDate' | cxTranslate
                }}</label>
                <p class="text-muted mb-1"></p>
              </div>
              <div class="col-md-1 col-sm float-left p-0">
                <label class="color-font font-text-600 mb-1">{{ 'knbrOrderDetails.label.plant' | cxTranslate }}</label>
                <p class="text-muted mb-1">{{ item.product?.manufacturer }}</p>
              </div>
              <div class="col-md-2 col-sm float-left p-0">
                <label class="color-font font-text-600 mb-1">{{
                  'knbrOrderDetails.label.lineItemStatus' | cxTranslate
                }}</label>
                <p class="text-muted mb-1">{{ item.lineItemStatus }}</p>
              </div>
              <div
                class="col-md-2 col-sm float-left p-0"
                *ngIf="item.knbrQuoteReference && item.knbrQuoteReference?.quoteRefNumber"
              >
                <label class="color-font font-text-600 mb-1">{{
                  'knbrOrderDetails.label.quoteId' | cxTranslate
                }}</label>
                <p class="text-muted mb-1">{{ item.knbrQuoteReference?.quoteRefNumber }}</p>
              </div>
              <div class="col-md-2 col-sm float-left p-0">
                <label class="color-font font-text-600 mb-1">{{
                  'knbrOrderDetails.label.openQuantity' | cxTranslate
                }}</label>
                <p class="text-muted mb-1">{{ item.openQuantity }}</p>
              </div>
              <div class="col-md-2 col-sm float-left p-0">
                <label class="color-font font-text-600 mb-1">{{
                  'knbrOrderDetails.label.unitNetPrice' | cxTranslate
                }}</label>
                <p class="text-muted mb-1">{{ item.basePrice?.formatedNetPrice }}</p>
              </div>
            </div>
          </ng-container>
          <knbr-kn-br-edit-order-item
            *ngIf="isOrderEditable$ | async"
            [item]="item"
            (inputChange)="onQuantityChange($event)"
          >
          </knbr-kn-br-edit-order-item>
          <ng-container *ngIf="item.consignmentEntry as consignment">
            <ng-container *ngIf="consignment.showShipment">
              <div class="col-12 float-left p-0 pt-2 pb-2">
                <label class="w-100 float-left mb-2 text-muted font-size-18">{{
                  'knbrOrderDetails.label.shipments' | cxTranslate
                }}</label>
                <div class="col-12 float-left p-0">
                  <div class="col-2 float-left p-0">
                    <label class="color-font font-text-600 mb-1">{{
                      'knbrOrderDetails.label.qty' | cxTranslate
                    }}</label>
                    <p class="text-muted mb-1">{{ consignment.shippedQuantity }}</p>
                  </div>
                  <div class="col-2 float-left p-0">
                    <label class="color-font font-text-600 mb-1">{{
                      'knbrOrderDetails.label.shipDate' | cxTranslate
                    }}</label>
                    <p class="text-muted mb-1">{{ consignment.shipDate }}</p>
                  </div>
                  <div class="col-2 float-left p-0">
                    <label class="color-font font-text-600 mb-1">{{
                      'knbrOrderDetails.label.status' | cxTranslate
                    }}</label>
                    <p class="text-muted mb-1">{{ consignment.status }}</p>
                  </div>
                  <div class="col-2 float-left p-0">
                    <label class="color-font font-text-600 mb-1">{{
                      'knbrOrderDetails.label.deliveryNumber' | cxTranslate
                    }}</label>
                    <p class="text-muted mb-1">{{ consignment.code }}</p>
                  </div>
                  <div class="col-2 float-left p-0">
                    <label class="color-font font-text-600 mb-1">{{
                      'knbrOrderDetails.label.carrier' | cxTranslate
                    }}</label>
                    <p class="text-muted mb-1">{{ consignment.carrierDetails?.name }}</p>
                  </div>
                  <div class="col-2 float-left p-0">
                    <label class="color-font font-text-600 mb-1">{{
                      'knbrOrderDetails.label.trackingId' | cxTranslate
                    }}</label>
                    <a class="text-muted mb-1 cursor-pointer">{{ consignment.trackingID }}</a>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="col-md-12 float-right d-flex justify-content-center mt-2">
    <button type="button" class="btn btn-action" (click)="goBack()">
      {{ 'knbrOrderDetails.actions.backOrderHistory' | cxTranslate }}
    </button>
  </div>
</ng-container>
<ng-container>
  <kn-br-add-products *ngIf="isOrderEditable$ | async" (inputChange)="onInputChange($event)"> </kn-br-add-products
></ng-container>
