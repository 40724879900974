import { SloanQuotesState } from './sloan-quotes.state';
import { SloanQuotesActionTypes, KnBrQuotesSearchActions } from './sloan-quotes.action';

export const initialState: SloanQuotesState = {
  quote: undefined,
  error: undefined,
  showSpinner: false,
};

export function sloanQuoteReducer(state = initialState, action: KnBrQuotesSearchActions): SloanQuotesState {
  switch (action.type) {
    case SloanQuotesActionTypes.SloanQuoteEditAction:
      return { ...state, quote: action.payload, showSpinner: true };
    case SloanQuotesActionTypes.SloanQuoteEditSuccessAction:
      return { ...state, quote: action.payload, showSpinner: false };
    case SloanQuotesActionTypes.SloanQuoteEditFailureAction:
      return { ...state, error: action.payload, showSpinner: false };
    default:
      return state;
  }
}
