import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { SloanDraftOrderDownloadLinkComponent } from './sloan-draft-order-download-link.component';

@NgModule({
  declarations: [SloanDraftOrderDownloadLinkComponent],
  imports: [
    CommonModule,
    I18nModule,
    ConfigModule.withConfig({
      cmsComponents: {
        KnBrDraftOrderDownloadLinkComponent: {
          component: SloanDraftOrderDownloadLinkComponent,
        },
      },
    } as CmsConfig),
  ],
})
export class SloanDraftOrderDownloadLinkModule {}
