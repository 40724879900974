export const factSheetHeader = {
  labels: {
    accountNumber: 'Account Number',
    orderConfirmationEmail: 'Order Confirmation Email',
    mrpdProgramMedallionLevel: 'MRPD Program Medallion Level',
    priceGroup: 'Price Group',
    name: 'Name',
    advanceShipNoticeEmail: 'Advance Ship Notice Email',
    factSheet: 'Fact Sheet',
    address: 'Address',
    invoiceEmail: 'Invoice Email',
  },
};
export const factSheetGroup = {
  header: {
    materialGroup: 'Material Group',
    multiplier: 'Multiplier',
  },
};
export const factSheetMaterial = {
  header: {
    material: 'Material',
    description: 'Description',
    netPrice: 'Net Price',
    currency: 'Currency',
  },
};
export const factSheet = {
  factSheetHeader,
  factSheetGroup,
  factSheetMaterial,
};
