import { KnBrEditOrderRequest, KnBrOrderFormsSearchRequest } from '../../../feature-libs/kn-br-order/root/models/kn-br-order.model';
import { Product } from '@spartacus/core';
import { KnBrOrderHistory, KnBrOrderHistorySearchForm } from 'src/feature-libs/kn-br-order/root/models/kn-br-order.model';

export const KN_BR_ORDER_HISTORY_FEATURE = 'kn-br-order';

export interface KnBrOrderHistoryState {
  orders?: KnBrOrderHistory[];
  pagination?: any;
  sorts?: any;
  error?: Error;
  searchRequest?: KnBrOrderHistorySearchForm;
  isLoading?: boolean;
  isSearchDisabled: boolean;
  order?: any;
  products?: Product[];
  orderFormsSearchRequest: KnBrOrderFormsSearchRequest;
  editOrder: KnBrEditOrderRequest;
  editDisable: boolean;
  orderFormspagination?: any;
  orderFormssorts?: any;
  orderFormsLoading?: boolean;
  orderFormsSearchDisabled?: boolean;
  orderFormResponse?: any;
}

export interface StateWithKnBrOrderHistory {
  [KN_BR_ORDER_HISTORY_FEATURE]: KnBrOrderHistoryState;
}
